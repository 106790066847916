import { memo, useEffect, useMemo } from "react"
import { useParams } from "react-router-dom"
import sub from "date-fns/sub"

import { useOrderResponseCount, useAllFilteredOrders, useOrganizationById } from "@/management/hooks"
import { RouteParams, SankeyNodeKey, TimeFilter, defaultTimezone } from "@/shared/types"
import { dateTimeFormatInTimeZone, dateTimeTzFormat, firstToUpperCase } from "@/shared/utils"
import { useGlobalStore } from "@/store"
import { Colors } from "@/stitches/types"

import { Box, Copy, Flex, Spacer } from "@/shared/components"
import { OrdersTableRow, Pagination } from "@/management/components"

const getStrategyColorByType = (strategyType: SankeyNodeKey): Colors => {
  switch (strategyType) {
    case SankeyNodeKey.WATERFALL:
      return "brand-blue-primary"
    case SankeyNodeKey.WAIT_FOR_ALL:
    case SankeyNodeKey.FCFS:
      return "brand-green-primary"
    case SankeyNodeKey.CRITICAL:
      return "brand-red-primary"
    default:
      return "system-black"
  }
}

const getStrategyNameByType = (strategyType: SankeyNodeKey) => {
  switch (strategyType) {
    case SankeyNodeKey.WATERFALL:
      return "Waterfall"
    case SankeyNodeKey.WAIT_FOR_ALL:
      return "Wait for all quotes"
    case SankeyNodeKey.FCFS:
      return "First come first serve"
    case SankeyNodeKey.CRITICAL:
      return "Critical"
    default:
      return "-"
  }
}

export const SankeyTable = memo(
  ({ selectedLinkDetails, timeFilter }: { selectedLinkDetails: string; timeFilter: TimeFilter }) => {
    const { organizationId } = useParams<keyof RouteParams>() as RouteParams
    const [selectedType, selectedValue] = selectedLinkDetails.split(":")
    const selectedStrategy = selectedType !== SankeyNodeKey.FILTERED

    const [page, setPage, orgId, setOrgId] = useGlobalStore((state) => [
      state.page,
      state.setPage,
      state.orgId,
      state.setOrgId,
    ])
    const { data: orgData, isLoading: isOrganizationLoading } = useOrganizationById(organizationId)
    const { data: allOrders, isLoading: isAllFilteredOrdersLoading } = useAllFilteredOrders({
      orgId: organizationId,
      page,
      size: 10,
      lineType: selectedType as SankeyNodeKey,
      from:
        sub(new Date(), { days: Number(timeFilter) - 1 })
          .toJSON()
          .split("T")[0] + "T00:00:00",
    })

    const orders = useMemo(() => (allOrders?.content ? allOrders.content : []), [allOrders?.content])
    const { data: orderResponseCount, isLoading: isOrderResponseCountLoading } = useOrderResponseCount(
      organizationId,
      orders.map((i) => i.orderId).join(","),
    )
    const isLoading = isOrganizationLoading || isAllFilteredOrdersLoading || isOrderResponseCountLoading

    // INFO: To protect bad orders pagination data
    useEffect(() => {
      if (!allOrders?.totalPages || allOrders?.totalPages < page) {
        setPage(0)
      }
    }, [allOrders?.totalPages, page, setPage])

    useEffect(() => {
      if (orgId !== organizationId) {
        setPage(0)
        setOrgId(organizationId)
      }
    }, [orgId, organizationId, setOrgId, setPage])

    return (
      <Box
        id="sankeyTable"
        css={{
          marginTop: "$32",
          padding: "$40 $56",
          backgroundColor: "$system-white",
          border: "1px solid $theme-n3-n7",
          borderRadius: "$8",
        }}
      >
        {!selectedStrategy ? (
          <Copy scale={8} color="theme-b-n3" fontWeight="bold">
            All filtered out orders for the selected period - {selectedValue}
          </Copy>
        ) : (
          <Copy scale={8} color="theme-b-n3" fontWeight="bold">
            All non-covered orders in
            <Copy
              as="span"
              scale={8}
              color={getStrategyColorByType(selectedType as SankeyNodeKey)}
              fontWeight="bold"
              css={{ paddingX: "$8" }}
            >
              {getStrategyNameByType(selectedType as SankeyNodeKey)}
            </Copy>
            for the selected period - {selectedValue}
          </Copy>
        )}
        <Spacer size={32} />
        <OrdersTableRow
          orderId="Order id"
          shipper="Shipper"
          consignee="Consignee"
          shipDate="Ship date"
          deliveryDate="Delivery date"
          createdDate="Created"
          status="Status"
          reason="Reason"
          responses={selectedStrategy ? "Responses" : undefined}
          details={!selectedStrategy ? "Details" : undefined}
          isTableHead
        />
        {isLoading ? (
          Array.from(new Array(5), (_, index) => index).map((v) => <OrdersTableRow key={v} isPlaceholder />)
        ) : orders.length === 0 ? (
          <Flex
            align="center"
            justify="center"
            css={{
              height: "$64",
              color: "$theme-b-n3",
            }}
          >
            <Copy color="theme-n6-n5">There is no data yet</Copy>
          </Flex>
        ) : (
          orders.map(
            ({
              orderId,
              fromCity,
              fromState,
              fromAddress2,
              fromAddress1,
              fromPostal,
              toCity,
              toState,
              toAddress2,
              toAddress1,
              toPostal,
              pickupStartAt,
              dropOffStartAt,
              createdInApollo,
              orderStatus,
              completionReason,
              structuredData,
            }) => {
              const shipperAdress =
                fromCity && fromState ? `${firstToUpperCase(fromCity)}, ${fromState}` : "-"
              const shipperFullAdress = [
                fromAddress1,
                fromAddress2,
                ...(fromCity ? [firstToUpperCase(fromCity)] : []),
                fromState,
                fromPostal,
              ]
                .filter(Boolean)
                .join(", ")
              const consigneeAdress = toCity && toState ? `${firstToUpperCase(toCity)}, ${toState}` : "-"
              const consigneeFullAdress = [
                toAddress1,
                toAddress2,
                ...(toCity ? [firstToUpperCase(toCity)] : []),
                toState,
                toPostal,
              ]
                .filter(Boolean)
                .join(", ")

              const timeZoneOrg = orgData?.timeZone || defaultTimezone
              const timeZoneFrom = structuredData?.fromTimeZoneId || defaultTimezone
              const timeZoneTo = structuredData?.toTimeZoneId || defaultTimezone
              const shipDate = `${dateTimeTzFormat({
                dateTime: pickupStartAt,
                timeZone: timeZoneFrom,
                timeFormat: "MMM d, yyyy",
              })}`
              const shipTime = `${dateTimeTzFormat({
                dateTime: pickupStartAt,
                timeZone: timeZoneFrom,
                timeFormat: "hh:mm aa (zzz)",
              })}`
              const deliveryDate = `${dateTimeTzFormat({
                dateTime: dropOffStartAt,
                timeZone: timeZoneTo,
                timeFormat: "MMM d, yyyy",
              })}`
              const deliveryTime = `${dateTimeTzFormat({
                dateTime: dropOffStartAt,
                timeZone: timeZoneTo,
                timeFormat: "hh:mm aa (zzz)",
              })}`
              const createdDate = `${dateTimeFormatInTimeZone({
                dateTime: createdInApollo,
                timeZone: timeZoneOrg,
                format: "MMM d, yyyy",
              })}`
              const createdTime = `${dateTimeFormatInTimeZone({
                dateTime: createdInApollo,
                timeZone: timeZoneOrg,
                format: "hh:mm aa (zzz)",
              })}`
              const responses = isFinite(Number(orderResponseCount?.[orderId]))
                ? orderResponseCount?.[orderId]
                : "-"
              const details = structuredData?.validationErrors
                ? firstToUpperCase(structuredData.validationErrors, true)
                : "-"

              return (
                <OrdersTableRow
                  key={orderId}
                  orderId={orderId}
                  shipper={structuredData?.shipperName || "-"}
                  shipperAdress={shipperAdress}
                  shipperFullAdress={shipperFullAdress}
                  consignee={structuredData?.consigneeName || ""}
                  consigneeAdress={consigneeAdress}
                  consigneeFullAdress={consigneeFullAdress}
                  shipDate={shipDate}
                  shipTime={shipTime}
                  deliveryDate={deliveryDate}
                  deliveryTime={deliveryTime}
                  createdDate={createdDate}
                  createdTime={createdTime}
                  status={orderStatus}
                  reason={completionReason || ""}
                  responses={selectedStrategy ? responses : undefined}
                  details={!selectedStrategy ? details : undefined}
                  organizationId={organizationId}
                />
              )
            },
          )
        )}

        <Pagination totalPages={allOrders?.totalPages} currentPage={page} onPageChange={setPage} />
      </Box>
    )
  },
)

SankeyTable.displayName = "SankeyTable"
