import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import {OrderReasonType, OrderStatusType, OrderType, RouteParams, defaultTimezone, ModalType, OrgProperties } from "@/shared/types"
import { useFullOrderById, useOrganizationById } from "@/management/hooks"
import { dateTimeFormatInTimeZone, orderReasonToText, transformOrderId } from "@/shared/utils"
import {MANAGEMENT} from "@/constants"

import {
  Breadcrumbs,
  Title,
  Spacer,
  Copy,
  Redacted,
  Flex,
  OrderStatusLabel,
  TabList,
  TabListItem,
  TabPanel,
  TabPanels,
  Tabs,
  Button,
} from "@/shared/components"
import { OrderEventsTable } from "@/management/components"

import { Summary } from "./components"
import {useModalActions} from "@/shared/hooks";
import { ForceTenderExtendedModal } from "@/modals"
import { getBooleanOrgProperty } from "@/shared/utils/helpers/getOrgProperties"

export const OrderViewContainer = () => {
  const { orderId, organizationId } = useParams<keyof RouteParams>() as RouteParams
  const navigate = useNavigate()
  const [tab, setTab] = useState(OrderType.Summary)
  const { open } = useModalActions()
  const { data: orgData, isLoading: isOrganizationLoading } = useOrganizationById(organizationId)
  const { data: order, isLoading: isOrderLoading } = useFullOrderById(orderId)
  const isForceTenderForAnyCarrierButton = getBooleanOrgProperty(OrgProperties.FEATURE_FLAG_MANUAL_CARRIER_ASSIGN_ENABLED)

  const isLoading = isOrderLoading || isOrganizationLoading

  const timeZoneFrom = order?.structuredData?.fromTimeZoneId || defaultTimezone

  const tabs = [
    {
      id: OrderType.Summary,
      label: "Summary",
      component: <Summary isDataLoading={isLoading} order={order} />,
    },
    { id: OrderType.Events, label: "Events", component: <OrderEventsTable /> },
  ]

  useEffect(() => {
    if (order?.orgId && organizationId !== order.orgId) {
      navigate(`${MANAGEMENT}/organization/${organizationId}`)
    }
  }, [navigate, order?.orgId, organizationId])

  return (
    <>
      {isLoading ? (
        <>
          <Redacted height="$24" width="300px" text animated />
          <Spacer size={20} />
        </>
      ) : (
        <Breadcrumbs
          additionalRoutes={[
            {
              url: orgData?.id ? `${MANAGEMENT}/organization/${orgData.id}` : MANAGEMENT,
              label: orgData?.label || "",
            },
          ]}
          routeParam={`${transformOrderId(order?.orderId, organizationId) || ""}`}
        />
      )}
      {isLoading ? (
        <Redacted height="34px" width="170px" text animated />
      ) : (
        <Flex align="center" css={{ gap: "$16" }}>
          <Title as="h1" scale={2} color="theme-b-n3">
            {transformOrderId(order?.orderId, organizationId) || "-"}
          </Title>
          {order?.orderStatus ? (
            <OrderStatusLabel status={order.orderStatus as OrderStatusType} scale={8} filled big />
          ) : null}
          {order?.completionReason ? (
            <Copy color="theme-b-n3" uppercase>{`(${orderReasonToText(
              order.completionReason as OrderReasonType,
            )})`}</Copy>
          ) : null}
          {order?.orderStatus === 'TENDERING' && isForceTenderForAnyCarrierButton ?
            <Button
              action="secondary"
              type="button"
              size={"small"}
              ariaLabel={"Assign carrier manually"}
              onClick={() => open(ModalType.ForceTenderExtendedModal+orderId)}
              disabled={false}
            >
              <Copy as="span" fontWeight="bold" css={{ whiteSpace: "nowrap" }}>
                {"Assign carrier manually"}
              </Copy>
            </Button> : null}
          <ForceTenderExtendedModal />
        </Flex>

      )}

      <Spacer size={8} />
      {isLoading ? (
        <Redacted height="$24" width="300px" text animated />
      ) : (
        <Copy color="theme-n6-n5">
          {order?.createdInApollo
            ? dateTimeFormatInTimeZone({
                dateTime: order?.createdInApollo,
                timeZone: timeZoneFrom,
              })
            : "-"}
        </Copy>
      )}
      <Spacer size={40} />
      <Tabs selectedTab={tab} animate={false}>
        <TabList label="dashboard-tabs">
          {tabs.map((tab) => (
            <TabListItem key={tab.id} id={tab.id} onChange={() => setTab(tab.id)}>
              {tab.label}
            </TabListItem>
          ))}
        </TabList>
        <TabPanels>
          {tabs.map((tab) => (
            <TabPanel key={tab.id} id={tab.id}>
              {tab.component}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </>
  )
}
