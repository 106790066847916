import { memo } from "react"

import { OrderReasonType } from "@/shared/types"
import { Colors } from "@/stitches/types"
import { orderReasonToText } from "@/shared/utils"

import { Copy } from "@/shared/components"

const getTextColor = (reason: OrderReasonType): Colors => {
  switch (reason) {
    case OrderReasonType.NotEligible:
    case OrderReasonType.NoCarriersLeft:
    case OrderReasonType.NoCarriersFound:
    case OrderReasonType.PickupTimeTooClose:
    case OrderReasonType.SentToManualProcessing:
    case OrderReasonType.OrderCanceledInMcleod:
    case OrderReasonType.RevenuewCodeChangedFromDfToZz:
    case OrderReasonType.CarrierManuallyAssignedInMcleod:
    case OrderReasonType.CoverageIsInvalid:
    case OrderReasonType.MissingKmaCode:
    case OrderReasonType.SmthWentWrong:
    case OrderReasonType.CanceledByShipper:
    case OrderReasonType.AllCarriersRejected:
    case OrderReasonType.NoCarriersResponded:
    case OrderReasonType.NoCarriersAccepted:
    case OrderReasonType.NoStrategyFound:
    case OrderReasonType.MajorUpdate:
    case OrderReasonType.NoWinnerDefined:
      return "brand-red-primary"

    case OrderReasonType.CarrierAcceptedTender:
    case OrderReasonType.ReachedPickupDate:
      return "brand-green-primary"

    default:
      return "system-black"
  }
}

export const OrderReasonLabel = memo(({ reason }: { reason: OrderReasonType }) => {
  return (
    <Copy as="span" scale={10} color={getTextColor(reason)} truncate fontWeight={reason ? "bold" : "regular"}>
      {orderReasonToText(reason)}
    </Copy>
  )
})

OrderReasonLabel.displayName = "OrderReasonLabel"
