import { SlaItemGroup } from "@/shared/types"

export const slaGroupToText = (group?: SlaItemGroup) => {
  switch (group) {
    case SlaItemGroup.And:
      return "AND"
    case SlaItemGroup.Or:
      return "OR"
    default:
      return ""
  }
}
