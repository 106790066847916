import { RegisterOptions } from "react-hook-form"

import { IZipCodeData } from "@/shared/types"

export const zipCodeRule = (zipCodeData: IZipCodeData): RegisterOptions => ({
  shouldUnregister: true,
  pattern: {
    value: zipCodeData.country === "Canada" ? Canada_RegularZipCode : USA_RegularZipCode,
    message: zipCodeData.country === "Canada" ? "Not match: A1A 1A1" : "Not match: XXXXX or XXXXX-XXXX",
  },
  minLength: {
    value: zipCodeData.country === "Canada" ? 7 : 5,
    message: "Zip code min length not met",
  },
  maxLength: {
    value: zipCodeData.country === "Canada" ? 7 : 10,
    message: "Zip code max length exceeded",
  },
})

export const USA_RegularZipCode = /^([0-9]{5}|([0-9]{5}-[0-9]{4}))?$/

export const Canada_RegularZipCode = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/
