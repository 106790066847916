import { RegisterOptions } from "react-hook-form"

export const emailRules = (required: boolean): RegisterOptions => ({
  shouldUnregister: true,
  required: {
    value: required,
    message: "Required field",
  },
  pattern: {
    value: /^[^\s@]{1,64}@[^\s@]+\.[^\s@]+$/,
    message: "Invalid format",
  },
  maxLength: {
    value: 256,
    message: "Max 256 characters allowed",
  },
})
