import { Navigate, Outlet, useLocation } from "react-router-dom"

import { useGlobalStore } from "@/store"
import { Role } from "@/shared/types"
import { LOGIN } from "@/constants"
import { getUserRole } from "@/shared/utils"

interface IAuthGuardProps {
  allowedRoles: Role[]
  allowedUserOrgIds?: string[]
}

export const AuthGuard = ({ allowedRoles, allowedUserOrgIds = [] }: IAuthGuardProps) => {
  const location = useLocation()
  const [accessToken, user] = useGlobalStore((state) => [state.accessToken, state.user])

  let allowByOrgId = true

  if (allowedUserOrgIds.length > 0) {
    allowByOrgId = allowedUserOrgIds.includes(user.defaultOrganizationId)
  }

  if (accessToken && allowedRoles.includes(getUserRole(user)) && allowByOrgId) {
    return <Outlet />
  }

  return <Navigate to={LOGIN} state={{ from: location }} replace />
}
