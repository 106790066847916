import { isAxiosError } from "axios"
import { useQuery } from "@tanstack/react-query"

import { showToast } from "@/shared/utils"
import { getAllUrgentContactsFn } from "@/api/organizationApi"

export const useAllUrgentContacts = ({ id }: { id: string }) => {
  const isEnabled = !!id

  return useQuery(["allUrgentContacts", id], () => getAllUrgentContactsFn({ organizationId: id }), {
    cacheTime: isEnabled ? 5 * 60 * 1000 : 0,
    enabled: isEnabled,
    keepPreviousData: true,
    onError: (error) => {
      if (isAxiosError(error)) {
        showToast({ type: "error", text: error.response?.data.errorMessage || error.message })
      }
    },
  })
}
