import { memo } from "react"
import { Controller, useFormContext } from "react-hook-form"

import { WorkingTime, IOrganizationState } from "@/shared/types"
import { requiredPattern } from "@/shared/rules"
import { getEnumKeyByEnumValue } from "@/shared/utils"

import { FormSelect } from "@/shared/components"

interface IOrganizationFieldProps {
  index: number
  description?: string
  placeholder?: string
  borderless?: boolean
  hasError?: boolean
  error?: string
}

const timeList: WorkingTime[] = Object.values(WorkingTime).filter(
  (el) => el <= WorkingTime["01:00 PM"] && el !== WorkingTime["00:00 AM"],
)

export const StartField = memo(({ index, description, borderless, error }: IOrganizationFieldProps) => {
  const { control, register, clearErrors, watch } = useFormContext<IOrganizationState>()
  const { workingDays } = watch()

  return (
    <Controller
      name={`workingDays.${index}.start`}
      control={control}
      render={({ field }) => {
        return (
          <FormSelect
            {...register(field.name, {
              shouldUnregister: true,
              ...requiredPattern,
              validate: {
                matching: (value: string) => {
                  return (
                    (workingDays[index].start && value < workingDays[index].end) ||
                    "End time should exceed start time"
                  )
                },
              },
            })}
            {...field}
            isDisplayValueDifferent
            id={field.name}
            onValueChange={(value) => {
              field.onChange(value as string)
              clearErrors([`workingDays.${index}.end`])
            }}
            label={description}
            labelProps={{ hidden: true, required: true }}
            description={description}
            borderless={borderless}
            disabled={workingDays[index].start === WorkingTime["00:00 AM"]}
            value={{
              value: field.value,
              label: getEnumKeyByEnumValue(WorkingTime, field.value),
            }}
            options={timeList.map((i) => ({
              value: i,
              label: getEnumKeyByEnumValue(WorkingTime, i),
            }))}
            error={error}
          />
        )
      }}
    />
  )
})

StartField.displayName = "StartField"
