import { CSS } from "@/stitches/config"
import { ComponentProps } from "@/stitches/types"

import { useAccordionItemContext } from "./AccordionItem"
import { SAccordionPanel, SAccordionContent } from "./AccordionPanel.styles"

interface IAccordionPanelProps extends ComponentProps<typeof SAccordionPanel> {
  contentCss?: CSS
}

export const AccordionPanel = ({ children, contentCss, ...props }: IAccordionPanelProps) => {
  const { id, open } = useAccordionItemContext("AccordionPanel")
  const state = open ? "open" : "closed"

  return (
    <SAccordionPanel
      id={id}
      role="region"
      data-ui="accordion-panel"
      {...props}
      initial={state}
      animate={state}
      variants={{
        open: {
          height: "auto",
          y: 0,
          display: "block",
          transitionEnd: { overflow: "visible" },
        },
        closed: {
          height: 0,
          y: -8,
          overflow: "hidden",
          transitionEnd: { display: "none" },
        },
      }}
      transition={{ type: "spring", damping: 40, stiffness: 500 }}
    >
      <SAccordionContent css={contentCss}>{children}</SAccordionContent>
    </SAccordionPanel>
  )
}
