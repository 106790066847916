import { useCallback, useMemo, useRef, useState, SyntheticEvent, forwardRef, useEffect } from "react"

import { ComponentProps } from "@/stitches/types"

import { Copy, Flex, ICopyProps } from "@/shared/components"
import { IllustrationSpinner } from "@/shared/illustrations"

import { SButton, SButtonIcon, SButtonSpinner } from "./Button.styles"

interface IButtonProps extends ComponentProps<typeof SButton> {
  icon?: JSX.Element
  suffix?: JSX.Element

  id?: string
  className?: string

  href?: string
  target?: string
  type?: "button" | "submit"

  ariaLabel?: string
  dataTestid?: string

  disabled?: boolean
  noWrap?: boolean
  shouldPreventDefault?: boolean

  scale?: ICopyProps["scale"]
  copyProps?: ICopyProps

  loading?: boolean

  onBlur?: (event: SyntheticEvent) => void
  onFocus?: (event: SyntheticEvent) => void
  onClick?: (event: SyntheticEvent) => void
}

interface ButtonElementProps extends Partial<IButtonProps> {
  "aria-label"?: string
  "data-testid"?: string
  rel?: string
}

export const Button = forwardRef<HTMLButtonElement, IButtonProps>(
  (
    {
      children,
      icon,
      suffix,
      noWrap,
      shouldPreventDefault,
      ariaLabel,
      dataTestid,
      onClick,
      scale = 8,
      copyProps,
      ...props
    },
    ref,
  ) => {
    const { loading, css } = props

    const isLink = props.as === "a"

    const buttonRef = useRef<HTMLButtonElement | null>(null)
    const [buttonWidth, setButtonWidth] = useState<string | null>(null)

    useEffect(() => {
      if (buttonRef.current) {
        setButtonWidth(`${buttonRef.current.offsetWidth}px`)
      }
    }, [loading, buttonRef])

    const buttonProps = useMemo(() => {
      const commonButtonProps: ButtonElementProps = {
        "aria-label": ariaLabel,
        "data-testid": dataTestid,
      }

      if (isLink) {
        return {
          ...commonButtonProps,
          rel: props.target === "_blank" ? "noopener noreferrer" : "",
        }
      } else {
        return {
          ...commonButtonProps,
          type: props.type || "button",
        }
      }
    }, [ariaLabel, dataTestid, isLink, props.target, props.type])

    const handleClick = useCallback(
      (event: SyntheticEvent) => {
        if (shouldPreventDefault) {
          event.preventDefault()
        }

        onClick && onClick(event)
      },
      [shouldPreventDefault, onClick],
    )

    return (
      <SButton
        ref={(node: HTMLButtonElement | null) => {
          buttonRef.current = node
          if (typeof ref === "function") {
            ref(node)
          } else if (ref) {
            ;(ref as React.MutableRefObject<HTMLButtonElement | null>).current = node
          }
        }}
        {...buttonProps}
        {...props}
        onClick={handleClick}
        css={loading && buttonWidth ? { ...css, width: buttonWidth } : css}
      >
        <Flex align="center" css={{ position: "relative", gap: "$8" }}>
          {loading ? (
            <SButtonSpinner>
              <IllustrationSpinner action={props.action as string} />
            </SButtonSpinner>
          ) : (
            <>
              {icon && <SButtonIcon>{icon}</SButtonIcon>}
              {!noWrap ? (
                <Copy scale={scale} fontWeight="bold" {...copyProps}>
                  {children}
                </Copy>
              ) : (
                children
              )}
              {suffix && <SButtonIcon>{suffix}</SButtonIcon>}
            </>
          )}
        </Flex>
      </SButton>
    )
  },
)

Button.displayName = "Button"
