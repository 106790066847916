import { useFormContext } from "react-hook-form"

import { useModal, useModalActions } from "@/shared/hooks"
import { IDashboardFormData, ModalType } from "@/shared/types"
import { numericPatternWithDot } from "@/shared/rules"
import { formatNumericInput } from "@/shared/utils"

import { Box, Button, Flex, Spacer, Stack, TextFieldController, Title } from "@/shared/components"
import { GeneralModal } from "@/modals"

export const CarrierOrderPriceModal = () => {
  const { modalProps } = useModal(ModalType.CarrierOrderPrice)
  const { close } = useModalActions()
  const {
    watch,
    setValue,
    control,
    register,
    trigger,
    formState: { errors },
  } = useFormContext<IDashboardFormData>()
  const { orders, currentOrder } = watch()
  const { orderId, originalOrderId, rate } = currentOrder

  const handleCancelClick = () => {
    close(ModalType.CarrierOrderPrice)
  }

  const handleSaveClick = () => {
    const currentOrder = orders.find(
      (order) => order.orderId === orderId && order.originalOrderId === originalOrderId,
    )
    if (currentOrder) {
      currentOrder.rate = rate
      const newOrders = orders.map((order) => (order.orderId === orderId ? currentOrder : order))
      setValue("orders", newOrders)
      close(ModalType.CarrierOrderPrice)
    }
  }

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    const formattedValue = formatNumericInput(inputValue, ".", 2, 6)
    setValue("currentOrder.rate", formattedValue)
  }

  return (
    <GeneralModal
      {...modalProps}
      closeButton={{ onClick: handleCancelClick }}
      onInteractOutside={handleCancelClick}
      onEscapeKeyDown={handleCancelClick}
      containerCss={{
        maxHeight: 255,
      }}
      footer={
        <Flex justify="end" css={{ gap: "$16", paddingBottom: "$8" }}>
          <Button onClick={handleSaveClick}>Save</Button>
          <Button action="secondary" onClick={handleCancelClick}>
            Cancel
          </Button>
        </Flex>
      }
    >
      <Stack space={12} css={{ padding: "$64 $12 $24 $24", minHeight: 160 }}>
        <Title as="h3" scale={2} color="system-black">
          Enter price
        </Title>

        <Spacer size={4} />

        <Box css={{ position: "relative" }}>
          <TextFieldController<IDashboardFormData>
            name="currentOrder.rate"
            control={control}
            register={register}
            trigger={trigger}
            controlledValue={rate}
            controlledChange={handlePriceChange}
            rules={numericPatternWithDot}
            autoFocus={true}
            error={errors.currentOrder?.rate?.message}
            prefix="$"
          />
        </Box>
      </Stack>
    </GeneralModal>
  )
}
