export const phoneToText = (phone?: string) => {
  if (!phone) return ""

  const initialValue = phone.replace(/[() -]/g, "")

  return initialValue.length < 4
    ? initialValue
    : initialValue.length < 7
    ? `(${initialValue.substring(0, 3)}) ${initialValue.substring(3, 6)}`
    : `(${initialValue.substring(0, 3)}) ${initialValue.substring(3, 6)}-${initialValue.substring(6)}`
}
