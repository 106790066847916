import { Controller, FieldValues, Path, PathValue } from "react-hook-form"

import { FormType, IFormInputControllerProps } from "@/shared/types"
import { percentRules } from "@/shared/rules"

import { FormInput } from "@/shared/components"

export const PercentController = <FieldsType extends FieldValues>({
  name,
  control,
  error,
  labelProps,
  disabled,
  description,
  placeholder,
  borderless,
  register,
  trigger,
  onBlurHandler,
  onChangeHandler,
}: IFormInputControllerProps<FieldsType>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <FormInput
            {...(register &&
              register(field.name, {
                ...percentRules(!!labelProps?.required),
              }))}
            {...field}
            onBlur={(event) => {
              field.onChange(event?.target?.value.trim() as PathValue<FieldsType, Path<FieldsType>>)
              onBlurHandler && onBlurHandler()
              trigger && trigger(name)
            }}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (
                event.key === "e" ||
                event.key === "-" ||
                event.key === "+" ||
                event.key === "." ||
                event.key === ","
              ) {
                event.preventDefault()
              }
            }}
            onChange={(event) => {
              const value = event?.target?.value
              field.onChange(
                (value.substring(0, 1) === "0" ? "" : parseInt(value) > 100 ? 100 : value) as PathValue<
                  FieldsType,
                  Path<FieldsType>
                >,
              )
              onChangeHandler && onChangeHandler()
            }}
            id={name}
            label={description}
            labelProps={{ ...labelProps, hidden: true }}
            description={description}
            type={FormType.Number}
            title=""
            error={error}
            disabled={disabled}
            placeholder={placeholder}
            borderless={borderless}
          />
        )
      }}
    />
  )
}
