import { Box } from "@/shared/components"

import { FlsLanesTableRow } from "./FlsLanesTableRow"

export const FlsLanesTablePlaceholder = () => (
  <Box>
    <FlsLanesTableRow
      fromCity="From, city*"
      fromState="From, state*"
      toCity="To, city*"
      toState="To, state*"
      isTableHead
      isPlaceholder
    />
    {Array.from(new Array(3), (_, index) => index).map((v) => (
      <FlsLanesTableRow key={v} isPlaceholder />
    ))}
  </Box>
)
