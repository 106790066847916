import { Controller, useFormContext } from "react-hook-form"

import { IOrganizationState } from "@/shared/types"

import { Box, Flex, FormCheckbox, Grid, Stack, TextFieldController, Title } from "@/shared/components"
import { lengthRule, numericPattern } from "@/shared/rules"

export const Services = () => {
  const {
    control,
    register,
    formState: { errors },
    trigger,
  } = useFormContext<IOrganizationState>()

  return (
    <Box css={{ height: 420 }}>
      <Stack space={32}>
        <Title as="h2" scale={6} color="theme-b-n3">
          Compliance & Driver Services
        </Title>
        <Flex css={{ gap: "$32" }}>
          <Controller
            name="newCarrier.equipment.twic"
            control={control}
            render={({ field }) => {
              return (
                <FormCheckbox
                  {...register(field.name)}
                  label="TWIC"
                  onChange={(value) => field.onChange(value.currentTarget.checked)}
                />
              )
            }}
          />
          <Controller
            name="newCarrier.equipment.teamDrivers"
            control={control}
            render={({ field }) => {
              return (
                <FormCheckbox
                  {...register(field.name)}
                  label="Team Drivers"
                  onChange={(value) => field.onChange(value.currentTarget.checked)}
                />
              )
            }}
          />
          <Controller
            name="newCarrier.equipment.driverWork"
            control={control}
            render={({ field }) => {
              return (
                <FormCheckbox
                  {...register(field.name)}
                  label="Driver Work"
                  onChange={(value) => field.onChange(value.currentTarget.checked)}
                />
              )
            }}
          />
          <Controller
            name="newCarrier.equipment.tankerEndorsement"
            control={control}
            render={({ field }) => {
              return (
                <FormCheckbox
                  {...register(field.name)}
                  label="Tanker endorsement"
                  onChange={(value) => field.onChange(value.currentTarget.checked)}
                />
              )
            }}
          />
          <Controller
            name="newCarrier.equipment.dropTrailers"
            control={control}
            render={({ field }) => {
              return (
                <FormCheckbox
                  {...register(field.name)}
                  label="Drop trailers"
                  onChange={(value) => field.onChange(value.currentTarget.checked)}
                />
              )
            }}
          />
        </Flex>
        <Title as="h2" scale={6} color="theme-b-n3">
          Tracking & Commodity Services
        </Title>
        <Flex css={{ gap: "$32" }}>
          <Controller
            name="newCarrier.equipment.automatedTracking"
            control={control}
            render={({ field }) => {
              return (
                <FormCheckbox
                  {...register(field.name)}
                  label="Automated tracking"
                  onChange={(value) => field.onChange(value.currentTarget.checked)}
                />
              )
            }}
          />
          <Controller
            name="newCarrier.equipment.highValue"
            control={control}
            render={({ field }) => {
              return (
                <FormCheckbox
                  {...register(field.name)}
                  label="High value cargo insurance"
                  onChange={(value) => field.onChange(value.currentTarget.checked)}
                />
              )
            }}
          />
          <Controller
            name="newCarrier.equipment.hazmat"
            control={control}
            render={({ field }) => {
              return (
                <FormCheckbox
                  {...register(field.name)}
                  label="Hazmat"
                  onChange={(value) => field.onChange(value.currentTarget.checked)}
                />
              )
            }}
          />
        </Flex>
        <Grid columns="1fr 1fr 1fr 1fr" columnGap={16}>
          <TextFieldController<IOrganizationState>
            name="newCarrier.equipment.maxCargo"
            control={control}
            register={register}
            trigger={trigger}
            description="Max cargo value, $"
            rules={{ ...lengthRule("Value", 9, 0), ...numericPattern }}
            error={errors.newCarrier?.equipment?.maxCargo?.message}
          />
        </Grid>
      </Stack>
    </Box>
  )
}
