import { Controller, useFormContext } from "react-hook-form"

import { CarrierSourceType, IOrganizationState } from "@/shared/types"
import { lengthRule } from "@/shared/rules"
import { phoneToText } from "@/shared/utils"

import {
  Box,
  ButtonIcon,
  Copy,
  EmailController,
  ErrorLineWithMessage,
  Flex,
  FormCheckbox,
  Grid,
  GridItem,
  PhoneController,
  Redacted,
  TextFieldController,
} from "@/shared/components"
import { IconBin, IconCross, IconTick } from "@/shared/icons"

interface IContactsTableRowProps {
  id?: string
  index?: number
  name?: string
  role?: string
  phone?: string
  email?: string
  tenderContact?: boolean | string
  isTableHead?: boolean
  isPlaceholder?: boolean
  isViewMode?: boolean
  sourceType?: CarrierSourceType
}

export const ContactsTableRow = ({
  id,
  index = 0,
  name,
  role,
  phone,
  email,
  tenderContact,
  isTableHead,
  isPlaceholder,
  isViewMode,
  sourceType,
}: IContactsTableRowProps) => {
  const {
    control,
    register,
    trigger,
    formState: { errors },
    watch,
  } = useFormContext<IOrganizationState>()
  const { carriers, newCarrier } = watch()

  const isDisabled = sourceType === CarrierSourceType.HIGHWAY

  return (
    <Grid
      columns="1fr 0.7fr 0.6fr 1.7fr 1fr"
      align="center"
      css={{
        minHeight: isTableHead ? "$48" : "$64",
        color: "$theme-b-n3",
        borderBottom: isTableHead ? "1px solid $theme-n3-n7" : "none",
      }}
    >
      <GridItem css={{ paddingX: "$16" }}>
        {isPlaceholder && !isTableHead ? (
          <Redacted height="$24" width="100px" text animated />
        ) : isTableHead || isViewMode ? (
          <Copy
            scale={isTableHead ? 8 : 10}
            fontWeight={isTableHead ? "bold" : "regular"}
            uppercase={isTableHead}
          >
            {name || "-"}
          </Copy>
        ) : (
          <Box css={{ position: "relative" }}>
            <TextFieldController<IOrganizationState>
              name={`newCarrier.contacts.${index}.name`}
              control={control}
              register={register}
              trigger={trigger}
              labelProps={{ required: true, hidden: true }}
              disabled={isDisabled}
              rules={lengthRule("Name", 40)}
              placeholder={isDisabled ? "" : "Enter a name"}
              borderless
            />
            <ErrorLineWithMessage
              errorId={`newCarrier.contacts.${index}.name`}
              errorMessage={errors.newCarrier?.contacts?.[index]?.name?.message}
            />
          </Box>
        )}
      </GridItem>
      <GridItem css={{ paddingX: "$16" }}>
        {isPlaceholder && !isTableHead ? (
          <Redacted height="$24" width="140px" text animated />
        ) : isTableHead || isViewMode ? (
          <Copy
            scale={isTableHead ? 8 : 10}
            fontWeight={isTableHead ? "bold" : "regular"}
            uppercase={isTableHead}
          >
            {role || "-"}
          </Copy>
        ) : (
          <TextFieldController<IOrganizationState>
            name={`newCarrier.contacts.${index}.role`}
            control={control}
            register={register}
            trigger={trigger}
            disabled={isDisabled}
            rules={lengthRule("Role", 40, 0)}
            error={errors.newCarrier?.contacts?.[index]?.role?.message}
            placeholder={isDisabled ? "" : "Enter a role, e.g. Dispatcher"}
            borderless
          />
        )}
      </GridItem>
      <GridItem css={{ paddingX: "$16" }}>
        {isPlaceholder && !isTableHead ? (
          <Redacted height="$24" width="100px" text animated />
        ) : isTableHead || isViewMode ? (
          <Copy
            scale={isTableHead ? 8 : 10}
            fontWeight={isTableHead ? "bold" : "regular"}
            uppercase={isTableHead}
          >
            {isTableHead ? phone : phoneToText(phone) || "-"}
          </Copy>
        ) : (
          <Box css={{ position: "relative" }}>
            <PhoneController<IOrganizationState>
              name={`newCarrier.contacts.${index}.phone`}
              control={control}
              register={register}
              trigger={trigger}
              labelProps={{ required: true }}
              placeholder={isDisabled ? "" : "Enter a phone, e.g. (123) 456-7890"}
              borderless
              disabled={isDisabled}
            />
            <ErrorLineWithMessage
              errorId={`newCarrier.contacts.${index}.phone`}
              errorMessage={errors.newCarrier?.contacts?.[index]?.phone?.message}
            />
          </Box>
        )}
      </GridItem>
      <GridItem css={{ paddingX: "$16" }}>
        {isPlaceholder && !isTableHead ? (
          <Redacted height="$24" width="100px" text animated />
        ) : isTableHead || isViewMode ? (
          <Copy
            scale={isTableHead ? 8 : 10}
            fontWeight={isTableHead ? "bold" : "regular"}
            uppercase={isTableHead}
          >
            {email || "-"}
          </Copy>
        ) : (
          <Box css={{ position: "relative" }}>
            <EmailController<IOrganizationState>
              name={`newCarrier.contacts.${index}.email`}
              control={control}
              register={register}
              trigger={trigger}
              labelProps={{ required: true }}
              placeholder={isDisabled ? "" : "Enter an email"}
              borderless
              disabled={isDisabled}
              rules={{
                validate: {
                  isUnique: (value: string) =>
                    !carriers
                      .filter((i) => i.id !== newCarrier.id)
                      .some((carrier) =>
                        carrier.contacts.some((carrierContact) => carrierContact.email === value),
                      ) || "Email should be unique",
                },
              }}
            />
            <ErrorLineWithMessage
              errorId={`newCarrier.contacts.${index}.email`}
              errorMessage={errors.newCarrier?.contacts?.[index]?.email?.message}
            />
          </Box>
        )}
      </GridItem>
      <GridItem css={{ paddingX: "$16" }}>
        {isPlaceholder && !isTableHead ? (
          <Redacted height="$24" width="100px" text animated />
        ) : isTableHead || isViewMode ? (
          <Copy
            scale={isTableHead ? 8 : 10}
            fontWeight={isTableHead ? "bold" : "regular"}
            uppercase={isTableHead}
          >
            {isTableHead ? (
              tenderContact
            ) : tenderContact ? (
              <IconTick css={{ color: "$special-success" }} />
            ) : (
              <IconCross css={{ color: "$special-error" }} />
            )}
          </Copy>
        ) : (
          <Flex align="center" justify="between" css={{ gap: "$16" }}>
            <Controller
              name={`newCarrier.contacts.${index}.tenderContact`}
              control={control}
              render={({ field }) => {
                return <FormCheckbox {...register(field.name)} label="" />
              }}
            />
            {!isViewMode ? <RemoveButton id={id || ""} isHighway={isDisabled} /> : null}
          </Flex>
        )}
      </GridItem>
    </Grid>
  )
}

const RemoveButton = ({ id, isHighway = false }: { id: string; isHighway?: boolean }) => {
  const { watch, setValue, clearErrors } = useFormContext<IOrganizationState>()
  const { newCarrier } = watch()
  const { contacts, lanes } = newCarrier

  const onDeleteClick = (e: React.SyntheticEvent, id: string) => {
    e.stopPropagation()

    clearErrors("newCarrier")
    setValue(
      "newCarrier.contacts",
      contacts.filter((i) => i.id !== id),
      { shouldDirty: true, shouldValidate: true },
    )
    // INFO: Removing this contact from lanes contacts
    setValue(
      "newCarrier.lanes",
      lanes.map((lane) => ({
        ...lane,
        contacts: lane.contacts.filter((i) => i.id !== id),
      })),
      { shouldDirty: true },
    )
  }

  return (
    <ButtonIcon
      type="button"
      ariaLabel={`Remove contact ${id}`}
      icon={<IconBin />}
      onClick={(e) => onDeleteClick(e, id)}
      inputIcon
      disabled={isHighway}
    />
  )
}
