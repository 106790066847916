import { useCallback } from "react"
import { useNavigate } from "react-router-dom"

import { useModal, useModalActions } from "@/shared/hooks"
import { useDeleteOrganization } from "@/management/hooks"
import { ModalType } from "@/shared/types"
import { MANAGEMENT } from "@/constants"

import { Button, Copy, Flex, Stack, Title, useGlobalSettings } from "@/shared/components"
import { GeneralModal } from "@/modals"

export const DeleteOrganizationModal = () => {
  const { modalProps } = useModal(ModalType.DeleteOrganizationModal)
  const { close } = useModalActions()
  const { organizationId } = useGlobalSettings("DeleteOrganizationModal")
  const navigate = useNavigate()
  const { mutate: deleteOrganization } = useDeleteOrganization(organizationId)

  const handleContinueClick = useCallback(() => {
    close(ModalType.DeleteOrganizationModal)
    deleteOrganization()
    navigate(`${MANAGEMENT}`)
  }, [close, deleteOrganization, navigate])

  return (
    <GeneralModal
      {...modalProps}
      footer={
        <Flex justify="end" css={{ gap: "$16", paddingBottom: "$8" }}>
          <Button action="destructive" onClick={handleContinueClick}>
            Delete org
          </Button>
          <Button action="secondary" onClick={() => close(ModalType.DeleteOrganizationModal)}>
            Cancel
          </Button>
        </Flex>
      }
    >
      <Stack space={12} css={{ padding: "$64 $24 $0" }}>
        <Title as="h3" scale={2} color="system-black">
          Do you want to delete the organization?
        </Title>
        <Copy scale={10} color="theme-n6-n5" css={{ paddingBottom: "$8" }}>
          In case you press Delete org, the organization and its child organizations and users will be deleted
          permanently
        </Copy>
      </Stack>
    </GeneralModal>
  )
}
