import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"

import { RouteParams, IOrganizationState, initialOrganizationState } from "@/shared/types"
import { createContext, showToast } from "@/shared/utils"
import { MANAGEMENT } from "@/constants"
import { useUpdateOrganizationSla, useOrganizationById, useOrganizationSla } from "@/management/hooks"

import { Breadcrumbs, Title, Spacer, Redacted, Flex, Spinner } from "@/shared/components"
import { EditSlaButton, SlaSettings } from "@/management/components"

type OrganizationFormContextValue = {
  slaErrors: string[]
  setSlaErrors: React.Dispatch<React.SetStateAction<string[]>>
}

export const [OrganizationFormProvider, useOrganizationForm] =
  createContext<OrganizationFormContextValue>("organizationForm")

export const EditSlaSettingsContainer = () => {
  const { organizationId } = useParams<keyof RouteParams>() as RouteParams
  const [slaErrors, setSlaErrors] = useState<string[]>([])
  const navigate = useNavigate()

  const { data: slaData, isLoading: organizationSlaLoading } = useOrganizationSla(organizationId)
  const { data: organization, isLoading: organizationLoading } = useOrganizationById(organizationId)
  const { mutateAsync: updateOrganizationSla, isLoading: isUpdateOrganizationSlaLoading } =
    useUpdateOrganizationSla()

  const methods = useForm<IOrganizationState>({
    mode: "all",
    defaultValues: initialOrganizationState,
  })

  const {
    setValue,
    getValues,
    reset,
    handleSubmit,
    formState: { dirtyFields },
  } = methods

  const isLoading = organizationSlaLoading || organizationLoading || isUpdateOrganizationSlaLoading

  useEffect(() => {
    if (!organization && !slaData && !isLoading) {
      navigate(`${MANAGEMENT}`)
    }
  }, [navigate, organization, slaData, isLoading])

  useEffect(() => {
    if (slaData) {
      setValue("sla", slaData, { shouldDirty: true })

      setTimeout(() => {
        reset(getValues())
      }, 0)
    }
  }, [setValue, slaData, reset, getValues])

  const onSubmitHandler: SubmitHandler<IOrganizationState> = async () => {
    if (dirtyFields.sla) {
      await updateOrganizationSla({ orgId: organizationId, data: getValues("sla") })
      showToast({ type: "success", text: "Saved successfully" })
      navigate(`${MANAGEMENT}/organization/${organizationId}`)
    }
  }

  return (
    <OrganizationFormProvider slaErrors={slaErrors} setSlaErrors={setSlaErrors}>
      {isLoading || !organization ? (
        <>
          <Redacted height="$24" width="300px" text animated />
          <Spacer size={20} />
        </>
      ) : (
        <Breadcrumbs
          additionalRoutes={[
            {
              url: `${MANAGEMENT}/organization/${organization.id}`,
              label: organization.name,
            },
          ]}
          routeParam="Edit SLA"
        />
      )}
      <Title as="h1" scale={2} color="system-black">
        Edit SLA
      </Title>
      <Spacer size={48} />
      {isLoading ? (
        <Flex align="center" justify="center" css={{ height: 400 }}>
          <Spinner />
        </Flex>
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmitHandler)} noValidate autoComplete="off">
            <SlaSettings />
            <EditSlaButton isLoading={isLoading} />
          </form>
        </FormProvider>
      )}
    </OrganizationFormProvider>
  )
}
