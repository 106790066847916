import { useFormContext } from "react-hook-form"

import { RecoveryInput } from "@/shared/types"

import { Button, Copy, EmailController, Flex, Spacer, Stack, Title } from "@/shared/components"

export const CarrierForm = ({ isLoading }: { isLoading: boolean }) => {
  const {
    control,
    formState: { errors },
    watch,
    register,
  } = useFormContext<RecoveryInput>()

  const { email } = watch()

  return (
    <Flex align="start" justify="center" direction="column">
      <Title as="h1" color="system-black">
        Carrier dashboard access
      </Title>
      <Spacer size={16} />
      <Copy scale={8} color="neutrals-5">
        Please enter your email address
      </Copy>
      <Spacer size={48} />
      <Stack space={32}>
        <EmailController<RecoveryInput>
          control={control}
          register={register}
          name="email"
          placeholder="Enter your email"
          error={errors.email?.message}
        />
        <Button type="submit" loading={isLoading} disabled={!email || !!errors.email?.message}>
          Confirm
        </Button>
      </Stack>
    </Flex>
  )
}
