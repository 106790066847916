import { memo, useCallback, useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { useDebounce } from "use-debounce"

import { escapeKeyDown, spaceAndEnterKeyDown } from "@/shared/utils"
import { useOrganizationCarriersFull } from "@/management/hooks"
import { IOrganizationCarrier, RouteParams, ICarrierStats, FilterCarrierLinesType } from "@/shared/types"

import {
  Box,
  Button,
  ButtonIcon,
  Copy,
  Flex,
  FormCheckbox,
  FormInput,
  Pill,
  Popover,
  PopoverAnchor,
  PopoverContent,
  ResponseContentItem,
  Stack,
  Divider,
} from "@/shared/components"
import { IconChevronTop, IconChevronDown, IconCross, IconSearch } from "@/shared/icons"
import { IllustrationSpinner } from "@/shared/illustrations"

import { ViewSelectedSPopoverAnchorButton } from "@/shared/styles/SPopoverAnchorButton"

interface IItemsSelectProps {
  carrierStats: ICarrierStats[]
  carriersIds: string[] | null
  setCarriersIds: React.Dispatch<React.SetStateAction<string[] | null>>
  laneData: FilterCarrierLinesType[]
}

const transformCarrierToStats = (carrier: IOrganizationCarrier): ICarrierStats => {
  return {
    carrierId: carrier.id || "",
    carrierName: carrier.name,
    offeredTenders: "",
    acceptedTenders: "",
    rejectedTenders: "",
    offeredRfqs: "",
    acceptedRfqs: "",
    rejectedRfqs: "",
    wonRfqs: "",
    communicationType: "",
  }
}

export const CarrierSelect = memo(
  ({ carrierStats, laneData, carriersIds, setCarriersIds }: IItemsSelectProps) => {
    const { organizationId } = useParams<keyof RouteParams>() as RouteParams

    const [inputValue, setInputValue] = useState<string>("")
    const pillRef = useRef<HTMLButtonElement>(null)
    const [keyword] = useDebounce(inputValue, 500)

    const [isOpen, setIsOpen] = useState(false)
    const triggerRef = useRef<HTMLButtonElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    const clearButtonRef = useRef<HTMLButtonElement>(null)
    const isTriggerClick = (event: Event) => event.composedPath().includes(triggerRef.current as EventTarget)
    const { fetchStatus: organizationCarriersFetchStatus, data: carriersData } = useOrganizationCarriersFull({
      orgId: organizationId,
      name: encodeURIComponent(keyword),
      size: laneData.length > 0 ? 50 : 7,
      disableCache: true,
      requestBody: laneData,
    })

    const [selectedCarriers, setSelectedCarriers] = useState<ICarrierStats[]>([])

    const isSearchLoading = organizationCarriersFetchStatus === "fetching"

    const handleCancelClick = () => {
      setCarriersIds([])
      setSelectedCarriers([])
      setIsOpen(false)
      setInputValue("")
    }

    const handleApplyClick = (search?: boolean) => {
      // INFO: this for apply filter with lanes and carriers
      if (laneData.length > 0 && carriersData?.content && !inputValue) {
        setCarriersIds(
          selectedCarriers
            .filter(
              (item) =>
                item.carrierId == (carriersData?.content.find((el) => el.id == item.carrierId)?.id || ""),
            )
            .map((el) => el.carrierId),
        )
      }

      // INFO: this for select carriers in search
      if (search) {
        setCarriersIds(selectedCarriers.map((el) => el.carrierId))
        setInputValue("")
        setIsOpen(false)
      }

      // INFO: this condition for default state, where carrierIds = null; we do setSelectedCarriers and setCarriersIds
      // INFO: to set just topWon carriers
      if (laneData.length === 0 && selectedCarriers.length === 0 && !carriersIds) {
        setSelectedCarriers(carrierStats)
        setCarriersIds(carrierStats.map((el) => el.carrierId))
      }
      // INFO: this for select carriers without lanes
      if (laneData.length === 0 && selectedCarriers.length > 0)
        setCarriersIds(selectedCarriers.map((el) => el.carrierId))
    }

    const handleChange = useCallback(
      (event: React.FormEvent<HTMLInputElement>) => {
        if (!event.currentTarget.checked) {
          const newArray = selectedCarriers?.filter((i) => i.carrierId != event.currentTarget.value) || []
          setSelectedCarriers(newArray)
        } else {
          if (carriersData?.content) {
            const newCarrier = carriersData.content.find((i) => i.id == event.currentTarget.value)
            if (newCarrier) {
              const newArray = [...(selectedCarriers || []), transformCarrierToStats(newCarrier)]
              setSelectedCarriers(newArray)
            }
          }
        }
      },
      [carriersData, setSelectedCarriers, selectedCarriers],
    )

    useEffect(() => {
      if (laneData.length > 0 && carriersData?.content) {
        handleApplyClick()
      }
      if (carrierStats.length && laneData.length === 0) {
        handleApplyClick()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carrierStats, laneData, carriersData])

    return (
      <Popover open={isOpen}>
        <PopoverAnchor
          ref={containerRef}
          asChild
          onKeyDown={(e: { key: string }) => escapeKeyDown(e.key) && setIsOpen(false)}
        >
          <ViewSelectedSPopoverAnchorButton
            ref={triggerRef}
            type="button"
            onClick={(event) => {
              if (!pillRef?.current?.contains(event?.target as Node)) {
                setIsOpen(!isOpen)
              }
            }}
            active={isOpen}
          >
            <Copy fontWeight="medium">Carrier name</Copy>
            <Flex css={{ gap: "$12" }}>
              {selectedCarriers.length > 0 ? (
                <Pill
                  as="span"
                  ref={pillRef}
                  suffix={<IconCross onClick={() => handleCancelClick()} />}
                  size="small"
                  tabIndex={0}
                  onKeyDown={(e: { key: string }) => spaceAndEnterKeyDown(e.key) && handleCancelClick}
                  css={{ color: "$theme-b-n11" }}
                  data-testid="carrier-filter"
                >
                  {`${selectedCarriers.length} selected`}
                </Pill>
              ) : null}

              <Flex align="center">
                {isOpen ? (
                  <IconChevronTop onClick={() => setIsOpen(!isOpen)} size="xs" />
                ) : (
                  <IconChevronDown onClick={() => setIsOpen(!isOpen)} size="xs" />
                )}
              </Flex>
            </Flex>
          </ViewSelectedSPopoverAnchorButton>
        </PopoverAnchor>
        <PopoverContent
          close={() => {
            setIsOpen(false)
            setInputValue("")
          }}
          align="start"
          css={{
            width: 295,
            borderRadius: "$8",
            overflow: "hidden",
            keyboardFocus: {
              outline: "1px solid $brand-yellow-primary",
            },
          }}
          alignOffset={-1}
          onInteractOutside={(event) => {
            if (isTriggerClick(event)) {
              return
            }
            setIsOpen(false)
            setInputValue("")
            return
          }}
          onOpenAutoFocus={(event) => {
            event.preventDefault()
          }}
        >
          <Stack space={0} css={{ paddingX: 0 }}>
            <Box css={{ padding: "$12 $16" }}>
              <FormInput
                id="Search field carriers"
                value={inputValue}
                label="Search for carriers"
                labelProps={{ hidden: true }}
                placeholder="Search for carriers"
                onChange={(event) => {
                  setInputValue(event.target.value)
                }}
                prefix={<IconSearch />}
                suffix={
                  inputValue && (
                    <ButtonIcon
                      ref={clearButtonRef}
                      icon={<IconCross />}
                      ariaLabel="Clear button"
                      onClick={() => {
                        setInputValue("")
                      }}
                      inputIcon
                    />
                  )
                }
                autoComplete="new-password"
              />
            </Box>

            <Box css={{ height: "max-content", maxHeight: 320, overflow: "auto" }}>
              {selectedCarriers.length > 0 ? (
                !keyword ? (
                  selectedCarriers.length === 0 ? (
                    <Box css={{ padding: "$16" }}>
                      <Copy color="theme-b-n3">Not found</Copy>
                    </Box>
                  ) : (
                    selectedCarriers.map((item) => (
                      <ResponseContentItem key={item.carrierId}>
                        <FormCheckbox
                          value={item.carrierId}
                          onChange={handleChange}
                          name={item.carrierId}
                          id={item.carrierId}
                          label={item.carrierName}
                          disabled={
                            selectedCarriers.length >= 50 &&
                            !selectedCarriers?.some((i) => i.carrierId === item.carrierId)
                          }
                          checked={selectedCarriers?.some((i) => i.carrierId === item.carrierId)}
                        />
                      </ResponseContentItem>
                    ))
                  )
                ) : null
              ) : (
                <Flex css={{ padding: "$16", height: "$56" }}>No selected carriers</Flex>
              )}
              {keyword && !isSearchLoading ? (
                (carriersData?.content || []).length === 0 ? (
                  <Box css={{ padding: "$16" }}>
                    <Copy color="theme-b-n3">No matches found</Copy>
                  </Box>
                ) : (
                  (carriersData?.content || []).map((item) => (
                    <ResponseContentItem key={item.id}>
                      <FormCheckbox
                        value={item.id}
                        onChange={handleChange}
                        name={item.id}
                        id={item.id}
                        label={item.name}
                        disabled={
                          selectedCarriers.length >= 50 &&
                          !selectedCarriers?.some((i) => i.carrierId === item.id)
                        }
                        checked={selectedCarriers?.some((i) => i.carrierId === item.id)}
                      />
                    </ResponseContentItem>
                  ))
                )
              ) : null}
              {keyword && isSearchLoading && (
                <Flex align="center" css={{ padding: 16 }}>
                  <IllustrationSpinner />
                </Flex>
              )}
            </Box>
            <Divider />
            <Flex css={{ padding: "$16" }} justify="end">
              <Button
                action="primary"
                type="button"
                scale={10}
                size="medium"
                disabled={selectedCarriers.length === 0}
                onClick={() => handleApplyClick(true)}
              >
                Apply
              </Button>
            </Flex>
          </Stack>
        </PopoverContent>
      </Popover>
    )
  },
)

CarrierSelect.displayName = "CarrierSelect"
