import { isAxiosError } from "axios"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { showToast } from "@/shared/utils"
import { postAcceptOrder } from "@/api/dashboardApi"
import { useGlobalStore } from "@/store"

export const useAcceptOrder = (originalOrderId: string, orderId: string, rate: string) => {
  const queryClient = useQueryClient()
  const [carrierDashboardToken] = useGlobalStore((state) => [state.carrierDashboardToken])

  return useMutation(() => postAcceptOrder(carrierDashboardToken, orderId, rate), {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["dashboardData"],
      })
      showToast({ type: "success", text: `Order #${originalOrderId} was accepted.` })
    },
    onError: (error) => {
      if (isAxiosError(error)) {
        showToast({ type: "error", text: `Order #${originalOrderId} hasn’t been accepted.` })
      }
    },
  })
}
