import React, { useCallback, useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"

import { useModal, useModalActions } from "@/shared/hooks"
import { ISla, ModalType, SlaField, IOrganizationState } from "@/shared/types"
import { excludedAddressFields, findSlaAdditionalItem, slaFieldToText } from "@/shared/utils"

import { Button, Copy, Flex, FormCheckbox, Stack, Title } from "@/shared/components"
import { GeneralModal } from "@/modals"
import { useOrganizationForm } from "@/management/components"

const slaTypes = [
  SlaField.ExcludedCarriers,
  SlaField.Delivery,
  SlaField.Tender,
  SlaField.Tracking,
  SlaField.Unloading,
  SlaField.Cargo,
  SlaField.Haul,
  SlaField.Brokers,
]

export const SlaTypeModal = () => {
  const { modalProps } = useModal(ModalType.SlaTypeModal)
  const { close } = useModalActions()
  const { watch, setValue } = useFormContext<IOrganizationState>()
  const { sla } = watch()
  const [types, setTypes] = useState<ISla[]>(sla)
  const slaKeysArr = types.map((i) => i.name)
  const { setSlaErrors } = useOrganizationForm("SlaTypeModal")

  const handleApplyClick = useCallback(() => {
    close(ModalType.SlaTypeModal)
    setValue("sla", types, {
      shouldDirty: true,
    })
    setSlaErrors([])
  }, [close, setValue, types, setSlaErrors])

  const handleCancelClick = useCallback(() => {
    close(ModalType.SlaTypeModal)
    setTypes(sla)
  }, [close, sla])

  const handleChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const { checked, value } = event.currentTarget

      if (!checked) {
        if (excludedAddressFields.includes(value as SlaField)) {
          const newArray = types.filter((i) => !excludedAddressFields.includes(i.name))
          return setTypes(newArray)
        }

        const newArray = types.filter((i) => i.name !== (value as SlaField))
        return setTypes(newArray)
      }

      const newArray = [...types, { name: value as SlaField, content: null }]
      return setTypes(newArray)
    },
    [types],
  )

  useEffect(() => {
    setTypes(sla)
  }, [sla])

  return (
    <GeneralModal
      {...modalProps}
      closeButton={{ onClick: handleCancelClick }}
      onInteractOutside={handleCancelClick}
      onEscapeKeyDown={handleCancelClick}
      footer={
        <Flex justify="end" css={{ gap: "$16" }}>
          <Button onClick={handleApplyClick}>Apply</Button>
          <Button action="secondary" onClick={handleCancelClick}>
            Cancel
          </Button>
        </Flex>
      }
    >
      <Stack space={12} css={{ padding: "$64 $24 $24" }}>
        <Title as="h3" scale={2} color="system-black">
          View settings
        </Title>
        <Copy color="theme-n6-n5">In case you unselect any SLA, all the data inside will be lost</Copy>
      </Stack>
      <Stack space={24} css={{ padding: "$12 $24 $8" }}>
        {slaTypes.map((item) => (
          <FormCheckbox
            key={item}
            value={item}
            onChange={handleChange}
            name={item}
            id={item}
            label={slaFieldToText(item)}
            checked={
              slaKeysArr.includes(item) ||
              (item === SlaField.ExcludedCarriers && !!findSlaAdditionalItem(slaKeysArr))
            }
          />
        ))}
      </Stack>
    </GeneralModal>
  )
}
