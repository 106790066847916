import { memo, useLayoutEffect, useRef } from "react"
import * as am5 from "@amcharts/amcharts5"
import { Sankey } from "@amcharts/amcharts5/flow"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"

import { ISankeyLink, ISankeyNode } from "@/shared/types"
import { scrollTo } from "@/shared/utils"

import { Box, Flex } from "@/shared/components"

interface ISankeyGraphProps {
  links: ISankeyLink[]
  nodes: ISankeyNode[]
  setSelectedLinkDetails: (v: string) => void
}

export const SankeyGraph = memo(
  ({ links, nodes, setSelectedLinkDetails }: ISankeyGraphProps) => {
    const chartRef = useRef<Sankey | null>(null)

    useLayoutEffect(() => {
      const root = am5.Root.new("chartdiv")
      root.setThemes([am5themes_Animated.new(root)])

      // INFO: Create series
      const chart = root.container.children.push(
        Sankey.new(root, {
          sourceIdField: "from",
          targetIdField: "to",
          valueField: "value",
          nodeAlign: "justify",
          nodePadding: 50,
          // minSize: 0.01,
          // linkTension: 0.4,
          paddingRight: 0,
          paddingLeft: 0,
          paddingTop: 100,
          paddingBottom: 100,
          linkSort: null,
        }),
      )

      // INFO: Clear logo
      if (chart.root._logo) {
        chart.root._logo?.dispose()
      }

      // INFO: Assign names to the node fields
      chart.nodes.setAll({
        idField: "id",
        nameField: "name",
        fillField: "color",
      })

      // INFO: Remove toggle event
      chart.nodes.nodes.template.setAll({
        toggleKey: "none",
      })

      // INFO: Set label props
      chart.nodes.labels.template.setAll({
        x: am5.percent(0),
        centerX: am5.percent(0),
        y: am5.percent(0),
        centerY: am5.percent(100),
        paddingLeft: 0,
        paddingRight: 0,

        fontSize: 14,
        fontWeight: "500",
        maxWidth: 150,
      })

      // INFO: Set link props and clear tooltips
      chart.links.template.setAll({
        tooltipText: "",
        // forceInactive: true,
        // controlPointDistance: 0.1,
      })

      // INFO: Set active link style
      chart.links.template.states.create("active", {
        strokeOpacity: 1,
        fillOpacity: 0.5,
      })

      // INFO: Set forceInactive link prop based on details availability
      chart.links.template.adapters.add("forceInactive", (v, target) => {
        const data = target.dataItem?.dataContext as { details?: string }
        return !data?.details || v
      })

      // INFO: Set label position
      chart.nodes.labels.template.adapters.add("centerX", (v, target) => {
        const labelSettings = target.dataItem?._settings as { incomingLinks: []; outgoingLinks: [] }

        // if (labelData.id === SankeyNodeKey.WINNERS || labelData.id === SankeyNodeKey.NO_WINNERS) {
        //   return am5.percent(95)
        // }

        if (!labelSettings.incomingLinks) {
          return am5.percent(0)
        } else if (!labelSettings.outgoingLinks) {
          return am5.percent(100)
        }
        return am5.percent(0) || v
      })

      chartRef.current = chart

      return () => {
        root.dispose()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useLayoutEffect(() => {
      if (chartRef.current) {
        const chart = chartRef.current

        // INFO: Set a click listener for the available link
        chart.links.template.events.on("click", (e) => {
          const linkData = e.target.dataItem?.dataContext as { details: string }
          if (linkData.details) {
            // setSelectedLinkDetails(linkData.details !== selectedLinkDetails ? linkData.details : "")
            setSelectedLinkDetails(linkData.details || "")
            chart.links.each((v) => v.set("active", e.target.uid === v.uid))
            setTimeout(() => {
              scrollTo({ selector: "#sankeyTable" })
            }, 100)
          }
        })
      }
    }, [setSelectedLinkDetails])

    useLayoutEffect(() => {
      if (chartRef.current) {
        const chart = chartRef.current

        // INFO: Set chart data
        chart.nodes.data.setAll(nodes)
        chart.data.setAll(links)
      }
    }, [links, nodes])

    return (
      <Box css={{ overflowX: "auto" }}>
        <Flex css={{ height: 600, minWidth: 1400 }}>
          <div id="chartdiv" style={{ width: "100%", height: "auto" }} />
        </Flex>
      </Box>
    )
  },
  (prev, next) =>
    JSON.stringify(prev.links) === JSON.stringify(next.links) &&
    JSON.stringify(prev.nodes) === JSON.stringify(next.nodes),
)

SankeyGraph.displayName = "SankeyGraph"
