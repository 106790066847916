import { memo } from "react"
import { Controller, useFormContext } from "react-hook-form"

import { Weekdays, IOrganizationState } from "@/shared/types"
import { requiredPattern } from "@/shared/rules"

import { FormSelect } from "@/shared/components"

const weekDays: Weekdays[] = Object.values(Weekdays)

interface IOrganizationFieldProps {
  index: number
  description?: string
  placeholder?: string
  borderless?: boolean
  hasError?: boolean
  error?: string
}

export const DayField = memo(({ index, description, borderless, error }: IOrganizationFieldProps) => {
  const { control, register, watch } = useFormContext<IOrganizationState>()
  const { workingDays } = watch()
  const weekDaysList: Weekdays[] = weekDays.filter((i) => !workingDays.find((el) => el.day === i))

  return (
    <Controller
      name={`workingDays.${index}.day`}
      control={control}
      render={({ field }) => {
        return (
          <FormSelect
            {...register(field.name, {
              shouldUnregister: true,
              ...requiredPattern,
            })}
            {...field}
            id={field.name}
            onValueChange={(value) => field.onChange(value as Weekdays)}
            label={description}
            labelProps={{ hidden: true, required: true }}
            description={description}
            borderless={borderless}
            options={weekDaysList}
            error={error}
          />
        )
      }}
    />
  )
})

DayField.displayName = "DayField"
