import { FlsStatusType } from "@/shared/types"

import { Copy, Grid, GridItem, OrderStatusLabel, Redacted, Tooltip } from "@/shared/components"

interface IOrderTableRowProps {
  orderId?: string
  createdAt?: string
  status?: string
  customerId?: string
  shipperAddress?: string
  consigneeAddress?: string
  isTableHead?: boolean
  isPlaceholder?: boolean
}

interface TableItemProps {
  children: React.ReactNode
  isTableHead?: boolean
  isPlaceholder?: boolean
  tooltip?: boolean
  label: string
}

const TableItem = ({ children, isTableHead, isPlaceholder, tooltip, label }: TableItemProps) => (
  <GridItem css={{ paddingX: "$24" }}>
    {isPlaceholder && !isTableHead ? (
      <Redacted height="$24" text animated />
    ) : isTableHead ? (
      <Copy scale={8} fontWeight="bold" uppercase>
        {label}
      </Copy>
    ) : tooltip ? (
      <Tooltip
        tooltip={<Copy scale={10}>{children}</Copy>}
        ariaLabel={`${label} tooltip`}
        withTitle={false}
        contentWidth="max-content"
        trigger={["hover"]}
        placement="top"
        contentCss={{
          width: "100%",
          padding: "$12",
          borderRadius: "$8",
        }}
        triggerCss={{
          display: "inline-grid",
          textAlign: "start",
          color: "$system-inherit",
          outline: "none",
          hover: {
            outline: "none",
            boxShadow: "none",
          },
          keyboardFocus: {
            outline: "none",
          },
        }}
        avoidTriggerFocus
      >
        <Copy scale={10} truncate>
          {children || "-"}
        </Copy>
      </Tooltip>
    ) : (
      <Copy scale={10} truncate>
        {children || "-"}
      </Copy>
    )}
  </GridItem>
)

export const FlsTableRow = ({
  orderId,
  createdAt,
  status,
  shipperAddress,
  consigneeAddress,
  customerId,
  isTableHead,
  isPlaceholder,
}: IOrderTableRowProps) => {
  return (
    <Grid
      columns="2fr 2fr 2fr 2fr 2fr 2fr"
      align="center"
      css={{
        height: isTableHead ? "$48" : "$64",
        color: "$theme-b-n3",
        borderBottom: isTableHead ? "1px solid $theme-n3-n7" : "none",
        transition: "150ms ease-out",

        lastOfType: {
          borderRadius: "0 0 $8 $8",
        },
      }}
      tabIndex={!isPlaceholder && !isTableHead ? 0 : -1}
      onClick={() => !isPlaceholder && !isTableHead}
    >
      <TableItem isTableHead={isTableHead} isPlaceholder={isPlaceholder} tooltip label="Order ID">
        {orderId}
      </TableItem>
      <TableItem isTableHead={isTableHead} isPlaceholder={isPlaceholder} label="Created At">
        {createdAt}
      </TableItem>
      <GridItem css={{ paddingX: "$24" }}>
        {isPlaceholder && !isTableHead ? (
          <Redacted height="$24" text animated />
        ) : (
          <Copy
            scale={isTableHead ? 8 : 10}
            fontWeight={isTableHead ? "bold" : "regular"}
            uppercase={isTableHead}
          >
            {isTableHead ? status : <OrderStatusLabel status={status as FlsStatusType} filled />}
          </Copy>
        )}
      </GridItem>
      <TableItem isTableHead={isTableHead} isPlaceholder={isPlaceholder} tooltip label="Shipper Address">
        {shipperAddress}
      </TableItem>
      <TableItem isTableHead={isTableHead} isPlaceholder={isPlaceholder} tooltip label="Consignee Address">
        {consigneeAddress}
      </TableItem>
      <TableItem isTableHead={isTableHead} isPlaceholder={isPlaceholder} label="Customer ID">
        {customerId}
      </TableItem>
    </Grid>
  )
}
