import { useFormContext } from "react-hook-form"

import { useModalActions } from "@/shared/hooks"
import { ModalType, IOrganizationState } from "@/shared/types"

import { ButtonIcon } from "@/shared/components"
import { IconPencil } from "@/shared/icons"
import { initialOrganizationData } from "@/store"

export const EditButton = ({ id }: { id: string }) => {
  const { watch, setValue, reset, getValues } = useFormContext<IOrganizationState>()
  const { carriers } = watch()
  const { open, close } = useModalActions()

  const onEditClick = (id: string) => {
    const currentItem = carriers.find((el) => el.id === id)

    if (currentItem) {
      close(ModalType.ViewCarrierModal)
      setTimeout(() => {
        setTimeout(() => {
          reset({ ...initialOrganizationData, carriers: getValues("carriers"), newCarrier: currentItem })
        }, 0)
        setValue("newCarrier", currentItem, { shouldDirty: true })
        open(ModalType.CreateCarrierModal)
      }, 300)
    }
  }

  return (
    <ButtonIcon
      type="button"
      ariaLabel={`Edit carrier ${id}`}
      icon={<IconPencil />}
      onClick={() => onEditClick(id)}
      inputIcon
    />
  )
}
