import { ComponentProps } from "@/stitches/types"

import { useTabsContext } from "./Tabs"

import { STabPanel } from "./TabPanel.styles"

interface ITabPanelProps extends ComponentProps<typeof STabPanel> {
  /**
   * ID of the Tab Panel
   */
  id: string
}

export const TabPanel = ({ children, id, css }: ITabPanelProps) => {
  const { selected, animate } = useTabsContext("TabPanel")
  const isSelected = selected === id

  if (!isSelected) {
    return null
  }

  return (
    <STabPanel
      aria-hidden={!isSelected}
      id={`panel-${id}`}
      aria-labelledby={`tab-${id}`}
      role="tabpanel"
      css={{
        marginRight: "-100%",
        ...css,
      }}
      animate={animate}
    >
      {children}
    </STabPanel>
  )
}
