import { ModalType, QuoteStatusType, StrategyType } from "@/shared/types"
import { strategyToText } from "@/shared/utils"

import {
  ButtonIcon,
  Copy,
  Dropdown,
  DropdownItem,
  Flex,
  Grid,
  GridItem,
  Link,
  QuoteStatusLabel,
  Redacted,
} from "@/shared/components"
import { IconMoreVertical } from "@/shared/icons"
import { useState } from "react"
import { useModalActions } from "@/shared/hooks"

interface IExecutionTableRowProps {
  id?: string
  code?: string
  name?: string
  quote?: string
  strategy?: string
  date?: string
  time?: string
  status?: string
  details?: string
  detailsInfo?: {
    forceName: string
    date: string
    winnerName?: string
  }
  isTableHead?: boolean
  isPlaceholder?: boolean
  isViewMode?: boolean
  carrierHandle?: (id: string) => void
}

export const ExecutionTableRow: React.FC<React.PropsWithChildren<IExecutionTableRowProps>> = ({
  id,
  code,
  name,
  quote,
  strategy,
  date,
  time,
  status,
  details,
  detailsInfo,
  isTableHead,
  isPlaceholder,
  carrierHandle,
}) => {
  const [isActionDropdownOpen, setActionDropdownOpen] = useState<boolean>(false)
  const { open } = useModalActions()

  const getQuoteStatusType = (): QuoteStatusType => {
    if (detailsInfo?.winnerName == `${code} - ${name}`) {
      return QuoteStatusType.AwardedWinner
    }

    if (
      (strategy as StrategyType) === StrategyType.Waterfall ||
      (strategy as StrategyType) === StrategyType.FirstCome
    ) {
      if ((status as QuoteStatusType) === QuoteStatusType.ReadyForSending) {
        return QuoteStatusType.NotTendered
      }
      if ((status as QuoteStatusType) === QuoteStatusType.Requested) {
        return QuoteStatusType.Waiting
      }
      if ((status as QuoteStatusType) === QuoteStatusType.Submitted) {
        return QuoteStatusType.Accepted
      }
    }

    return status as QuoteStatusType
  }

  return (
    <Grid
      columns={`1fr 1fr 1fr 1fr 1fr 1fr ${details && "1fr"}`}
      align="center"
      css={{
        height: isTableHead ? "$48" : "$64",
        color: "$theme-b-n3",
        borderBottom: isTableHead ? "1px solid $theme-n3-n7" : "none",
      }}
      tabIndex={!isPlaceholder && !isTableHead ? 0 : -1}
    >
      <GridItem css={{ paddingX: "$24" }}>
        {isPlaceholder && !isTableHead ? (
          <Redacted height="$24" width="100px" text animated />
        ) : (
          <Copy
            scale={isTableHead ? 8 : 10}
            fontWeight={isTableHead ? "bold" : "regular"}
            uppercase={isTableHead ? true : false}
          >
            {code}
          </Copy>
        )}
      </GridItem>
      <GridItem css={{ paddingX: "$24" }}>
        {isPlaceholder && !isTableHead ? (
          <Redacted height="$24" width="140px" text animated />
        ) : (
          <>
            {isTableHead ? (
              <Copy scale={8} fontWeight="bold" uppercase>
                {name}
              </Copy>
            ) : (
              <Link
                scale={10}
                onClick={() => {
                  carrierHandle?.(id as string)
                }}
                clearLinkDefaultStyles
                fontWeight="bold"
                spanStyles={{ color: "#7b7bfe" }}
              >
                {name}
              </Link>
            )}
          </>
        )}
      </GridItem>
      <GridItem css={{ paddingX: "$24" }}>
        {isPlaceholder && !isTableHead ? (
          <Redacted height="$24" width="100px" text animated />
        ) : (
          <Copy
            scale={isTableHead ? 8 : 10}
            fontWeight={isTableHead ? "bold" : "regular"}
            uppercase={isTableHead ? true : false}
          >
            {quote}
          </Copy>
        )}
      </GridItem>
      <GridItem css={{ paddingX: "$24" }}>
        {isPlaceholder && !isTableHead ? (
          <Redacted height="$24" width="100px" text animated />
        ) : (
          <Copy
            scale={isTableHead ? 8 : 10}
            fontWeight={isTableHead ? "bold" : "regular"}
            uppercase={isTableHead ? true : false}
          >
            {isTableHead ? strategy : strategyToText(strategy as StrategyType)}
          </Copy>
        )}
      </GridItem>
      <GridItem css={{ paddingX: "$24" }}>
        {isPlaceholder && !isTableHead ? (
          <Redacted height="$24" width="100px" text animated />
        ) : isTableHead ? (
          <Copy scale={8} fontWeight="bold" uppercase>
            {date}
          </Copy>
        ) : (
          <>
            <Copy scale={10}>{date}</Copy>
            <Copy scale={10}>{time}</Copy>
          </>
        )}
      </GridItem>
      <GridItem css={{ paddingX: "$24" }}>
        {isPlaceholder && !isTableHead ? (
          <Redacted height="$24" width="100px" text animated />
        ) : isTableHead ? (
          <Copy scale={8} fontWeight="bold" uppercase>
            {status}
          </Copy>
        ) : (
          <QuoteStatusLabel code={code} status={getQuoteStatusType()} />
        )}
      </GridItem>
      {details ? (
        <GridItem css={{ paddingX: "$24" }}>
          {isPlaceholder && !isTableHead ? (
            <Redacted height="$24" width="100px" text animated />
          ) : isTableHead ? (
            <Copy scale={8} fontWeight="bold" uppercase>
              {details}
            </Copy>
          ) : (
            <Flex justify={detailsInfo?.forceName ? "start" : "end"}>
              {detailsInfo?.forceName ? (
                <Flex direction={"column"}>
                  <Copy scale={10}>
                    {detailsInfo?.date && "Awarded by"} {detailsInfo?.forceName}
                  </Copy>
                  <Copy scale={10}>{detailsInfo?.date}</Copy>
                </Flex>
              ) : quote != "-" ? (
                <Dropdown
                  asChild
                  trigger={
                    <ButtonIcon type="button" ariaLabel="Edit" icon={<IconMoreVertical />} inputIcon />
                  }
                  open={isActionDropdownOpen}
                  onOpenChange={() => setActionDropdownOpen(!isActionDropdownOpen)}
                >
                  <DropdownItem
                    key="forceTender"
                    label="Assign carrier as winner"
                    onSelect={() => open(ModalType.ForceTenderModal + id)}
                  />
                </Dropdown>
              ) : null}
            </Flex>
          )}
        </GridItem>
      ) : null}
    </Grid>
  )
}
