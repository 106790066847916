import { useNavigate } from "react-router-dom"
import { useMutation } from "@tanstack/react-query"

import { showToast } from "@/shared/utils"
import { RecoveryInput } from "@/shared/types"
import { CARRIER_LOGIN } from "@/constants"
import { sendCarrierEmailFn } from "@/api/userApi"

export const useSendCarrierEmail = () => {
  const navigate = useNavigate()

  return useMutation((data: RecoveryInput) => sendCarrierEmailFn(data), {
    onSuccess: () => {
      navigate(CARRIER_LOGIN)
      showToast({ type: "success", text: "Please check your email for further instructions" })
    },
    onError: (error) => {
      showToast({ type: "error", text: `${error}` })
    },
  })
}
