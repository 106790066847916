import { memo } from "react"

import { Box, ErrorLabel } from "@/shared/components"

export const ErrorLineWithMessage = memo(
  ({ errorId, errorMessage }: { errorId: string; errorMessage?: string }) => {
    return (
      <Box
        css={{
          position: "absolute",
          bottom: 0,
          height: "$2",
          width: "100%",
          backgroundColor: errorMessage ? "$special-error" : "$system-inherit",
        }}
      >
        {errorMessage ? <ErrorLabel id={errorId}>{errorMessage}</ErrorLabel> : null}
      </Box>
    )
  },
)

ErrorLineWithMessage.displayName = "ErrorLineWithMessage"
