import { useTheme } from "next-themes"
import { useAuth0 } from "@auth0/auth0-react"

import { initialOrganizationData, initialUserData, useGlobalStore } from "@/store"
import { ThemeType } from "@/shared/types"
import { LOGIN } from "@/constants"

export const useLogout = () => {
  const { logout } = useAuth0()
  const [setTokens, setUser, setOrganization] = useGlobalStore((state) => [
    state.setTokens,
    state.setUser,
    state.setOrganization,
  ])
  const { setTheme } = useTheme()

  const logoutUser = () => {
    setTokens({ accessToken: "" })
    setUser(initialUserData)
    setOrganization(initialOrganizationData)
    setTheme(ThemeType.Light)
    logout({ logoutParams: { returnTo: `${window.location.origin}${LOGIN}` } })
  }

  return {
    logoutUser,
  }
}
