import { useEffect } from "react"
import { jwtDecode } from "jwt-decode"
import { useAuth0 } from "@auth0/auth0-react"

import { useGlobalStore } from "@/store"
import { ErrorStatusCode, CARRIER_LOGIN, CARRIER } from "@/constants"
// import { LOGIN } from "@/constants"
import { userApi } from "@/api/userApi"
// import { refreshTokenFn } from "@/api/authApi"
import { slaApi, slaApiMultipartFormData } from "@/api/slaApi"
import { tenderApi } from "@/api/tenderApi"
import { organizationApi } from "@/api/organizationApi"
import { carriersApi } from "@/api/carriersApi"
import { ordersApi, ordersCsvApi } from "@/api/ordersApi"
import { tasksApi } from "@/api/tasksApi"
import { flsApi } from "@/api/flsApi"
import { mrktsApi } from "@/api/mrktsApi"
import { useLogout } from "@/auth/hooks"

const services = [
  carriersApi,
  ordersApi,
  organizationApi,
  ordersCsvApi,
  slaApi,
  slaApiMultipartFormData,
  tasksApi,
  tenderApi,
  userApi,
  flsApi,
  mrktsApi,
]

export const AxiosInterceptor: React.FC<React.PropsWithChildren> = ({ children }) => {
  // const [tokenLifetime, accessToken, refreshToken, setTokens, setTokenLifetime] = useGlobalStore((state) => [
  //   state.tokenLifetime,
  //   state.accessToken,
  //   state.refreshToken,
  //   state.setTokens,
  //   state.setTokenLifetime,
  // ])
  const [accessToken, tokenLifetime, setTokens, setTokenLifetime] = useGlobalStore((state) => [
    state.accessToken,
    state.tokenLifetime,
    state.setTokens,
    state.setTokenLifetime,
  ])
  const { getAccessTokenSilently } = useAuth0()
  const { logoutUser } = useLogout()
  const isExcludedRoute = location.pathname.includes(CARRIER_LOGIN) || location.pathname.includes(CARRIER)

  // INFO: This useEffect allows as to synchronize the Zustand store between browser tabs
  useEffect(() => {
    const updateStore = () => useGlobalStore.persist.rehydrate()

    document.addEventListener("visibilitychange", updateStore)
    window.addEventListener("focus", updateStore)

    return () => {
      document.removeEventListener("visibilitychange", updateStore)
      window.removeEventListener("focus", updateStore)
    }
  }, [])

  useEffect(() => {
    if (!isExcludedRoute) {
      services.map((service) => {
        service.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const reqInterceptor = async (config: any) => {
          // if (refreshToken && tokenLifetime < new Date().getTime()) {
          if (tokenLifetime < new Date().getTime()) {
            const accessToken = await getAccessTokenSilently()
            const { exp = 0 } = jwtDecode(accessToken)
            // const { accessToken, expiresInTime } = await refreshTokenFn(refreshToken)

            setTokens({ accessToken })
            // setTokenLifetime({ tokenLifetime: new Date().getTime() + expiresInTime * 1000 })
            setTokenLifetime({ tokenLifetime: exp * 1000 })
            config.headers["Authorization"] = `Bearer ${accessToken}`
          }

          return config
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const resInterceptor = (response: any) => response

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const errInterceptor = async (error: any) => {
          const originalRequest = error.config
          const errorStatusCode = error?.response?.status as number

          if (errorStatusCode === ErrorStatusCode["401"] && !originalRequest._retry) {
            originalRequest._retry = true

            // if (refreshToken) {
            const accessToken = await getAccessTokenSilently()
            const { exp = 0 } = jwtDecode(accessToken)
            // const { accessToken, expiresInTime } = await refreshTokenFn(refreshToken)
            setTokens({ accessToken })
            // setTokenLifetime({ tokenLifetime: new Date().getTime() + expiresInTime * 1000 })
            setTokenLifetime({ tokenLifetime: exp * 1000 })
            originalRequest.headers["Authorization"] = `Bearer ${accessToken}`

            return service(originalRequest)
            // }
          }

          //INFO: for the case when the request for a new accessToken from auth0 fails with an error (expired)
          if (error.toString() === "Error: Login required") {
            logoutUser()
          }

          return Promise.reject(error)
        }

        service.interceptors.request.use(reqInterceptor)
        service.interceptors.response.use(resInterceptor, errInterceptor)

        return service
      })

      return () => {
        services.map((service) => {
          // INFO: initially, eject was used here, but it may not make much difference to use clear or eject?
          // service.interceptors.response.eject(interceptor)
          service.interceptors.request.clear()
          service.interceptors.response.clear()

          return service
        })
      }
    }
    // }, [refreshToken, setTokenLifetime, setTokens, tokenLifetime])
  }, [
    setTokens,
    setTokenLifetime,
    getAccessTokenSilently,
    tokenLifetime,
    logoutUser,
    accessToken,
    isExcludedRoute,
  ])

  return <>{children}</>
}
