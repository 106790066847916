import { useFormContext } from "react-hook-form"

import { nonNumericPattern, lengthRule } from "@/shared/rules"
import { IFlsFiltersState } from "@/shared/types"

import {
  Box,
  ButtonIcon,
  Copy,
  ErrorLineWithMessage,
  Flex,
  Grid,
  GridItem,
  Redacted,
  StateController,
  TextFieldController,
} from "@/shared/components"
import { IconBin } from "@/shared/icons"

interface IFlsLanesTableRowProps {
  id?: string
  index?: number
  fromCity?: string
  fromState?: string
  toCity?: string
  toState?: string
  isTableHead?: boolean
  isPlaceholder?: boolean
  isViewMode?: boolean
}

export const FlsLanesTableRow = ({
  id,
  index = 0,
  fromCity,
  fromState,
  toCity,
  toState,
  isTableHead,
  isPlaceholder,
  isViewMode,
}: IFlsLanesTableRowProps) => {
  const {
    control,
    register,
    trigger,
    formState: { errors },
  } = useFormContext<IFlsFiltersState>()

  return (
    <Grid
      columns="1fr 1fr 1fr 1fr"
      align="center"
      css={{
        minHeight: isTableHead ? "$48" : "$64",
        color: "$theme-b-n3",
        borderBottom: isTableHead ? "1px solid $theme-n3-n7" : "none",
      }}
    >
      <GridItem css={{ paddingX: "$24" }}>
        {isPlaceholder && !isTableHead ? (
          <Redacted height="$24" width="100px" text animated />
        ) : isTableHead || isViewMode ? (
          <Copy
            scale={isTableHead ? 8 : 10}
            fontWeight={isTableHead ? "bold" : "regular"}
            uppercase={isTableHead}
          >
            {fromCity || "-"}
          </Copy>
        ) : (
          <Box css={{ position: "relative" }}>
            <TextFieldController<IFlsFiltersState>
              name={`lanesFilter.filterData.${index}.fromCity`}
              control={control}
              register={register}
              trigger={trigger}
              labelProps={{ required: true }}
              description=""
              rules={{ ...nonNumericPattern, ...lengthRule("City", 40) }}
              placeholder="Enter a city, e.g. Dallas"
              borderless
            />
            <ErrorLineWithMessage
              errorId={`lanesFilter.filterData.${index}.fromCity`}
              errorMessage={errors.lanesFilter?.filterData?.[index]?.fromCity?.message}
            />
          </Box>
        )}
      </GridItem>
      <GridItem css={{ paddingX: "$24" }}>
        {isPlaceholder && !isTableHead ? (
          <Redacted height="$24" width="100px" text animated />
        ) : isTableHead || isViewMode ? (
          <Copy
            scale={isTableHead ? 8 : 10}
            fontWeight={isTableHead ? "bold" : "regular"}
            uppercase={isTableHead}
          >
            {fromState || "-"}
          </Copy>
        ) : (
          <Box css={{ position: "relative" }}>
            <StateController<IFlsFiltersState>
              name={`lanesFilter.filterData.${index}.fromState`}
              control={control}
              register={register}
              trigger={trigger}
              labelProps={{ required: true }}
              description=""
              borderless
            />
            <ErrorLineWithMessage
              errorId={`lanesFilter.filterData.${index}.fromState`}
              errorMessage={errors.lanesFilter?.filterData?.[index]?.fromState?.message}
            />
          </Box>
        )}
      </GridItem>
      <GridItem css={{ paddingX: "$24" }}>
        {isPlaceholder && !isTableHead ? (
          <Redacted height="$24" width="140px" text animated />
        ) : isTableHead || isViewMode ? (
          <Copy
            scale={isTableHead ? 8 : 10}
            fontWeight={isTableHead ? "bold" : "regular"}
            uppercase={isTableHead}
          >
            {toCity || "-"}
          </Copy>
        ) : (
          <Box css={{ position: "relative" }}>
            <TextFieldController<IFlsFiltersState>
              name={`lanesFilter.filterData.${index}.toCity`}
              control={control}
              register={register}
              trigger={trigger}
              labelProps={{ required: true }}
              description=""
              rules={{ ...nonNumericPattern, ...lengthRule("City", 40) }}
              placeholder="Enter a city, e.g. Houston"
              borderless
            />
            <ErrorLineWithMessage
              errorId={`lanesFilter.filterData.${index}.toCity`}
              errorMessage={errors.lanesFilter?.filterData?.[index]?.toCity?.message}
            />
          </Box>
        )}
      </GridItem>
      <GridItem css={{ paddingX: "$24" }}>
        {isPlaceholder && !isTableHead ? (
          <Redacted height="$24" width="140px" text animated />
        ) : isTableHead || isViewMode ? (
          <Copy
            scale={isTableHead ? 8 : 10}
            fontWeight={isTableHead ? "bold" : "regular"}
            uppercase={isTableHead}
          >
            {toState || "-"}
          </Copy>
        ) : (
          <Flex align="center" justify="between" css={{ gap: "$16" }}>
            <Box css={{ position: "relative", width: "50%" }}>
              <StateController<IFlsFiltersState>
                name={`lanesFilter.filterData.${index}.toState`}
                control={control}
                register={register}
                trigger={trigger}
                labelProps={{ required: true }}
                description=""
                borderless
              />
              <ErrorLineWithMessage
                errorId={`lanesFilter.filterData.${index}.toState`}
                errorMessage={errors.lanesFilter?.filterData?.[index]?.toState?.message}
              />
            </Box>
            <RemoveButton id={id || ""} />
          </Flex>
        )}
      </GridItem>
    </Grid>
  )
}

const RemoveButton = ({ id }: { id: string }) => {
  const { watch, setValue } = useFormContext<IFlsFiltersState>()
  const { lanesFilter } = watch()
  const { filterData } = lanesFilter

  const onDeleteClick = (e: React.SyntheticEvent, id: string) => {
    e.stopPropagation()

    setValue(
      "lanesFilter.filterData",
      filterData.filter((i) => i.id !== id),
      { shouldDirty: true, shouldValidate: true },
    )
  }

  return (
    <ButtonIcon
      type="button"
      ariaLabel={`Remove lane ${id}`}
      icon={<IconBin />}
      onClick={(e) => onDeleteClick(e, id)}
      inputIcon
    />
  )
}
