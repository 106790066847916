import { BaseIllustration, IIllustrationProps } from "../Illustration"

export const IllustrationSpinnerBig = ({ ...props }: IIllustrationProps) => (
  <BaseIllustration {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="32" height="32" fill="none">
      <rect x="14.6666" width="2.66667" height="8" rx="1.33333" fill="var(--colors-system-black)" />
      <rect x="14.6666" y="24" width="2.66667" height="8" rx="1.33333" fill="var(--colors-neutrals-5)" />
      <rect
        y="17.3335"
        width="2.66667"
        height="8"
        rx="1.33333"
        transform="rotate(-90 0 17.3335)"
        fill="var(--colors-neutrals-5)"
      />
      <rect
        x="24"
        y="17.3335"
        width="2.66667"
        height="8"
        rx="1.33333"
        transform="rotate(-90 24 17.3335)"
        fill="var(--colors-neutrals-5)"
      />
      <rect
        x="3.74346"
        y="5.62891"
        width="2.66667"
        height="8"
        rx="1.33333"
        transform="rotate(-45 3.74346 5.62891)"
        fill="var(--colors-neutrals-5)"
      />
      <rect
        x="20.7137"
        y="22.5996"
        width="2.66667"
        height="8"
        rx="1.33333"
        transform="rotate(-45 20.7137 22.5996)"
        fill="var(--colors-neutrals-5)"
      />
      <rect
        x="5.62891"
        y="28.2559"
        width="2.66667"
        height="8"
        rx="1.33333"
        transform="rotate(-135 5.62891 28.2559)"
        fill="var(--colors-neutrals-5)"
      />
      <rect
        x="22.5987"
        y="11.2852"
        width="2.66667"
        height="8"
        rx="1.33333"
        transform="rotate(-135 22.5987 11.2852)"
        fill="var(--colors-neutrals-5)"
      />
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        dur=".6s"
        repeatCount="indefinite"
        calcMode="discrete"
        values="0; 45; 90; 135; 180; 225; 270; 315"
      />
    </svg>
  </BaseIllustration>
)
