export const formatNumericInput = (
  input: string,
  separator: string,
  lengthAfterSeparator: number,
  lengthBeforeSeparator: number,
): string => {
  const numericRegex = /^[0-9]+(\.[0-9]+)?$/

  if (input === separator || numericRegex.test(input)) {
    const [beforeSeparator, afterSeparator] = input.split(separator)

    if (beforeSeparator.startsWith("00")) {
      return "0"
    }

    const truncatedBeforeSeparator = beforeSeparator.slice(0, lengthBeforeSeparator)
    const truncatedAfterSeparator = afterSeparator ? afterSeparator.slice(0, lengthAfterSeparator) : ""

    return truncatedAfterSeparator
      ? `${truncatedBeforeSeparator}${separator}${truncatedAfterSeparator}`
      : truncatedBeforeSeparator
  }

  return ""
}
