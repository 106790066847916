import { useEffect } from "react"
import { useFormContext } from "react-hook-form"

import { numericPattern } from "@/shared/rules"
import { IScac, StrategyType, IOrganizationState, ICarrierIds } from "@/shared/types"
import { uberScac } from "@/constants"

import { Copy, Flex, Grid, GridItem, Spacer, Stack, TextFieldController } from "@/shared/components"

import { CarrierScacSelect } from "./CarrierScacSelect"

export const FirstCome = ({ isViewMode }: { isViewMode?: boolean }) => {
  const {
    watch,
    control,
    register,
    trigger,
    setValue,
    formState: { errors },
  } = useFormContext<IOrganizationState>()
  const { firstComeStrategy, criticalStrategy, carriers } = watch()

  const carrierScacsList: IScac[] = carriers
    .map((i) => ({ scac: i.scac, name: i.name, carrierId: Number(i.id) }))
    .filter((i) => i.scac.toUpperCase() === uberScac)

  const carrierIdsList: ICarrierIds[] = carriers
    .map((i) => ({
      tmsCode: i.code,
      carrierName: i.name,
      carrierId: Number(i.id),
      scac: i.scac,
    }))
    .filter((i) => i.scac.toUpperCase() === uberScac)

  useEffect(() => {
    setValue("firstComeStrategy.scacs", carrierScacsList, { shouldDirty: true })
    setValue("firstComeStrategy.carrierIds", carrierIdsList, { shouldDirty: true })
    trigger("firstComeStrategy.scacs")
    trigger("firstComeStrategy.carrierIds")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Stack space={32}>
      <Grid columns="1fr 1fr 1fr 1fr" columnGap={16}>
        <GridItem>
          {isViewMode ? (
            <Flex direction="column" wrap>
              <Copy scale={10} color="neutrals-5" fontWeight="semiBold">
                Select carriers
              </Copy>
              <Spacer size={4} />
              <Flex align="center" css={{ minHeight: "$48", paddingY: "$12" }}>
                <Copy>{firstComeStrategy.scacs.map((i) => i.scac).join(", ")}</Copy>
              </Flex>
            </Flex>
          ) : (
            <CarrierScacSelect type={StrategyType.FirstCome} withTooltip={false} />
          )}
        </GridItem>
        <GridItem>
          <TextFieldController<IOrganizationState>
            name="firstComeStrategy.finishPoint"
            control={control}
            register={register}
            trigger={trigger}
            labelProps={{ required: !isViewMode && firstComeStrategy.enabled }}
            description="Finish point, Business days before Pickup"
            rules={{
              ...numericPattern,
              validate: {
                max: (v: string) => parseInt(v || "0") < 22 || "Max value not met",
                min: (v: string) => v.length === 0 || parseInt(v || "0") >= 0 || "Min value not met",
                lessThanPrev: (v: string, { waterfallStrategy, waitAllStrategy }) => {
                  if (waitAllStrategy.enabled && firstComeStrategy.enabled) {
                    return (
                      parseInt(v) <= parseInt(waitAllStrategy.finishPoint || "0") ||
                      "Should not exceed the previous strategy’s finish point"
                    )
                  }
                  if (waterfallStrategy.enabled && firstComeStrategy.enabled) {
                    return (
                      parseInt(v) <= parseInt(waterfallStrategy.finishPoint || "0") ||
                      "Should not exceed the previous strategy’s finish point"
                    )
                  }

                  return true
                },
              },
            }}
            onChangeHandler={() => {
              if (criticalStrategy.enabled) {
                trigger("criticalStrategy.finishPoint")
              }
            }}
            borderless={isViewMode}
            isViewMode={isViewMode}
            error={errors.firstComeStrategy?.finishPoint?.message}
          />
        </GridItem>
        {/* <GridItem>
          <TextFieldController<IOrganizationState>
            name="firstComeStrategy.responseTime"
            control={control}
            register={register}
            trigger={trigger}
            labelProps={{ required: !isViewMode && firstComeStrategy.enabled }}
            description="Response time, minutes"
            rules={{
              ...numericPattern,
              validate: {
                max: (v: string) => parseInt(v || "0") < 1000 || "Max value exceeded",
                min: (v: string) => v.length === 0 || parseInt(v || "0") > 0 || "Min value not met",
              },
            }}
            borderless={isViewMode}
            isViewMode={isViewMode}
            error={errors.firstComeStrategy?.responseTime?.message}
          />
        </GridItem> */}
      </Grid>
    </Stack>
  )
}
