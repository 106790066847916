import { Navigate, useLocation } from "react-router-dom"
import { parseUrl } from "query-string"

import { useGlobalStore } from "@/store"
import { CARRIER, CARRIER_LOGIN } from "@/constants"

import { CarrierContainerContent } from "../CarrierContainerContent"

export const CarrierContainer = () => {
  const location = useLocation()
  const token = parseUrl(location.search).query.token as string

  const [carrierDashboardToken, setCarrierDashboardToken] = useGlobalStore((state) => [
    state.carrierDashboardToken,
    state.setCarrierDashboardToken,
  ])

  if (!token && !carrierDashboardToken) {
    return <Navigate to={CARRIER_LOGIN} replace />
  }

  if (token) {
    setCarrierDashboardToken(token)
    return <Navigate to={CARRIER} replace />
  }

  return <CarrierContainerContent />
}
