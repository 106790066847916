import { Box, Flex, Redacted } from "@/shared/components"

import { OrdersTableRow } from "./OrdersTableRow"

export const OrdersTablePlaceholder = () => (
  <>
    <Flex justify="between" css={{ marginBottom: "$32" }}>
      <Flex css={{ gap: "$12", flexWrap: "nowrap" }}>
        <Redacted width="320px" height="$48" text animated />
        <Redacted width="470px" height="$48" text animated />
        <Redacted width="500px" height="$48" text animated />
      </Flex>
    </Flex>
    <Box css={{ backgroundColor: "$system-white", borderRadius: "$8" }}>
      <OrdersTableRow
        orderId="Order id"
        shipper="Shipper"
        consignee="Consignee"
        shipDate="Ship date"
        deliveryDate="Delivery date"
        createdDate="Created"
        status="Status"
        reason="Reason"
        responses="Responses"
        isTableHead
        isPlaceholder
      />
      <Box
      // css={{
      //   maxHeight: "calc((var(--vh) * 100) - 254px)",
      //   overflowY: "scroll",
      //   marginRight: -15,
      // }}
      >
        {Array.from(new Array(10), (_, index) => index).map((v) => (
          <OrdersTableRow key={v} isPlaceholder />
        ))}
      </Box>
    </Box>
  </>
)
