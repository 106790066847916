import axios from "axios"
import { ILoginResponse, IRefreshResponse, LoginInput, RecoveryInput } from "@/shared/types"
import { AUTH_BASE_URI } from "@/constants"
import { getMeFn, userApi } from "./userApi"

export const authApi = axios.create({
  baseURL: AUTH_BASE_URI,
})

authApi.defaults.headers.common["Content-Type"] = "application/json"

export const loginUserFn = async ({ username, password }: LoginInput) => {
  const services = [userApi]
  const { data } = await authApi.post<ILoginResponse>("auth/login", { username, password })
  const { accessToken } = data

  services.map((service) => (service.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`))

  const { user, organizations } = await getMeFn(username)

  return { ...data, user, organizations }
}

export const refreshTokenFn = async (token: string) => {
  const services = [userApi]
  const { data } = await authApi.post<IRefreshResponse>("auth/refresh", { token })
  const { accessToken } = data

  services.map((service) => (service.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`))

  return data
}

export const logoutUserFn = async (accessToken: string, refreshToken: string) => {
  const services = [userApi]
  const { data } = await authApi.post<string>("auth/logout", { accessToken, refreshToken })

  services.map((service) => delete service.defaults.headers.common["Authorization"])

  return data
}

export const forgotPasswordFn = async ({ email }: RecoveryInput) => {
  const { data } = await authApi.post<string>("auth/forgot_password", { email })

  return data
}

export const resetPasswordFn = async ({ newPassword, token }: { newPassword: string; token: string }) => {
  const { data } = await authApi.post<string>("auth/reset_password", { newPassword, token })

  return data
}
