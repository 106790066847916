import { useQuery } from "@tanstack/react-query"
import { isAxiosError } from "axios"

import { showToast } from "@/shared/utils"
import { getFlsConfigFn } from "@/api/flsApi"

export const useFlsConfig = () => {
  return useQuery(["flsConfig"], () => getFlsConfigFn(), {
    cacheTime: 5 * 60 * 1000,
    onError: (error) => {
      if (isAxiosError(error)) {
        showToast({ type: "error", text: error.response?.data.errorMessage || error.message })
      }
    },
  })
}
