import { styled } from "@/stitches/config"

import { Flex } from "../Flex"

export const STabList = styled(Flex, {
  marginBottom: "$20",
  boxShadow: "0 2px 0 -1px var(--colors-theme-n3-n7)",

  "@md": {
    marginBottom: "$32",
  },
})
