import { useCallback } from "react"
import { useParams } from "react-router-dom"

import { useModal, useModalActions } from "@/shared/hooks"
import { ModalType, RouteParams } from "@/shared/types"

import { Button, Copy, Flex, Stack, Title } from "@/shared/components"
import { GeneralModal } from "@/modals"
import { getOrdersCsvFn } from "@/api/ordersApi"
import { downloadFile } from "@/shared/utils"

export const ExportOrdersModal = () => {
  const { organizationId } = useParams<keyof RouteParams>() as RouteParams
  const { modalProps } = useModal(ModalType.ExportOrdersModal)
  const { close } = useModalActions()

  const handleContinueClick = useCallback(async () => {
    const data = await getOrdersCsvFn(organizationId)
    downloadFile(data, `orders_orgId_${organizationId}`, ".csv")

    close(ModalType.ExportOrdersModal)
  }, [close, organizationId])

  const handleCancelClick = () => {
    close(ModalType.ExportOrdersModal)
  }

  return (
    <GeneralModal
      {...modalProps}
      closeButton={{ onClick: handleCancelClick }}
      onInteractOutside={handleCancelClick}
      onEscapeKeyDown={handleCancelClick}
      footer={
        <Flex justify="end" css={{ gap: "$16", paddingBottom: "$8" }}>
          <Button onClick={handleContinueClick}>OK</Button>
          <Button action="secondary" onClick={handleCancelClick}>
            Cancel
          </Button>
        </Flex>
      }
    >
      <Stack space={12} css={{ padding: "$64 $24 $0" }}>
        <Title as="h3" scale={2} color="system-black">
          Do you want to export orders?
        </Title>
        <Copy scale={10} color="theme-n6-n5" css={{ paddingBottom: "$8" }}>
          In case you press OK, orders created during the last 90 days will be exported with market rates
        </Copy>
      </Stack>
    </GeneralModal>
  )
}
