import { StrategyType, IPagination, SankeyNodeKey } from "@/shared/types"

interface ICommodity {
  commodity: string
}

export enum DistanceUnitType {
  Mile = "MILE",
  Km = "KM",
}

export interface IMarketRates {
  id: number
  primaryOrderId: number
  rateInCents: number
  source: string
}

interface IOrderDetails {
  billDistance: number
  commodities: ICommodity[]
  distanceUnitType: DistanceUnitType
  weight: string | null
  weightUnit: string | null
  comments: string | null
}

interface IStructuredData {
  orderDetails: IOrderDetails
  validationErrorsDateTime: string | null
  validationErrors: string | null
  shipperName: string | null
  consigneeName: string | null
  fromTimeZoneId: string | null
  toTimeZoneId: string | null
}

export enum ISpecialService {
  TEAM_DRIVERS = "Team drivers",
  TWIC = "TWIC",
  HIGH_VALUE = "High value cargo insurance",
  TANKER_ENDORSEMENT = "Tanker endorsement",
  HAZMAT = "Hazmat",
  DRIVER_WORK = "Driver work",
  DROP_TRAILERS = "Drop trailers",
  NOT_SELECTED = "-",
}
export interface IOrderResponse {
  id: number
  orderId: string
  orgId: string
  originalOrderId: string
  customerId: string
  createdAt: string
  createdInApollo: string
  customerRate: number
  pickupStartAt: string
  pickupEndAt: string | null
  dropOffStartAt: string
  dropOffEndAt: string | null
  orderSourceType: string
  orderStatus: string
  completionReason: string
  fromPostal: string
  fromState: string
  fromCity: string
  fromAddress1: string | null
  fromAddress2: string | null
  fromLatitude: string
  fromLongitude: string
  toPostal: string
  toState: string
  toCity: string
  toAddress1: string | null
  toAddress2: string | null
  toLatitude: string
  toLongitude: string
  equipmentType: string
  hasError: boolean | null
  data: null
  structuredData: IStructuredData | null
  marketRates: IMarketRates[]
  fromCarrierSpecialService: ISpecialService
  toCarrierSpecialService: ISpecialService
}

export interface IAllOrdersResponse extends IPagination {
  content: IOrderResponse[]
}

interface IEventParamsResponse {
  orderId: string
  carrierId?: number
}

export interface IEventResponse {
  timestamp: string
  message: string
  level: string
  source: string
  params: IEventParamsResponse
  internalOnly: boolean
}

interface IStatisticsServicesResponse {
  OMS: number
  CAS: number
}

export interface IStatisticsResponse {
  totalNumber: number
  totalNotCompleted: number
  totalProcessedSuccessfully: number
  todayCreated: number
  todayInServices: IStatisticsServicesResponse
}

export interface IOrderQuote {
  status: string
  orderId: string
  orgId: string
  carrierId: string
  rateInCents: number
  createdAt: string
  updatedAt: string
  strategyType: string
  forceSubmittedBy: string
}

export interface IOrderStrategy {
  currentStrategyType: string
  currentStrategyDeadline: string
  nextStrategyType: string
  winner: string
}

interface INextStratery {
  strategyType: StrategyType
  count: number
}

export interface IStatsOrdersByStrategy {
  strategyType:
    | SankeyNodeKey.WATERFALL
    | SankeyNodeKey.WAIT_FOR_ALL
    | SankeyNodeKey.WAIT_FOR_ALL_WITH_LANES
    | SankeyNodeKey.FCFS
    | SankeyNodeKey.CRITICAL
  total: number
  numberWithWinner: number
  numberInProgress: number
  numberWinnerNotFound: number
  numberOfFinishedWithoutTransition: number
  nextStrategies: INextStratery[]
}

interface IOrderResponseCountItem {
  [key: string]: string
}

export interface IOrderResponseCount {
  orgId: string
  responsesMap: IOrderResponseCountItem
}

export interface IOrdersSankeyGraphResponse {
  total: number
  statsList: IStatsOrdersByStrategy[]
}

export interface ICarrierStats {
  carrierId: string
  carrierName: string
  offeredTenders: string
  acceptedTenders: string
  rejectedTenders: string
  offeredRfqs: string
  acceptedRfqs: string
  rejectedRfqs: string
  wonRfqs: string
  communicationType: string
}

export interface IGroupedCarrierStats {
  carrierStatsDtos: ICarrierStats[]
  dateFrom: string
  dateTo: string
}

export enum OrderStatusType {
  OmsCreated = "OMS_CREATED",
  OmsAccepted = "OMS_ACCEPTED",
  ReadyForTender = "READY_FOR_TENDER",
  WaitingForAcknowledge = "WAITING_FOR_ACKNOWLEDGE",
  Tendering = "TENDERING",
  TenderingFinished = "TENDERING_FINISHED",
  Completed = "COMPLETED",
  NotCovered = "NOT_COVERED",
}

export enum QuoteStatusType {
  ReadyForSending = "READY_FOR_SENDING",
  Requested = "REQUESTED",
  Acknowledged = "ACKNOWLEDGED",
  Rejected = "REJECTED",
  Submitted = "SUBMITTED",
  Error = "ERROR",
  AwardedWinner = "AWARDED_WINNER",
  Waiting = "WAITING",
  Accepted = "ACCEPTED",
  NotTendered = "NOT_TENDERED",
}

export enum OrderReasonType {
  NotEligible = "NOT_ELIGIBLE",
  NoCarriersLeft = "NO_CARRIERS_LEFT",
  NoCarriersFound = "NO_CARRIERS_FOUND",
  PickupTimeTooClose = "PICKUP_TIME_TOO_CLOSE",
  SentToManualProcessing = "SENT_TO_MANUAL_PROCESSING",
  OrderCanceledInMcleod = "ORDER_CANCELED_IN_MCLEOD",
  RevenuewCodeChangedFromDfToZz = "REVENUE_CODE_CHANGED_FROM_DF_TO_ZZ",
  CarrierManuallyAssignedInMcleod = "CARRIER_MANUALLY_ASSIGNED_IN_MCLEOD",
  CoverageIsInvalid = "COVERAGE_IS_INVALID",
  MissingKmaCode = "MISSING_KMA_CODE",
  SmthWentWrong = "SMTH_WENT_WRONG",
  CanceledByShipper = "CANCELED_BY_SHIPPER",
  CarrierAcceptedTender = "CARRIER_ACCEPTED_TENDER",
  ReachedPickupDate = "REACHED_PICKUP_DATE",
  AllCarriersRejected = "ALL_CARRIERS_REJECTED",
  NoCarriersResponded = "NO_CARRIERS_RESPONDED",
  NoCarriersAccepted = "NO_CARRIERS_ACCEPTED",
  NoStrategyFound = "NO_STRATEGY_FOUND",
  MajorUpdate = "MAJOR_UPDATE",
  NoWinnerDefined = "NO_WINNER_DEFINED",
}

export enum OrderType {
  Summary = "Summary",
  Events = "Events",
}

export enum FilterType {
  All = "All",
  Error = "Error",
  FDS = "FDS",
}

export interface ICarrierOrderResponse {
  id: number
  orderId: string
  orgId: string
  originalOrderId: string
  createdInApollo: string
  pickupStartAt: string
  pickupEndAt: string
  dropOffStartAt: string
  dropOffEndAt: string
  orderStatus: string
  completionReason: string | null
  fromPostal: string
  fromState: string
  fromCity: string
  fromCarrierSpecialService: string
  toPostal: string
  toState: string
  toCity: string
  toCarrierSpecialService: string
  equipmentType: string
  weight: string
  weightUnit: string
  commodity: string
  billDistance: number
  distanceUnitType: string
  shipperName: string
  consigneeName: string
}

export interface ICarrierOrderStrategyResponse {
  orgId: number
  orderId: string
  carrierId: number
  status: string
  responseTimeEndUtc: string
  strategyType: string
  rateInCents: number
}
