import { useQuery, useQueryClient } from "@tanstack/react-query"
import { isAxiosError } from "axios"

import { getCarrierDashboardQuotes } from "@/api/tenderApi"
import { getCarrierDashboardOrders } from "@/api/ordersApi"
import { showToast, transformDashboardResponse } from "@/shared/utils"
import { CarrierTabType, IDashboardData } from "@/shared/types"
import { useGlobalStore } from "@/store"

const getDashboardData = async (token: string, tab: CarrierTabType) => {
  const quotesResponse = await getCarrierDashboardQuotes(token, tab)
  const ordersIds = quotesResponse.map((quote) => quote.orderId)
  const ordersResponse = await getCarrierDashboardOrders(token, ordersIds)

  return transformDashboardResponse(quotesResponse, ordersResponse)
}

export const useGetDashboardData = (tab: CarrierTabType) => {
  const [carrierDashboardToken] = useGlobalStore((state) => [state.carrierDashboardToken])
  const queryClient = useQueryClient()

  return useQuery<IDashboardData[]>(
    ["dashboardData", carrierDashboardToken, tab],
    () => getDashboardData(carrierDashboardToken, tab),
    {
      refetchInterval: 1 * 60 * 1000,
      cacheTime: 1 * 60 * 1000,
      keepPreviousData: true,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["dashboardDataNumbers"],
        })
      },
      onError: (error) => {
        if (isAxiosError(error)) {
          showToast({ type: "error", text: error.response?.data.errorMessage || error.message })
        }
      },
    },
  )
}
