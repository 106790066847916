import { useMutation, useQueryClient } from "@tanstack/react-query"
import { isAxiosError } from "axios"

import { updateOrganizationStrategiesFn } from "@/api/slaApi"
import { showToast } from "@/shared/utils"
import { StrategyType, OrganizationType, IOrganizationStrategy } from "@/shared/types"

export const useUpdateOrganizationStrategies = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({
      orgId,
      organizationType,
      waterfallStrategy,
      waitAllStrategy,
      firstComeStrategy,
      criticalStrategy,
      urgentDaysBeforePickup,
      urgentHoursBeforePickup,
    }: {
      orgId: string
      organizationType?: OrganizationType
      waterfallStrategy?: IOrganizationStrategy
      waitAllStrategy?: IOrganizationStrategy
      firstComeStrategy?: IOrganizationStrategy
      criticalStrategy?: IOrganizationStrategy
      urgentDaysBeforePickup: string
      urgentHoursBeforePickup: string
    }) => {
      const isBroker = organizationType === OrganizationType.Broker

      const executionStrategies = [
        ...(waterfallStrategy ? [waterfallStrategy] : []),
        ...(firstComeStrategy ? [firstComeStrategy] : []),
        ...(waitAllStrategy ? [waitAllStrategy] : []),
        ...(criticalStrategy ? [criticalStrategy] : []),
      ]

      if (isBroker) {
        const strategyIndex = executionStrategies.findIndex(
          (strategy) => strategy.type === StrategyType.WaitAll,
        )

        executionStrategies[strategyIndex].type = StrategyType.WaitAllWithLanes
      }

      return updateOrganizationStrategiesFn({
        orgId,
        ...((!!waterfallStrategy || !!waitAllStrategy || !!firstComeStrategy || !!criticalStrategy) && {
          executionStrategies,
        }),
        urgentDaysBeforePickup,
        urgentHoursBeforePickup,
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["organizationStrategies"],
        })
      },
      onError: (error) => {
        if (isAxiosError(error)) {
          showToast({ type: "error", text: error.response?.data.errorMessage || error.message })
        }
      },
    },
  )
}
