import { useTaskData } from "@/tasks/hooks"

import { Box, Spacer, Copy, Button } from "@/shared/components"

enum TaskStatus {
  Running = "RUNNING",
  NotRunning = "NOT_RUNNING",
}

export const Task = ({ label, name }: { label: string; name: string }) => {
  const { status, startTask, stopTask, isLoading } = useTaskData(name)

  return (
    <Box
      css={{
        width: 380,
        backgroundColor: "$system-white",
        padding: "$40 $24 $24",
        border: "1px solid $theme-n3-n7",
        borderRadius: "$8",
      }}
    >
      <Copy>{`${status === TaskStatus.Running ? "Stop" : "Start"} task for reading orders in ${label}`}</Copy>
      <Spacer size={24} />
      <Button
        loading={isLoading}
        size="medium"
        disabled={isLoading}
        onClick={() => (status === TaskStatus.Running ? stopTask() : startTask())}
      >
        {status === TaskStatus.Running ? "Stop" : "Start"}
      </Button>
    </Box>
  )
}
