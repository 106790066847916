import { useCallback } from "react"
import { useFormContext } from "react-hook-form"

import { ISlaItem, SlaField, SlaItemGroup, SlaItemType, IOrganizationState } from "@/shared/types"
import { spaceAndEnterKeyDown } from "@/shared/utils"

import { Button, Copy, Stack, Tooltip } from "@/shared/components"
import { IconPlus } from "@/shared/icons"

const initialSlaGroup: ISlaItem = {
  type: SlaItemType.Group,
  nodes: [],
  group: SlaItemGroup.And,
}

const initialSlaRule: ISlaItem = {
  type: SlaItemType.Rule,
  field: null,
  op: null,
  values: [],
}

export const AddButton = ({
  type,
  name,
  path = [],
  disabled,
}: {
  type: SlaItemType
  name: SlaField
  path?: number[]
  disabled?: boolean
}) => {
  const { watch, setValue } = useFormContext<IOrganizationState>()
  const { sla } = watch()

  // TODO: it will need to be refactored in the future
  const handleClick = useCallback(() => {
    if (path.length === 0) {
      const newValue = sla.map((i) => {
        if (i.name !== name) {
          return i
        }

        return {
          ...i,
          content:
            type === SlaItemType.Rule ? { ...initialSlaGroup, nodes: [initialSlaRule] } : initialSlaGroup,
        }
      })
      setValue("sla", newValue, {
        shouldDirty: true,
      })
      return
    }

    if (path.length === 1) {
      const newValue = sla.map((i) => {
        if (i.name !== name) {
          return i
        }

        return {
          ...i,
          content: {
            ...(i.content as ISlaItem),
            nodes: [
              ...(i.content?.nodes || []),
              type === SlaItemType.Rule ? initialSlaRule : initialSlaGroup,
            ],
          },
        }
      })
      setValue("sla", newValue, {
        shouldDirty: true,
      })
      return
    }

    if (path.length === 2) {
      const newValue = sla.map((i) => {
        if (i.name !== name) {
          return i
        }

        return {
          ...i,
          content: {
            ...(i.content as ISlaItem),
            nodes: i.content?.nodes?.map((n, index) => {
              if (index !== path[0]) {
                return n
              }

              return {
                ...n,
                nodes: [...(n.nodes || []), type === SlaItemType.Rule ? initialSlaRule : initialSlaGroup],
              }
            }) as ISlaItem[],
          },
        }
      })
      setValue("sla", newValue, {
        shouldDirty: true,
      })
      return
    }

    if (path.length === 3) {
      const newValue = sla.map((i) => {
        if (i.name !== name) {
          return i
        }

        return {
          ...i,
          content: {
            ...(i.content as ISlaItem),
            nodes: i.content?.nodes?.map((n, index) => {
              if (index !== path[0]) {
                return n
              }

              return {
                ...n,
                nodes: n.nodes?.map((k, index) => {
                  if (index !== path[1]) {
                    return k
                  }

                  return {
                    ...k,
                    nodes: [...(k.nodes || []), type === SlaItemType.Rule ? initialSlaRule : initialSlaGroup],
                  }
                }),
              }
            }) as ISlaItem[],
          },
        }
      })
      setValue("sla", newValue, {
        shouldDirty: true,
      })
      return
    }
  }, [path, sla, setValue, name, type])

  if ((type === SlaItemType.Rule && path.length > 3) || (type === SlaItemType.Group && path.length > 2)) {
    return null
  }

  return (
    <Tooltip
      tooltip={
        <Stack space={12}>
          <Copy scale={10} color="theme-b-n3">
            {type === SlaItemType.Rule
              ? "Line is a separate condition"
              : "Groups will help you to combine different conditions inside 1 SLA"}
          </Copy>
          <Copy scale={12} color="neutrals-7" css={{ fontStyle: "italic" }}>
            {type === SlaItemType.Rule
              ? "Example: the On-time delivery of all carriers must be more than 95%"
              : "Example: the On-time delivery must be more than 98% if the consignee is X and more than 95% in all other cases"}
          </Copy>
        </Stack>
      }
      ariaLabel={type === SlaItemType.Rule ? " Add rule button tooltip" : "Add group button tooltip"}
      withTitle={false}
      contentWidth="max-content"
      trigger={["hover"]}
      delayShow={2000}
      placement="top"
      contentCss={{
        width: type === SlaItemType.Rule ? 240 : 320,
        padding: "$20 $16",
        borderRadius: "$8",
      }}
      triggerCss={{
        display: "inline-grid",
        textAlign: "start",
        color: "$system-inherit",
        borderRadius: "$8",
        outline: "none",

        hover: {
          outline: "none",
          boxShadow: "none",
        },
        keyboardFocus: {
          outline: "none",
          "> span": {
            color: type === SlaItemType.Rule ? "$brand-yellow-primary" : "inherit",
            borderColor: "$brand-yellow-primary",
            outline: "2px solid $brand-yellow-primary",
          },
        },
      }}
      onKeyDown={(e) => {
        if (spaceAndEnterKeyDown(e.key)) {
          e.preventDefault()
          e.stopPropagation()
          handleClick()
        }
      }}
    >
      <Button
        as="span"
        action={"secondary"}
        type="button"
        ariaLabel={type === SlaItemType.Rule ? " Add rule button" : "Add group button"}
        size="small"
        icon={<IconPlus />}
        onClick={handleClick}
        disabled={disabled}
      >
        {type === SlaItemType.Rule ? "Add line" : "Add group"}
      </Button>
    </Tooltip>
  )
}
