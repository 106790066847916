import { useEffect } from "react"
import { useFormContext } from "react-hook-form"

import { ISlaItem, SlaField, IOrganizationState } from "@/shared/types"
import { getSlaKeyValues, slaKeyToText } from "@/shared/utils"

import { FormSelect } from "@/shared/components"
import { useOrganizationForm } from "@/management/components"

export const FieldSelect = ({
  field,
  name,
  path = [],
  isViewMode,
}: {
  name: SlaField
  path?: number[]
  field?: SlaField | null
  isViewMode?: boolean
}) => {
  const { slaErrors, setSlaErrors } = useOrganizationForm("FieldSelect")
  const { watch, setValue } = useFormContext<IOrganizationState>()
  const { sla } = watch()

  // TODO: it will need to be refactored in the future
  const handleValueChange = (value: SlaField) => {
    if (path.length === 0) {
      const newValue = sla.map((i) => {
        if (i.name !== name) {
          return i
        }

        return {
          ...i,
          content: {
            ...(i.content as ISlaItem),
            field: value,
            op: null,
            values: [],
          },
        }
      })
      setValue("sla", newValue, {
        shouldDirty: true,
      })
      return
    }

    if (path.length === 1) {
      const newValue = sla.map((i) => {
        if (i.name !== name) {
          return i
        }

        return {
          ...i,
          content: {
            ...(i.content as ISlaItem),
            nodes: i.content?.nodes?.map((j, index) => {
              if (index !== path[0]) {
                return j
              }

              return {
                ...j,
                field: value,
                op: null,
                values: [],
              }
            }),
          },
        }
      })
      setValue("sla", newValue, {
        shouldDirty: true,
      })
      return
    }

    if (path.length === 2) {
      const newValue = sla.map((i) => {
        if (i.name !== name) {
          return i
        }

        return {
          ...i,
          content: {
            ...(i.content as ISlaItem),
            nodes: i.content?.nodes?.map((n, index) => {
              if (index !== path[0]) {
                return n
              }

              return {
                ...n,
                nodes: n.nodes?.map((j, index) => {
                  if (index !== path[1]) {
                    return j
                  }

                  return {
                    ...j,
                    field: value,
                    op: null,
                    values: [],
                  }
                }),
              }
            }) as ISlaItem[],
          },
        }
      })
      setValue("sla", newValue, {
        shouldDirty: true,
      })
      return
    }

    if (path.length === 3) {
      const newValue = sla.map((i) => {
        if (i.name !== name) {
          return i
        }

        return {
          ...i,
          content: {
            ...(i.content as ISlaItem),
            nodes: i.content?.nodes?.map((n, index) => {
              if (index !== path[0]) {
                return n
              }

              return {
                ...n,
                nodes: n.nodes?.map((k, index) => {
                  if (index !== path[1]) {
                    return k
                  }

                  return {
                    ...k,
                    nodes: k.nodes?.map((j, index) => {
                      if (index !== path[2]) {
                        return j
                      }

                      return {
                        ...j,
                        field: value,
                        op: null,
                        values: [],
                      }
                    }),
                  }
                }),
              }
            }) as ISlaItem[],
          },
        }
      })
      setValue("sla", newValue, {
        shouldDirty: true,
      })
      return
    }
  }

  useEffect(() => {
    if (!field && !slaErrors?.includes(`slaField_${name}_${path.join(".")}`)) {
      setSlaErrors([...slaErrors, `slaField_${name}_${path.join(".")}`])
    }

    if (field && slaErrors?.includes(`slaField_${name}_${path.join(".")}`)) {
      setSlaErrors(slaErrors.filter((i) => i !== `slaField_${name}_${path.join(".")}`))
    }
  }, [field, name, path, setSlaErrors, slaErrors])

  return (
    <FormSelect
      id={`slaField_${name}_${path.join(".")}`}
      name={`slaField_${name}_${path.join(".")}`}
      label="Field select"
      labelProps={{ hidden: true }}
      isDisplayValueDifferent
      value={
        field
          ? {
              value: field,
              label: slaKeyToText(field),
            }
          : ""
      }
      onValueChange={(value) => handleValueChange(value as SlaField)}
      options={[
        { value: "", label: "Not selected" },
        ...getSlaKeyValues(name).map((i) => ({
          value: i,
          label: slaKeyToText(i),
        })),
      ]}
      menuListCss={{
        maxHeight: 190,
      }}
      isMenuScrollbarColored
      placeholder="Key"
      disabled={isViewMode}
      isViewMode={isViewMode}
      error={!field ? "Required field" : ""}
      menuPlacement="top"
    />
  )
}
