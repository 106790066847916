import { useEffect } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useFormContext } from "react-hook-form"

import { MANAGEMENT } from "@/constants"
import { ModalType, ReferralKey, RouteParams, IOrganizationState } from "@/shared/types"
import { useModalActions } from "@/shared/hooks"

import { Button, Flex, FooterWrapper, useGlobalSettings } from "@/shared/components"
import { CancelButton } from "@/management/components"

export const CreateOrganizationButton = ({ isLoading }: { isLoading: boolean }) => {
  const { organizationId } = useParams<keyof RouteParams>() as RouteParams
  const isEditMode = location.pathname.includes("edit")
  const { open } = useModalActions()
  const navigate = useNavigate()
  const { globalProtectFlag, setGlobalProtectFlag, setGlobalProtectUrl } =
    useGlobalSettings("CreateOrganization")

  const [searchParams] = useSearchParams()
  const referralPage = searchParams.get(`${ReferralKey.ReferralPage}`)
  const url = isEditMode
    ? `${searchParams.has(ReferralKey.ReferralPage) ? referralPage + "/" + organizationId : MANAGEMENT} `
    : MANAGEMENT

  const {
    watch,
    formState: { errors, isDirty },
  } = useFormContext<IOrganizationState>()
  const { name, generalEmail } = watch()

  const protectNavigationHandler = async (url: string) => {
    if (globalProtectFlag) {
      setGlobalProtectUrl(url)
      open(ModalType.GlobalProtectModal)
    } else {
      navigate(url)
    }
  }

  useEffect(() => {
    setGlobalProtectFlag(isDirty)
  }, [isDirty, setGlobalProtectFlag])

  return (
    <FooterWrapper>
      <Flex justify="between" css={{ gap: "$16" }}>
        <Button
          type="submit"
          loading={isLoading}
          disabled={isLoading || !name || !generalEmail || !isDirty || Object.keys(errors).length > 0}
        >
          {isEditMode ? "Save changes" : "Create org"}
        </Button>
        <CancelButton handleClick={() => protectNavigationHandler(url)} />
      </Flex>
    </FooterWrapper>
  )
}
