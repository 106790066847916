import { useRef } from "react"
import ReactECharts from "echarts-for-react"
import { LineSeriesOption, type EChartsOption, graphic } from "echarts"

import { rgba } from "@/stitches/utils"
import { LineChartTypes, TimeFilter } from "@/shared/types"

interface ResultItem {
  carrierName: string
  communicationType: string
  labels: string[]
  data: number[]
  originalData: {
    offered: number
    accepted: number
    rejectedWon: number
  }[]
}

export type Result = { [carrierId: string]: ResultItem }

type SelectedPointData = {
  seriesIndex: number
  dataIndex: number
  color: string
}

export const LineChart = ({
  chartType,
  values,
  timeFilter,
}: {
  chartType: LineChartTypes
  values: Result
  timeFilter: TimeFilter
}) => {
  const chartRef = useRef<ReactECharts>(null)

  const getSeries = (selectedPoint?: SelectedPointData): LineSeriesOption[] =>
    Object.values(values).map(({ carrierName, communicationType, data, originalData }, idx) => ({
      name: carrierName,
      type: "line",
      data: data.map((dataValue, index) => ({
        value: dataValue,
        symbolSize: selectedPoint?.seriesIndex === idx && selectedPoint.dataIndex === index ? 12 : 8,
        ...(selectedPoint?.seriesIndex === idx && selectedPoint?.dataIndex === index
          ? {
              itemStyle: {
                color: "transparent",
                borderColor: selectedPoint.color,
                borderWidth: 5,
              },
            }
          : {}),
      })),
      symbol: "circle",
      emphasis: {
        focus: "series",
      },
      selectedMode: "single",
      ...(selectedPoint?.seriesIndex === idx
        ? {
            areaStyle: {
              color: new graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: selectedPoint.color,
                },
                {
                  offset: 1,
                  color: rgba("system-white"),
                },
              ]),
            },
          }
        : {
            areaStyle: {
              color: "transparent",
            },
          }),
      tooltip: {
        padding: 12,
        borderColor: rgba("system-white"),
        borderRadius: 8,
        position: (point, _, dom) => {
          const tooltipWidth = (dom as HTMLDivElement).offsetWidth
          const tooltipHeight = (dom as HTMLDivElement).offsetHeight
          return [point[0] - Math.ceil(tooltipWidth / 2), point[1] - tooltipHeight - 10]
        },
        formatter: ({ dataIndex }) => {
          return `<div id="testTest" style="display:flex;flex-direction:column;gap:8px;color:${rgba(
            "system-black",
          )}">
            <strong style="text-transform:uppercase;font-weight:bold">${carrierName}</strong>
            <div style="display:flex;flex-direction:column;gap:4px">
              <div style="display:flex;gap:4px">
                <p>Offered: <strong style="font-weight:bold">${originalData[dataIndex].offered}</strong></p>
                <p>Accepted: <strong style="font-weight:bold">${originalData[dataIndex].accepted}</strong></p>
                <p>${
                  chartType === LineChartTypes.TENDERS ? "Rejected" : "Won"
                }: <strong style="font-weight:bold">${originalData[dataIndex].rejectedWon}</strong></p>
              </div>
              <p>Communication type: <strong style="font-weight:bold">${communicationType}</strong></p>
            </div>
          </div>`
        },
      },
    }))

  const getYAxisName = () => {
    switch (chartType) {
      case LineChartTypes.TENDERS:
        return "% of accepted tenders out of offered to each individual carrier"
      case LineChartTypes.RESPONDED_RFQS:
        return "% of responded RFQs out of offered to each individual carrier"
      case LineChartTypes.WON_RFQS:
        return "% of won RFQs out of responded by each individual carrier"
    }
  }

  const chartOption: EChartsOption = {
    tooltip: {
      trigger: "item",
      triggerOn: "click",
    },
    legend: {
      data: Object.values(values).map(({ carrierName }) => carrierName),
      icon: "circle",
      bottom: 25,
      type: "scroll",
      left: 30,
      itemGap: 30,
    },
    grid: {
      containLabel: true,
      bottom: 120,
      show: false,
    },
    toolbox: {},
    xAxis: {
      type: "category",
      name: "Days",
      offset: 10,
      boundaryGap: false,
      nameRotate: 0,
      nameLocation: "start",
      nameTextStyle: {
        color: rgba("neutrals-5"),
        align: "center",
        verticalAlign: "top",
        padding: [timeFilter === TimeFilter.Last7 ? 55 : 110, 0, 0, 50],
      },
      axisLabel: {
        rotate: 50,
        color: rgba("neutrals-5"),
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: rgba("neutrals-3"),
        },
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      data: Object.values(values)[0].labels,
    },
    yAxis: {
      type: "value",
      name: getYAxisName(),
      nameRotate: 90,
      nameLocation: "middle",
      nameTextStyle: {
        color: rgba("neutrals-5"),
        padding: [0, 0, 60, 0],
      },
      axisLabel: {
        formatter: (value: number) => (value !== 0 ? `${value}%` : "0"),
        color: rgba("neutrals-5"),
      },
      min: 0,
      max: 100,
      minInterval: 25,
      splitLine: {
        show: false,
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: rgba("neutrals-3"),
        },
      },
    },
    axisPointer: {
      show: false,
    },
    series: getSeries(),
  }

  const handlePointClick = (params: { dataIndex: number; seriesIndex: number; color: string }) => {
    const { dataIndex, seriesIndex, color } = params

    const chartInstance = chartRef?.current?.getEchartsInstance()

    if (chartInstance && color !== "transparent") {
      const newOption = {
        series: getSeries({ seriesIndex, dataIndex, color }),
      }

      chartInstance.setOption(newOption)
    }
  }

  return (
    <ReactECharts
      ref={chartRef}
      option={chartOption}
      style={{ height: 600 }}
      onEvents={{
        click: handlePointClick,
      }}
    />
  )
}
