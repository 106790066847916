export type FilterCarrierLinesType = {
  fromStop: {
    stopType: string
    value: string
  }
  toStop: {
    stopType: string
    value: string
  }
}

export enum CarrierType {
  Asset = "ASSET",
  Broker = "BROKER",
}

export enum CommunicationType {
  EDI = "EDI",
  EMAIL = "EMAIL",
  API = "API",
}

export enum LaneStopType {
  CITY_STATE = "CITY_STATE",
  STATE = "STATE",
  ZIP_CODE_5 = "ZIP_CODE_5",
  ZIP_CODE_3 = "ZIP_CODE_3",
  KMA = "KMA",
  ANY = "ANY",
}

export interface IScac {
  carrierId: number | null
  scac: string
  name: string
}

export interface ICarrierIds {
  carrierId: number | null
  tmsCode: string
  scac: string
  carrierName: string
}

export enum CarrierDetailsTabType {
  Company = "Company",
  Contacts = "Contacts",
  Equipment = "Equipment",
  Lanes = "Lanes",
  Analytics = "Analytics",
  Integration = "Integration",
  Services = "Services",
}

export enum CarrierOrdersStatusType {
  Submitted = "SUBMITTED",
  Rejected = "REJECTED",
}

export enum CarrierTabType {
  Pending = "Pending",
  Accepted = "Accepted",
  Rejected = "Rejected",
  Expired = "Expired",
}

export interface IForceTenderDto {
  orgId: string
  carrierId: string
  orderId: string
  rateInCents: number
}

export interface IForceTenderToAnyDto {
  carrierId: string
  orderId: string
  rateInCents: number
}

export enum CarrierOrderStatus {
  Idle = "idle",
  Loading = "loading",
  Error = "error",
  Success = "success",
}
