import { FileType } from "@/shared/types"

export const fileTypeToText = (type: FileType) => {
  switch (type) {
    case FileType.DAT_KMAS:
      return "DAT KMAs"
    case FileType.CARRIERS_COVERAGE:
      return "Carriers’ coverages"
    case FileType.CONVOY_RATES_FOR_LANES:
      return "Rates for top 150 Convoy lanes"
  }
}
