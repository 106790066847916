import { FeatureFlags, IOrganizationCarrier, StatusType } from "@/shared/types"

import { getFeatureFlags } from "@/shared/utils"
import { Box, Copy, Flex } from "@/shared/components"

import { CarrierTableRow } from "./components"

export const CarrierTable = ({
  carriers,
  isViewMode,
  isPlaceholder,
  placeholderRows = 10,
  withBorder,
  isBroker,
  isWithoutPermission,
}: {
  carriers: IOrganizationCarrier[]
  isViewMode?: boolean
  isPlaceholder?: boolean
  placeholderRows?: number
  withBorder?: boolean
  isBroker?: boolean
  isWithoutPermission?: boolean
}) => {
  const [isIdInsteadScac] = getFeatureFlags([FeatureFlags.FEATURE_FLAG_USE_CARRIER_ID_INSTEAD_SCAC])

  return (
    <Box
      css={{
        backgroundColor: "$system-white",
        borderRadius: "$8",
        border: withBorder ? "1px solid $theme-n3-n7" : "none",
      }}
    >
      <CarrierTableRow
        code={isIdInsteadScac ? "TMS code" : "Scac code"}
        name="Name"
        status="Status"
        isTableHead
        isPlaceholder
      />
      {isPlaceholder ? (
        Array.from(new Array(placeholderRows), (_, index) => index).map((v) => (
          <CarrierTableRow key={v} isPlaceholder />
        ))
      ) : carriers.length === 0 ? (
        <Flex
          align="center"
          justify="center"
          css={{
            height: "$64",
            color: "$theme-b-n3",
          }}
        >
          <Copy color="theme-n6-n5">No matches found</Copy>
        </Flex>
      ) : (
        carriers.map(({ id, scac, code, name, enabled, source }) => (
          <CarrierTableRow
            key={id}
            id={id}
            code={isIdInsteadScac ? code : scac}
            name={name}
            source={source}
            status={enabled === StatusType.Active}
            isViewMode={isViewMode}
            isBroker={isBroker}
            isWithoutPermission={isWithoutPermission}
            scac={scac}
          />
        ))
      )}
    </Box>
  )
}
