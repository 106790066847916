import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useFormContext } from "react-hook-form"

import { useAllUrgentContacts } from "@/management/hooks"
import { RouteParams, IOrganizationState, IUrgentContacts } from "@/shared/types"
import { useIsReader } from "@/shared/utils"

import { AddItemButton, Box, Copy, Flex, Stack, Title } from "@/shared/components"
import { DeleteUrgentContactModal } from "@/modals"

import { UrgentContactsTableRow, UrgentNewContactsTableRow } from "./components"

export const UrgentContactsTable = () => {
  const { organizationId } = useParams<keyof RouteParams>() as RouteParams
  const isRoleReader = useIsReader()
  const [rowsData, setRowsData] = useState<IUrgentContacts[]>([])

  const { isInitialLoading, data } = useAllUrgentContacts({ id: organizationId })

  const { setValue, watch, trigger } = useFormContext<IOrganizationState>()
  const { newEmails } = watch()

  useEffect(() => {
    if (!isInitialLoading) {
      setRowsData(data?.reverse() || [])
    }
  }, [isInitialLoading, data])

  return isInitialLoading || !data ? null : (
    <Stack space={32}>
      <Flex align="center" css={{ gap: "$8" }}>
        <Title as="h2" scale={6} color="theme-b-n3">
          Contacts for urgent emails
        </Title>
      </Flex>
      {!isRoleReader && (
        <AddItemButton
          text="Add urgent contact"
          onClick={() => {
            setValue(
              "newEmails",
              [
                {
                  id: `${Date.now()}`,
                  email: "",
                },
                ...newEmails,
              ],
              { shouldDirty: true },
            )
            setTimeout(() => {
              trigger("newEmails")
            }, 0)
          }}
          css={{ width: "fit-content", marginBottom: "$12" }}
        />
      )}

      <Box css={{ backgroundColor: "$system-white", borderRadius: "$8", maxWidth: "480px" }}>
        {rowsData.length === 0 && newEmails?.length === 0 ? (
          <Flex
            align="center"
            css={{
              height: "$64",
              color: "$theme-b-n3",
            }}
          >
            <Copy color="theme-n6-n5">There is no data yet</Copy>
          </Flex>
        ) : (
          <>
            {newEmails?.length > 0 &&
              newEmails?.map(({ id }, index) => (
                <UrgentNewContactsTableRow key={id + index} id={id + index} index={index} />
              ))}
            {rowsData?.map(({ id, email }) => (
              <UrgentContactsTableRow key={id} id={id} email={email} />
            ))}
          </>
        )}
      </Box>

      <DeleteUrgentContactModal />
    </Stack>
  )
}
