import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useFormContext } from "react-hook-form"
import { useDebounce } from "use-debounce"

import { useOrganizationById, useOrganizationCarriersFull } from "@/management/hooks"
import { PAGE_MAX_CARRIERS } from "@/constants"
import {
  ModalType,
  RouteParams,
  IOrganizationState,
  OrganizationType,
  FilterCarrierLinesType,
  carrierInitialState,
} from "@/shared/types"
import { useGlobalStore } from "@/store"
import { CreateCarrierModal, DeleteCarrierModal, ViewCarrierModal } from "@/modals"
import { useModalActions } from "@/shared/hooks"
import { IconCross, IconSearch } from "@/shared/icons"
import { useIsReader } from "@/shared/utils"

import { BulkButtons, CarrierTable, Pagination, PreferredLanesFilter } from "@/management/components"
import { AddItemButton, Box, ButtonIcon, Flex, FormInput, Spacer, Stack } from "@/shared/components"
import { NoLineSelect } from "./components/NoLineSelect"

export const OrganizationCarrierDetails = () => {
  const { organizationId } = useParams<keyof RouteParams>() as RouteParams
  const [isBroker, setIsBroker] = useState(false)
  const [inputValue, setInputValue] = useState<string>("")
  const [requestBody, setRequestBody] = useState<FilterCarrierLinesType[]>([])
  const [noLanes, setNoLanes] = useState(false)
  const [noContacts, setNoContacts] = useState(false)
  const [keyword] = useDebounce(inputValue, 500)
  const { watch, setValue, resetField } = useFormContext<IOrganizationState>()
  const { carriers } = watch()
  const { open } = useModalActions()

  const [page, setPage] = useGlobalStore((state) => [state.page, state.setPage])
  const isRoleReader = useIsReader()

  const { data: currentOrganization } = useOrganizationById(organizationId)
  const { data: organization, isLoading: organizationLoading } = useOrganizationById(organizationId)
  const { fetchStatus: organizationCarriersFetchStatus, data: carriersData } = useOrganizationCarriersFull({
    orgId: organizationId,
    name: encodeURIComponent(keyword),
    page,
    size: PAGE_MAX_CARRIERS,
    noLanes,
    noContacts,
    requestBody,
  })

  const isLoading = organizationCarriersFetchStatus === "fetching" || organizationLoading

  const isNoLanesDisabled = requestBody.length !== 0

  useEffect(() => {
    return () => {
      setPage(0)
    }
  }, [setPage, keyword])

  useEffect(() => {
    if (carriersData) {
      setValue("carriers", carriersData?.content)
    }
  }, [carriersData, setValue])

  useEffect(() => {
    if (organization && organization.organizationType === OrganizationType.Broker) {
      setIsBroker(true)
    } else {
      setIsBroker(false)
    }
  }, [organization, organizationId, currentOrganization?.parentId])

  return (
    <>
      <>
        <Stack space={32}>
          <Flex justify="between">
            <Flex css={{ gap: "$12" }}>
              <Box css={{ width: 320 }}>
                <FormInput
                  id="carriersSearch"
                  value={inputValue}
                  label="Search"
                  labelProps={{ hidden: true }}
                  placeholder="Search by carrier name"
                  autoComplete="off"
                  onChange={(event) => {
                    setInputValue(event.target.value)
                  }}
                  prefix={<IconSearch />}
                  suffix={
                    inputValue && (
                      <ButtonIcon
                        icon={<IconCross />}
                        ariaLabel="Clear button"
                        onClick={() => setInputValue("")}
                        inputIcon
                      />
                    )
                  }
                  css={{ width: 320, height: "$40", minHeight: "$40" }}
                />
              </Box>

              <NoLineSelect {...{ noLanes, noContacts, setNoLanes, setNoContacts, isNoLanesDisabled }} />

              <PreferredLanesFilter {...{ noLanes, setRequestBody }} />
            </Flex>
            {!isRoleReader && (
              <Flex css={{ gap: "$12" }}>
                <AddItemButton
                  text="Add carrier"
                  size="medium"
                  onClick={() => {
                    open(ModalType.CreateCarrierModal)
                    resetField("newCarrier", { defaultValue: carrierInitialState })
                    setValue("newCarrier", carrierInitialState, { shouldDirty: true })
                  }}
                  css={{ width: "fit-content" }}
                />
                <BulkButtons orgId={organizationId} />
              </Flex>
            )}
          </Flex>
          <CarrierTable
            carriers={carriersData?.content || []}
            isPlaceholder={isLoading || !carriers}
            placeholderRows={3}
            withBorder
            isBroker={isBroker}
            isViewMode={isRoleReader}
            isWithoutPermission={isRoleReader}
          />
        </Stack>
        <Spacer size={48} />
        <Pagination totalPages={carriersData?.totalPages} currentPage={page} onPageChange={setPage} />
      </>
      <CreateCarrierModal />
      <ViewCarrierModal isViewMode={isRoleReader} />
      <DeleteCarrierModal isViewMode />
    </>
  )
}
