import React from "react"

import { Copy, Flex, IconFiller, Stack } from "@/shared/components"

export const GeneralInfoCard = ({
  title,
  icon,
  content,
  fill,
}: {
  title: string
  icon: React.ReactNode
  content: React.ReactNode
  fill?: boolean
}) => {
  return (
    <Flex
      align="start"
      css={{
        padding: "$20 $24",
        gap: "$16",
        border: "1px solid $theme-n3-n7",
        borderRadius: "$8",
        backgroundColor: fill ? "$system-white" : "none",
      }}
    >
      <IconFiller color="brand-yellow-light">{icon}</IconFiller>
      <Stack space={16} css={{ flex: 1 }}>
        <Copy color="theme-b-n3" fontWeight="bold" uppercase>
          {title}
        </Copy>
        {content}
      </Stack>
    </Flex>
  )
}
