import { useEffect } from "react"
import { useFormContext } from "react-hook-form"

import { ISlaItem, SlaField, SlaItemOp, IOrganizationState } from "@/shared/types"
import { slaOpToText } from "@/shared/utils"

import { FormSelect } from "@/shared/components"
import { useOrganizationForm } from "@/management/components"

const setOperationList = (field?: SlaField | null) => {
  switch (field) {
    case SlaField.Haul:
    case SlaField.Brokers:
    case SlaField.Consignee:
    case SlaField.Shipper:
    case SlaField.Tracking:
    case SlaField.Unloading:
    case SlaField.AddressFrom:
    case SlaField.AddressTo:
      return [SlaItemOp.Eq, SlaItemOp.Neq]
    case SlaField.Delivery:
    case SlaField.Tender:
    case SlaField.Cargo:
      return [SlaItemOp.Eq, SlaItemOp.Neq, SlaItemOp.Gt, SlaItemOp.Lt, SlaItemOp.Gte, SlaItemOp.Lte]
    case SlaField.ExcludedCarriers:
      return [SlaItemOp.Neq]
    default:
      return []
  }
}

export const OperationSelect = ({
  op,
  name,
  field,
  path = [],
  isViewMode,
}: {
  name: SlaField
  path?: number[]
  op?: SlaItemOp | null
  field?: SlaField | null
  isViewMode?: boolean
}) => {
  const { slaErrors, setSlaErrors } = useOrganizationForm("OperationSelect")
  const { watch, setValue } = useFormContext<IOrganizationState>()
  const { sla } = watch()

  const operationList = setOperationList(field)
  // TODO: it will need to be refactored in the future
  const handleValueChange = (value: SlaItemOp) => {
    if (path.length === 0) {
      const newValue = sla.map((i) => {
        if (i.name !== name) {
          return i
        }

        return {
          ...i,
          content: {
            ...(i.content as ISlaItem),
            op: value,
          },
        }
      })
      setValue("sla", newValue, {
        shouldDirty: true,
      })
      return
    }

    if (path.length === 1) {
      const newValue = sla.map((i) => {
        if (i.name !== name) {
          return i
        }

        return {
          ...i,
          content: {
            ...(i.content as ISlaItem),
            nodes: i.content?.nodes?.map((j, index) => {
              if (index !== path[0]) {
                return j
              }

              return {
                ...j,
                op: value,
              }
            }),
          },
        }
      })
      setValue("sla", newValue, {
        shouldDirty: true,
      })
      return
    }

    if (path.length === 2) {
      const newValue = sla.map((i) => {
        if (i.name !== name) {
          return i
        }

        return {
          ...i,
          content: {
            ...(i.content as ISlaItem),
            nodes: i.content?.nodes?.map((n, index) => {
              if (index !== path[0]) {
                return n
              }

              return {
                ...n,
                nodes: n.nodes?.map((j, index) => {
                  if (index !== path[1]) {
                    return j
                  }

                  return {
                    ...j,
                    op: value,
                  }
                }),
              }
            }) as ISlaItem[],
          },
        }
      })
      setValue("sla", newValue, {
        shouldDirty: true,
      })
      return
    }

    if (path.length === 3) {
      const newValue = sla.map((i) => {
        if (i.name !== name) {
          return i
        }

        return {
          ...i,
          content: {
            ...(i.content as ISlaItem),
            nodes: i.content?.nodes?.map((n, index) => {
              if (index !== path[0]) {
                return n
              }

              return {
                ...n,
                nodes: n.nodes?.map((k, index) => {
                  if (index !== path[1]) {
                    return k
                  }

                  return {
                    ...k,
                    nodes: k.nodes?.map((j, index) => {
                      if (index !== path[2]) {
                        return j
                      }

                      return {
                        ...j,
                        op: value,
                      }
                    }),
                  }
                }),
              }
            }) as ISlaItem[],
          },
        }
      })
      setValue("sla", newValue, {
        shouldDirty: true,
      })
      return
    }
  }

  useEffect(() => {
    if (!op && !slaErrors?.includes(`slaOp_${name}_${path.join(".")}`)) {
      setSlaErrors([...slaErrors, `slaOp_${name}_${path.join(".")}`])
    }

    if (op && slaErrors?.includes(`slaOp_${name}_${path.join(".")}`)) {
      setSlaErrors(slaErrors.filter((i) => i !== `slaOp_${name}_${path.join(".")}`))
    }
  }, [op, name, path, setSlaErrors, slaErrors])

  return (
    <FormSelect
      id={`slaOp_${name}_${path.join(".")}`}
      name={`slaOp_${name}_${path.join(".")}`}
      label="Operation select"
      labelProps={{ hidden: true }}
      isDisplayValueDifferent
      value={
        op
          ? {
              value: op,
              label: slaOpToText(op),
            }
          : ""
      }
      onValueChange={(value) => handleValueChange(value as SlaItemOp)}
      options={[
        { value: "", label: "Not selected" },
        ...operationList.map((i) => ({
          value: i,
          label: slaOpToText(i),
        })),
      ]}
      isMenuScrollbarColored
      placeholder="Condition"
      disabled={isViewMode}
      isViewMode={isViewMode}
      error={!op ? "Required field" : ""}
      menuPlacement="top"
    />
  )
}
