import { memo } from "react"

import { Flex } from "@/shared/components"
import { IllustrationSpinnerBig } from "@/shared/illustrations"

export const Spinner = memo(() => {
  return (
    <Flex align="center" css={{ padding: "$160", height: "$560" }}>
      <IllustrationSpinnerBig />
    </Flex>
  )
})

Spinner.displayName = "Spinner"
