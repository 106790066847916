import { Modal, ModalProps } from "@/shared/components"

export const GeneralModal: React.FC<ModalProps> = ({ children, contentCss, containerCss, ...props }) => {
  const modalProps: ModalProps = {
    align: "center",
    gap: 16,
    panelCss: {
      "@max-sm": {
        width: "100%",
      },
    },
    contentCss: {
      ...contentCss,
      ...{
        padding: 0,
        borderRadius: "$16",

        "@max-sm": {
          width: "100%",
        },
      },
    },
    containerCss: {
      ...containerCss,
    },
  }

  return (
    <Modal {...props} {...modalProps}>
      {children}
    </Modal>
  )
}
