import { memo } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { HOME, MANAGEMENT } from "@/constants"
import { useModalActions } from "@/shared/hooks"
import { ModalType } from "@/shared/types"

import { Copy, Flex, Link, useGlobalSettings } from "@/shared/components"

interface IAdditionalRoute {
  url: string
  label: string
}

interface IBreadcrumbsProps {
  additionalRoutes?: IAdditionalRoute[]
  routeParam?: string
}

export const Breadcrumbs = memo(({ additionalRoutes, routeParam }: IBreadcrumbsProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { open } = useModalActions()
  const { globalProtectFlag, setGlobalProtectUrl } = useGlobalSettings("Breadcrumbs")

  const protectNavigationHandler = async (url: string) => {
    if (globalProtectFlag) {
      setGlobalProtectUrl(url)
      open(ModalType.GlobalProtectModal)
    } else {
      navigate(url)
    }
  }

  const isManagementRoute = location.pathname.includes("management")

  return (
    <Flex css={{ width: "max-content", marginBottom: "$20", gap: "$4" }}>
      <Link
        as="button"
        type="button"
        onClick={() => protectNavigationHandler(isManagementRoute ? MANAGEMENT : HOME)}
        css={{ color: "$theme-n6-n5" }}
      >
        {isManagementRoute ? "User Management" : "Home"}
      </Link>
      <Copy color="theme-n6-n5" css={{ cursor: "default" }}>
        /
      </Copy>
      {additionalRoutes?.map((route) => (
        <Flex key={route.url} css={{ gap: "$4" }}>
          <Link
            as="button"
            type="button"
            onClick={() => protectNavigationHandler(route.url)}
            css={{ color: "$theme-n6-n5" }}
          >
            {route.label}
          </Link>
          <Copy color="theme-n6-n5" css={{ cursor: "default" }}>
            /
          </Copy>
        </Flex>
      ))}
      <Copy fontWeight="semiBold" color="theme-b-n3" css={{ cursor: "default" }}>
        {routeParam}
      </Copy>
    </Flex>
  )
})

Breadcrumbs.displayName = "Breadcrumbs"
