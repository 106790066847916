import { useMutation } from "@tanstack/react-query"
import { isAxiosError } from "axios"

import { checkCarriersApiKeyFn } from "@/api/slaApi"
import { carriersToCarriersDto, showToast } from "@/shared/utils"
import { IOrganizationCarrier } from "@/shared/types"

export const useCheckCarriersApiKey = () => {
  return useMutation(
    ({ orgId, data }: { orgId: string; data: IOrganizationCarrier[] }) =>
      checkCarriersApiKeyFn(carriersToCarriersDto(data, orgId)),
    {
      onError: (error) => {
        if (isAxiosError(error)) {
          showToast({ type: "error", text: error.response?.data || error.message })
        }
      },
    },
  )
}
