import { useFormContext } from "react-hook-form"

import { IOrganizationState } from "@/shared/types"
import { CONTACTS_MAX } from "@/constants"

import { Box, Copy, Spacer, Grid, Stack, AddItemButton } from "@/shared/components"

import { ContactsTableRow } from "./ContactsTableRow"
import { ContactsCreateRow } from "./ContactsCreateRow"

export const Contacts = () => {
  const { setValue, watch, trigger } = useFormContext<IOrganizationState>()
  const { contacts } = watch()

  return (
    <Box>
      <Copy scale={8} fontWeight="bold">
        Contacts
      </Copy>
      <Spacer size={32} />
      <AddItemButton
        text="Add contact"
        onClick={() => {
          setValue(
            "contacts",
            [
              {
                id: "",
                name: "",
                email: "",
                urgentContact: true,
              },
              ...contacts,
            ],
            {
              shouldDirty: true,
            },
          )
          setTimeout(() => {
            trigger("contacts")
          }, 0)
        }}
        disabled={contacts.length >= CONTACTS_MAX}
      />
      <Spacer size={24} />
      <ContactsTableRow
        name="Contact name*"
        email="Contact email*"
        urgentContact="Urgent email recipient"
        isTableHead
      />
      {contacts.length === 0 ? (
        <Grid columns="1fr" css={{ padding: "$20 $16", textAlign: "center" }}>
          <Copy color="theme-n6-n5">There is no data yet</Copy>
        </Grid>
      ) : (
        <Stack space={0}>
          {contacts.map((_, index) => (
            <ContactsCreateRow key={index} index={index} />
          ))}
        </Stack>
      )}
    </Box>
  )
}
