import { useFormContext } from "react-hook-form"

import { lengthRule } from "@/shared/rules"
import { IFlsFiltersState } from "@/shared/types"

import {
  Box,
  ButtonIcon,
  Copy,
  ErrorLineWithMessage,
  Flex,
  Grid,
  GridItem,
  Redacted,
  TextFieldController,
} from "@/shared/components"
import { IconBin } from "@/shared/icons"

interface IFlsCustomersTableRowProps {
  id?: string
  index?: number
  value?: string
  fromState?: string

  isTableHead?: boolean
  isPlaceholder?: boolean
  isViewMode?: boolean
}

export const FlsCustomersTableRow = ({
  id,
  index = 0,
  value,
  isTableHead,
  isPlaceholder,
  isViewMode,
}: IFlsCustomersTableRowProps) => {
  const {
    control,
    register,
    trigger,
    formState: { errors },
  } = useFormContext<IFlsFiltersState>()

  return (
    <Grid
      columns="1f"
      align="center"
      css={{
        minHeight: "$64",
        color: "$theme-b-n3",
        width: "30%",
      }}
    >
      <GridItem css={{ paddingX: "$24" }}>
        {isPlaceholder && !isTableHead ? (
          <Redacted height="$24" width="100px" text animated />
        ) : isTableHead || isViewMode ? (
          <Copy
            scale={isTableHead ? 8 : 10}
            fontWeight={isTableHead ? "bold" : "regular"}
            uppercase={isTableHead}
          >
            {value || "-"}
          </Copy>
        ) : (
          <Flex align="center" justify="between" css={{ gap: "$16" }}>
            <Box css={{ position: "relative", width: "50%" }}>
              <TextFieldController<IFlsFiltersState>
                name={`customersFilter.filterData.${index}.value`}
                control={control}
                register={register}
                trigger={trigger}
                labelProps={{ required: true }}
                description=""
                rules={{ ...lengthRule("Value", 40) }}
                placeholder="Enter a value"
                borderless
              />
              <ErrorLineWithMessage
                errorId={`customersFilter.filterData.${index}.value`}
                errorMessage={errors.customersFilter?.filterData?.[index]?.value?.message}
              />
            </Box>
            <RemoveButton id={id || ""} />
          </Flex>
        )}
      </GridItem>
    </Grid>
  )
}

const RemoveButton = ({ id }: { id: string }) => {
  const { watch, setValue } = useFormContext<IFlsFiltersState>()
  const { customersFilter } = watch()
  const { filterData } = customersFilter

  const onDeleteClick = (e: React.SyntheticEvent, id: string) => {
    e.stopPropagation()

    setValue(
      "customersFilter.filterData",
      filterData.filter((i) => i.id !== id),
      { shouldDirty: true, shouldValidate: true },
    )
  }

  return (
    <ButtonIcon
      type="button"
      ariaLabel={`Remove lane ${id}`}
      icon={<IconBin />}
      onClick={(e) => onDeleteClick(e, id)}
      inputIcon
    />
  )
}
