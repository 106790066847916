import { SlaItemOp } from "@/shared/types"

export const slaOpToText = (op?: SlaItemOp | null) => {
  switch (op) {
    case SlaItemOp.Eq:
      return "Equal to"
    case SlaItemOp.Neq:
      return "Not equal to"
    case SlaItemOp.Gt:
      return "More than"
    case SlaItemOp.Lt:
      return "Less than"
    case SlaItemOp.Gte:
      return "More than or equal to"
    case SlaItemOp.Lte:
      return "Less than or equal to"
    default:
      return ""
  }
}
