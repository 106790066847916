import React from "react"
import * as DialogPrimitive from "@radix-ui/react-dialog"

import { CSS } from "@/stitches/config"

import { ModalPortal, ModalPortalProps } from "./Portal"
import { ModalOverlay } from "./Overlay"
import { ModalCloseButton, ModalCloseButtonProps } from "./CloseButton"
import { ModalHeader } from "./Header"
import { ModalFooter } from "./Footer"
import { ModalContent, ModalContentProps } from "./Content"
import { ModalPanel, ModalPanelProps } from "./Panel"

import { SContentContainer } from "./Content.styles"

export type ModalProps = DialogPrimitive.DialogProps &
  Pick<ModalPortalProps, "container"> &
  Omit<ModalPanelProps, "css"> &
  Omit<ModalContentProps, "css"> & {
    trigger?: React.ReactNode

    overlayCss?: CSS
    contentCss?: CSS
    panelCss?: CSS
    containerCss?: CSS

    sticky?: boolean
    header?: string | React.ReactNode

    closeButton?: ModalCloseButtonProps

    footer?: React.ReactNode
    onInteractOutside?: (e?: React.SyntheticEvent) => void
    onEscapeKeyDown?: () => void
  }

export const Modal = React.forwardRef<HTMLDivElement, ModalProps>(
  (
    {
      children,
      trigger,
      container,
      align,
      gap,
      nested,
      fullscreen,
      overlayCss,
      scrollable,
      panelCss,
      contentCss,
      containerCss,
      header,
      sticky,
      closeButton,
      footer,
      open,
      onOpenChange,
      onInteractOutside,
      onEscapeKeyDown,
      ...props
    },
    forwardedRef,
  ) => {
    const overlayProps = {
      css: overlayCss,
    }

    const panelProps = {
      align,
      gap,
      nested,
      fullscreen,
      css: panelCss,
      onInteractOutside,
      onEscapeKeyDown,
    }

    const contentProps = {
      css: contentCss,
      scrollable,
      ...props,
    }

    return (
      <DialogPrimitive.Dialog open={open} onOpenChange={onOpenChange}>
        <DialogPrimitive.Trigger asChild>{trigger}</DialogPrimitive.Trigger>
        <ModalPortal container={container}>
          <ModalOverlay nested={nested} {...overlayProps} />

          <ModalPanel tabIndex={undefined} ref={forwardedRef} {...panelProps}>
            <ModalContent {...contentProps}>
              <ModalHeader header={header} sticky={sticky}>
                <ModalCloseButton {...closeButton} />
              </ModalHeader>

              <SContentContainer css={containerCss}>{children}</SContentContainer>

              {footer && <ModalFooter>{footer}</ModalFooter>}
            </ModalContent>
          </ModalPanel>
        </ModalPortal>
      </DialogPrimitive.Dialog>
    )
  },
)

Modal.displayName = "Modal"
