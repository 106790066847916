import { useEffect, useState } from "react"
import { Navigate } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"
import { useTheme } from "next-themes"
import { useAuth0 } from "@auth0/auth0-react"
import { jwtDecode } from "jwt-decode"
import { parseUrl } from "query-string"

import { initialUserData, useGlobalStore } from "@/store"
import { HOME } from "@/constants"
import { carriersApi } from "@/api/carriersApi"
import { ordersApi } from "@/api/ordersApi"
import { slaApi, slaApiMultipartFormData } from "@/api/slaApi"
import { tasksApi } from "@/api/tasksApi"
import { tenderApi } from "@/api/tenderApi"
import { flsApi } from "@/api/flsApi"
import { getMeFn, organizationApi } from "@/api/organizationApi"
import { getDefaultUserOrganization } from "@/shared/utils"
import { ThemeType } from "@/shared/types"
// import { handleLogin } from "@/auth/hooks"

import { Flex, Spinner } from "@/shared/components"

// import { AuthWrapper } from "@/shared/components"
// import { LoginFormContainer } from "@/auth/components"

const services = [
  carriersApi,
  ordersApi,
  organizationApi,
  slaApi,
  slaApiMultipartFormData,
  tasksApi,
  tenderApi,
  flsApi,
]

export const Login = () => {
  const [setUser, setOrganization, setTokens, setTokenLifetime] = useGlobalStore((state) => [
    state.setUser,
    state.setOrganization,
    state.setTokens,
    state.setTokenLifetime,
  ])
  const { setTheme } = useTheme()
  const queryClient = useQueryClient()
  const { isAuthenticated, isLoading, getAccessTokenSilently, user: authUser, loginWithRedirect } = useAuth0()
  const [isLoginFinished, setIsLoginFinished] = useState(false)
  const invitation = parseUrl(location.search).query.invitation as string
  const organization = parseUrl(location.search).query.organization as string

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        const fetchData = async () => {
          const accessToken = await getAccessTokenSilently()
          const { exp = 0 } = jwtDecode(accessToken)

          services.map(
            (service) => (service.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`),
          )
          const { user, organizations } = await getMeFn()
          const defaultOrganization = getDefaultUserOrganization(organizations, user.defaultOrganizationId)

          const userData = {
            ...initialUserData,
            firstName: authUser?.given_name || "",
            lastName: authUser?.family_name || "",
            username: authUser?.email || "",
            authorities: user.authorities,
            defaultOrganizationId: user.defaultOrganizationId,
          }

          queryClient.invalidateQueries()

          setTokens({ accessToken })
          // setTokenLifetime({ tokenLifetime: new Date().getTime() + expiresInTime * 1000 })
          setTokenLifetime({ tokenLifetime: exp * 1000 })
          setUser(userData)
          setTheme(ThemeType.Light)
          setOrganization(defaultOrganization)
          setIsLoginFinished(true)
        }

        fetchData()
      } else {
        loginWithRedirect(
          invitation && organization ? { authorizationParams: { invitation, organization } } : {},
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated])

  if (isLoading || !isLoginFinished) {
    return (
      <Flex align="center" justify="center" css={{ height: 400 }}>
        <Spinner />
      </Flex>
    )
  }

  return <Navigate to={HOME} />

  // return (
  //   <AuthWrapper>
  //     <LoginFormContainer />
  //   </AuthWrapper>
  // )
}
