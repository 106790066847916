import { ButtonIcon, Copy, Flex, Link } from "@/shared/components"
import { IconChevronLeft, IconChevronRight } from "@/shared/icons"
import { createPaginationRange, spaceAndEnterKeyDown } from "@/shared/utils"

interface PaginationProps {
  totalPages?: number
  currentPage: number
  onPageChange: (page: number) => void
}

export const Pagination: React.FC<PaginationProps> = ({ totalPages, currentPage, onPageChange }) => {
  return (
    <Flex align="center" justify="center" css={{ gap: "$20", height: "$56", paddingTop: "$16" }}>
      {totalPages && totalPages > 1 ? (
        <>
          <ButtonIcon
            type="button"
            icon={<IconChevronLeft />}
            ariaLabel="Previous page"
            onClick={() => onPageChange(Math.max(currentPage - 1, 0))}
            disabled={currentPage === 0}
            css={{ width: "$40", height: "$40", borderRadius: "$rounded" }}
          />
          <Flex css={{ gap: "$20" }}>
            {createPaginationRange(totalPages, 1, currentPage + 1).map((item, index) => (
              <Flex
                key={index}
                align="center"
                justify="center"
                css={{
                  width: "$40",
                  height: "$40",
                  color: currentPage + 1 === item ? "$system-black" : "$neutrals-5",
                }}
              >
                {item === 0 ? (
                  <Copy scale={10} fontWeight="bold">
                    ...
                  </Copy>
                ) : (
                  <Link
                    as="button"
                    type="button"
                    scale={10}
                    fontWeight="bold"
                    tabIndex={0}
                    onClick={() => onPageChange(item - 1)}
                    onKeyDown={(e) => {
                      spaceAndEnterKeyDown(e.key) && onPageChange(item - 1)
                    }}
                    css={{ color: "inherit" }}
                  >
                    {item}
                  </Link>
                )}
              </Flex>
            ))}
          </Flex>
          <ButtonIcon
            type="button"
            icon={<IconChevronRight />}
            ariaLabel="Next page"
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage + 1 === totalPages}
            css={{ width: "$40", height: "$40", borderRadius: "$rounded" }}
          />
        </>
      ) : null}
    </Flex>
  )
}
