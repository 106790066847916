import { useFormContext } from "react-hook-form"

import { IconBin } from "@/shared/icons"
import { useIsReader } from "@/shared/utils"
import { IOrganizationState } from "@/shared/types"

import {
  Box,
  ButtonIcon,
  Copy,
  EmailController,
  ErrorLineWithMessage,
  Flex,
  Grid,
  GridItem,
} from "@/shared/components"

interface IUserTableRowProps {
  id?: string
  index?: number
}

export const UrgentNewContactsTableRow = ({ id, index = 0 }: IUserTableRowProps) => {
  const {
    control,
    register,
    trigger,
    setValue,
    getValues,
    resetField,
    formState: { errors },
  } = useFormContext<IOrganizationState>()

  const isRoleReader = useIsReader()

  const onDeleteClick = (e: React.SyntheticEvent) => {
    e.stopPropagation()

    const updatedEmails = [...(getValues().newEmails || [])]
    updatedEmails.splice(index, 1)

    if (updatedEmails.length === 0) {
      resetField("newEmails")
    } else {
      setValue("newEmails", updatedEmails, { shouldValidate: true })
    }
  }

  return (
    <Grid
      columns="11fr 0.5fr"
      align="center"
      css={{
        height: "$64",
        color: "$theme-b-n3",
        borderBottom: "none",
        cursor: "default",
        transition: "150ms ease-out",

        lastOfType: {
          borderRadius: "0 0 $8 $8",
        },
        keyboardFocus: {
          backgroundColor: "$brand-yellow-lightest",
        },
      }}
    >
      <GridItem css={{ paddingX: "$24" }}>
        <Copy scale={10}>
          <Box css={{ position: "relative" }}>
            <EmailController
              name={`newEmails.${index}.email`}
              control={control}
              register={register}
              trigger={trigger}
              placeholder="Name@example.com"
              borderless
              labelProps={{ required: true }}
            />
            <ErrorLineWithMessage
              errorId={`newEmails.${index}.email`}
              errorMessage={errors.newEmails?.[index]?.email?.message}
            />
          </Box>
        </Copy>
      </GridItem>
      {!isRoleReader && id && (
        <Flex justify="end" css={{ paddingX: "$12" }}>
          {
            <ButtonIcon
              type="button"
              ariaLabel={`Remove urgent contact ${id}`}
              icon={<IconBin />}
              onClick={onDeleteClick}
              inputIcon
            />
          }
        </Flex>
      )}
    </Grid>
  )
}
