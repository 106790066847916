import { memo } from "react"

import { Copy } from "@/shared/components"

import { SMenuButton } from "./MenuButton.styles"

interface INavButtonProps {
  ariaLabel: string
  text: string
  icon: React.ReactNode
  onClick?: (event: React.SyntheticEvent) => void
  selected?: boolean
}

export const MenuButton = memo(({ ariaLabel, text, icon, onClick, selected }: INavButtonProps) => {
  return (
    <SMenuButton type="button" aria-label={ariaLabel} onClick={onClick} selected={selected}>
      {icon}
      <Copy color="theme-b-n3" fontWeight="bold" css={{ display: "none", "@md": { display: "block" } }}>
        {text}
      </Copy>
    </SMenuButton>
  )
})

MenuButton.displayName = "MenuButton"
