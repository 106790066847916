import { useMutation, useQueryClient } from "@tanstack/react-query"
import { isAxiosError } from "axios"

import { updateOrganizationFn } from "@/api/organizationApi"
import { showToast, transformOrganizationRequest } from "@/shared/utils"
import { IOrganizationState } from "@/shared/types"

export const useUpdateOrganization = (organizationId: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    (data: IOrganizationState) => {
      return updateOrganizationFn(organizationId, transformOrganizationRequest(data))
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["allOrganizations"],
        })
        queryClient.invalidateQueries({
          queryKey: ["allUserOrganizations"],
        })
        queryClient.invalidateQueries({
          queryKey: ["organizationById"],
        })
        queryClient.invalidateQueries({
          queryKey: ["userById"],
        })
        showToast({ type: "success", text: "Saved successfully" })
      },
      onError: (error) => {
        if (isAxiosError(error)) {
          showToast({ type: "error", text: error.response?.data.errorMessage || error.message })
        }
      },
    },
  )
}
