import { CarrierFormContainer } from "@/carrier/components"
import { Box, Copy, GridContainer, Spacer } from "@/shared/components"
import { IllustrationLogo } from "@/shared/illustrations"
import { CommonLayout } from "@/shared/layouts/common"

export const CarrierLogin = () => {
  return (
    <CommonLayout>
      <GridContainer
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          background: "$neutrals-3",
        }}
        fullBleed={{ "@initial": false, "@md": true }}
      >
        <Box css={{ background: "$system-white", padding: "$40", borderRadius: "$16" }}>
          <IllustrationLogo theme="dark" css={{ display: "inline-block", width: "fit-content" }} />
          <CarrierFormContainer />
          <Spacer size={8} />
          <Copy color="neutrals-5">© 2024 Apollo. All Rights Reserved</Copy>
        </Box>
      </GridContainer>
    </CommonLayout>
  )
}
