import { memo } from "react"
import { Colors, ResponsiveProp } from "@/stitches/types"
import { Copy, Flex, TypographyScale } from "@/shared/components"

const getStatusColor = (status: boolean): Colors => (status ? "system-white" : "special-disabled")

export const ActivityStatusLabel = memo(
  ({
    status,
    scale = 8,
    filled = false,
    big,
  }: {
    status: boolean
    scale?: TypographyScale | ResponsiveProp<TypographyScale>
    filled?: boolean
    big?: boolean
  }) => {
    const label = status ? "Active" : "Inactive"

    return filled ? (
      <Flex
        align="center"
        css={{
          height: big ? "$32" : "$24",
          borderRadius: "$32",
          padding: `$2 $${big ? "16" : "8"}`,
          backgroundColor: status ? "$specials-info" : "$specials-disabled",
          width: "fit-content",
        }}
      >
        <Copy scale={scale} color={getStatusColor(status)} truncate fontWeight="bold">
          {label}
        </Copy>
      </Flex>
    ) : (
      <Copy scale={scale} color={getStatusColor(status)} truncate fontWeight="bold">
        {label}
      </Copy>
    )
  },
)

ActivityStatusLabel.displayName = "ActivityStatusLabel"
