import deepmerge from "deepmerge"
import cx from "classnames"

import { ComponentProps, ResponsiveProp, Colors } from "@/stitches/types"
import { atomicClassNames } from "@/stitches/utils"

import { SCopy } from "./Copy.styles"

export interface ICopyProps extends Omit<ComponentProps<typeof SCopy>, "color"> {
  color?: Colors | ResponsiveProp<Colors>
  uppercase?: boolean | ResponsiveProp<boolean>
  dataTestid?: string
  wordBreak?: "normal" | "break-word" | "break-all"
}

/**
 * Use for paragraphs and spans of text with options including scale and tracking.
 */
export const Copy = ({
  as,
  className,
  color = "system-inherit",
  scale = 8,
  dataTestid,
  wordBreak,
  ...props
}: ICopyProps) => {
  return (
    <SCopy
      as={as}
      className={cx(
        atomicClassNames({
          color,
          wordBreak,
        }).toString(),
        className,
      )}
      data-testid={dataTestid}
      {...deepmerge(
        {
          ...props,
          scale,
        },
        {},
      )}
    />
  )
}
