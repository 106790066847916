import { motion } from "framer-motion"

import { styled } from "@/stitches/config"

export const SAccordionPanel = styled(motion.div, {
  backgroundColor: "transparent",
  color: "$theme-b-n3",
})

export const SAccordionContent = styled("div", {
  paddingY: "$24",

  "@sm": {
    paddingY: "$32",
  },
})
