import { useQuery } from "@tanstack/react-query"
import { isAxiosError } from "axios"

import { getOrganizationSlaFn } from "@/api/slaApi"
import { showToast, transformOrganizationSlaResponse } from "@/shared/utils"

export const useOrganizationSla = (id: string) => {
  const isEnabled = !!id

  return useQuery(["organizationSla", id], () => getOrganizationSlaFn(id), {
    cacheTime: isEnabled ? 5 * 60 * 1000 : 0,
    enabled: isEnabled,
    select: (data) => transformOrganizationSlaResponse(data || []),
    onError: (error) => {
      if (isAxiosError(error)) {
        showToast({ type: "error", text: error.response?.data.errorMessage || error.message })
      }
    },
  })
}
