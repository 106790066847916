import { SlaField } from "@/shared/types"

export const slaKeyToText = (type?: SlaField | null) => {
  switch (type) {
    case SlaField.Shipper:
      return "Customer"
    case SlaField.ExcludedCarriers:
      return "Carrier"
    case SlaField.Consignee:
      return "Consignee"
    case SlaField.Delivery:
      return "On-time delivery"
    case SlaField.Tender:
      return "Tender acceptance"
    case SlaField.Tracking:
      return "Automated tracking"
    case SlaField.Unloading:
      return "Unloading requirement"
    case SlaField.Cargo:
      return "Min cargo insurance"
    case SlaField.Haul:
      return "Consider no-haul consignee"
    case SlaField.Brokers:
      return "Allow brokers to bid"
    case SlaField.AddressFrom:
      return "Lane origin"
    case SlaField.AddressTo:
      return "Lane destination"
    default:
      return ""
  }
}
