import { getIconRotation } from "../utils"

import { IIconProps, withIcon } from "../Icon"

const IconDownloadImpl = withIcon(
  '<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M6 20h12m-6-4V4m0 0 3.5 3.5M12 4 8.5 7.5"/>',
)

export const IconDownload = ({ direction, ...props }: IIconProps) => (
  <IconDownloadImpl {...props} rotate={getIconRotation(direction)} />
)

export const IconDownloadTop = (props: IIconProps) => <IconDownload {...props} direction="right" />
export const IconDownloadDown = (props: IIconProps) => <IconDownload {...props} direction="left" />
