import { FeatureFlags, OrgProperties } from "@/shared/types"

export const featuresOnLoad = [
  FeatureFlags.FEATURE_FLAG_USE_CARRIER_ID_INSTEAD_SCAC,
  FeatureFlags.FEATURE_FLAG_FORCE_TENDERS,
]

export const orgPropertiesOnLoad = [
  OrgProperties.FEATURE_FLAG_MANUAL_CARRIER_ASSIGN_ENABLED,
]

/*
    All BE feature flags (18.06.2024)
    FEATURE_FLAG_WORKING_HOURS,
    FEATURE_FLAG_ADVANCED_LOGIC_OF_FINDING_CARRIER,
    FEATURE_FLAG_TENDER_UPDATES,
    FEATURE_FLAG_MARKET_RATE_MAX_BUY,
    FEATURE_FLAG_FIND_MARKET_RATES,
    FEATURE_FLAG_FIND_IPAAS_DAT_MARKET_RATES,
    FEATURE_FLAG_NEW_URGENT_CONTACTS,
    FEATURE_FLAG_EVENTS,


    carriers
    CARRIER_UBER_ENABLED,
    FEATURE_FLAG_SPECIAL_CARRIER_SERVICES,
    FEATURE_FLAG_SYNC_CARRIERS_WITH_HW,

    emails
    EMAIL_SEND_URGENT_TO_NOT_ELIGIBLE_ORDERS,

    other
    CARRIER_EMAIL_TEMPLATE_AB_TESTING,
    FEATURE_FLAG_SNOWFLAKE_SYNC,
    FEATURE_FLAG_BATCH_ORDERS_EMAIL,
    FEATURE_FLAG_SENDGRID_TEMPLATES,
    FEATURE_FLAG_SAVE_FDS_REQUESTS,
*/
