import { isAxiosError } from "axios"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { updateCurrentSourceFn } from "@/api/mrktsApi"
import { showToast } from "@/shared/utils"
import { ICurrentSource } from "@/shared/types"

export const useUpdateCurrentSource = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ currentSource, orgId }: { currentSource: ICurrentSource; orgId: string }) => {
      const formattedSource: ICurrentSource = { name: currentSource.name === "" ? null : currentSource.name }

      return updateCurrentSourceFn(formattedSource, orgId)
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ["currentAndAvailableSource"],
        })
      },
      onError: (error) => {
        if (isAxiosError(error)) {
          showToast({ type: "error", text: error.response?.data.errorMessage || error.message })
        }
      },
    },
  )
}
