import { OrderReasonType } from "@/shared/types"

export const orderReasonToText = (reason?: OrderReasonType) => {
  switch (reason) {
    case OrderReasonType.NotEligible:
      return "Not eligible"
    case OrderReasonType.NoCarriersLeft:
      return "No carriers left"
    case OrderReasonType.NoCarriersFound:
      return "No carriers found"
    case OrderReasonType.PickupTimeTooClose:
      return "Pickup time too close"
    case OrderReasonType.SentToManualProcessing:
      return "Sent to manual processing"
    case OrderReasonType.OrderCanceledInMcleod:
      return "Order canceled in McLeod"
    case OrderReasonType.RevenuewCodeChangedFromDfToZz:
      return "Revenue code changed from DF to ZZ"
    case OrderReasonType.CarrierManuallyAssignedInMcleod:
      return "Carrier manually assigned in McLeod"
    case OrderReasonType.CoverageIsInvalid:
      return "Coverage is invalid"
    case OrderReasonType.MissingKmaCode:
      return "Missing KMA code"
    case OrderReasonType.SmthWentWrong:
      return "Smth went wrong"
    case OrderReasonType.CanceledByShipper:
      return "Canceled by shipper"
    case OrderReasonType.CarrierAcceptedTender:
      return "Carrier accepted tender"
    case OrderReasonType.ReachedPickupDate:
      return "Reached pickup date"
    case OrderReasonType.AllCarriersRejected:
      return "All carriers rejected"
    case OrderReasonType.NoCarriersResponded:
      return "No carriers responded"
    case OrderReasonType.NoCarriersAccepted:
      return "No carriers accepted"
    case OrderReasonType.MajorUpdate:
      return "Major change"
    case OrderReasonType.NoStrategyFound:
      return "No strategy found"
    case OrderReasonType.NoWinnerDefined:
      return "No winner defined"
    default:
      return "-"
  }
}
