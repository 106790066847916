import { useMutation, useQueryClient } from "@tanstack/react-query"
import { isAxiosError } from "axios"

import { updateOrganizationCarriersFn } from "@/api/slaApi"
import { carriersToCarriersDto, showToast } from "@/shared/utils"
import { IOrganizationCarrier } from "@/shared/types"

export const useUpdateOrganizationCarrier = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ orgId, data, toDelete }: { orgId: string; data: IOrganizationCarrier[]; toDelete: string[] }) =>
      updateOrganizationCarriersFn({
        toSave: carriersToCarriersDto(data, orgId),
        toDelete,
      }),
    {
      onSuccess: (data: IOrganizationCarrier[]) => {
        queryClient.invalidateQueries({
          queryKey: ["organizationCarriers"],
        })
        queryClient.invalidateQueries({
          queryKey: ["organizationCarriersFull"],
        })
        queryClient.invalidateQueries({
          queryKey: ["organizationCarriersStrategies"],
        })

        showToast({
          type: "success",
          text: `${data[0]?.name ? `Carrier [${data[0].name}] saved` : "Saved"}`,
        })
      },
      onError: (error) => {
        if (isAxiosError(error)) {
          showToast({ type: "error", text: error.response?.data || error.message })
        }
      },
    },
  )
}
