import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useFormContext } from "react-hook-form"

import { useOrganizationSla } from "@/management/hooks"
import { findSlaAdditionalItem, slaFieldToText, useIsReader } from "@/shared/utils"
import { RouteParams, SlaField, IOrganizationState } from "@/shared/types"
import { IconPencil } from "@/shared/icons"
import { MANAGEMENT } from "@/constants"

import {
  Accordion,
  AccordionButton,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Box,
  ButtonIcon,
  Copy,
  Flex,
  Spinner,
  Stack,
  Title,
} from "@/shared/components"
import { SlaContent } from "@/management/components"

export const OrganizationSlaSettings = () => {
  const navigate = useNavigate()
  const { organizationId } = useParams<keyof RouteParams>() as RouteParams
  const { setValue } = useFormContext<IOrganizationState>()
  const isRoleReader = useIsReader()

  const { isLoading: organizationSlaLoading, data: slaData } = useOrganizationSla(organizationId)

  const slaKeysArr = slaData ? slaData.map((i) => i.name) : []

  useEffect(() => {
    if (slaData) {
      setValue("sla", slaData)
    }
  }, [slaData, setValue])

  return (
    <Stack space={32}>
      <Flex align="center" css={{ gap: "$16" }}>
        <Title as="h2" scale={6} color="system-black">
          SLA Settings
        </Title>
        {!isRoleReader && (
          <ButtonIcon
            type="button"
            ariaLabel="Edit sla"
            icon={<IconPencil />}
            onClick={() => {
              navigate(`${MANAGEMENT}/edit/organization/sla/${organizationId}`)
            }}
            inputIcon
          />
        )}
      </Flex>
      {organizationSlaLoading ? (
        <Flex align="center" justify="center" css={{ height: 400 }}>
          <Spinner />
        </Flex>
      ) : slaData && slaData.length === 0 ? (
        <Copy color="theme-n6-n5">There is no data yet</Copy>
      ) : (
        <Box
          css={{
            backgroundColor: "$system-white",
            borderRadius: "$8",
            padding: "$40 $56",
            border: "1px solid $theme-n3-n7",
          }}
        >
          <Accordion multiple>
            {findSlaAdditionalItem(slaKeysArr) || slaKeysArr.includes(SlaField.ExcludedCarriers) ? (
              <AccordionItem value={SlaField.ExcludedCarriers}>
                <AccordionHeader>
                  <AccordionButton>{slaFieldToText(SlaField.ExcludedCarriers)}</AccordionButton>
                </AccordionHeader>
                <AccordionPanel>
                  <SlaContent
                    name={findSlaAdditionalItem(slaKeysArr) || SlaField.ExcludedCarriers}
                    slaData={slaData}
                    isViewMode
                  />
                </AccordionPanel>
              </AccordionItem>
            ) : null}
            {slaKeysArr.includes(SlaField.Delivery) ? (
              <AccordionItem value={SlaField.Delivery}>
                <AccordionHeader>
                  <AccordionButton>{slaFieldToText(SlaField.Delivery)}</AccordionButton>
                </AccordionHeader>
                <AccordionPanel>
                  <SlaContent name={SlaField.Delivery} slaData={slaData} isViewMode />
                </AccordionPanel>
              </AccordionItem>
            ) : null}
            {slaKeysArr.includes(SlaField.Tender) ? (
              <AccordionItem value={SlaField.Tender}>
                <AccordionHeader>
                  <AccordionButton>{slaFieldToText(SlaField.Tender)}</AccordionButton>
                </AccordionHeader>
                <AccordionPanel>
                  <SlaContent name={SlaField.Tender} slaData={slaData} isViewMode />
                </AccordionPanel>
              </AccordionItem>
            ) : null}
            {slaKeysArr.includes(SlaField.Tracking) ? (
              <AccordionItem value={SlaField.Tracking}>
                <AccordionHeader>
                  <AccordionButton>{slaFieldToText(SlaField.Tracking)}</AccordionButton>
                </AccordionHeader>
                <AccordionPanel>
                  <SlaContent name={SlaField.Tracking} slaData={slaData} isViewMode />
                </AccordionPanel>
              </AccordionItem>
            ) : null}
            {slaKeysArr.includes(SlaField.Unloading) ? (
              <AccordionItem value={SlaField.Unloading}>
                <AccordionHeader>
                  <AccordionButton>{slaFieldToText(SlaField.Unloading)}</AccordionButton>
                </AccordionHeader>
                <AccordionPanel>
                  <SlaContent name={SlaField.Unloading} slaData={slaData} isViewMode />
                </AccordionPanel>
              </AccordionItem>
            ) : null}
            {slaKeysArr.includes(SlaField.Cargo) ? (
              <AccordionItem value={SlaField.Cargo}>
                <AccordionHeader>
                  <AccordionButton>{slaFieldToText(SlaField.Cargo)}</AccordionButton>
                </AccordionHeader>
                <AccordionPanel>
                  <SlaContent name={SlaField.Cargo} slaData={slaData} isViewMode />
                </AccordionPanel>
              </AccordionItem>
            ) : null}
            {slaKeysArr.includes(SlaField.Haul) ? (
              <AccordionItem value={SlaField.Haul}>
                <AccordionHeader>
                  <AccordionButton>{slaFieldToText(SlaField.Haul)}</AccordionButton>
                </AccordionHeader>
                <AccordionPanel>
                  <SlaContent name={SlaField.Haul} slaData={slaData} isViewMode />
                </AccordionPanel>
              </AccordionItem>
            ) : null}
            {slaKeysArr.includes(SlaField.Brokers) ? (
              <AccordionItem value={SlaField.Brokers}>
                <AccordionHeader>
                  <AccordionButton>{slaFieldToText(SlaField.Brokers)}</AccordionButton>
                </AccordionHeader>
                <AccordionPanel>
                  <SlaContent name={SlaField.Brokers} slaData={slaData} isViewMode />
                </AccordionPanel>
              </AccordionItem>
            ) : null}
          </Accordion>
        </Box>
      )}
    </Stack>
  )
}
