import { useCallback } from "react"
import { useParams } from "react-router-dom"
import { useFormContext } from "react-hook-form"

import { useModal, useModalActions } from "@/shared/hooks"
import { ModalType, RouteParams, IOrganizationState, carrierInitialState } from "@/shared/types"
import { useUpdateOrganizationCarrier } from "@/shared/data"
import { useGlobalStore } from "@/store"

import { Button, Copy, Flex, Stack, Title } from "@/shared/components"
import { GeneralModal } from "@/modals"

export const DeleteCarrierModal = ({ isViewMode }: { isViewMode?: boolean }) => {
  const { organizationId } = useParams<keyof RouteParams>() as RouteParams
  const { modalProps } = useModal(ModalType.DeleteCarrierModal)
  const { close } = useModalActions()
  const [page, setPage] = useGlobalStore((state) => [state.page, state.setPage])

  const { watch, resetField, clearErrors, setValue, getValues } = useFormContext<IOrganizationState>()
  const { newCarrier, carriersToDelete, carriers } = watch()
  const { id } = newCarrier

  const { mutate: updateOrganizationCarriers } = useUpdateOrganizationCarrier()

  const handleContinueClick = useCallback(() => {
    if (id && !isNaN(+id)) {
      setValue("carriersToDelete", [...carriersToDelete, id], { shouldDirty: true })
    }

    close(ModalType.DeleteCarrierModal)

    setTimeout(() => {
      resetField("newCarrier", { defaultValue: carrierInitialState })
      clearErrors("newCarrier")

      if (isViewMode && organizationId) {
        updateOrganizationCarriers({
          orgId: organizationId,
          data: [],
          toDelete: getValues("carriersToDelete"),
        })

        setValue("carriersToDelete", [])

        if (carriers.length === 1 && page > 0) {
          setPage(page - 1)
        }
      }
    }, 300)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    carriersToDelete,
    clearErrors,
    close,
    getValues,
    id,
    isViewMode,
    organizationId,
    resetField,
    setValue,
    updateOrganizationCarriers,
  ])

  const handleCancelClick = useCallback(() => {
    close(ModalType.DeleteCarrierModal)
    setTimeout(() => {
      resetField("newCarrier", { defaultValue: carrierInitialState })
      clearErrors("newCarrier")
    }, 300)
  }, [clearErrors, close, resetField])

  return (
    <GeneralModal
      {...modalProps}
      closeButton={{ onClick: handleCancelClick }}
      onInteractOutside={handleCancelClick}
      onEscapeKeyDown={handleCancelClick}
      footer={
        <Flex justify="end" css={{ gap: "$16", paddingBottom: "$8" }}>
          <Button action="destructive" onClick={handleContinueClick}>
            Delete carrier
          </Button>
          <Button action="secondary" onClick={handleCancelClick}>
            Cancel
          </Button>
        </Flex>
      }
    >
      <Stack space={12} css={{ padding: "$64 $24 $0" }}>
        <Title as="h3" scale={2} color="system-black">
          Do you want to delete the carrier?
        </Title>
        <Copy scale={10} color="theme-n6-n5" css={{ paddingBottom: "$8" }}>
          In case you press Delete carrier, your carrier will be deleted permanently
        </Copy>
      </Stack>
    </GeneralModal>
  )
}
