import { styled } from "@/stitches/config"
import { boxShadows } from "@/stitches/utils"

export const SPopoverAnchorButton = styled("button", {
  reset: true,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "$16",
  height: "$40",
  backgroundColor: "$system-white",
  color: "$theme-b-n3",
  cursor: "pointer",

  keyboardFocus: {
    boxShadow: boxShadows.input.focus,
    backgroundColor: "$brand-yellow-lightest",
  },

  disabled: {
    color: "$theme-n5-n6",
    cursor: "default",
  },
})

export const ViewSelectedSPopoverAnchorButton = styled(SPopoverAnchorButton, {
  padding: "$0 $12",
  borderRadius: "$8",
  boxShadow: boxShadows.input.initial,

  hover: {
    boxShadow: boxShadows.input.hover,
  },

  variants: {
    active: {
      true: {
        boxShadow: boxShadows.input.focus,
        backgroundColor: "$brand-yellow-lightest",

        hover: {
          boxShadow: boxShadows.input.focus,
        },
      },
    },
  },
})

export const SimpleSelectSPopoverAnchorButton = styled(SPopoverAnchorButton, {
  gap: "$8",
  width: "100%",
  height: "$48",
  backgroundColor: "$theme-w-n9",
  borderRadius: "$8",
  boxShadow: boxShadows.input.initial,
  transition: "100ms boxShadow ease-out",
  outline: "none",
  padding: "$0 $16",
  boxSizing: "border-box",

  hover: {
    boxShadow: boxShadows.input.hover,
  },

  disabled: {
    backgroundColor: "$theme-n1-n10",
    color: "$neutrals-5",

    hover: {
      boxShadow: boxShadows.input.initial,
    },
  },

  variants: {
    active: {
      true: {
        boxShadow: boxShadows.input.focus,
        backgroundColor: "$brand-yellow-lightest",

        hover: {
          boxShadow: boxShadows.input.focus,
        },
      },
    },
    hasError: {
      true: {
        boxShadow: boxShadows.input.error,

        hover: {
          boxShadow: boxShadows.input.hover,
        },

        keyboardFocus: {
          boxShadow: boxShadows.input.focus,
          backgroundColor: "$brand-yellow-lightest",
        },
      },
    },
  },
})

export const ContactsSelectSPopoverAnchorButton = styled(SPopoverAnchorButton, {
  gap: "$8",
  width: "100%",
  height: "$48",
  backgroundColor: "$theme-w-n9",
  transition: "100ms boxShadow ease-out",
  outline: "none",
  boxSizing: "border-box",
  whiteSpace: "nowrap",

  hover: {
    color: "$neutrals-7",
  },

  disabled: {
    backgroundColor: "$theme-n1-n10",
    color: "$neutrals-5",

    hover: {
      color: "$neutrals-7",
    },
  },

  variants: {
    active: {
      true: {
        hover: {
          color: "$neutrals-7",
        },
      },
    },
    hasError: {
      true: {
        color: "$neutrals-7",

        hover: {
          color: "$neutrals-7",
        },

        keyboardFocus: {
          color: "$neutrals-7",
        },
      },
    },
  },
})
