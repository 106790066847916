import { useFormContext } from "react-hook-form"

import { numericPattern } from "@/shared/rules"
import { StrategyType, IOrganizationState, IOrganizationStrategy } from "@/shared/types"

import { Copy, Flex, Grid, GridItem, Spacer, Stack, TextFieldController } from "@/shared/components"

import { CarrierScacSelect } from "./CarrierScacSelect"

export const Critical = ({ isBroker, isViewMode }: { isBroker: boolean; isViewMode?: boolean }) => {
  const {
    watch,
    control,
    register,
    trigger,
    formState: { errors },
  } = useFormContext<IOrganizationState>()
  const { criticalStrategy } = watch()

  return (
    <Stack space={32}>
      <Grid columns="1fr 1fr 1fr 1fr" columnGap={16}>
        <GridItem>
          {isViewMode ? (
            <Flex direction="column" wrap>
              <Copy scale={10} color="neutrals-5" fontWeight="semiBold">
                Select carriers
              </Copy>
              <Spacer size={4} />
              <Flex align="center" css={{ minHeight: "$48", paddingY: "$12" }}>
                <Copy>{criticalStrategy.scacs.map((i) => i.scac).join(", ")}</Copy>
              </Flex>
            </Flex>
          ) : (
            <CarrierScacSelect type={StrategyType.Critical} isBroker={isBroker} />
          )}
        </GridItem>
        <GridItem>
          <TextFieldController<IOrganizationState>
            name="criticalStrategy.finishPoint"
            control={control}
            register={register}
            trigger={trigger}
            labelProps={{ required: !isViewMode && criticalStrategy.enabled }}
            description="Finish point, Business days before Pickup"
            rules={{
              ...numericPattern,
              validate: {
                max: (v: string) => parseInt(v || "0") < 22 || "Max value not met",
                min: (v: string) => v.length === 0 || parseInt(v || "0") >= 0 || "Min value not met",
                lessThanPrev: (v: string, { waterfallStrategy, waitAllStrategy, firstComeStrategy }) => {
                  const prevStrategy: IOrganizationStrategy = isBroker
                    ? firstComeStrategy.enabled
                      ? firstComeStrategy
                      : waterfallStrategy
                    : waitAllStrategy.enabled
                    ? waitAllStrategy
                    : waterfallStrategy

                  if (prevStrategy.enabled && criticalStrategy.enabled) {
                    return (
                      parseInt(v) <= parseInt(prevStrategy.finishPoint || "0") ||
                      "Should not exceed the previous strategy’s finish point"
                    )
                  }

                  return true
                },
              },
            }}
            borderless={isViewMode}
            isViewMode={isViewMode}
            error={errors.criticalStrategy?.finishPoint?.message}
          />
        </GridItem>
        <GridItem>
          <TextFieldController<IOrganizationState>
            name="criticalStrategy.responseTime"
            control={control}
            register={register}
            trigger={trigger}
            labelProps={{ required: !isViewMode && criticalStrategy.enabled }}
            description="Response time, minutes"
            rules={{
              ...numericPattern,
              validate: {
                max: (v: string) => parseInt(v || "0") < 1000 || "Max value exceeded",
                min: (v: string) => v.length === 0 || parseInt(v || "0") > 0 || "Min value not met",
              },
            }}
            borderless={isViewMode}
            isViewMode={isViewMode}
            error={errors.criticalStrategy?.responseTime?.message}
          />
        </GridItem>
      </Grid>
    </Stack>
  )
}
