import { isAxiosError } from "axios"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { shallow } from "zustand/shallow"

import { deleteOrganizationFn } from "@/api/organizationApi"
import { showToast } from "@/shared/utils"
import { useGlobalStore } from "@/store"
import { useAllUserOrganizations } from "@/management/hooks"
import { mainOrganizationId } from "@/constants"
import { IDeleteOrganizationResponse, IOrganizationResponse } from "@/shared/types"

export const useDeleteOrganization = (id: string) => {
  const queryClient = useQueryClient()
  const [setOrganization, user] = useGlobalStore((state) => [state.setOrganization, state.user], shallow)
  const { data: organizations } = useAllUserOrganizations(user.username)

  return useMutation(() => deleteOrganizationFn(id), {
    onSuccess: (data: IDeleteOrganizationResponse) => {
      const mainOrg = organizations?.find((i) => i.id === mainOrganizationId) || ({} as IOrganizationResponse)

      setOrganization(mainOrg)
      showToast({ type: "success", text: `Organization "${data.organizationName}" has been deleted` })
      queryClient.invalidateQueries({
        queryKey: ["allOrganizations"],
      })
      queryClient.invalidateQueries({
        queryKey: ["allUserOrganizations"],
      })
      queryClient.invalidateQueries({
        queryKey: ["organizationById"],
      })
    },
    onError: (error) => {
      if (isAxiosError(error)) {
        showToast({ type: "error", text: "Operation failed. Please try again" })
      }
    },
  })
}
