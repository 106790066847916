import { isAxiosError } from "axios"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { showToast } from "@/shared/utils"
import { IUrgentContacts } from "@/shared/types"
import { createUrgentContactsFn } from "@/api/organizationApi"

export const useCreateUrgentContacts = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (data: { organizationId: string; requestBody: IUrgentContacts[] }) => createUrgentContactsFn(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["allUrgentContacts"],
        })
      },
      onError: (error) => {
        if (isAxiosError(error)) {
          showToast({ type: "error", text: "Operation failed. Please try again" })
        }
      },
    },
  )
}
