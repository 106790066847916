import React from "react"

import { FormComponentProps } from "@/stitches/types"
import { enterKeyDown } from "@/shared/utils"

import { FormField, IFormFieldCommonProps, getFormFieldProps } from "@/shared/components"

import { SFormInput } from "./FormInput.styles"

interface IFormInputProps
  extends IFormFieldCommonProps,
    FormComponentProps<typeof SFormInput, React.InputHTMLAttributes<HTMLInputElement>> {}

export const FormInput = React.forwardRef<HTMLInputElement, IFormInputProps>(
  (
    {
      labelProps,
      postLabel,
      prefix,
      suffix,
      id,
      hasError,
      borderless,
      hasdoublesuffix,
      isViewMode,
      autoFocus,
      ...props
    },
    ref,
  ) => {
    const fieldProps = getFormFieldProps({
      id,
      labelProps,
      postLabel,
      prefix,
      suffix,
      hasError,
      borderless,
      isViewMode,
      ...props,
    })

    return (
      <FormField id={id} {...fieldProps}>
        <SFormInput
          ref={ref}
          id={id}
          hasSuffix={!!suffix}
          hasdoublesuffix={hasdoublesuffix}
          borderless={borderless}
          isViewMode={isViewMode}
          autoFocus={autoFocus}
          onKeyDown={(e: { key: string; preventDefault: () => void }) => {
            enterKeyDown(e.key) && e.preventDefault()
          }}
          tabIndex={isViewMode ? -1 : 0}
          {...props}
        />
      </FormField>
    )
  },
)

FormInput.displayName = "FormInput"
