import { Copy, Flex } from "@/shared/components"
import { IconCross, IconTick } from "@/shared/icons"

export const ServicePresenceFlag = ({ title, value }: { title: string; value: boolean }) => {
  return (
    <Flex align="center" css={{ gap: "$8" }}>
      <Copy>{title}:</Copy>
      {value ? (
        <IconTick fixedSize width={20} height={20} css={{ color: "$special-success" }} />
      ) : (
        <IconCross fixedSize width={22} height={22} css={{ color: "$special-error" }} />
      )}
    </Flex>
  )
}
