import { memo, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import * as am5 from "@amcharts/amcharts5"
import sub from "date-fns/sub"

import { useOrdersSankey, useAllOrders } from "@/management/hooks"
import {
  FilterType,
  RouteParams,
  StrategyType,
  ISankeyLink,
  ISankeyNode,
  SankeyNodeKey,
  TimeFilter,
  IOrdersSankeyGraphResponse,
  IStatsOrdersByStrategy,
} from "@/shared/types"

import { Box, Flex, FormSelect, Spinner } from "@/shared/components"

import { SankeyGraph, SankeyTable } from "./components"

interface ICalculateBuffer {
  waterfallToWaitAll: number
  waterfallToFirstCome: number
  waterfallToCritical: number
  firstComeToCritical: number
  waitAllToCritical: number
  winner: number
  noWinner: number
  waitAllAndFCFSCount: number
  criticalCount: number
  waitAllWithLaneCount: number
}

const timeFilterList: TimeFilter[] = Object.values(TimeFilter)

const getPercentFromNumber = (a: number, b: number): string => `${parseFloat(((a / b) * 100).toFixed(1))}`

const generateSankeyNode = (
  key: SankeyNodeKey,
  totalCount: number,
  currentCount: number,
  calculateBuffer: ICalculateBuffer,
): ISankeyNode => {
  switch (key) {
    case SankeyNodeKey.ALL:
      return {
        id: key,
        name: `ALL ORDERS - 100%`,
        color: am5.color("#7b7bfe"),
      }
    case SankeyNodeKey.REMAINING:
      return {
        id: key,
        name: `REMAINING ORDERS - ${getPercentFromNumber(currentCount, totalCount)}%`,
        color: am5.color("#00ccf9"),
      }
    case SankeyNodeKey.FILTERED:
      return {
        id: key,
        name: `FILTERED OUT ORDERS - ${getPercentFromNumber(currentCount, totalCount)}%`,
        color: am5.color("#00ccf9"),
      }

    case SankeyNodeKey.WATERFALL:
      return {
        id: key,
        name: `WATERFALL - ${getPercentFromNumber(currentCount, totalCount)}%`,
        color: am5.color("#256ffe"),
      }
    case SankeyNodeKey.WATERFALL_WINNER:
      return {
        id: key,
        name: `Winner Defined - ${getPercentFromNumber(currentCount, totalCount)}%`,
        color: am5.color("#256ffe"),
      }
    case SankeyNodeKey.WATERFALL_PROGRESS:
      return {
        id: key,
        name: `In Progress - ${getPercentFromNumber(currentCount, totalCount)}%`,
        color: am5.color("#256ffe"),
      }
    case SankeyNodeKey.WATERFALL_NO_WINNER:
      return {
        id: key,
        name: `No Winner - ${getPercentFromNumber(currentCount, totalCount)}%`,
        color: am5.color("#256ffe"),
      }

    case SankeyNodeKey.WAIT_FOR_ALL:
      return {
        id: key,
        name: `WAIT FOR ALL - ${getPercentFromNumber(calculateBuffer.waitAllAndFCFSCount, totalCount)}%`,
        color: am5.color("#11d05e"),
      }
    case SankeyNodeKey.WAIT_FOR_ALL_WINNER:
      return {
        id: key,
        name: `Winner Defined - ${getPercentFromNumber(currentCount, totalCount)}%`,
        color: am5.color("#11d05e"),
      }
    case SankeyNodeKey.WAIT_FOR_ALL_PROGRESS:
      return {
        id: key,
        name: `In Progress - ${getPercentFromNumber(currentCount, totalCount)}%`,
        color: am5.color("#11d05e"),
      }
    case SankeyNodeKey.WAIT_FOR_ALL_NO_WINNER:
      return {
        id: key,
        name: `No Winner - ${getPercentFromNumber(currentCount, totalCount)}%`,
        color: am5.color("#11d05e"),
      }

    case SankeyNodeKey.WAIT_FOR_ALL_WITH_LANES:
      return {
        id: key,
        name: `WAIT FOR ALL WITH LANES - ${getPercentFromNumber(
          calculateBuffer.waitAllWithLaneCount,
          totalCount,
        )}%`,
        color: am5.color("#f9af20"),
      }
    case SankeyNodeKey.WAIT_FOR_ALL_WITH_LANES_WINNER:
      return {
        id: key,
        name: `Winner Defined - ${getPercentFromNumber(currentCount, totalCount)}%`,
        color: am5.color("#f9af20"),
      }
    case SankeyNodeKey.WAIT_FOR_ALL_WITH_LANES_PROGRESS:
      return {
        id: key,
        name: `In Progress - ${getPercentFromNumber(currentCount, totalCount)}%`,
        color: am5.color("#f9af20"),
      }
    case SankeyNodeKey.WAIT_FOR_ALL_WITH_LANES_NO_WINNER:
      return {
        id: key,
        name: `No Winner - ${getPercentFromNumber(currentCount, totalCount)}%`,
        color: am5.color("#f9af20"),
      }

    case SankeyNodeKey.FCFS:
      return {
        id: key,
        name: `FIRST COME FIRST SERVE - ${getPercentFromNumber(
          calculateBuffer.waitAllAndFCFSCount,
          totalCount,
        )}%`,
        color: am5.color("#11d05e"),
      }
    case SankeyNodeKey.FCFS_WINNER:
      return {
        id: key,
        name: `Winner Defined - ${getPercentFromNumber(currentCount, totalCount)}%`,
        color: am5.color("#11d05e"),
      }
    case SankeyNodeKey.FCFS_PROGRESS:
      return {
        id: key,
        name: `In Progress - ${getPercentFromNumber(currentCount, totalCount)}%`,
        color: am5.color("#11d05e"),
      }
    case SankeyNodeKey.FCFS_NO_WINNER:
      return {
        id: key,
        name: `No Winner - ${getPercentFromNumber(currentCount, totalCount)}%`,
        color: am5.color("#11d05e"),
      }

    case SankeyNodeKey.CRITICAL:
      return {
        id: key,
        name: `CRITICAL - ${getPercentFromNumber(calculateBuffer.criticalCount, totalCount)}%`,
        color: am5.color("#fe3225"),
      }
    case SankeyNodeKey.CRITICAL_WINNER:
      return {
        id: key,
        name: `Winner Defined - ${getPercentFromNumber(currentCount, totalCount)}%`,
        color: am5.color("#fe3225"),
      }
    case SankeyNodeKey.CRITICAL_PROGRESS:
      return {
        id: key,
        name: `In Progress - ${getPercentFromNumber(currentCount, totalCount)}%`,
        color: am5.color("#fe3225"),
      }
    case SankeyNodeKey.CRITICAL_NO_WINNER:
      return {
        id: key,
        name: `No Winner - ${getPercentFromNumber(currentCount, totalCount)}%`,
        color: am5.color("#fe3225"),
      }

    case SankeyNodeKey.WINNERS:
      return {
        id: key,
        name: `WITH WINNERs - ${getPercentFromNumber(calculateBuffer.winner, totalCount)}%`,
        color: am5.color("#7b7bfe"),
      }
    case SankeyNodeKey.NO_WINNERS:
      return {
        id: key,
        name: `NO WINNERs - ${getPercentFromNumber(calculateBuffer.noWinner, totalCount)}%`,
        color: am5.color("#7b7bfe"),
      }
    default:
      return {}
  }
}

const createLinks = (
  sankey: IOrdersSankeyGraphResponse,
  totalOrders = 0,
): {
  links: ISankeyLink[]
  calculateBuffer: ICalculateBuffer
} => {
  const linksArray: ISankeyLink[] = []

  let winner = 0
  let noWinner = 0

  let waterfallToWaitAll = 0
  let waterfallToFirstCome = 0
  let waterfallToCritical = 0

  let waterfallToWaitAllWithLanes = 0
  let waitAllWithLanesToFCFS = 0
  let waitAllWithLanesToCritical = 0

  let firstComeToCritical = 0
  let waitAllToCritical = 0

  let waitAllAndFCFSCount = 0
  let criticalCount = 0
  let waitAllWithLaneCount = 0

  const pushLinksHelper = (el: IStatsOrdersByStrategy) => {
    el.numberWithWinner > 0 &&
      (linksArray.push({
        from: SankeyNodeKey[el.strategyType],
        to: SankeyNodeKey[`${el.strategyType}_WINNER`],
        value: el.numberWithWinner,
      }),
      linksArray.push({
        from: SankeyNodeKey[`${el.strategyType}_WINNER`],
        to: SankeyNodeKey.WINNERS,
        value: el.numberWithWinner,
      }),
      (winner += el.numberWithWinner))
    el.numberInProgress > 0 &&
      linksArray.push({
        from: SankeyNodeKey[el.strategyType],
        to: SankeyNodeKey[`${el.strategyType}_PROGRESS`],
        value: el.numberInProgress,
      })
    el.numberWinnerNotFound > 0 &&
      linksArray.push({
        from: SankeyNodeKey[el.strategyType],
        to: SankeyNodeKey[`${el.strategyType}_NO_WINNER`],
        value: el.numberWinnerNotFound,
      })
    el.numberOfFinishedWithoutTransition > 0 &&
      (linksArray.push({
        from: SankeyNodeKey[`${el.strategyType}_NO_WINNER`],
        to: SankeyNodeKey.NO_WINNERS,
        value: el.numberOfFinishedWithoutTransition,
        details: `${SankeyNodeKey[el.strategyType]}:${getPercentFromNumber(
          el.numberOfFinishedWithoutTransition,
          totalOrders,
        )}%`,
      }),
      (noWinner += el.numberOfFinishedWithoutTransition))
  }

  if (sankey.total > 0) {
    linksArray.push({
      from: SankeyNodeKey.ALL,
      to: SankeyNodeKey.REMAINING,
      value: sankey.total,
    })
  }

  {
    totalOrders - sankey.total > 0
      ? linksArray.push({
          from: SankeyNodeKey.ALL,
          to: SankeyNodeKey.FILTERED,
          value: totalOrders - sankey.total,
          details: `${SankeyNodeKey.FILTERED}:${getPercentFromNumber(
            totalOrders - sankey.total,
            totalOrders,
          )}%`,
        })
      : null
  }

  sankey.statsList.map((el) => {
    if (el.total === 0) return
    if (el.strategyType === SankeyNodeKey.WATERFALL) {
      linksArray.push({ from: SankeyNodeKey.REMAINING, to: SankeyNodeKey.WATERFALL, value: el.total })

      pushLinksHelper(el)

      el.nextStrategies.map((nextStrategy) => {
        if (nextStrategy.count === 0) return
        if (nextStrategy.strategyType === StrategyType.WaitAll) {
          linksArray.push({
            from: SankeyNodeKey.WATERFALL_NO_WINNER,
            to: SankeyNodeKey.WAIT_FOR_ALL,
            value: nextStrategy.count,
          })
          waterfallToWaitAll += nextStrategy.count
          waitAllAndFCFSCount += nextStrategy.count
        }

        if (nextStrategy.strategyType === StrategyType.WaitAllWithLanes) {
          linksArray.push({
            from: SankeyNodeKey.WATERFALL_NO_WINNER,
            to: SankeyNodeKey.WAIT_FOR_ALL_WITH_LANES,
            value: nextStrategy.count,
          })
          waterfallToWaitAllWithLanes += nextStrategy.count
        }

        if (nextStrategy.strategyType === StrategyType.FirstCome) {
          linksArray.push({
            from: SankeyNodeKey.WATERFALL_NO_WINNER,
            to: SankeyNodeKey.FCFS,
            value: nextStrategy.count,
          })
          waterfallToFirstCome += nextStrategy.count
          waitAllAndFCFSCount += nextStrategy.count
        }

        if (nextStrategy.strategyType === StrategyType.Critical) {
          linksArray.push({
            from: SankeyNodeKey.WATERFALL_NO_WINNER,
            to: SankeyNodeKey.CRITICAL,
            value: nextStrategy.count,
          })
          waterfallToCritical += nextStrategy.count
        }
      })
    }

    if (el.strategyType === SankeyNodeKey.WAIT_FOR_ALL) {
      linksArray.push({
        from: SankeyNodeKey.REMAINING,
        to: SankeyNodeKey.WAIT_FOR_ALL,
        value: el.total - waterfallToFirstCome - waterfallToWaitAll,
      })
      waitAllAndFCFSCount = el.total

      pushLinksHelper(el)

      el.nextStrategies.map((nextStrategy) => {
        if (nextStrategy.count === 0) return
        if (nextStrategy.strategyType === StrategyType.Critical) {
          linksArray.push({
            from: SankeyNodeKey.WAIT_FOR_ALL_NO_WINNER,
            to: SankeyNodeKey.CRITICAL,
            value: nextStrategy.count,
          })
          waitAllToCritical += nextStrategy.count
        }
      })
    }

    if (el.strategyType === SankeyNodeKey.WAIT_FOR_ALL_WITH_LANES) {
      linksArray.push({
        from: SankeyNodeKey.REMAINING,
        to: SankeyNodeKey.WAIT_FOR_ALL_WITH_LANES,
        value: el.total - waterfallToWaitAll - waterfallToWaitAllWithLanes,
      })

      waitAllWithLaneCount = el.total

      pushLinksHelper(el)

      el.nextStrategies.map((nextStrategy) => {
        if (nextStrategy.count === 0) return
        if (nextStrategy.strategyType === StrategyType.FirstCome) {
          linksArray.push({
            from: SankeyNodeKey.WAIT_FOR_ALL_WITH_LANES_NO_WINNER,
            to: SankeyNodeKey.FCFS,
            value: nextStrategy.count,
          })
          waitAllWithLanesToFCFS += nextStrategy.count
        }
        if (nextStrategy.strategyType === StrategyType.Critical) {
          linksArray.push({
            from: SankeyNodeKey.WAIT_FOR_ALL_WITH_LANES_NO_WINNER,
            to: SankeyNodeKey.CRITICAL,
            value: nextStrategy.count,
          })
          waitAllWithLanesToCritical += nextStrategy.count
        }
      })
    }

    if (el.strategyType === SankeyNodeKey.FCFS) {
      linksArray.push({
        from: SankeyNodeKey.REMAINING,
        to: SankeyNodeKey.FCFS,
        value: el.total - waterfallToFirstCome - waterfallToWaitAll - waitAllWithLanesToFCFS,
      })
      waitAllAndFCFSCount = el.total

      pushLinksHelper(el)

      el.nextStrategies.map((nextStrategy) => {
        if (nextStrategy.count === 0) return
        if (nextStrategy.strategyType === StrategyType.Critical) {
          linksArray.push({
            from: SankeyNodeKey.FCFS_NO_WINNER,
            to: SankeyNodeKey.CRITICAL,
            value: nextStrategy.count,
          })
          firstComeToCritical += nextStrategy.count
        }
      })
    }

    if (el.strategyType === SankeyNodeKey.CRITICAL) {
      linksArray.push({
        from: SankeyNodeKey.REMAINING,
        to: SankeyNodeKey.CRITICAL,
        value:
          el.total -
          waterfallToCritical -
          waitAllToCritical -
          firstComeToCritical -
          waitAllWithLanesToCritical,
      })
      criticalCount = el.total

      pushLinksHelper(el)
    }
  })

  return {
    links: linksArray,
    calculateBuffer: {
      waterfallToWaitAll,
      waterfallToFirstCome,
      waterfallToCritical,
      firstComeToCritical,
      waitAllToCritical,
      winner,
      noWinner,
      waitAllAndFCFSCount,
      criticalCount,
      waitAllWithLaneCount,
    },
  }
}

const createNodes = (
  links: ISankeyLink[],
  totalCounts = 0,
  calculateBuffer: ICalculateBuffer,
): ISankeyNode[] => {
  const nodes: ISankeyNode[] = []

  links.map((el) => {
    if (!nodes.find((node) => node.id === el.from)) {
      nodes.push(generateSankeyNode(el.from, totalCounts, el.value, calculateBuffer))
    }
    if (nodes.find((node) => node.id === el.from && !nodes.find((node) => node.id === el.to))) {
      nodes.push(generateSankeyNode(el.to, totalCounts, el.value, calculateBuffer))
    }
  })

  return nodes
}

export const OrderFlow = memo(() => {
  const { organizationId } = useParams<keyof RouteParams>() as RouteParams
  const [selectedLinkDetails, setSelectedLinkDetails] = useState("")
  const [timeFilter, setTimeFilter] = useState(TimeFilter.Last7)

  const { data: sankey, isLoading: isSankeyLoading } = useOrdersSankey(
    organizationId,
    sub(new Date(), { days: Number(timeFilter) - 1 })
      .toJSON()
      .split("T")[0] + "T00:00:00",
  )

  const { data: allOrders, isLoading: isAllOrdersLoading } = useAllOrders({
    orgId: organizationId,
    page: 0,
    filter: FilterType.FDS,
    size: 1,
    disableRefetchInterval: true,
    from:
      sub(new Date(), { days: Number(timeFilter) - 1 })
        .toJSON()
        .split("T")[0] + "T00:00:00",
  })

  const { links, calculateBuffer } = createLinks(
    sankey || { total: 0, statsList: [] },
    allOrders?.totalElements,
  )
  const nodes = createNodes(links, allOrders?.totalElements, calculateBuffer)

  const cachedLinks = useMemo(() => links, [links])
  const cachedNodes = useMemo(() => nodes, [nodes])

  const isLoading = isSankeyLoading || isAllOrdersLoading

  if (isLoading || !sankey || !allOrders) {
    return (
      <Flex align="center" justify="center" css={{ height: 500 }}>
        <Spinner />
      </Flex>
    )
  }

  return (
    <>
      <Box
        css={{
          padding: "$40 $56",
          backgroundColor: "$system-white",
          border: "1px solid $theme-n3-n7",
          borderRadius: "$8",
        }}
      >
        <Box css={{ width: 170 }}>
          <FormSelect
            id="timeFilter"
            name="timeFilter"
            label="Time filter"
            labelProps={{ hidden: true }}
            isDisplayValueDifferent
            value={{
              value: timeFilter,
              label: `Last ${timeFilter} days`,
            }}
            options={timeFilterList.map((timeFilter) => ({
              value: timeFilter,
              label: `Last ${timeFilter} days`,
            }))}
            onValueChange={(value) => {
              setTimeFilter(value as TimeFilter)
              setSelectedLinkDetails("")
            }}
            generalCss={{
              padding: "var(--space-0) var(--space-0) var(--space-0) var(--space-16)",
              minHeight: "var(--space-40)",
              width: "var(--space-40)",
            }}
          />
        </Box>
        {allOrders.totalElements > 0 ? (
          <SankeyGraph
            links={cachedLinks}
            nodes={cachedNodes}
            setSelectedLinkDetails={setSelectedLinkDetails}
          />
        ) : (
          <Flex css={{ height: 100, marginTop: "$32" }}>
            {/*  TODO: Add empty view here */}
            No data
          </Flex>
        )}
      </Box>
      {allOrders.totalElements > 0 && selectedLinkDetails ? (
        <SankeyTable selectedLinkDetails={selectedLinkDetails} timeFilter={timeFilter} />
      ) : null}
    </>
  )
})

OrderFlow.displayName = "OrderFlow"
