import { useFormContext } from "react-hook-form"

import { CarrierOrderStatus, IDashboardData, IDashboardFormData, ModalType } from "@/shared/types"
import { useModalActions } from "@/shared/hooks"
import { useAcceptOrder, useRejectOrder } from "@/carrier/hooks"
import { useGlobalStore } from "@/store"

import { Button, Flex } from "@/shared/components"
import { IconCross, IconTick } from "@/shared/icons"

interface ICarrierOrderButtons extends IDashboardData {
  disabled: boolean
}

export const CarrierOrderButtons = ({
  disabled,
  orderId,
  originalOrderId,
  from,
  to,
  dropOffStartAt,
  pickupStartAt,
  details,
  rate,
  status,
  updatedAt,
  hasOriginalRate,
}: ICarrierOrderButtons) => {
  const [setRejectFunction] = useGlobalStore((state) => [state.setRejectFunction])
  const { open } = useModalActions()
  const { setValue, reset, getValues } = useFormContext<IDashboardFormData>()

  const { mutate: acceptOrder, status: acceptStatus } = useAcceptOrder(originalOrderId, orderId, rate)
  const { mutate: rejectOrder, status: rejectStatus } = useRejectOrder(originalOrderId, orderId)

  const handleRejectClick = () => {
    setValue("currentOrder", {
      orderId,
      originalOrderId,
      from,
      to,
      dropOffStartAt,
      pickupStartAt,
      details,
      rate,
      status,
      updatedAt,
      hasOriginalRate,
    })

    setRejectFunction(() => rejectOrder())

    setTimeout(() => {
      reset(getValues())
      open(ModalType.CarrierOrderReject)
    }, 0)
  }

  const isDisabled =
    disabled ||
    acceptStatus === CarrierOrderStatus.Loading ||
    acceptStatus === CarrierOrderStatus.Success ||
    rejectStatus === CarrierOrderStatus.Loading ||
    rejectStatus === CarrierOrderStatus.Success

  return (
    <Flex css={{ gap: "$16" }}>
      <Button
        size="small"
        icon={<IconTick />}
        css={{ visibility: rate ? "visible" : "hidden" }}
        onClick={() => {
          acceptOrder()
        }}
        disabled={isDisabled}
        copyProps={{ wordBreak: "normal" }}
      >
        Accept
      </Button>
      <Button
        action="secondary"
        size="small"
        icon={<IconCross />}
        onClick={handleRejectClick}
        disabled={isDisabled}
        copyProps={{ wordBreak: "normal" }}
      >
        Reject
      </Button>
    </Flex>
  )
}
