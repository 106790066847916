import { Role } from "@/shared/types"

export const roleToText = (role: Role) => {
  switch (role) {
    case Role.SysAdmin:
      return "SysAdmin"
    case Role.CustomerAdmin:
      return "Customer Admin"
    case Role.Reader:
      return "Reader"
  }
}
