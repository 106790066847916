import { IOrgProperty, OrgProperties } from "@/shared/types"
import { useGlobalStore } from "@/store"

export const getOrgProperties = (keys: OrgProperties[]):  (string | null)[] => {
  const orgProperties: IOrgProperty[] = useGlobalStore.getState().orgProperties

  return keys.map((key) => {
    const prop = orgProperties.find((prop) => prop.key === key)
    return prop?.value || null
  })
}

export const getBooleanOrgProperty = (key: OrgProperties): boolean => {
  const orgProperties: IOrgProperty[] = useGlobalStore.getState().orgProperties

  const prop = orgProperties.find((prop) => prop.key === key)

  if (!prop) {
    return false;
  }

  if (prop.value === 'true') {
    return true;
  }

  return false;
}