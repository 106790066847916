import { Flex } from "@/shared/components"

interface IIconFillerProps {
  borderColor?: string
  color?: string
}

export const IconFiller: React.FC<React.PropsWithChildren<IIconFillerProps>> = ({
  children,
  borderColor,
  color,
}) => {
  return (
    <Flex
      align="center"
      justify="center"
      css={{
        backgroundColor: color ? `$${color}` : "none",
        height: "$32",
        width: "$32",
        borderRadius: "$8",
        borderColor: borderColor ? `1px solid ${borderColor}` : 0,
      }}
    >
      {children}
    </Flex>
  )
}
