import { SlaField } from "@/shared/types"

export const getSlaKeyValues = (type?: SlaField | null) => {
  switch (type) {
    case SlaField.ExcludedCarriers:
    case SlaField.Shipper:
    case SlaField.AddressFrom:
    case SlaField.AddressTo:
      return [SlaField.Shipper, SlaField.ExcludedCarriers, SlaField.AddressFrom, SlaField.AddressTo]
    case SlaField.Delivery:
      return [SlaField.Consignee, SlaField.Delivery]
    case SlaField.Tender:
      return [SlaField.Consignee, SlaField.Tender]
    case SlaField.Tracking:
      return [SlaField.Shipper, SlaField.Consignee, SlaField.Tracking]
    case SlaField.Unloading:
      return [SlaField.Consignee, SlaField.Unloading]
    case SlaField.Cargo:
      return [SlaField.Consignee, SlaField.Cargo]
    case SlaField.Haul:
      return [SlaField.Haul]
    case SlaField.Brokers:
      return [SlaField.Consignee, SlaField.Brokers]
    default:
      return []
  }
}
