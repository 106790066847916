import { IPagination, StatusType, IOrganizationResponse } from "@/shared/types"

interface IAuthority {
  authority: Role
}

export interface IUser {
  id: string
  firstName: string
  lastName: string
  phone: string
  darkTheme: boolean
  username: string
  authorities: IAuthority[]
  defaultOrganizationId: string
  urgentContact: boolean
}

export interface IUserInfo extends Pick<IUser, "authorities" | "defaultOrganizationId"> {}

interface IUserById extends IUser {
  enabled: boolean
  createdAt: string
  updatedAt: string
  updatedBy: string
  lastPasswordReset?: string
}

export interface IUserResponseById extends IUserById {
  organizations: IOrganizationResponse[]
  defaultOrganization: IOrganizationResponse
}

export interface IUsersResponse extends IPagination {
  content: IUserById[]
}

export interface IDeleteUserResponse {
  fullName: string
}

export interface ICreateUserRequest
  extends Omit<IUserById, "id" | "createdAt" | "updatedAt" | "updatedBy" | "lastPasswordReset"> {}

export enum Role {
  Reader = "ROLE_READER",
  SysAdmin = "ROLE_ADMIN",
  CustomerAdmin = "ROLE_COMPANY_ADMIN",
  // Reader = "ROLE_READER",
}

export interface IUserState {
  firstName: string
  lastName: string
  username: string
  urgentContact: boolean
  phone: string
  defaultOrganizationId: string
  availableOrganizations: IOrganizationResponse[]
  role: Role
  enabled: StatusType
  darkTheme: boolean
}

export interface ChangePersonInfoInput extends IUserState {
  oldPassword: string
  newPassword: string
  confirmPassword: string
}
