export const laneRegex = {
  state: /^[A-Z]{2}$/,
  threeDigitZip: /^\d{3}$/,
  fiveDigitZip: /^\d{5}$/,
  kmaCode: /^[A-Z]{2}_[A-Z]{3}$/,
  cityState: /^[\w\s-]+, ?[A-Z]{2}$/,
  any: /^ANY$/i,
}

export const validateLane = (value: string) => {
  if (laneRegex.state.test(value)) {
    return true
  } else if (laneRegex.threeDigitZip.test(value)) {
    return true
  } else if (laneRegex.fiveDigitZip.test(value)) {
    return true
  } else if (laneRegex.kmaCode.test(value)) {
    return true
  } else if (laneRegex.cityState.test(value)) {
    return true
  } else if (laneRegex.any.test(value)) {
    return true
  } else {
    return false
  }
}

export const laneValidationRule = (isWithTooltip?: boolean) => ({
  validate: (value: string) => {
    if (!value) {
      return "This field is required"
    } else if (!validateLane(value)) {
      return isWithTooltip ? "Invalid format. Please view valid formats in the header" : "Invalid format"
    }
    return true
  },
})

export const lengthRule = (field: string, maxLength: number, minLength = 2) => ({
  minLength: {
    value: minLength,
    message: `${field} min length not met`,
  },
  maxLength: {
    value: maxLength,
    message: `${field} max length exceeded`,
  },
})

export const alphanumericPattern = {
  pattern: {
    value: /^[A-Za-z0-9]+$/,
    message: "Only alphanumeric characters allowed",
  },
}

export const alphabeticPattern = {
  pattern: {
    value: /^[a-zA-Z\s]*$/,
    message: "Only alphabetic characters and spaces allowed",
  },
}

export const nonNumericPattern = {
  pattern: {
    value: /^[^0-9]+$/,
    message: "Only letters and symbols allowed",
  },
}

export const requiredPattern = {
  required: {
    value: true,
    message: "Required field",
  },
}

export const numericPattern = {
  pattern: {
    value: /^[0-9]+$/,
    message: "Only numeric characters allowed",
  },
}

export const numericPatternWithDot = {
  pattern: {
    value: /^[0-9]+(\.[0-9]+)?$/,
    message: "Numbers and dot allowed",
  },
}
