import { Navigate, useRoutes } from "react-router-dom"
import { ToastContainer } from "react-toastify"

import { adminRoles, allAdminRoles, allRoles, mainOrganizationId } from "@/constants"

import {
  Login,
  PageNotFound,
  Tasks,
  Home,
  Dashboard,
  Files,
  Management,
  Organization,
  Profile,
  OrganizationView,
  Icons,
  Illustrations,
  EditOrganization,
  EditSlaSettings,
  EditStrategies,
  OrderView,
  Flags,
  Fls,
  Carrier,
  CarrierLogin,
  // EditUser,
  // User,
  // UserView,
} from "@/pages"
import { AuthGuard, Box } from "@/shared/components"
import { ModalsContainer } from "@/modals"

import "react-toastify/dist/ReactToastify.css"
import "@/stitches/fonts/fonts.css"

const App: React.FC = (): JSX.Element => {
  const mainRoutes = {
    path: "/",
    children: [
      {
        path: "/",
        element: <AuthGuard allowedRoles={allRoles} />,
        children: [{ path: "", element: <Home /> }],
      },
      {
        path: "tasks",
        element: <AuthGuard allowedRoles={adminRoles} />,
        children: [{ path: "", element: <Tasks /> }],
      },
      {
        path: "dashboard",
        element: <AuthGuard allowedRoles={adminRoles} />,
        children: [{ path: "", element: <Dashboard /> }],
      },
      {
        path: "files",
        element: <AuthGuard allowedRoles={adminRoles} />,
        children: [{ path: "", element: <Files /> }],
      },
      {
        path: "profile",
        element: <AuthGuard allowedRoles={allRoles} />,
        children: [{ path: "", element: <Profile /> }],
      },
      {
        path: "fls",
        element: <AuthGuard allowedRoles={allRoles} allowedUserOrgIds={[mainOrganizationId]} />,
        children: [{ path: "", element: <Fls /> }],
      },
      {
        path: "management",
        element: <AuthGuard allowedRoles={allRoles} />,
        children: [
          {
            path: "",
            element: <AuthGuard allowedRoles={allRoles} />,
            children: [{ path: "", element: <Management /> }],
          },
          // {
          //   path: "user/:userId",
          //   element: <AuthGuard allowedRoles={allRoles} />,
          //   children: [{ path: "", element: <UserView /> }],
          // },
          {
            path: "organization/:organizationId",
            element: <AuthGuard allowedRoles={allRoles} />,
            children: [
              { path: "", element: <OrganizationView /> },
              { path: "order/:orderId", element: <OrderView /> },
            ],
          },
          {
            path: "create",
            children: [
              {
                path: "organization",
                element: <AuthGuard allowedRoles={adminRoles} />,
                children: [{ path: "", element: <Organization /> }],
              },
              // {
              //   path: "user",
              //   element: <AuthGuard allowedRoles={allAdminRoles} />,
              //   children: [{ path: "", element: <User /> }],
              // },
            ],
          },
          {
            path: "edit",
            children: [
              {
                path: "organization/:organizationId",
                element: <AuthGuard allowedRoles={allAdminRoles} />,
                children: [{ path: "", element: <EditOrganization /> }],
              },
              {
                path: "organization/sla/:organizationId",
                element: <AuthGuard allowedRoles={allAdminRoles} />,
                children: [{ path: "", element: <EditSlaSettings /> }],
              },
              {
                path: "organization/strategies/:organizationId",
                element: <AuthGuard allowedRoles={allAdminRoles} />,
                children: [{ path: "", element: <EditStrategies /> }],
              },
              // {
              //   path: "user/:userId",
              //   element: <AuthGuard allowedRoles={allAdminRoles} />,
              //   children: [{ path: "", element: <EditUser /> }],
              // },
            ],
          },
        ],
      },
    ],
  }

  const devRoutes = {
    path: "*",
    children: [
      { path: "icons", element: <Icons /> },
      { path: "illustrations", element: <Illustrations /> },
      { path: "flags", element: <Flags /> },
    ],
  }

  const baseRoutes = {
    path: "*",
    children: [
      { path: "login", element: <Login /> },
      // { path: "recovery", element: <Recovery /> },
      // { path: "reset", element: <Reset /> },
      { path: "carrier-dashboard-login", element: <CarrierLogin /> },
      { path: "carrier-dashboard", element: <Carrier /> },
      { path: "*", element: <Navigate to="/404" /> },
      { path: "404", element: <PageNotFound /> },
    ],
  }

  const routing = useRoutes([mainRoutes, devRoutes, baseRoutes])

  return (
    <Box>
      <ToastContainer />
      <ModalsContainer />
      {routing}
    </Box>
  )
}

export default App
