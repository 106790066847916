import { memo, useCallback, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { useDebounce } from "use-debounce"
import { Controller, useFormContext } from "react-hook-form"

import { escapeKeyDown, getFeatureFlags, transformCarrierToCarrierId } from "@/shared/utils"
import {
  IScac,
  RouteParams,
  StrategyType,
  IOrganizationState,
  IOrganizationCarrier,
  ICarrierIds,
  FeatureFlags,
} from "@/shared/types"
import { useOrganizationCarriersFull } from "@/management/hooks"
import { uberScac } from "@/constants"

import {
  Box,
  ButtonIcon,
  Copy,
  ErrorLabel,
  Flex,
  FormCheckbox,
  FormInput,
  IconTooltip,
  Popover,
  PopoverAnchor,
  PopoverContent,
  ResponseContentItem,
  Spacer,
  Stack,
} from "@/shared/components"
import { IconChevronTop, IconChevronDown, IconWarning, IconCross, IconSearch } from "@/shared/icons"
import { IllustrationSpinner } from "@/shared/illustrations"

import { SimpleSelectSPopoverAnchorButton } from "@/shared/styles/SPopoverAnchorButton"

interface IItemsSelectProps {
  type: StrategyType
  isBroker?: boolean
  withTooltip?: boolean
}

const transformCarrierToScac = (carrier: IOrganizationCarrier): IScac => {
  return { carrierId: Number(carrier.id), name: carrier.name, scac: carrier.scac }
}

export const CarrierScacSelect = memo(({ type, withTooltip = true, isBroker = false }: IItemsSelectProps) => {
  const [isIdInsteadScac] = getFeatureFlags([FeatureFlags.FEATURE_FLAG_USE_CARRIER_ID_INSTEAD_SCAC])
  const { organizationId } = useParams<keyof RouteParams>() as RouteParams

  const [inputValue, setInputValue] = useState<string>("")
  const [keyword] = useDebounce(inputValue, 500)

  const [isOpen, setIsOpen] = useState(false)
  const triggerRef = useRef<HTMLButtonElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const clearButtonRef = useRef<HTMLButtonElement>(null)
  const isTriggerClick = (event: Event) => event.composedPath().includes(triggerRef.current as EventTarget)
  const { watch, setValue, control, register, trigger } = useFormContext<IOrganizationState>()
  const { waitAllStrategy, firstComeStrategy, criticalStrategy } = watch()
  const { fetchStatus: organizationCarriersFetchStatus, data: carriersData } = useOrganizationCarriersFull({
    orgId: organizationId,
    name: encodeURIComponent(keyword),
    size: 7,
    disableCache: true,
  })

  const carriersFilterByStrategy = (
    carriers: IOrganizationCarrier[],
    strategyType: StrategyType,
  ): IOrganizationCarrier[] => {
    let filteredCarriers = carriers
    if (strategyType === StrategyType.Critical) {
      filteredCarriers = filteredCarriers.filter((i) => (isBroker ? i.scac.toUpperCase() !== uberScac : true))
    }

    if (strategyType === StrategyType.FirstCome) {
      filteredCarriers = filteredCarriers.filter((i) => i.scac.toUpperCase() === uberScac)
    }

    return filteredCarriers
  }

  const isSearchLoading = organizationCarriersFetchStatus === "fetching"

  const strategy =
    type === StrategyType.WaitAll
      ? waitAllStrategy
      : type === StrategyType.FirstCome
      ? firstComeStrategy
      : criticalStrategy
  const selectedItems = strategy.scacs
  const selectedCarrierIds = strategy.carrierIds
  const selectedField =
    type === StrategyType.WaitAll
      ? "waitAllStrategy.scacs"
      : type === StrategyType.FirstCome
      ? "firstComeStrategy.scacs"
      : "criticalStrategy.scacs"
  const selectedCarrierIdField =
    type === StrategyType.WaitAll
      ? "waitAllStrategy.carrierIds"
      : type === StrategyType.FirstCome
      ? "firstComeStrategy.carrierIds"
      : "criticalStrategy.carrierIds"

  const handleChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      if (!event.currentTarget.checked) {
        if (carriersData?.content) {
          const newArray = selectedItems?.filter((i) => `${i.carrierId}` !== event.currentTarget.value) || []
          const newCarrierIdArray =
            selectedCarrierIds?.filter((i) => `${i.carrierId}` !== event.currentTarget.value) || []

          setValue(selectedField, newArray, { shouldDirty: true })
          setValue(selectedCarrierIdField, newCarrierIdArray, { shouldDirty: true })

          setTimeout(() => {
            trigger(selectedField)
            trigger(selectedCarrierIdField)
          }, 0)
        }
      } else {
        if (carriersData?.content) {
          const newCarrier = carriersData.content.find((i) => i.id == event.currentTarget.value)
          if (newCarrier) {
            const newArray = [...(selectedItems || []), transformCarrierToScac(newCarrier)]
            const newCarrierIdArray = [...(selectedCarrierIds || []), transformCarrierToCarrierId(newCarrier)]

            setValue(selectedField, newArray, { shouldDirty: true })
            setValue(selectedCarrierIdField, newCarrierIdArray, { shouldDirty: true })

            setTimeout(() => {
              trigger(selectedField)
              trigger(selectedCarrierIdField)
            }, 0)
          }
        }
      }
    },
    [
      selectedItems,
      selectedCarrierIds,
      carriersData,
      selectedField,
      selectedCarrierIdField,
      setValue,
      trigger,
    ],
  )

  return (
    <Controller
      name={selectedField}
      control={control}
      render={({ field }) => {
        return (
          <Popover open={isOpen}>
            <PopoverAnchor
              asChild
              onKeyDown={(e: { key: string }) => escapeKeyDown(e.key) && setIsOpen(false)}
            >
              <Flex ref={containerRef} direction="column" css={{ position: "relative" }}>
                <Flex css={{ gap: "$4" }}>
                  <Copy scale={10} color="neutrals-5" fontWeight="semiBold">
                    Select carriers
                    <Copy
                      as="span"
                      scale={10}
                      fontWeight="semiBold"
                      css={{ paddingLeft: "$2", visibility: strategy?.enabled ? "visible" : "hidden" }}
                    >
                      *
                    </Copy>
                  </Copy>
                  {withTooltip ? (
                    <IconTooltip
                      tooltip={<Copy scale={10}>Please enter carriers in the previous tab</Copy>}
                      ariaLabel="Per package tooltip"
                      withTitle={false}
                      contentWidth="max-content"
                      trigger={["hover", "focus"]}
                      placement="top"
                      delayShow={150}
                      delayHide={150}
                      contentCss={{
                        padding: "$12",
                        borderRadius: "$8",
                      }}
                      triggerCss={{
                        height: "$20",
                      }}
                      icon={<IconWarning fixedSize width={20} height={20} />}
                    />
                  ) : null}
                </Flex>
                <Spacer size={4} />
                <SimpleSelectSPopoverAnchorButton
                  {...register(field.name, {
                    shouldUnregister: true,
                    validate: {
                      required: (_, values) => {
                        const strategy =
                          type === StrategyType.WaitAll
                            ? values.waitAllStrategy
                            : type === StrategyType.FirstCome
                            ? values.firstComeStrategy
                            : values.criticalStrategy
                        const selectedItems = isIdInsteadScac ? strategy.carrierIds : strategy.scacs

                        if (strategy?.enabled && selectedItems) {
                          return selectedItems.length > 0 || "Required field"
                        }

                        return true
                      },
                    },
                  })}
                  {...field}
                  value={field.value.map((i) => i.carrierId).join(",")}
                  onChange={(value) =>
                    field.onChange(value.currentTarget.value as unknown as IScac[] | ICarrierIds[])
                  }
                  ref={triggerRef}
                  type="button"
                  onClick={() => setIsOpen(!isOpen)}
                  active={isOpen}
                  hasError={
                    strategy?.enabled && (selectedItems?.length === 0 || selectedCarrierIds?.length === 0)
                  }
                >
                  <Copy as="span">{`${
                    selectedItems?.length || selectedCarrierIds?.length || "Not"
                  } selected`}</Copy>
                  {isOpen ? <IconChevronTop size="xs" /> : <IconChevronDown size="xs" />}
                </SimpleSelectSPopoverAnchorButton>

                {strategy?.enabled && (selectedItems?.length === 0 || selectedCarrierIds?.length === 0) && (
                  <Box css={{ position: "absolute", bottom: "-$24" }}>
                    <ErrorLabel id="dateFieldError">
                      {type === StrategyType.FirstCome ? "UBER carrier not added" : "Required field"}
                    </ErrorLabel>
                  </Box>
                )}
              </Flex>
            </PopoverAnchor>
            <PopoverContent
              close={() => setIsOpen(false)}
              align="start"
              css={{
                width: containerRef.current?.getBoundingClientRect().width,
                borderRadius: "$8",
                overflow: "hidden",
                keyboardFocus: {
                  outline: "1px solid $brand-yellow-primary",
                },
              }}
              alignOffset={-1}
              onInteractOutside={(event) => {
                if (isTriggerClick(event)) {
                  return
                }
                return setIsOpen(false)
              }}
              onOpenAutoFocus={(event) => {
                event.preventDefault()
              }}
            >
              <Stack space={0} css={{ paddingX: 0 }}>
                <Box css={{ padding: "$12 $16" }}>
                  <FormInput
                    id="Search field carriers"
                    value={inputValue}
                    label="Search for carriers"
                    labelProps={{ hidden: true }}
                    placeholder="Search for carriers"
                    onChange={(event) => {
                      setInputValue(event.target.value)
                    }}
                    prefix={<IconSearch />}
                    suffix={
                      inputValue && (
                        <ButtonIcon
                          ref={clearButtonRef}
                          icon={<IconCross />}
                          ariaLabel="Clear button"
                          onClick={() => {
                            setInputValue("")
                          }}
                          inputIcon
                        />
                      )
                    }
                    autoComplete="new-password"
                  />
                </Box>

                <Box css={{ height: "max-content", maxHeight: 320, overflow: "auto" }}>
                  {isIdInsteadScac ? (
                    selectedCarrierIds.length > 0 ? (
                      !keyword ? (
                        selectedCarrierIds.length === 0 ? (
                          <Box css={{ padding: "$16" }}>
                            <Copy color="theme-b-n3">Not found</Copy>
                          </Box>
                        ) : (
                          selectedCarrierIds.map((item) => (
                            <ResponseContentItem key={item.carrierId}>
                              <FormCheckbox
                                value={`${item.carrierId}`}
                                onChange={handleChange}
                                name={`${item.carrierId}`}
                                id={`${item.carrierId}`}
                                label={item.carrierName}
                                checked={selectedCarrierIds?.some(
                                  (i) => `${i.carrierId}` === `${item.carrierId}`,
                                )}
                                disabled={type === StrategyType.FirstCome}
                              />
                            </ResponseContentItem>
                          ))
                        )
                      ) : null
                    ) : (
                      <Flex css={{ padding: "$16", height: "$56" }}>No selected carriers</Flex>
                    )
                  ) : null}
                  {!isIdInsteadScac ? (
                    selectedItems.length > 0 ? (
                      !keyword ? (
                        selectedItems.length === 0 ? (
                          <Box css={{ padding: "$16" }}>
                            <Copy color="theme-b-n3">Not found</Copy>
                          </Box>
                        ) : (
                          selectedItems.map((item) => (
                            <ResponseContentItem key={item.carrierId}>
                              <FormCheckbox
                                value={`${item.carrierId}`}
                                onChange={handleChange}
                                name={`${item.carrierId}`}
                                id={`${item.carrierId}`}
                                label={item.name}
                                checked={selectedItems?.some((i) => `${i.carrierId}` === `${item.carrierId}`)}
                                disabled={type === StrategyType.FirstCome}
                              />
                            </ResponseContentItem>
                          ))
                        )
                      ) : null
                    ) : (
                      <Flex css={{ padding: "$16", height: "$56" }}>No selected carriers</Flex>
                    )
                  ) : null}
                  {keyword && !isSearchLoading ? (
                    carriersFilterByStrategy(carriersData?.content || [], type).length === 0 ? (
                      <Box css={{ padding: "$16" }}>
                        <Copy color="theme-b-n3">No matches found</Copy>
                      </Box>
                    ) : (
                      carriersFilterByStrategy(carriersData?.content || [], type).map((item) => (
                        <ResponseContentItem key={item.id}>
                          <FormCheckbox
                            value={`${item.id}`}
                            onChange={handleChange}
                            name={item.id}
                            id={item.id}
                            label={item.name}
                            checked={selectedItems?.some((i) => `${i.carrierId}` === `${item.id}`)}
                            disabled={type === StrategyType.FirstCome}
                          />
                        </ResponseContentItem>
                      ))
                    )
                  ) : null}
                  {keyword && isSearchLoading && (
                    <Flex align="center" css={{ padding: 16 }}>
                      <IllustrationSpinner />
                    </Flex>
                  )}
                </Box>
              </Stack>
            </PopoverContent>
          </Popover>
        )
      }}
    />
  )
})

CarrierScacSelect.displayName = "CarrierScacSelect"
