import { useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"
import { useParams } from "react-router-dom"

import { useGlobalStore } from "@/store"
import { useAllUserOrganizations, useOrganizationById } from "@/management/hooks"
import { RouteParams, IOrganizationState, OrganizationType } from "@/shared/types"

import {
  Accordion,
  AccordionButton,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Spacer,
  Spinner,
  Switch,
  SwitchOption,
  useSwitch,
} from "@/shared/components"

import { Waterfall, FirstCome, WaitAll, Critical, Email, MarketRate } from "./components"
import { UrgentContactsTable } from "../UrgentContactsTable"

export const ExecutionStrategy = () => {
  const { organizationId } = useParams<keyof RouteParams>() as RouteParams
  const [isBroker, setIsBroker] = useState(false)
  const user = useGlobalStore((state) => state.user)
  const { watch, setValue, trigger } = useFormContext<IOrganizationState>()
  const { id, parentId, waterfallStrategy, waitAllStrategy, firstComeStrategy, criticalStrategy } = watch()

  const { data: organization, isLoading: organizationLoading } = useOrganizationById(organizationId)
  const { data: organizationList = [] } = useAllUserOrganizations(user.username)

  const switchWaterfallStrategyProps = useSwitch<"true" | "false">(
    "isWaterfallStrategyActive",
    waterfallStrategy.enabled ? "true" : "false",
  )
  const switchWaitAllStrategyProps = useSwitch<"true" | "false">(
    "isWaitAllStrategyActive",
    waitAllStrategy.enabled ? "true" : "false",
  )
  const switchFirstComeStrategyProps = useSwitch<"true" | "false">(
    "isFirstComeStrategyActive",
    firstComeStrategy.enabled ? "true" : "false",
  )
  const switchCriticalStrategyProps = useSwitch<"true" | "false">(
    "isCriticalStrategyActive",
    criticalStrategy.enabled ? "true" : "false",
  )

  const isLoading = organizationLoading

  useEffect(() => {
    switchWaterfallStrategyProps.onValueChange(waterfallStrategy.enabled ? "true" : "false")
    switchWaitAllStrategyProps.onValueChange(waitAllStrategy.enabled ? "true" : "false")
    switchFirstComeStrategyProps.onValueChange(firstComeStrategy.enabled ? "true" : "false")
    switchCriticalStrategyProps.onValueChange(criticalStrategy.enabled ? "true" : "false")
  }, [
    switchWaterfallStrategyProps,
    switchWaitAllStrategyProps,
    switchFirstComeStrategyProps,
    switchCriticalStrategyProps,
    waterfallStrategy,
    waitAllStrategy,
    firstComeStrategy,
    criticalStrategy,
  ])

  useEffect(() => {
    if (organization && organization.organizationType === OrganizationType.Broker) {
      setIsBroker(true)
      // setValue("waterfallStrategy.enabled", true, { shouldDirty: true })
      // switchWaterfallStrategyProps.onValueChange("true")
      // setValue("firstComeStrategy.enabled", true, { shouldDirty: true })
      // switchFirstComeStrategyProps.onValueChange("true")

      setTimeout(() => {
        trigger("waterfallStrategy.finishPoint")
        trigger("waterfallStrategy.responseTime")
        trigger("waitAllStrategy.finishPoint")
        trigger("waitAllStrategy.responseTime")
        trigger("firstComeStrategy.finishPoint")
        trigger("criticalStrategy.finishPoint")
      }, 0)
    } else {
      setIsBroker(false)

      setTimeout(() => {
        trigger("waterfallStrategy.finishPoint")
        trigger("waterfallStrategy.responseTime")
        trigger("waitAllStrategy.finishPoint")
        trigger("criticalStrategy.finishPoint")
      }, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, organizationList, parentId, trigger, setValue])

  const WaitForAllStrategy = (
    <AccordionItem value="waitForAll">
      <AccordionHeader>
        <AccordionButton>‘Wait for all quotes’ strategy</AccordionButton>
        <Box css={{ position: "absolute", top: "50%", left: 334, transform: "translateY(-50%)" }}>
          <Switch
            {...switchWaitAllStrategyProps}
            onValueChange={(value) => {
              setValue("waitAllStrategy.enabled", value === "true", { shouldDirty: true })
              switchWaitAllStrategyProps.onValueChange(value)

              setTimeout(() => {
                trigger("waitAllStrategy.scacs")
                trigger("waitAllStrategy.finishPoint")
                trigger("waitAllStrategy.responseTime")
                trigger("firstComeStrategy.finishPoint")

                trigger("criticalStrategy.finishPoint")
              }, 0)
            }}
            checked={switchWaitAllStrategyProps.value === "true"}
          >
            <SwitchOption value={waitAllStrategy.enabled ? "false" : "true"} />
          </Switch>
        </Box>
      </AccordionHeader>
      <AccordionPanel>
        <WaitAll {...{ isBroker }} />
      </AccordionPanel>
    </AccordionItem>
  )

  return (
    <>
      {isLoading ? (
        <Flex align="center" justify="center" css={{ height: 500 }}>
          <Spinner />
        </Flex>
      ) : (
        <>
          <Accordion multiple defaultSelected={["waterfall", "firstCome", "waitForAll", "critical"]}>
            <AccordionItem value="waterfall">
              <AccordionHeader>
                <AccordionButton>‘Waterfall’ strategy</AccordionButton>
                <Box css={{ position: "absolute", top: "50%", left: 254, transform: "translateY(-50%)" }}>
                  <Switch
                    {...switchWaterfallStrategyProps}
                    onValueChange={(value) => {
                      setValue("waterfallStrategy.enabled", value === "true", { shouldDirty: true })
                      switchWaterfallStrategyProps.onValueChange(value)

                      setTimeout(() => {
                        trigger("waterfallStrategy.finishPoint")
                        trigger("waterfallStrategy.responseTime")

                        isBroker
                          ? trigger("firstComeStrategy.finishPoint")
                          : trigger("waitAllStrategy.finishPoint")
                        trigger("criticalStrategy.finishPoint")
                      }, 0)
                    }}
                    checked={switchWaterfallStrategyProps.value === "true"}
                  >
                    <SwitchOption value={waterfallStrategy.enabled ? "false" : "true"} />
                  </Switch>
                </Box>
              </AccordionHeader>
              <AccordionPanel>
                <Waterfall />
              </AccordionPanel>
            </AccordionItem>
            {isBroker ? (
              <>
                {WaitForAllStrategy}
                <AccordionItem value="firstCome">
                  <AccordionHeader>
                    <AccordionButton>‘First come first serve’ strategy</AccordionButton>
                    <Box css={{ position: "absolute", top: "50%", left: 356, transform: "translateY(-50%)" }}>
                      <Switch
                        {...switchFirstComeStrategyProps}
                        onValueChange={(value) => {
                          setValue("firstComeStrategy.enabled", value === "true", { shouldDirty: true })
                          switchFirstComeStrategyProps.onValueChange(value)

                          setTimeout(() => {
                            trigger("firstComeStrategy.scacs")
                            trigger("firstComeStrategy.finishPoint")
                            // trigger("firstComeStrategy.responseTime")

                            trigger("criticalStrategy.finishPoint")
                          }, 0)
                        }}
                        checked={switchFirstComeStrategyProps.value === "true"}
                      >
                        <SwitchOption value={firstComeStrategy.enabled ? "false" : "true"} />
                      </Switch>
                    </Box>
                  </AccordionHeader>
                  <AccordionPanel>
                    <FirstCome />
                  </AccordionPanel>
                </AccordionItem>
              </>
            ) : (
              WaitForAllStrategy
            )}
            <AccordionItem value="critical">
              <AccordionHeader>
                <AccordionButton>‘Critical’ strategy</AccordionButton>
                <Box css={{ position: "absolute", top: "50%", left: 236, transform: "translateY(-50%)" }}>
                  <Switch
                    {...switchCriticalStrategyProps}
                    onValueChange={(value) => {
                      setValue("criticalStrategy.enabled", value === "true", { shouldDirty: true })
                      switchCriticalStrategyProps.onValueChange(value)

                      setTimeout(() => {
                        trigger("criticalStrategy.scacs")
                        trigger("criticalStrategy.finishPoint")
                        trigger("criticalStrategy.responseTime")
                      }, 0)
                    }}
                    checked={switchCriticalStrategyProps.value === "true"}
                  >
                    <SwitchOption value={criticalStrategy.enabled ? "false" : "true"} />
                  </Switch>
                </Box>
              </AccordionHeader>
              <AccordionPanel>
                <Critical isBroker={isBroker} />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <Spacer size={24} />
          <MarketRate />
          <Spacer size={48} />
          <Email />
          <Spacer size={48} />
          <UrgentContactsTable />
        </>
      )}
    </>
  )
}
