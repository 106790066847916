import { useQuery } from "@tanstack/react-query"
import { isAxiosError } from "axios"

import { getAllFilteredOrdersByDateFn } from "@/api/ordersApi"
import { dateTimeFormat, showToast } from "@/shared/utils"

export const useAllOrdersByDate = ({
  orgId,
  page,
  size,
  inputShipStart,
  inputShipEnd,
  inputDeliveryStart,
  inputDeliveryEnd,
}: {
  orgId: string
  page: number
  size?: number
  disableRefetchInterval?: boolean
  inputShipStart: Date | null
  inputShipEnd: Date | null
  inputDeliveryStart: Date | null
  inputDeliveryEnd: Date | null
}) => {
  const dateNow = new Date(new Date().setFullYear(new Date().getFullYear() + 1))
  const formattedDateNow = dateTimeFormat(`${dateNow}`, "yyyy-MM-dd")
  const formattedDateStart = dateTimeFormat("0", "yyyy-MM-dd")

  return useQuery(
    [
      "allFilteredOrdersByDate",
      orgId,
      page,
      inputShipStart,
      inputShipEnd,
      inputDeliveryStart,
      inputDeliveryEnd,
    ],
    () =>
      getAllFilteredOrdersByDateFn({
        orgId,
        page,
        size,
        pickupStartDateStart: (inputShipStart
          ? dateTimeFormat(`${inputShipStart}`, "yyyy-MM-dd")
          : formattedDateStart) as string,
        pickupStartDateEnd: (inputShipEnd
          ? dateTimeFormat(`${inputShipEnd}`, "yyyy-MM-dd")
          : formattedDateNow) as string,
        dropOffStartDateStart: (inputDeliveryStart
          ? dateTimeFormat(`${inputDeliveryStart}`, "yyyy-MM-dd")
          : formattedDateStart) as string,
        dropOffStartDateEnd: (inputDeliveryEnd
          ? dateTimeFormat(`${inputDeliveryEnd}`, "yyyy-MM-dd")
          : formattedDateNow) as string,
      }),
    {
      staleTime: 0,
      keepPreviousData: true,
      enabled: false,
      onError: (error) => {
        if (isAxiosError(error)) {
          showToast({ type: "error", text: error.response?.data || error.message })
        }
      },
    },
  )
}
