import {
  IScac,
  StrategyType,
  IOrganizationState,
  OrganizationType,
  IOrganizationCarrier,
  IOrganizationStrategiesDto,
  IOrganizationStrategy,
} from "@/shared/types"
import { isEmpty, transformCarrierToCarrierId } from "@/shared/utils"

export const transformOrganizationStrategiesResponse = (
  data: IOrganizationStrategiesDto,
  id: string,
  organizationType?: OrganizationType,
): Pick<
  IOrganizationState,
  | "waterfallStrategy"
  | "waitAllStrategy"
  | "firstComeStrategy"
  | "criticalStrategy"
  | "urgentDaysBeforePickup"
  | "urgentHoursBeforePickup"
> => {
  const isBroker = organizationType === OrganizationType.Broker

  const waterfallStrategy = data.executionStrategies?.find((i) => i.type === StrategyType.Waterfall)
  const waitAllStrategy = data.executionStrategies?.find(
    (i) => i.type === (isBroker ? StrategyType.WaitAllWithLanes : StrategyType.WaitAll),
  )
  const firstComeStrategy = data.executionStrategies?.find((i) => i.type === StrategyType.FirstCome)
  const criticalStrategy = data.executionStrategies?.find((i) => i.type === StrategyType.Critical)

  return {
    waterfallStrategy: {
      ...waterfallStrategy,
      type: StrategyType.Waterfall,
      scacs: waterfallStrategy?.scacs || [],
      carrierIds: waterfallStrategy?.carrierIds || [],
      finishPoint: `${isEmpty(waterfallStrategy?.finishPoint) ? "" : waterfallStrategy?.finishPoint}`,
      responseTime: `${waterfallStrategy?.responseTime || ""}`,
      enabled: !!waterfallStrategy?.enabled,
      totalElements: waterfallStrategy?.scacs.length,
    },
    waitAllStrategy: {
      ...waitAllStrategy,
      type: StrategyType.WaitAll,
      scacs: waitAllStrategy?.scacs || [],
      carrierIds: waitAllStrategy?.carrierIds || [],
      finishPoint: `${isEmpty(waitAllStrategy?.finishPoint) ? "" : waitAllStrategy?.finishPoint}`,
      priceTolerance: `${waitAllStrategy?.priceTolerance || ""}`,
      responseTime: `${waitAllStrategy?.responseTime || ""}`,
      enabled: !!waitAllStrategy?.enabled,
      totalElements: waitAllStrategy?.scacs.length,
    },
    firstComeStrategy: {
      ...firstComeStrategy,
      type: StrategyType.FirstCome,
      scacs: firstComeStrategy?.scacs || [],
      carrierIds: firstComeStrategy?.carrierIds || [],
      finishPoint: `${isEmpty(firstComeStrategy?.finishPoint) ? "" : firstComeStrategy?.finishPoint}`,
      // responseTime: `${firstComeStrategy?.responseTime || ""}`,
      enabled: !!firstComeStrategy?.enabled,
      totalElements: firstComeStrategy?.scacs.length,
    },
    criticalStrategy: {
      ...criticalStrategy,
      type: StrategyType.Critical,
      scacs: criticalStrategy?.scacs || [],
      carrierIds: criticalStrategy?.carrierIds || [],
      finishPoint: `${isEmpty(criticalStrategy?.finishPoint) ? "" : criticalStrategy?.finishPoint}`,
      responseTime: `${criticalStrategy?.responseTime || ""}`,
      enabled: !!criticalStrategy?.enabled,
      totalElements: criticalStrategy?.scacs.length,
    },
    urgentDaysBeforePickup: `${data.urgentDaysBeforePickup || ""}`,
    urgentHoursBeforePickup: `${data.urgentHoursBeforePickup || ""}`,
  }
}

export const transformStrategy = (
  carrierData: {
    content: IOrganizationCarrier[]
    totalPages?: number
    totalElements?: number
  },
  strategiesData: IOrganizationStrategy,
  strategyType: StrategyType,
  totalElements?: number,
): IOrganizationStrategy => {
  return {
    type: strategyType,
    scacs:
      carrierData?.content.map(
        (item) => ({ name: item.name, carrierId: Number(item.id), scac: item.scac } as IScac),
      ) || [],
    carrierIds: carrierData?.content.map((item) => transformCarrierToCarrierId(item)) || [],
    finishPoint: `${isEmpty(strategiesData?.finishPoint) ? "" : strategiesData?.finishPoint}`,
    priceTolerance: `${strategiesData?.priceTolerance || ""}`,
    responseTime: `${strategiesData?.responseTime || ""}`,
    enabled: !!strategiesData?.enabled,
    totalElements: totalElements ? totalElements : carrierData.totalElements,
    totalPages: carrierData.totalPages,
  }
}
