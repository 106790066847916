import { Controller, useFormContext } from "react-hook-form"

import { Role, ChangePersonInfoInput } from "@/shared/types"
import { roleToText } from "@/shared/utils"
import { alphanumericPattern, lengthRule, requiredPattern } from "@/shared/rules"
import { allRoles } from "@/constants"

import {
  Box,
  EmailController,
  FormSelect,
  Grid,
  GridItem,
  Spacer,
  Stack,
  TextFieldController,
  Title,
} from "@/shared/components"

export const ProfileInfo = () => {
  const {
    watch,
    control,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext<ChangePersonInfoInput>()
  const { availableOrganizations } = watch()
  // const isPasswordChanged = oldPassword !== defaultValues?.oldPassword

  // const onBlurOldPasswordHandler = () => {
  //   trigger("oldPassword")

  //   if (oldPassword === "") {
  //     trigger("newPassword")
  //     trigger("confirmPassword")
  //   }
  // }

  return (
    <Box
      css={{ backgroundColor: "$system-white", padding: "$40 $56", borderRadius: "$8", marginBottom: "$96" }}
    >
      <Box>
        <Title as="h2" scale={6} color="system-black">
          General Info
        </Title>
        <Spacer size={32} />
        <Stack space={32}>
          <Grid columns="1fr 1fr 1fr 1fr" columnGap={16}>
            <GridItem>
              <TextFieldController<ChangePersonInfoInput>
                name="firstName"
                control={control}
                register={register}
                trigger={trigger}
                error={errors.firstName?.message}
                labelProps={{ required: true }}
                disabled
                rules={{ ...alphanumericPattern, ...lengthRule("Name", 40) }}
                description="First name"
              />
            </GridItem>
            <GridItem>
              <TextFieldController<ChangePersonInfoInput>
                name="lastName"
                control={control}
                register={register}
                trigger={trigger}
                error={errors.lastName?.message}
                disabled
                rules={{ ...alphanumericPattern, ...lengthRule("Name", 40) }}
                description="Last name"
              />
            </GridItem>
            {/* <GridItem>
              <PhoneController<ChangePersonInfoInput>
                name="phone"
                control={control}
                register={register}
                trigger={trigger}
                error={errors.phone?.message}
              />
            </GridItem> */}
            <GridItem>
              <EmailController<ChangePersonInfoInput>
                name="username"
                control={control}
                register={register}
                trigger={trigger}
                description="Email"
                disabled
                labelProps={{ required: true }}
                error={errors.username?.message}
              />
            </GridItem>
          </Grid>

          <Grid columns="1fr 1fr 1fr 1fr" columnGap={16}>
            <GridItem>
              <Controller
                name="defaultOrganizationId"
                control={control}
                render={({ field }) => {
                  return (
                    <FormSelect
                      {...register(field.name, {
                        shouldUnregister: true,
                        ...requiredPattern,
                      })}
                      {...field}
                      id={field.name}
                      onValueChange={(value) => {
                        setValue("defaultOrganizationId", value as string, { shouldDirty: true })
                      }}
                      label="Parent organization"
                      labelProps={{ hidden: true, required: true }}
                      description="Parent organization"
                      isDisplayValueDifferent
                      isSearchable
                      value={{
                        value: field.value,
                        label: availableOrganizations.find((i) => i.id === field.value)?.label,
                      }}
                      options={availableOrganizations.map((i) => ({
                        value: i.id,
                        label: i.label,
                      }))}
                      disabled
                      error={errors[field.name]?.message}
                    />
                  )
                }}
              />
            </GridItem>
            <GridItem>
              <Controller
                name="role"
                control={control}
                render={({ field }) => {
                  return (
                    <FormSelect
                      {...register(field.name, {
                        shouldUnregister: true,
                        ...requiredPattern,
                      })}
                      {...field}
                      id="role"
                      onValueChange={(value) => {
                        setValue("role", value as Role, { shouldDirty: true })
                      }}
                      label="Role"
                      labelProps={{ hidden: true, required: true }}
                      description="Role"
                      isDisplayValueDifferent
                      value={{
                        value: field.value,
                        label: roleToText(field.value),
                      }}
                      options={allRoles.map((i) => ({
                        value: i,
                        label: roleToText(i),
                      }))}
                      disabled
                      error={errors[field.name]?.message}
                    />
                  )
                }}
              />
            </GridItem>
          </Grid>
        </Stack>
      </Box>
      <Spacer size={40} />
      {/* <Stack space={32}>
        <Title as="h2" scale={6} color="system-black">
          Change password
        </Title>

        <Grid columns="1fr 1fr 1fr 1fr" columnGap={16}>
          <GridItem>
            <PasswordController<ChangePersonInfoInput>
              control={control}
              register={register}
              name="oldPassword"
              labelProps={{ required: true, hidden: true }}
              description="Current password"
              placeholder="Enter current password"
              onBlurHandler={onBlurOldPasswordHandler}
              rules={{
                required: {
                  value: isPasswordChanged,
                  message: "Required field",
                },
              }}
              autoComplete="new-password"
              error={errors.oldPassword?.message}
            />
          </GridItem>
        </Grid>

        <Grid columns="1fr 1fr 1fr 1fr" columnGap={16}>
          <GridItem>
            <PasswordController<ChangePersonInfoInput>
              control={control}
              register={register}
              name="newPassword"
              labelProps={{ required: true, hidden: true }}
              description="New password"
              placeholder="Enter new password"
              rules={{
                required: {
                  value: isPasswordChanged,
                  message: "Required field",
                },
                ...newPasswordInputRules(isPasswordChanged),
              }}
              autoComplete="new-password"
              error={errors.newPassword?.message}
            />
          </GridItem>
        </Grid>

        <Grid columns="1fr 1fr 1fr 1fr" columnGap={16}>
          <GridItem>
            <PasswordController<ChangePersonInfoInput>
              control={control}
              register={register}
              name="confirmPassword"
              labelProps={{ required: true, hidden: true }}
              description="Confirm password"
              placeholder="Confirm new password"
              rules={{
                required: {
                  value: isPasswordChanged,
                  message: "Required field",
                },
                ...confirmPasswordInputRules(newPassword),
              }}
              autoComplete="new-password"
              error={errors.confirmPassword?.message}
            />
          </GridItem>
        </Grid>
        <Controller
          name="urgentContact"
          control={control}
          render={({ field }) => {
            return (
              <FormCheckbox
                {...register(field.name)}
                label="Assign this contact for urgent emails"
                disabled
              />
            )
          }}
        />
      </Stack> */}
    </Box>
  )
}
