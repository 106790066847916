export interface IQuotesResponse {
  status: string
  orderId: string
  orgId: string
  carrierId: number
  rateInCents: number
  createdAt: string
  updatedAt: string
  strategyType: string
  scheduledMessageSeqNum: number
  responseTimeEnd: string
  scheduleTime: string
  forceSubmittedBy: string
}

export interface IOrderDetailsResponse {
  orderId: string
  originalOrderId: string
  pickupState: string
  pickupCity: string
  pickupStartAt: string
  pickupEndAt: string
  dropOffState: string
  dropOffCity: string
  dropOffStartAt: string
  dropOffEndAt: string
  commodity: string
  weight: number
  weightUnit: string
  equipment: string
  distance: number
  distanceUnitType: string
  note: string
}

export interface IDashboardData {
  orderId: string
  originalOrderId: string
  from: string
  to: string
  dropOffStartAt: string
  pickupStartAt: string
  details: string
  rate: string
  status: string
  updatedAt: string
  hasOriginalRate: boolean
}

export const initialDashboardData: IDashboardFormData = {
  orders: [],
  currentOrder: {
    orderId: "",
    originalOrderId: "",
    from: "",
    to: "",
    dropOffStartAt: "",
    pickupStartAt: "",
    details: "",
    rate: "",
    status: "",
    updatedAt: "",
    hasOriginalRate: false,
  },
}

export interface IDashboardAmountNumbers {
  ACCEPTED: number
  REJECTED: number
  PENDING: number
  EXPIRED: number
}

export interface IDashboardFormData {
  orders: IDashboardData[]
  currentOrder: IDashboardData
}

export interface IDashboardCarrierData {
  carrierId: number
  carrierName: string
  orgId: number
  orgName: string
}
