import { useFormContext } from "react-hook-form"

import { IFlsFiltersState } from "@/shared/types"

import { Button, Flex, FooterWrapper } from "@/shared/components"
import { CancelButton } from "@/management/components"

export const SaveFiltersButton = ({
  isLoading,
  resetAction,
}: {
  isLoading: boolean
  resetAction: () => void
}) => {
  const {
    formState: { errors, isDirty },
  } = useFormContext<IFlsFiltersState>()

  const cancelClick = () => {
    resetAction()
  }

  return (
    <FooterWrapper>
      <Flex justify="between" css={{ gap: "$16" }}>
        <Button
          type="submit"
          loading={isLoading}
          disabled={isLoading || !isDirty || Object.keys(errors).length > 0}
        >
          Save changes
        </Button>
        <CancelButton handleClick={() => cancelClick()} />
      </Flex>
    </FooterWrapper>
  )
}
