import axios from "axios"

import { USER_BASE_URI } from "@/constants"
import {
  IUser,
  ICreateUserRequest,
  IUserResponseById,
  IUsersResponse,
  IOrganizationResponse,
  RecoveryInput,
} from "@/shared/types"
import { replaceFalsyProps, buildUrlString } from "@/shared/utils"

export const userApi = axios.create({
  baseURL: USER_BASE_URI,
})

userApi.defaults.headers.common["Content-Type"] = "application/json"

export const getMeFn = async (username: string) => {
  const { data: user } = await userApi.get<IUser>(`users/info?username=${username}`)
  const organizations = await getUserOrganizationsFn()

  return { user, organizations }
}

export const getUserOrganizationsFn = async () => {
  const { data } = await userApi.get<IOrganizationResponse[]>("users/organizations")
  return data
}

export const getAllUsersFn = async ({
  organizationId,
  sort,
  page = 0,
  size = 1000,
}: {
  organizationId: string
  sort: string
  page?: number
  size?: number
}) => {
  const queryParams = {
    organizationId,
    sort,
    page,
    size,
  }
  const baseUrl = "users/all"
  const url = buildUrlString(baseUrl, queryParams)

  const { data } = await userApi.get<IUsersResponse>(url)
  return data.content
}

export const getUserByIdFn = async (id: string) => {
  const { data } = await userApi.get<IUserResponseById>(`users?id=${id}`)
  return data
}

export const createUserFn = async (user: ICreateUserRequest) => {
  const formattedData = JSON.stringify(user, replaceFalsyProps)
  const { data } = await userApi.post("users", JSON.parse(formattedData))
  return data
}

export const deleteUserFn = async (id: string) => {
  const { data } = await userApi.delete(`users/${id}`)
  return data
}

export const updateUserFn = async (id: string, user: ICreateUserRequest) => {
  const formattedData = JSON.stringify(user, replaceFalsyProps)
  const { data } = await userApi.put(`users/${id}`, JSON.parse(formattedData))
  return data
}

export const sendActivationLinkByUserIdFn = async (id: string) => {
  const { data } = await userApi.post(`users/new-activation-link?id=${id}`)
  return data
}

export const updatePersonInfoFn = async ({
  phone,
  darkTheme,
  oldPassword,
  newPassword,
}: {
  phone: string
  darkTheme: boolean
  oldPassword: string
  newPassword: string
}) => {
  const formattedData = JSON.stringify(
    {
      phone,
      darkTheme,
      oldPassword,
      newPassword,
    },
    replaceFalsyProps,
  )

  const { data } = await userApi.post("users/update_profile", JSON.parse(formattedData))

  return data
}

export const sendCarrierEmailFn = async ({ email }: RecoveryInput) => {
  const { data } = await userApi.post<string>(
    `carrier-contacts/send-email-with-login-link`,
    { email },
    {
      headers: {
        Authorization: null,
      },
    },
  )

  return data
}
