import { ICarrierIds, IOrganizationCarrier } from "@/shared/types"

export const transformCarrierToCarrierId = (carrier: IOrganizationCarrier): ICarrierIds => {
  return {
    carrierId: Number(carrier.id),
    carrierName: carrier.name,
    tmsCode: carrier.code,
    scac: carrier.scac,
  }
}
