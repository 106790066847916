import {
  ISla,
  LaneStopType,
  CarrierType,
  IScac,
  ISlaItem,
  StatusType,
  IPagination,
  CommunicationType,
  ICarrierIds,
} from "@/shared/types"

interface IOrganizationSettingsResponse {
  id: string
}

export enum ICarrierSource {
  APOLLO = "APOLLO",
  HIGHWAY = "HIGHWAY",
}

export enum ICarrierOverallResult {
  PASS = "pass",
  FAIL = "fail",
}

export enum StrategyType {
  Waterfall = "WATERFALL",
  WaitAll = "WAIT_FOR_ALL",
  WaitAllWithLanes = "WAIT_FOR_ALL_WITH_LANES",
  FirstCome = "FCFS",
  Critical = "CRITICAL",
}

export interface IOrganizationCarrier {
  id?: string
  orgId: string
  name: string
  legalName: string
  communicationType: string
  country: string
  zipCode: string
  state: string
  city: string
  address1: string
  address2: string
  phone: string
  type: CarrierType | string
  code: string
  scac: string
  mc: string
  usdot: string
  enabled: StatusType
  carrierRepName: string | null
  carrierRepEmail: string | null
  contacts: ICarrierContact[]
  equipment: {
    truckAmount: string | null
    vanAmount: string | null
    flatbedAmount: string | null
    reeferAmount: string | null
    twic: boolean
    teamDrivers: boolean
    maxCargo: string | null
    tankerEndorsement: boolean
    dropTrailers: boolean
    automatedTracking: boolean
    driverWork: boolean
    highValue: boolean
    hazmat: boolean

    //HIGHWAY
    dropDeckAmount: string
    tankerAmount: string
    intermodalAmount: string
    pneumaticAmount: string
    dumpsAmount: string
    carHaulerAmount: string
    doubleDropAmount: string
    lowboyAmount: string
    grainAmount: string
    livestockAmount: string
    heavyHaulAmount: string
  }
  lanes: ICarrierLane[]
  laneDetails: {
    noHaulConsignees: string
    noHaulShippers: string
  }
  lanePreferences: ILanePreference[]
  analytics: {
    claimsRatio: string | null
    tenderAcceptance: string | null
    ediCompliance: string | null
    otd: string | null
    invoiceCompliance: string | null
    otp: string | null
  }
  integration: {
    quotes: string
    tracking: string
  }

  //HIGHWAY
  overallResult: string
  source: ICarrierSource
}

export interface IOrganizationCarrierDto extends Omit<IOrganizationCarrier, "enabled" | "lanes"> {
  enabled: boolean
  lanes: ICarrierLaneResponse[]
}

export interface IUpdateOrganizationCarrierRequest {
  toSave: IOrganizationCarrierDto[]
  toDelete: string[]
}

export interface ICreateOrganizationRequest
  extends Pick<
    IOrganizationState,
    | "id"
    | "name"
    | "generalEmail"
    | "invoiceEmail"
    | "senderEmail"
    | "phone"
    | "timeZone"
    | "address"
    | "billingAddress"
    | "parentId"
    | "contacts"
    | "workingDays"
    | "apiKey"
    | "apiSecret"
    | "carrierApiKey"
    | "carrierApiSecret"
    | "organizationType"
  > {}

export interface IOrganizationResponse extends ICreateOrganizationRequest {
  label: string
  id: string
  rootOrganizationId: string

  createdAt: string
  updatedAt: string
  updatedBy: string

  //temp variables
  description?: string
  parentName?: string
  email?: string
  legalAddress?: string
}

export interface IOrganizationsResponse extends IPagination {
  content: IOrganizationResponse[]
}

export interface IOrganizationCarriersResponse extends IPagination {
  content: IOrganizationCarrierDto[]
}

export interface IOrganizationSlaDto {
  orgId: string
  settings: ISlaItem
}

export interface IDeleteOrganizationResponse {
  organizationName: string
}

export interface IOrganizationStrategy {
  type: StrategyType
  scacs: IScac[]
  carrierIds: ICarrierIds[]
  finishPoint: string
  priceTolerance?: string | null
  responseTime?: string
  enabled: boolean
  totalElements?: number
  totalPages?: number
}

export interface IOrganizationStrategiesDto {
  orgId: string
  executionStrategies?: IOrganizationStrategy[]
  urgentDaysBeforePickup: string
  urgentHoursBeforePickup: string
}

interface ILanePreference {
  carrierId: string
  code: string
  id: string
  name: string
  type: string
}
export interface IUrgentContacts {
  id: string
  email: string
}

export enum OrganizationType {
  Broker = "BROKER",
  Shipper = "SHIPPER",
}

export enum Weekdays {
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday",
  Sunday = "Sunday",
}

export enum WorkingTime {
  "00:00 AM" = "00:00",
  "00:30 AM" = "00:30",
  "01:00 AM" = "01:00",
  "01:30 AM" = "01:30",
  "02:00 AM" = "02:00",
  "02:30 AM" = "02:30",
  "03:00 AM" = "03:00",
  "03:30 AM" = "03:30",
  "04:00 AM" = "04:00",
  "04:30 AM" = "04:30",
  "05:00 AM" = "05:00",
  "05:30 AM" = "05:30",
  "06:00 AM" = "06:00",
  "06:30 AM" = "06:30",
  "07:00 AM" = "07:00",
  "07:30 AM" = "07:30",
  "08:00 AM" = "08:00",
  "08:30 AM" = "08:30",
  "09:00 AM" = "09:00",
  "09:30 AM" = "09:30",
  "10:00 AM" = "10:00",
  "10:30 AM" = "10:30",
  "11:00 AM" = "11:00",
  "11:30 AM" = "11:30",
  "12:00 PM" = "12:00",
  "12:30 PM" = "12:30",
  "01:00 PM" = "13:00",
  "01:30 PM" = "13:30",
  "02:00 PM" = "14:00",
  "02:30 PM" = "14:30",
  "03:00 PM" = "15:00",
  "03:30 PM" = "15:30",
  "04:00 PM" = "16:00",
  "04:30 PM" = "16:30",
  "05:00 PM" = "17:00",
  "05:30 PM" = "17:30",
  "06:00 PM" = "18:00",
  "06:30 PM" = "18:30",
  "07:00 PM" = "19:00",
  "07:30 PM" = "19:30",
  "08:00 PM" = "20:00",
  "08:30 PM" = "20:30",
  "09:00 PM" = "21:00",
  "09:30 PM" = "21:30",
  "10:00 PM" = "22:00",
  "10:30 PM" = "22:30",
  "11:00 PM" = "23:00",
  "11:30 PM" = "23:30",
  "11:59 PM" = "23:59",
}

export enum EquipmentType {
  Van = "VAN",
  DryVan = "DRY_VAN",
  Flatbed = "FLATBED",
  Truck = "TRUCK",
  Reefer = "REEFER",
}

export const initialWorkingDays: IWorkingDay[] = [
  {
    id: "",
    day: Weekdays.Monday,
    start: WorkingTime["07:00 AM"],
    end: WorkingTime["07:00 PM"],
  },
  {
    id: "",
    day: Weekdays.Tuesday,
    start: WorkingTime["07:00 AM"],
    end: WorkingTime["07:00 PM"],
  },
  {
    id: "",
    day: Weekdays.Wednesday,
    start: WorkingTime["07:00 AM"],
    end: WorkingTime["07:00 PM"],
  },
  {
    id: "",
    day: Weekdays.Thursday,
    start: WorkingTime["07:00 AM"],
    end: WorkingTime["07:00 PM"],
  },
  {
    id: "",
    day: Weekdays.Friday,
    start: WorkingTime["07:00 AM"],
    end: WorkingTime["07:00 PM"],
  },
  {
    id: "",
    day: Weekdays.Saturday,
    start: WorkingTime["07:00 AM"],
    end: WorkingTime["07:00 PM"],
  },
  {
    id: "",
    day: Weekdays.Sunday,
    start: WorkingTime["07:00 AM"],
    end: WorkingTime["07:00 PM"],
  },
]

export const defaultTimezone = "America/Chicago"
export const hiddenInputValue = "********"

export const carrierInitialState: IOrganizationCarrier = {
  id: "",
  orgId: "",
  name: "",
  legalName: "",
  communicationType: CommunicationType.EMAIL,
  country: "",
  zipCode: "",
  state: "",
  city: "",
  address1: "",
  address2: "",
  phone: "",
  type: "",
  code: "",
  scac: "",
  mc: "",
  usdot: "",
  enabled: StatusType.Active,
  carrierRepName: "",
  carrierRepEmail: "",
  contacts: [],
  equipment: {
    truckAmount: "",
    vanAmount: "",
    flatbedAmount: "",
    reeferAmount: "",
    twic: false,
    teamDrivers: false,
    maxCargo: "",
    tankerEndorsement: false,
    dropTrailers: false,
    automatedTracking: false,
    driverWork: false,
    highValue: false,
    hazmat: false,
    dropDeckAmount: "",
    tankerAmount: "",
    intermodalAmount: "",
    pneumaticAmount: "",
    dumpsAmount: "",
    carHaulerAmount: "",
    doubleDropAmount: "",
    lowboyAmount: "",
    grainAmount: "",
    livestockAmount: "",
    heavyHaulAmount: "",
  },
  lanes: [],
  laneDetails: {
    noHaulConsignees: "",
    noHaulShippers: "",
  },
  lanePreferences: [],
  analytics: {
    claimsRatio: "",
    tenderAcceptance: "",
    ediCompliance: "",
    otd: "",
    invoiceCompliance: "",
    otp: "",
  },
  integration: {
    quotes: "",
    tracking: "",
  },
  overallResult: "",
  source: ICarrierSource.APOLLO,
}

export const initialOrganizationState: IOrganizationState = {
  id: "",
  name: "",
  generalEmail: "",
  invoiceEmail: "",
  senderEmail: "",
  phone: "",
  timeZone: defaultTimezone,
  address: {
    address1: "",
    address2: "",
    city: "",
    country: "",
    state: "",
    zipCode: "",
  },
  billingAddress: {
    address1: "",
    address2: "",
    city: "",
    country: "",
    state: "",
    zipCode: "",
  },
  workingDays: initialWorkingDays,
  contacts: [],

  parentId: "",
  apiKey: "",
  apiSecret: hiddenInputValue,
  carrierApiKey: "",
  carrierApiSecret: hiddenInputValue,

  carriers: [],
  carriersToDelete: [],
  newCarrier: carrierInitialState,

  waterfallStrategy: {
    type: StrategyType.Waterfall,
    scacs: [],
    carrierIds: [],
    finishPoint: "",
    responseTime: "",
    enabled: false,
  },
  waitAllStrategy: {
    type: StrategyType.WaitAll,
    scacs: [],
    carrierIds: [],
    finishPoint: "",
    priceTolerance: "",
    responseTime: "",
    enabled: false,
  },
  firstComeStrategy: {
    type: StrategyType.FirstCome,
    scacs: [],
    carrierIds: [],
    finishPoint: "",
    // responseTime: "",
    enabled: false,
  },
  criticalStrategy: {
    type: StrategyType.Critical,
    scacs: [],
    carrierIds: [],
    finishPoint: "",
    responseTime: "",
    enabled: false,
  },
  urgentDaysBeforePickup: "1",
  urgentHoursBeforePickup: "",

  currentMarketSource: "",

  sla: [],
  newPreferredLane: {
    id: "",
    from: "",
    to: "",
  },
  newEmails: [],
  organizationType: OrganizationType.Broker,
}

interface IAddress {
  id?: string
  address1: string
  address2: string
  city: string
  country: string
  state: string
  zipCode: string
}

export interface IWorkingDay {
  id: string
  day: Weekdays
  start: WorkingTime
  end: WorkingTime
}

interface IContact {
  id: string
  name: string
  email: string
  urgentContact: boolean
}

export enum ReportType {
  Average = "By the average response time",
  NumberDeclines = "By the number of declines",
  TopCarriers = "Top 5 carriers",
  InsufficientData = "With insufficient data to build in Apollo",
  Volume = "Volume of orders and total spend by carrier",
}

interface IOrganizationDashboard {
  id: string
}

export interface IPreferredLanesValue {
  id: string
  from: string
  to: string
}

export interface IOrganizationState extends IOrganizationSettingsResponse {
  name: string
  generalEmail: string
  invoiceEmail: string
  senderEmail: string
  phone: string
  timeZone: string

  address: IAddress
  billingAddress: IAddress

  workingDays: IWorkingDay[]
  contacts: IContact[]

  parentId: string

  apiKey: string
  apiSecret: string
  carrierApiKey: string
  carrierApiSecret: string
  carriers: IOrganizationCarrier[]
  carriersToDelete: string[]
  newCarrier: IOrganizationCarrier

  waterfallStrategy: IOrganizationStrategy
  waitAllStrategy: IOrganizationStrategy
  firstComeStrategy: IOrganizationStrategy
  criticalStrategy: IOrganizationStrategy
  urgentDaysBeforePickup: string
  urgentHoursBeforePickup: string

  currentMarketSource: string

  dashboard?: IOrganizationDashboard

  newPreferredLane: IPreferredLanesValue

  organizationType: OrganizationType

  sla: ISla[]
  newEmails: IUrgentContacts[]
}

export interface ILaneContact {
  id: string
}

export enum CarrierSourceType {
  APOLLO = "APOLLO",
  HIGHWAY = "HIGHWAY",
}

export interface ICarrierContact {
  id?: string
  name: string
  role: string
  phone: string
  email: string
  tenderContact: boolean
  sourceType: CarrierSourceType
}

interface ICarrierLane {
  id?: string
  from: string
  to: string
  fromStopType?: LaneStopType
  toStopType?: LaneStopType
  price: string
  contacts: ILaneContact[]
}

interface ICarrierLaneResponse extends Omit<ICarrierLane, "fromCity" | "fromState" | "toCity" | "toState"> {
  from: string
  to: string
}

export enum OrganizationInfoTabType {
  General = "General",
  CarrierDetails = "CarrierDetails",
  ExecutionStrategy = "ExecutionStrategy",
  SLASettings = "SLASettings",
  Orders = "Orders",
  OrderFlow = "OrderFlow",
  CarrierAnalytics = "CarrierAnalytics",
  Dashboard = "Dashboard",
  Users = "Users",
  UrgentContacts = "UrgentCont contacts",
  History = "History",
}
