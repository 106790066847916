import React from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { useMedia, useModalActions } from "@/shared/hooks"
import { MANAGEMENT, PROFILE, FLS, mainOrganizationId, featuresOnLoad, orgPropertiesOnLoad } from "@/constants"
import { ModalType } from "@/shared/types"
import { useLogout } from "@/auth/hooks"
import { mediaQueries } from "@/stitches/theme"
import { useGlobalStore } from "@/store"
import { rgba } from "@/stitches/utils"
import { useFeatureFlags } from "@/shared/data"

import { Flex, Grid, GridContainer, GridItem, MenuButton, useGlobalSettings } from "@/shared/components"
import { IconManagement, IconProfile, IconLogout, IconTool } from "@/shared/icons"
import { IllustrationLogo, IllustrationLogoIcon } from "@/shared/illustrations"
import { LeaveWithoutChangesModal } from "@/modals"
import { useOrgProperties } from "../data/useOrgProperties"

interface IMainLayoutProps {
  mobileFullBleed?: boolean
}

export const MainLayout: React.FC<React.PropsWithChildren<IMainLayoutProps>> = ({
  mobileFullBleed = true,
  children,
}) => {
  useFeatureFlags(featuresOnLoad)
  const organization = useGlobalStore((state) => state.organization)
  useOrgProperties(organization.id, orgPropertiesOnLoad)

  const location = useLocation()
  const navigate = useNavigate()
  const { open } = useModalActions()
  const isActive = (pathname: string, url: string) => pathname.includes(url)
  const { globalProtectFlag, setGlobalProtectUrl } = useGlobalSettings("MainLayout")
  const user = useGlobalStore((state) => state.user)

  const protectNavigationHandler = async (url: string) => {
    if (globalProtectFlag) {
      setGlobalProtectUrl(url)
      open(ModalType.GlobalProtectModal)
    } else {
      navigate(url)
    }
  }
  const { logoutUser } = useLogout()

  const isMediumAndAbove = useMedia([mediaQueries.md], [true], false)

  return (
    <GridContainer fullBleed={{ "@initial": mobileFullBleed, "@sm": true }}>
      <Grid rows="$80 1fr" css={{ height: "100vh", minHeight: "calc(var(--vh, 1vh) * 100)" }}>
        <GridItem
          css={{
            padding: "$20 $32",
            position: "sticky",
            top: 0,
            zIndex: "$5",
            backgroundColor: "$theme-w-n11",
            boxShadow: `0 0 2px 0 ${rgba("neutrals-5", 0.5)}`,
          }}
        >
          <Flex align="center" justify="between" css={{ gap: "$40" }}>
            {isMediumAndAbove ? (
              <IllustrationLogo
                theme="dark"
                css={{ height: "$40", hover: { cursor: "pointer" } }}
                onClick={() => protectNavigationHandler(MANAGEMENT)}
              />
            ) : (
              <IllustrationLogoIcon
                theme="dark"
                css={{ hover: { cursor: "pointer" } }}
                onClick={() => protectNavigationHandler(MANAGEMENT)}
              />
            )}
            <Flex align="center" css={{ gap: "$40", "@md": { gap: "$20" } }}>
              {/* {adminRoles.includes(role) ? (
                <MenuButton
                  text="Dashboard"
                  icon={<IconDashboard />}
                  selected={isActive(location.pathname, DASHBOARD)}
                  onClick={() => protectNavigationHandler(DASHBOARD)}
                  ariaLabel="Dashboard button"
                />
              ) : null}
              {adminRoles.includes(role) ? (
                <MenuButton
                  text="Tasks"
                  icon={<IconTask />}
                  selected={isActive(location.pathname, TASKS)}
                  onClick={() => protectNavigationHandler(TASKS)}
                  ariaLabel="Tasks button"
                />
              ) : null}
              {adminRoles.includes(role) ? (
                <MenuButton
                  text="Upload files"
                  icon={<IconUpload />}
                  selected={isActive(location.pathname, FILES)}
                  onClick={() => protectNavigationHandler(FILES)}
                  ariaLabel="Files button"
                />
              ) : null} */}
              <MenuButton
                text="Management"
                icon={<IconManagement />}
                selected={isActive(location.pathname, MANAGEMENT)}
                onClick={() => protectNavigationHandler(MANAGEMENT)}
                ariaLabel="Management button"
              />
              <MenuButton
                text="Profile"
                icon={<IconProfile />}
                selected={isActive(location.pathname, PROFILE)}
                onClick={() => !location.pathname.includes(PROFILE) && protectNavigationHandler(PROFILE)}
                ariaLabel="Profile button"
              />
              {user.defaultOrganizationId == mainOrganizationId && (
                <MenuButton
                  text="FLS"
                  icon={<IconTool />}
                  selected={isActive(location.pathname, FLS)}
                  onClick={() => !location.pathname.includes(FLS) && protectNavigationHandler(FLS)}
                  ariaLabel="FLS button"
                />
              )}
            </Flex>
            <MenuButton
              text="Logout"
              icon={<IconLogout />}
              onClick={() => logoutUser()}
              ariaLabel="Logout button"
            />
          </Flex>
        </GridItem>
        <GridItem>
          <Grid
            columns={{ "@initial": 6, "@sm": 12, "@lg": 24 }}
            columnGap={32}
            css={{
              backgroundColor: "$system-layout",
              height: "100%",
            }}
          >
            {children}
          </Grid>
        </GridItem>
      </Grid>
      <LeaveWithoutChangesModal modalName={ModalType.GlobalProtectModal} />
    </GridContainer>
  )
}
