import { useQuery } from "@tanstack/react-query"

import { getCarrierStatsByOrgIdFn } from "@/api/tenderApi"

export const useCarrierStats = (orgId: string, from: string, enabled: boolean, ids?: string[]) => {
  const isEnabled = !!orgId && enabled

  return useQuery(
    ["carrierStatsByOrgIdFn", orgId, from, ids],
    () => getCarrierStatsByOrgIdFn(orgId, from, ids),
    {
      keepPreviousData: true,
      enabled: isEnabled,
    },
  )
}
