import { useFormContext } from "react-hook-form"

import { IOrganizationState } from "@/shared/types"
import { useIsCustomerAdmin, useIsReader } from "@/shared/utils"
import { lengthRule } from "@/shared/rules"

import { Box, Grid, GridItem, Stack, TextFieldController } from "@/shared/components"

export const Integration = () => {
  const isEditMode = location.pathname.includes("edit")
  const isRoleReader = useIsReader()
  const isRoleCustomerAdmin = useIsCustomerAdmin()

  const {
    control,
    register,
    formState: { errors },
    trigger,
  } = useFormContext<IOrganizationState>()

  return (
    <Box css={{ height: 420 }}>
      <Stack space={32}>
        <Grid columns="1fr 1fr 1fr 1fr" columnGap={16}>
          <GridItem>
            <TextFieldController<IOrganizationState>
              name="newCarrier.integration.quotes"
              control={control}
              register={register}
              trigger={trigger}
              description="Quotes"
              rules={lengthRule("Quotes", 255, 0)}
              error={errors.newCarrier?.integration?.quotes?.message}
              disabled={isEditMode && (isRoleCustomerAdmin || isRoleReader)}
            />
          </GridItem>
          <GridItem>
            <TextFieldController<IOrganizationState>
              name="newCarrier.integration.tracking"
              control={control}
              register={register}
              trigger={trigger}
              description="Tracking"
              rules={lengthRule("Tracking", 255, 0)}
              error={errors.newCarrier?.integration?.tracking?.message}
              disabled={isEditMode && (isRoleCustomerAdmin || isRoleReader)}
            />
          </GridItem>
        </Grid>
      </Stack>
    </Box>
  )
}
