import { useQuery } from "@tanstack/react-query"
import { isAxiosError } from "axios"

import { getStrategyByOrderIdFn } from "@/api/tenderApi"
import { showToast } from "@/shared/utils"

export const useStrategyByOrderId = (orderId: string) => {
  const isEnabled = !!orderId

  return useQuery(["strategyByOrderId", orderId], () => getStrategyByOrderIdFn(orderId), {
    staleTime: 30 * 1000,
    cacheTime: isEnabled ? 30 * 1000 : 0,
    refetchInterval: 30 * 1000,
    keepPreviousData: true,
    enabled: isEnabled,
    onError: (error) => {
      if (isAxiosError(error)) {
        showToast({ type: "error", text: error.response?.data.errorMessage || error.message })
      }
    },
  })
}
