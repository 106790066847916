import { useQuery } from "@tanstack/react-query"
import { isAxiosError } from "axios"

import { getAllEventsForOrderByOrderIdFn } from "@/api/ordersApi"
import { showToast } from "@/shared/utils"

export const useAllEventsForOrder = (orderId: string) => {
  const isEnabled = !!orderId

  return useQuery(["allEventsForOrder", orderId], () => getAllEventsForOrderByOrderIdFn(orderId), {
    staleTime: 0,
    keepPreviousData: true,
    refetchInterval: 5 * 1000,
    enabled: isEnabled,
    onError: (error) => {
      if (isAxiosError(error)) {
        showToast({
          type: "error",
          text: error.response?.data.errorMessage || error.message,
        })
      }
    },
  })
}
