import { ISlaItem, SlaField, SlaItemType } from "@/shared/types"

import { Grid } from "@/shared/components"

import { RemoveButton } from "./RemoveButton"
import { FieldSelect } from "./FieldSelect"
import { OperationSelect } from "./OperationSelect"
import { ValuesField } from "./ValuesField"

type ISlaRuleItem = Pick<ISlaItem, "field" | "op" | "values"> & {
  name: SlaField
  path?: number[]
  isLastChild?: boolean
  isViewMode?: boolean
}

export const SlaRuleItem = ({
  name,
  path = [],
  field,
  op,
  values,
  isLastChild,
  isViewMode,
}: ISlaRuleItem) => {
  return (
    <Grid
      columns="1fr 1fr 1fr 1fr"
      columnGap={16}
      align="center"
      css={{
        marginLeft: "$80",
        marginTop: "$32",
        backgroundColor: path.length % 2 === 1 ? "$system-white" : "$theme-n2-n7",
        borderRadius: "$8",
        position: "relative",

        before: {
          content: "''",
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          left: "-$32",
          width: "$32",
          height: "$2",
          backgroundColor: "$theme-n3-n7",
        },

        after: {
          content: "''",
          position: "absolute",
          top: "-$32",
          left: "-$32",
          width: "$2",
          height: `calc(${isLastChild ? "50%" : "100%"} + $32)`,
          backgroundColor: "$theme-n3-n7",
          zIndex: "$1",
        },
      }}
    >
      <FieldSelect name={name} path={path} field={field} isViewMode={isViewMode} />
      <OperationSelect name={name} path={path} field={field} op={op} isViewMode={isViewMode} />
      <ValuesField name={name} path={path} field={field} values={values} isViewMode={isViewMode} />
      {!isViewMode ? <RemoveButton type={SlaItemType.Rule} name={name} path={path} /> : null}
    </Grid>
  )
}
