import React, { memo } from "react"

import { SSwitchIndicator } from "./Switch.styles"

interface SwitchIndicatorProps {
  checked?: boolean
}

export const SwitchIndicator = memo(({ checked }: SwitchIndicatorProps) => (
  <SSwitchIndicator checked={checked} />
))

SwitchIndicator.displayName = "SwitchIndicator"
