import { Spacer, Title } from "@/shared/components"
import { FlagsForm } from "@/setup/components"

export const FlagsContainer = () => {
  return (
    <>
      <Title as="h1" scale={2} color="system-black">
        Feature flags
      </Title>
      <Spacer size={32} />
      <FlagsForm />
    </>
  )
}
