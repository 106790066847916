// import { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"

import { ChangePersonInfoInput, Role, StatusType } from "@/shared/types"
import { useGlobalStore } from "@/store"
import { getUserRole, phoneToText } from "@/shared/utils"
// import { useProfileData } from "@/profile/hooks"

import { ProfileInfo } from "@/profile/components"

const personInfoInitialState: ChangePersonInfoInput = {
  phone: "",
  username: "",
  urgentContact: false,
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
  darkTheme: false,
  firstName: "",
  lastName: "",
  defaultOrganizationId: "",
  role: Role.SysAdmin,
  enabled: StatusType.Active,
  availableOrganizations: [],
}

export const ProfileForm = () => {
  const [user, organization] = useGlobalStore((state) => [state.user, state.organization])
  // const { getCurrentUser, updatePersonInfo } = useProfileData()

  const methods = useForm<ChangePersonInfoInput>({
    // mode: "onChange",
    mode: "all",
    defaultValues: {
      ...personInfoInitialState,
      availableOrganizations: [organization],
      firstName: user.firstName || personInfoInitialState.firstName,
      lastName: user.lastName || personInfoInitialState.lastName,
      phone: phoneToText(user.phone || personInfoInitialState.phone),
      username: user.username || personInfoInitialState.username,
      role: getUserRole(user) || personInfoInitialState.role,
      darkTheme: user.darkTheme || personInfoInitialState.darkTheme,
      defaultOrganizationId: user.defaultOrganizationId || organization.id,
      urgentContact: user.urgentContact || personInfoInitialState.urgentContact,
    },
  })

  // const { handleSubmit, watch, reset } = methods
  // const { phone, darkTheme } = watch()

  // const onSubmitHandler: SubmitHandler<ChangePersonInfoInput> = async (values) => {
  //   updatePersonInfo(values, {
  //     onSuccess: () => {
  //       const newPersonInfo = { ...user, phone, darkTheme }
  //       setUser(newPersonInfo)
  //       // INFO: we use reset here to update old dirtyValues and use current form data
  //       reset({
  //         ...personInfoInitialState,
  //         availableOrganizations: [organization],
  //         firstName: newPersonInfo.firstName || personInfoInitialState.firstName,
  //         lastName: newPersonInfo.lastName || personInfoInitialState.lastName,
  //         phone: newPersonInfo.phone || personInfoInitialState.phone,
  //         username: newPersonInfo.username || personInfoInitialState.username,
  //         role: getUserRole(newPersonInfo) || personInfoInitialState.role,
  //         darkTheme: newPersonInfo.darkTheme || personInfoInitialState.darkTheme,
  //         defaultOrganizationId:
  //           newPersonInfo.defaultOrganizationId || personInfoInitialState.defaultOrganizationId,
  //         urgentContact: newPersonInfo.urgentContact || personInfoInitialState.urgentContact,
  //       })

  //       showToast({ type: "success", text: "Saved successfully" })
  //     },
  //   })
  // }

  // useEffect(() => {
  //   getCurrentUser()
  // }, [getCurrentUser])

  return (
    <FormProvider {...methods}>
      {/* <form onSubmit={handleSubmit(onSubmitHandler)} noValidate autoComplete="off"> */}
      <form noValidate autoComplete="off">
        <ProfileInfo />
        {/* <EditProfileButton isLoading={isLoading} /> */}
      </form>
    </FormProvider>
  )
}
