import { useEffect, useRef, useState } from "react"
import { useFormContext } from "react-hook-form"
import { useDebouncedCallback } from "use-debounce"

import { useModal, useModalActions } from "@/shared/hooks"
import {
  ModalType,
  StrategyType,
  IOrganizationState,
  IOrganizationStrategy,
  FeatureFlags,
} from "@/shared/types"
import { getFeatureFlags } from "@/shared/utils"

import {
  ButtonIcon,
  Copy,
  Flex,
  FormInput,
  Grid,
  GridItem,
  Popover,
  PopoverAnchor,
  Spacer,
  Spinner,
  Stack,
  Title,
} from "@/shared/components"
import { GeneralModal } from "@/modals"
import { IconCross, IconSearch } from "@/shared/icons"
import { Pagination } from "@/management/components"

const getStrategyNameByType = (strategyType: StrategyType) => {
  switch (strategyType) {
    case StrategyType.Waterfall:
      return "Waterfall"
    case StrategyType.WaitAll:
      return "Wait for all quotes"
    case StrategyType.FirstCome:
      return "First come first serve"
    case StrategyType.Critical:
      return "Critical"
    default:
      return ""
  }
}

export const ExecutionStrategyModal = ({
  strategyType,
  inputValue,
  setInputValue,
  page,
  setPage,
  waterfallFetching,
  waitAllFetching,
}: {
  strategyType: StrategyType
  inputValue: string
  setInputValue: (value: string) => void
  page: number
  setPage: (value: number) => void
  waterfallFetching: boolean
  waitAllFetching: boolean
}) => {
  const [isIdInsteadScac] = getFeatureFlags([FeatureFlags.FEATURE_FLAG_USE_CARRIER_ID_INSTEAD_SCAC])

  const { modalProps } = useModal(ModalType.ExecutionStrategyModal)
  const { close } = useModalActions()
  const containerRef = useRef<HTMLDivElement>(null)
  const clearButtonRef = useRef<HTMLButtonElement>(null)
  const triggerRef = useRef<HTMLInputElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [currentContent, setCurrentContent] = useState<IOrganizationStrategy>()
  const debouncedSetIsOpen = useDebouncedCallback((v) => setIsOpen(v), 300)
  const { watch } = useFormContext<IOrganizationState>()

  const { waterfallStrategy, waitAllStrategy, firstComeStrategy, criticalStrategy } = watch()

  useEffect(() => {
    switch (strategyType) {
      case StrategyType.Waterfall:
        setCurrentContent(waterfallStrategy)
        break
      case StrategyType.WaitAll:
        setCurrentContent(waitAllStrategy)
        break
      case StrategyType.FirstCome:
        setCurrentContent(firstComeStrategy)
        break
      case StrategyType.Critical:
        setCurrentContent(criticalStrategy)
        break
    }
  }, [strategyType, waterfallStrategy, waitAllStrategy, firstComeStrategy, criticalStrategy])

  const handleClearButton = () => {
    setInputValue("")
    setIsOpen(false)
    setPage(0)
  }

  const handleCancelClick = () => {
    close(ModalType.ExecutionStrategyModal)
    setInputValue("")
    setPage(0)
  }

  return (
    <GeneralModal
      {...modalProps}
      closeButton={{ onClick: handleCancelClick }}
      onInteractOutside={handleCancelClick}
      contentCss={{
        height: 880,
        width: 1073,
      }}
      containerCss={{
        maxHeight: 880,
      }}
      size="lg"
    >
      <Stack space={12} css={{ padding: "$64 $20 $20", minHeight: 810 }}>
        <Title as="h3" scale={2} color="system-black">
          {getStrategyNameByType(strategyType)}
        </Title>

        <Spacer size={20} />

        <Popover open={isOpen}>
          <PopoverAnchor asChild>
            <Flex align="center" css={{ position: "relative", width: 320 }} ref={containerRef}>
              <FormInput
                ref={triggerRef}
                id="globalSearch"
                value={inputValue}
                label="Search"
                labelProps={{ hidden: true, style: { width: 40 } }}
                placeholder="Search by carrier name"
                autoComplete="off"
                onClick={() => {
                  if (!isOpen && inputValue.trim().length > 3) {
                    setIsOpen(true)
                  }
                }}
                onFocus={() => {
                  if (!isOpen && inputValue.trim().length > 3) {
                    setIsOpen(true)
                  }
                }}
                onChange={(event) => {
                  setInputValue(event.target.value)
                  setPage(0)

                  if (event.target.value.trim().length > 3) {
                    debouncedSetIsOpen(true)
                  } else {
                    setIsOpen(false)
                  }
                }}
                prefix={<IconSearch />}
                suffix={
                  inputValue && (
                    <ButtonIcon
                      ref={clearButtonRef}
                      icon={<IconCross />}
                      ariaLabel="Clear button"
                      onClick={handleClearButton}
                      inputIcon
                    />
                  )
                }
                css={{
                  width: 160,
                  height: "$40",
                  minHeight: "$40",
                }}
              />
            </Flex>
          </PopoverAnchor>
        </Popover>

        <Spacer size={12} />

        <Grid
          columns="1fr 1fr"
          align="center"
          css={{
            height: "$48",
            color: "$theme-b-n3",
            borderBottom: "1px solid $theme-n3-n7",
          }}
        >
          <GridItem css={{ paddingX: "$24" }}>
            <Copy scale={10} fontWeight="bold" uppercase>
              Carrier name
            </Copy>
          </GridItem>
          <GridItem css={{ paddingX: "$24" }}>
            <Copy scale={10} fontWeight="bold" uppercase>
              {isIdInsteadScac ? "TMS" : "Scac"}
            </Copy>
          </GridItem>
        </Grid>

        {isIdInsteadScac && currentContent?.carrierIds && !waterfallFetching && !waitAllFetching ? (
          currentContent?.carrierIds
            .filter((data) => data.carrierName.toLowerCase().includes(inputValue.toLowerCase()))
            .map((data, index) => (
              <Grid
                key={index + data.carrierName}
                columns="1fr 1fr"
                align="center"
                css={{ height: "$40", paddingBottom: "$24" }}
              >
                <GridItem css={{ paddingX: "$24" }}>
                  <Copy scale={8}>{data.carrierName}</Copy>
                </GridItem>
                <GridItem css={{ paddingX: "$24" }}>
                  <Copy scale={8}>{data.tmsCode}</Copy>
                </GridItem>
              </Grid>
            ))
        ) : !isIdInsteadScac && currentContent?.scacs && !waterfallFetching && !waitAllFetching ? (
          currentContent?.scacs
            .filter((data) => data?.name?.toLowerCase().includes(inputValue.toLowerCase()))
            .map((data) => (
              <Grid
                key={data.carrierId}
                columns="1fr 1fr"
                align="center"
                css={{ height: "$40", paddingBottom: "$24" }}
              >
                <GridItem css={{ paddingX: "$24" }}>
                  <Copy scale={8}>{data.name}</Copy>
                </GridItem>
                <GridItem css={{ paddingX: "$24" }}>
                  <Copy scale={8}>{data.scac}</Copy>
                </GridItem>
              </Grid>
            ))
        ) : (
          <Flex align="center" justify="center" css={{ height: 500 }}>
            <Spinner />
          </Flex>
        )}
      </Stack>
      {!!currentContent?.totalPages && (
        <Pagination totalPages={currentContent?.totalPages} currentPage={page} onPageChange={setPage} />
      )}
    </GeneralModal>
  )
}
