import { useFormContext } from "react-hook-form"

import { Weekdays, WorkingTime, IOrganizationState, IWorkingDay } from "@/shared/types"
import { WORKING_DAYS_MAX } from "@/constants"

import { Box, Copy, Spacer, Grid, Stack, AddItemButton, Title } from "@/shared/components"

import { WorkingDaysTableRow, WorkingDaysCreateRow } from "./components"

const weekDays: Weekdays[] = Object.values(Weekdays)
const sortDays = (workingDays: IWorkingDay[]) =>
  workingDays.sort((a, b) => weekDays.indexOf(a.day) - weekDays.indexOf(b.day))

export const WorkingDays = () => {
  const { setValue, watch, clearErrors, trigger } = useFormContext<IOrganizationState>()
  const { workingDays } = watch()

  return (
    <Box>
      <Title as="h2" scale={6} color="theme-b-n3">
        Working days & time
      </Title>
      <Spacer size={32} />

      <AddItemButton
        text="Add line"
        onClick={() => {
          setValue(
            "workingDays",
            sortDays([
              {
                id: `${Date.now()}`,
                day: weekDays.find((i) => !workingDays.find((el) => el.day === i)) || Weekdays.Monday,
                start: WorkingTime["07:00 AM"],
                end: WorkingTime["07:00 PM"],
              },
              ...workingDays,
            ]),

            {
              shouldDirty: true,
            },
          )
          setTimeout(() => {
            trigger("workingDays")
          }, 0)
        }}
        disabled={workingDays.length >= WORKING_DAYS_MAX}
      />
      <Spacer size={24} />
      <WorkingDaysTableRow
        day="working day"
        start="working time, start"
        end="working time, end"
        fullDay="24-hour day"
        isTableHead
      />
      {workingDays.length === 0 ? (
        <Grid columns="1fr" css={{ padding: "$20 $16", textAlign: "center" }}>
          <Copy color="theme-n6-n5">There is no data yet</Copy>
        </Grid>
      ) : (
        <Stack space={0}>
          {workingDays.map((field, index) => (
            <WorkingDaysCreateRow
              key={field.day}
              index={index}
              onRemoveClick={() => {
                setValue(
                  "workingDays",
                  workingDays.filter((_, i) => i !== index),
                  { shouldDirty: true },
                )
                clearErrors(`workingDays.${index}`)
              }}
            />
          ))}
        </Stack>
      )}
    </Box>
  )
}
