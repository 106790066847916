import ReactECharts from "echarts-for-react"
import { type EChartsOption, type LineSeriesOption } from "echarts"

import { rgba } from "@/stitches/utils"
import { LineChartTypes } from "@/shared/types"

import { Flex, Redacted } from "@/shared/components"

export const LineChartPlaceholder = ({ chartType }: { chartType: LineChartTypes }) => {
  const series: LineSeriesOption[] = [
    {
      type: "line",
      data: [0, 17, 12, 30, 20, 55, 32, 71],
      areaStyle: {
        color: "neutrals-2",
      },
      lineStyle: {
        color: "none",
      },
      showSymbol: false,
      silent: true,
    },
  ]

  const chartOption: EChartsOption = {
    grid: {
      containLabel: true,
      bottom: 120,
      show: false,
    },
    toolbox: {},
    xAxis: {
      type: "category",
      boundaryGap: false,
      name: "Days",
      nameRotate: 0,
      nameLocation: "start",
      nameTextStyle: {
        color: rgba("neutrals-5"),
        align: "center",
        verticalAlign: "top",
        padding: [55, 0, 0, 50],
      },
      axisLabel: {
        rotate: 50,
        backgroundColor: rgba("neutrals-3"),
        width: 25,
      },
      axisLine: {
        lineStyle: {
          color: rgba("neutrals-2"),
        },
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      data: ["", " ", " ", " ", " ", " ", " ", " "],
    },
    yAxis: {
      type: "value",
      name:
        chartType === LineChartTypes.TENDERS
          ? "% of accepted tenders out of offered to each individual carrier"
          : "% of responded RFQs out of offered to each individual carrier",
      nameRotate: 90,
      nameLocation: "middle",
      nameTextStyle: {
        color: rgba("neutrals-5"),
        padding: [0, 0, 60, 0],
      },
      axisLabel: {
        formatter: (value: number) => (value !== 0 ? `${value}%` : "0"),
        color: rgba("neutrals-5"),
      },
      min: 0,
      max: 100,
      minInterval: 25,
      splitLine: {
        show: false,
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: rgba("neutrals-2"),
        },
      },
    },
    axisPointer: {
      show: false,
    },
    series,
  }

  return (
    <>
      <ReactECharts option={chartOption} style={{ height: 600 }} />
      <Flex justify="between" css={{ marginLeft: "$32", width: "86%" }}>
        {Array.from(new Array(4), (_, index) => index).map((v) => (
          <Flex css={{ gap: "$16" }} key={v}>
            <Redacted width="$16" css={{ borderRadius: "$16" }} />
            <Redacted width="$64" />
          </Flex>
        ))}
      </Flex>
    </>
  )
}
