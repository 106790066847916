import { Controller, FieldValues, Path, PathValue } from "react-hook-form"
import { IFormInputControllerProps } from "@/shared/types"
import { FormSelect } from "@/shared/components"

const countries = ["United States"]

export const CountryController = <FieldsType extends FieldValues>({
  name,
  control,
  error,
  disabled,
  register,
  onChangeHandler,
}: IFormInputControllerProps<FieldsType>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <FormSelect
            {...(register &&
              register(field.name, {
                shouldUnregister: true,
              }))}
            {...field}
            onValueChange={(value) => {
              if (value !== field.value) {
                onChangeHandler && onChangeHandler()
              }

              return field.onChange(value as PathValue<FieldsType, Path<FieldsType>>)
            }}
            id={name}
            isDisplayValueDifferent
            value={{
              value: field.value,
              label: field.value === "" ? "Not selected" : field.value,
            }}
            options={[
              { value: "", label: "Not selected" },
              ...countries.map((i) => ({
                value: i,
                label: i,
              })),
            ]}
            label="Country"
            labelProps={{ hidden: true }}
            description="Country"
            disabled={disabled}
            error={error}
          />
        )
      }}
    />
  )
}
