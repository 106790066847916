import { useEffect } from "react"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { useTheme } from "next-themes"

import { useSendCarrierEmail } from "@/carrier/hooks"
import { ThemeType, RecoveryInput } from "@/shared/types"

import { CarrierForm } from "../CarrierForm"

export const CarrierFormContainer = () => {
  const { setTheme } = useTheme()
  const methods = useForm<RecoveryInput>({
    mode: "onChange",
    defaultValues: {
      email: "",
    },
  })

  const { handleSubmit } = methods

  const { mutate: sendCarrierEmail, isLoading } = useSendCarrierEmail()

  const onSubmitHandler: SubmitHandler<RecoveryInput> = (values) => {
    sendCarrierEmail(values)
    methods.setValue("email", "")
  }

  useEffect(() => {
    setTheme(ThemeType.Light)
  }, [setTheme])

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmitHandler)} noValidate autoComplete="off">
        <CarrierForm isLoading={isLoading} />
      </form>
    </FormProvider>
  )
}
