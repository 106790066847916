import { useState } from "react"
import { useParams } from "react-router-dom"

import {
  useAllEventsForOrder,
  useQuotesByOrderId,
  useOrganizationCarriersOrderView,
} from "@/management/hooks"

import { dateTimeFormatInTimeZone } from "@/shared/utils"
import { RouteParams } from "@/shared/types"

import {
  Box,
  Copy,
  Flex,
  Grid,
  GridContainer,
  GridItem,
  Redacted,
  Spacer,
  Stack,
  Switch,
  SwitchOption,
  useSwitch,
} from "@/shared/components"

import { OrderEventsTableRow } from "./components"

export const OrderEventsTable = () => {
  const { orderId, organizationId } = useParams<keyof RouteParams>() as RouteParams
  const [ascSortDirection, setAscSortDirection] = useState(true)
  const [isActive, setIsActive] = useState(false)
  const switchProps = useSwitch("darkTheme", isActive ? "true" : "false")

  const { data, isLoading: isAllEventsLoading } = useAllEventsForOrder(orderId)
  const { data: quotes = [], isLoading: isQuotesLoading } = useQuotesByOrderId(orderId)
  const { data: carriersData, isLoading: isOrganizationCarriersLoading } = useOrganizationCarriersOrderView({
    orgId: organizationId,
    page: 0,
    size: quotes.length,
    ids: quotes.map((i) => Number(i.carrierId)).join(","),
  })

  const isLoading = isAllEventsLoading || isQuotesLoading || isOrganizationCarriersLoading

  const getCarrierName = (carrierId: string) => {
    let carrierName = "-"
    if (carriersData && carriersData.content) {
      const carrier = carriersData.content.find((i) => `${i.id}` === carrierId)
      carrierName = carrier?.name || "-"
    }

    return carrierName
  }

  return (
    <GridContainer fullBleed>
      <Flex align="center" justify="start" css={{ gap: "$16" }}>
        <Copy scale={8} color="theme-b-n3" fontWeight="bold">
          View all events
        </Copy>
        <Switch
          {...switchProps}
          onValueChange={(value) => {
            setIsActive(JSON.parse(value))
            switchProps.onValueChange(value)
          }}
          checked={JSON.parse(switchProps.value)}
        >
          <SwitchOption value={isActive ? "false" : "true"} />
        </Switch>
      </Flex>
      <Spacer size={32} />
      <Box css={{ backgroundColor: "$system-white", borderRadius: "$8" }}>
        <Grid rows="$48 1fr">
          <GridItem css={{ borderBottom: "1px solid $theme-n3-n7" }}>
            <OrderEventsTableRow
              isTableHead
              isPlaceholder={isLoading}
              hideAdditionalData={isActive}
              ascSortDirection={ascSortDirection}
              setAscSortDirection={setAscSortDirection}
            />
          </GridItem>
          <GridItem>
            <Box
            // css={{
            //   // INFO: this is the sum of all components above
            //   height: "calc((var(--vh) * 100) - 474px)",
            //   overflowY: "overlay",
            //   marginRight: -15,
            // }}
            >
              {isLoading || !data ? (
                <Stack space={0}>
                  {Array.from({ length: 10 }, (_, i) => i).map((i) => (
                    <OrderEventsTableRow key={i}>
                      <GridItem>
                        <Redacted height="$24" text animated />
                      </GridItem>
                      <GridItem>
                        <Redacted height="$24" text animated />
                      </GridItem>
                      <GridItem>
                        <Redacted height="$24" text animated />
                      </GridItem>
                      <GridItem>
                        <Redacted height="$24" text animated />
                      </GridItem>
                    </OrderEventsTableRow>
                  ))}
                </Stack>
              ) : data.length === 0 ? (
                <Grid columns="1fr" css={{ padding: "$20 $16", textAlign: "center" }}>
                  <Copy color="theme-n6-n5">This order does not have events</Copy>
                </Grid>
              ) : (
                <Stack space={0}>
                  {data
                    .filter((i) => (!isActive ? !i.internalOnly : true))
                    .sort((a, b) =>
                      ascSortDirection
                        ? Date.parse(a.timestamp) - Date.parse(b.timestamp)
                        : Date.parse(b.timestamp) - Date.parse(a.timestamp),
                    )
                    .map((event) => {
                      const {
                        timestamp,
                        message,
                        source,
                        level,
                        params: { carrierId },
                      } = event

                      const carrierName = getCarrierName(`${carrierId}`)

                      return (
                        <OrderEventsTableRow key={timestamp} hideAdditionalData={isActive}>
                          <GridItem>
                            <Copy>
                              {dateTimeFormatInTimeZone({
                                dateTime: timestamp,
                                format: "MMM d, yyyy hh:mm:ss aa (zzz)",
                              })}
                            </Copy>
                          </GridItem>
                          <GridItem>
                            <Copy color={level == "ERROR" ? "special-error" : "system-black"}>{message}</Copy>
                          </GridItem>
                          {isActive && (
                            <GridItem>
                              <Copy>{source}</Copy>
                            </GridItem>
                          )}
                          <GridItem>
                            <Copy>{carrierName}</Copy>
                          </GridItem>
                        </OrderEventsTableRow>
                      )
                    })}
                </Stack>
              )}
            </Box>
          </GridItem>
        </Grid>
      </Box>
    </GridContainer>
  )
}
