import { memo } from "react"
import { Colors, ResponsiveProp } from "@/stitches/types"
import { ICarrierOverallResult } from "@/shared/types"
import { Copy, Flex, TypographyScale } from "@/shared/components"

const getStatusColor = (status: string, background = false): Colors => {
  if (status === ICarrierOverallResult.PASS) {
    return background ? "specials-success" : "system-white"
  }
  if (status === ICarrierOverallResult.FAIL) {
    return background ? "specials-error" : "system-white"
  }
  return background ? "theme-n2-n7" : "neutrals-5"
}

export const HighwayStatusLabel = memo(
  ({
    status,
    scale = 8,
    filled = false,
  }: {
    status: string
    scale?: TypographyScale | ResponsiveProp<TypographyScale>
    filled?: boolean
  }) => {
    let label = ""
    if (status === ICarrierOverallResult.PASS) {
      label = "Passed"
    }
    if (status === ICarrierOverallResult.FAIL) {
      label = "Failed"
    }

    return filled ? (
      <Flex
        align="center"
        css={{
          height: "$32",
          borderRadius: "$32",
          padding: "$0 $16",
          backgroundColor: `$${getStatusColor(status, true)}`,
        }}
      >
        <Copy scale={scale} color={getStatusColor(status)} truncate fontWeight="bold">
          {label}
        </Copy>
      </Flex>
    ) : (
      <Copy scale={scale} color={getStatusColor(status)} truncate fontWeight="bold">
        {label}
      </Copy>
    )
  },
)

HighwayStatusLabel.displayName = "HighwayStatusLabel"
