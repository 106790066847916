import { Controller, FieldValues, Path, PathValue } from "react-hook-form"

import { IFormInputControllerProps, CarrierType } from "@/shared/types"
import { carrierTypeToText } from "@/shared/utils"

import { FormSelect } from "@/shared/components"

const locationTypeList = [CarrierType.Asset, CarrierType.Broker]

export const CarrierTypeController = <FieldsType extends FieldValues>({
  name,
  control,
  error,
  disabled,
  labelProps,
  register,
  onChangeHandler,
}: IFormInputControllerProps<FieldsType>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <FormSelect
            {...(register &&
              register(field.name, {
                shouldUnregister: true,
                required: {
                  value: !!labelProps?.required,
                  message: "Required field",
                },
              }))}
            {...field}
            isDisplayValueDifferent
            value={{
              value: field.value,
              label: field.value === "" ? "Not selected" : carrierTypeToText(field.value),
            }}
            onValueChange={(value) => {
              if (value !== field.value) {
                onChangeHandler && onChangeHandler()
              }
              return field.onChange(value as PathValue<FieldsType, Path<FieldsType>>)
            }}
            id={name}
            label="Type"
            labelProps={{ ...labelProps, hidden: true }}
            description="Type"
            disabled={disabled}
            options={[
              { value: "", label: "Not selected" },
              ...locationTypeList.map((i) => ({
                value: i,
                label: carrierTypeToText(i),
              })),
            ]}
            error={error}
          />
        )
      }}
    />
  )
}
