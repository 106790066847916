import axios from "axios"

import { ORDERS_CARS_URI } from "@/constants"

export const dashboardApi = axios.create({
  baseURL: ORDERS_CARS_URI,
})

dashboardApi.defaults.headers.common["Content-Type"] = "application/json"

export const postAcceptOrder = async (token: string, orderId: string, rate: string) => {
  const { data } = await dashboardApi.post<string>(
    `inbound/orders/tender/book-from-carrier-dashboard`,
    { orderId, rateInDollars: rate },
    { headers: { "CARRIER-DASHBOARD-TOKEN": token } },
  )

  return data
}

export const postRejectOrder = async (token: string, orderId: string) => {
  const { data } = await dashboardApi.post<string>(
    `inbound/orders/tender/reject-from-carrier-dashboard`,
    { orderId },
    { headers: { "CARRIER-DASHBOARD-TOKEN": token } },
  )

  return data
}
