import { memo } from "react"

import { CSS } from "@/stitches/config"

import { ButtonIcon } from "@/shared/components"
import { IconBin } from "@/shared/icons"

export const RemoveItemButton = memo(
  ({
    text,
    index,
    onClick,
    buttonCss,
  }: {
    text: string
    index: number
    onClick: () => void
    buttonCss?: CSS
  }) => {
    return (
      <ButtonIcon
        type="button"
        ariaLabel={`Remove ${text} ${index}`}
        icon={<IconBin />}
        onClick={onClick}
        inputIcon
        css={{ ...buttonCss }}
      />
    )
  },
)

RemoveItemButton.displayName = "RemoveItemButton"
