import { Dispatch, SetStateAction, useEffect, useState } from "react"

import { firstToUpperCase, dateTimeFormatInTimeZone, getKeyByValue } from "@/shared/utils"
import { useGlobalStore } from "@/store"
import { useAllFls } from "@/fls/hooks"
import { flsStatusesText } from "@/shared/types"

import { Box, Copy, Flex, Spacer } from "@/shared/components"
import { Pagination } from "@/management/components"
import { ItemsSelect } from "@/management/components/CarrierAnalytics/components"

import { FlsTablePlaceholder, FlsTableRow } from "./components"

export const OrdersTabInfo = () => {
  const [selectedItems, setSelectedItems] = useState<Record<string, string>[]>([])
  const [filteredStatus, setFilteredStatus] = useState<string>("")

  const [page, setPage, organization] = useGlobalStore((state) => [
    state.page,
    state.setPage,
    state.organization,
  ])

  const { data: allFls, isFetching: isAllFlsFetching } = useAllFls({
    page,
    filteredStatus,
  })

  const flsStatusOptions = Object.values(flsStatusesText).map((status) => ({
    status,
  }))

  const flsContent = allFls?.content ? allFls.content : []
  const totalPages = allFls?.totalPages || 0

  useEffect(() => {
    if (allFls?.totalPages && totalPages < page) {
      setPage(0)
    }
  }, [allFls?.totalPages, totalPages, page, setPage])

  useEffect(() => {
    const filteredStatus = selectedItems
      .map((item) => getKeyByValue(item.status, flsStatusesText))
      .filter(Boolean)
      .join(", ")

    setFilteredStatus(filteredStatus)
  }, [selectedItems])

  if (isAllFlsFetching || !allFls) {
    return <FlsTablePlaceholder />
  }

  return (
    <>
      <Flex css={{ gap: "$12" }}>
        <ItemsSelect
          items={flsStatusOptions || []}
          selectedItemsState={[
            selectedItems,
            setSelectedItems as Dispatch<SetStateAction<Record<string, string>[]>>,
          ]}
          isSelectAll={false}
          keyField="status"
          labelField="status"
          titleField="Status"
          infoField="status"
        />
      </Flex>
      <Spacer size={32} />
      <Box css={{ backgroundColor: "$system-white", borderRadius: "$8" }}>
        <FlsTableRow
          orderId="ID"
          createdAt="Created"
          status="Status"
          shipperAddress="From"
          consigneeAddress="To"
          customerId="Customer"
          isTableHead
        />
        <Box
        // css={{
        //   maxHeight: "calc((var(--vh) * 100) - 460px)",
        //   overflowY: "scroll",
        //   marginRight: -15,
        // }}
        >
          {flsContent.length > 0 ? (
            flsContent.map(
              ({ orderId, createdAt, status, fromCity, toCity, fromState, toState, customerId }) => {
                const shipperAddress =
                  fromCity && fromState ? `${firstToUpperCase(fromCity)}, ${fromState}` : "-"

                const consigneeAddress = toCity && toState ? `${firstToUpperCase(toCity)}, ${toState}` : "-"

                return (
                  <FlsTableRow
                    key={orderId}
                    orderId={orderId}
                    shipperAddress={shipperAddress}
                    consigneeAddress={consigneeAddress}
                    status={status}
                    customerId={customerId}
                    createdAt={
                      dateTimeFormatInTimeZone({ dateTime: createdAt, timeZone: organization?.timeZone }) ||
                      "-"
                    }
                  />
                )
              },
            )
          ) : (
            <Flex
              align="center"
              justify="center"
              css={{
                height: "$64",
                color: "$theme-b-n3",
              }}
            >
              <Copy color="theme-n6-n5">There is no data yet</Copy>
            </Flex>
          )}
        </Box>
      </Box>

      <Pagination totalPages={allFls?.totalPages} currentPage={page} onPageChange={setPage} />
    </>
  )
}
