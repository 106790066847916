import axios from "axios"

import { FLS_BASE_URI } from "@/constants"
import { buildUrlString } from "@/shared/utils"
import { IFlsFiltersState, IFlsFiltersStateResponse, IAllFlsResponse } from "@/shared/types"

export const flsApi = axios.create({
  baseURL: FLS_BASE_URI,
})

flsApi.defaults.headers.common["Content-Type"] = "application/json"

export const getFlsConfigFn = async (): Promise<IFlsFiltersState> => {
  const { data } = await flsApi.get<IFlsFiltersStateResponse>(`filter-configs`)
  const flsFilterState: IFlsFiltersState = {
    ...data,
    lanesFilter: {
      ...data.lanesFilter,
      filterData: data.lanesFilter.filterData.map(({ id, from, to }) => ({
        id,
        fromCity: from ? from.split(",")[0] : "",
        fromState: from ? from.split(",")[1].toUpperCase() : "",
        toCity: from ? to.split(",")[0] : "",
        toState: from ? to.split(",")[1].toUpperCase() : "",
      })),
    },
  }

  return flsFilterState
}

export const updateFlsConfigFn = async (flsFilter: IFlsFiltersState) => {
  const flsFilterDto: IFlsFiltersStateResponse = {
    ...flsFilter,
    lanesFilter: {
      ...flsFilter.lanesFilter,
      filterData: flsFilter.lanesFilter.filterData.map(({ id, fromCity, fromState, toCity, toState }) => ({
        ...(id && !isNaN(+id) && { id: id }),
        from: `${fromCity},${fromState}`,
        to: `${toCity},${toState}`,
      })),
    },
    customersFilter: {
      ...flsFilter.customersFilter,
      filterData: flsFilter.customersFilter.filterData.map(({ id, ...rest }) => ({
        ...rest,
        ...(id && !isNaN(+id) && { id: id }),
      })),
    },
  }

  const { data } = await flsApi.post("filter-configs", flsFilterDto)
  return data
}

export const getAllFlsFn = async ({
  page,
  size = 20,
  filteredStatus = "",
}: {
  page: number
  size?: number
  filteredStatus?: string
}) => {
  const queryParams = {
    orderStatuses: filteredStatus,
    size,
    page,
  }

  const baseUrl = "orders"
  const url = buildUrlString(baseUrl, queryParams)

  const { data } = await flsApi.get<IAllFlsResponse>(url)
  return data
}
