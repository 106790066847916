import { CommunicationType } from "@/shared/types"

export const carrierCommunicationTypeToText = (locationType: CommunicationType | string) => {
  switch (locationType) {
    case CommunicationType.EMAIL:
      return "Email"
    case CommunicationType.API:
      return "API"
    case CommunicationType.EDI:
      return "EDI"
    default:
      return "-"
  }
}
