import { useFormContext } from "react-hook-form"

import { numericPattern } from "@/shared/rules"
import { StrategyType, IOrganizationState } from "@/shared/types"

import {
  Copy,
  Flex,
  Grid,
  GridItem,
  PercentController,
  Spacer,
  Stack,
  TextFieldController,
} from "@/shared/components"

import { CarrierScacSelect } from "./CarrierScacSelect"

export const WaitAll = ({ isViewMode, isBroker }: { isViewMode?: boolean; isBroker?: boolean }) => {
  const {
    watch,
    control,
    register,
    trigger,
    formState: { errors },
  } = useFormContext<IOrganizationState>()
  const { waitAllStrategy, criticalStrategy, firstComeStrategy } = watch()

  return (
    <Stack space={32}>
      <Grid columns="1fr 1fr 1fr 1fr" columnGap={16}>
        {!isBroker && (
          <GridItem>
            {isViewMode ? (
              <Flex direction="column" wrap>
                <Copy scale={10} color="neutrals-5" fontWeight="semiBold">
                  Select carriers
                </Copy>
                <Spacer size={4} />
                <Flex align="center" css={{ minHeight: "$48", paddingY: "$12" }}>
                  <Copy>{waitAllStrategy.scacs.map((i) => i.scac).join(", ")}</Copy>
                </Flex>
              </Flex>
            ) : (
              <CarrierScacSelect type={StrategyType.WaitAll} />
            )}
          </GridItem>
        )}
        <GridItem>
          <TextFieldController<IOrganizationState>
            name="waitAllStrategy.finishPoint"
            control={control}
            register={register}
            trigger={trigger}
            labelProps={{ required: !isViewMode && waitAllStrategy.enabled }}
            description="Finish point, Business days before Pickup"
            rules={{
              ...numericPattern,
              validate: {
                max: (v: string) => parseInt(v || "0") < 22 || "Max value not met",
                min: (v: string) => v.length === 0 || parseInt(v || "0") >= 0 || "Min value not met",
                lessThanPrev: (v: string, { waterfallStrategy }) => {
                  if (waterfallStrategy.enabled && waitAllStrategy.enabled) {
                    return (
                      parseInt(v) <= parseInt(waterfallStrategy.finishPoint || "0") ||
                      "Should not exceed the previous strategy’s finish point"
                    )
                  }

                  return true
                },
              },
            }}
            onChangeHandler={() => {
              if (criticalStrategy.enabled) {
                trigger("criticalStrategy.finishPoint")
              }
              if (firstComeStrategy.enabled) {
                trigger("firstComeStrategy.finishPoint")
              }
            }}
            borderless={isViewMode}
            isViewMode={isViewMode}
            error={errors.waitAllStrategy?.finishPoint?.message}
          />
        </GridItem>
        <GridItem>
          <TextFieldController<IOrganizationState>
            name="waitAllStrategy.responseTime"
            control={control}
            register={register}
            trigger={trigger}
            labelProps={{ required: !isViewMode && waitAllStrategy.enabled }}
            description="Response time, minutes"
            rules={{
              ...numericPattern,
              validate: {
                max: (v: string) => parseInt(v || "0") < 1000 || "Max value exceeded",
                min: (v: string) => v.length === 0 || parseInt(v || "0") > 0 || "Min value not met",
              },
            }}
            borderless={isViewMode}
            isViewMode={isViewMode}
            error={errors.waitAllStrategy?.responseTime?.message}
          />
        </GridItem>
        {isBroker && (
          <GridItem>
            <PercentController<IOrganizationState>
              name="waitAllStrategy.priceTolerance"
              control={control}
              register={register}
              trigger={trigger}
              description="Price tolerance, %"
              error={errors.waitAllStrategy?.priceTolerance?.message}
            />
          </GridItem>
        )}
      </Grid>
    </Stack>
  )
}
