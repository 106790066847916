import React, { useEffect, useState } from "react"
import lazysizes from "lazysizes"
import "lazysizes/plugins/attrchange/ls.attrchange"

import { globalCss, darkTheme } from "@/stitches/config"
import { darkColorsMap } from "@/stitches/theme"
import { createContext } from "@/shared/utils"

import { IconProvider } from "@/shared/icons/IconProvider"

lazysizes.cfg.loadMode = 1

const globalStyles = () => {
  return globalCss({
    /* Box sizing rules */
    "*, *::before, *::after": {
      boxSizing: "border-box",
    },

    html: {
      WebkitTapHighlightColor: "transparent",
      marginRight: "calc(-1 * (100vw - 100%))",
      // zoom: window.devicePixelRatio > 1 ? 0.8 : 1,
      height: "100%",
      width: "100%",
    },

    /* Remove default margin */
    "body, h1, h2, h3, h4, h5, h6, p, figure, blockquote, dl, dd": {
      margin: 0,
    },

    /* Remove styles on ul, ol elements */
    "ul, ol": {
      listStyle: "none",
      margin: 0,
      padding: 0,
    },

    /* Set core body defaults */
    body: {
      minHeight: "calc(var(--vh) * 100)",
      fontFamily: "$sans",
      fontStyle: "normal",
      fontStretch: "normal",
      fontWeight: "normal",
      letterSpacing: "0.5px",
      textRendering: "optimizeSpeed",
      lineHeight: 1.5,
      overflowX: "hidden",
      backgroundColor: "$theme-w-n11",
    },

    "a, button": {
      color: "$system-inherit",
    },

    /* `<a>` elements that don't have a class get default styles */
    "a:not([class])": {
      transition: "150ms opacity",
      textDecorationSkipInk: "auto",
      textDecorationThickness: "from-font",

      hover: {
        opacity: 0.75,
      },
    },

    s: {
      textDecorationThickness: "from-font",
    },

    /* `<button>` elements that don't have a class get default styles */
    "button:not([class])": {
      appearance: "none",
      background: "transparent",
      border: "none",
      borderRadius: 0,
      padding: 0,
      cursor: "pointer",
      transition: "150ms opacity",

      hover: {
        opacity: 0.75,
      },
    },

    /* Make images easier to work with */
    "img, picture": {
      maxWidth: "100%",
      display: "block",
    },

    /* Inherit font and letterSpacing for inputs and buttons */
    "input, button, textarea, select": {
      font: "inherit",
      letterSpacing: "inherit",
    },

    "strong, b": {
      fontWeight: 500,
    },

    ".svg-sprite": {
      display: "none",
    },

    // 100vh fix in mobile webkit
    // https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/
    // Alternative: https://pqina.nl/blog/how-to-prevent-scrolling-the-page-on-ios-safari/
    "html[data-fullscreen-page]": {
      height: "-webkit-fill-available",

      body: {
        height: "calc(var(--vh) * 100)",
        minHeight: "-webkit-fill-available",
        overflow: "hidden",
      },
    },

    /* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
    "@reduceMotion": {
      "html:focus-within": {
        scrollBehavior: "auto",
      },

      "*, *::before, *::after": {
        animationDuration: "0.01ms !important",
        animationIterationCount: "1 !important",
        transitionDuration: "0.01ms !important",
        scrollBehavior: "auto",
      },
    },

    // custom scrollbar styles based on the current theme
    "*::-webkit-scrollbar": {
      // width: "auto",
      width: "var(--space-12)",
    },

    // "*::-webkit-scrollbar-track": {
    //   backgroundColor: "var(--colors-theme-w-n11)",
    // },

    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "var(--colors-neutrals-4)",
      // border: "3px solid var(--colors-theme-w-n11)",
      borderRadius: "var(--radii-8)",

      ":hover": {
        backgroundColor: "var(--colors-neutrals-5)",
      },
    },

    "iframe#webpack-dev-server-client-overlay": {
      display: "none",
    },
  })()
}

const pageCoverStyles = globalCss({
  "body::before": {
    content: "''",
    display: "block",
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    backgroundColor: "$theme-w-n11",
    zIndex: 99999,
  },

  ".render body::before": {
    display: "none",
  },
})

const updateThemeColors = globalCss({
  "@dark": {
    ":root:not(.light)": {
      ...Object.keys(darkTheme.colors).reduce((varSet, currentColorKey) => {
        const currentColor = darkTheme.colors[currentColorKey as keyof typeof darkColorsMap]
        const currentColorValue =
          currentColor.value.substring(0, 1) === "$" ? `$colors${currentColor.value}` : currentColor.value

        return {
          ...varSet,
          [currentColor.variable]: currentColorValue,
        }
      }, {}),
    },
  },
})

type GlobalSettingsContextValue = {
  globalProtectFlag: boolean
  setGlobalProtectFlag: React.Dispatch<React.SetStateAction<boolean>>
  globalProtectUrl: string
  setGlobalProtectUrl: React.Dispatch<React.SetStateAction<string>>
  userId: string
  setUserId: React.Dispatch<React.SetStateAction<string>>
  urgentId: string
  setUrgentId: React.Dispatch<React.SetStateAction<string>>
  organizationId: string
  setOrganizationId: React.Dispatch<React.SetStateAction<string>>
  orderRate: string
  setOrderRate: React.Dispatch<React.SetStateAction<string>>
  orderData: string[]
  setOrderData: React.Dispatch<React.SetStateAction<string[]>>
}

export const [GlobalSettingsProvider, useGlobalSettings] = createContext<GlobalSettingsContextValue>("global")

export const Global = ({
  children,
  shouldCoverPage,
}: React.PropsWithChildren<{
  shouldCoverPage?: boolean
}>) => {
  globalStyles()
  // body shift fix when opening a modal window
  document.body.setAttribute("style", "margin: 0px !important")

  const [globalProtectFlag, setGlobalProtectFlag] = useState(false)
  const [globalProtectUrl, setGlobalProtectUrl] = useState("")
  const [userId, setUserId] = useState("")
  const [organizationId, setOrganizationId] = useState("")
  const [urgentId, setUrgentId] = useState("")
  const [orderRate, setOrderRate] = useState("")
  const [orderData, setOrderData] = useState<string[]>([])

  if (shouldCoverPage) {
    pageCoverStyles()
  }

  updateThemeColors()

  useEffect(() => {
    if (shouldCoverPage) {
      document.documentElement.classList.add("render")
    }
  }, [shouldCoverPage])

  return (
    <GlobalSettingsProvider
      {...{
        globalProtectFlag,
        globalProtectUrl,
        userId,
        urgentId,
        organizationId,
        orderRate,
        orderData,
        setGlobalProtectFlag,
        setGlobalProtectUrl,
        setUserId,
        setUrgentId,
        setOrganizationId,
        setOrderRate,
        setOrderData,
      }}
    >
      {shouldCoverPage && (
        <noscript>
          <style type="text/css">{"body::before { display: none; }"}</style>
        </noscript>
      )}
      <IconProvider>{children}</IconProvider>
    </GlobalSettingsProvider>
  )
}
