import React from "react"

import { Colors } from "@/stitches/types"

import { Copy, Flex, IconFiller, Spacer } from "@/shared/components"

export const AnalyticsInfoCard = ({
  title,
  icon,
  iconBoxColor,
  value,
}: {
  title: string
  icon: React.ReactNode
  iconBoxColor: Colors
  value: string
}) => {
  return (
    <Flex
      direction="column"
      align="start"
      css={{ padding: "$20 $24", border: "1px solid $theme-n3-n7", borderRadius: "$8" }}
    >
      <IconFiller color={iconBoxColor}>{icon}</IconFiller>
      <Spacer size={16} />
      <Copy scale={8} fontWeight="bold">
        {value ? `${value}%` : "-"}
      </Copy>
      <Spacer size={8} />
      <Copy scale={10} color="theme-n6-n5" uppercase css={{ width: "$96" }}>
        {title}
      </Copy>
    </Flex>
  )
}
