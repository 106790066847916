import { useRef } from "react"
import { Slice, SliceProps, VictoryArea, VictoryClipContainer, VictoryLabel, VictoryPie } from "victory"

import { useElementDimensions } from "@/shared/hooks"

import { Grid, Stack, Redacted, Copy, Flex, Box, Spacer, IconTooltip, GridItem } from "@/shared/components"
import { IconCrown, IconWarning } from "@/shared/icons"

const colors = ["var(--colors-brand-violet-lighter)", "var(--colors-brand-violet-light)"]
const height = 300
const width = 300
const carrier = "Uber"
const current = 450
const max = 1040
const totalPrice = 3500
const data = [{ y: (max - current) / max, background: true }, { y: current / max }]

const heightArea = 60
const widthArea = 80
const sampleData = [
  { x: new Date(2021, 5, 1), y: 8 },
  { x: new Date(2021, 5, 2), y: 10 },
  { x: new Date(2021, 5, 3), y: 3 },
  { x: new Date(2021, 5, 4), y: 5 },
  { x: new Date(2021, 5, 7), y: 6 },
  { x: new Date(2021, 5, 8), y: 3 },
  { x: new Date(2021, 5, 9), y: 7 },
  { x: new Date(2021, 5, 10), y: 9 },
  { x: new Date(2021, 5, 11), y: 6 },
]

// const reportTypeList: ReportType[] = Object.values(ReportType)

export const Dashboard = () => {
  const containerRef = useRef<HTMLDivElement>(null)
  const { dimensions } = useElementDimensions(containerRef)

  const isLoading = false

  // const [reportType, setReportType] = useState(ReportType.TopCarriers)

  // if (isLoading) {
  //   return true //placeholder
  // }

  return (
    <Stack space={16}>
      <Grid columns="1fr 1fr 1fr 1fr 1fr" columnGap={16}>
        <GridItem ref={containerRef}>
          <StatisticCard
            title="# of covered orders"
            content={
              isLoading ? (
                <Stack space={12}>
                  <Redacted height="22px" width="170px" text animated />
                  <Redacted height="22px" width="170px" text animated />
                </Stack>
              ) : (
                <Flex
                  justify="between"
                  wrap
                  css={{ columnGap: "$8", paddingTop: "$16", textAlign: "center" }}
                >
                  <Box>
                    <Copy scale={2} color="theme-b-n3" fontWeight="bold">
                      7
                    </Copy>
                    <Copy scale={10} color="brand-blue-primary" fontWeight="semiBold">
                      Waterfall
                    </Copy>
                  </Box>
                  <Box>
                    <Copy scale={2} color="theme-b-n3" fontWeight="bold">
                      5
                    </Copy>
                    <Copy scale={10} color="brand-green-primary" fontWeight="semiBold">
                      Wait for all quotes
                    </Copy>
                  </Box>
                  <Box>
                    <Copy scale={2} color="theme-b-n3" fontWeight="bold">
                      2
                    </Copy>
                    <Copy scale={10} color="brand-red-primary" fontWeight="semiBold">
                      Critical
                    </Copy>
                  </Box>
                </Flex>
              )
            }
          />
        </GridItem>
        <GridItem>
          <StatisticCard
            title="Not covered orders"
            content={
              isLoading ? (
                <Stack space={12}>
                  <Redacted height="22px" width="170px" text animated />
                  <Redacted height="22px" width="170px" text animated />
                </Stack>
              ) : (
                <Chart
                  total="30%"
                  detail="5 orders"
                  delta="+1.01%"
                  subtitle="Compared to last week"
                  isPositive
                />
              )
            }
          />
        </GridItem>
        <GridItem>
          <StatisticCard
            title="Orders with insufficient data"
            content={
              isLoading ? (
                <Stack space={12}>
                  <Redacted height="22px" width="170px" text animated />
                  <Redacted height="22px" width="170px" text animated />
                </Stack>
              ) : (
                <Chart
                  total="1.2%"
                  detail="2 orders"
                  delta="+1.43%"
                  subtitle="Compared to last week"
                  isPositive={false}
                />
              )
            }
          />
        </GridItem>
        <GridItem>
          <StatisticCard
            title="Avg lead time (days)"
            tooltip="Difference between Order Ship Date and Order Creation Date"
            content={
              isLoading ? (
                <Stack space={12}>
                  <Redacted height="22px" width="170px" text animated />
                  <Redacted height="22px" width="170px" text animated />
                </Stack>
              ) : (
                <Chart total="1" delta="+5.52%" subtitle="Compared to last week" isPositive />
              )
            }
          />
        </GridItem>
        <GridItem>
          <StatisticCard
            title="Avg lead coverage speed (days)"
            tooltip="Difference between Order Ship Date and Order Creation Date"
            content={
              isLoading ? (
                <Stack space={12}>
                  <Redacted height="22px" width="170px" text animated />
                  <Redacted height="22px" width="170px" text animated />
                </Stack>
              ) : (
                <Chart total="1" delta="+97.20%" subtitle="Compared to last week" isPositive={false} />
              )
            }
          />
        </GridItem>
      </Grid>
      <Grid columns="1fr 1fr 1fr 1fr 1fr" columnGap={16}>
        <GridItem column="1 / span 1">
          <StatisticCard
            title="Top carrier by volume"
            content={
              isLoading ? (
                <Stack space={12}>
                  <Redacted height="22px" width="170px" text animated />
                  <Redacted height="22px" width="170px" text animated />
                </Stack>
              ) : (
                <>
                  <Flex align="center" justify="center">
                    <svg
                      width={dimensions.clientWidth - 50}
                      height={dimensions.clientWidth - 50}
                      viewBox={`0 0 ${width} ${height}`}
                    >
                      <VictoryPie
                        standalone={false}
                        startAngle={0}
                        endAngle={-360}
                        width={width}
                        height={height}
                        data={data}
                        innerRadius={85}
                        colorScale={colors}
                        dataComponent={<CustomSlice />}
                        cornerRadius={20}
                        labels={[]}
                      />
                      <VictoryLabel
                        textAnchor="middle"
                        style={{
                          fontSize: 25,
                          fontWeight: "bold",
                          fill: "var(--colors-theme-b-n3)",
                          fontFamily: "var(--fonts-sans)",
                        }}
                        x={height / 2}
                        y={width / 2 - 10}
                        text={`${current}/${max}`}
                      />
                      <VictoryLabel
                        textAnchor="middle"
                        style={{
                          fontSize: 18,
                          fontWeight: "bold",
                          fill: "var(--colors-neutrals-5)",
                          fontFamily: "var(--fonts-sans)",
                          textTransform: "uppercase",
                        }}
                        x={height / 2}
                        y={width / 2 + 20}
                        text="Orders"
                      />
                    </svg>
                  </Flex>
                  <Flex align="center" justify="center" css={{ gap: "$8" }}>
                    <IconCrown css={{ color: "$brand-orange-primary" }} />
                    <Copy scale={2} color="theme-b-n3" fontWeight="bold" uppercase>
                      {carrier}
                    </Copy>
                  </Flex>
                  <Spacer size={20} />
                  <Flex align="center" justify="center" css={{ gap: "$4" }}>
                    <Copy scale={10} color="theme-b-n3">
                      # of orders:
                    </Copy>
                    <Copy scale={10} color="theme-b-n3" fontWeight="bold">
                      {current}
                    </Copy>
                  </Flex>
                  <Spacer size={12} />
                  <Flex align="center" justify="center" css={{ gap: "$4" }}>
                    <Copy scale={10} color="theme-b-n3">
                      Total spend:
                    </Copy>
                    <Copy scale={10} color="theme-b-n3" fontWeight="bold">
                      {`$${totalPrice}`}
                    </Copy>
                  </Flex>
                </>
              )
            }
          />
        </GridItem>
        <GridItem column="2 / span 4">
          <Box
            css={{
              height: "100%",
              padding: "$40 $56",
              backgroundColor: "$system-white",
              border: "1px solid $theme-n3-n7",
              borderRadius: "$8",
            }}
          >
            {/* <Flex>
              <FormSelect
                id="reportType"
                name="reportType"
                label="report Type"
                labelProps={{ hidden: true }}
                value={reportType}
                onValueChange={(value) => {
                  setReportType(value as ReportType)
                }}
                options={reportTypeList}
              />
            </Flex> */}
          </Box>
        </GridItem>
      </Grid>
    </Stack>
  )
}

const StatisticCard = ({
  title,
  content,
  tooltip,
}: {
  title: string
  content: React.ReactNode
  tooltip?: string
}) => {
  return (
    <Flex
      direction="column"
      justify="between"
      css={{
        height: "100%",
        minHeight: 180,
        padding: "$24",
        backgroundColor: "$system-white",
        border: "1px solid $theme-n3-n7",
        borderRadius: "$8",
      }}
    >
      <Flex align="center" justify="between" css={{ gap: "$16" }}>
        <Copy color="theme-b-n3" fontWeight="bold" uppercase>
          {title}
        </Copy>
        {tooltip ? (
          <IconTooltip
            tooltip={<Copy scale={10}>{tooltip}</Copy>}
            ariaLabel="Chart tooltip"
            withTitle={false}
            contentWidth="max-content"
            trigger={["hover", "focus"]}
            placement="top"
            delayShow={150}
            delayHide={150}
            contentCss={{
              padding: "$12",
              borderRadius: "$8",
            }}
            triggerCss={{
              height: "$20",
            }}
            icon={<IconWarning fixedSize width={20} height={20} />}
          />
        ) : null}
      </Flex>
      {content}
    </Flex>
  )
}

const Chart = ({
  total,
  delta,
  subtitle,
  isPositive,
  detail,
}: {
  total: string
  delta: string
  subtitle: string
  isPositive: boolean
  detail?: string
}) => {
  return (
    <Grid columns="1fr $80" columnGap={8} align="end">
      <Stack space={8}>
        <Flex align="start" css={{ gap: "$8" }}>
          <Flex align="end" css={{ gap: "$8" }}>
            <Copy scale={2} color="theme-b-n3" fontWeight="bold">
              {total}
            </Copy>
            {detail ? (
              <Copy scale={10} color="theme-b-n3" fontWeight="bold">
                ({detail})
              </Copy>
            ) : null}
          </Flex>
          <Copy scale={10} color={`brand-${isPositive ? "green" : "red"}-primary`}>
            {delta}
          </Copy>
        </Flex>
        <Copy scale={10} color="neutrals-5">
          {subtitle}
        </Copy>
      </Stack>
      <svg width={widthArea} height={heightArea} viewBox={`0 0 ${widthArea} ${heightArea}`}>
        <VictoryArea
          standalone={false}
          width={widthArea}
          height={heightArea}
          padding={0}
          groupComponent={<VictoryClipContainer />}
          style={{
            data: {
              fill: isPositive
                ? "url(#gradient-vertical-positive) var(--colors-brand-green-light)"
                : "url(#gradient-vertical-negative) var(--colors-brand-red-light)",
              stroke: `var(--colors-brand-${isPositive ? "green" : "red"}-primary)`,
              strokeWidth: 2,
            },
          }}
          data={sampleData}
        />
        <linearGradient
          id={isPositive ? "gradient-vertical-positive" : "gradient-vertical-negative"}
          x2="0"
          y2="1"
        >
          <stop
            offset="0%"
            stopColor={`var(--colors-brand-${isPositive ? "green" : "red"}-primary)`}
            stopOpacity="0.4"
          />
          <stop
            offset="100%"
            stopColor={`var(--colors-brand-${isPositive ? "green" : "red"}-light)`}
            stopOpacity="0.4"
          />
        </linearGradient>
      </svg>
    </Grid>
  )
}

const CustomSlice = (props: SliceProps) => {
  const { datum } = props

  return (
    <Slice
      {...props}
      sliceStartAngle={datum.background ? 0 : props.sliceStartAngle}
      sliceEndAngle={datum.background ? 360 : props.sliceEndAngle}
    />
  )
}
