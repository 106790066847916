import { CommonLayout } from "@/shared/layouts/common"
import { CarrierContainer } from "@/carrier/components"
import { Box, GridContainer, GridItem } from "@/shared/components"

export const Carrier = () => {
  return (
    <CommonLayout>
      <GridContainer
        fullBleed={{ "@initial": true, "@sm": true }}
        css={{ minHeight: "calc(var(--vh, 1vh) * 100)", backgroundColor: "$system-layout", height: "100%" }}
      >
        <GridItem column={{ "@initial": "1 / span 6", "@sm": "1 / span 12", "@lg": "1 / span 24" }}>
          <Box css={{ padding: "$32" }}>
            <CarrierContainer />
          </Box>
        </GridItem>
      </GridContainer>
    </CommonLayout>
  )
}
