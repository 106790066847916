import { useFormContext } from "react-hook-form"

import { boxShadows } from "@/stitches/utils"
import {
  ModalType,
  StrategyType,
  IOrganizationState,
  OrganizationType,
  IOrganizationStrategy,
} from "@/shared/types"
import { strategyToText } from "@/shared/utils"
import { useModalActions } from "@/shared/hooks"
import { Colors } from "@/stitches/types"

import { Copy, Flex, GridItem, Stack } from "@/shared/components"
import { IconChevronRight } from "@/shared/icons"
import { IllustrationSpinner } from "@/shared/illustrations"

const getStrategyColorByType = (
  strategyType: StrategyType,
  isBroker: boolean,
): { main: Colors; second: Colors } => {
  switch (strategyType) {
    case StrategyType.Waterfall:
      return {
        main: "brand-blue-primary",
        second: "brand-blue-light",
      }
    case StrategyType.WaitAll:
      return isBroker
        ? {
            main: "brand-orange-primary",
            second: "brand-orange-light",
          }
        : {
            main: "brand-green-primary",
            second: "brand-green-light",
          }
    case StrategyType.FirstCome:
      return {
        main: "brand-green-primary",
        second: "brand-green-light",
      }
    case StrategyType.Critical:
      return {
        main: "brand-red-primary",
        second: "brand-red-light",
      }
    default:
      return {
        main: "system-white",
        second: "system-white",
      }
  }
}

const blockHeight = 72
const blockSpace = 12

export const StrategyColumn = ({
  strategyType,
  strategy,
  setStrategyType,
}: {
  strategyType: StrategyType
  strategy: IOrganizationStrategy
  setStrategyType?: (type: StrategyType) => void
}) => {
  const { open } = useModalActions()
  const { watch } = useFormContext<IOrganizationState>()
  const { waterfallStrategy, waitAllStrategy, firstComeStrategy, criticalStrategy, organizationType } =
    watch()

  const isWaterfallCarriers = waterfallStrategy.enabled ? 1 : 0
  const allCarriersHeight = waterfallStrategy.enabled
    ? blockHeight * isWaterfallCarriers + isWaterfallCarriers * blockSpace
    : 0
  const criticalStrategyHeight = criticalStrategy.enabled ? blockHeight * 1 + 1 * blockSpace : 0
  const firstComeStrategyHeight = firstComeStrategy.enabled ? blockHeight * 1 + 1 * blockSpace : 0
  const waitAllStrategyHeight = waitAllStrategy.enabled ? blockHeight * 1 + 1 * blockSpace : 0

  const getStrategyPaddingByType = (top?: boolean) => {
    switch (strategyType) {
      case StrategyType.Waterfall:
        return top
          ? 0
          : firstComeStrategyHeight +
              waitAllStrategyHeight +
              criticalStrategyHeight -
              (isWaterfallCarriers ? 0 : 12)
      case StrategyType.WaitAll:
        return top ? allCarriersHeight : criticalStrategyHeight + firstComeStrategyHeight
      case StrategyType.FirstCome:
        return top ? allCarriersHeight + waitAllStrategyHeight : criticalStrategyHeight
      case StrategyType.Critical:
        return top ? allCarriersHeight + firstComeStrategyHeight + waitAllStrategyHeight : 0
      default:
        return 0
    }
  }

  const handleOpenModal = (data: StrategyType) => {
    if (strategy.totalElements) {
      setStrategyType?.(data)
      open(ModalType.ExecutionStrategyModal)
    }
  }

  const getStrategyDaysByType = () => {
    switch (strategyType) {
      case StrategyType.Waterfall:
        return `>= ${waterfallStrategy.finishPoint}`
      case StrategyType.WaitAll:
        return `>=${waitAllStrategy.finishPoint}`
      case StrategyType.FirstCome:
        return `>=${firstComeStrategy.finishPoint}`
      case StrategyType.Critical:
        return `>=${criticalStrategy.finishPoint}`
      default:
        return "-"
    }
  }

  const isBroker = organizationType === OrganizationType.Broker

  return (
    <GridItem>
      <Stack space={12}>
        <Flex
          direction="column"
          justify="end"
          css={{
            gap: "$12",
            paddingX: "$12",
            paddingTop: `${getStrategyPaddingByType(true)}px`,
            paddingBottom: `calc(${getStrategyPaddingByType()}px + $12)`,
            borderColor: "none $neutrals-5",
            borderRight: "1px dashed $theme-n3-n7",
            borderLeft: "1px dashed $theme-n3-n7",
            borderBottom: `1px solid $${getStrategyColorByType(strategyType, isBroker).main}`,
            background: `linear-gradient(180deg, $system-white 0%, $${
              getStrategyColorByType(strategyType, isBroker).second
            } 100%)`,
          }}
        >
          <Flex
            css={{
              gap: "$4",
              height: `$${blockHeight}`,
              borderRadius: "$8",
              padding: "$8 $20",
              boxShadow: boxShadows.dropdown,
              background: "$system-white",
              overflowX: "hidden",

              cursor: strategy.totalElements ? "pointer" : "default",
              flexDirection: "row",
              justifyContent: "space-between",
              placeItems: "center",
              borderLeft: `4px solid $${getStrategyColorByType(strategyType, isBroker).main}`,

              hover: strategy.totalElements
                ? {
                    outline: "none",
                    boxShadow: "none",
                  }
                : {},
            }}
            onClick={() => handleOpenModal(strategyType)}
          >
            <Flex direction="column">
              {!!strategy.enabled && (
                <Copy scale={8} fontWeight="bold" uppercase>
                  {`${Number(strategy.totalElements)}` ? (
                    `${strategyToText(strategyType)} (${Number(strategy.totalElements)} ${
                      !!strategy.totalElements && strategy.totalElements === 1 ? "Carrier" : "Carriers"
                    })`
                  ) : (
                    <Flex align="center" css={{ padding: 4 }}>
                      <IllustrationSpinner />
                    </Flex>
                  )}
                </Copy>
              )}
              <Flex css={{ gap: "$12" }}>
                {strategyType !== StrategyType.FirstCome && !!strategy.responseTime && (
                  <Copy color="neutrals-5">Response time: {strategy.responseTime} min</Copy>
                )}
                {strategyType === StrategyType.WaitAll && !!strategy.priceTolerance && (
                  <Copy color="neutrals-5">Price tolerance: {strategy.priceTolerance}%</Copy>
                )}
              </Flex>
            </Flex>

            {!!strategy.totalElements && <IconChevronRight size="xs" />}
          </Flex>
        </Flex>

        <Copy color="neutrals-5" uppercase>
          {getStrategyDaysByType()} day(s) before pickup
        </Copy>
      </Stack>
    </GridItem>
  )
}
