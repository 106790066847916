import { FeatureFlags, IFeatureFlag } from "@/shared/types"
import { useGlobalStore } from "@/store"

export const getFeatureFlags = (keys: FeatureFlags[]): boolean[] => {
  const featureFlags: IFeatureFlag[] = useGlobalStore.getState().featureFlags

  return keys.map((key) => {
    const flag = featureFlags.find((flag) => flag.key === key)
    return flag?.value || false
  })
}
