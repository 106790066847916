import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"

import { RouteParams, IOrganizationState, initialOrganizationState } from "@/shared/types"
import { MANAGEMENT } from "@/constants"
import {
  useUpdateOrganizationStrategies,
  useCreateUrgentContacts,
  useCurrentAndAvailableSource,
  useOrganizationById,
  useOrganizationCarriersFull,
  useOrganizationStrategies,
  useUpdateCurrentSource,
} from "@/management/hooks"

import { Breadcrumbs, Title, Spacer, Redacted, Box } from "@/shared/components"
import { showToast } from "@/shared/utils"

import { EditStrategiesButton, ExecutionStrategy, ExecutionStrategyPreloader } from "@/management/components"

export const EditStrategiesContainer = () => {
  const { organizationId } = useParams<keyof RouteParams>() as RouteParams
  const navigate = useNavigate()

  const { data: carriersData, isLoading: organizationCarriersLoading } = useOrganizationCarriersFull({
    orgId: organizationId,
  })

  const { data: organization, isLoading: organizationLoading } = useOrganizationById(organizationId)
  const { data: strategiesData, isLoading: organizationStrategiesLoading } = useOrganizationStrategies(
    organizationId,
    organization?.organizationType,
  )
  const { data: currentMarketSource, isLoading: currentMarketSourceLoading } =
    useCurrentAndAvailableSource(organizationId)
  const { mutateAsync: updateOrganizationStrategies, isLoading: isUpdateOrganizationStrategiesLoading } =
    useUpdateOrganizationStrategies()
  const { mutateAsync: updateCurrentSource, isLoading: isUpdateCurrentSourceLoading } =
    useUpdateCurrentSource()
  const { mutate: createUrgentContacts } = useCreateUrgentContacts()

  const methods = useForm<IOrganizationState>({
    mode: "all",
    defaultValues: initialOrganizationState,
  })

  const {
    setValue,
    getValues,
    reset,
    handleSubmit,
    formState: { dirtyFields },
  } = methods

  const isLoading =
    organizationStrategiesLoading ||
    organizationCarriersLoading ||
    organizationLoading ||
    isUpdateOrganizationStrategiesLoading ||
    currentMarketSourceLoading ||
    isUpdateCurrentSourceLoading

  useEffect(() => {
    if (!organization && !strategiesData && !isLoading) {
      navigate(`${MANAGEMENT}`)
    }
  }, [navigate, organization, strategiesData, isLoading])

  useEffect(() => {
    if (strategiesData && organization && currentMarketSource) {
      setValue("id", organization.id || "", { shouldDirty: true })
      setValue("parentId", organization.parentId || "", { shouldDirty: true })
      setValue("waterfallStrategy", strategiesData.waterfallStrategy, { shouldDirty: true })
      setValue("waitAllStrategy", strategiesData.waitAllStrategy, { shouldDirty: true })
      setValue("firstComeStrategy", strategiesData.firstComeStrategy, { shouldDirty: true })
      setValue("criticalStrategy", strategiesData.criticalStrategy, { shouldDirty: true })
      setValue("urgentDaysBeforePickup", strategiesData.urgentDaysBeforePickup, { shouldDirty: true })
      setValue("urgentHoursBeforePickup", strategiesData.urgentHoursBeforePickup, { shouldDirty: true })

      setValue("currentMarketSource", currentMarketSource?.currentMarketRateSource?.name || "", {
        shouldDirty: true,
      })

      setTimeout(() => {
        reset(getValues())
      }, 0)
    }
  }, [setValue, strategiesData, organization, currentMarketSource, reset, getValues])

  useEffect(() => {
    if (carriersData) {
      setValue("carriers", carriersData?.content, { shouldDirty: true })

      setTimeout(() => {
        reset(getValues())
      }, 0)
    }
  }, [setValue, carriersData, reset, getValues])

  const onSubmitHandler: SubmitHandler<IOrganizationState> = async () => {
    if (
      dirtyFields.waterfallStrategy ||
      dirtyFields.waitAllStrategy ||
      dirtyFields.firstComeStrategy ||
      dirtyFields.criticalStrategy ||
      dirtyFields.urgentDaysBeforePickup ||
      dirtyFields.urgentHoursBeforePickup ||
      dirtyFields.newEmails
    ) {
      await updateOrganizationStrategies({
        orgId: organizationId,
        organizationType: organization?.organizationType,
        waterfallStrategy: getValues("waterfallStrategy"),
        waitAllStrategy: getValues("waitAllStrategy"),
        firstComeStrategy: getValues("firstComeStrategy"),
        criticalStrategy: getValues("criticalStrategy"),
        urgentDaysBeforePickup: getValues("urgentDaysBeforePickup"),
        urgentHoursBeforePickup: getValues("urgentHoursBeforePickup"),
      })

      createUrgentContacts({ organizationId, requestBody: getValues().newEmails })
      setValue("newEmails", [])

      showToast({ type: "success", text: "Saved successfully" })
    }

    if (dirtyFields.currentMarketSource) {
      await updateCurrentSource({
        orgId: organizationId,
        currentSource: { name: getValues("currentMarketSource") },
      })
      showToast({ type: "success", text: "Saved successfully" })
    }
    navigate(`${MANAGEMENT}/organization/${organizationId}`)
  }

  return (
    <>
      {isLoading || !organization ? (
        <>
          <Redacted height="$24" width="300px" text animated />
          <Spacer size={20} />
        </>
      ) : (
        <Breadcrumbs
          additionalRoutes={[
            {
              url: `${MANAGEMENT}/organization/${organization.id}`,
              label: organization.name,
            },
          ]}
          routeParam="Edit strategies"
        />
      )}
      <Title as="h1" scale={2} color="system-black">
        Edit execution strategies
      </Title>
      <Spacer size={48} />
      <Box
        css={{
          backgroundColor: "$system-white",
          padding: "$40 $56",
          borderRadius: "$8",
          marginBottom: "$96",
        }}
      >
        {isLoading ? (
          <ExecutionStrategyPreloader />
        ) : (
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmitHandler)} noValidate autoComplete="off">
              <ExecutionStrategy />
              <EditStrategiesButton isLoading={isLoading} />
            </form>
          </FormProvider>
        )}
      </Box>
    </>
  )
}
