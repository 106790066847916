import { ComponentProps } from "@/stitches/types"

import { Title } from "@/shared/components"

import { SAccordionHeader } from "./AccordionHeader.styles"

interface IAccordionHeaderProps extends ComponentProps<typeof SAccordionHeader> {}

export const AccordionHeader = ({ children, ...props }: IAccordionHeaderProps) => {
  return (
    <SAccordionHeader data-ui="accordion-header" data-testid="accordion-header" {...props}>
      <Title as="h3" scale={{ "@initial": 4, "@md": 6 }}>
        {children}
      </Title>
    </SAccordionHeader>
  )
}
