import { useMutation } from "@tanstack/react-query"
import { isAxiosError } from "axios"

import { readDatKmasFileFn } from "@/api/ordersApi"
import { readCarriersCoverageFileFn, readConvoyRatesForTopLanesFileFn } from "@/api/carriersApi"
import { showToast } from "@/shared/utils"
import { FileType } from "@/shared/types"

export const useReadFile = (type: FileType) => {
  return useMutation(
    (file: File) => {
      switch (type) {
        case FileType.DAT_KMAS:
          return readDatKmasFileFn(file)
        case FileType.CARRIERS_COVERAGE:
          return readCarriersCoverageFileFn(file)
        case FileType.CONVOY_RATES_FOR_LANES:
          return readConvoyRatesForTopLanesFileFn(file)
      }
    },
    {
      onSuccess: () => {
        showToast({
          type: "success",
          text: "The data from the file has been successfully imported",
          autoClose: false,
        })
      },
      onError: (error) => {
        if (isAxiosError(error)) {
          showToast({
            type: "error",
            text: error.response?.data || "Operation failed. Please try again",
            autoClose: false,
          })
        }
      },
    },
  )
}
