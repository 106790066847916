import { memo } from "react"

import { CSS } from "@/stitches/config"
import { Button, Copy } from "@/shared/components"
import { IconPlus } from "@/shared/icons"

export const AddItemButton = memo(
  ({
    text,
    onClick,
    disabled,
    size,
    css,
  }: {
    text: string
    onClick: () => void
    disabled?: boolean
    size?: "small" | "medium" | "large"
    css?: CSS
  }) => (
    <Button
      action="secondary"
      type="button"
      size={size ? size : "small"}
      ariaLabel={`${text} button`}
      icon={<IconPlus />}
      onClick={onClick}
      disabled={disabled}
      css={css}
    >
      <Copy as="span" fontWeight="bold" css={{ whiteSpace: "nowrap" }}>
        {text}
      </Copy>
    </Button>
  ),
)

AddItemButton.displayName = "AddItemButton"
