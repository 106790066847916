import { useOrderStatistics } from "@/dashboard/hooks"
import { useGlobalStore } from "@/store"

import { Copy, Flex, Spacer, Spinner, Title } from "@/shared/components"
import { IconBox, IconBoxCreated, IconBoxDelay, IconBoxService, IconBoxSuccess } from "@/shared/icons"
import { StatisticsContainer } from "@/dashboard/components"

export const DashboardContainer = () => {
  const organization = useGlobalStore((state) => state.organization)
  const { data, fetchStatus } = useOrderStatistics(organization.id)

  return (
    <>
      <Title as="h1" scale={2} color="system-black">
        Dashboard
      </Title>
      <Spacer size={32} />
      {fetchStatus === "fetching" && !data ? (
        <Spinner />
      ) : data ? (
        <Flex wrap css={{ gap: "$24" }}>
          <StatisticsContainer
            name="Orders. Total number"
            value={data.totalNumber.toString()}
            icon={<IconBox />}
          />
          <StatisticsContainer
            name="Orders. Total not completed"
            value={data.totalNotCompleted.toString()}
            icon={<IconBoxDelay />}
          />
          <StatisticsContainer
            name="Orders. Total processed successfully"
            value={data.totalProcessedSuccessfully.toString()}
            icon={<IconBoxSuccess />}
          />
          <StatisticsContainer
            name="Orders. Today created (Apollo V1 and V2)"
            value={data.todayCreated.toString()}
            icon={<IconBoxCreated />}
          />
          <StatisticsContainer
            name="Orders. Today in services (Apollo V1)"
            value={
              <Copy css={{ fontWeight: "inherit" }}>
                <Copy css={{ fontWeight: "inherit" }}>OMS: {data.todayInServices.OMS}</Copy>
                <Copy css={{ fontWeight: "inherit" }}>CAS: {data.todayInServices.CAS}</Copy>
              </Copy>
            }
            icon={<IconBoxService />}
          />
        </Flex>
      ) : (
        <Copy color="theme-n6-n5">There is no data yet</Copy>
      )}
    </>
  )
}
