export const equipmentToText = (equipmentType: string) => {
  switch (equipmentType) {
    case "VAN":
    case "DRY_VAN":
      return "Van"
    case "FLATBED":
      return "Flatbed"
    case "TRUCK":
      return "Truck"
    case "REEFER":
      return "Reefer"
    default:
      return equipmentType
  }
}
