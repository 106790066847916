import { styled } from "@/stitches/config"
import { blue } from "@/stitches/theme"

export const SButtonIcon = styled("button", {
  reset: true,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  color: "$gray-900",
  cursor: "pointer",
  borderRadius: "$8",

  hover: {
    backgroundColor: "$gray-100",
    color: "$gray-900",
  },

  keyboardFocus: {
    boxShadow: `0 0 0 2px transparent, 0 0 0 2px ${blue["500"]}`,
  },

  active: {
    backgroundColor: "$gray-200",
  },

  disabled: {
    color: "$theme-n4-n7",
    pointerEvents: "none",
    cursor: "initial",
  },

  variants: {
    size: {
      small: {
        height: "$32",
        width: "$32",
        padding: "$6",
      },
      medium: {
        height: "$40",
        width: "$40",
        padding: "$8",
      },
    },

    inputIcon: {
      true: {},
    },
  },

  defaultVariants: {
    size: "small",
  },
})
