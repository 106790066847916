import { Controller, FieldValues, Path, PathValue } from "react-hook-form"

import { FormType, IZipCodeData, IFormInputControllerProps } from "@/shared/types"
import { zipCodeRule } from "@/shared/rules"

import { FormInput } from "@/shared/components"

export const ZipCodeController = <FieldsType extends FieldValues>({
  name,
  label,
  description,
  control,
  error,
  labelProps,
  disabled,
  borderless,
  placeholder,
  extendedData,
  trigger,
  register,
  onChangeHandler,
}: IFormInputControllerProps<FieldsType>) => {
  const zipCodeData: IZipCodeData = {
    zipCode: extendedData?.zipCodeData ? extendedData.zipCodeData.zipCode || "" : "",
    country: extendedData?.zipCodeData ? extendedData.zipCodeData.country || "" : "",
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <FormInput
            {...(register &&
              register(field.name, {
                required: {
                  value: !!labelProps?.required,
                  message: "Required field",
                },
                ...zipCodeRule(zipCodeData),
              }))}
            {...field}
            onChange={(event) => {
              let formattedValue = event?.target?.value.replaceAll(" ", "").toUpperCase()
              if (zipCodeData.country === "Canada" && event?.target?.value.replaceAll(" ", "").length > 3) {
                formattedValue = formattedValue.replace(/^(.{3})(.*)$/, "$1 $2")
              }

              if (formattedValue !== zipCodeData.zipCode) {
                field.onChange(formattedValue as PathValue<FieldsType, Path<FieldsType>>)
                onChangeHandler && onChangeHandler()
              }
            }}
            disabled={disabled}
            id={name}
            type={FormType.Text}
            label={label ? label : "Zip code / Postal code"}
            labelProps={{ ...labelProps, hidden: true }}
            description={description || borderless ? description : "Zip code / Postal code"}
            placeholder={placeholder}
            borderless={borderless}
            autoComplete="new-password"
            error={error}
            onBlur={(event) => {
              field.onBlur()
              field.onChange(
                (event?.target?.value !== "" ? event?.target?.value.trim() : "") as PathValue<
                  FieldsType,
                  Path<FieldsType>
                >,
              )
              trigger && trigger(name)
            }}
          />
        )
      }}
    />
  )
}
