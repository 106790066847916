import { useState } from "react"
import { useFormContext } from "react-hook-form"

import { IOrganizationState } from "@/shared/types"
import { numericPatternWithDot, laneValidationRule } from "@/shared/rules"
import { formatNumericInput } from "@/shared/utils"

import {
  Box,
  ButtonIcon,
  Copy,
  ErrorLineWithMessage,
  Flex,
  Grid,
  GridItem,
  IconTooltip,
  Redacted,
  TextFieldController,
} from "@/shared/components"
import { IconBin, IconWarning } from "@/shared/icons"
import { ContactsSelectField } from "./ContactsSelectField"

const tooltipBox = (
  <>
    <Copy scale={8} fontWeight="bold" css={{ marginBottom: "10px" }}>
      Expected formats:
    </Copy>
    <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
      <li>
        <Copy scale={10} truncate>
          City and state should be divided by comma: <i>Dallas, TX</i>;
        </Copy>
      </li>
      <li>
        <Copy scale={10} truncate>
          State should be a 2-letter abbreviation (uppercase): <i>TX</i>;
        </Copy>
      </li>
      <li>
        <Copy scale={10} truncate>
          KMA should have underscore and uppercase letters: <i>AZ_FLA</i>;
        </Copy>
      </li>
      <li>
        <Copy scale={10} truncate>
          Zip code should have 3 or 5 numbers: <i>75201</i>;
        </Copy>
      </li>
      <li>
        <Copy scale={10} truncate>
          For any address please enter &quot;<i>Any</i>&quot; (case insensitive).
        </Copy>
      </li>
    </ul>
  </>
)

interface ILanesTableRowProps {
  id?: string
  index?: number
  from?: string
  to?: string
  price?: string
  contacts?: string
  isTableHead?: boolean
  isPlaceholder?: boolean
  isViewMode?: boolean
  isShowTooltip?: boolean
}

export const LanesTableRow = ({
  id,
  index = 0,
  from,
  to,
  price,
  contacts,
  isTableHead,
  isPlaceholder,
  isViewMode,
  isShowTooltip,
}: ILanesTableRowProps) => {
  const {
    control,
    register,
    trigger,
    formState: { errors },
  } = useFormContext<IOrganizationState>()

  const [formattedPrice, setFormattedPrice] = useState(price)

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    const formattedValue = formatNumericInput(inputValue, ".", 2, 6)

    setFormattedPrice(formattedValue)
  }

  return (
    <Grid
      columns="2fr 2fr 1fr 1.3fr"
      align="center"
      css={{
        minHeight: isTableHead ? "$48" : "$64",
        color: "$theme-b-n3",
        borderBottom: isTableHead ? "1px solid $theme-n3-n7" : "none",
      }}
    >
      <GridItem css={{ paddingX: "$24" }}>
        {isPlaceholder && !isTableHead ? (
          <Redacted height="$24" width="100px" text animated />
        ) : isTableHead || isViewMode ? (
          <Flex align="center" css={{ gap: "$16" }}>
            <Copy
              scale={isTableHead ? 8 : 10}
              fontWeight={isTableHead ? "bold" : "regular"}
              uppercase={isTableHead}
            >
              {from || "-"}
            </Copy>
            {isShowTooltip && isTableHead && (
              <IconTooltip
                tooltip={tooltipBox}
                ariaLabel="From tooltip"
                withTitle={false}
                contentWidth="max-content"
                trigger={["hover", "focus"]}
                placement="top"
                delayShow={150}
                delayHide={150}
                contentCss={{
                  minWidth: "450px",
                  padding: "$12",
                  borderRadius: "$8",
                }}
                triggerCss={{
                  height: "$20",
                }}
                icon={<IconWarning fixedSize width={20} height={20} />}
              />
            )}
          </Flex>
        ) : (
          <Box css={{ position: "relative" }}>
            <TextFieldController<IOrganizationState>
              name={`newCarrier.lanes.${index}.from`}
              control={control}
              register={register}
              trigger={trigger}
              labelProps={{ required: true }}
              description=""
              rules={laneValidationRule(true)}
              placeholder="City and state / state / zip / KMA / Any"
              borderless
            />
            <ErrorLineWithMessage
              errorId={`newCarrier.lanes.${index}.from`}
              errorMessage={errors.newCarrier?.lanes?.[index]?.from?.message}
            />
          </Box>
        )}
      </GridItem>
      <GridItem css={{ paddingX: "$24" }}>
        {isPlaceholder && !isTableHead ? (
          <Redacted height="$24" width="140px" text animated />
        ) : isTableHead || isViewMode ? (
          <Flex align="center" css={{ gap: "$16" }}>
            <Copy
              scale={isTableHead ? 8 : 10}
              fontWeight={isTableHead ? "bold" : "regular"}
              uppercase={isTableHead}
            >
              {to || "-"}
            </Copy>
            {isShowTooltip && isTableHead && (
              <IconTooltip
                tooltip={tooltipBox}
                ariaLabel="From tooltip"
                withTitle={false}
                contentWidth="max-content"
                trigger={["hover", "focus"]}
                placement="top"
                delayShow={150}
                delayHide={150}
                contentCss={{
                  minWidth: "450px",
                  padding: "$12",
                  borderRadius: "$8",
                }}
                triggerCss={{
                  height: "$20",
                }}
                icon={<IconWarning fixedSize width={20} height={20} />}
              />
            )}
          </Flex>
        ) : (
          <Box css={{ position: "relative" }}>
            <TextFieldController<IOrganizationState>
              name={`newCarrier.lanes.${index}.to`}
              control={control}
              register={register}
              trigger={trigger}
              labelProps={{ required: true }}
              description=""
              rules={laneValidationRule(true)}
              placeholder="City and state / state / zip / KMA / Any"
              borderless
            />
            <ErrorLineWithMessage
              errorId={`newCarrier.lanes.${index}.to`}
              errorMessage={errors.newCarrier?.lanes?.[index]?.to?.message}
            />
          </Box>
        )}
      </GridItem>
      <GridItem css={{ paddingX: "$24" }}>
        {isPlaceholder && !isTableHead ? (
          <Redacted height="$24" width="140px" text animated />
        ) : isTableHead || isViewMode ? (
          <Copy
            scale={isTableHead ? 8 : 10}
            fontWeight={isTableHead ? "bold" : "regular"}
            uppercase={isTableHead}
          >
            {price || "-"}
          </Copy>
        ) : (
          <Box css={{ position: "relative" }}>
            <TextFieldController<IOrganizationState>
              name={`newCarrier.lanes.${index}.price`}
              control={control}
              register={register}
              trigger={trigger}
              controlledValue={formattedPrice}
              controlledChange={handlePriceChange}
              placeholder="E.g. 500.01"
              rules={numericPatternWithDot}
              borderless
            />
            <ErrorLineWithMessage
              errorId={`newCarrier.lanes.${index}.price`}
              errorMessage={errors.newCarrier?.lanes?.[index]?.price?.message}
            />
          </Box>
        )}
      </GridItem>
      <GridItem css={{ paddingX: "$24" }}>
        {isPlaceholder && !isTableHead ? (
          <Redacted height="$24" width="140px" text animated />
        ) : isTableHead || isViewMode ? (
          <Copy
            scale={isTableHead ? 8 : 10}
            fontWeight={isTableHead ? "bold" : "regular"}
            uppercase={isTableHead}
          >
            {contacts || "-"}
          </Copy>
        ) : (
          <Flex align="center" justify="between" css={{ gap: "$16" }}>
            <Box css={{ width: "100%", position: "relative" }}>
              <ContactsSelectField index={index} />
            </Box>
            <RemoveButton id={id || ""} />
          </Flex>
        )}
      </GridItem>
    </Grid>
  )
}

const RemoveButton = ({ id }: { id: string }) => {
  const { watch, setValue, clearErrors } = useFormContext<IOrganizationState>()
  const { newCarrier } = watch()
  const { lanes } = newCarrier

  const onDeleteClick = (e: React.SyntheticEvent, id: string) => {
    e.stopPropagation()

    clearErrors("newCarrier.lanes")
    setValue(
      "newCarrier.lanes",
      lanes.filter((i) => i.id !== id),
      { shouldDirty: true, shouldValidate: true },
    )
  }

  return (
    <ButtonIcon
      type="button"
      ariaLabel={`Remove lane ${id}`}
      icon={<IconBin />}
      onClick={(e) => onDeleteClick(e, id)}
      inputIcon
    />
  )
}
