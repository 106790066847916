import { Controller, useFormContext } from "react-hook-form"

import { StatusType, IOrganizationState, FeatureFlags } from "@/shared/types"
import { lengthRule, nonNumericPattern, numericPattern } from "@/shared/rules"
import { uberScac } from "@/constants"
import { getFeatureFlags } from "@/shared/utils"

import {
  Box,
  CarrierTypeController,
  Copy,
  CountryController,
  Flex,
  FormRadioGroup,
  FormRadioInput,
  Grid,
  GridItem,
  PhoneController,
  Spacer,
  Stack,
  StateController,
  TextFieldController,
  ZipCodeController,
  CommunicationTypeController,
} from "@/shared/components"

export const CompanyInfo = ({ isHighway }: { isHighway?: boolean }) => {
  const [isIdInsteadScac] = getFeatureFlags([FeatureFlags.FEATURE_FLAG_USE_CARRIER_ID_INSTEAD_SCAC])

  const {
    watch,
    control,
    register,
    formState: { errors },
    trigger,
  } = useFormContext<IOrganizationState>()
  const { newCarrier, carriers } = watch()
  const { zipCode, country } = newCarrier
  const zipCodeData = { zipCode, country }

  return (
    <Box css={{ height: 420 }}>
      <Stack space={32}>
        <Grid columns="1fr 1fr 1fr 1fr" columnGap={16}>
          <GridItem>
            <TextFieldController<IOrganizationState>
              name="newCarrier.name"
              control={control}
              register={register}
              trigger={trigger}
              labelProps={{ required: true }}
              description="Carrier name"
              disabled={isHighway}
              rules={lengthRule("Name", 100)}
              error={errors.newCarrier?.name?.message}
            />
          </GridItem>
          <GridItem>
            <TextFieldController<IOrganizationState>
              name="newCarrier.legalName"
              control={control}
              register={register}
              trigger={trigger}
              description="Legal name"
              disabled={isHighway}
              rules={lengthRule("Name", 100, 0)}
              error={errors.newCarrier?.legalName?.message}
            />
          </GridItem>
          <GridItem>
            <CommunicationTypeController<IOrganizationState>
              name="newCarrier.communicationType"
              control={control}
              register={register}
              trigger={trigger}
              labelProps={{ required: true }}
              error={errors.newCarrier?.communicationType?.message}
            />
          </GridItem>
          <GridItem>
            <TextFieldController<IOrganizationState>
              name="newCarrier.address1"
              control={control}
              register={register}
              trigger={trigger}
              description="Address line 1"
              disabled={isHighway}
              rules={lengthRule("Address", 100, 0)}
              error={errors.newCarrier?.address1?.message}
            />
          </GridItem>
        </Grid>
        <Grid columns="1fr 1fr 1fr 1fr" columnGap={16}>
          <GridItem>
            <TextFieldController<IOrganizationState>
              name="newCarrier.address2"
              control={control}
              register={register}
              trigger={trigger}
              description="Address line 2"
              disabled={isHighway}
              rules={lengthRule("Address", 40, 0)}
              error={errors.newCarrier?.address2?.message}
            />
          </GridItem>
          <GridItem>
            <TextFieldController<IOrganizationState>
              name="newCarrier.city"
              control={control}
              register={register}
              trigger={trigger}
              description="City"
              disabled={isHighway}
              rules={{ ...nonNumericPattern, ...lengthRule("City", 40, 0) }}
              error={errors.newCarrier?.city?.message}
            />
          </GridItem>
          <GridItem>
            <StateController<IOrganizationState>
              name="newCarrier.state"
              control={control}
              register={register}
              trigger={trigger}
              label="State"
              description="State"
              disabled={isHighway}
              error={errors.newCarrier?.state?.message}
            />
          </GridItem>
          <Grid columns="1fr 1fr" columnGap={16}>
            <GridItem>
              <ZipCodeController<IOrganizationState>
                name="newCarrier.zipCode"
                control={control}
                register={register}
                trigger={trigger}
                label="Zip code"
                description="Zip code"
                disabled={isHighway}
                error={errors.newCarrier?.zipCode?.message}
                extendedData={{ zipCodeData }}
              />
            </GridItem>
            <GridItem>
              <CountryController<IOrganizationState>
                name="newCarrier.country"
                control={control}
                register={register}
                trigger={trigger}
                disabled={isHighway}
                error={errors.newCarrier?.country?.message}
              />
            </GridItem>
          </Grid>
        </Grid>
        <Grid columns="1fr 1fr 1fr 1fr" columnGap={16}>
          <GridItem>
            <PhoneController<IOrganizationState>
              name="newCarrier.phone"
              control={control}
              register={register}
              trigger={trigger}
              error={errors.newCarrier?.phone?.message}
            />
          </GridItem>
          <GridItem>
            <CarrierTypeController<IOrganizationState>
              name="newCarrier.type"
              control={control}
              register={register}
              trigger={trigger}
            />
          </GridItem>
          <GridItem>
            <Grid columns="1fr 1fr" columnGap={16}>
              <GridItem>
                <TextFieldController<IOrganizationState>
                  name="newCarrier.code"
                  control={control}
                  register={register}
                  trigger={trigger}
                  labelProps={{ required: true }}
                  description="TMS code"
                  disabled={isHighway || (isIdInsteadScac && !!newCarrier.id && !isNaN(+newCarrier.id))}
                  rules={lengthRule("Code", 20, 0)}
                  error={errors.newCarrier?.code?.message}
                />
              </GridItem>
              <GridItem>
                <TextFieldController<IOrganizationState>
                  name="newCarrier.scac"
                  control={control}
                  register={register}
                  trigger={trigger}
                  labelProps={{ required: !isIdInsteadScac }}
                  description="SCAC"
                  rules={{
                    pattern: {
                      value: /^[a-zA-Z]*$/,
                      message: "Only alphabetic characters allowed",
                    },
                    validate: {
                      alphabetic: (v: string) =>
                        !v || /^([a-zA-Z]{4})$/.test(v) || "Not match the format: XXXX",
                      isUnique: (v: string) =>
                        isIdInsteadScac ||
                        !carriers
                          .filter((i) => i.id !== newCarrier.id)
                          .find((i) => i.scac.toUpperCase() === v.toUpperCase()) ||
                        "SCAC should be unique",
                    },
                  }}
                  disabled={isIdInsteadScac ? true : isHighway || (!!newCarrier.id && !isNaN(+newCarrier.id))}
                  error={errors.newCarrier?.scac?.message}
                />
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem>
            <Grid columns="1fr 1fr" columnGap={16}>
              <GridItem>
                <TextFieldController<IOrganizationState>
                  name="newCarrier.mc"
                  control={control}
                  register={register}
                  trigger={trigger}
                  description="MC#"
                  disabled={isHighway}
                  rules={{ ...lengthRule("Value", 15, 0), ...numericPattern }}
                  error={errors.newCarrier?.mc?.message}
                />
              </GridItem>
              <GridItem>
                <TextFieldController<IOrganizationState>
                  name="newCarrier.usdot"
                  control={control}
                  register={register}
                  trigger={trigger}
                  description="USDOT#"
                  disabled={isHighway}
                  rules={{ ...lengthRule("Value", 15, 0), ...numericPattern }}
                  error={errors.newCarrier?.usdot?.message}
                />
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
      </Stack>
      <Spacer size={40} />
      <Flex>
        <Copy scale={8} color="theme-b-n3" fontWeight="bold">
          Select a carrier status*
        </Copy>
        <Spacer size={32} horizontal />
        <Controller
          name="newCarrier.enabled"
          control={control}
          render={({ field }) => {
            return (
              <FormRadioGroup
                {...register(field.name, {
                  shouldUnregister: true,
                })}
                {...field}
                id={field.name}
                horizontal
                disabled={
                  !!newCarrier.id && !isNaN(+newCarrier.id) && newCarrier.scac.toUpperCase() === uberScac
                }
              >
                {[
                  {
                    label: "Active",
                    value: StatusType.Active,
                  },
                  {
                    label: "Inactive",
                    value: StatusType.Inactive,
                  },
                ].map(({ label, value }) => (
                  <FormRadioInput key={value} label={label} value={value} />
                ))}
              </FormRadioGroup>
            )
          }}
        />
      </Flex>
    </Box>
  )
}
