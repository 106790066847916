import { useMemo } from "react"
import { useFormContext } from "react-hook-form"

import { StrategyType, IOrganizationState } from "@/shared/types"

import { Grid, Stack } from "@/shared/components"

import { StrategyColumn } from "./components"

export const ExecutionStrategyView = ({
  setStrategyType,
}: {
  setStrategyType: (type: StrategyType) => void
}) => {
  const { watch } = useFormContext<IOrganizationState>()
  const { waterfallStrategy, waitAllStrategy, firstComeStrategy, criticalStrategy } = watch()

  const enabledStrategies = useMemo(() => {
    return [waterfallStrategy, waitAllStrategy, firstComeStrategy, criticalStrategy].filter(
      (strategy) => strategy.enabled,
    )
  }, [waterfallStrategy, waitAllStrategy, firstComeStrategy, criticalStrategy])

  const columns = enabledStrategies.length === 4 ? "1fr 1fr 1fr 1fr" : "1fr 1fr 1fr"

  return (
    <Stack space={32}>
      <Grid columns={columns}>
        {enabledStrategies.map(
          (strategy) =>
            strategy.enabled && (
              <StrategyColumn
                key={strategy.type}
                setStrategyType={setStrategyType}
                strategyType={strategy.type}
                strategy={strategy}
              />
            ),
        )}
      </Grid>
    </Stack>
  )
}
