import { useQuery } from "@tanstack/react-query"
import { isAxiosError } from "axios"

import { getOrderStatisticsFn } from "@/api/ordersApi"
import { showToast } from "@/shared/utils"

export const useOrderStatistics = (id: string) => {
  const isEnabled = !!id

  return useQuery(["orderStatistics"], () => getOrderStatisticsFn(id), {
    staleTime: 0,
    enabled: isEnabled,
    onError: (error) => {
      if (isAxiosError(error)) {
        showToast({
          type: "error",
          text: error.response?.data.errorMessage || error.message,
        })
      }
    },
  })
}
