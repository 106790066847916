import { IDashboardData, IOrderDetailsResponse, IQuotesResponse } from "@/shared/types"

export const transformDashboardResponse = (
  quotes: IQuotesResponse[],
  orders: IOrderDetailsResponse[],
): IDashboardData[] => {
  const transformedData: IDashboardData[] = []

  orders.forEach((order) => {
    const matchingQuote = quotes.find((quote) => quote.orderId === order.orderId)
    const details = [
      order.commodity,
      order.weight ? `${order.weight} ${order.weightUnit}` : null,
      order.equipment,
      order.distance ? `${order.distance} ${order.distanceUnitType}` : null,
      order.note,
    ]
      .filter(Boolean)
      .join(", ")

    if (matchingQuote) {
      transformedData.push({
        orderId: order.orderId,
        originalOrderId: order.originalOrderId,
        from: `${order.pickupCity.charAt(0).toUpperCase() + order.pickupCity.slice(1).toLowerCase()}, ${
          order.pickupState
        }`,
        to: `${order.dropOffCity.charAt(0).toUpperCase() + order.dropOffCity.slice(1).toLowerCase()}, ${
          order.dropOffState
        }`,
        dropOffStartAt: order.dropOffStartAt,
        pickupStartAt: order.pickupStartAt,
        details,
        rate: `${matchingQuote.rateInCents ? matchingQuote.rateInCents / 100 : ""}`,
        status: matchingQuote.status,
        updatedAt: matchingQuote.updatedAt,
        hasOriginalRate: !!matchingQuote.rateInCents,
      })
    }
  })

  return transformedData
}
