import { useQuery } from "@tanstack/react-query"

import { getOrdersByPartOfId } from "@/api/ordersApi"

export const useOrdersByPartOfId = (id: string, partOfOrderId: string) => {
  const isEnabled = !!id && !!partOfOrderId && partOfOrderId.length > 3

  return useQuery(["ordersByPartOfId", partOfOrderId], () => getOrdersByPartOfId(id, partOfOrderId), {
    staleTime: 0,
    enabled: isEnabled,
  })
}
