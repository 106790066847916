export interface RouteParams {
  orderId: string
  organizationId: string
  userId: string
}

export enum ThemeType {
  Light = "light",
  Dark = "dark",
}

export enum ModalType {
  DeleteOrganizationModal = "deleteOrganization",
  DeleteUserModal = "deleteUserModal",
  DeleteUrgentContactModal = "deleteUrgentContactModal",
  CreateCarrierModal = "createCarrierModal",
  ViewCarrierModal = "viewCarrierModal",
  DeleteCarrierModal = "deleteCarrierModal",
  SlaTypeModal = "slaTypeModal",
  GlobalProtectModal = "globalProtectModal",
  ExecutionStrategyModal = "executionStrategyModal",
  ExportOrdersModal = "exportOrdersModal",
  ViewUrgentContacts = "viewUrgentContacts",
  CarrierOrderPrice = "carrierOrderPrice",
  CarrierOrderReject = "carrierOrderReject",
  ForceTenderModal = "forceTenderModal",
  ForceTenderExtendedModal = "forceTenderExtendedModal",
}

export enum ReferralKey {
  ReferralTab = "referralTab",
  ReferralOrgId = "referralOrgId",
  ReferralPage = "referralPage",
}

export enum StatusType {
  Active = "active",
  Inactive = "inactive",
  Passed = "PASSED",
  Failed = "FAILED",
}

interface IPaginationSortResponse {
  empty: boolean
  sorted: boolean
  unsorted: boolean
}

interface IPageableResponse {
  offset: number
  pageNumber: number
  pageSize: number
  paged: boolean
  sort: IPaginationSortResponse
  unpaged: boolean
}

export interface IPagination {
  empty?: boolean
  first: boolean
  hasContent?: boolean
  last: boolean
  number: number
  numberOfElements: number
  pageable: IPageableResponse
  size: number
  sort: IPaginationSortResponse
  totalElements: number
  totalPages: number
}

export enum FileType {
  DAT_KMAS,
  CARRIERS_COVERAGE,
  CONVOY_RATES_FOR_LANES,
}

export interface ICurrentSource {
  name: string | null
}

export interface ICurrentAndAvailableSource {
  availableMarketSources: string[]
  currentMarketRateSource: ICurrentSource
}

export enum FeatureFlags {
  FEATURE_FLAG_USE_CARRIER_ID_INSTEAD_SCAC = "FEATURE_FLAG_USE_CARRIER_ID_INSTEAD_SCAC",
  FEATURE_FLAG_FORCE_TENDERS = "FEATURE_FLAG_FORCE_TENDERS",
}

export enum OrgProperties {
  FEATURE_FLAG_MANUAL_CARRIER_ASSIGN_ENABLED = "FEATURE_FLAG_MANUAL_CARRIER_ASSIGN_ENABLED",
}

export type IFeatureFlag = {
  key: FeatureFlags
  value: boolean | null
}

export type IOrgProperty = {
  key: OrgProperties
  value: string | null
}
