import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { FormProvider, useForm } from "react-hook-form"

import { useGlobalStore } from "@/store"
import { useModalActions } from "@/shared/hooks"
import { ModalType, RouteParams, IOrganizationState, initialOrganizationState } from "@/shared/types"
import { MANAGEMENT, mainOrganizationId } from "@/constants"
import { useOrganizationById } from "@/management/hooks"
import { dateTimeFormatInTimeZone, useIsCustomerAdmin, useIsReader } from "@/shared/utils"

import {
  Breadcrumbs,
  Flex,
  Title,
  Spacer,
  Copy,
  useGlobalSettings,
  ButtonIcon,
  Redacted,
} from "@/shared/components"
import { IconBin } from "@/shared/icons"
import { OrganizationViewTabs } from "@/management/components"
import { DeleteOrganizationModal, DeleteUserModal } from "@/modals"

export const OrganizationViewContainer = () => {
  const { organizationId } = useParams<keyof RouteParams>() as RouteParams
  const navigate = useNavigate()
  const { open } = useModalActions()
  const [organization] = useGlobalStore((state) => [state.organization])
  const isRoleReader = useIsReader()
  const isRoleCustomerAdmin = useIsCustomerAdmin()
  const isOrganizationUnavailable = isRoleCustomerAdmin && organization.id !== organizationId
  const { setOrganizationId } = useGlobalSettings("ActionDetailsButton")

  const methods = useForm<IOrganizationState>({
    // mode: "onChange",
    mode: "all",
    defaultValues: initialOrganizationState,
  })

  const { data, fetchStatus } = useOrganizationById(!isOrganizationUnavailable ? organizationId : "")
  const isLoading = fetchStatus === "fetching"

  const handleDeleteClick = () => {
    setOrganizationId(organizationId)
    open(ModalType.DeleteOrganizationModal)
  }

  useEffect(() => {
    if (isOrganizationUnavailable) {
      navigate(`${MANAGEMENT}/organization/${organization.id}`)
    }
  }, [isOrganizationUnavailable, navigate, organization.id])

  return (
    <>
      {!(isRoleCustomerAdmin || isRoleReader) ? (
        isLoading || !data ? (
          <>
            <Redacted height="$24" width="300px" text animated />
            <Spacer size={20} />
          </>
        ) : (
          <Breadcrumbs routeParam={data.name} />
        )
      ) : null}
      <Flex justify="between">
        <Flex css={{ gap: "$16" }}>
          {isLoading || !data ? (
            <Redacted height="$32" width="170px" text animated />
          ) : (
            <Title as="h1" scale={2} color="system-black">
              {data.name}
            </Title>
          )}
          {!(isRoleCustomerAdmin || isRoleReader) && data?.id !== mainOrganizationId ? (
            <Flex align={"center"}>
              <ButtonIcon
                type="button"
                ariaLabel="Delete organization"
                icon={<IconBin />}
                onClick={() => handleDeleteClick()}
                inputIcon
              />
            </Flex>
          ) : null}
        </Flex>
        {/* {!isRoleReader && (
          <Button
            action="primary"
            size="medium"
            icon={<IconPlus />}
            onClick={() =>
              navigate(`${MANAGEMENT}/create/user?${ReferralKey.ReferralOrgId}=${organizationId}`)
            }
          >
            Create user
          </Button>
        )} */}
      </Flex>
      <Spacer size={8} />
      {isLoading || !data ? (
        <Redacted height="$24" width="300px" text animated />
      ) : (
        <Flex css={{ gap: "$4" }}>
          <Copy color="theme-n6-n5">{dateTimeFormatInTimeZone({ dateTime: data.createdAt })}</Copy>
          {data.createdAt !== data.updatedAt ? (
            <Copy color="theme-n6-n5">
              {`(was modified ${dateTimeFormatInTimeZone({ dateTime: data.updatedAt })})`}
            </Copy>
          ) : null}
        </Flex>
      )}
      <Spacer size={40} />
      <FormProvider {...methods}>
        <form noValidate autoComplete="off">
          <OrganizationViewTabs />
        </form>
      </FormProvider>
      <DeleteUserModal />
      <DeleteOrganizationModal />
    </>
  )
}
