import { Controller, FieldValues, Path, PathValue } from "react-hook-form"

import { IFormInputControllerProps, CommunicationType } from "@/shared/types"
import { FormSelect } from "@/shared/components"
import { carrierCommunicationTypeToText } from "@/shared/utils"

const communicationTypes = [CommunicationType.EMAIL, CommunicationType.API, CommunicationType.EDI]

export const CommunicationTypeController = <FieldsType extends FieldValues>({
  name,
  control,
  error,
  disabled,
  labelProps,
  description,
  register,
  onChangeHandler,
}: IFormInputControllerProps<FieldsType>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <FormSelect
            {...(register &&
              register(field.name, {
                shouldUnregister: true,
                required: {
                  value: !!labelProps?.required,
                  message: "Required field",
                },
              }))}
            {...field}
            isDisplayValueDifferent
            value={{
              value: field.value,
              label: carrierCommunicationTypeToText(field.value),
            }}
            onValueChange={(value) => {
              if (value !== field.value) {
                onChangeHandler && onChangeHandler()
              }

              return field.onChange(value as PathValue<FieldsType, Path<FieldsType>>)
            }}
            id={name}
            label="Communication type"
            labelProps={{ ...labelProps, hidden: true }}
            description={description || "Communication type"}
            disabled={disabled}
            options={communicationTypes.map((i) => ({
              value: i,
              label: carrierCommunicationTypeToText(i),
            }))}
            error={error}
          />
        )
      }}
    />
  )
}
