import { memo } from "react"

import { Colors, ResponsiveProp } from "@/stitches/types"

import { Copy, Flex, TypographyScale } from "@/shared/components"

const getStatusColor = (status: boolean): Colors => (status ? "brand-green-primary" : "brand-red-primary")

export const CarrierOrdersStatus = memo(
  ({
    status,
    scale = 8,
    filled = false,
  }: {
    status: boolean
    scale?: TypographyScale | ResponsiveProp<TypographyScale>
    filled?: boolean
  }) => {
    const label = status ? "Accepted" : "Rejected"

    return filled ? (
      <Flex
        align="center"
        css={{
          height: "$32",
          borderRadius: "$8",
          padding: "$0 $16",
          backgroundColor: status ? "$brand-green-light" : "$brand-red-light",
          border: status ? "1px solid $brand-green-primary" : "1px solid $brand-red-primary",
        }}
      >
        <Copy scale={scale} color={getStatusColor(status)} truncate fontWeight="bold">
          {label}
        </Copy>
      </Flex>
    ) : (
      <Copy scale={scale} color={getStatusColor(status)} truncate fontWeight="bold">
        {label}
      </Copy>
    )
  },
)

CarrierOrdersStatus.displayName = "CarrierOrdersStatus"
