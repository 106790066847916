import { CarrierType } from "@/shared/types"

export const carrierTypeToText = (locationType: CarrierType | string) => {
  switch (locationType) {
    case CarrierType.Asset:
      return "Asset Based"
    case CarrierType.Broker:
      return "Broker"
    default:
      return "Not Selected"
  }
}
