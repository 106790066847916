import { Controller, FieldValues, Path, PathValue } from "react-hook-form"
import { IFormInputControllerProps } from "@/shared/types"
import { FormSelect } from "@/shared/components"

// export const statesCanada: string[] = [
//   "AB",
//   "BC",
//   "MB",
//   "NB",
//   "NL",
//   "NT",
//   "NS",
//   "NU",
//   "ON",
//   "PE",
//   "QC",
//   "SK",
//   "YT",
// ]

export const statesUS: string[] = [
  "AZ",
  "AL",
  "AK",
  "AR",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
  "AS",
  "GU",
  "MP",
  "PR",
  "VI",
  "UM",
]

export const StateController = <FieldsType extends FieldValues>({
  name,
  control,
  label,
  labelProps,
  description,
  borderless,
  placeholder,
  error,
  disabled,
  register,
  onChangeHandler,
}: IFormInputControllerProps<FieldsType>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <FormSelect
            {...(register &&
              register(field.name, {
                required: {
                  value: !!labelProps?.required,
                  message: "Required field",
                },
                shouldUnregister: true,
              }))}
            {...field}
            onValueChange={(value) => {
              if (value !== field.value) {
                onChangeHandler && onChangeHandler()
              }

              return field.onChange(value as PathValue<FieldsType, Path<FieldsType>>)
            }}
            id={name}
            isSearchable
            isDisplayValueDifferent
            placeholder={placeholder}
            value={
              !placeholder || field.value
                ? {
                    value: field.value,
                    label: field.value === "" ? "Not selected" : field.value,
                  }
                : null
            }
            options={[
              { value: "", label: "Not selected" },
              ...statesUS.map((i) => ({
                value: i,
                label: i,
              })),
            ]}
            label={label ? label : "State"}
            labelProps={{ ...labelProps, hidden: true }}
            description={description || borderless ? description : "State"}
            borderless={borderless}
            disabled={disabled}
            error={error}
            isMenuScrollbarColored
            menuListCss={{
              maxHeight: 528,
            }}
          />
        )
      }}
    />
  )
}
