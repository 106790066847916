import React from "react"

import { ComponentProps } from "@/stitches/types"

import { Title } from "@/shared/components"

import { SModalHeader } from "./Header.styles"

type ModalHeaderProps = ComponentProps<typeof SModalHeader> & {
  header?: string | React.ReactNode
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({ header, children, ...props }) => {
  return (
    <SModalHeader empty={!header || typeof header !== "string"} {...props}>
      {header ? (
        typeof header === "string" ? (
          <Title as="h3" scale={2}>
            {header}
          </Title>
        ) : (
          header
        )
      ) : null}

      {children}
    </SModalHeader>
  )
}
