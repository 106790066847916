import { Flex, Redacted, Stack } from "@/shared/components"

export const CarrierInfoCardPlaceholder = () => {
  return (
    <Flex
      direction="column"
      align="start"
      css={{ padding: "$20 $24", border: "1px solid $theme-n3-n7", borderRadius: "$8" }}
    >
      <Stack space={24}>
        <Flex align="center" css={{ gap: "$16" }}>
          <Redacted width={32} height={32} />
          <Redacted width="44%" />
        </Flex>

        <Redacted width="20%" />
        <Stack space={8}>
          <Redacted width="60%" />
          <Redacted width="60%" />
          <Redacted width="60%" />
        </Stack>
        <Redacted width="20%" />

        <Stack space={8}>
          <Redacted width="60%" />
          <Redacted width="60%" />
          <Redacted width="60%" />
        </Stack>
        <Redacted width="60%" />
      </Stack>
    </Flex>
  )
}
