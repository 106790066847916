import { useCallback } from "react"
import { useFormContext } from "react-hook-form"

import { ISlaItem, SlaField, SlaItemType, IOrganizationState } from "@/shared/types"
import { spaceAndEnterKeyDown } from "@/shared/utils"

import { Button, ButtonIcon, Copy, Tooltip } from "@/shared/components"
import { IconCross } from "@/shared/icons"
import { useOrganizationForm } from "@/management/components"

export const RemoveButton = ({
  type,
  name,
  path = [],
}: {
  type: SlaItemType
  name: SlaField
  path?: number[]
}) => {
  const { setSlaErrors } = useOrganizationForm("RemoveButton")
  const { watch, setValue } = useFormContext<IOrganizationState>()
  const { sla } = watch()

  // TODO: it will need to be refactored in the future
  const handleClick = useCallback(() => {
    setSlaErrors([])

    if (path.length === 0) {
      const newValue = sla.map((i) => {
        if (i.name !== name) {
          return i
        }

        return {
          ...i,
          content: null,
        }
      })
      setValue("sla", newValue, {
        shouldDirty: true,
      })
      return
    }

    if (path.length === 1) {
      const newValue = sla.map((i) => {
        if (i.name !== name) {
          return i
        }

        return {
          ...i,
          content: {
            ...(i.content as ISlaItem),
            nodes: i.content?.nodes?.filter((_, index) => index !== path[0]) || [],
          },
        }
      })
      setValue("sla", newValue, {
        shouldDirty: true,
      })
      return
    }

    if (path.length === 2) {
      const newValue = sla.map((i) => {
        if (i.name !== name) {
          return i
        }

        return {
          ...i,
          content: {
            ...(i.content as ISlaItem),
            nodes: i.content?.nodes?.map((n, index) => {
              if (index !== path[0]) {
                return n
              }

              return {
                ...n,
                nodes: n.nodes?.filter((_, index) => index !== path[1]) || [],
              }
            }) as ISlaItem[],
          },
        }
      })
      setValue("sla", newValue, {
        shouldDirty: true,
      })
      return
    }

    if (path.length === 3) {
      const newValue = sla.map((i) => {
        if (i.name !== name) {
          return i
        }

        return {
          ...i,
          content: {
            ...(i.content as ISlaItem),
            nodes: i.content?.nodes?.map((n, index) => {
              if (index !== path[0]) {
                return n
              }

              return {
                ...n,
                nodes: n.nodes?.map((k, index) => {
                  if (index !== path[1]) {
                    return k
                  }

                  return {
                    ...k,
                    nodes: k.nodes?.filter((_, index) => index !== path[2]) || [],
                  }
                }),
              }
            }) as ISlaItem[],
          },
        }
      })
      setValue("sla", newValue, {
        shouldDirty: true,
      })
      return
    }
  }, [path, setSlaErrors, name, sla, setValue])

  if ((type === SlaItemType.Rule && path.length > 3) || (type === SlaItemType.Group && path.length > 2)) {
    return null
  }

  return type === SlaItemType.Group ? (
    <Tooltip
      tooltip={
        <Copy scale={10} color="theme-b-n3">
          Deletes the entire group with all contents inside
        </Copy>
      }
      ariaLabel="Remove group button tooltip"
      withTitle={false}
      contentWidth="max-content"
      trigger={["hover"]}
      delayShow={2000}
      placement="top"
      contentCss={{
        width: 240,
        padding: "$20 $16",
        borderRadius: "$8",
      }}
      triggerCss={{
        display: "inline-grid",
        textAlign: "start",
        color: "$system-inherit",
        borderRadius: "$8",
        outline: "none",

        hover: {
          outline: "none",
          boxShadow: "none",
        },
        keyboardFocus: {
          outline: "none",
          "> span": {
            borderColor: "$brand-yellow-primary",
            outline: "2px solid $brand-yellow-primary",
          },
        },
      }}
      onKeyDown={(e) => {
        if (spaceAndEnterKeyDown(e.key)) {
          e.preventDefault()
          e.stopPropagation()
          handleClick()
        }
      }}
    >
      <Button
        as="span"
        action="secondary"
        type="button"
        ariaLabel="Remove group button"
        size="small"
        onClick={handleClick}
        css={{ width: "$32", paddingX: 0 }}
      >
        <IconCross css={{ display: "block" }} />
      </Button>
    </Tooltip>
  ) : (
    <ButtonIcon
      type="button"
      icon={<IconCross />}
      ariaLabel="Remove sla rule button"
      onClick={handleClick}
      inputIcon
      css={{ width: "$32", height: "$32" }}
    />
  )
}
