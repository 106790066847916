import { useCallback, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { useDebounce } from "use-debounce"
import { Controller, useFormContext } from "react-hook-form"

import { escapeKeyDown } from "@/shared/utils"
import {
  RouteParams,
  IOrganizationCarrier,
  IForceTenderToAnyDto,
} from "@/shared/types"
import { useOrganizationCarriersFull } from "@/management/hooks"

import {
  Box,
  ButtonIcon,
  Copy,
  Flex,
  FormCheckbox,
  FormInput,
  Popover,
  PopoverAnchor,
  PopoverContent,
  ResponseContentItem,
  Spacer,
  Stack,
} from "@/shared/components"
import { IconChevronTop, IconChevronDown, IconCross, IconSearch } from "@/shared/icons"
import { IllustrationSpinner } from "@/shared/illustrations"

import { SimpleSelectSPopoverAnchorButton } from "@/shared/styles/SPopoverAnchorButton"

export const CarrierForceTenderSelect = () => {
  const { organizationId } = useParams<keyof RouteParams>() as RouteParams

  const [inputValue, setInputValue] = useState<string>("")
  const [keyword] = useDebounce(inputValue, 500)

  const [isOpen, setIsOpen] = useState(false)
  const triggerRef = useRef<HTMLButtonElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const clearButtonRef = useRef<HTMLButtonElement>(null)
  const isTriggerClick = (event: Event) => event.composedPath().includes(triggerRef.current as EventTarget)
  const { setValue, control, register } = useFormContext<IForceTenderToAnyDto>()

  const { fetchStatus: organizationCarriersFetchStatus, data: carriersData } = useOrganizationCarriersFull({
    orgId: organizationId,
    name: encodeURIComponent(keyword),
    size: 5,
    disableCache: true,
  })

  const [selectedCarrier, setSelectedCarrier] = useState<IOrganizationCarrier | null>(null)

  const isSearchLoading = organizationCarriersFetchStatus === "fetching"

  const handleChange = useCallback((event: React.FormEvent<HTMLInputElement>) => {
      if (!event.currentTarget.checked) {
        if (carriersData?.content) {
          setValue("carrierId", selectedCarrier?.id || "")
        }
      } else {
        if (carriersData?.content) {
          const newCarrier = carriersData.content.find((i) => i.id == event.currentTarget.value)
          if (newCarrier) {
            setSelectedCarrier(newCarrier)
            setValue("carrierId", newCarrier.id || "")
          }
        }
      }
    },
    [
      selectedCarrier,
      carriersData,
      setValue
    ],
  )

  return (
    <Controller
      name={"carrierId"}
      control={control}
      render={({ field }) => {
        return (
          <Popover open={isOpen}>

            {/* name of field and dropdown */}
            <PopoverAnchor
              asChild
              onKeyDown={(e: { key: string }) => escapeKeyDown(e.key) && setIsOpen(false)}
            >
              <Flex ref={containerRef} direction="column" css={{ position: "relative" }}>
                <Flex css={{ gap: "$4" }}>
                  <Copy scale={10} color="neutrals-5" fontWeight="semiBold">
                    Select carrier
                    <Copy
                      as="span"
                      scale={10}
                      fontWeight="semiBold"
                      css={{ paddingLeft: "$2", visibility: "visible"}}
                    >
                      *
                    </Copy>
                  </Copy>
                </Flex>
                <Spacer size={4} />
                <SimpleSelectSPopoverAnchorButton
                  {...register(field.name, {
                    shouldUnregister: true
                  })}
                  {...field}
                  value={field.value}
                  ref={triggerRef}
                  type="button"
                  onClick={() => setIsOpen(!isOpen)}
                  active={isOpen}
                  // hasError={
                  //   selectedItems?.length === 0 || selectedCarrierIds?.length === 0
                  // }
                >
                  <Copy as="span">{selectedCarrier?.name || "Not selected"}</Copy>
                  {isOpen ? <IconChevronTop size="xs" /> : <IconChevronDown size="xs" />}
                </SimpleSelectSPopoverAnchorButton>
              </Flex>
            </PopoverAnchor>


            {/* list with carriers and selected OR not found carrier */}
            <PopoverContent
              close={() => setIsOpen(false)}
              align="start"
              css={{
                width: containerRef.current?.getBoundingClientRect().width,
                borderRadius: "$8",
                overflow: "hidden",
                keyboardFocus: {
                  outline: "1px solid $brand-yellow-primary",
                },
              }}
              alignOffset={-1}
              onInteractOutside={(event) => {
                if (isTriggerClick(event)) {
                  return
                }
                return setIsOpen(false)
              }}
              onOpenAutoFocus={(event) => {
                event.preventDefault()
              }}
            >
              <Stack space={0} css={{ paddingX: 0 }}>
                <Box css={{ padding: "$12 $16" }}>
                  <FormInput
                    id="Search field carrier"
                    value={inputValue}
                    label="Search for carrier"
                    labelProps={{ hidden: true }}
                    placeholder="Search for carrier"
                    onChange={(event) => {
                      setInputValue(event.target.value)
                    }}
                    prefix={<IconSearch />}
                    suffix={
                      inputValue && (
                        <ButtonIcon
                          ref={clearButtonRef}
                          icon={<IconCross />}
                          ariaLabel="Clear button"
                          onClick={() => {
                            setInputValue("")
                          }}
                          inputIcon
                        />
                      )
                    }
                    autoComplete="new-password"
                  />
                </Box>

                <Box css={{ height: "max-content", maxHeight: 320, overflow: "auto" }}>
                  {selectedCarrier ? (
                      !keyword ? (
                        !selectedCarrier ? (
                          <Box css={{ padding: "$16" }}>
                            <Copy color="theme-b-n3">Not found</Copy>
                          </Box>
                        ) : (
                            <ResponseContentItem key={selectedCarrier?.id}>
                              <FormCheckbox
                                value={`${selectedCarrier?.id}`}
                                onChange={handleChange}
                                name={`${selectedCarrier?.id}`}
                                id={`${selectedCarrier?.id}`}
                                label={selectedCarrier?.name}
                                checked={true}
                              />
                            </ResponseContentItem>
                        )
                      ) : null
                  ) : (
                    <Flex css={{ padding: "$16", height: "$56" }}>Carrier not selected</Flex>
                  )}


                  {/* dropdown with carriers */}
                  {keyword && !isSearchLoading ? (
                    carriersData?.content ? (
                      (carriersData?.content || []).map((item) => (
                        <ResponseContentItem key={item.id}>
                          <FormCheckbox
                            value={`${item.id}`}
                            onChange={handleChange}
                            name={item.id}
                            id={item.id}
                            label={item.name}
                            checked={selectedCarrier?.id === item.id}
                          />
                        </ResponseContentItem>
                      ))
                    ) 
                    : (
                      <Box css={{ padding: "$16" }}>
                        <Copy color="theme-b-n3">No matches found</Copy>
                      </Box>
                    )
                  ) : null}


                  {keyword && isSearchLoading && (
                    <Flex align="center" css={{ padding: 16 }}>
                      <IllustrationSpinner />
                    </Flex>
                  )}
                </Box>
              </Stack>
            </PopoverContent>
          </Popover>
        )
      }}
    />
  )
}

CarrierForceTenderSelect.displayName = "CarrierForceTenderSelect"
