import { useQuery } from "@tanstack/react-query"
import { isAxiosError } from "axios"

import { getAmountValues } from "@/api/tenderApi"
import { showToast } from "@/shared/utils"
import { IDashboardAmountNumbers } from "@/shared/types"
import { useGlobalStore } from "@/store"

export const useGetDashboardNumbers = () => {
  const [carrierDashboardToken] = useGlobalStore((state) => [state.carrierDashboardToken])

  return useQuery<IDashboardAmountNumbers>(
    ["dashboardDataNumbers", carrierDashboardToken],
    () => getAmountValues(carrierDashboardToken),
    {
      keepPreviousData: true,
      onError: (error) => {
        if (isAxiosError(error)) {
          showToast({ type: "error", text: error.response?.data.errorMessage || error.message })
        }
      },
    },
  )
}
