import { memo } from "react"

import { OrderStatusType, FlsStatusType } from "@/shared/types"
import { Colors, ResponsiveProp } from "@/stitches/types"
import { statusToText } from "@/shared/utils"

import { Copy, Flex, TypographyScale } from "@/shared/components"

function getStatusColor(status: OrderStatusType | FlsStatusType): {
  color: Colors
  backgroundColor: Colors
} {
  switch (status) {
    case OrderStatusType.OmsCreated:
    case OrderStatusType.OmsAccepted:
    case OrderStatusType.ReadyForTender:
      return {
        color: "system-white",
        backgroundColor: "specials-info",
      }

    case OrderStatusType.WaitingForAcknowledge:
    case OrderStatusType.Tendering:
    case FlsStatusType.PASSED_TO_APOLLO:
    case FlsStatusType.CREATED_IN_APOLLO:
      return {
        color: "system-black",
        backgroundColor: "specials-warning",
      }

    case OrderStatusType.Completed:
    case OrderStatusType.TenderingFinished:
    case FlsStatusType.COVERED:
    case FlsStatusType.COVERED_AND_COMPLETED:
      return {
        color: "system-white",
        backgroundColor: "specials-success",
      }

    case OrderStatusType.NotCovered:
    case FlsStatusType.REVENUE_CODE_WAS_CHANGED_BY_OPS:
    case FlsStatusType.CANCELED_BECAUSE_UPDATE_FAILED:
    case FlsStatusType.FAILED_TO_CREATE_ORDER_IN_FLS:
    case FlsStatusType.FAILED_TO_CREATE_IN_APOLLO:
    case FlsStatusType.SEND_TO_MANUAL_PROCESSING:
    case FlsStatusType.RETURNED_TO_MCLEOD:
    case FlsStatusType.CANCELED_BY_APOLLO:
    case FlsStatusType.NOT_BROKERAGE:
    case FlsStatusType.RETRY_FLIPPING:
    case FlsStatusType.IGNORED:
    case FlsStatusType.ON_HOLD:
      return {
        color: "system-white",
        backgroundColor: "specials-error",
      }

    default:
      return {
        color: "neutrals-5",
        backgroundColor: "theme-n2-n7",
      }
  }
}

export const OrderStatusLabel = memo(
  ({
    status,
    scale = 10,
    filled = false,
    big,
  }: {
    status: OrderStatusType | FlsStatusType
    scale?: TypographyScale | ResponsiveProp<TypographyScale>
    filled?: boolean
    height?: number
    big?: boolean
  }) => {
    return filled ? (
      <Flex
        align="center"
        css={{
          height: big ? "$32" : "$24",
          borderRadius: "$32",
          padding: `$2 $${big ? "16" : "8"}`,
          backgroundColor: `$${getStatusColor(status).backgroundColor}`,
          width: "fit-content",
        }}
      >
        <Copy as="span" scale={scale} color={getStatusColor(status).color} truncate fontWeight="bold">
          {statusToText(status)}
        </Copy>
      </Flex>
    ) : (
      <Copy as="span" scale={scale} color={getStatusColor(status).color} truncate fontWeight="bold">
        {statusToText(status)}
      </Copy>
    )
  },
)

OrderStatusLabel.displayName = "OrderStatusLabel"
