import { styled } from "@/stitches/config"

export const SAccordionButton = styled("button", {
  reset: true,
  display: "flex",
  alignItems: "center",
  gap: "$16",
  width: "100%",
  height: "$56",
  paddingX: "$16",
  backgroundColor: "$theme-n2-n7",
  color: "$theme-b-n3",
  textAlign: "left",
  transition: "150ms background",
  cursor: "pointer",

  disabled: {
    pointerEvents: "none",
  },

  "@sm": {
    hover: {
      backgroundColor: "$theme-n3-n7",
    },
    keyboardFocus: {
      backgroundColor: "$theme-n3-n7",
    },
  },
})

export const SAccordionIconBox = styled("span", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "$24",
  height: "$24",
  backgroundColor: "transparent",
  border: "1px solid $theme-n6-n5",
  borderRadius: "$2",
})
