import { useQuery } from "@tanstack/react-query"
import { isAxiosError } from "axios"

import { getOrgPropertiesFn } from "@/api/ordersApi"
import { showToast } from "@/shared/utils"
import { OrgProperties } from "@/shared/types"
import { useGlobalStore } from "@/store"

export const useOrgProperties = (orgId: string, keys: OrgProperties[]) => {
  const [setOrgProperties] = useGlobalStore((state) => [state.setOrgProperties])

  return useQuery(["allOrgProperties", keys], () => getOrgPropertiesFn(orgId, keys), {
    keepPreviousData: true,
    refetchInterval: 60 * 1000,
    onSuccess: (currentOrgProperties) => setOrgProperties(currentOrgProperties),
    onError: (error) => {
      if (isAxiosError(error)) {
        showToast({
          type: "error",
          text: error.response?.data.errorMessage || error.message,
        })
      }
    },
  })
}
