import { useCallback } from "react"
import { useNavigate } from "react-router-dom"

import { useModal, useModalActions } from "@/shared/hooks"

import { Button, Copy, Flex, Stack, Title, useGlobalSettings } from "@/shared/components"
import { GeneralModal } from "@/modals"

export function LeaveWithoutChangesModal<T>({
  modalName,
  futureTab,
  setTab,
}: {
  modalName: string
  futureTab?: T
  setTab?: (arg: T) => void
}) {
  const navigate = useNavigate()
  const { modalProps } = useModal(modalName)
  const { close } = useModalActions()
  const { setGlobalProtectFlag, globalProtectUrl, setGlobalProtectUrl } =
    useGlobalSettings("LeaveWithoutChangesModal")

  const handleContinueClick = useCallback(() => {
    globalProtectUrl && navigate(globalProtectUrl)
    futureTab && setTab && setTab(futureTab)

    setGlobalProtectUrl("")
    setGlobalProtectFlag(false)
    close(modalName)
  }, [
    close,
    futureTab,
    globalProtectUrl,
    modalName,
    navigate,
    setGlobalProtectFlag,
    setGlobalProtectUrl,
    setTab,
  ])

  const handleCancelClick = useCallback(() => {
    setGlobalProtectUrl("")
    close(modalName)
  }, [close, modalName, setGlobalProtectUrl])

  return (
    <GeneralModal
      {...modalProps}
      closeButton={{ onClick: handleCancelClick }}
      onInteractOutside={handleCancelClick}
      onEscapeKeyDown={handleCancelClick}
      footer={
        <Flex justify="end" css={{ gap: "$16", paddingBottom: "$8" }}>
          <Button onClick={handleContinueClick}>OK</Button>
          <Button action="secondary" onClick={handleCancelClick}>
            Cancel
          </Button>
        </Flex>
      }
    >
      <Stack space={12} css={{ padding: "$64 $24 $0" }}>
        <Title as="h3" scale={2} color="system-black">
          Do you want to leave without saving?
        </Title>
        <Copy scale={10} color="theme-n6-n5" css={{ paddingBottom: "$8" }}>
          In case you leave, new changes will not be saved
        </Copy>
      </Stack>
    </GeneralModal>
  )
}
