import { IOrganizationCarrier, IOrganizationCarrierDto, StatusType } from "@/shared/types"
import { phoneToText } from "@/shared/utils"

export const transformOrganizationCarriersResponseFull = (data: {
  content: IOrganizationCarrierDto[]
  totalPages: number
  totalElements: number
}): { content: IOrganizationCarrier[]; totalPages: number; totalElements: number } => {
  const transformedContent = data.content.map((carrier) => {
    const {
      truckAmount,
      vanAmount,
      flatbedAmount,
      reeferAmount,
      twic,
      teamDrivers,
      maxCargo,
      tankerEndorsement,
      dropTrailers,
      automatedTracking,
      driverWork,
      highValue,
      hazmat,
      dropDeckAmount,
      tankerAmount,
      intermodalAmount,
      pneumaticAmount,
      dumpsAmount,
      carHaulerAmount,
      doubleDropAmount,
      lowboyAmount,
      grainAmount,
      livestockAmount,
      heavyHaulAmount,
    } = carrier.equipment
    const { noHaulShippers, noHaulConsignees } = carrier.laneDetails
    const { claimsRatio, ediCompliance, invoiceCompliance, otd, otp, tenderAcceptance } = carrier.analytics
    const { quotes, tracking } = carrier.integration

    return {
      ...carrier,
      phone: phoneToText(carrier.phone),
      enabled: carrier.enabled ? StatusType.Active : StatusType.Inactive,
      carrierRepName: carrier.carrierRepName || "",
      carrierRepEmail: carrier.carrierRepEmail || "",
      contacts: carrier.contacts.map(({ id, name, role, phone, email, tenderContact, sourceType }) => ({
        id,
        name,
        role,
        phone: phoneToText(phone),
        email,
        tenderContact,
        sourceType,
      })),
      equipment: {
        truckAmount: truckAmount || "",
        vanAmount: vanAmount || "",
        flatbedAmount: flatbedAmount || "",
        reeferAmount: reeferAmount || "",
        twic,
        teamDrivers,
        maxCargo: maxCargo || "",
        tankerEndorsement,
        dropTrailers,
        automatedTracking,
        driverWork,
        highValue,
        hazmat,
        dropDeckAmount,
        tankerAmount,
        intermodalAmount,
        pneumaticAmount,
        dumpsAmount,
        carHaulerAmount,
        doubleDropAmount,
        lowboyAmount,
        grainAmount,
        livestockAmount,
        heavyHaulAmount,
      },
      lanes: carrier.lanes.map(({ id, from, to, price, contacts }) => ({
        id,
        from: from ? from : "",
        to: from ? to : "",
        price: price ? `${price}` : "",
        contacts: contacts || [],
      })),
      laneDetails: {
        noHaulShippers,
        noHaulConsignees,
      },
      analytics: {
        claimsRatio: claimsRatio || "",
        ediCompliance: ediCompliance || "",
        invoiceCompliance: invoiceCompliance || "",
        otd: otd || "",
        otp: otp || "",
        tenderAcceptance: tenderAcceptance || "",
      },
      integration: {
        quotes,
        tracking,
      },
    }
  })

  const sortedContent = transformedContent.sort((a, b) => a.name.localeCompare(b.name))

  return {
    content: sortedContent,
    totalPages: data.totalPages,
    totalElements: data.totalElements,
  }
}
