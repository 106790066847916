import { useMutation, useQueryClient } from "@tanstack/react-query"
import { isAxiosError } from "axios"

import { stopTasksFn } from "@/api/tasksApi"
import { showToast } from "@/shared/utils"

export const useStopTask = () => {
  const queryClient = useQueryClient()

  return useMutation(() => stopTasksFn(), {
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["checkTask"],
      }),
    onError: (error) => {
      if (isAxiosError(error)) {
        showToast({
          type: "error",
          text: error.response?.data.errorMessage || error.message,
          autoClose: false,
        })
      }
    },
  })
}
