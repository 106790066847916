import React, { memo } from "react"
import * as PopoverPrimitive from "@radix-ui/react-popover"

import { CSS } from "@/stitches/config"
import { ComponentProps } from "@/stitches/types"
import { escapeKeyDown } from "@/shared/utils"

import { SPopoverContent } from "./Popover.styles"

export const Popover = PopoverPrimitive.Root
export const PopoverTrigger = PopoverPrimitive.Trigger
export const PopoverAnchor = PopoverPrimitive.Anchor
export const PopoverClose = PopoverPrimitive.Close

interface IPopoverContentProps
  extends ComponentProps<typeof SPopoverContent>,
    PopoverPrimitive.PopoverContentProps {
  children: React.ReactNode
  contentCss?: CSS
  close: () => void
}

export const PopoverContent = memo(
  React.forwardRef<HTMLInputElement, IPopoverContentProps>(({ children, close, ...props }, ref) => {
    return (
      <SPopoverContent
        tabIndex={0}
        avoidCollisions
        collisionPadding={12}
        sideOffset={8}
        ref={ref}
        onKeyDown={(e: { key: string; stopPropagation: () => void }) => {
          if (escapeKeyDown(e.key)) {
            e.stopPropagation()
            close()
          }
        }}
        {...props}
      >
        {children}
      </SPopoverContent>
    )
  }),
)

PopoverContent.displayName = "PopoverContent"
