import { useQuery } from "@tanstack/react-query"
import { isAxiosError } from "axios"

import { getOrganizationCarriersFn } from "@/api/slaApi"
import { showToast, transformOrganizationCarriersResponseFull } from "@/shared/utils"
import { FilterCarrierLinesType } from "@/shared/types"

export const useOrganizationCarriersFull = ({
  orgId,
  name,
  page,
  size,
  disableCache,
  hasLanesPrice,
  noLanes,
  noContacts,
  lanesNoPrice,
  isGetData,
  requestBody,
}: {
  orgId: string
  name?: string
  page?: number
  size?: number
  disableCache?: boolean
  hasLanesPrice?: boolean
  noLanes?: boolean
  noContacts?: boolean
  lanesNoPrice?: boolean
  isGetData?: boolean
  requestBody?: FilterCarrierLinesType[]
}) => {
  const isEnabled = !!orgId

  return useQuery(
    [
      "organizationCarriersFull",
      orgId,
      name,
      page,
      size,
      disableCache,
      hasLanesPrice,
      noLanes,
      noContacts,
      lanesNoPrice,
      requestBody,
    ],
    () =>
      getOrganizationCarriersFn({
        orgId,
        name,
        page,
        size,
        hasLanesPrice,
        noLanes,
        noContacts,
        lanesNoPrice,
        requestBody,
      }),
    {
      cacheTime: !disableCache ? (isEnabled ? 5 * 60 * 1000 : 0) : 0,
      enabled: isEnabled && isGetData,
      keepPreviousData: disableCache ? false : true,
      select: (data) => transformOrganizationCarriersResponseFull(data),
      onError: (error) => {
        if (isAxiosError(error)) {
          showToast({ type: "error", text: error.response?.data.errorMessage || error.message })
        }
      },
    },
  )
}
