import { useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"

import { ISlaItem, SlaField, IOrganizationState } from "@/shared/types"

import { FormInput } from "@/shared/components"
import { useOrganizationForm } from "@/management/components"
import { validateLane } from "@/shared/rules"
import { getStopType } from "@/shared/utils"

export const ValuesField = ({
  values,
  name,
  field,
  path = [],
  isViewMode,
}: {
  name: SlaField
  path?: number[]
  field?: SlaField | null
  values?: string[]
  isViewMode?: boolean
}) => {
  const { slaErrors, setSlaErrors } = useOrganizationForm("ValuesField")
  const { watch, setValue } = useFormContext<IOrganizationState>()
  const { sla } = watch()
  const [additionalErrorText, setAdditionalErrorText] = useState<string>()

  const isAddressField = field === SlaField.AddressFrom || field === SlaField.AddressTo

  // TODO: it will need to be refactored in the future
  const handleValueChange = (value: string) => {
    let values: string[] = []

    if (isAddressField) {
      values = [value]
      const validatedValue = validateLane(value)
      // INFO: transform object to string
      values = [JSON.stringify({ type: getStopType(values[0]), value: values[0].replace(/,\s+/g, ",") })]
      if (!validatedValue) {
        setSlaErrors([...slaErrors, `slaValues_${name}_addressField_${path.join(".")}`])
        setAdditionalErrorText("Invalid format")
      } else {
        setSlaErrors(slaErrors.filter((i) => i !== `slaValues_${name}_addressField_${path.join(".")}`))
        setAdditionalErrorText("")
      }
    } else {
      values = value.split(",")
    }

    if (path.length === 0) {
      const newValue = sla.map((i) => {
        if (i.name !== name) {
          return i
        }

        return {
          ...i,
          content: {
            ...(i.content as ISlaItem),
            values: values,
          },
        }
      })
      setValue("sla", newValue, {
        shouldDirty: true,
      })
      return
    }

    if (path.length === 1) {
      const newValue = sla.map((i) => {
        if (i.name !== name) {
          return i
        }

        return {
          ...i,
          content: {
            ...(i.content as ISlaItem),
            nodes: i.content?.nodes?.map((j, index) => {
              if (index !== path[0]) {
                return j
              }

              return {
                ...j,
                values: values,
              }
            }),
          },
        }
      })
      setValue("sla", newValue, {
        shouldDirty: true,
      })
      return
    }

    if (path.length === 2) {
      const newValue = sla.map((i) => {
        if (i.name !== name) {
          return i
        }

        return {
          ...i,
          content: {
            ...(i.content as ISlaItem),
            nodes: i.content?.nodes?.map((n, index) => {
              if (index !== path[0]) {
                return n
              }

              return {
                ...n,
                nodes: n.nodes?.map((j, index) => {
                  if (index !== path[1]) {
                    return j
                  }

                  return {
                    ...j,
                    values: values,
                  }
                }),
              }
            }) as ISlaItem[],
          },
        }
      })
      setValue("sla", newValue, {
        shouldDirty: true,
      })
      return
    }

    if (path.length === 3) {
      const newValue = sla.map((i) => {
        if (i.name !== name) {
          return i
        }

        return {
          ...i,
          content: {
            ...(i.content as ISlaItem),
            nodes: i.content?.nodes?.map((n, index) => {
              if (index !== path[0]) {
                return n
              }

              return {
                ...n,
                nodes: n.nodes?.map((k, index) => {
                  if (index !== path[1]) {
                    return k
                  }

                  return {
                    ...k,
                    nodes: k.nodes?.map((j, index) => {
                      if (index !== path[2]) {
                        return j
                      }

                      return {
                        ...j,
                        values: values,
                      }
                    }),
                  }
                }),
              }
            }) as ISlaItem[],
          },
        }
      })
      setValue("sla", newValue, {
        shouldDirty: true,
      })
      return
    }
  }

  useEffect(() => {
    if (!values?.join(",") && !slaErrors?.includes(`slaValues_${name}_${path.join(".")}`)) {
      setSlaErrors([...slaErrors, `slaValues_${name}_${path.join(".")}`])
    }

    if (values?.join(",") && slaErrors?.includes(`slaValues_${name}_${path.join(".")}`)) {
      setSlaErrors(slaErrors.filter((i) => i !== `slaValues_${name}_${path.join(".")}`))
    }
  }, [values, name, path, setSlaErrors, slaErrors])

  return (
    <FormInput
      // onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
      //   if (
      //     event.key === "e" ||
      //     event.key === "-" ||
      //     event.key === "+" ||
      //     event.key === "." ||
      //     event.key === ","
      //   ) {
      //     event.preventDefault()
      //   }
      // }}

      value={isAddressField && values && values[0] ? JSON.parse(values[0]).value : values?.join(",")}
      onChange={(event) => handleValueChange(event?.target?.value)}
      id={`slaValues_${name}_${path.join(".")}`}
      name={`slaValues_${name}_${path.join(".")}`}
      label="Values field"
      labelProps={{ hidden: true }}
      // type="number"

      placeholder={isAddressField ? "City and state / state / zip / KMA / Any" : "Value"}
      isViewMode={isViewMode}
      error={!values?.join(",") ? "Required field" : additionalErrorText}
    />
  )
}
