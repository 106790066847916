import { CommonLayout } from "@/shared/layouts/common"
import { Box, Copy, Flex, Spacer } from "@/shared/components"
import * as AllElements from "@/shared/illustrations"

export const Illustrations = () => {
  return (
    <CommonLayout>
      <Flex align="center" wrap css={{ padding: "$64", gap: "$32" }}>
        {Object.keys(AllElements).map((el) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const Element = AllElements[el]

          return (
            <Box
              key={el}
              css={{
                padding: "$8",
                backgroundColor: "$system-black",
                border: "1px solid $theme-n3-n7",
                borderRadius: "$8",
                textAlign: "center",
              }}
            >
              <Element />
              <Spacer size={4} />
              <Copy scale={12} color="system-white">{`<${el} />`}</Copy>
            </Box>
          )
        })}
      </Flex>
    </CommonLayout>
  )
}
