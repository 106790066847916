import { Control, FieldValues, Path, RegisterOptions, UseFormRegister, UseFormTrigger } from "react-hook-form"

import { IFormFieldCommonProps } from "@/shared/components"

export enum FormType {
  Text = "text",
  Email = "email",
  Password = "password",
  Number = "number",
}

export interface IZipCodeData {
  zipCode: string
  country: string
}

interface IFormExtendedData {
  zipCodeData?: IZipCodeData
  isOrigin?: boolean
}

export interface IFormInputControllerProps<FieldsType extends FieldValues>
  extends Omit<IFormFieldCommonProps, "label" | "description"> {
  name: Path<FieldsType>
  type?: FormType
  defaultValue?: string
  rules?: Omit<RegisterOptions, "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled">
  control: Control<FieldsType>
  register?: UseFormRegister<FieldsType>
  trigger?: UseFormTrigger<FieldsType>
  error?: string
  disabled?: boolean
  label?: React.ReactNode
  description?: string
  placeholder?: string
  extendedData?: IFormExtendedData
  autoFocus?: boolean
  prefix?: JSX.Element | string

  onBlurHandler?: () => void
  onChangeHandler?: (value?: string) => void

  controlledValue?: string | number | readonly string[]
  controlledChange?: React.ChangeEventHandler<HTMLInputElement>
}

export interface IFormInputEnrichControllerProps<FieldsType extends FieldValues>
  extends IFormInputControllerProps<FieldsType> {
  type?: FormType
  autoComplete?: string
  autoFocus?: boolean
  prefix?: JSX.Element | string
}
