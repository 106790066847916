import { FormProvider, useForm } from "react-hook-form"
import { Navigate } from "react-router-dom"
import { isAxiosError } from "axios"

import { useGlobalStore } from "@/store"
import { CarrierTabType, initialDashboardData, IDashboardFormData } from "@/shared/types"
import { useGetDashboardCarrierData, useGetDashboardNumbers } from "@/carrier/hooks"
import { CARRIER_LOGIN, ErrorStatusCode } from "@/constants"

import { Copy, Spacer, TabList, TabListItem, TabPanel, TabPanels, Tabs, Title } from "@/shared/components"
import { CarrierOrderPriceModal, CarrierOrderRejectModal } from "@/modals"

import { OrdersContainer } from "../OrdersContainer"

export const CarrierContainerContent = () => {
  const [carrierViewTab, setCarrierViewTab] = useGlobalStore((state) => [
    state.carrierViewTab,
    state.setCarrierViewTab,
  ])
  const {
    data: { ACCEPTED = 0, EXPIRED = 0, PENDING = 0, REJECTED = 0 } = {},
    isInitialLoading: isInitialLoadingNumbers,
    error: numbersError,
  } = useGetDashboardNumbers()
  const {
    data: carrierData,
    isLoading: isCarrierDataLoading,
    error: carrierDataError,
  } = useGetDashboardCarrierData()

  const methods = useForm<IDashboardFormData>({
    // mode: "onChange",
    mode: "all",
    defaultValues: initialDashboardData,
  })

  const isLoadingData = isCarrierDataLoading || isInitialLoadingNumbers

  if (isLoadingData) {
    return <></>
  }

  if (
    (isAxiosError(numbersError) && numbersError?.response?.status === ErrorStatusCode[401]) ||
    (isAxiosError(carrierDataError) && carrierDataError?.response?.status === ErrorStatusCode[401])
  ) {
    return <Navigate to={CARRIER_LOGIN} replace />
  }

  return (
    <>
      <Title as="h1" scale={2} color="system-black">
        {`Hello, ${carrierData?.carrierName}`}
      </Title>
      <Spacer size={32} />
      <Copy color="neutrals-7">{`Please review the offers from ${carrierData?.orgName} below:`}</Copy>
      <Spacer size={48} />

      <FormProvider {...methods}>
        <form noValidate autoComplete="off">
          <Tabs selectedTab={carrierViewTab} animate={false}>
            <TabList label="management-tabs">
              <TabListItem
                id={CarrierTabType.Pending}
                onChange={() => {
                  setCarrierViewTab(CarrierTabType.Pending)
                }}
              >
                {`Pending orders (${PENDING})`}
              </TabListItem>
              <TabListItem
                id={CarrierTabType.Accepted}
                onChange={() => {
                  setCarrierViewTab(CarrierTabType.Accepted)
                }}
              >
                {`Accepted orders (${ACCEPTED})`}
              </TabListItem>
              <TabListItem
                id={CarrierTabType.Rejected}
                onChange={() => {
                  setCarrierViewTab(CarrierTabType.Rejected)
                }}
              >
                {`Rejected orders (${REJECTED})`}
              </TabListItem>
              <TabListItem
                id={CarrierTabType.Expired}
                onChange={() => {
                  setCarrierViewTab(CarrierTabType.Expired)
                }}
              >
                {`Expired orders (${EXPIRED})`}
              </TabListItem>
            </TabList>
            <TabPanels>
              <TabPanel id={CarrierTabType.Pending}>
                <OrdersContainer />
              </TabPanel>
              <TabPanel id={CarrierTabType.Accepted}>
                <OrdersContainer />
              </TabPanel>
              <TabPanel id={CarrierTabType.Rejected}>
                <OrdersContainer />
              </TabPanel>
              <TabPanel id={CarrierTabType.Expired}>
                <OrdersContainer />
              </TabPanel>
            </TabPanels>
          </Tabs>
          <CarrierOrderPriceModal />
          <CarrierOrderRejectModal />
        </form>
      </FormProvider>
    </>
  )
}
