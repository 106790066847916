export enum SlaField {
  Consignee = "CONSIGNEE",
  Shipper = "SHIPPER",
  ExcludedCarriers = "CARRIER_NAME",
  Delivery = "OTD",
  Tender = "TENDER_ACCEPTANCE",
  Tracking = "TRACKING",
  Unloading = "UNLOADING",
  Cargo = "MIN_CARGO_INSURANCE",
  Haul = "NO_HAUL_CONSIGNEE",
  Brokers = "ALLOW_BROKERS",
  AddressFrom = "ADDRESS_FROM",
  AddressTo = "ADDRESS_TO",
}

export enum SlaItemType {
  Group = "GROUP",
  Rule = "RULE",
}

export enum SlaItemGroup {
  And = "AND",
  Or = "OR",
}

export enum SlaItemOp {
  Eq = "EQ",
  Neq = "NEQ",
  Gt = "GT",
  Lt = "LT",
  Gte = "GTE",
  Lte = "LTE",
}

export interface ISlaItem {
  type: SlaItemType
  nodes?: ISlaItem[]
  group?: SlaItemGroup
  field?: SlaField | null
  op?: SlaItemOp | null
  values?: string[]
}

export interface ISla {
  name: SlaField
  content: ISlaItem | null
}
