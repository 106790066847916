import { useFormContext } from "react-hook-form"

import { IOrganizationState } from "@/shared/types"
import { LANES_MAX } from "@/constants"

import {
  AddItemButton,
  Box,
  Copy,
  Flex,
  Grid,
  GridItem,
  Redacted,
  Spacer,
  TextFieldController,
  Title,
} from "@/shared/components"
import { isHighway } from "@/shared/utils"

import { LanesTableRow } from "./LanesTableRow"
import { LanesTablePlaceholder } from "./LanesTablePlaceholder"

export const Lanes = () => {
  const {
    control,
    register,
    formState: { errors },
    trigger,
    setValue,
    watch,
  } = useFormContext<IOrganizationState>()
  const { newCarrier } = watch()
  const { lanes } = newCarrier
  const isLoading = false

  return (
    <Box css={{ height: 420 }}>
      {isHighway(newCarrier) ? (
        <>
          <Title as="h2" scale={6} color="theme-b-n3">
            Preferred lanes
          </Title>
          <Spacer size={32} />
        </>
      ) : null}
      {isLoading ? (
        <Redacted height="$32" width="170px" text animated />
      ) : (
        <AddItemButton
          text="Add lane"
          onClick={() => {
            setValue(
              "newCarrier.lanes",
              [
                {
                  id: `id-${Date.now()}`,
                  from: "",
                  to: "",
                  price: "",
                  contacts: [],
                },
                ...lanes,
              ],
              {
                shouldDirty: true,
              },
            )
            setTimeout(() => {
              trigger("newCarrier.lanes")
            }, 0)
          }}
          disabled={lanes.length >= LANES_MAX}
          css={{ width: "fit-content" }}
        />
      )}
      <Spacer size={24} />
      {isLoading ? (
        <LanesTablePlaceholder />
      ) : (
        <Box>
          <LanesTableRow
            from="From*"
            to="To*"
            price="Price, usd"
            contacts="Tender Contact"
            isTableHead
            isShowTooltip
          />
          {lanes.length > 0 ? (
            lanes.map(({ id, from, to, price }, index) => (
              <LanesTableRow
                key={index + String(id)}
                index={index}
                id={id}
                from={from}
                to={to}
                price={price}
              />
            ))
          ) : (
            <Flex
              align="center"
              justify="center"
              css={{
                height: "$64",
                color: "$theme-b-n3",
              }}
            >
              <Copy color="theme-n6-n5">There is no data yet</Copy>
            </Flex>
          )}
        </Box>
      )}
      <Spacer size={32} />
      <Grid columns="1fr 1fr 1fr 1fr" columnGap={16}>
        <GridItem>
          <TextFieldController<IOrganizationState>
            name="newCarrier.laneDetails.noHaulShippers"
            control={control}
            register={register}
            trigger={trigger}
            description="No haul shippers"
            rules={{
              pattern: {
                value: /^[a-zA-Z\s,]*$/,
                message: "Only alphabetic characters and comma allowed",
              },
            }}
            error={errors.newCarrier?.laneDetails?.noHaulShippers?.message}
          />
        </GridItem>
        <GridItem>
          <TextFieldController<IOrganizationState>
            name="newCarrier.laneDetails.noHaulConsignees"
            control={control}
            register={register}
            trigger={trigger}
            description="No haul consignees"
            rules={{
              pattern: {
                value: /^[a-zA-Z\s,]*$/,
                message: "Only alphabetic characters and comma allowed",
              },
            }}
            error={errors.newCarrier?.laneDetails?.noHaulConsignees?.message}
          />
        </GridItem>
      </Grid>
      {isHighway(newCarrier) ? (
        <>
          <Spacer size={32} />
          <Title as="h2" scale={6} color="theme-b-n3">
            Preferred states
          </Title>
          <Spacer size={32} />
          <Flex css={{ gap: "$12", flexWrap: "wrap" }}>
            {newCarrier.lanePreferences.length > 0
              ? newCarrier.lanePreferences.map((el) => (
                  <Box
                    id={el.id + el.code}
                    key={el.id + el.code}
                    css={{
                      width: "$56",
                      height: "$32",
                      alignContent: "center",
                      textAlign: "center",
                      backgroundColor: "$brand-yellow-lighter",
                      borderRadius: "$8",
                    }}
                  >
                    {el.code}
                  </Box>
                ))
              : "-"}
          </Flex>
        </>
      ) : null}
    </Box>
  )
}
