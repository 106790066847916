export const buildUrlString = (
  basePath: string,
  params: { [key: string]: string | number | boolean | undefined },
): string => {
  const queryString = Object.entries(params)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([_, value]) => value !== undefined)
    .map(([key, value]) => `${key}=${value}`)
    .join("&")

  return queryString ? `${basePath}?${queryString}` : basePath
}
