import { useRef, useState } from "react"

import { useReadFile } from "@/files/hooks"
import { FileType } from "@/shared/types"
import { showToast, fileTypeToText } from "@/shared/utils"

import { Box, Button, Copy, Flex, FormInput, Spacer } from "@/shared/components"
import { IconImport, IconDocument, IconTick } from "@/shared/icons"
import { IllustrationSpinner } from "@/shared/illustrations"

export const UploadFile = ({ type }: { type: FileType }) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [file, setFile] = useState<File>()
  const { mutate: uploadFile, isLoading } = useReadFile(type)

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (e.target.files[0].size > 10_000_000) {
        return showToast({ type: "error", text: "Allowed file size exceeded", autoClose: false })
      }
      setFile(e.target.files[0])
    }
  }

  return (
    <Box
      css={{
        width: 600,
        backgroundColor: "$system-white",
        padding: "$16 $24",
        border: "1px solid $theme-n3-n7",
        borderRadius: "$8",
      }}
    >
      <Flex align="center" justify="between">
        <Flex css={{ gap: "$24" }}>
          <IconImport size="lg" />
          <Box>
            <Copy color="theme-b-n3" fontWeight="bold">
              Import data: {fileTypeToText(type)}
            </Copy>
            <Copy scale={10} color="neutrals-5">
              Excel file (.xlsx). Max file size is 10MB
            </Copy>
          </Box>
        </Flex>
        <Box>
          <Button
            action="secondary"
            type="button"
            disabled={isLoading}
            scale={10}
            size="medium"
            onClick={() => inputRef.current?.click()}
          >
            Select file
          </Button>
          <FormInput
            ref={inputRef}
            id={`${type} file`}
            label={`${type} file`}
            labelProps={{ hidden: true }}
            type="file"
            accept=".xlsx"
            onChange={handleFileChange}
            css={{ display: "none" }}
          />
        </Box>
      </Flex>
      {file ? (
        <>
          <Spacer size={24} />
          <Flex align="center" justify="between">
            <Copy scale={10} fontWeight="semiBold">
              File added
            </Copy>
            <Button
              action="primary"
              type="button"
              disabled={isLoading}
              scale={10}
              size="medium"
              onClick={() => uploadFile(file)}
            >
              Import
            </Button>
          </Flex>
          <Spacer size={16} />
          <Flex align="center" justify="between">
            <Flex>
              <IconDocument />
              <Spacer size={16} horizontal />
              <Copy scale={10} color="theme-b-n3">
                {file.name}
              </Copy>
            </Flex>
            {isLoading ? <IllustrationSpinner /> : <IconTick css={{ color: "$special-info" }} />}
          </Flex>
        </>
      ) : null}
    </Box>
  )
}
