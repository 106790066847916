import { memo } from "react"
import { Controller, useFormContext } from "react-hook-form"

import { IOrganizationState } from "@/shared/types"
import { RemoveItemButton } from "@/management/components"
import { lengthRule } from "@/shared/rules"

import {
  Box,
  EmailController,
  ErrorLineWithMessage,
  Flex,
  FormCheckbox,
  GridItem,
  TextFieldController,
} from "@/shared/components"

import { ContactsTableRow } from "./ContactsTableRow"

interface IContactsCreateRowProps {
  index: number
}

export const ContactsCreateRow = memo(({ index }: IContactsCreateRowProps) => {
  const {
    control,
    register,
    trigger,
    setValue,
    formState: { errors },
    watch,
  } = useFormContext<IOrganizationState>()
  const { contacts } = watch()

  return (
    <ContactsTableRow>
      <GridItem css={{ paddingX: "$24" }}>
        <Box css={{ position: "relative" }}>
          <TextFieldController<IOrganizationState>
            name={`contacts.${index}.name`}
            control={control}
            register={register}
            trigger={trigger}
            labelProps={{ required: true, hidden: true }}
            rules={{ ...lengthRule("Name", 40, 2) }}
            placeholder="Enter a name"
            borderless
          />
          <ErrorLineWithMessage
            errorId={`errors.contacts.${index}.name`}
            errorMessage={errors.contacts?.[index]?.name?.message}
          />
        </Box>
      </GridItem>
      <GridItem css={{ paddingX: "$24" }}>
        <Box css={{ position: "relative" }}>
          <EmailController<IOrganizationState>
            name={`contacts.${index}.email`}
            control={control}
            register={register}
            trigger={trigger}
            labelProps={{ required: true, hidden: true }}
            placeholder="Enter an email, e.g. name@example.com"
            borderless
          />
          <ErrorLineWithMessage
            errorId={`errors.contacts.${index}.email`}
            errorMessage={errors.contacts?.[index]?.email?.message}
          />
        </Box>
      </GridItem>
      <GridItem css={{ position: "relative", paddingX: "$24" }}>
        <Flex align="center" justify="between">
          <Controller
            name={`contacts.${index}.urgentContact`}
            control={control}
            render={({ field }) => {
              return (
                <FormCheckbox
                  {...register(field.name)}
                  label=""
                  onChange={(value) => field.onChange(value.currentTarget.checked)}
                />
              )
            }}
          />
          <RemoveItemButton
            text="contacts"
            index={index}
            onClick={() => {
              setValue(
                "contacts",
                contacts.filter((_, i) => i !== index),
                { shouldDirty: true },
              )
              setTimeout(() => {
                trigger("contacts")
              }, 0)
            }}
          />
        </Flex>
      </GridItem>
    </ContactsTableRow>
  )
})

ContactsCreateRow.displayName = "ContactsCreateRow"
