import { styled } from "@/stitches/config"

export const SMenuButton = styled("button", {
  reset: true,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "$8",
  padding: "$4 $12",
  backgroundColor: "$system-white",
  borderRadius: "$8",
  position: "relative",
  cursor: "pointer",
  transition: "150ms ease-out",
  outline: "none",

  hover: {
    backgroundColor: "$brand-yellow-light",
  },

  keyboardFocus: {
    backgroundColor: "$brand-yellow-light",
  },

  active: {
    backgroundColor: "$brand-yellow-light",
  },

  disabled: {
    pointerEvents: "none",
    color: "$neutrals-7",
  },

  variants: {
    selected: {
      true: {
        backgroundColor: "$brand-yellow-primary",
      },
    },
  },
})
