import { useQuery } from "@tanstack/react-query"
import { isAxiosError } from "axios"

import { getOrganizationStrategiesFn } from "@/api/slaApi"
import { showToast, transformOrganizationStrategiesResponse } from "@/shared/utils"
import { OrganizationType } from "@/shared/types"

export const useOrganizationStrategies = (id: string, organizationType?: OrganizationType) => {
  const isEnabled = !!id && !!organizationType

  return useQuery(["organizationStrategies", id], () => getOrganizationStrategiesFn(id), {
    cacheTime: isEnabled ? 5 * 60 * 1000 : 0,
    enabled: isEnabled,
    select: (data) => transformOrganizationStrategiesResponse(data, id, organizationType),
    onError: (error) => {
      if (isAxiosError(error)) {
        showToast({ type: "error", text: error.response?.data.errorMessage || error.message })
      }
    },
  })
}
