import { ICarrierStats } from "@/shared/types"
import { carrierCommunicationTypeToText } from "@/shared/utils"

import { Copy, Flex, IconFiller, Stack, Tooltip } from "@/shared/components"
import { IconTruck } from "@/shared/icons"

export const CarrierInfoCard = ({ carrierStats }: { carrierStats: ICarrierStats }) => {
  return (
    <Flex direction="column" align="start" css={{ padding: "$20 $24" }}>
      <Stack space={24}>
        <Flex align="center" css={{ gap: "$16" }}>
          <IconFiller color="brand-yellow-lighter">
            <IconTruck fixedSize width={20} height={20} block css={{ color: "$system-black" }} />
          </IconFiller>
          <Tooltip
            tooltip={<Copy scale={10}>{carrierStats.carrierName}</Copy>}
            ariaLabel="carrier name tooltip"
            withTitle={false}
            contentWidth="max-content"
            trigger={["hover"]}
            placement="top"
            contentCss={{
              width: "100%",
              padding: "$12",
              borderRadius: "$8",
            }}
            triggerCss={{
              display: "inline-grid",
              textAlign: "start",
              color: "$system-inherit",
              outline: "none",

              hover: {
                outline: "none",
                boxShadow: "none",
              },
              keyboardFocus: {
                outline: "none",
              },
            }}
            avoidTriggerFocus
          >
            <Copy scale={8} truncate>
              {carrierStats.carrierName || "-"}
            </Copy>
          </Tooltip>
        </Flex>

        <Copy scale={10} uppercase color="neutrals-5" fontWeight="bold">
          tenders
        </Copy>
        <Stack space={8}>
          <Copy># of Offered: {carrierStats.offeredTenders}</Copy>
          <Copy># of Accepted: {carrierStats.acceptedTenders}</Copy>
          <Copy># of Rejected: {carrierStats.rejectedTenders}</Copy>
        </Stack>
        <Copy scale={10} uppercase color="neutrals-5" fontWeight="bold">
          RFQ
        </Copy>
        <Stack space={8}>
          <Copy># of Offered: {carrierStats.offeredRfqs}</Copy>
          <Copy># of Responded: {carrierStats.acceptedRfqs}</Copy>
          <Copy># of Won: {carrierStats.wonRfqs}</Copy>
        </Stack>
        <Flex wrap css={{ gap: "$4" }}>
          <Copy>Communication type:</Copy>
          <Copy as="span" fontWeight="bold">
            {carrierCommunicationTypeToText(carrierStats.communicationType)}
          </Copy>
        </Flex>
      </Stack>
    </Flex>
  )
}
