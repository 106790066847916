import { Role } from "@/shared/types"

function getRoleMark(role: Role): number {
  switch (role) {
    case Role.Reader:
      return 0
    case Role.CustomerAdmin:
      return 1
    case Role.SysAdmin:
      return 2
    default:
      return 0
  }
}

export const isUserRoleHigher = (currentRole: Role, role: Role): boolean => {
  return getRoleMark(currentRole) >= getRoleMark(role)
}
