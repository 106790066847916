import { IOrganizationCarrier, IOrganizationCarrierDto, StatusType } from "@/shared/types"
import { getStopType } from "./getStopType"

export const carriersToCarriersDto = (
  carriers: IOrganizationCarrier[],
  orgId: string,
): IOrganizationCarrierDto[] =>
  carriers.map(({ id, phone, enabled, contacts, lanes, equipment, analytics, ...carrier }) => {
    return {
      ...carrier,
      ...(id && !isNaN(+id) && { id: id }),
      orgId,
      phone: phone.replace(/[() -]/g, ""),
      enabled: enabled === StatusType.Active,
      contacts: contacts.map(({ id, phone, ...rest }) => ({
        ...(id && !isNaN(+id) && { id: id }),
        phone: phone.replace(/[() -]/g, ""),
        ...rest,
      })),
      lanes: lanes.map(({ id, from, to, ...rest }) => ({
        ...(id && !isNaN(+id) && { id: id }),
        from,
        to,
        fromStopType: getStopType(from) || null,
        toStopType: getStopType(to) || null,
        ...rest,
      })),
      equipment: {
        ...equipment,
        truckAmount: equipment.truckAmount || null,
        vanAmount: equipment.vanAmount || null,
        flatbedAmount: equipment.flatbedAmount || null,
        reeferAmount: equipment.reeferAmount || null,
        maxCargo: equipment.maxCargo || null,
      },
      analytics: {
        claimsRatio: analytics.claimsRatio || null,
        tenderAcceptance: analytics.tenderAcceptance || null,
        ediCompliance: analytics.ediCompliance || null,
        otd: analytics.otd || null,
        invoiceCompliance: analytics.invoiceCompliance || null,
        otp: analytics.otp || null,
      },
    }
  })
