import { styled } from "@/stitches/config"

export const SModalHeader = styled("header", {
  zIndex: "$2",

  variants: {
    empty: {
      false: {
        backgroundColor: "$theme-w-n11",

        "@sm": {
          padding: "$64 $24 $24",
        },
      },
    },

    sticky: {
      false: { position: "relative" },
      true: { position: "sticky", top: 0 },
    },
  },
})
