import { styled } from "@/stitches/config"
import { blue } from "@/stitches/theme"

export const SModalCloseButton = styled("button", {
  reset: true,
  position: "absolute",
  zIndex: "$3",
  top: "$12",
  right: "$12",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  stroke: "$theme-b-n3",
  transition: "200ms ease-out",
  cursor: "pointer",
  borderRadius: "$8",

  focus: {
    outline: "none",
  },

  hover: {
    backgroundColor: "$gray-100",
    color: "$gray-900",
  },

  keyboardFocus: {
    boxShadow: `0 0 0 2px transparent, 0 0 0 2px ${blue["500"]}`,
  },

  active: {
    backgroundColor: "$gray-200",
  },

  variants: {
    variant: {
      text: {
        padding: "$4 $8",
      },
      icon: {
        height: "$32",
        width: "$32",
      },
    },
  },

  defaultVariants: {
    variant: "icon",
  },
})
