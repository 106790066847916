import { RegisterOptions } from "react-hook-form"

export const decimalRules = (required: boolean): RegisterOptions => ({
  shouldUnregister: true,
  required: {
    value: required,
    message: "Required field",
  },
  validate: {
    max: (v: string) => parseFloat(v || "0") <= 100 || "Max value exceeded",
    min: (v: string) => v.length === 0 || parseFloat(v || "0") >= 0.1 || "Min value not met",
  },
})
