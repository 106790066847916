import { memo, useCallback, useEffect, useRef, useState } from "react"

import { escapeKeyDown } from "@/shared/utils"

import {
  Box,
  Copy,
  Flex,
  FormCheckbox,
  Popover,
  PopoverAnchor,
  PopoverContent,
  ResponseContentItem,
  Stack,
} from "@/shared/components"
import { IconChevronTop, IconChevronDown } from "@/shared/icons"

import { SimpleSelectSPopoverAnchorButton } from "@/shared/styles/SPopoverAnchorButton"
import { useGlobalStore } from "@/store"

interface INoLineSelectProps {
  noLanes: boolean
  noContacts: boolean
  setNoLanes: React.Dispatch<React.SetStateAction<boolean>>
  setNoContacts: React.Dispatch<React.SetStateAction<boolean>>
  isNoLanesDisabled: boolean
}

export const NoLineSelect = memo(
  ({ noLanes, noContacts, setNoLanes, setNoContacts, isNoLanesDisabled }: INoLineSelectProps) => {
    const [isOpen, setIsOpen] = useState(false)
    const [setPage] = useGlobalStore((state) => [state.setPage])

    const triggerRef = useRef<HTMLButtonElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    const isTriggerClick = (event: Event) => event.composedPath().includes(triggerRef.current as EventTarget)

    useEffect(() => {
      if (isNoLanesDisabled) {
        setNoLanes(false)
      }
    }, [isNoLanesDisabled, setNoLanes])

    const handleChange = useCallback(
      (event: React.FormEvent<HTMLInputElement>) => {
        const { name, checked } = event.currentTarget
        if (name === "Carriers with no lanes") {
          setPage(0)
          setNoLanes(checked)
        } else if (name === "Carriers with no contacts") {
          setPage(0)
          setNoContacts(checked)
        }
      },
      [setNoLanes, setNoContacts, setPage],
    )

    const checkboxes = [
      {
        value: "Carriers with no lanes",
        onChange: handleChange,
        name: "Carriers with no lanes",
        id: "Carriers_with_no_lanes",
        label: "Carriers with no lanes",
        checked: noLanes,
        disabled: isNoLanesDisabled,
      },
      {
        value: "Carriers with no contacts",
        onChange: handleChange,
        name: "Carriers with no contacts",
        id: "Carriers_with_no_contacts",
        label: "Carriers with no contacts",
        checked: noContacts,
      },
    ]

    const viewText =
      noLanes && noContacts
        ? "No lanes, No contacts"
        : noLanes
        ? "No lanes"
        : noContacts
        ? "No contacts"
        : "All carriers"

    return (
      <Popover open={isOpen}>
        <PopoverAnchor asChild onKeyDown={(e: { key: string }) => escapeKeyDown(e.key) && setIsOpen(false)}>
          <Flex
            ref={containerRef}
            direction="column"
            css={{ position: "relative", width: 263, height: "$40", minHeight: "$40" }}
          >
            <SimpleSelectSPopoverAnchorButton
              value={`${noLanes ? "Carriers with no lanes" : ""}, ${
                noContacts ? "Carriers with no contacts" : ""
              }`}
              ref={triggerRef}
              type="button"
              onClick={() => setIsOpen(!isOpen)}
              active={isOpen}
            >
              <Copy fontWeight="medium" css={{ letterSpacing: "normal" }}>{`View: ${viewText}`}</Copy>
              {isOpen ? <IconChevronTop size="xs" /> : <IconChevronDown size="xs" />}
            </SimpleSelectSPopoverAnchorButton>
          </Flex>
        </PopoverAnchor>
        <PopoverContent
          close={() => setIsOpen(false)}
          align="start"
          css={{
            width: containerRef.current?.getBoundingClientRect().width,
            borderRadius: "$8",
            overflow: "hidden",
            keyboardFocus: {
              outline: "1px solid $brand-yellow-primary",
            },
          }}
          alignOffset={-1}
          onInteractOutside={(event) => {
            if (isTriggerClick(event)) {
              return
            }
            return setIsOpen(false)
          }}
          onOpenAutoFocus={(event) => {
            event.preventDefault()
          }}
        >
          <Stack space={0} css={{ paddingX: 0 }}>
            <Box css={{ height: "max-content", maxHeight: 320, overflow: "auto" }}>
              {checkboxes.map((checkbox) => (
                <ResponseContentItem key={checkbox.name}>
                  <FormCheckbox {...checkbox} />
                </ResponseContentItem>
              ))}
            </Box>
          </Stack>
        </PopoverContent>
      </Popover>
    )
  },
)

NoLineSelect.displayName = "NoLineSelect"
