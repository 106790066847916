import { Copy, Flex, Grid, GridItem, Redacted } from "@/shared/components"
import { IconDropArrowTop } from "@/shared/icons"

interface IWorkingDaysTableRowProps {
  loadId?: string
  from?: string
  to?: string
  pickupDate?: string
  deliveryDate?: string
  loadDetails?: string
  rate?: string
  status?: string
  isTableHead?: boolean
  isPlaceholder?: boolean
}

const TableCell = ({
  value,
  isTableHead,
  isPlaceholder,
}: {
  value?: string
  isTableHead?: boolean
  isPlaceholder?: boolean
}) => (
  <GridItem css={{ paddingX: "$16" }}>
    {isPlaceholder && !isTableHead ? (
      <Redacted height="$24" width="100px" text animated />
    ) : (
      <Flex>
        <Copy
          scale={isTableHead ? 8 : 10}
          fontWeight={isTableHead ? "bold" : "regular"}
          uppercase={!!isTableHead}
        >
          {value}
        </Copy>
        {isTableHead && value === "Pickup date" && <IconDropArrowTop css={{ color: "$neutrals-4" }} />}
      </Flex>
    )}
  </GridItem>
)

export const CarrierOrdersTable = ({
  children,
  loadId,
  from,
  to,
  pickupDate,
  deliveryDate,
  loadDetails,
  rate,
  status,
  isTableHead,
  isPlaceholder,
}: React.PropsWithChildren<IWorkingDaysTableRowProps>) => {
  const values = [loadId, from, to, pickupDate, deliveryDate, loadDetails, rate, status]

  return (
    <Grid
      columns="0.8fr 0.9fr 0.9fr 1.5fr 1.5fr 2.5fr 1fr 2fr"
      align="center"
      css={{
        height: isTableHead ? "$64" : "$80",
        backgroundColor: "$system-white",
        borderRadius: "$8",
        marginBottom: "$12",
      }}
    >
      {isTableHead
        ? values.map((value, index) => (
            <TableCell key={index} value={value} isTableHead={isTableHead} isPlaceholder={isPlaceholder} />
          ))
        : children}
    </Grid>
  )
}
