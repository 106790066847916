import { styled } from "@/stitches/config"

export const SPre = styled("pre", {
  fontFamily: "$sans",
  fontSize: "16px",
  fontWeight: "normal",
  letterSpacing: "0.5px",
  lineHeight: 1.5,
  color: "$neutrals-5",
})
