import { useModal, useModalActions } from "@/shared/hooks"
import { ModalType, IUrgentContacts } from "@/shared/types"

import { GeneralModal } from "@/modals"
import { Copy, Flex, Spacer, Stack, Title } from "@/shared/components"
import { UrgentContactsTableRow } from "@/management/components/UrgentContactsTable/components"

export const UrgentContactsModal = ({
  currentUrgentContacts,
}: {
  currentUrgentContacts: IUrgentContacts[]
}) => {
  const { modalProps } = useModal(ModalType.ViewUrgentContacts)
  const { close } = useModalActions()

  const handleCancelClick = () => {
    close(ModalType.ExecutionStrategyModal)
  }

  return (
    <GeneralModal
      {...modalProps}
      closeButton={{ onClick: handleCancelClick }}
      onInteractOutside={handleCancelClick}
      contentCss={{
        height: 544,
        width: 430,
      }}
      containerCss={{
        maxHeight: 544,
      }}
    >
      <Stack space={12} css={{ padding: "$64 $24 $24", minHeight: 544 }}>
        <Title as="h3" scale={2} color="system-black">
          Urgent contacts
        </Title>

        <Spacer size={12} />

        {currentUrgentContacts.length > 0 ? (
          currentUrgentContacts?.map(({ id, email }) => (
            <UrgentContactsTableRow key={id} id={id} email={email} isShowMode />
          ))
        ) : (
          <Flex
            align="center"
            justify="center"
            css={{
              height: "$64",
              color: "$theme-b-n3",
            }}
          >
            <Copy color="theme-n6-n5">There is no data yet</Copy>
          </Flex>
        )}
      </Stack>
    </GeneralModal>
  )
}
