import { StateCreator } from "zustand"
import { createWithEqualityFn } from "zustand/traditional"
import { devtools, persist } from "zustand/middleware"
import { shallow } from "zustand/shallow"

import {
  FilterType,
  FlsTabType,
  OrganizationInfoTabType,
  IUser,
  initialOrganizationState,
  IOrganizationResponse,
  IFeatureFlag,
  CarrierTabType,
  IOrgProperty,
} from "@/shared/types"
import { isDev } from "@/constants"

export const initialUserData: IUser = {
  id: "",
  firstName: "",
  lastName: "",
  phone: "",
  darkTheme: false,
  username: "",
  authorities: [],
  defaultOrganizationId: "",
  urgentContact: false,
}

export const initialOrganizationData: IOrganizationResponse = {
  ...initialOrganizationState,
  label: "",
  rootOrganizationId: "",
  createdAt: "",
  updatedAt: "",
  updatedBy: "",
}

type GeneralUnion = IOrdersSlice &
  IUserSlice &
  IOrganizationSlice &
  IAuthenticationSlice &
  IOrgViewTabSlice &
  IFlsSlice &
  ICarrierSlice &
  IFeaturesFlagSlice &
  IOrgPropertiesSlice

interface IOrdersSlice {
  page: number
  filter: FilterType
  setPage: (page: number) => void
  setFilter: (filter: FilterType) => void
  orgId: string
  setOrgId: (orgId: string) => void
}

interface IUserSlice {
  user: IUser
  setUser: (user: IUser) => void
}

interface IFlsSlice {
  flsViewTab: FlsTabType
  setFlsViewTab: (flsViewTab: FlsTabType) => void
}

export interface ICarrierSlice {
  carrierDashboardToken: string
  carrierViewTab: CarrierTabType
  rejectFunction: (() => void) | null
  setCarrierDashboardToken: (carrierDashboardToken: string) => void
  setCarrierViewTab: (carrierViewTab: CarrierTabType) => void
  setRejectFunction: (rejectFunction: (() => void) | null) => void
}

interface IFeaturesFlagSlice {
  featureFlags: IFeatureFlag[]
  setFeatureFlags: (orgProperties: IFeatureFlag[]) => void
}

interface IOrgPropertiesSlice {
  orgProperties: IOrgProperty[]
  setOrgProperties: (orgProperties: IOrgProperty[]) => void
}

interface IOrgViewTabSlice {
  orgViewTab: OrganizationInfoTabType
  setOrgViewTab: (orgViewTab: OrganizationInfoTabType) => void
}
interface IAuthenticationSlice {
  accessToken: string
  refreshToken: string
  tokenLifetime: number
  setTokens: ({ accessToken }: { accessToken: string }) => void
  setTokenLifetime: ({ tokenLifetime }: { tokenLifetime: number }) => void
}

interface IOrganizationSlice {
  organization: IOrganizationResponse
  setOrganization: (organization: IOrganizationResponse) => void
}

const createOrdersSlice: StateCreator<GeneralUnion, [], [], IOrdersSlice> = (set) => ({
  page: 0,
  filter: FilterType.Error,
  setPage: (page) => set({ page }),
  setFilter: (filter) => set({ filter }),
  orgId: "",
  setOrgId: (orgId) => set({ orgId }),
})

const createOrgViewTabSlice: StateCreator<GeneralUnion, [], [], IOrgViewTabSlice> = (set) => ({
  orgViewTab: OrganizationInfoTabType.General,
  setOrgViewTab: (orgViewTab) => set({ orgViewTab }),
})

const createOrganizationSlice: StateCreator<GeneralUnion, [], [], IOrganizationSlice> = (set) => ({
  organization: initialOrganizationData,
  setOrganization: (organization) => set({ organization }),
})

const createUserSlice: StateCreator<GeneralUnion, [], [], IUserSlice> = (set) => ({
  user: initialUserData,
  setUser: (user) => set({ user }),
})

const flsTabsSlice: StateCreator<GeneralUnion, [], [], IFlsSlice> = (set) => ({
  flsViewTab: FlsTabType.Orders,
  setFlsViewTab: (flsViewTab) => set({ flsViewTab }),
})

const carrierTabsSlice: StateCreator<GeneralUnion, [], [], ICarrierSlice> = (set) => ({
  carrierDashboardToken: "",
  carrierViewTab: CarrierTabType.Pending,
  rejectFunction: null,
  setCarrierDashboardToken: (carrierDashboardToken) => set({ carrierDashboardToken }),
  setCarrierViewTab: (carrierViewTab) => set({ carrierViewTab }),
  setRejectFunction: (rejectFunction) => set({ rejectFunction })
})

const createAuthenticationSlice: StateCreator<GeneralUnion, [], [], IAuthenticationSlice> = (set) => ({
  accessToken: "",
  refreshToken: "",
  tokenLifetime: 0,
  setTokens: ({ accessToken }) => set({ accessToken }),
  setTokenLifetime: ({ tokenLifetime }) => set({ tokenLifetime }),
})

const featuresFlagList: StateCreator<GeneralUnion, [], [], IFeaturesFlagSlice> = (set) => ({
  featureFlags: [],
  setFeatureFlags: (featureFlags) => set({ featureFlags }),
})

const orgPropertiesList: StateCreator<GeneralUnion, [], [], IOrgPropertiesSlice> = (set) => ({
  orgProperties: [],
  setOrgProperties: (orgProperties) => set({ orgProperties }),
})


export const useGlobalStore = createWithEqualityFn<GeneralUnion>()(
  devtools(
    persist(
      (...a) => ({
        ...createOrdersSlice(...a),
        ...createUserSlice(...a),
        ...createOrganizationSlice(...a),
        ...createAuthenticationSlice(...a),
        ...createOrgViewTabSlice(...a),
        ...flsTabsSlice(...a),
        ...carrierTabsSlice(...a),
        ...featuresFlagList(...a),
        ...orgPropertiesList(...a)
      }),
      {
        name: "globalStore",
      },
    ),
    { name: "globalStore", enabled: isDev },
  ),
  shallow,
)
