import { styled } from "@/stitches/config"

export const SCloseToastButton = styled("button", {
  reset: true,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "$24",
  height: "$24",
  color: "$theme-b-n3",
  transition: "150ms ease-out",
  cursor: "pointer",

  hover: {
    color: "$theme-n6-n5",
  },

  keyboardFocus: {
    color: "$theme-n6-n5",
  },
})
