import { IPagination } from "@/shared/types"

interface ICustomersFilterData {
  id?: string
  value: string
}

interface ICustomersFilter {
  enabled: boolean
  filterData: ICustomersFilterData[]
}

interface ILanesFilterData {
  id?: string
  fromCity: string
  fromState: string
  toCity: string
  toState: string
}

interface ILanesFilter {
  enabled: boolean
  filterData: ILanesFilterData[]
}

interface ILanesFilterDataResponse
  extends Omit<ILanesFilterData, "fromCity" | "fromState" | "toCity" | "toState"> {
  from: string
  to: string
}

interface ILanesFilterResponse extends Omit<ILanesFilter, "filterData"> {
  filterData: ILanesFilterDataResponse[]
}

export interface IFlsFiltersStateResponse extends Omit<IFlsFiltersState, "lanesFilter"> {
  lanesFilter: ILanesFilterResponse
}

export interface IFlsFiltersState {
  customersFilter: ICustomersFilter
  lanesFilter: ILanesFilter
}

export enum FlsFilterField {
  CustomerFilter = "CustomerFilter",
  LanesFilter = "LanesFilter",
}

interface IFlsResponse {
  orderId: string
  createdAt: string
  status: string
  fromCity: string
  toCity: string
  fromState: string
  toState: string
  customerId: string
  consigneeName: string
}

export interface IAllFlsResponse extends IPagination {
  content: IFlsResponse[]
}

export enum FlsStatusType {
  FAILED_TO_CREATE_ORDER_IN_FLS = "FAILED_TO_CREATE_ORDER_IN_FLS",
  RETURNED_TO_MCLEOD = "RETURNED_TO_MCLEOD",
  PASSED_TO_APOLLO = "PASSED_TO_APOLLO",
  CREATED_IN_APOLLO = "CREATED_IN_APOLLO",
  FAILED_TO_CREATE_IN_APOLLO = "FAILED_TO_CREATE_IN_APOLLO",
  CANCELED_BECAUSE_UPDATE_FAILED = "CANCELED_BECAUSE_UPDATE_FAILED",
  CANCELED_BY_APOLLO = "CANCELED_BY_APOLLO",
  COVERED = "COVERED",
  COVERED_AND_COMPLETED = "COVERED_AND_COMPLETED",
  ON_HOLD = "ON_HOLD",
  IGNORED = "IGNORED",
  NOT_BROKERAGE = "NOT_BROKERAGE",
  RETRY_FLIPPING = "RETRY_FLIPPING",
  SEND_TO_MANUAL_PROCESSING = "SEND_TO_MANUAL_PROCESSING",
  REVENUE_CODE_WAS_CHANGED_BY_OPS = "REVENUE_CODE_WAS_CHANGED_BY_OPS",
}

export const flsStatusesText = {
  FAILED_TO_CREATE_ORDER_IN_FLS: "Failed to create in FLS",
  RETURNED_TO_MCLEOD: "Returned to TMS",
  PASSED_TO_APOLLO: "Passed to Apollo",
  CREATED_IN_APOLLO: "Created in Apollo",
  FAILED_TO_CREATE_IN_APOLLO: "Failed to create in Apollo",
  CANCELED_BECAUSE_UPDATE_FAILED: "Canceled because update failed",
  CANCELED_BY_APOLLO: "Not covered",
  COVERED: "Covered",
  COVERED_AND_COMPLETED: "Covered and completed",
  ON_HOLD: "On hold",
  IGNORED: "Ignored",
  NOT_BROKERAGE: "Not brokerage",
  RETRY_FLIPPING: "Retry flipping",
  SEND_TO_MANUAL_PROCESSING: "Sent to manual processing",
  REVENUE_CODE_WAS_CHANGED_BY_OPS: "Revenue code changed",
}

export enum FlsTabType {
  Orders = "Orders",
  Filters = "Filters",
}
