import axios from "axios"

import { CARRIERS_BASE_URI } from "@/constants"

export const carriersApi = axios.create({
  baseURL: CARRIERS_BASE_URI,
})

carriersApi.defaults.headers.common["Content-Type"] = "application/json"

export const readCarriersCoverageFileFn = async (file: File) => {
  const formData = new FormData()
  formData.append("file", file)
  formData.append("fileName", file.name)

  const { data } = await carriersApi.post<string>(
    "import/carrier-coverage-for-lane/read-from-excel-file",
    formData,
    {
      headers: {
        "content-type": file.type,
      },
    },
  )

  return data
}

export const readConvoyRatesForTopLanesFileFn = async (file: File) => {
  const formData = new FormData()
  formData.append("file", file)
  formData.append("fileName", file.name)

  const { data } = await carriersApi.post<string>("import/convoy-kma-rate/read-from-excel-file", formData, {
    headers: {
      "content-type": file.type,
    },
  })

  return data
}
