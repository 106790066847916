import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import type {} from "@mui/x-date-pickers/themeAugmentation"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import { LocalizationProvider } from "@mui/x-date-pickers"

import { dateTimeFormatInTimeZone, firstToUpperCase, dateTimeTzFormat } from "@/shared/utils"
import { FilterType, ModalType, RouteParams, IAllOrdersResponse, defaultTimezone } from "@/shared/types"
import { useGlobalStore } from "@/store"
import {
  useOrderResponseCount,
  useOrganizationById,
  useAllOrders,
  useAllOrdersByDate,
} from "@/management/hooks"
import { IconDownloadDown } from "@/shared/icons"
import { useModalActions } from "@/shared/hooks"

import { Box, Copy, Flex, OrdersSearch, Button } from "@/shared/components"
import { OrdersTablePlaceholder, Pagination, OrdersTableRow } from "@/management/components"
import { ExportOrdersModal } from "@/modals"

export const OrdersTable = () => {
  const { organizationId } = useParams<keyof RouteParams>() as RouteParams
  const { open } = useModalActions()

  const [page, setPage, orgId, setOrgId] = useGlobalStore((state) => [
    state.page,
    state.setPage,
    state.orgId,
    state.setOrgId,
  ])
  const { data: orgData, isLoading: isOrganizationLoading } = useOrganizationById(organizationId)
  const { data: allOrders, isFetching: isAllOrdersFetching } = useAllOrders({
    orgId: organizationId,
    page,
    filter: FilterType.FDS,
  })
  const [inputShipStart, setInputShipStart] = useState<Date | null>(null)
  const [inputShipEnd, setInputShipEnd] = useState<Date | null>(null)
  const [inputDeliveryStart, setInputDeliveryStart] = useState<Date | null>(null)
  const [inputDeliveryEnd, setInputDeliveryEnd] = useState<Date | null>(null)
  const [filteredOrders, setFilteredOrders] = useState<IAllOrdersResponse | null>(null)
  const { isInitialLoading: isAllFilteredOrdersByDateLoading, refetch: refetchAllFilteredOrders } =
    useAllOrdersByDate({
      orgId: organizationId,
      page,
      inputShipStart,
      inputShipEnd,
      inputDeliveryStart,
      inputDeliveryEnd,
    })

  const isFilterEnabled = inputShipStart || inputShipEnd || inputDeliveryStart || inputDeliveryEnd

  let orders = allOrders?.content ? allOrders.content : []
  if (isFilterEnabled && filteredOrders) {
    orders = filteredOrders?.content ? filteredOrders.content : []
  }
  const totalPages = isFilterEnabled && filteredOrders ? filteredOrders.totalPages : allOrders?.totalPages
  const { data: orderResponseCount, isLoading: isOrderResponseCountLoading } = useOrderResponseCount(
    organizationId,
    orders.map((i) => i.orderId).join(","),
  )
  const isLoading =
    isOrganizationLoading ||
    isAllOrdersFetching ||
    isAllFilteredOrdersByDateLoading ||
    isOrderResponseCountLoading

  useEffect(() => {
    return () => {
      setPage(0)
    }
  }, [setPage])

  useEffect(() => {
    if (!allOrders?.totalPages || allOrders?.totalPages < page) {
      setPage(0)
    }
  }, [allOrders?.totalPages, page, setPage])

  useEffect(() => {
    if (orgId !== organizationId) {
      setPage(0)
      setOrgId(organizationId)
    }
  }, [orgId, organizationId, setOrgId, setPage])

  if (isLoading || !orders) {
    return <OrdersTablePlaceholder />
  }

  const clearFilter = () => {
    setInputShipStart(null)
    setInputShipEnd(null)
    setInputDeliveryStart(null)
    setInputDeliveryEnd(null)
    setFilteredOrders(null)
    setPage(0)
  }

  const applyFilter = async () => {
    const { data } = await refetchAllFilteredOrders()
    setFilteredOrders(data ? data : null)
  }

  const theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            color: "black",
            "&:hover": {
              backgroundColor: "transparent",
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              color: "black",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            backgroundColor: "var(--colors-system-white)",
            borderRadius: "var(--radii-8)",
            "&.Mui-focused": {
              backgroundColor: "var(--colors-brand-yellow-lightest)",
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "var(--colors-brand-yellow-primary)",
              },
            },
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            lineHeight: "initial",
            "&:not(.Mui-selected)": {
              color: "var(--colors-theme-n5-n6)",
              ".has-focus &:focus": {
                backgroundColor: "var(--colors-brand-yellow-light)",
                border: "1px solid var(--colors-theme-yl-n3)",
              },
              "&:hover": {
                backgroundColor: "var(--colors-brand-yellow-light)",
                border: "none",
              },
            },
            "&:not(.Mui-disabled)": {
              backgroundColor: "var(--colors-theme-w-n8)",
              color: "var(--colors-theme-b-n3)",
            },
            "&.Mui-selected": {
              backgroundColor: "var(--colors-brand-yellow-primary)",
              "&:hover": {
                backgroundColor: "var(--colors-brand-yellow-primary)",
              },
              "&:focus": {
                backgroundColor: "var(--colors-brand-yellow-primary)",
              },
              ".has-focus &:focus": {
                backgroundColor: "var(--colors-theme-ylr-ydr)",
                border: "1px solid var(--colors-theme-yl-n3)",
              },
            },
          },
          today: {
            "&:not(.Mui-selected)": {
              border: "1px solid var(--colors-theme-b-n3)",
            },
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },

      MuiInputBase: {
        styleOverrides: {
          root: {
            height: "var(--space-48)",
          },
        },
      },

      MuiIconButton: {
        styleOverrides: {
          root: {
            color: "var(--colors-theme-b-n3)",
            borderRadius: "0",
            "&:hover": {
              backgroundColor: "transparent",
            },
            ".has-focus &:focus": {
              outline: "1px solid var(--colors-theme-yl-n3)",
            },
          },
        },
      },
    },
  })

  return (
    <>
      <Flex justify="between" css={{ marginBottom: "$32" }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider theme={theme}>
            <Flex align="center" css={{ gap: "$12" }} wrap>
              <Flex css={{ gap: "$12" }}>
                <OrdersSearch searchCss={{ height: "var(--space-48)" }} />
                <Flex>
                  <DatePicker
                    slotProps={{
                      actionBar: {
                        actions: ["clear"],
                      },
                    }}
                    onAccept={(value) => {
                      setInputShipStart(value)
                      !isFilterEnabled && setFilteredOrders(null)
                    }}
                    label="Ship date, from: "
                    value={inputShipStart}
                    onChange={(value) => {
                      if (!value) {
                        return ""
                      }
                      setInputShipStart(value)
                    }}
                    sx={{
                      ".MuiOutlinedInput-root": {
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      },
                    }}
                  />
                  <DatePicker
                    slotProps={{
                      actionBar: {
                        actions: ["clear"],
                      },
                    }}
                    onAccept={(value) => {
                      setInputShipEnd(value)
                      !isFilterEnabled && setFilteredOrders(null)
                    }}
                    label="Ship date, to: "
                    value={inputShipEnd}
                    onChange={(value) => {
                      if (!value) {
                        return ""
                      }
                      setInputShipEnd(value)
                    }}
                    sx={{
                      ".MuiOutlinedInput-root": {
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                      },
                    }}
                  />
                </Flex>
              </Flex>
              <Flex css={{ gap: "$12" }}>
                <Flex>
                  <DatePicker
                    slotProps={{
                      actionBar: {
                        actions: ["clear"],
                      },
                    }}
                    onAccept={(value) => {
                      setInputDeliveryStart(value)
                      !isFilterEnabled && setFilteredOrders(null)
                    }}
                    label="Delivery date, from: "
                    value={inputDeliveryStart}
                    onChange={(value) => {
                      if (!value) {
                        return ""
                      }
                      setInputDeliveryStart(value)
                    }}
                    sx={{
                      ".MuiOutlinedInput-root": {
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      },
                    }}
                  />
                  <DatePicker
                    slotProps={{
                      actionBar: {
                        actions: ["clear"],
                      },
                    }}
                    onAccept={(value) => {
                      setInputDeliveryEnd(value)
                      !isFilterEnabled && setFilteredOrders(null)
                    }}
                    label="Delivery date, to: "
                    value={inputDeliveryEnd}
                    onChange={(value) => {
                      if (!value) {
                        return ""
                      }
                      setInputDeliveryEnd(value)
                    }}
                    sx={{
                      ".MuiOutlinedInput-root": {
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                      },
                    }}
                  />
                </Flex>
                <Button
                  type="button"
                  action="secondary"
                  size="large"
                  onClick={() => {
                    applyFilter()
                  }}
                  css={{ width: "fit-content" }}
                >
                  <Copy as="span" fontWeight="bold" css={{ whiteSpace: "nowrap" }}>
                    Apply
                  </Copy>
                </Button>
                {isFilterEnabled ? (
                  <Button
                    action="tertiary"
                    type="button"
                    size="large"
                    onClick={() => {
                      clearFilter()
                    }}
                  >
                    Clear all filters
                  </Button>
                ) : null}
              </Flex>
            </Flex>
            <Flex align="center" css={{ marginLeft: "$12" }}>
              <Button
                size="medium"
                action="secondary"
                onClick={() => {
                  open(ModalType.ExportOrdersModal)
                }}
                icon={<IconDownloadDown />}
                css={{ backgroundColor: "$system-white" }}
              >
                <Copy as="span" fontWeight="bold" css={{ whiteSpace: "nowrap" }}>
                  Data export
                </Copy>
              </Button>
            </Flex>
          </ThemeProvider>
        </LocalizationProvider>
      </Flex>
      <Box css={{ backgroundColor: "$system-white", borderRadius: "$8" }}>
        <OrdersTableRow
          orderId="Order id"
          shipper="Shipper"
          consignee="Consignee"
          shipDate="Ship date"
          deliveryDate="Delivery date"
          createdDate="Created"
          status="Status"
          reason="Reason"
          responses="Responses"
          isTableHead
        />
        <Box
        // css={{
        //   maxHeight: "calc((var(--vh) * 100) - 254px)",
        //   overflowY: "scroll",
        //   marginRight: -15,
        // }}
        >
          {orders.length > 0 && !!orderResponseCount ? (
            orders.map(
              ({
                orderId,
                fromCity,
                fromState,
                fromAddress2,
                fromAddress1,
                fromPostal,
                toCity,
                toState,
                toAddress2,
                toAddress1,
                toPostal,
                pickupStartAt,
                dropOffStartAt,
                createdInApollo,
                orderStatus,
                completionReason,
                structuredData,
              }) => {
                const shipperAdress =
                  fromCity && fromState ? `${firstToUpperCase(fromCity)}, ${fromState}` : "-"
                const shipperFullAdress = [
                  fromAddress1,
                  fromAddress2,
                  ...(fromCity ? [firstToUpperCase(fromCity)] : []),
                  fromState,
                  fromPostal,
                ]
                  .filter(Boolean)
                  .join(", ")
                const consigneeAdress = toCity && toState ? `${firstToUpperCase(toCity)}, ${toState}` : "-"
                const consigneeFullAdress = [
                  toAddress1,
                  toAddress2,
                  ...(toCity ? [firstToUpperCase(toCity)] : []),
                  toState,
                  toPostal,
                ]
                  .filter(Boolean)
                  .join(", ")

                const timeZoneOrg = orgData?.timeZone || defaultTimezone
                const timeZoneFrom = structuredData?.fromTimeZoneId || defaultTimezone
                const timeZoneTo = structuredData?.toTimeZoneId || defaultTimezone
                const shipDate = `${dateTimeTzFormat({
                  dateTime: pickupStartAt,
                  timeZone: timeZoneFrom,
                  timeFormat: "MMM d, yyyy",
                })}`
                const shipTime = `${dateTimeTzFormat({
                  dateTime: pickupStartAt,
                  timeZone: timeZoneFrom,
                  timeFormat: "hh:mm aa (zzz)",
                })}`
                const deliveryDate = `${dateTimeTzFormat({
                  dateTime: dropOffStartAt,
                  timeZone: timeZoneTo,
                  timeFormat: "MMM d, yyyy",
                })}`
                const deliveryTime = `${dateTimeTzFormat({
                  dateTime: dropOffStartAt,
                  timeZone: timeZoneTo,
                  timeFormat: "hh:mm aa (zzz)",
                })}`
                const createdDate = `${dateTimeFormatInTimeZone({
                  dateTime: createdInApollo,
                  timeZone: timeZoneOrg,
                  format: "MMM d, yyyy",
                })}`
                const createdTime = `${dateTimeFormatInTimeZone({
                  dateTime: createdInApollo,
                  timeZone: timeZoneOrg,
                  format: "hh:mm aa (zzz)",
                })}`
                const responses = isFinite(Number(orderResponseCount?.[orderId]))
                  ? orderResponseCount?.[orderId]
                  : "-"

                return (
                  <OrdersTableRow
                    key={orderId}
                    orderId={orderId}
                    shipper={structuredData?.shipperName || "-"}
                    shipperAdress={shipperAdress}
                    shipperFullAdress={shipperFullAdress}
                    consignee={structuredData?.consigneeName || ""}
                    consigneeAdress={consigneeAdress}
                    consigneeFullAdress={consigneeFullAdress}
                    shipDate={shipDate}
                    shipTime={shipTime}
                    deliveryDate={deliveryDate}
                    deliveryTime={deliveryTime}
                    createdDate={createdDate}
                    createdTime={createdTime}
                    status={orderStatus}
                    reason={completionReason || ""}
                    responses={responses}
                    organizationId={organizationId}
                  />
                )
              },
            )
          ) : (
            <Flex
              align="center"
              justify="center"
              css={{
                height: "$64",
                color: "$theme-b-n3",
              }}
            >
              <Copy color="theme-n6-n5">{isFilterEnabled ? "No matches found" : "There is no data yet"}</Copy>
            </Flex>
          )}
        </Box>
      </Box>
      <Pagination totalPages={totalPages} currentPage={page} onPageChange={setPage} />

      <ExportOrdersModal />
    </>
  )
}
