import React, { useCallback } from "react"

import { ComponentProps } from "@/stitches/types"

import { IconMinus, IconPlus } from "@/shared/icons"

import { useAccordionContext } from "./Accordion"
import { useAccordionItemContext } from "./AccordionItem"
import { SAccordionButton, SAccordionIconBox } from "./AccordionButton.styles"

interface IAccordionButtonProps extends ComponentProps<typeof SAccordionButton> {
  /**
   * Disable the Accordion Button
   */
  disabled?: boolean
}

export const AccordionButton = ({ children, ...props }: IAccordionButtonProps) => {
  const { onItemOpen, onItemClose } = useAccordionContext("AccordionButton")
  const { id, value, open, disabled } = useAccordionItemContext("AccordionButton")

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault()
      open ? onItemClose(value) : onItemOpen(value)
    },
    [onItemClose, onItemOpen, open, value],
  )

  return (
    <SAccordionButton
      aria-controls={id}
      aria-expanded={open || false}
      disabled={disabled || false}
      type="button"
      data-ui="accordion-button"
      data-testid="accordion-button"
      {...props}
      onClick={handleClick}
    >
      <SAccordionIconBox data-ui="form-checkbox-box">
        {open ? <IconMinus size="xs" /> : <IconPlus size="xs" />}
      </SAccordionIconBox>
      {children}
    </SAccordionButton>
  )
}
