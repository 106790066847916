import { Button } from "@/shared/components"

export const CancelButton = ({
  handleClick,
  disabled,
  changesFlag,
}: {
  handleClick: () => void
  changesFlag?: boolean
  disabled?: boolean
}) => {
  return (
    <Button action="secondary" disabled={disabled} onClick={handleClick}>
      {changesFlag ? "Cancel changes" : "Cancel"}
    </Button>
  )
}

CancelButton.displayName = "CancelButton"
