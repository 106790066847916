import { memo } from "react"
import { useFormContext } from "react-hook-form"

import { WorkingTime, IOrganizationState } from "@/shared/types"

import { Box, ErrorLineWithMessage, Flex, FormCheckbox, GridItem } from "@/shared/components"
import { RemoveItemButton } from "@/management/components"

import { WorkingDaysTableRow } from "./WorkingDaysTableRow"
import { DayField } from "./DayField"
import { StartField } from "./StartField"
import { EndField } from "./EndField"

interface IWorkingDaysCreateRowProps {
  index: number
  onRemoveClick: () => void
}

export const WorkingDaysCreateRow = memo(({ index, onRemoveClick }: IWorkingDaysCreateRowProps) => {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<IOrganizationState>()
  const { workingDays } = watch()

  return (
    <WorkingDaysTableRow>
      <GridItem css={{ position: "relative", paddingX: "$24" }}>
        <DayField index={index} borderless />
        <ErrorLineWithMessage
          errorId={`errors.workingDays.${index}.day`}
          errorMessage={errors?.workingDays?.[index]?.day?.message}
        />
      </GridItem>
      <GridItem css={{ position: "relative", paddingX: "$24" }}>
        <StartField index={index} borderless />
        <ErrorLineWithMessage
          errorId={`errors.workingDays.${index}.start`}
          errorMessage={errors?.workingDays?.[index]?.start?.message}
        />
      </GridItem>
      <GridItem css={{ position: "relative", paddingX: "$24" }}>
        <EndField index={index} borderless />
        <ErrorLineWithMessage
          errorId={`errors.workingDays.${index}.start`}
          errorMessage={errors?.workingDays?.[index]?.start?.message}
        />
      </GridItem>
      <GridItem css={{ position: "relative", paddingX: "$24" }}>
        <Flex align="center" justify="between">
          <Box css={{ width: "100%" }}>
            <FormCheckbox
              id={workingDays[index].day + "fullDay"}
              name={workingDays?.[index]?.day + "fullDay"}
              onChange={() => {
                if (workingDays[index].start === WorkingTime["00:00 AM"]) {
                  setValue(`workingDays.${index}.start`, WorkingTime["07:00 AM"], { shouldDirty: true })
                  setValue(`workingDays.${index}.end`, WorkingTime["07:00 PM"], { shouldDirty: true })
                } else {
                  setValue(`workingDays.${index}.start`, WorkingTime["00:00 AM"], { shouldDirty: true })
                  setValue(`workingDays.${index}.end`, WorkingTime["11:59 PM"], { shouldDirty: true })
                }
              }}
              checked={
                workingDays[index].start === WorkingTime["00:00 AM"] &&
                workingDays[index].end === WorkingTime["11:59 PM"]
              }
              label=""
            />
          </Box>
          {workingDays.length !== 1 ? (
            <RemoveItemButton text="WorkingDays" index={index} onClick={onRemoveClick} />
          ) : null}
        </Flex>
        <ErrorLineWithMessage
          errorId={`errors.workingDays.${index}.end`}
          errorMessage={errors?.workingDays?.[index]?.end?.message}
        />
      </GridItem>
    </WorkingDaysTableRow>
  )
})

WorkingDaysCreateRow.displayName = "WorkingDaysCreateRow"
