import { useCallback, useEffect, useRef } from "react"

import { ComponentProps, ResponsiveProp } from "@/stitches/types"

import { Copy, ICopyProps, TypographyScale } from "@/shared/components"

import { useTabsContext } from "./Tabs"
import { STabListItem } from "./TabListItem.styles"

interface ITabListItemProps extends ComponentProps<typeof STabListItem> {
  /**
   * ID of the Tab List Item
   */
  id: string
  scale?: TypographyScale | ResponsiveProp<TypographyScale>
  fontWeight?: ICopyProps["fontWeight"]

  onChange?: () => void
}

export const TabListItem = ({
  id,
  children,
  scale = 8,
  fontWeight = "regular",
  onChange,
  ...props
}: ITabListItemProps) => {
  const ref = useRef<HTMLButtonElement>(null)
  const { selected, setSelected } = useTabsContext("TabListItem")

  const handleOnClick = useCallback(() => {
    setSelected(id)
    onChange && onChange()
  }, [id, setSelected, onChange])

  const isSelected = selected === id

  useEffect(() => {
    if (isSelected && ref.current) {
      ref.current.focus()
    }
  }, [isSelected])

  return (
    <STabListItem
      type="button"
      role="tab"
      aria-selected={isSelected}
      aria-controls={`panel-${id}`}
      id={`tab-${id}`}
      onClick={handleOnClick}
      ref={ref}
      {...props}
    >
      <Copy scale={scale} fontWeight={fontWeight}>
        {children}
      </Copy>
    </STabListItem>
  )
}
