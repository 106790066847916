import { Tooltip } from "@/shared/components"

export const OrdersTableTooltip = ({
  label,
  tooltip,
  children,
}: {
  label: string
  tooltip: string | React.ReactNode
  children: React.ReactNode
}) => (
  <Tooltip
    tooltip={tooltip}
    ariaLabel={`${label} tooltip`}
    withTitle={false}
    contentWidth="max-content"
    trigger={["hover"]}
    placement="top"
    contentCss={{
      width: "100%",
      padding: "$12",
      borderRadius: "$8",
    }}
    triggerCss={{
      display: "inline-grid",
      textAlign: "start",
      color: "$system-inherit",
      outline: "none",
      hover: {
        outline: "none",
        boxShadow: "none",
      },
      keyboardFocus: {
        outline: "none",
      },
    }}
    avoidTriggerFocus
  >
    {children}
  </Tooltip>
)
