import { Box, Copy } from "@/shared/components"

export const StatisticsContainer = ({
  name,
  value,
  icon,
}: {
  name: string
  value: string | React.ReactElement
  icon: React.ReactElement
}) => {
  return (
    <Box
      css={{
        width: 333,
        padding: "$24",
        border: "1px solid $theme-n3-n7",
        borderRadius: "$8",
      }}
    >
      <Box
        css={{
          width: "$48",
          height: "$48",
          padding: "$12",
          border: "1px solid $theme-n3-n7",
          borderRadius: "$4",
        }}
      >
        {icon}
      </Box>
      <Copy
        scale={2}
        fontWeight="black"
        css={{
          marginTop: "$24",
        }}
      >
        {value}
      </Copy>
      <Copy
        color="neutrals-5"
        fontWeight="bold"
        css={{
          marginTop: "$8",
        }}
      >
        {name}
      </Copy>
    </Box>
  )
}
