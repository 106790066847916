import { useNavigate } from "react-router-dom"

import { MANAGEMENT } from "@/constants"
import { OrderReasonType, OrderStatusType } from "@/shared/types"
import { transformOrderId } from "@/shared/utils"

import { Copy, Flex, Grid, GridItem, OrderStatusLabel, Redacted, Stack } from "@/shared/components"
import { IconDropArrowDown } from "@/shared/icons"

import { OrderReasonLabel } from "./OrderReasonLabel"
import { OrdersTableTooltip } from "./OrdersTableTooltip"

interface IOrderTableRowProps {
  orderId?: string
  shipper?: string
  shipperAdress?: string
  shipperFullAdress?: string
  consignee?: string
  consigneeAdress?: string
  consigneeFullAdress?: string
  shipDate?: string
  shipTime?: string
  deliveryDate?: string
  deliveryTime?: string
  createdDate?: string
  createdTime?: string
  status?: string
  reason?: string
  responses?: string
  details?: string
  organizationId?: string
  isTableHead?: boolean
  isPlaceholder?: boolean
}

export const OrdersTableRow = ({
  organizationId,
  orderId,
  shipper,
  shipperAdress,
  shipperFullAdress,
  consignee,
  consigneeAdress,
  consigneeFullAdress,
  shipDate,
  shipTime,
  deliveryDate,
  deliveryTime,
  createdDate,
  createdTime,
  status,
  reason,
  responses,
  details,
  isTableHead,
  isPlaceholder,
}: IOrderTableRowProps) => {
  const navigate = useNavigate()
  const onViewClick = (id: string) => navigate(`${MANAGEMENT}/organization/${organizationId}/order/${id}`)

  return (
    <Grid
      columns="1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"
      align="center"
      css={{
        height: isTableHead ? "$48" : "$64",
        color: "$theme-b-n3",
        borderBottom: isTableHead ? "1px solid $theme-n3-n7" : "none",
        cursor: !isPlaceholder && !isTableHead ? "pointer" : "default",
        transition: "150ms ease-out",

        lastOfType: {
          borderRadius: "0 0 $8 $8",
        },
        hover: { backgroundColor: !isPlaceholder && !isTableHead ? "$brand-yellow-lightest" : "inherit" },
        keyboardFocus: {
          backgroundColor: !isPlaceholder && !isTableHead ? "$brand-yellow-lightest" : "inherit",
        },
      }}
      tabIndex={!isPlaceholder && !isTableHead ? 0 : -1}
      onClick={() => !isPlaceholder && !isTableHead && onViewClick(orderId || "")}
    >
      <GridItem css={{ paddingX: "$24" }}>
        {isPlaceholder && !isTableHead ? (
          <Redacted height="$24" text animated />
        ) : isTableHead ? (
          <Copy scale={8} fontWeight="bold" uppercase>
            {orderId || "-"}
          </Copy>
        ) : (
          <OrdersTableTooltip
            tooltip={<Copy scale={10}> {transformOrderId(orderId, organizationId)}</Copy>}
            label="order id"
          >
            <Copy scale={10} truncate>
              {transformOrderId(orderId, organizationId)}
            </Copy>
          </OrdersTableTooltip>
        )}
      </GridItem>
      <GridItem css={{ paddingX: "$24" }}>
        {isPlaceholder && !isTableHead ? (
          <Redacted height="$24" text animated />
        ) : isTableHead ? (
          <Copy scale={8} fontWeight="bold" uppercase>
            {shipper || "-"}
          </Copy>
        ) : (
          <Stack space={0}>
            <OrdersTableTooltip tooltip={<Copy scale={10}>{shipper}</Copy>} label="shipper">
              <Copy scale={10} fontWeight="bold" truncate>
                {shipper || "-"}
              </Copy>
            </OrdersTableTooltip>

            <OrdersTableTooltip tooltip={<Copy scale={10}>{shipperFullAdress}</Copy>} label="scac">
              <Copy scale={10}>{shipperAdress || "-"}</Copy>
            </OrdersTableTooltip>
          </Stack>
        )}
      </GridItem>

      <GridItem css={{ paddingX: "$24" }}>
        {isPlaceholder && !isTableHead ? (
          <Redacted height="$24" text animated />
        ) : isTableHead ? (
          <Copy scale={8} fontWeight="bold" uppercase>
            {consignee || "-"}
          </Copy>
        ) : (
          <Stack space={0}>
            <OrdersTableTooltip tooltip={<Copy scale={10}>{consignee}</Copy>} label="consignee">
              <Copy scale={10} fontWeight="bold" truncate>
                {consignee || "-"}
              </Copy>
            </OrdersTableTooltip>

            <OrdersTableTooltip tooltip={<Copy scale={10}>{consigneeFullAdress}</Copy>} label="scac">
              <Copy scale={10}>{consigneeAdress || "-"}</Copy>
            </OrdersTableTooltip>
          </Stack>
        )}
      </GridItem>
      <GridItem css={{ paddingX: "$24" }}>
        {isPlaceholder && !isTableHead ? (
          <Redacted height="$24" text animated />
        ) : isTableHead ? (
          <Copy scale={8} fontWeight="bold" uppercase>
            {shipDate}
          </Copy>
        ) : (
          <>
            <Copy scale={10}>{shipDate}</Copy>
            <Copy scale={10}>{shipTime}</Copy>
          </>
        )}
      </GridItem>
      <GridItem css={{ paddingX: "$24" }}>
        {isPlaceholder && !isTableHead ? (
          <Redacted height="$24" text animated />
        ) : isTableHead ? (
          <Copy scale={8} fontWeight="bold" uppercase>
            {deliveryDate}
          </Copy>
        ) : (
          <>
            <Copy scale={10}>{deliveryDate}</Copy>
            <Copy scale={10}>{deliveryTime}</Copy>
          </>
        )}
      </GridItem>
      <GridItem css={{ paddingX: "$24" }}>
        {isPlaceholder && !isTableHead ? (
          <Redacted height="$24" text animated />
        ) : isTableHead ? (
          <Flex>
            <Copy scale={8} fontWeight="bold" uppercase>
              {createdDate}
            </Copy>
            <IconDropArrowDown css={{ color: "$neutrals-4" }} />
          </Flex>
        ) : (
          <>
            <Copy scale={10}>{createdDate}</Copy>
            <Copy scale={10}>{createdTime}</Copy>
          </>
        )}
      </GridItem>
      <GridItem css={{ paddingX: "$24" }}>
        {isPlaceholder && !isTableHead ? (
          <Redacted height="$24" text animated />
        ) : (
          <Copy
            scale={isTableHead ? 8 : 10}
            fontWeight={isTableHead ? "bold" : "regular"}
            uppercase={isTableHead ? true : false}
          >
            {isTableHead ? status : <OrderStatusLabel status={status as OrderStatusType} filled />}
          </Copy>
        )}
      </GridItem>
      <GridItem css={{ paddingX: "$24" }}>
        {isPlaceholder && !isTableHead ? (
          <Redacted height="$24" text animated />
        ) : isTableHead ? (
          <Copy scale={8} fontWeight="bold" uppercase>
            {reason}
          </Copy>
        ) : (
          <OrdersTableTooltip
            tooltip={<OrderReasonLabel reason={reason as OrderReasonType} />}
            label={reason as OrderReasonType}
          >
            <OrderReasonLabel reason={reason as OrderReasonType} />
          </OrdersTableTooltip>
        )}
      </GridItem>
      {details ? (
        <GridItem css={{ paddingX: "$24" }}>
          {isPlaceholder && !isTableHead ? (
            <Redacted height="$24" text animated />
          ) : isTableHead ? (
            <Copy scale={8} fontWeight="bold" uppercase>
              {details}
            </Copy>
          ) : (
            <OrdersTableTooltip tooltip={<Copy scale={10}>{details}</Copy>} label={details}>
              <Copy scale={10} fontWeight="bold" truncate>
                {details || "-"}
              </Copy>
            </OrdersTableTooltip>
          )}
        </GridItem>
      ) : (
        <GridItem css={{ paddingX: "$24" }}>
          {isPlaceholder && !isTableHead ? (
            <Redacted height="$24" text animated />
          ) : (
            <Copy
              scale={isTableHead ? 8 : 10}
              fontWeight={isTableHead ? "bold" : "regular"}
              uppercase={isTableHead ? true : false}
            >
              {responses}
            </Copy>
          )}
        </GridItem>
      )}
    </Grid>
  )
}
