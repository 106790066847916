import { Controller, FieldValues, Path, PathValue } from "react-hook-form"
import { IFormInputEnrichControllerProps } from "@/shared/types"
import { FormInput } from "@/shared/components"

export const MainController = <FieldsType extends FieldValues>({
  name,
  type,
  rules,
  control,
  register,
  trigger,
  error,
  disabled,
  placeholder,
  label,
  labelProps,
  hasdoublesuffix,
  prefix,
  suffix,
  controlledValue,
  onBlurHandler,
  controlledChange,
  onChangeHandler,
  autoComplete,
  borderless,
  isViewMode,
  autoFocus,
}: IFormInputEnrichControllerProps<FieldsType>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <FormInput
            {...register?.(field.name, {
              ...rules,
            })}
            {...field}
            value={controlledValue ? controlledValue : isViewMode && !field.value ? "-" : field.value}
            onBlur={(event) => {
              field.onChange(event?.target?.value.trim() as PathValue<FieldsType, Path<FieldsType>>)
              onBlurHandler?.()
              trigger?.(name)
            }}
            onChange={(event) => {
              field.onChange(event?.target?.value as PathValue<FieldsType, Path<FieldsType>>)
              controlledChange ? controlledChange(event) : onChangeHandler?.()
            }}
            id={name}
            label={label}
            labelProps={labelProps}
            placeholder={placeholder}
            description={label}
            type={type}
            error={error}
            disabled={disabled}
            hasdoublesuffix={hasdoublesuffix}
            prefix={prefix}
            suffix={suffix}
            borderless={borderless}
            autoComplete={autoComplete}
            isViewMode={isViewMode}
            autoFocus={autoFocus}
          />
        )
      }}
    />
  )
}
