import { memo, useRef, useState } from "react"
import { useFormContext } from "react-hook-form"

import { escapeKeyDown, spaceAndEnterKeyDown, getStopType } from "@/shared/utils"
import { IconChevronTop, IconChevronDown, IconCross, IconArrowRight } from "@/shared/icons"
import { boxShadows } from "@/stitches/utils"

import { ViewSelectedSPopoverAnchorButton } from "@/shared/styles/SPopoverAnchorButton"
import { laneValidationRule } from "@/shared/rules"
import { useGlobalStore } from "@/store"
import { IOrganizationState, IPreferredLanesValue, FilterCarrierLinesType } from "@/shared/types"

import {
  Button,
  ButtonIcon,
  Copy,
  Flex,
  Pill,
  Popover,
  PopoverAnchor,
  PopoverContent,
  Stack,
  TextFieldController,
} from "@/shared/components"

interface IPreferredLanesFilterProps {
  noLanes?: boolean
  setRequestBody: React.Dispatch<React.SetStateAction<FilterCarrierLinesType[]>>
}

export const PreferredLanesFilter = memo(({ noLanes, setRequestBody }: IPreferredLanesFilterProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [preferredLanesValues, setPreferredLanesValues] = useState<IPreferredLanesValue[]>([])
  const triggerRef = useRef<HTMLButtonElement>(null)
  const [setPage] = useGlobalStore((state) => [state.setPage])
  const isTriggerClick = (event: Event) => event.composedPath().includes(triggerRef.current as EventTarget)
  const pillRef = useRef<HTMLButtonElement>(null)

  const {
    watch,
    control,
    register,
    trigger,
    formState: { errors },
    resetField,
  } = useFormContext<IOrganizationState>()

  const { newPreferredLane } = watch()

  const handleLaneClick = () => {
    setPreferredLanesValues([
      ...preferredLanesValues,
      { ...newPreferredLane, id: new Date().getTime().toString() },
    ])
    resetField("newPreferredLane")
  }

  const handleCancelClick = (shouldClose = true) => {
    setPreferredLanesValues([])
    setRequestBody([])

    if (shouldClose) {
      setIsOpen(false)
    }
    setPage(0)
    resetField("newPreferredLane")
  }

  const handleApplyClick = () => {
    const body = preferredLanesValues.map((el) => ({
      fromStop: {
        stopType: getStopType(el.from),
        value: el.from,
      },
      toStop: {
        stopType: getStopType(el.to),
        value: el.to,
      },
    }))

    setRequestBody(body)
    setIsOpen(false)
    setPage(0)
  }

  const handleDeleteClick = (e: React.SyntheticEvent, id: string) => {
    e.stopPropagation()
    const filteredLanes = preferredLanesValues.filter((i) => i.id !== id)
    setPreferredLanesValues(filteredLanes)
    if (filteredLanes.length === 0) {
      handleCancelClick(false)
    }
  }

  const checkAddButtonDisability = (): boolean => {
    if (preferredLanesValues.length > 9) {
      return true
    }

    if (
      (!newPreferredLane.from && !newPreferredLane.to) ||
      !newPreferredLane.to ||
      !newPreferredLane.from ||
      errors?.newPreferredLane
    ) {
      return true
    }

    return false
  }

  const Lanes = ({ lane }: { lane: IPreferredLanesValue }) => (
    <Flex
      align="center"
      css={{ padding: "$4 $12", borderRadius: "$8", backgroundColor: "$brand-yellow-primary" }}
    >
      <Copy>{`${lane.from ? `${lane.from}` : ""} -> ${lane.to ? `${lane.to}` : ""}`}</Copy>
      <ButtonIcon
        type="button"
        ariaLabel={`Remove lane ${lane.id}`}
        icon={<IconCross />}
        onClick={(e) => handleDeleteClick(e, lane.id)}
        inputIcon
      />
    </Flex>
  )

  return (
    <Popover open={isOpen}>
      <PopoverAnchor asChild onKeyDown={(e: { key: string }) => escapeKeyDown(e.key) && setIsOpen(false)}>
        <ViewSelectedSPopoverAnchorButton
          ref={triggerRef}
          type="button"
          onClick={(event) => {
            if (!pillRef?.current?.contains(event?.target as Node)) {
              setIsOpen(!isOpen)
            }
          }}
          active={isOpen}
          disabled={noLanes}
          css={
            noLanes
              ? {
                  opacity: 0.3,
                  backgroundColor: "$theme-n1-n10",
                  boxShadow: "inset 0 0 0 1px var(--colors-neutrals-6)",

                  "&:hover": {
                    outline: "none",
                    boxShadow: "inset 0 0 0 1px var(--colors-neutrals-6)",
                  },
                }
              : {}
          }
        >
          <Copy fontWeight="medium">Preferred lanes</Copy>
          <Flex css={{ gap: "$12" }}>
            {preferredLanesValues.length > 0 ? (
              <Pill
                as="span"
                ref={pillRef}
                suffix={<IconCross onClick={() => handleCancelClick()} />}
                size="small"
                tabIndex={0}
                onKeyDown={(e: { key: string }) => spaceAndEnterKeyDown(e.key) && handleCancelClick}
                css={{ color: "$theme-b-n11" }}
                data-testid="carrier-filter"
              >
                {`${preferredLanesValues.length} selected`}
              </Pill>
            ) : null}

            <Flex align="center">
              {isOpen ? (
                <IconChevronTop onClick={() => setIsOpen(!isOpen)} size="xs" />
              ) : (
                <IconChevronDown onClick={() => setIsOpen(!isOpen)} size="xs" />
              )}
            </Flex>
          </Flex>
        </ViewSelectedSPopoverAnchorButton>
      </PopoverAnchor>
      <PopoverContent
        close={() => setIsOpen(false)}
        align="start"
        css={{
          width: 780,
          borderRadius: "$8",
          keyboardFocus: {
            outline: "1px solid $theme-vl-n3",
          },
        }}
        alignOffset={-1}
        onInteractOutside={(event) => {
          if (isTriggerClick(event)) {
            return
          }
          return setIsOpen(false)
        }}
        onOpenAutoFocus={(event) => {
          event.preventDefault()
        }}
      >
        <Stack space={32} css={{ padding: "$12 $12 $24" }}>
          <Flex css={{ gap: "$8" }}>
            <Flex
              css={{
                width: "100%",
                height: "$40",
                alignItems: "center",
                backgroundColor: "$theme-w-n9",
                boxShadow: boxShadows.input.initial,
                borderRadius: "$8",
                padding: "0 $12",
              }}
            >
              <TextFieldController<IOrganizationState>
                name="newPreferredLane.from"
                control={control}
                register={register}
                trigger={trigger}
                rules={laneValidationRule()}
                placeholder="City and state / state / zip / KMA / Any"
                error={errors.newPreferredLane?.from?.message}
                borderless
              />
            </Flex>

            <Flex align="center">
              <IconArrowRight />
            </Flex>

            <Flex
              css={{
                width: "100%",
                height: "$40",
                alignItems: "center",
                backgroundColor: "$theme-w-n9",
                boxShadow: boxShadows.input.initial,
                borderRadius: "$8",
                padding: "0 $12",
              }}
            >
              <TextFieldController<IOrganizationState>
                name="newPreferredLane.to"
                control={control}
                register={register}
                trigger={trigger}
                rules={laneValidationRule()}
                placeholder="City and state / state / zip / KMA / Any"
                error={errors.newPreferredLane?.to?.message}
                borderless
              />
            </Flex>
            <Button
              action="secondary"
              type="button"
              size="medium"
              disabled={checkAddButtonDisability()}
              onClick={() => handleLaneClick()}
              css={{ flex: "none" }}
            >
              Add
            </Button>
          </Flex>
          <Flex wrap="true" css={{ gap: "$12" }}>
            {preferredLanesValues.map((el) => (
              <Lanes key={el.id} lane={el} />
            ))}
          </Flex>
          <Flex css={{ gap: "$12" }} justify="end">
            <Button
              action="primary"
              type="button"
              size="medium"
              disabled={preferredLanesValues.length === 0}
              onClick={() => handleApplyClick()}
            >
              Apply
            </Button>
            <Button action="secondary" type="button" size="medium" onClick={() => handleCancelClick()}>
              Cancel
            </Button>
          </Flex>
        </Stack>
      </PopoverContent>
    </Popover>
  )
})

PreferredLanesFilter.displayName = "PreferredLanesFilter"
