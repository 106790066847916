import { memo, useState } from "react"
import { useParams } from "react-router-dom"
import sub from "date-fns/sub"

import {
  FilterCarrierLinesType,
  RouteParams,
  TimeFilter,
  RfqChartType,
  LineChartTypes,
  ICarrierStats,
  IGroupedCarrierStats,
} from "@/shared/types"
import { useCarrierStats, useGroupedCarrierStats } from "@/management/hooks"
import { dateTimeFormat } from "@/shared/utils"

import {
  Box,
  ButtonIcon,
  Copy,
  Dropdown,
  DropdownItem,
  Flex,
  FormSelect,
  Grid,
  GridItem,
  Spacer,
} from "@/shared/components"
import { IconAnalytics, IconChevronDown, IconChevronTop, IconDashboard } from "@/shared/icons"

import {
  CarrierInfoCard,
  CarrierInfoCardPlaceholder,
  CarrierSelect,
  LineChart,
  LineChartPlaceholder,
  Result,
} from "./components"
import { PreferredLanesFilter } from "@/management/components"

const formatData = (
  data: IGroupedCarrierStats[],
  type: LineChartTypes,
  timeFilter: TimeFilter,
  carriersFilter: ICarrierStats[],
): Result => {
  const result: Result = {}

  data.forEach((item) => {
    item.carrierStatsDtos.forEach((carrier) => {
      const {
        carrierId,
        carrierName,
        acceptedTenders,
        offeredTenders,
        acceptedRfqs,
        rejectedTenders,
        wonRfqs,
        offeredRfqs,
        communicationType,
      } = carrier

      if (!carriersFilter.some((i) => i.carrierId === carrierId)) {
        return
      }

      if (!result[carrierId.toString()]) {
        result[carrierId.toString()] = {
          carrierName,
          communicationType,
          labels: [],
          data: [],
          originalData: [],
        }
      }

      switch (type) {
        case LineChartTypes.TENDERS:
          result[carrierId.toString()].data.push(
            Number(offeredTenders) > 0
              ? parseFloat(((Number(acceptedTenders) / Number(offeredTenders)) * 100).toFixed(1))
              : 0,
          )
          result[carrierId.toString()].labels.push(
            timeFilter === TimeFilter.Last7
              ? `${dateTimeFormat(item.dateFrom, "MMM d")}`
              : `${dateTimeFormat(item.dateFrom, "MMM d")} - ${dateTimeFormat(item.dateTo, "MMM d")}`,
          )
          result[carrierId.toString()].originalData.push({
            offered: Number(offeredTenders),
            accepted: Number(acceptedTenders),
            rejectedWon: Number(rejectedTenders),
          })

          break
        case LineChartTypes.RESPONDED_RFQS:
          result[carrierId.toString()].data.push(
            Number(offeredRfqs) > 0
              ? parseFloat(((Number(acceptedRfqs) / Number(offeredRfqs)) * 100).toFixed(1))
              : 0,
          )
          result[carrierId.toString()].labels.push(
            timeFilter === TimeFilter.Last7
              ? `${dateTimeFormat(item.dateFrom, "MMM d")}`
              : `${dateTimeFormat(item.dateFrom, "MMM d")} - ${dateTimeFormat(item.dateTo, "MMM d")}`,
          )
          result[carrierId.toString()].originalData.push({
            offered: Number(offeredRfqs),
            accepted: Number(acceptedRfqs),
            rejectedWon: Number(wonRfqs),
          })

          break
        case LineChartTypes.WON_RFQS:
          result[carrierId.toString()].data.push(
            Number(acceptedRfqs) > 0
              ? parseFloat(((Number(wonRfqs) / Number(acceptedRfqs)) * 100).toFixed(1))
              : 0,
          )
          result[carrierId.toString()].labels.push(
            timeFilter === TimeFilter.Last7
              ? `${dateTimeFormat(item.dateFrom, "MMM d")}`
              : `${dateTimeFormat(item.dateFrom, "MMM d")} - ${dateTimeFormat(item.dateTo, "MMM d")}`,
          )
          result[carrierId.toString()].originalData.push({
            offered: Number(offeredRfqs),
            accepted: Number(acceptedRfqs),
            rejectedWon: Number(wonRfqs),
          })
          break
      }
    })
  })
  return result
}

const timeFilterList: TimeFilter[] = Object.values(TimeFilter)
const rfqChartTypeList: RfqChartType[] = Object.values(RfqChartType)

export const CarrierAnalytics = memo(() => {
  const { organizationId } = useParams<keyof RouteParams>() as RouteParams
  const [timeFilter, setTimeFilter] = useState(TimeFilter.Last7)
  const [rfqChartType, setRfqChartType] = useState(RfqChartType.Responses)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isCardsView, setIsCardsView] = useState(true)
  const [carriersIds, setCarriersIds] = useState<string[] | null>(null)
  const [requestBody, setRequestBody] = useState<FilterCarrierLinesType[]>([])

  const { data: carrierStats, fetchStatus: carrierStatsFetchStatus } = useCarrierStats(
    organizationId,
    sub(new Date(), { days: Number(timeFilter) - 1 })
      .toJSON()
      .split("T")[0] + "T00:00:00",
    isCardsView,
    carriersIds ? carriersIds : [],
  )
  const { data: groupedCarrierStats = [], fetchStatus: groupedCarrierStatsFetchStatus } =
    useGroupedCarrierStats(
      organizationId,
      sub(new Date(), { days: Number(timeFilter) - 1 })
        .toJSON()
        .split("T")[0] + "T00:00:00",
      timeFilter === TimeFilter.Last7 ? "7" : "10",
      !isCardsView,
      carriersIds ? carriersIds : [],
    )

  return (
    <>
      <Flex justify="between">
        <Flex css={{ gap: "$12" }}>
          <Box css={{ width: 170 }}>
            <FormSelect
              id="timeFilter"
              name="timeFilter"
              label="Time filter"
              labelProps={{ hidden: true }}
              isDisplayValueDifferent
              value={{
                value: timeFilter,
                label: `Last ${timeFilter} days`,
              }}
              options={timeFilterList.map((timeFilter) => ({
                value: timeFilter,
                label: `Last ${timeFilter} days`,
              }))}
              onValueChange={(value) => {
                setTimeFilter(value as TimeFilter)
              }}
              generalCss={{
                padding: "var(--space-0) var(--space-0) var(--space-0) var(--space-16)",
                minHeight: "var(--space-40)",
                width: "var(--space-40)",
              }}
            />
          </Box>
          <CarrierSelect
            carrierStats={carrierStats || []}
            laneData={requestBody || []}
            carriersIds={carriersIds}
            setCarriersIds={setCarriersIds}
          />
          <PreferredLanesFilter {...{ setRequestBody }} />
        </Flex>
        <Dropdown
          asChild
          trigger={
            <ButtonIcon
              type="button"
              ariaLabel="Select view"
              icon={
                <>
                  {isCardsView ? <IconDashboard /> : <IconAnalytics />}
                  <Spacer size={4} horizontal />
                  {isDropdownOpen ? <IconChevronTop size="xs" /> : <IconChevronDown size="xs" />}
                </>
              }
              inputIcon
              css={{ width: "auto", height: "auto" }}
            />
          }
          open={isDropdownOpen}
          onOpenChange={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <DropdownItem key="Graphs" label="Graphs" onSelect={() => setIsCardsView(false)}>
            <IconAnalytics fixedSize width={20} height={20} />
          </DropdownItem>
          <DropdownItem key="Cards" label="Cards" onSelect={() => setIsCardsView(true)}>
            <IconDashboard fixedSize width={20} height={20} />
          </DropdownItem>
        </Dropdown>
      </Flex>
      <Spacer size={24} />
      {isCardsView ? (
        <Grid columns="1fr 1fr 1fr 1fr 1fr" gap={16}>
          {carrierStatsFetchStatus === "fetching" || !carrierStats ? (
            Array.from(new Array(5), (_, index) => index).map((v) => <CarrierInfoCardPlaceholder key={v} />)
          ) : carrierStats.length > 0 && carriersIds && carriersIds.length > 0 ? (
            carrierStats.map((el) => (
              <GridItem
                key={el.carrierId}
                css={{
                  border: "1px solid $theme-n3-n7",
                  backgroundColor: "$system-white",
                  borderRadius: "$8",
                }}
              >
                <CarrierInfoCard carrierStats={el as unknown as ICarrierStats} />
              </GridItem>
            ))
          ) : (
            <Box css={{ height: "$96" }}>
              <Copy>{!carrierStats.length ? "There is no data" : "No matches found"}</Copy>
            </Box>
          )}
        </Grid>
      ) : (
        <Grid columns="1fr 1fr" columnGap={24}>
          <GridItem
            css={{
              padding: "$40",
              backgroundColor: "$system-white",
              border: "1px solid $theme-n3-n7",
              borderRadius: "$8",
            }}
          >
            <Copy scale={8} color="theme-b-n3" fontWeight="bold" css={{ height: "$40" }}>
              % of accepted tenders out of offered
            </Copy>
            <Spacer size={16} />
            <Copy scale={10} color="theme-b-n3">
              (% is calculated individually for each carrier based on the number of tenders offered to certain
              carriers)
            </Copy>
            {groupedCarrierStatsFetchStatus === "fetching" ? (
              <LineChartPlaceholder
                chartType={
                  rfqChartType === RfqChartType.Responses
                    ? LineChartTypes.RESPONDED_RFQS
                    : LineChartTypes.WON_RFQS
                }
              />
            ) : carrierStats?.length ? (
              <LineChart
                chartType={LineChartTypes.TENDERS}
                values={formatData(groupedCarrierStats, LineChartTypes.TENDERS, timeFilter, carrierStats)}
                timeFilter={timeFilter}
              />
            ) : (
              <Box css={{ padding: "$12", display: "flex", justifyContent: "center" }}>
                <Copy>{!carrierStats?.length ? "There is no data" : "No matches found"}</Copy>
              </Box>
            )}
          </GridItem>
          <GridItem
            css={{
              padding: "$40",
              backgroundColor: "$system-white",
              border: "1px solid $theme-n3-n7",
              borderRadius: "$8",
            }}
          >
            <Flex align="start" justify="between" css={{ gap: "$16" }}>
              <Copy scale={8} color="theme-b-n3" fontWeight="bold">
                {rfqChartType === RfqChartType.Responses
                  ? "% of responded RFQs out of offered"
                  : "% of won RFQs out of responded"}
              </Copy>
              <FormSelect
                id="rfqChartType"
                name="rfqChartType"
                label="rfqChartType"
                labelProps={{ hidden: true }}
                isDisplayValueDifferent
                value={{
                  value: rfqChartType,
                  label: `Control type: ${rfqChartType}`,
                }}
                options={rfqChartTypeList.map((type) => ({
                  value: type,
                  label: type,
                }))}
                onValueChange={(value) => {
                  setRfqChartType(value as RfqChartType)
                }}
                generalCss={{
                  padding: "var(--space-0) var(--space-0) var(--space-0) var(--space-16)",
                  minHeight: "var(--space-40)",
                  width: "var(--space-40)",
                }}
              />
            </Flex>
            <Spacer size={16} />
            <Copy scale={10} color="theme-b-n3">
              {rfqChartType === RfqChartType.Responses
                ? "(% is calculated individually for each carrier based on the number of RFQs offered to certain carriers)"
                : "(% is calculated individually for each carrier based on the number of responded RFQs by certain carriers)"}
            </Copy>
            {groupedCarrierStatsFetchStatus === "fetching" ? (
              <LineChartPlaceholder
                chartType={
                  rfqChartType === RfqChartType.Responses
                    ? LineChartTypes.RESPONDED_RFQS
                    : LineChartTypes.WON_RFQS
                }
              />
            ) : carrierStats?.length ? (
              <LineChart
                chartType={
                  rfqChartType === RfqChartType.Responses
                    ? LineChartTypes.RESPONDED_RFQS
                    : LineChartTypes.WON_RFQS
                }
                values={formatData(
                  groupedCarrierStats,
                  rfqChartType === RfqChartType.Responses
                    ? LineChartTypes.RESPONDED_RFQS
                    : LineChartTypes.WON_RFQS,
                  timeFilter,
                  carrierStats,
                )}
                timeFilter={timeFilter}
              />
            ) : (
              <Box css={{ padding: "$12", display: "flex", justifyContent: "center" }}>
                <Copy>{!carrierStats?.length ? "There is no data" : "No matches found"}</Copy>
              </Box>
            )}
          </GridItem>
        </Grid>
      )}
    </>
  )
})

CarrierAnalytics.displayName = "CarrierAnalytics"
