import { useParams } from "react-router-dom"
import { useFormContext } from "react-hook-form"

import { useModalActions } from "@/shared/hooks"
import { ModalType, RouteParams, SlaField, IOrganizationState } from "@/shared/types"
import { findSlaAdditionalItem, slaFieldToText, spaceAndEnterKeyDown } from "@/shared/utils"
import { useOrganizationSla } from "@/management/hooks"

import {
  Accordion,
  AccordionButton,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Copy,
  Spacer,
  Stack,
  Tooltip,
} from "@/shared/components"
import { IconSettings } from "@/shared/icons"
import { SlaContent } from "@/management/components"
import { SlaTypeModal } from "@/modals"

export const SlaSettings = () => {
  const { organizationId } = useParams<keyof RouteParams>() as RouteParams
  const { open } = useModalActions()
  const { watch } = useFormContext<IOrganizationState>()
  const { sla } = watch()
  const slaKeysArr = sla.map((i) => i.name)
  const { fetchStatus } = useOrganizationSla(organizationId)

  return (
    <Box
      css={{ backgroundColor: "$system-white", padding: "$40 $56", borderRadius: "$8", marginBottom: "$96" }}
    >
      <Tooltip
        tooltip={
          <Stack space={12}>
            <Copy scale={10} color="theme-b-n3" fontWeight="bold">
              Welcome to SLA Settings
            </Copy>
            <Copy scale={10} color="neutrals-7">
              To get started with SLA, please select the criteria that all carriers must meet.
            </Copy>
            <Copy scale={10} color="neutrals-7">
              Carriers who do not meet your SLA will be filtered out of requests for spot quotes.
            </Copy>
          </Stack>
        }
        ariaLabel="View settings button tooltip"
        withTitle={false}
        contentWidth="max-content"
        trigger={["hover"]}
        delayShow={2000}
        placement="top"
        contentCss={{
          width: 320,
          padding: "$20 $16",
          borderRadius: "$8",
        }}
        triggerCss={{
          display: "inline-grid",
          textAlign: "start",
          color: "$system-inherit",
          borderRadius: "$8",
          outline: "none",

          hover: {
            outline: "none",
            boxShadow: "none",
          },
          keyboardFocus: {
            outline: "none",
            "> span": {
              borderColor: "$brand-yellow-primary",
              outline: "2px solid $brand-yellow-primary",
            },
          },
        }}
        onKeyDown={(e) => {
          if (spaceAndEnterKeyDown(e.key)) {
            e.preventDefault()
            e.stopPropagation()
            open(ModalType.SlaTypeModal)
          }
        }}
      >
        <Button
          as="span"
          action="secondary"
          type="button"
          ariaLabel="View settings button"
          size="medium"
          icon={<IconSettings />}
          onClick={() => open(ModalType.SlaTypeModal)}
        >
          View settings
        </Button>
      </Tooltip>

      <Spacer size={32} />

      {fetchStatus !== "fetching" && sla.length > 0 ? (
        <Accordion multiple>
          {findSlaAdditionalItem(slaKeysArr) || slaKeysArr.includes(SlaField.ExcludedCarriers) ? (
            <AccordionItem value={SlaField.ExcludedCarriers}>
              <AccordionHeader>
                <AccordionButton>{slaFieldToText(SlaField.ExcludedCarriers)}</AccordionButton>
              </AccordionHeader>
              <AccordionPanel>
                <SlaContent name={findSlaAdditionalItem(slaKeysArr) || SlaField.ExcludedCarriers} />
              </AccordionPanel>
            </AccordionItem>
          ) : null}
          {slaKeysArr.includes(SlaField.Delivery) ? (
            <AccordionItem value={SlaField.Delivery}>
              <AccordionHeader>
                <AccordionButton>{slaFieldToText(SlaField.Delivery)}</AccordionButton>
              </AccordionHeader>
              <AccordionPanel>
                <SlaContent name={SlaField.Delivery} />
              </AccordionPanel>
            </AccordionItem>
          ) : null}
          {slaKeysArr.includes(SlaField.Tender) ? (
            <AccordionItem value={SlaField.Tender}>
              <AccordionHeader>
                <AccordionButton>{slaFieldToText(SlaField.Tender)}</AccordionButton>
              </AccordionHeader>
              <AccordionPanel>
                <SlaContent name={SlaField.Tender} />
              </AccordionPanel>
            </AccordionItem>
          ) : null}
          {slaKeysArr.includes(SlaField.Tracking) ? (
            <AccordionItem value={SlaField.Tracking}>
              <AccordionHeader>
                <AccordionButton>{slaFieldToText(SlaField.Tracking)}</AccordionButton>
              </AccordionHeader>
              <AccordionPanel>
                <SlaContent name={SlaField.Tracking} />
              </AccordionPanel>
            </AccordionItem>
          ) : null}
          {slaKeysArr.includes(SlaField.Unloading) ? (
            <AccordionItem value={SlaField.Unloading}>
              <AccordionHeader>
                <AccordionButton>{slaFieldToText(SlaField.Unloading)}</AccordionButton>
              </AccordionHeader>
              <AccordionPanel>
                <SlaContent name={SlaField.Unloading} />
              </AccordionPanel>
            </AccordionItem>
          ) : null}
          {slaKeysArr.includes(SlaField.Cargo) ? (
            <AccordionItem value={SlaField.Cargo}>
              <AccordionHeader>
                <AccordionButton>{slaFieldToText(SlaField.Cargo)}</AccordionButton>
              </AccordionHeader>
              <AccordionPanel>
                <SlaContent name={SlaField.Cargo} />
              </AccordionPanel>
            </AccordionItem>
          ) : null}
          {slaKeysArr.includes(SlaField.Haul) ? (
            <AccordionItem value={SlaField.Haul}>
              <AccordionHeader>
                <AccordionButton>{slaFieldToText(SlaField.Haul)}</AccordionButton>
              </AccordionHeader>
              <AccordionPanel>
                <SlaContent name={SlaField.Haul} />
              </AccordionPanel>
            </AccordionItem>
          ) : null}
          {slaKeysArr.includes(SlaField.Brokers) ? (
            <AccordionItem value={SlaField.Brokers}>
              <AccordionHeader>
                <AccordionButton>{slaFieldToText(SlaField.Brokers)}</AccordionButton>
              </AccordionHeader>
              <AccordionPanel>
                <SlaContent name={SlaField.Brokers} />
              </AccordionPanel>
            </AccordionItem>
          ) : null}
        </Accordion>
      ) : (
        <Copy>Please select SLAs inside the settings</Copy>
      )}
      <SlaTypeModal />
    </Box>
  )
}
