import { useQuery } from "@tanstack/react-query"
import { isAxiosError } from "axios"

import { checkTaskStatusFn } from "@/api/tasksApi"
import { showToast } from "@/shared/utils"

export const useCheckTaskStatus = (taskName: string) => {
  const isEnabled = !!taskName

  return useQuery(["checkTask", taskName], () => checkTaskStatusFn(taskName), {
    staleTime: 1 * 60 * 1000,
    cacheTime: 1 * 60 * 1000,
    refetchInterval: 1 * 60 * 1000,
    enabled: isEnabled,
    onError: (error) => {
      if (isAxiosError(error)) {
        showToast({
          type: "error",
          text: error.response?.data.errorMessage || error.message,
          autoClose: false,
        })
      }
    },
  })
}
