import axios from "axios"

import { TENDER_BASE_URI } from "@/constants"
import {
  ICarrierStats,
  IOrderQuote,
  IOrderResponseCount,
  IOrderStrategy,
  IOrdersSankeyGraphResponse,
  IGroupedCarrierStats,
  SankeyNodeKey,
  ICarrierOrderStrategyResponse,
  IDashboardAmountNumbers,
  IQuotesResponse,
  CarrierTabType,
  IForceTenderDto, 
  IForceTenderToAnyDto,
} from "@/shared/types"
import { buildUrlString } from "@/shared/utils"

import { getOrdersByIdsFn } from "./ordersApi"

export const tenderApi = axios.create({
  baseURL: TENDER_BASE_URI,
})

tenderApi.defaults.headers.common["Content-Type"] = "application/json"

export const getQuotesByOrderIdFn = async (orderId: string) => {
  const { data } = await tenderApi.get<IOrderQuote[]>(`quotes/order/${orderId}`)
  return data
}

export const getStrategyByOrderIdFn = async (orderId: string) => {
  const { data } = await tenderApi.get<IOrderStrategy>(`order-tender-strategies/current/state/${orderId}`)
  return data
}

export const getOrderResponseCountFn = async (orgId: string, orderIds: string) => {
  const { data } = await tenderApi.get<IOrderResponseCount>(
    `quotes/order-quote-response-count/${orgId}?orderIds=${orderIds}`,
  )
  return data.responsesMap
}

export const getOrdersSankeyByOrgIdFn = async (orgId: string, from: string) => {
  const { data } = await tenderApi.get<IOrdersSankeyGraphResponse>(
    `order-tender-strategies/sankey/${orgId}?from=${from}`,
  )

  return data
}

export const getCarrierStatsByOrgIdFn = async (orgId: string, from: string, ids?: string[]) => {
  const queryParams: { [key: string]: string | undefined } = {
    from,
  }

  if (ids && ids.length && ids.length !== 10) {
    queryParams.ids = ids.join(",")
  } else {
    queryParams.size = "10"
    queryParams.top = "WON"
  }

  const baseUrl = `quotes/carrier-stats/${orgId}`
  const url = buildUrlString(baseUrl, queryParams)

  const { data } = await tenderApi.get<ICarrierStats[]>(url)

  return data
}

export const getGroupedCarrierStatsByOrgIdFn = async (
  orgId: string,
  from: string,
  groups: string,
  ids?: string[],
) => {
  const queryParams: { [key: string]: string | undefined } = {
    from,
    groups,
  }

  if (ids && ids.length > 0) {
    queryParams.ids = ids.join(",")
  } else {
    queryParams.size = "10"
    queryParams.top = "WON"
  }

  const baseUrl = `quotes/carrier-stats-graph/${orgId}`
  const url = buildUrlString(baseUrl, queryParams)

  const { data } = await tenderApi.get<IGroupedCarrierStats[]>(url)

  return data
}

export const getTenderOrdersFn = async ({
  orgId,
  strategyType,
  from,
  page = 0,
  size = 10,
}: {
  orgId: string
  strategyType: SankeyNodeKey
  from: string
  page: number
  size?: number
}) => {
  const queryParams = {
    orgId,
    strategyType,
  }

  const baseUrl = "order-tender-strategies/order-ids/non-covered"
  const url = buildUrlString(baseUrl, queryParams)

  const { data: orderIds } = await tenderApi.get(url)

  return await getOrdersByIdsFn({ orgId, orderIds, from, page, size })
}

export const getCarrierOrdersStrategyFn = async ({ orgId }: { orgId: string }) => {
  const queryParams = {
    orgId,
  }

  const baseUrl = "orders/carrier"
  const url = buildUrlString(baseUrl, queryParams)

  const { data } = await tenderApi.get<ICarrierOrderStrategyResponse>(url)

  return data
}

export const getAmountValues = async (token: string) => {
  const { data } = await tenderApi.get<IDashboardAmountNumbers>(`quotes/carrier-dashboard-state-count`, {
    headers: { "CARRIER-DASHBOARD-TOKEN": token },
  })

  return data
}

export const getCarrierDashboardQuotes = async (token: string, tab: CarrierTabType) => {
  const { data } = await tenderApi.get<IQuotesResponse[]>(
    `quotes/carrier-dashboard?state=${tab.toUpperCase()}`,
    { headers: { "CARRIER-DASHBOARD-TOKEN": token } },
  )

  return data
}

export const forceTenderSubmitFn = async (forceTender: IForceTenderDto) => {
  const { data } = await tenderApi.post("quotes/force-submit", forceTender)
  return data
}

export const forceTenderSubmitToAnyFn = async (forceTender: IForceTenderToAnyDto) => {
  const { data } = await tenderApi.post("quotes/force-tender-to-any", forceTender)
  return data
}