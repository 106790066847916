import { Copy, Grid, GridItem, Redacted } from "@/shared/components"

interface IContactsTableRowProps {
  name?: string
  email?: string
  urgentContact?: string | boolean
  isTableHead?: boolean
  isPlaceholder?: boolean
  isViewMode?: boolean
}

const renderCellContent = (
  content?: string | boolean,
  isPlaceholder?: boolean,
  isTableHead?: boolean,
  placeholderWidth?: string,
  placeholderHeight = "$24",
) => {
  return isPlaceholder && !isTableHead ? (
    <Redacted height={placeholderHeight} width={placeholderWidth} text animated />
  ) : (
    <Copy
      scale={isTableHead ? 8 : 10}
      fontWeight={isTableHead ? "bold" : "regular"}
      uppercase={!!isTableHead}
    >
      {content}
    </Copy>
  )
}

export const ContactsTableRow: React.FC<React.PropsWithChildren<IContactsTableRowProps>> = ({
  children,
  name,
  email,
  urgentContact,
  isTableHead,
  isPlaceholder,
}) => {
  return (
    <Grid
      columns="1fr 1fr 1fr"
      align="center"
      css={{
        height: isTableHead ? "$48" : "$64",
        color: "$theme-b-n3",
        borderBottom: isTableHead ? "1px solid $theme-n3-n7" : "none",
      }}
      tabIndex={!isPlaceholder && !isTableHead ? 0 : -1}
    >
      {isTableHead ? (
        <>
          <GridItem css={{ paddingX: "$24" }}>
            {renderCellContent(name, isPlaceholder, isTableHead, "100px")}
          </GridItem>
          <GridItem css={{ paddingX: "$24" }}>
            {renderCellContent(email, isPlaceholder, isTableHead, "140px")}
          </GridItem>
          <GridItem css={{ paddingX: "$24" }}>
            {renderCellContent(urgentContact, isPlaceholder, isTableHead, "100px")}
          </GridItem>
        </>
      ) : (
        children
      )}
    </Grid>
  )
}
