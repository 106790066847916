import { memo, useRef, useState } from "react"
import { isAxiosError } from "axios"
import { useNavigate, useParams } from "react-router-dom"
import { useDebounce, useDebouncedCallback } from "use-debounce"

import { CSS } from "@/stitches/config"
import { useElementDimensions } from "@/shared/hooks"
import { useOrdersByPartOfId } from "@/shared/data"
import { spaceAndEnterKeyDown, transformOrderId } from "@/shared/utils"
import { MANAGEMENT } from "@/constants"
import { IconArrowRight, IconCross, IconSearch } from "@/shared/icons"
import { RouteParams, IOrderResponse } from "@/shared/types"

import {
  Box,
  ButtonIcon,
  Copy,
  Flex,
  FormInput,
  Popover,
  PopoverAnchor,
  PopoverContent,
  Spacer,
} from "@/shared/components"
import { IllustrationSpinner } from "@/shared/illustrations"

export const OrdersSearch = memo(({ searchCss }: { searchCss?: CSS }) => {
  const navigate = useNavigate()
  const { organizationId } = useParams<keyof RouteParams>() as RouteParams
  const containerRef = useRef<HTMLDivElement>(null)
  const { dimensions } = useElementDimensions(containerRef)
  const triggerRef = useRef<HTMLInputElement>(null)
  const isTriggerClick = (event: Event) => event.composedPath().includes(triggerRef.current as EventTarget)
  const clearButtonRef = useRef<HTMLButtonElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [inputValue, setInputValue] = useState<string>("")

  const [keyword] = useDebounce(inputValue.trim(), 300)
  const debouncedSetIsOpen = useDebouncedCallback((v) => setIsOpen(v), 300)

  const { data = [], fetchStatus, error } = useOrdersByPartOfId(organizationId, keyword)

  const handleClearButton = () => {
    setInputValue("")
    setIsOpen(false)
  }

  const Content = () => {
    if (fetchStatus === "idle" && !data) {
      return null
    }

    if (fetchStatus === "fetching") {
      return (
        <Flex align="center" css={{ padding: "$20" }}>
          <IllustrationSpinner />
        </Flex>
      )
    }

    if (isAxiosError(error)) {
      return (
        <Flex css={{ padding: "$16" }}>
          <Copy color="theme-b-n3">{error.response?.data.errorMessage || error.message}</Copy>
        </Flex>
      )
    }

    if (data.length === 0) {
      return (
        <Flex css={{ padding: "$16" }}>
          <Copy color="theme-b-n3">Not found</Copy>
        </Flex>
      )
    }

    return (
      <>
        {data.map((order: IOrderResponse) => {
          return (
            <Box
              key={`${order.orderId}`}
              onClick={() => navigate(`${MANAGEMENT}/organization/${organizationId}/order/${order.orderId}`)}
              tabIndex={0}
              onKeyDown={(e) => {
                spaceAndEnterKeyDown(e.key) &&
                  navigate(`${MANAGEMENT}/organization/${organizationId}/order/${order.orderId}`)
              }}
              css={{
                padding: "$12 $16",
                color: "$theme-b-n3",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                cursor: "pointer",
                hover: {
                  backgroundColor: "$brand-yellow-lightest",
                },

                keyboardFocus: {
                  backgroundColor: "$brand-yellow-lightest",
                },
              }}
            >
              <Copy fontWeight="bold">{transformOrderId(order.orderId, organizationId)}</Copy>
              <Spacer size={4} />
              <Flex align="center">
                <Copy scale={10} truncate>{`${order.fromCity}, ${order.fromState}`}</Copy>
                <Flex css={{ paddingX: "$8" }}>
                  <IconArrowRight />
                </Flex>
                <Copy scale={10} truncate>{`${order.toCity}, ${order.toState}`}</Copy>
              </Flex>
            </Box>
          )
        })}
      </>
    )
  }

  return (
    <Popover open={isOpen}>
      <PopoverAnchor asChild>
        <Flex align="center" css={{ position: "relative" }} ref={containerRef}>
          <FormInput
            ref={triggerRef}
            id="globalSearch"
            value={inputValue}
            label="Search"
            labelProps={{ hidden: true }}
            placeholder="Search by order ID"
            autoComplete="off"
            onClick={() => {
              if (!isOpen && inputValue.trim().length > 3) {
                setIsOpen(true)
              }
            }}
            onFocus={() => {
              if (!isOpen && inputValue.trim().length > 3) {
                setIsOpen(true)
              }
            }}
            onChange={(event) => {
              setInputValue(event.target.value)

              if (event.target.value.trim().length > 3) {
                debouncedSetIsOpen(true)
              } else {
                setIsOpen(false)
              }
            }}
            prefix={<IconSearch />}
            suffix={
              inputValue && (
                <ButtonIcon
                  ref={clearButtonRef}
                  icon={<IconCross />}
                  ariaLabel="Clear button"
                  onClick={handleClearButton}
                  inputIcon
                />
              )
            }
            css={{ ...(searchCss ? searchCss : { height: "$40" }), width: 320, minHeight: "$40" }}
          />
        </Flex>
      </PopoverAnchor>
      <PopoverContent
        close={() => setIsOpen(false)}
        align="start"
        css={{
          width: dimensions.clientWidth,
          maxHeight: 330,
          overflow: "auto",
          borderRadius: "$8",
          keyboardFocus: {
            outline: "1px solid $brand-yellow-primary",
          },
        }}
        onPointerDownOutside={(event) => {
          if (isTriggerClick(event)) {
            return
          }
          return setIsOpen(false)
        }}
        onOpenAutoFocus={(event) => {
          event.preventDefault()
        }}
      >
        <Content />
      </PopoverContent>
    </Popover>
  )
})

OrdersSearch.displayName = "OrdersSearch"
