import { isAxiosError } from "axios"
import { useQuery } from "@tanstack/react-query"

import { getAllOrganizationsFn } from "@/api/organizationApi"
import { showToast } from "@/shared/utils"

export const useAllOrganizations = ({
  maxDepth = 2,
  isParent,
}: {
  maxDepth?: number
  isParent?: boolean
}) => {
  const isEnabled = !isParent

  return useQuery(["allOrganizations", maxDepth], () => getAllOrganizationsFn(maxDepth), {
    cacheTime: isEnabled ? 5 * 60 * 1000 : 0,
    enabled: isEnabled,
    onError: (error) => {
      if (isAxiosError(error)) {
        showToast({ type: "error", text: error.response?.data.errorMessage || error.message })
      }
    },
  })
}
