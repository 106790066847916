import { memo } from "react"

import { Box, Flex } from "@/shared/components"
import { boxShadows } from "@/stitches/utils"

interface IInputGroupProps {
  start: React.ReactNode
  end: React.ReactNode
}

export const InputGroup: React.FC<IInputGroupProps> = memo(({ start, end }) => {
  return (
    <Flex
      css={{
        width: "100%",
        minHeight: "$48",
        alignItems: "center",
        backgroundColor: "$theme-w-n9",
        boxShadow: boxShadows.input.initial,
        borderRadius: "$8",
      }}
    >
      <Box
        css={{
          width: "50%",
          borderRight: "1px solid $neutrals-4",
          padding: "1px $16",
        }}
      >
        {start}
      </Box>
      <Box
        css={{
          width: "50%",
          padding: "1px $16",
        }}
      >
        {end}
      </Box>
    </Flex>
  )
})

InputGroup.displayName = "InputGroup"
