import { useNavigate, useParams } from "react-router-dom"

import { ReferralKey, RouteParams, WorkingTime, OrganizationType } from "@/shared/types"
import { useCurrentAndAvailableSource, useOrganizationById } from "@/management/hooks"
import { MANAGEMENT } from "@/constants"
import { getEnumKeyByEnumValue, phoneToText, useIsReader, useIsSysAdmin } from "@/shared/utils"

import {
  Box,
  Copy,
  Flex,
  GeneralInfoCard,
  Grid,
  Stack,
  Link,
  Title,
  Redacted,
  GridItem,
  ButtonIcon,
  Spacer,
} from "@/shared/components"
import {
  IconCross,
  IconLink,
  IconLocationPin,
  IconPencil,
  IconPhone,
  IconSettings,
  IconTick,
} from "@/shared/icons"
import { WorkingDaysTableRow } from "@/management/components"

export const OrganizationInfo = () => {
  const { organizationId } = useParams<keyof RouteParams>() as RouteParams
  const navigate = useNavigate()
  const isRoleReader = useIsReader()
  const isSysAdminRole = useIsSysAdmin()

  const { fetchStatus: currentOrganizationFetchStatus, data: currentOrganization } =
    useOrganizationById(organizationId)
  const { data: currentMarketSource, isLoading: currentMarketSourceLoading } =
    useCurrentAndAvailableSource(organizationId)

  const fullAddress =
    [
      currentOrganization?.address.address1,
      currentOrganization?.address.address2,
      currentOrganization?.address.city,
      currentOrganization?.address.state,
      currentOrganization?.address.zipCode,
      currentOrganization?.address.country,
    ]
      .filter(Boolean)
      .join(", ") || "-"
  const billingFullAddress =
    [
      currentOrganization?.billingAddress.address1,
      currentOrganization?.billingAddress.address2,
      currentOrganization?.billingAddress.city,
      currentOrganization?.billingAddress.state,
      currentOrganization?.billingAddress.zipCode,
      currentOrganization?.billingAddress.country,
    ]
      .filter(Boolean)
      .join(", ") || "-"
  const workingDays = currentOrganization?.workingDays || []

  return (
    <>
      <Stack space={32}>
        <Flex css={{ gap: "$16" }}>
          <Title as="h2" scale={6} color="system-black">
            General Info
          </Title>
          {!isRoleReader && (
            <ButtonIcon
              type="button"
              ariaLabel="Edit organization"
              icon={<IconPencil />}
              onClick={() => {
                navigate(
                  `${MANAGEMENT}/edit/organization/${organizationId}?${ReferralKey.ReferralPage}=${MANAGEMENT}/organization`,
                )
              }}
              inputIcon
            />
          )}
        </Flex>
        <Grid columns="1fr 1fr 1fr 1fr" columnGap={16}>
          <GeneralInfoCard
            title="Address"
            fill
            icon={<IconLocationPin fixedSize width={20} height={20} block />}
            content={
              currentOrganizationFetchStatus === "fetching" || !currentOrganization ? (
                <Stack space={12}>
                  <Redacted as="p" height="$24" width="170px" text animated />
                  <Redacted as="p" height="$24" width="170px" text animated />
                </Stack>
              ) : (
                <Stack space={12}>
                  <Copy color="theme-b-n3">General: {fullAddress}</Copy>
                  <Copy color="theme-b-n3">Billing: {billingFullAddress}</Copy>
                </Stack>
              )
            }
          />

          <GeneralInfoCard
            title="Contacts"
            fill
            icon={<IconPhone fixedSize width={20} height={20} block />}
            content={
              currentOrganizationFetchStatus === "fetching" || !currentOrganization ? (
                <Stack space={12}>
                  <Redacted as="p" height="$24" width="170px" text animated />
                  <Redacted as="p" height="$24" width="170px" text animated />
                  <Redacted as="p" height="$24" width="170px" text animated />
                </Stack>
              ) : (
                <Stack space={12}>
                  <Copy color="theme-b-n3">General email: {currentOrganization?.generalEmail || "-"}</Copy>
                  <Copy color="theme-b-n3">Invoice email: {currentOrganization?.invoiceEmail || "-"}</Copy>
                  <Copy color="theme-b-n3">
                    Sender for email correspondence: {currentOrganization?.senderEmail || "-"}
                  </Copy>
                  <Copy color="theme-b-n3">Phone: {phoneToText(currentOrganization?.phone) || "-"}</Copy>
                </Stack>
              )
            }
          />
          <GeneralInfoCard
            title="Links"
            fill
            icon={<IconLink fixedSize width={20} height={20} block />}
            content={
              currentOrganizationFetchStatus === "fetching" || !currentOrganization ? (
                <Stack space={12}>
                  <Redacted as="p" height="$24" width="170px" text animated />
                  <Redacted as="p" height="$24" width="170px" text animated />
                </Stack>
              ) : (
                <Stack space={12}>
                  <Flex direction="row" css={{ gap: "$4" }}>
                    <Copy color="theme-b-n3">Parent organization:</Copy>
                    {currentOrganization?.parentId ? (
                      <Link
                        as="button"
                        type="button"
                        fontWeight="bold"
                        onClick={() => navigate(`${MANAGEMENT}/organization/${currentOrganization.parentId}`)}
                        css={{ color: "$theme-vl-yl" }}
                      >
                        {currentOrganization?.parentName || ""}
                      </Link>
                    ) : (
                      <Copy as="span" color="theme-b-n3">
                        -
                      </Copy>
                    )}
                  </Flex>
                  {isSysAdminRole && (
                    <Copy color="theme-b-n3">API key: {currentOrganization?.apiKey || "-"}</Copy>
                  )}
                  {isSysAdminRole && (
                    <Copy color="theme-b-n3">
                      Carrier API key: {currentOrganization?.carrierApiKey || "-"}
                    </Copy>
                  )}
                </Stack>
              )
            }
          />
          <GeneralInfoCard
            title="Other Settings"
            fill
            icon={<IconSettings fixedSize width={20} height={20} block />}
            content={
              currentOrganizationFetchStatus === "fetching" ||
              !currentOrganization ||
              currentMarketSourceLoading ? (
                <Stack space={12}>
                  <Redacted as="p" height="$24" width="170px" text animated />
                  <Redacted as="p" height="$24" width="170px" text animated />
                  <Redacted as="p" height="$24" width="170px" text animated />
                </Stack>
              ) : (
                <Stack space={12}>
                  <Copy color="theme-b-n3">
                    Market rate source: {currentMarketSource?.currentMarketRateSource?.name || "Not Selected"}
                  </Copy>
                  <Copy color="theme-b-n3">Time zone: {currentOrganization?.timeZone || "-"}</Copy>
                  <Copy color="theme-b-n3">
                    {`Type: ${
                      getEnumKeyByEnumValue(OrganizationType, currentOrganization?.organizationType) || "-"
                    }`}
                  </Copy>
                </Stack>
              )
            }
          />
        </Grid>
      </Stack>
      <Spacer size={48} />
      <Stack space={32}>
        <Title as="h2" scale={6} color="system-black">
          Working days & time
        </Title>
        <Box css={{ backgroundColor: "$system-white", borderRadius: "$8", border: "1px solid $theme-n3-n7" }}>
          <WorkingDaysTableRow
            day="working day"
            start="working time, start"
            end="working time, end"
            fullDay="24-hour day"
            isTableHead
          />
          {workingDays.length === 0 ? (
            <Grid columns="1fr" css={{ padding: "$20 $16", textAlign: "center" }}>
              <Copy color="theme-n6-n5">There is no data yet</Copy>
            </Grid>
          ) : (
            <Stack space={0}>
              {workingDays.map((field, index) => (
                <WorkingDaysTableRow key={workingDays[index].day}>
                  <GridItem css={{ paddingX: "$24" }}>
                    <Copy scale={10}>{workingDays[index].day}</Copy>
                  </GridItem>
                  <GridItem css={{ paddingX: "$24" }}>
                    <Copy scale={10}>{getEnumKeyByEnumValue(WorkingTime, workingDays[index].start)}</Copy>
                  </GridItem>
                  <GridItem css={{ paddingX: "$24" }}>
                    <Copy scale={10}>{getEnumKeyByEnumValue(WorkingTime, workingDays[index].end)}</Copy>
                  </GridItem>
                  <GridItem css={{ paddingX: "$24" }}>
                    <Copy scale={10}>
                      {workingDays[index].start === WorkingTime["00:00 AM"] ? (
                        <IconTick css={{ color: "$special-success" }} />
                      ) : (
                        <IconCross css={{ color: "$special-error" }} />
                      )}
                    </Copy>
                  </GridItem>
                </WorkingDaysTableRow>
              ))}
            </Stack>
          )}
        </Box>
      </Stack>
    </>
  )
}
