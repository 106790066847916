import { OrganizationInfoTabType } from "@/shared/types"
import { useGlobalStore } from "@/store"

import { TabList, TabListItem, TabPanel, TabPanels, Tabs } from "@/shared/components"
import {
  OrganizationInfo,
  OrdersTable,
  OrderFlow,
  CarrierAnalytics,
  OrganizationCarrierDetails,
  OrganizationExecutionStrategy,
  OrganizationSlaSettings,
} from "@/management/components"

export const OrganizationViewTabs = () => {
  // const [searchParams] = useSearchParams()
  const [orgViewTab, setOrgViewTab] = useGlobalStore((state) => [state.orgViewTab, state.setOrgViewTab])

  const tabs = [
    {
      id: OrganizationInfoTabType.General,
      label: "Organization info",
      component: <OrganizationInfo />,
    },
    {
      id: OrganizationInfoTabType.CarrierDetails,
      label: "Carrier details",
      component: <OrganizationCarrierDetails />,
    },
    {
      id: OrganizationInfoTabType.ExecutionStrategy,
      label: "Execution strategy",
      component: <OrganizationExecutionStrategy />,
    },
    {
      id: OrganizationInfoTabType.SLASettings,
      label: "SLA settings",
      component: <OrganizationSlaSettings />,
    },
    {
      id: OrganizationInfoTabType.Orders,
      label: "Orders",
      component: <OrdersTable />,
    },
    {
      id: OrganizationInfoTabType.OrderFlow,
      label: "Order flow",
      component: <OrderFlow />,
    },
    {
      id: OrganizationInfoTabType.CarrierAnalytics,
      label: "Carrier analytics",
      component: <CarrierAnalytics />,
    },
    // {
    //   id: OrganizationInfoTabType.Dashboard,
    //   label: "Dashboard",
    //   component: <Dashboard />,
    // },
    // {
    //   id: OrganizationInfoTabType.Users,
    //   label: "Users",
    //   component: <UsersTable />,
    // },
    // {
    //   id: OrganizationInfoTabType.History,
    //   label: "History",
    //   component: <>History</>,
    // },
  ]

  // useEffect(() => {
  //   const referralTab = searchParams.get(`${ReferralKey.ReferralTab}`)

  //   if (referralTab === OrganizationInfoTabType.Users) {
  //     setOrgViewTab(OrganizationInfoTabType.Users)
  //     searchParams.delete(`${ReferralKey.ReferralTab}`)
  //   }
  // }, [searchParams, setOrgViewTab])

  return (
    <Tabs selectedTab={orgViewTab} animate={false}>
      <TabList label="org-view-tabs">
        {tabs.map((tab) => (
          <TabListItem key={tab.id} id={tab.id} onChange={() => setOrgViewTab(tab.id)}>
            {tab.label}
          </TabListItem>
        ))}
      </TabList>
      <TabPanels>
        {tabs.map((tab) => (
          <TabPanel key={tab.id} id={tab.id}>
            {tab.component}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  )
}
