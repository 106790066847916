import { toast } from "react-toastify"

import { boxShadows } from "@/stitches/utils"

import { IToastProps, Toast, CloseToast, ToastType } from "@/shared/components"

function getToastComponentStyle(type: ToastType, component: "progress" | "container") {
  switch (type) {
    case "error":
      return {
        backgroundColor:
          component === "progress" ? "var(--colors-special-error)" : "var(--colors-theme-rl-rd)",
      }
    case "info":
      return {
        backgroundColor:
          component === "progress" ? "var(--colors-special-info)" : "var(--colors-theme-bl-bd)",
      }
    case "warning":
      return {
        backgroundColor:
          component === "progress" ? "var(--colors-special-warning)" : "var(--colors-theme-ol-od)",
      }
    case "success":
      return {
        backgroundColor:
          component === "progress" ? "var(--colors-special-success)" : "var(--colors-theme-gl-gd)",
      }
    case "default":
      return {}
  }
}

export const showToast = ({ type, text, width, autoClose = 5000 }: IToastProps) => {
  return toast(Toast, {
    type,
    icon: false,
    draggable: false,
    closeOnClick: false,
    position: "top-right",
    progressStyle: getToastComponentStyle(type, "progress"),
    closeButton: CloseToast,
    style: {
      ...(width && { width }),
      borderRadius: 0,
      boxShadow: boxShadows.toast,
      ...getToastComponentStyle(type, "container"),
    },
    data: { type, text },
    autoClose,
  })
}
