import { useEffect } from "react"
import { useFormContext } from "react-hook-form"

import { IDashboardFormData } from "@/shared/types"
import { useGlobalStore } from "@/store"
import { useGetDashboardData } from "@/carrier/hooks"

import { Grid, Copy, Stack, Flex, Spinner } from "@/shared/components"

import { CarrierOrdersTable } from "../CarrierOrdersTable"
import { OrderRow } from "../OrderRow"

export const OrdersContainer = () => {
  const [carrierViewTab] = useGlobalStore((state) => [state.carrierViewTab])
  const { watch, setValue, reset, getValues } = useFormContext<IDashboardFormData>()
  const { orders } = watch()

  const { data = [], isLoading } = useGetDashboardData(carrierViewTab)

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(orders)) {
      setValue("orders", data)

      setTimeout(() => {
        reset(getValues())
      }, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, setValue, getValues, reset])

  return (
    <>
      <CarrierOrdersTable
        loadId="Load id"
        from="From"
        to="To"
        pickupDate="Pickup date"
        deliveryDate="Delivery date"
        loadDetails="Load details"
        rate="Rate"
        status="Status"
        isTableHead
      />
      <>
        {isLoading ? (
          <Flex align="center" justify="center" css={{ height: 500 }}>
            <Spinner />
          </Flex>
        ) : orders.length === 0 ? (
          <Grid columns="1fr" css={{ padding: "$20 $16", textAlign: "center" }}>
            <Copy color="theme-n6-n5">There is no data yet</Copy>
          </Grid>
        ) : (
          <Stack space={0}>
            {orders.map((order) => (
              <OrderRow key={order.orderId} {...order} />
            ))}
          </Stack>
        )}
      </>
    </>
  )
}
