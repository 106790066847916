import { Box } from "@/shared/components"

export const ResponseContentItem: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Box
      css={{
        "> label": {
          padding: "$12",

          "@md": {
            padding: "$12 $16",
            cursor: "pointer",

            hover: {
              backgroundColor: "$brand-yellow-lightest",
            },

            keyboardFocus: {
              backgroundColor: "$brand-yellow-lightest",
            },

            "> p": {
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            },
          },
        },
      }}
    >
      {children}
    </Box>
  )
}
