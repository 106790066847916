import { useState } from "react"

import { CarrierDetailsTabType } from "@/shared/types"

import { TabList, TabListItem, TabPanel, TabPanels, Tabs } from "@/shared/components"

import { CompanyInfo } from "./CompanyInfo"
import { Contacts } from "./Contacts"
import { Equipment } from "./Equipment"
import { Lanes } from "./Lanes"
import { Integration } from "./Integration"
import { Services } from "./Services"
import { Analytics } from "./Analytics"

export const CarrierDetailsTabs = ({ isHighway }: { isHighway?: boolean }) => {
  const [tab, setTab] = useState(CarrierDetailsTabType.Company)

  const tabs = [
    {
      id: CarrierDetailsTabType.Company,
      label: "Company Info",
      component: <CompanyInfo isHighway={isHighway} />,
    },
    {
      id: CarrierDetailsTabType.Contacts,
      label: "Contacts",
      component: <Contacts isHighway={isHighway} />,
    },
    {
      id: CarrierDetailsTabType.Equipment,
      label: "Equipment",
      component: <Equipment isHighway={isHighway} />,
    },
    {
      id: CarrierDetailsTabType.Services,
      label: "Services",
      component: <Services />,
    },
    {
      id: CarrierDetailsTabType.Lanes,
      label: "Preferred Lanes",
      component: <Lanes />,
    },
    {
      id: CarrierDetailsTabType.Analytics,
      label: "Analytics",
      component: <Analytics />,
    },
    {
      id: CarrierDetailsTabType.Integration,
      label: "Integration",
      component: <Integration />,
    },
  ]

  return (
    <Tabs selectedTab={tab} animate={false}>
      <TabList label="carrier-details-tabs">
        {tabs.map((tab) => (
          <TabListItem key={tab.id} id={tab.id} onChange={() => setTab(tab.id)}>
            {tab.label}
          </TabListItem>
        ))}
      </TabList>
      <TabPanels>
        {tabs.map((tab) => (
          <TabPanel key={tab.id} id={tab.id}>
            {tab.component}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  )
}
