import { RegisterOptions } from "react-hook-form"

export const phoneRules = (required: boolean): RegisterOptions => ({
  shouldUnregister: true,
  required: {
    value: required,
    message: "Required field",
  },
  pattern: {
    value: /^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/,
    message: "Not match the format: XXX XXX-XXXX",
  },
})
