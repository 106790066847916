import { isAxiosError } from "axios"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { deleteUrgentContactFn } from "@/api/organizationApi"
import { showToast } from "@/shared/utils"

export const useDeleteUrgentContact = () => {
  const queryClient = useQueryClient()

  return useMutation((data: { organizationId: string; urgentId: string }) => deleteUrgentContactFn(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["allUrgentContacts"],
      })
      showToast({ type: "success", text: `Urgent contact has been deleted` })
    },
    onError: (error) => {
      if (isAxiosError(error)) {
        showToast({ type: "error", text: "Operation failed. Please try again" })
      }
    },
  })
}
