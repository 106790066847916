import { spaceAndEnterKeyDown } from "@/shared/utils"

import { Copy, Flex, Grid, GridItem } from "@/shared/components"
import { IconDropArrowDown, IconDropArrowTop } from "@/shared/icons"

interface IOrderEventsTableRowProps {
  isTableHead?: boolean
  isPlaceholder?: boolean
  hideAdditionalData?: boolean
  ascSortDirection?: boolean
  setAscSortDirection?: React.Dispatch<React.SetStateAction<boolean>>
}

export const OrderEventsTableRow: React.FC<React.PropsWithChildren<IOrderEventsTableRowProps>> = ({
  children,
  isTableHead,
  isPlaceholder,
  hideAdditionalData,
  ascSortDirection,
  setAscSortDirection,
}) => {
  return (
    <Grid
      columns={hideAdditionalData ? "2fr 6fr 1fr 1fr" : "2fr 7fr 1fr"}
      align="center"
      css={{
        height: "100%",
        minHeight: isTableHead ? "$48" : "$64",
        paddingX: "$16",
        position: "relative",
        gap: "$16",
      }}
    >
      {isTableHead && setAscSortDirection ? (
        <>
          <GridItem>
            {isPlaceholder ? (
              <Copy fontWeight="bold" uppercase>
                Date & time
              </Copy>
            ) : (
              <Flex
                align="center"
                justify="between"
                onClick={() => setAscSortDirection(!ascSortDirection)}
                tabIndex={0}
                onKeyDown={(e) => {
                  spaceAndEnterKeyDown(e.key) && setAscSortDirection(!ascSortDirection)
                }}
                css={{
                  gap: 0,
                  maxWidth: 250,
                  height: "$32",
                  paddingX: "$12",
                  backgroundColor: "$brand-yellow-lighter",
                  border: "1px solid $brand-yellow-primary",
                  borderRadius: "$8",
                  cursor: "pointer",

                  hover: {
                    backgroundColor: "$brand-yellow-light",
                  },
                  keyboardFocus: {
                    backgroundColor: "$brand-yellow-light",
                  },
                }}
              >
                <Copy fontWeight="bold" uppercase>
                  Date & time
                </Copy>
                <Flex
                  justify="center"
                  css={{
                    color: "inherit",
                    "@lg": {
                      width: "$24",
                    },
                  }}
                >
                  {ascSortDirection ? <IconDropArrowDown size="xs" /> : <IconDropArrowTop size="xs" />}
                </Flex>
              </Flex>
            )}
          </GridItem>
          <GridItem>
            <Copy fontWeight="bold" uppercase>
              Message
            </Copy>
          </GridItem>
          {hideAdditionalData && (
            <GridItem>
              <Copy fontWeight="bold" uppercase>
                Source
              </Copy>
            </GridItem>
          )}
          <GridItem>
            <Copy fontWeight="bold" uppercase>
              Carrier Name
            </Copy>
          </GridItem>
        </>
      ) : (
        children
      )}
    </Grid>
  )
}
