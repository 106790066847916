import { BaseIllustration, IIllustrationProps } from "../Illustration"

interface ISpinnerProps extends IIllustrationProps {
  action?: string
}

export const IllustrationSpinner = ({ action, ...props }: ISpinnerProps) => {
  const colorMap = {
    primary: {
      fill: "var(--colors-button-primary-spinner)",
      active: "var(--colors-system-white)",
    },
    secondary: {
      fill: "var(--colors-button-secondary-spinner)",
      active: "var(--colors-system-black)",
    },
    tertiary: {
      fill: "var(--colors-button-secondary-spinner)",
      active: "var(--colors-system-black)",
    },
    destructive: {
      fill: "var(--colors-button-primary-spinner)",
      active: "var(--colors-system-white)",
    },
  }

  const spinnerColors = colorMap[(action as keyof typeof colorMap) || "primary"]

  return (
    <BaseIllustration {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" fill="none">
        <rect x="7.33331" width="1.33333" height="4" rx="0.666667" fill={spinnerColors.active} />
        <rect x="7.33331" y="12" width="1.33333" height="4" rx="0.666667" fill={spinnerColors.fill} />
        <rect
          y="8.66699"
          width="1.33333"
          height="4"
          rx="0.666667"
          transform="rotate(-90 0 8.66699)"
          fill={spinnerColors.fill}
        />
        <rect
          x="12"
          y="8.66699"
          width="1.33333"
          height="4"
          rx="0.666667"
          transform="rotate(-90 12 8.66699)"
          fill={spinnerColors.fill}
        />
        <rect
          x="1.87173"
          y="2.81445"
          width="1.33333"
          height="4"
          rx="0.666667"
          transform="rotate(-45 1.87173 2.81445)"
          fill={spinnerColors.fill}
        />
        <rect
          x="10.3568"
          y="11.2998"
          width="1.33333"
          height="4"
          rx="0.666667"
          transform="rotate(-45 10.3568 11.2998)"
          fill={spinnerColors.fill}
        />
        <rect
          x="2.81445"
          y="14.1279"
          width="1.33333"
          height="4"
          rx="0.666667"
          transform="rotate(-135 2.81445 14.1279)"
          fill={spinnerColors.fill}
        />
        <rect
          x="11.2993"
          y="5.64258"
          width="1.33333"
          height="4"
          rx="0.666667"
          transform="rotate(-135 11.2993 5.64258)"
          fill={spinnerColors.fill}
        />
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          dur=".6s"
          repeatCount="indefinite"
          calcMode="discrete"
          values="0; 45; 90; 135; 180; 225; 270; 315"
        />
      </svg>
    </BaseIllustration>
  )
}
