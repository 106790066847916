import { ToastContentProps } from "react-toastify"

import { Copy, Flex, Grid } from "@/shared/components"
import { IconCrossCircle, IconInfoCircle, IconTickCircle, IconWarning } from "@/shared/icons"

export type ToastType = "error" | "info" | "warning" | "success" | "default"

export interface IToastProps {
  type: ToastType
  text: string
  autoClose?: number | false
  width?: number
}

export const Toast = ({ data }: ToastContentProps<IToastProps>) => {
  if (!data) return null

  return (
    <Grid columns="$24 1fr" gap={16}>
      <Flex align="center" justify="center">
        {getToastIcon(data.type)}
      </Flex>
      <Copy color="theme-b-n3">{data.text}</Copy>
    </Grid>
  )
}

function getToastIcon(type: ToastType) {
  switch (type) {
    case "error":
      return <IconCrossCircle css={{ color: "$special-error" }} />
    case "info":
      return <IconInfoCircle css={{ color: "$special-info" }} />
    case "warning":
      return <IconWarning css={{ color: "$special-warning" }} />
    case "success":
      return <IconTickCircle css={{ color: "$special-success" }} />
    case "default":
      return null
  }
}
