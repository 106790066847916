import { Controller, FieldValues, Path, PathValue } from "react-hook-form"

import { FormType, IFormInputControllerProps } from "@/shared/types"
import { phoneRules } from "@/shared/rules"
import { phoneToText } from "@/shared/utils"

import { FormInput } from "@/shared/components"

export const PhoneController = <FieldsType extends FieldValues>({
  name,
  control,
  error,
  labelProps,
  disabled,
  placeholder,
  borderless,
  register,
  trigger,
  onBlurHandler,
  onChangeHandler,
}: IFormInputControllerProps<FieldsType>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <FormInput
            {...(register &&
              register(field.name, {
                ...phoneRules(!!labelProps?.required),
              }))}
            {...field}
            id={name}
            value={phoneToText(field.value) || ""}
            onChange={(event) => {
              if (event?.target?.value.length < 15) {
                field.onChange(phoneToText(event?.target?.value) as PathValue<FieldsType, Path<FieldsType>>)
              }
              onChangeHandler && onChangeHandler()
            }}
            onBlur={(event) => {
              field.onChange(event?.target?.value.trim() as PathValue<FieldsType, Path<FieldsType>>)
              onBlurHandler && onBlurHandler()
              trigger && trigger(name)
            }}
            onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
              const isNumber = /^[0-9]$/i.test(event.key)
              if (!isNumber) {
                event.preventDefault()
              }
            }}
            label={borderless ? "" : "Phone number"}
            labelProps={{ ...labelProps, hidden: true }}
            description={borderless ? "" : "Phone number"}
            autoComplete="new-password"
            type={FormType.Text}
            error={error}
            disabled={disabled}
            placeholder={placeholder}
            borderless={borderless}
          />
        )
      }}
    />
  )
}
