import { useCallback } from "react"
import { useFormContext } from "react-hook-form"

import { useModal, useModalActions } from "@/shared/hooks"
import { IDashboardFormData, ModalType } from "@/shared/types"
import { useGlobalStore } from "@/store"

import { Button, Copy, Flex, Stack, Title } from "@/shared/components"
import { GeneralModal } from "@/modals"

export const CarrierOrderRejectModal = () => {
  const [rejectFunction] = useGlobalStore((state) => [state.rejectFunction])
  const { modalProps } = useModal(ModalType.CarrierOrderReject)
  const { close } = useModalActions()
  const { watch } = useFormContext<IDashboardFormData>()
  const { currentOrder } = watch()
  const { originalOrderId, from, to } = currentOrder

  const handleContinueClick = useCallback(() => {
    rejectFunction?.()
    close(ModalType.CarrierOrderReject)
  }, [rejectFunction, close])

  const handleCancelClick = useCallback(() => {
    close(ModalType.CarrierOrderReject)
  }, [close])

  return (
    <GeneralModal
      {...modalProps}
      closeButton={{ onClick: handleCancelClick }}
      onInteractOutside={handleCancelClick}
      onEscapeKeyDown={handleCancelClick}
      footer={
        <Flex justify="end" css={{ gap: "$16", paddingBottom: "$8" }}>
          <Button onClick={handleContinueClick}>Yes, reject</Button>
          <Button action="secondary" onClick={handleCancelClick}>
            Cancel
          </Button>
        </Flex>
      }
    >
      <Stack space={12} css={{ padding: "$64 $24 $0" }}>
        <Title as="h3" scale={2} color="system-black">
          {`Reject order ${originalOrderId}: ${from} to ${to}?`}
        </Title>
        <Copy scale={10} color="theme-n6-n5" css={{ paddingBottom: "$8" }}>
          You will not be able to recover this order once rejected.
        </Copy>
      </Stack>
    </GeneralModal>
  )
}
