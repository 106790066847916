import { useGlobalStore } from "@/store"

import { TabList, TabListItem, TabPanel, TabPanels, Tabs, Spacer, Title } from "@/shared/components"

import { FlsTabType } from "@/shared/types"
import { OrdersTabInfo } from "../OrdersTab"
import { FiltersTabInfo } from "../FiltersTab"

export const FlsContainer = () => {
  const [flsViewTab, setFlsViewTab] = useGlobalStore((state) => [state.flsViewTab, state.setFlsViewTab])

  const tabs = [
    { id: FlsTabType.Orders, label: "Orders", component: <OrdersTabInfo /> },
    { id: FlsTabType.Filters, label: "Filters", component: <FiltersTabInfo /> },
  ]

  return (
    <>
      <Title as="h1" scale={2} color="system-black">
        Filter Management
      </Title>
      <Spacer size={32} />

      <Tabs selectedTab={flsViewTab} animate={false}>
        <TabList label="fls-tabs">
          {tabs.map((tab) => (
            <TabListItem key={tab.id} id={tab.id} onChange={() => setFlsViewTab(tab.id)}>
              {tab.label}
            </TabListItem>
          ))}
        </TabList>
        <TabPanels>
          {tabs.map((tab) => (
            <TabPanel key={tab.id} id={tab.id}>
              {tab.component}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </>
  )
}
