import { StateCreator } from "zustand"
import { createWithEqualityFn } from "zustand/traditional"
import { createJSONStorage, devtools, persist } from "zustand/middleware"
import { shallow } from "zustand/shallow"

import { isDev } from "@/constants"

type GeneralUnion = ISessionSlice

interface ISessionStore {
  id: string
}

interface ISessionSlice {
  session: ISessionStore
  setSession: (session: ISessionStore) => void
}

const createSessionStorage: StateCreator<GeneralUnion, [], [], ISessionSlice> = (set) => ({
  session: { id: "" },
  setSession: (session) => set({ session }),
})

export const useSessionStore = createWithEqualityFn<GeneralUnion>()(
  devtools(
    persist(
      (...a) => ({
        ...createSessionStorage(...a),
      }),
      {
        name: "sessionStore",
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
    { name: "sessionStore", enabled: isDev },
  ),
  shallow,
)
