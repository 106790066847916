import { DistanceUnitType } from "@/shared/types"

export const distanceTypeToText = (type?: DistanceUnitType) => {
  switch (type) {
    case DistanceUnitType.Mile:
      return "miles"
    case DistanceUnitType.Km:
      return "km"
    default:
      return "-"
  }
}
