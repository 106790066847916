import * as am5 from "@amcharts/amcharts5"

export interface ISankeyLink {
  from: SankeyNodeKey
  to: SankeyNodeKey
  value: number
  details?: string
}

export interface ISankeyNode {
  id?: SankeyNodeKey
  name?: string
  color?: am5.Color
}

export enum SankeyNodeKey {
  ALL = "ALL",
  REMAINING = "REMAINING",
  FILTERED = "FILTERED",

  WATERFALL = "WATERFALL",
  WATERFALL_WINNER = "WATERFALL_WINNER",
  WATERFALL_PROGRESS = "WATERFALL_PROGRESS",
  WATERFALL_NO_WINNER = "WATERFALL_NO_WINNER",

  WAIT_FOR_ALL_WITH_LANES = "WAIT_FOR_ALL_WITH_LANES",
  WAIT_FOR_ALL_WITH_LANES_WINNER = "WAIT_FOR_ALL_WITH_LANES_WINNER",
  WAIT_FOR_ALL_WITH_LANES_PROGRESS = "WAIT_FOR_ALL_WITH_LANES_PROGRESS",
  WAIT_FOR_ALL_WITH_LANES_NO_WINNER = "WAIT_FOR_ALL_WITH_LANES_NO_WINNER",

  WAIT_FOR_ALL = "WAIT_FOR_ALL",
  WAIT_FOR_ALL_WINNER = "WAIT_FOR_ALL_WINNER",
  WAIT_FOR_ALL_PROGRESS = "WAIT_FOR_ALL_PROGRESS",
  WAIT_FOR_ALL_NO_WINNER = "WAIT_FOR_ALL_NO_WINNER",

  FCFS = "FCFS",
  FCFS_WINNER = "FCFS_WINNER",
  FCFS_PROGRESS = "FCFS_PROGRESS",
  FCFS_NO_WINNER = "FCFS_NO_WINNER",

  CRITICAL = "CRITICAL",
  CRITICAL_WINNER = "CRITICAL_WINNER",
  CRITICAL_PROGRESS = "CRITICAL_PROGRESS",
  CRITICAL_NO_WINNER = "CRITICAL_NO_WINNER",

  WINNERS = "WINNERS",
  NO_WINNERS = "NO_WINNERS",
}

export enum TimeFilter {
  Last7 = "7",
  Last30 = "30",
  Last90 = "90",
}

export enum RfqChartType {
  Responses = "% of responses",
  Wins = "% of wins",
}

export enum LineChartTypes {
  TENDERS = "TENDERS",
  RESPONDED_RFQS = "RESPONDED_RFQS",
  WON_RFQS = "WON_RFQS",
}
