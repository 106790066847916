import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { useGlobalStore } from "@/store"
import { useAllOrganizations } from "@/management/hooks"
import { MANAGEMENT } from "@/constants"
import { useIsCustomerAdmin, useIsReader } from "@/shared/utils"

import { Button, Dropdown, DropdownItem, Flex, Spacer, Title } from "@/shared/components"
import { IconPlus } from "@/shared/icons"
import { OrganizationTable } from "@/management/components"
import { DeleteOrganizationModal } from "@/modals"

export const ManagementContainer = () => {
  const [isCreateDropdownOpen, setCreateDropdownOpen] = useState<boolean>(false)
  const navigate = useNavigate()
  const [organization] = useGlobalStore((state) => [state.organization])
  const isRoleReader = useIsReader()
  const isRoleCustomerAdmin = useIsCustomerAdmin()

  const { data: organizationList = [] } = useAllOrganizations({
    maxDepth: 2,
    isParent: isRoleCustomerAdmin || isRoleReader,
  })

  const handleClick = (page: string) => {
    navigate(`${MANAGEMENT}/create/${page}`)
  }

  useEffect(() => {
    if (isRoleCustomerAdmin || isRoleReader) {
      navigate(`${MANAGEMENT}/organization/${organization.id}`)
    }
  }, [navigate, organization.id, isRoleReader, isRoleCustomerAdmin])

  return (
    <>
      <Flex justify="between">
        <Title as="h1" scale={2} color="system-black">
          Organizations
        </Title>
        <Dropdown
          asChild
          trigger={
            <Button
              action="primary"
              type="button"
              ariaLabel="Create button"
              suffix={<IconPlus />}
              size="medium"
              css={{ padding: "$0 $24" }}
            >
              Create
            </Button>
          }
          open={isCreateDropdownOpen}
          onOpenChange={() => setCreateDropdownOpen(!isCreateDropdownOpen)}
        >
          <DropdownItem
            key="Organization"
            label="Create an organization"
            onSelect={() => handleClick("organization")}
          />
          {/* <DropdownItem key="User" label="Create a user" onSelect={() => handleClick("user")} /> */}
        </Dropdown>
      </Flex>
      <Spacer size={40} />
      <OrganizationTable organizationList={organizationList} />
      <DeleteOrganizationModal />
    </>
  )
}
