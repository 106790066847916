import formatInTimeZone from "date-fns-tz/formatInTimeZone"
import { enUS } from "date-fns/locale"

import { defaultTimezone } from "@/shared/types"

export const dateTimeFormatInTimeZone = ({
  dateTime,
  timeZone = defaultTimezone,
  format = "MMM d, yyyy hh:mm aa (zzz)",
  isConverted,
}: {
  dateTime?: string | null
  timeZone?: string
  format?: string
  isConverted?: boolean
}) => {
  return dateTime
    ? formatInTimeZone(Date.parse(isConverted ? dateTime : dateTime + "+00:00"), timeZone, format, {
        locale: enUS,
      })
    : null
}
