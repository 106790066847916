import { useQuery } from "@tanstack/react-query"
import { isAxiosError } from "axios"

import { showToast } from "@/shared/utils"
import { useGlobalStore } from "@/store"
import { getDashboardOrgName } from "@/api/organizationApi"
import { IDashboardCarrierData } from "@/shared/types"

const getMainData = async (token: string) => {
  const orgName = await getDashboardOrgName(token)

  const jsonString = atob(token)
  const decodedData = JSON.parse(jsonString)

  return { ...decodedData, orgName }
}

export const useGetDashboardCarrierData = () => {
  const [carrierDashboardToken] = useGlobalStore((state) => [state.carrierDashboardToken])

  return useQuery<IDashboardCarrierData>(["dashboardCarrierData", carrierDashboardToken], () => getMainData(carrierDashboardToken), {
    cacheTime: 5 * 60 * 1000,
    onError: (error) => {
      if (isAxiosError(error)) {
        showToast({ type: "error", text: error.response?.data.errorMessage || error.message })
      }
    },
  })
}
