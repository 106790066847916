import axios from "axios"

import { ORDERS_BASE_URI, ORDERS_CSV_URI } from "@/constants"
import {
  FilterType,
  IOrderResponse,
  IAllOrdersResponse,
  IEventResponse,
  IStatisticsResponse,
  FeatureFlags,
  IFeatureFlag,
  ICarrierOrderResponse,
  IOrderDetailsResponse,
  OrgProperties,
  IOrgProperty,
} from "@/shared/types"
import { buildUrlString } from "@/shared/utils"

export const ordersApi = axios.create({
  baseURL: ORDERS_BASE_URI,
})

export const ordersCsvApi = axios.create({
  baseURL: ORDERS_CSV_URI,
})

ordersApi.defaults.headers.common["Content-Type"] = "application/json"
ordersCsvApi.defaults.headers.common["Content-Type"] = "application/json"

export const getAllOrdersFn = async ({
  orgId,
  page,
  filter,
  size = 20,
  from,
}: {
  orgId: string
  page: number
  filter: FilterType
  size?: number
  from?: string
}) => {
  const queryParams = {
    orgId,
    page,
    size,
    sort: "DESC",
    hasError: filter === FilterType.Error ? true : undefined,
    orderSourceType: filter === FilterType.FDS ? "FDS" : undefined,
    from,
  }

  const baseUrl = "orders/all"
  const url = buildUrlString(baseUrl, queryParams)

  const { data } = await ordersApi.get<IAllOrdersResponse>(url)

  return data
}

export const getAllFilteredOrdersFn = async ({
  orgId,
  from,
  page,
  size = 20,
  sort = "DESC",
}: {
  orgId: string
  from: string
  page: number
  size?: number
  sort?: string
}) => {
  const queryParams = {
    orgId,
    v2Tendered: false,
    from,
    page,
    size,
    sort,
  }

  const baseUrl = "orders/all/v2tender-related"
  const url = buildUrlString(baseUrl, queryParams)

  const { data } = await ordersApi.get<IAllOrdersResponse>(url)

  return data
}

export const getAllFilteredOrdersByDateFn = async ({
  orgId,
  page,
  size = 20,
  sort = "DESC",
  pickupStartDateStart,
  pickupStartDateEnd,
  dropOffStartDateStart,
  dropOffStartDateEnd,
}: {
  orgId: string
  page: number
  size?: number
  sort?: string
  pickupStartDateStart: string
  pickupStartDateEnd: string
  dropOffStartDateStart: string
  dropOffStartDateEnd: string
}) => {
  const queryParams = {
    orgId,
    hasError: false,
    orderSourceType: "FDS",
    page,
    size,
    sort,
    pickupStartDateStart,
    pickupStartDateEnd,
    dropOffStartDateStart,
    dropOffStartDateEnd,
  }

  const baseUrl = "orders/all"
  const url = buildUrlString(baseUrl, queryParams)

  const { data } = await ordersApi.get<IAllOrdersResponse>(url)

  return data
}

export const getOrderByIdFn = async (orderId: string, full = false) => {
  const { data } = await ordersApi.get<IOrderResponse>(`orders/${orderId}?full=${full}`)

  return data
}

export const getOrdersByPartOfId = async (orgId: string, partOfOrderId: string) => {
  const { data } = await ordersApi.get<IOrderResponse[]>(
    `orders/find-all-by-part-of-order-id-and-org-id?orgId=${orgId}&partOfOrderId=${partOfOrderId}&orderSourceType=FDS`,
  )

  return data
}

export const getAllEventsForOrderByOrderIdFn = async (orderId: string) => {
  const { data } = await ordersApi.get<IEventResponse[]>(`orders/find-events-by-order-id/${orderId}`)

  return data
}

export const getOrderStatisticsFn = async (orgId: string) => {
  const { data } = await ordersApi.get<IStatisticsResponse>(`orders/statistics?orgId=${orgId}`)

  return data
}

export const readDatKmasFileFn = async (file: File) => {
  const formData = new FormData()
  formData.append("file", file)
  formData.append("fileName", file.name)

  const { data } = await ordersApi.post<string>("import/dat-kma/read-from-excel-file", formData, {
    headers: {
      "content-type": file.type,
    },
  })

  return data
}

export const getOrdersByIdsFn = async ({
  orgId,
  orderIds,
  from,
  page,
  size,
}: {
  orgId: string
  orderIds: string[]
  from: string
  page: number
  size: number
}) => {
  const { data } = await ordersApi.post<IAllOrdersResponse>(`orders/${orgId}/by-ids`, {
    orderIds,
    from,
    page,
    size,
    sort: "DESC",
  })

  return data
}

export const getOrdersCsvFn = async (orgId: string) => {
  const currentDate = new Date()
  currentDate.setMonth(currentDate.getMonth() - 3)

  const formattedDate = `${currentDate.toISOString().split("T")[0]}T00:00:00`

  const { data } = await ordersCsvApi.get<string>(`export/order-csv?orgId=${orgId}&dateFrom=${formattedDate}`)
  return data
}

export const getCarrierOrdersFn = async ({ orgId }: { orgId: string }) => {
  const queryParams = {
    orgId,
  }

  const baseUrl = "orders/carrier"
  const url = buildUrlString(baseUrl, queryParams)

  const { data } = await ordersApi.get<ICarrierOrderResponse>(url)

  return data
}

export const getFeatureFlagsFn = async (keys: FeatureFlags[]) => {
  const { data } = await ordersApi.get<IFeatureFlag[]>(`features?propertyKeys=${keys}`)

  return data
}

export const getOrgPropertiesFn = async (orgId: string, keys: OrgProperties[]) => {
  const { data } = await ordersApi.get<IOrgProperty[]>(`org-properties/${orgId}?propertyKeys=${keys}`)

  return data
}

export const getCarrierDashboardOrders = async (token: string, ordersIds: string[]) => {
  const { data } = await ordersApi.post<IOrderDetailsResponse[]>(
    `/orders/find-by-orders-ids-for-carrier-dashboard`,
    { ordersIds },
    { headers: { "CARRIER-DASHBOARD-TOKEN": token } },
  )

  return data
}
