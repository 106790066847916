import { Box } from "@/shared/components"

import { FlsCustomersTableRow } from "./FlsCustomersTableRow"

export const FlsCustomersTablePlaceholder = () => (
  <Box>
    {Array.from(new Array(3), (_, index) => index).map((v) => (
      <FlsCustomersTableRow key={v} isPlaceholder />
    ))}
  </Box>
)
