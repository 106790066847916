import { isAxiosError } from "axios"
import { useQuery } from "@tanstack/react-query"

import { getOrderResponseCountFn } from "@/api/tenderApi"
import { showToast } from "@/shared/utils"

export const useOrderResponseCount = (orgId: string, ids: string) => {
  return useQuery(["orderResponseCount", orgId, ids], () => getOrderResponseCountFn(orgId, ids), {
    keepPreviousData: true,
    onError: (error) => {
      if (isAxiosError(error)) {
        showToast({ type: "error", text: error.response?.data.errorMessage || error.message })
      }
    },
  })
}
