import { getCarriersCsvFn } from "@/api/slaApi"
import { downloadFile } from "@/shared/utils"
import { useUploadCarriersCSV } from "@/management/hooks"

import { Flex, Copy, Button } from "@/shared/components"
import { IconDownloadTop, IconDownloadDown } from "@/shared/icons"

export const BulkButtons = ({ orgId }: { orgId: string }) => {
  const { mutateAsync: uploadCarriersCSV } = useUploadCarriersCSV()

  const handleFileChange = async (event: Event) => {
    const target = event.target as HTMLInputElement
    const files = target.files
    if (files && files[0]) {
      const formData = new FormData()
      formData.append("file", files[0], "carriers.csv")
      await uploadCarriersCSV({ orgId, formData })
    }
  }

  const triggerFileInput = () => {
    const input = document.createElement("input")
    input.type = "file"
    input.accept = ".csv"
    input.onchange = handleFileChange
    input.click()
  }

  const handleDownload = async () => {
    const data = await getCarriersCsvFn(orgId)
    downloadFile(data, `carriers_orgId_${orgId}`, ".csv")
  }

  return (
    <Flex css={{ gap: "$12" }}>
      <Button size="medium" action="secondary" onClick={triggerFileInput} icon={<IconDownloadTop />}>
        <Copy as="span" fontWeight="bold" css={{ whiteSpace: "nowrap" }}>
          Bulk upload
        </Copy>
      </Button>
      <Button
        size="medium"
        action="secondary"
        onClick={handleDownload}
        icon={<IconDownloadDown />}
        css={{ backgroundColor: "$system-white" }}
      >
        <Copy as="span" fontWeight="bold" css={{ whiteSpace: "nowrap" }}>
          Bulk export
        </Copy>
      </Button>
    </Flex>
  )
}
