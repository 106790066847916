import { useQuery } from "@tanstack/react-query"
import { isAxiosError } from "axios"

import { showToast } from "@/shared/utils"
import { getAllFlsFn } from "@/api/flsApi"

export const useAllFls = ({
  page,
  size,
  filteredStatus,
}: {
  page: number
  size?: number
  filteredStatus?: string
}) => {
  return useQuery(["allFls", page, filteredStatus], () => getAllFlsFn({ page, size, filteredStatus }), {
    keepPreviousData: true,
    onError: (error) => {
      if (isAxiosError(error)) {
        showToast({ type: "error", text: error.response?.data.errorMessage || error.message })
      }
    },
  })
}
