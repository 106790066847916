import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"

import { FlsFilterField, IFlsFiltersState } from "@/shared/types"
import { useIsReader } from "@/shared/utils"

import {
  Accordion,
  AccordionButton,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Box,
  ButtonIcon,
  Flex,
  Redacted,
  Switch,
  SwitchOption,
  useSwitch,
} from "@/shared/components"
import { IconPencil } from "@/shared/icons"

import { useFlsConfig, useUpdateFlsConfig } from "@/fls/hooks"
import { FlsLanes } from "./FlsLanes"
import { SaveFiltersButton } from "../SaveFiltersButton"
import { FlsCustomers } from "./FlsCustomers"

const initialFlsFiltersState: IFlsFiltersState = {
  customersFilter: {
    enabled: false,
    filterData: [],
  },
  lanesFilter: {
    enabled: false,
    filterData: [],
  },
}

export const FiltersTabInfo = () => {
  const [isLanesEditMode, setIsLanesEditMode] = useState<boolean>(false)
  const [isCustomersEditMode, setIsCustomersEditMode] = useState<boolean>(false)
  const { fetchStatus: flsConfigFetchStatus, data: flsConfig } = useFlsConfig()
  const { mutateAsync: updateFlsConfig, isLoading: isUpdateFlsConfigLoading } = useUpdateFlsConfig()
  const isRoleReader = useIsReader()

  const isLoading = flsConfigFetchStatus === "fetching" || isUpdateFlsConfigLoading

  const methods = useForm<IFlsFiltersState>({
    mode: "all",
    defaultValues: {
      ...initialFlsFiltersState,
    },
  })

  const {
    handleSubmit,
    getValues,
    setValue,
    watch,
    reset,
    formState: { dirtyFields },
  } = methods

  const resetAction = () => {
    setIsLanesEditMode(false)
    setIsCustomersEditMode(false)
    switchLanesFilterProps.onValueChange(flsConfig?.lanesFilter.enabled ? "true" : "false")
    switchCustomersFilterProps.onValueChange(flsConfig?.customersFilter.enabled ? "true" : "false")
    reset()
  }
  const { customersFilter, lanesFilter } = watch()

  const switchLanesFilterProps = useSwitch<"true" | "false">(
    "isLanesFilterActive",
    lanesFilter.enabled ? "true" : "false",
  )

  const switchCustomersFilterProps = useSwitch<"true" | "false">(
    "isCustomersActive",
    customersFilter.enabled ? "true" : "false",
  )

  useEffect(() => {
    if (flsConfig) {
      setValue("lanesFilter", flsConfig.lanesFilter, { shouldDirty: true })
      setValue("customersFilter", flsConfig.customersFilter, { shouldDirty: true })
      switchLanesFilterProps.onValueChange(flsConfig.lanesFilter.enabled ? "true" : "false")
      switchCustomersFilterProps.onValueChange(flsConfig.customersFilter.enabled ? "true" : "false")
      setTimeout(() => {
        reset(getValues())
      }, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, flsConfig, getValues, reset])

  const onSubmitHandler: SubmitHandler<IFlsFiltersState> = async (values) => {
    if (dirtyFields) {
      await updateFlsConfig(values)
      resetAction()
    }
  }

  return (
    <Box
      css={{
        backgroundColor: "$system-white",
        borderRadius: "$8",
        padding: "$40 $56",
        border: "1px solid $theme-n3-n7",
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitHandler)} noValidate autoComplete="off">
          <Accordion multiple defaultSelected={[FlsFilterField.CustomerFilter, FlsFilterField.LanesFilter]}>
            <AccordionItem value={FlsFilterField.CustomerFilter}>
              <AccordionHeader>
                <AccordionButton>Excluded lanes filter</AccordionButton>

                {!isRoleReader && (
                  <Flex
                    align="center"
                    css={{
                      position: "absolute",
                      top: "50%",
                      left: 264,
                      transform: "translateY(-50%)",
                      gap: "$16",
                    }}
                  >
                    {isLoading ? (
                      <Redacted height="$24" width="100px" text animated />
                    ) : (
                      <>
                        <Switch
                          {...switchLanesFilterProps}
                          onValueChange={(value) => {
                            setValue("lanesFilter.enabled", value === "true", { shouldDirty: true })
                            switchLanesFilterProps.onValueChange(value)
                          }}
                          checked={switchLanesFilterProps.value === "true"}
                          disabled={!isLanesEditMode}
                        >
                          <SwitchOption
                            value={lanesFilter.enabled ? "false" : "true"}
                            disabled={!isLanesEditMode}
                          />
                        </Switch>

                        {!isLanesEditMode ? <EditButton action={setIsLanesEditMode} /> : null}
                      </>
                    )}
                  </Flex>
                )}
              </AccordionHeader>
              <AccordionPanel>
                <FlsLanes isEditMode={isLanesEditMode} isLoading={isLoading} />
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value={FlsFilterField.LanesFilter}>
              <AccordionHeader>
                <AccordionButton>Excluded customers filter</AccordionButton>

                {!isRoleReader && (
                  <Flex
                    align="center"
                    css={{
                      position: "absolute",
                      top: "50%",
                      left: 308,
                      transform: "translateY(-50%)",
                      gap: "$16",
                    }}
                  >
                    {isLoading ? (
                      <Redacted height="$24" width="100px" text animated />
                    ) : (
                      <>
                        <Switch
                          {...switchCustomersFilterProps}
                          onValueChange={(value) => {
                            setValue("customersFilter.enabled", value === "true", { shouldDirty: true })
                            switchCustomersFilterProps.onValueChange(value)
                          }}
                          checked={switchCustomersFilterProps.value === "true"}
                          disabled={!isCustomersEditMode}
                        >
                          <SwitchOption
                            value={customersFilter.enabled ? "false" : "true"}
                            disabled={!isCustomersEditMode}
                          />
                        </Switch>

                        {!isCustomersEditMode ? <EditButton action={setIsCustomersEditMode} /> : null}
                      </>
                    )}
                  </Flex>
                )}
              </AccordionHeader>
              <AccordionPanel>
                <FlsCustomers isEditMode={isCustomersEditMode} isLoading={isLoading} />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          {isLanesEditMode || isCustomersEditMode ? (
            <SaveFiltersButton isLoading={isLoading} resetAction={resetAction} />
          ) : null}
        </form>
      </FormProvider>
    </Box>
  )
}

const EditButton = ({ action }: { action: Dispatch<SetStateAction<boolean>> }) => {
  return (
    <ButtonIcon type="button" ariaLabel="Edit" icon={<IconPencil />} onClick={() => action(true)} inputIcon />
  )
}
