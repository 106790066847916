import { useMutation, useQueryClient } from "@tanstack/react-query"
import { isAxiosError } from "axios"

import { updateOrganizationSlaFn } from "@/api/slaApi"
import { showToast } from "@/shared/utils"
import { ISla, ISlaItem, SlaItemGroup, SlaItemType } from "@/shared/types"

export const useUpdateOrganizationSla = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ orgId, data }: { orgId: string; data: ISla[] }) => {
      const nodes = data.map((i) => i.content as ISlaItem).filter((i) => i)

      return updateOrganizationSlaFn({
        orgId,
        settings: {
          type: SlaItemType.Group,
          nodes,
          group: SlaItemGroup.And,
        },
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["organizationSla"],
        })
      },
      onError: (error) => {
        if (isAxiosError(error)) {
          showToast({ type: "error", text: error.response?.data.errorMessage || error.message })
        }
      },
    },
  )
}
