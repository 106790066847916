import { useQuery } from "@tanstack/react-query"
import { isAxiosError } from "axios"

import { getAllOrdersFn } from "@/api/ordersApi"
import { showToast } from "@/shared/utils"
import { FilterType } from "@/shared/types"

export const useAllOrders = ({
  orgId,
  page,
  filter,
  size,
  disableRefetchInterval,
  from,
}: {
  orgId: string
  page: number
  filter: FilterType
  size?: number
  disableRefetchInterval?: boolean
  from?: string
}) => {
  const isEnabled = !!orgId

  return useQuery(
    ["allOrders", orgId, page, filter, from],
    () => getAllOrdersFn({ orgId, page, filter, size, from }),
    {
      staleTime: 0,
      refetchInterval: disableRefetchInterval ? false : 60 * 1000,
      keepPreviousData: true,
      enabled: isEnabled,
      onError: (error) => {
        if (isAxiosError(error)) {
          showToast({ type: "error", text: error.response?.data || error.message })
        }
      },
    },
  )
}
