import { useCallback, useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"

import { useModal, useModalActions } from "@/shared/hooks"
import { ModalType, StatusType, ILaneContact, IOrganizationState, carrierInitialState } from "@/shared/types"
import { carrierCommunicationTypeToText, carrierTypeToText, isHighway, phoneToText } from "@/shared/utils"

import {
  ActivityStatusLabel,
  Box,
  Copy,
  Divider,
  EquipmentInfoCard,
  Flex,
  GeneralInfoCard,
  Grid,
  HighwayStatusLabel,
  Stack,
  Title,
} from "@/shared/components"
import {
  IconBox,
  IconComment,
  IconDocument,
  IconInvoice,
  IconLocationPin,
  IconMoney,
  IconPhone,
  IconTender,
  IconTruck,
} from "@/shared/icons"
import { ContactsTableRow, GeneralModal, LanesTableRow } from "@/modals"

import { AnalyticsInfoCard, EditButton, ServicePresenceFlag } from "./components"

export const ViewCarrierModal = ({ isViewMode }: { isViewMode?: boolean }) => {
  const [savedId, setSavedId] = useState("")
  const { modalProps } = useModal(ModalType.ViewCarrierModal)
  const { close } = useModalActions()

  const { watch, resetField, clearErrors } = useFormContext<IOrganizationState>()
  const { newCarrier, name: orgName } = watch()
  const {
    id,
    name,
    enabled,
    code,
    scac,
    legalName,
    mc,
    usdot,
    type,
    address1,
    address2,
    city,
    country,
    zipCode,
    state,
    phone,
    contacts,
    equipment,
    lanes,
    laneDetails,
    analytics,
    integration,
    communicationType,
    carrierRepName,
    carrierRepEmail,
    overallResult,
  } = newCarrier
  const {
    truckAmount,
    vanAmount,
    flatbedAmount,
    reeferAmount,
    twic,
    teamDrivers,
    maxCargo,
    tankerEndorsement,
    dropTrailers,
    automatedTracking,
    driverWork,
    highValue,
    hazmat,
    dropDeckAmount,
    tankerAmount,
    intermodalAmount,
    pneumaticAmount,
    dumpsAmount,
    carHaulerAmount,
    doubleDropAmount,
    lowboyAmount,
    grainAmount,
    livestockAmount,
    heavyHaulAmount,
  } = equipment
  const { noHaulShippers, noHaulConsignees } = laneDetails
  const { claimsRatio, ediCompliance, invoiceCompliance, otd, otp, tenderAcceptance } = analytics
  const { quotes, tracking } = integration
  const fullAddress = [address2, address1, city, state, zipCode, country].filter(Boolean).join(", ")

  const generateContactNames = (contactIds: ILaneContact[]): string => {
    const result: string[] = []
    contactIds.forEach((el) => {
      const name = contacts.find((contact) => contact.id == el.id)
      if (name) {
        result.push(name.name)
      }
    })
    return result.join(", ")
  }

  const handleCancelClick = useCallback(() => {
    close(ModalType.ViewCarrierModal)
    setTimeout(() => {
      resetField("newCarrier", { defaultValue: carrierInitialState })
      clearErrors("newCarrier")
    }, 300)
  }, [clearErrors, close, resetField])

  useEffect(() => {
    if (id) {
      setSavedId(id)
    }
  }, [id])

  const carrierSalesText = carrierRepName
    ? carrierRepEmail
      ? `${carrierRepName} (${carrierRepEmail})`
      : carrierRepName
    : carrierRepEmail || "-"

  return (
    <GeneralModal
      {...modalProps}
      size="xl"
      closeButton={{ onClick: handleCancelClick }}
      onInteractOutside={handleCancelClick}
      onEscapeKeyDown={handleCancelClick}
      header={
        <>
          <Stack space={32} css={{ padding: "$64 $24 $32", backgroundColor: "$theme-n1-n10" }}>
            <Flex align="center" css={{ gap: "$24" }}>
              <Title as="h3" scale={2} color="system-black">
                {name || "-"}
              </Title>
              <ActivityStatusLabel status={enabled === StatusType.Active} scale={8} big filled />
              {isHighway(newCarrier) && overallResult ? (
                <HighwayStatusLabel status={overallResult} scale={8} filled />
              ) : null}
              {!isViewMode ? <EditButton id={savedId} /> : null}
            </Flex>
            <Flex wrap css={{ columnGap: "$32", rowGap: "$16" }}>
              <Copy>Carrier code: {code || "-"}</Copy>
              <Copy>SCAC: {scac || "-"}</Copy>
              <Copy>Legal name: {legalName || "-"}</Copy>
              <Copy>MC#: {mc || "-"}</Copy>
              <Copy>USDOT#: {usdot || "-"}</Copy>
              <Copy>Type: {carrierTypeToText(type)}</Copy>
              <Copy>Communication Type: {carrierCommunicationTypeToText(communicationType)}</Copy>
            </Flex>
          </Stack>
          <Divider />
        </>
      }
    >
      <Stack space={48} css={{ padding: "$24" }}>
        <Grid columns={`1fr 1fr 1fr ${isHighway(newCarrier) ? "" : "1fr"}`} columnGap={16}>
          <GeneralInfoCard
            title="Address"
            icon={<IconLocationPin fixedSize width={20} height={20} block />}
            content={<Copy scale={10}>{fullAddress || "-"}</Copy>}
          />
          {!isHighway(newCarrier) && (
            <GeneralInfoCard
              title="Equipment"
              icon={<IconTruck fixedSize width={20} height={20} block />}
              content={
                <Flex wrap css={{ columnGap: "$16", rowGap: "$12" }}>
                  <Flex css={{ gap: "$4" }}>
                    <Copy scale={10} fontWeight="bold">
                      {vanAmount || "0"}
                    </Copy>
                    <Copy scale={10}>Vans</Copy>
                  </Flex>
                  <Flex css={{ gap: "$4" }}>
                    <Copy scale={10} fontWeight="bold">
                      {reeferAmount || "0"}
                    </Copy>
                    <Copy scale={10}>Reefers</Copy>
                  </Flex>
                  <Flex css={{ gap: "$4" }}>
                    <Copy scale={10} fontWeight="bold">
                      {flatbedAmount || "0"}
                    </Copy>
                    <Copy scale={10}>Flatbeds</Copy>
                  </Flex>
                  <Flex css={{ gap: "$4" }}>
                    <Copy scale={10} fontWeight="bold">
                      {truckAmount || "0"}
                    </Copy>
                    <Copy scale={10}>Trucks</Copy>
                  </Flex>
                </Flex>
              }
            />
          )}
          <GeneralInfoCard
            title="Max cargo value"
            icon={<IconMoney fixedSize width={20} height={20} block />}
            content={<Copy scale={10}>{maxCargo ? `$${maxCargo}` : "-"}</Copy>}
          />
          <GeneralInfoCard
            title="Phone number"
            icon={<IconPhone fixedSize width={20} height={20} block />}
            content={<Copy scale={10}>{phoneToText(phone) || "-"}</Copy>}
          />
        </Grid>
        <Stack space={24}>
          <Title as="h2" scale={6} color="theme-b-n3">
            Contacts
          </Title>
          <Flex css={{ gap: "$8" }}>
            <Copy>{orgName} Carrier Contact:</Copy>
            <Copy>{carrierSalesText}</Copy>
          </Flex>
          <Box css={{ border: "1px solid $theme-n3-n7", borderRadius: "$8" }}>
            <ContactsTableRow
              name="Name"
              role="Role"
              phone="Phone"
              email="Email"
              tenderContact="Tender & Bid contact"
              isTableHead
            />
            {contacts.length > 0 ? (
              contacts.map(({ id, name, role, phone, email, tenderContact }, index) => (
                <ContactsTableRow
                  key={index}
                  index={index}
                  id={id}
                  name={name}
                  role={role}
                  phone={phone}
                  email={email}
                  tenderContact={tenderContact}
                  isViewMode
                />
              ))
            ) : (
              <Flex
                align="center"
                justify="center"
                css={{
                  height: "$64",
                  color: "$theme-b-n3",
                }}
              >
                <Copy color="theme-n6-n5">There is no data yet</Copy>
              </Flex>
            )}
          </Box>
        </Stack>
        {isHighway(newCarrier) && (
          <Stack space={24}>
            <Title as="h2" scale={6} color="theme-b-n3">
              Equipment
            </Title>

            <Grid columns="1fr 1fr 1fr 1fr" columnGap={16}>
              <EquipmentInfoCard
                content={
                  <Stack space={8}>
                    <Flex css={{ gap: "$4" }}>
                      <Copy scale={10}>Trucks:</Copy>
                      <Copy scale={10} fontWeight="bold">
                        {truckAmount || "0"}
                      </Copy>
                    </Flex>
                    <Flex css={{ gap: "$4" }}>
                      <Copy scale={10}>Vans:</Copy>
                      <Copy scale={10} fontWeight="bold">
                        {vanAmount || "0"}
                      </Copy>
                    </Flex>
                    <Flex css={{ gap: "$4" }}>
                      <Copy scale={10}>Flatbeds:</Copy>
                      <Copy scale={10} fontWeight="bold">
                        {flatbedAmount || "0"}
                      </Copy>
                    </Flex>
                    <Flex css={{ gap: "$4" }}>
                      <Copy scale={10}>Reefers:</Copy>
                      <Copy scale={10} fontWeight="bold">
                        {reeferAmount || "0"}
                      </Copy>
                    </Flex>
                  </Stack>
                }
              />
              <EquipmentInfoCard
                content={
                  <Stack space={8}>
                    <Flex css={{ gap: "$4" }}>
                      <Copy scale={10}>Drop deck trailers:</Copy>
                      <Copy scale={10} fontWeight="bold">
                        {dropDeckAmount || "0"}
                      </Copy>
                    </Flex>
                    <Flex css={{ gap: "$4" }}>
                      <Copy scale={10}>Tanker trailers:</Copy>
                      <Copy scale={10} fontWeight="bold">
                        {tankerAmount || "0"}
                      </Copy>
                    </Flex>
                    <Flex css={{ gap: "$4" }}>
                      <Copy scale={10}>Intermodal trailers:</Copy>
                      <Copy scale={10} fontWeight="bold">
                        {intermodalAmount || "0"}
                      </Copy>
                    </Flex>
                    <Flex css={{ gap: "$4" }}>
                      <Copy scale={10}>Pneumatic trailers:</Copy>
                      <Copy scale={10} fontWeight="bold">
                        {pneumaticAmount || "0"}
                      </Copy>
                    </Flex>
                  </Stack>
                }
              />
              <EquipmentInfoCard
                content={
                  <Stack space={8}>
                    <Flex css={{ gap: "$4" }}>
                      <Copy scale={10}>Dumps trailers:</Copy>
                      <Copy scale={10} fontWeight="bold">
                        {dumpsAmount || "0"}
                      </Copy>
                    </Flex>
                    <Flex css={{ gap: "$4" }}>
                      <Copy scale={10}>Car hauler trailers:</Copy>
                      <Copy scale={10} fontWeight="bold">
                        {carHaulerAmount || "0"}
                      </Copy>
                    </Flex>
                    <Flex css={{ gap: "$4" }}>
                      <Copy scale={10}>Double drop trailers:</Copy>
                      <Copy scale={10} fontWeight="bold">
                        {doubleDropAmount || "0"}
                      </Copy>
                    </Flex>
                    <Flex css={{ gap: "$4" }}>
                      <Copy scale={10}>Lowboy trailers:</Copy>
                      <Copy scale={10} fontWeight="bold">
                        {lowboyAmount || "0"}
                      </Copy>
                    </Flex>
                  </Stack>
                }
              />
              <EquipmentInfoCard
                content={
                  <Stack space={8}>
                    <Flex css={{ gap: "$4" }}>
                      <Copy scale={10}>Grain trailers:</Copy>
                      <Copy scale={10} fontWeight="bold">
                        {grainAmount || "0"}
                      </Copy>
                    </Flex>
                    <Flex css={{ gap: "$4" }}>
                      <Copy scale={10}>Livestock trailers:</Copy>
                      <Copy scale={10} fontWeight="bold">
                        {livestockAmount || "0"}
                      </Copy>
                    </Flex>
                    <Flex css={{ gap: "$4" }}>
                      <Copy scale={10}>Heavy haul trailers:</Copy>
                      <Copy scale={10} fontWeight="bold">
                        {heavyHaulAmount || "0"}
                      </Copy>
                    </Flex>
                  </Stack>
                }
              />
            </Grid>
          </Stack>
        )}
        <Stack space={24}>
          <Title as="h2" scale={6} color="theme-b-n3">
            Services
          </Title>
          <Flex wrap="true" css={{ gap: "$32" }}>
            <ServicePresenceFlag title="TWIC" value={twic} />
            <ServicePresenceFlag title="Team drivers" value={teamDrivers} />
            <ServicePresenceFlag title="Driver work" value={driverWork} />
            <ServicePresenceFlag title="Tanker endorsement" value={tankerEndorsement} />
            <ServicePresenceFlag title="Drop trailers" value={dropTrailers} />
            <ServicePresenceFlag title="Automated tracking" value={automatedTracking} />
            <ServicePresenceFlag title="High value cargo insurance" value={highValue} />
            <ServicePresenceFlag title="Hazmat" value={hazmat} />
          </Flex>
        </Stack>
        <Stack space={24}>
          <Title as="h2" scale={6} color="theme-b-n3">
            Preferred lanes
          </Title>
          <Flex css={{ gap: "$8" }}>
            <Copy>No haul consignees:</Copy>
            <Copy>{noHaulConsignees || "-"}</Copy>
          </Flex>
          <Flex css={{ gap: "$8" }}>
            <Copy>No haul shippers:</Copy>
            <Copy>{noHaulShippers || "-"}</Copy>
          </Flex>
          <Box css={{ border: "1px solid $theme-n3-n7", borderRadius: "$8" }}>
            <LanesTableRow from="From" to="To" price="Price, usd" contacts="Tender Contact" isTableHead />
            {lanes.length > 0 ? (
              lanes.map(({ id, from, to, price, contacts }, index) => (
                <LanesTableRow
                  key={index}
                  index={index}
                  id={id}
                  from={from}
                  to={to}
                  price={price}
                  contacts={generateContactNames(contacts)}
                  isViewMode
                />
              ))
            ) : (
              <Flex
                align="center"
                justify="center"
                css={{
                  height: "$64",
                  color: "$theme-b-n3",
                }}
              >
                <Copy color="theme-n6-n5">There is no data yet</Copy>
              </Flex>
            )}
          </Box>
        </Stack>
        {isHighway(newCarrier) && (
          <Stack space={32}>
            <Title as="h2" scale={6} color="theme-b-n3">
              Preferred states
            </Title>
            <Flex css={{ gap: "$12", flexWrap: "wrap" }}>
              {newCarrier.lanePreferences.length > 0
                ? newCarrier.lanePreferences.map((el) => (
                    <Box
                      id={el.id + el.code}
                      key={el.id + el.code}
                      css={{
                        width: "$56",
                        height: "$32",
                        alignContent: "center",
                        textAlign: "center",
                        backgroundColor: "$brand-yellow-lighter",
                        borderRadius: "$8",
                      }}
                    >
                      {el.code}
                    </Box>
                  ))
                : "-"}
            </Flex>
          </Stack>
        )}

        <Stack space={24}>
          <Title as="h2" scale={6} color="theme-b-n3">
            Analytics
          </Title>
          <Grid columns="1fr 1fr 1fr 1fr 1fr 1fr" columnGap={16}>
            <AnalyticsInfoCard
              title="Claims ratio"
              icon={
                <IconComment fixedSize width={20} height={20} block css={{ color: "$brand-red-primary" }} />
              }
              iconBoxColor="brand-red-light"
              value={claimsRatio || ""}
            />
            <AnalyticsInfoCard
              title="Tender acceptance"
              icon={
                <IconTender fixedSize width={20} height={20} block css={{ color: "$brand-azure-primary" }} />
              }
              iconBoxColor="brand-azure-light"
              value={tenderAcceptance || ""}
            />
            <AnalyticsInfoCard
              title="Edi compliance"
              icon={
                <IconDocument
                  fixedSize
                  width={20}
                  height={20}
                  block
                  css={{ color: "$brand-green-primary" }}
                />
              }
              iconBoxColor="brand-green-light"
              value={ediCompliance || ""}
            />
            <AnalyticsInfoCard
              title="On-time pickup"
              icon={<IconBox fixedSize width={20} height={20} block css={{ color: "$brand-pink-primary" }} />}
              iconBoxColor="brand-pink-light"
              value={otp || ""}
            />
            <AnalyticsInfoCard
              title="On-time delivery"
              icon={
                <IconTruck fixedSize width={20} height={20} block css={{ color: "$brand-orange-primary" }} />
              }
              iconBoxColor="brand-orange-light"
              value={otd || ""}
            />
            <AnalyticsInfoCard
              title="Invoice compliance"
              icon={
                <IconInvoice
                  fixedSize
                  width={20}
                  height={20}
                  block
                  css={{ color: "$brand-purple-primary" }}
                />
              }
              iconBoxColor="brand-purple-light"
              value={invoiceCompliance || ""}
            />
          </Grid>
        </Stack>
        <Stack space={24}>
          <Title as="h2" scale={6} color="theme-b-n3">
            Integration
          </Title>
          <Flex css={{ gap: "$32" }}>
            <Flex css={{ gap: "$8" }}>
              <Copy>Quotes:</Copy>
              <Copy>{quotes || "-"}</Copy>
            </Flex>
            <Flex css={{ gap: "$8" }}>
              <Copy>Tracking:</Copy>
              <Copy>{tracking || "-"}</Copy>
            </Flex>
          </Flex>
        </Stack>
      </Stack>
    </GeneralModal>
  )
}
