import { useQuery } from "@tanstack/react-query"
import { isAxiosError } from "axios"

import { getCurrentAndAvailableSourceFn } from "@/api/mrktsApi"

import { showToast } from "@/shared/utils"

export const useCurrentAndAvailableSource = (orgId: string) => {
  const isEnabled = !!orgId

  return useQuery(["currentAndAvailableSource", orgId], () => getCurrentAndAvailableSourceFn(orgId), {
    cacheTime: isEnabled ? 5 * 60 * 1000 : 0,
    enabled: isEnabled,
    onError: (error) => {
      if (isAxiosError(error)) {
        showToast({ type: "error", text: error.response?.data.errorMessage || error.message })
      }
    },
  })
}
