import { useParams } from "react-router-dom"
import { FormProvider, useForm } from "react-hook-form"

import {
  firstToUpperCase,
  equipmentToText,
  strategyToText,
  distanceTypeToText,
  dateTimeFormatInTimeZone,
  dateTimeTzFormat,
  getFeatureFlags,
} from "@/shared/utils"
import {
  IOrganizationState,
  initialOrganizationState,
  ModalType,
  RouteParams,
  StrategyType,
  IMarketRates,
  IOrderResponse,
  ISpecialService,
  IOrganizationCarrier,
  FeatureFlags,
  defaultTimezone,
  OrderStatusType,
} from "@/shared/types"
import {
  useOrganizationCarriersOrderView,
  useQuotesByOrderId,
  useStrategyByOrderId,
} from "@/management/hooks"
import { useModalActions } from "@/shared/hooks"

import { Title, Copy, Redacted, GeneralInfoCard, Grid, Stack, Flex, Box, Spacer } from "@/shared/components"
import { IconBox, IconCalendar, IconLocationPin, IconWarning } from "@/shared/icons"
import { CreateCarrierModal, ForceTenderModal, ViewCarrierModal } from "@/modals"

import { ExecutionTableRow } from "./ExecutionTableRow"

interface ISummaryProps {
  isDataLoading?: boolean
  order?: IOrderResponse
}

export const Summary = ({ isDataLoading, order }: ISummaryProps) => {
  const [isIdInsteadScac, isForceTender] = getFeatureFlags([
    FeatureFlags.FEATURE_FLAG_USE_CARRIER_ID_INSTEAD_SCAC,
    FeatureFlags.FEATURE_FLAG_FORCE_TENDERS,
  ])

  const { orderId, organizationId } = useParams<keyof RouteParams>() as RouteParams
  const { data: quotes = [], isLoading: isQuotesLoading } = useQuotesByOrderId(orderId)
  const { data: strategy, isLoading: isStrategyLoading } = useStrategyByOrderId(orderId)
  const { open } = useModalActions()
  const methods = useForm<IOrganizationState>({
    mode: "all",
    defaultValues: {
      ...initialOrganizationState,
    },
  })
  const { setValue, watch } = methods
  const { newCarrier } = watch()

  const { data: carriersData, isLoading: isOrganizationCarriersLoading } = useOrganizationCarriersOrderView({
    orgId: organizationId,
    page: 0,
    size: quotes.length,
    ids: quotes.map((i) => Number(i.carrierId)).join(","),
  })

  const isLoading = isDataLoading || isQuotesLoading || isStrategyLoading || isOrganizationCarriersLoading

  const formFullAddress = [
    order?.fromAddress1,
    order?.fromAddress2,
    ...(order?.fromCity ? [firstToUpperCase(order.fromCity)] : []),
    order?.fromState,
    order?.fromPostal,
  ]
    .filter(Boolean)
    .join(", ")
  const toFullAddress = [
    order?.toAddress1,
    order?.toAddress2,
    ...(order?.fromCity ? [firstToUpperCase(order.toCity)] : []),
    order?.toState,
    order?.toPostal,
  ]
    .filter(Boolean)
    .join(", ")

  const timeZoneFrom = order?.structuredData?.fromTimeZoneId || defaultTimezone
  const timeZoneTo = order?.structuredData?.toTimeZoneId || defaultTimezone

  const shipDate =
    order?.pickupStartAt === order?.pickupEndAt
      ? order?.pickupStartAt
        ? `${dateTimeTzFormat({
            dateTime: order?.pickupStartAt,
            timeZone: timeZoneFrom,
          })}`
        : "-"
      : order?.pickupStartAt && order?.pickupEndAt
      ? `${dateTimeTzFormat({
          dateTime: order?.pickupStartAt,
          timeZone: timeZoneFrom,
          timeFormat: "MMM d, yyyy hh:mm aa",
        })} - ${dateTimeTzFormat({
          dateTime: order?.pickupEndAt,
          timeZone: timeZoneFrom,
        })}`
      : "-"
  const deliveryDate =
    order?.dropOffStartAt === order?.dropOffEndAt
      ? order?.dropOffStartAt
        ? `${dateTimeTzFormat({
            dateTime: order?.dropOffStartAt,
            timeZone: timeZoneTo,
          })}`
        : "-"
      : order?.dropOffStartAt && order?.dropOffEndAt
      ? `${dateTimeTzFormat({
          dateTime: order?.dropOffStartAt,
          timeZone: timeZoneTo,
          timeFormat: "MMM d, yyyy hh:mm aa",
        })} - ${dateTimeTzFormat({
          dateTime: order?.dropOffEndAt,
          timeZone: timeZoneTo,
        })}`
      : "-"

  let isDetails = false
  quotes.map((el) => {
    if (el.forceSubmittedBy) {
      isDetails = true
    }
  })

  const getDetailsHeader = () => {
    if (
      strategy?.currentStrategyType == StrategyType.Waterfall ||
      strategy?.currentStrategyType == StrategyType.WaitAll ||
      strategy?.currentStrategyType == StrategyType.WaitAllWithLanes
    ) {
      if (order?.orderStatus == OrderStatusType.TenderingFinished && isDetails) {
        return "Details"
      }
      if (order?.orderStatus == OrderStatusType.Tendering) {
        return " "
      }
    }
    return ""
  }

  const parseWinner = (id?: string) => {
    if (!id) {
      return {
        text: "-",
        price: "-",
      }
    }

    const carrier = (carriersData?.content || []).find((i) => `${i.id}` === id)
    const quote = quotes?.find((i) => `${i.carrierId}` === id)

    const codeText = carrier?.code && carrier?.name ? `${carrier.code} - ${carrier.name}` : "-"
    const scacText = carrier?.scac && carrier?.name ? `${carrier.scac} - ${carrier.name}` : "-"

    return {
      text: isIdInsteadScac ? codeText : scacText,
      price: quote?.rateInCents ? `$${(quote.rateInCents / 100).toFixed(2)}` : "-",
    }
  }

  const servicesString = (from: ISpecialService, to: ISpecialService) => {
    const servicesArray = []
    if (ISpecialService[from as keyof typeof ISpecialService] !== ISpecialService.NOT_SELECTED) {
      servicesArray.push(ISpecialService[from as keyof typeof ISpecialService])
    }
    if (ISpecialService[to as keyof typeof ISpecialService] !== ISpecialService.NOT_SELECTED) {
      servicesArray.push(ISpecialService[to as keyof typeof ISpecialService])
    }
    return servicesArray.length > 0 ? servicesArray.join(", ") : "-"
  }

  const formatMarketRate = (marketRates?: IMarketRates[]) => {
    if (!marketRates || marketRates.length === 0) {
      return "-"
    }

    return marketRates
      .map(
        (item) =>
          `$${(item.rateInCents / 100).toFixed(2)} (${item.source === "GREEN_SCREEN" ? "GS" : item.source})`,
      )
      .join(", ")
  }

  const onViewClick = (id: string) => {
    const viewItem = carriersData?.content.find((el) => el.id === id)

    if (viewItem) {
      setValue("newCarrier", viewItem as IOrganizationCarrier, { shouldDirty: true })
      setValue("carriers", carriersData?.content as IOrganizationCarrier[])

      setTimeout(() => {
        open(ModalType.ViewCarrierModal)
      }, 0)
    }
  }

  return (
    <FormProvider {...methods}>
      <form>
        <>
          <Stack space={32}>
            <Title as="h2" scale={6} color="theme-b-n3">
              General Info
            </Title>
            {order?.structuredData?.validationErrors ? (
              <Flex align="center" css={{ gap: "$4" }}>
                <IconWarning fixedSize width={20} height={20} css={{ color: "$special-error" }} />
                <Spacer size={0} />
                <Copy scale={10} css={{ color: "$special-error" }}>
                  Error reason:
                </Copy>
                <Copy scale={10}>
                  {order.structuredData.validationErrors
                    .split("; ")
                    .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
                    .join("; ")}
                </Copy>
              </Flex>
            ) : null}
            <Grid columns="1fr 1fr 1fr 1fr" columnGap={16}>
              <GeneralInfoCard
                title="Shipper"
                fill
                icon={<IconLocationPin fixedSize width={20} height={20} block />}
                content={
                  isLoading ? (
                    <Stack space={12}>
                      <Redacted height="22px" width="170px" text animated />
                      <Redacted height="22px" width="170px" text animated />
                    </Stack>
                  ) : (
                    <Stack space={12}>
                      <Copy scale={10} fontWeight="bold">
                        {order?.structuredData?.shipperName || "-"}
                      </Copy>
                      <Copy scale={10}>{formFullAddress}</Copy>
                    </Stack>
                  )
                }
              />
              <GeneralInfoCard
                title="consignee"
                fill
                icon={<IconLocationPin fixedSize width={20} height={20} block />}
                content={
                  isLoading ? (
                    <Stack space={12}>
                      <Redacted height="22px" width="170px" text animated />
                      <Redacted height="22px" width="170px" text animated />
                    </Stack>
                  ) : (
                    <Stack space={12}>
                      <Copy scale={10} fontWeight="bold">
                        {order?.structuredData?.consigneeName || "-"}
                      </Copy>
                      <Copy scale={10}>{toFullAddress}</Copy>
                    </Stack>
                  )
                }
              />
              <GeneralInfoCard
                title="Pickup & Delivery time"
                fill
                icon={<IconCalendar fixedSize width={20} height={20} block />}
                content={
                  isLoading ? (
                    <Stack space={12}>
                      <Redacted height="22px" width="170px" text animated />
                      <Redacted height="22px" width="170px" text animated />
                    </Stack>
                  ) : (
                    <Stack space={12}>
                      <Copy scale={10}>{`Pickup time: ${shipDate}`}</Copy>
                      <Copy scale={10}>{`Delivery time: ${deliveryDate}`}</Copy>
                    </Stack>
                  )
                }
              />
              <GeneralInfoCard
                title="Shipment details"
                fill
                icon={<IconBox fixedSize width={20} height={20} block />}
                content={
                  isLoading ? (
                    <Stack space={12}>
                      <Redacted height="22px" width="170px" text animated />
                      <Redacted height="22px" width="170px" text animated />
                      <Redacted height="22px" width="170px" text animated />
                    </Stack>
                  ) : (
                    <Flex wrap css={{ columnGap: "$12", rowGap: "$12" }}>
                      <Copy scale={10}>{`Equipment: ${equipmentToText(order?.equipmentType || "")}`}</Copy>
                      <Copy scale={10}>{`Weight: ${
                        order?.structuredData?.orderDetails.weight
                          ? `${
                              order?.structuredData?.orderDetails.weight
                            } ${order?.structuredData?.orderDetails.weightUnit?.toLowerCase()}`
                          : "-"
                      }`}</Copy>
                      <Copy scale={10}>{`Distance: ${
                        order?.structuredData?.orderDetails.billDistance &&
                        order?.structuredData?.orderDetails.distanceUnitType
                          ? `${order?.structuredData?.orderDetails.billDistance} ${distanceTypeToText(
                              order?.structuredData?.orderDetails.distanceUnitType,
                            )}`
                          : "-"
                      } `}</Copy>
                      <Copy scale={10}>{`Commodity: ${
                        order?.structuredData?.orderDetails.commodities &&
                        order?.structuredData?.orderDetails.commodities.length > 0
                          ? order?.structuredData?.orderDetails.commodities[0].commodity
                          : ""
                      }`}</Copy>
                      <Copy scale={10}>{`Note: ${
                        order?.structuredData?.orderDetails.comments
                          ? order?.structuredData?.orderDetails.comments
                          : "-"
                      }`}</Copy>
                      <Copy scale={10}>{`Services: ${
                        order
                          ? servicesString(order.fromCarrierSpecialService, order.toCarrierSpecialService)
                          : "-"
                      }`}</Copy>
                    </Flex>
                  )
                }
              />
            </Grid>
          </Stack>
          <Spacer size={48} />
          <Stack space={32}>
            <Title as="h2" scale={6} color="theme-b-n3">
              Request execution info
            </Title>
            <Flex wrap css={{ rowGap: "$12", columnGap: "$32" }}>
              <Flex css={{ gap: "$4" }}>
                <Copy>Current Strategy:</Copy>
                <Copy
                  color={strategy?.currentStrategyType ? "brand-green-primary" : "theme-b-n3"}
                  fontWeight="bold"
                >
                  {strategyToText(strategy?.currentStrategyType as StrategyType)}
                </Copy>
              </Flex>
              <Flex css={{ gap: "$4" }}>
                <Copy>Strategy Deadline:</Copy>
                <Copy fontWeight="bold">
                  {strategy?.currentStrategyDeadline
                    ? `${dateTimeTzFormat({
                        dateTime: strategy?.currentStrategyDeadline,
                        timeZone: timeZoneFrom,
                      })}`
                    : "-"}
                </Copy>
              </Flex>
              <Flex css={{ gap: "$4" }}>
                <Copy>Next Strategy:</Copy>
                <Copy
                  color={strategy?.nextStrategyType ? "brand-green-primary" : "theme-b-n3"}
                  fontWeight="bold"
                >
                  {strategyToText(strategy?.nextStrategyType as StrategyType)}
                </Copy>
              </Flex>
              <Flex css={{ gap: "$4" }}>
                <Copy>Winner:</Copy>
                <Copy fontWeight="bold" id="winner_text">
                  {parseWinner(strategy?.winner).text}
                </Copy>
              </Flex>
              <Flex css={{ gap: "$4" }}>
                <Copy>Winning price:</Copy>
                <Copy fontWeight="bold">{parseWinner(strategy?.winner).price}</Copy>
              </Flex>
              <Flex css={{ gap: "$4" }}>
                <Copy>Market price:</Copy>
                <Copy fontWeight="bold" id="market_price">
                  {formatMarketRate(order?.marketRates)}
                </Copy>
              </Flex>
            </Flex>
            <Box
              css={{ backgroundColor: "$system-white", borderRadius: "$8", border: "1px solid $theme-n3-n7" }}
            >
              <ExecutionTableRow
                code={isIdInsteadScac ? "Carrier TMS" : "Carrier scac"}
                name="Carrier name"
                quote="Quote"
                strategy="Strategy"
                date="Date & Time"
                status="Status"
                details={(isForceTender && getDetailsHeader()) || ""}
                isTableHead
              />

              {!quotes || !carriersData || quotes.length === 0 ? (
                <Grid columns="1fr" css={{ padding: "$20 $16", textAlign: "center" }}>
                  <Copy color="theme-n6-n5">There is no data yet</Copy>
                </Grid>
              ) : (
                quotes.map((quote, index) => {
                  const carrier = (carriersData?.content || []).find((i) => i.id === quote.carrierId)
                  const date = quote?.updatedAt
                    ? `${dateTimeFormatInTimeZone({
                        dateTime: quote?.updatedAt,
                        timeZone: timeZoneFrom,
                        format: "MMM d, yyyy",
                        isConverted: true,
                      })}`
                    : "-"

                  const time = quote?.updatedAt
                    ? `${dateTimeFormatInTimeZone({
                        dateTime: quote?.updatedAt,
                        timeZone: timeZoneFrom,
                        format: "hh:mm aa (zzz)",
                        isConverted: true,
                      })}`
                    : "-"

                  return (
                    <>
                      <ExecutionTableRow
                        key={index}
                        id={carrier?.id}
                        code={isIdInsteadScac ? carrier?.code : carrier?.scac || "-"}
                        name={carrier?.name || "-"}
                        quote={quote.rateInCents ? `$${(quote.rateInCents / 100).toFixed(2)}` : "-"}
                        strategy={quote?.strategyType || ""}
                        date={date}
                        time={time}
                        status={quote.status}
                        details={
                          isForceTender
                            ? ((strategy?.currentStrategyType == StrategyType.Waterfall ||
                                strategy?.currentStrategyType == StrategyType.WaitAll ||
                                strategy?.currentStrategyType == StrategyType.WaitAllWithLanes) &&
                                order?.orderStatus == OrderStatusType.Tendering) ||
                              (order?.orderStatus == OrderStatusType.TenderingFinished && isDetails)
                              ? " "
                              : ""
                            : ""
                        }
                        detailsInfo={{
                          forceName: isDetails ? quote.forceSubmittedBy || " " : "",
                          date: quote.forceSubmittedBy
                            ? quote?.updatedAt
                              ? `${dateTimeFormatInTimeZone({
                                  dateTime: quote?.updatedAt,
                                  timeZone: timeZoneFrom,
                                  format: "hh:mm aa (zzz)",
                                  isConverted: true,
                                })}`
                              : ""
                            : "",
                          winnerName: parseWinner(strategy?.winner).text,
                        }}
                        carrierHandle={onViewClick}
                      />
                      <ForceTenderModal
                        carrierId={carrier?.id || ""}
                        rateInCents={quote.rateInCents || 0}
                        carrierName={carrier?.name || "-"}
                      />
                    </>
                  )
                })
              )}
            </Box>
          </Stack>
        </>

        {newCarrier && <CreateCarrierModal />}
        {newCarrier && <ViewCarrierModal />}
      </form>
    </FormProvider>
  )
}
