import { FieldValues } from "react-hook-form"
import { FormType, IFormInputControllerProps } from "@/shared/types"
import { MainController } from "@/shared/components"

export const TextFieldController = <FieldsType extends FieldValues>(
  props: IFormInputControllerProps<FieldsType>,
) => {
  const { labelProps, description, rules, type, autoFocus, prefix } = props

  return (
    <MainController<FieldsType>
      {...props}
      type={type || FormType.Text}
      rules={{
        shouldUnregister: true,
        required: {
          value: !!labelProps?.required,
          message: "Required field",
        },
        ...rules,
      }}
      label={description}
      labelProps={{ ...labelProps, hidden: true }}
      autoComplete="new-password"
      autoFocus={autoFocus}
      prefix={prefix}
    />
  )
}
