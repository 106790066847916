import { BaseIllustration, IIllustrationProps } from "../Illustration"

export const IllustrationLogoIcon = ({ theme = "light", ...props }: IIllustrationProps) => (
  <BaseIllustration {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 40" width="46" height="40" fill="none">
      <path
        fill={theme === "light" ? "var(--colors-system-white)" : "var(--colors-system-black)"}
        fillRule="evenodd"
        d="M16.889 31.411a6.547 6.547 0 0 0 4.043-5.787L21.953 0l-1.38 1.624a4.922 4.922 0 0 0-1.138 2.6L17.01 24.381a4.788 4.788 0 0 1-3.236 3.965L4.684 31.39l2.553 2.206a3.04 3.04 0 0 0 3.147.508l6.505-2.692Z"
        clipRule="evenodd"
      />
      <path
        fill={theme === "light" ? "var(--colors-system-white)" : "var(--colors-system-black)"}
        fillRule="evenodd"
        d="m3.906 28.843 7.367-2.267a4.715 4.715 0 0 0 3.253-3.648l2.857-15.467L15.47 9.84a3.286 3.286 0 0 0-.647 1.345c-.392 1.76-1.39 6.25-2.08 9.342a5.28 5.28 0 0 1-3.855 3.968l-2.095.533a3.312 3.312 0 0 0-2.323 2.148l-.564 1.667ZM29.111 31.411a6.548 6.548 0 0 1-4.043-5.787L24.046 0l1.381 1.624a4.922 4.922 0 0 1 1.138 2.6l2.426 20.157a4.788 4.788 0 0 0 3.236 3.965l9.089 3.043-2.553 2.206a3.04 3.04 0 0 1-3.147.508L29.11 31.41Z"
        clipRule="evenodd"
      />
      <path
        fill={theme === "light" ? "var(--colors-system-white)" : "var(--colors-system-black)"}
        fillRule="evenodd"
        d="m42.093 28.843-7.367-2.267a4.715 4.715 0 0 1-3.252-3.648L28.617 7.461l1.912 2.379c.316.393.538.853.647 1.345l2.08 9.342a5.28 5.28 0 0 0 3.856 3.968l2.094.533a3.313 3.313 0 0 1 2.323 2.148l.564 1.667Z"
        clipRule="evenodd"
      />
    </svg>
  </BaseIllustration>
)
