import { styled } from "@/stitches/config"
import { boxShadows } from "@/stitches/utils"

import { SModalCloseButton } from "./CloseButton.styles"

export const SModalContent = styled("div", {
  $$modalContentXPadding: 0,
  $$modalContentYPadding: 0,

  position: "relative",
  boxShadow: boxShadows.modal,
  maxHeight: "100%",
  maxWidth: "100%",
  overflow: "auto",
  backgroundColor: "$theme-w-n8",
  color: "$theme-b-n3",
  padding: "$$modalContentYPadding $$modalContentXPadding",

  variants: {
    scrollable: {
      true: {
        overflowY: "auto",
      },
    },
    size: {
      xs: {
        width: 340,
        height: "auto",
        minHeight: "auto",
      },

      sm: {
        minHeight: "auto",
        width: 420,
      },

      md: {
        minHeight: "100%",

        [`${SModalCloseButton}`]: {
          top: "$16",
          right: "$16",
        },

        "@sm": {
          minHeight: "auto",
          width: 560,
        },
      },

      lg: {
        minHeight: "100%",

        [`${SModalCloseButton}`]: {
          top: "$16",
          right: "$16",
        },

        "@sm": {
          minHeight: "auto",
          width: 950,
        },
      },

      xl: {
        minHeight: "100%",

        [`${SModalCloseButton}`]: {
          top: "$16",
          right: "$16",
        },

        "@sm": {
          minHeight: "auto",
          width: 1400,
        },
      },

      dimensionless: {
        minHeight: "100%",
      },
    },

    hasGutter: {
      true: {},
    },
  },

  compoundVariants: [
    {
      size: "xs",
      hasGutter: "true",
      css: {
        $$modalContentXPadding: "$space$24",
        $$modalContentYPadding: "$space$32",
      },
    },
    {
      size: "sm",
      hasGutter: "true",
      css: {
        $$modalContentXPadding: "$space$24",
        $$modalContentYPadding: "$space$40",
      },
    },
    {
      size: "md",
      hasGutter: "true",
      css: {
        $$modalContentXPadding: "$space$24",
        $$modalContentYPadding: "$space$40",

        "@sm": {
          $$modalContentXPadding: "$space$32",
        },
      },
    },
    {
      size: "lg",
      hasGutter: "true",
      css: {
        $$modalContentXPadding: "$space$24",
        $$modalContentYPadding: "$space$40",

        "@sm": {
          $$modalContentXPadding: "$space$32",
        },
      },
    },
    {
      size: "xl",
      hasGutter: "true",
      css: {
        $$modalContentXPadding: "$space$24",
        $$modalContentYPadding: "$space$40",

        "@sm": {
          $$modalContentXPadding: "$space$32",
        },
      },
    },
  ],

  defaultVariants: {
    hasGutter: true,
    scrollable: true,

    size: "md",
  },
})

export const SContentContainer = styled("div", {
  maxWidth: "100%",
  maxHeight: 500,
  overflowY: "scroll",
  marginRight: -15,

  "&::-webkit-scrollbar-track": {
    backgroundColor: "var(--colors-system-white)",
  },

  "&::-webkit-scrollbar-thumb": {
    border: "3px solid var(--colors-system-white)",
  },
})
