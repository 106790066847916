import { useFormContext } from "react-hook-form"

import {
  CarrierOrdersStatusType,
  CarrierTabType,
  IDashboardData,
  IDashboardFormData,
  ModalType,
  defaultTimezone,
} from "@/shared/types"
import { useModalActions } from "@/shared/hooks"
import { dateTimeTzFormat } from "@/shared/utils"
import { useGlobalStore } from "@/store"

import { Copy, GridItem, Flex, Link, CarrierOrdersStatus } from "@/shared/components"

import { CarrierOrdersTable } from "../CarrierOrdersTable"
import { CarrierOrderButtons } from "../CarrierOrderButtons"

export const OrderRow: React.FC<IDashboardData> = ({
  orderId,
  originalOrderId,
  from,
  to,
  pickupStartAt,
  dropOffStartAt,
  details,
  rate,
  status,
  updatedAt,
  hasOriginalRate,
}) => {
  const [carrierViewTab] = useGlobalStore((state) => [state.carrierViewTab])
  const { open } = useModalActions()
  const { setValue, reset, getValues } = useFormContext<IDashboardFormData>()

  const handlePriceClick = (e: React.SyntheticEvent) => {
    e.preventDefault()
    setValue("currentOrder", {
      orderId,
      originalOrderId,
      from,
      to,
      dropOffStartAt,
      pickupStartAt,
      details,
      rate,
      status,
      updatedAt,
      hasOriginalRate,
    })

    setTimeout(() => {
      reset(getValues())
      open(ModalType.CarrierOrderPrice)
    }, 0)
  }

  return (
    <CarrierOrdersTable key={originalOrderId}>
      <GridItem css={{ paddingX: "$16" }}>
        <Copy scale={10}>{originalOrderId}</Copy>
      </GridItem>
      <GridItem css={{ paddingX: "$16" }}>
        <Copy scale={10}>{from}</Copy>
      </GridItem>
      <GridItem css={{ paddingX: "$16" }}>
        <Copy scale={10}>{to}</Copy>
      </GridItem>
      <GridItem css={{ paddingX: "$16" }}>
        <Copy scale={10}>{`${dateTimeTzFormat({
          dateTime: pickupStartAt,
          timeZone: defaultTimezone,
          timeFormat: "MMM d, yyyy hh:mm aa (zzz)",
        })}`}</Copy>
      </GridItem>
      <GridItem css={{ paddingX: "$16" }}>
        <Copy scale={10}>{`${dateTimeTzFormat({
          dateTime: dropOffStartAt,
          timeZone: defaultTimezone,
          timeFormat: "MMM d, yyyy hh:mm aa (zzz)",
        })}`}</Copy>
      </GridItem>
      <GridItem css={{ paddingX: "$16" }}>
        <Copy scale={10}>{details}</Copy>
      </GridItem>
      <GridItem css={{ paddingX: "$16" }}>
        {hasOriginalRate ? (
          <Copy scale={10} fontWeight="bold">
            {`$${rate}`}
          </Copy>
        ) : carrierViewTab === CarrierTabType.Accepted || carrierViewTab === CarrierTabType.Rejected ? (
          "-"
        ) : (
          <Link
            scale={10}
            onClick={(e) => (carrierViewTab === CarrierTabType.Expired ? null : handlePriceClick(e))}
            clearLinkDefaultStyles
            fontWeight="bold"
            spanStyles={{
              minWidth: "max-content",
              cursor: carrierViewTab === CarrierTabType.Expired ? "default" : "pointer",
              color: carrierViewTab === CarrierTabType.Expired ? "$neutrals-4" : "$brand-violet-light",
              borderBottom:
                carrierViewTab === CarrierTabType.Expired ? "none" : "1px solid $brand-violet-light",
            }}
          >
            {rate ? `$${rate}` : "Enter price"}
          </Link>
        )}
      </GridItem>
      <GridItem css={{ paddingX: "$16" }}>
        {carrierViewTab === CarrierTabType.Pending || carrierViewTab === CarrierTabType.Expired ? (
          <CarrierOrderButtons
            disabled={carrierViewTab === CarrierTabType.Expired}
            {...{
              rate,
              status,
              originalOrderId,
              orderId,
              from,
              to,
              details,
              dropOffStartAt,
              pickupStartAt,
              updatedAt,
              hasOriginalRate,
            }}
          />
        ) : (
          <Flex direction="column" align="start" css={{ gap: "$8" }}>
            <CarrierOrdersStatus status={status === CarrierOrdersStatusType.Submitted} scale={8} filled />
            <Copy scale={10} color="neutrals-5">
              {`${dateTimeTzFormat({
                dateTime: updatedAt,
                timeZone: defaultTimezone,
                timeFormat: "MMM d, yyyy hh:mm aa (zzz)",
              })}`}
            </Copy>
          </Flex>
        )}
      </GridItem>
    </CarrierOrdersTable>
  )
}
