import { rgba } from "@/stitches/utils"

import { Flex } from "@/shared/components"

export const FooterWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Flex
      align="center"
      justify="end"
      css={{
        width: "100%",
        height: "$96",
        paddingX: "$88",
        backgroundColor: "$system-white",
        position: "fixed",
        bottom: 0,
        left: 0,
        zIndex: "$5",
        boxShadow: `0 0 8px 0 ${rgba("neutrals-5", 0.25)}`,
      }}
    >
      {children}
    </Flex>
  )
}
