import { useEffect } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form"

import { generateApiKey, scrollTo, generateSecretKey, showToast } from "@/shared/utils"
import { MANAGEMENT } from "@/constants"
import { ReferralKey, RouteParams, IOrganizationState, initialOrganizationState } from "@/shared/types"
import {
  useUpdateOrganization,
  useUpdateOrganizationSla,
  useUpdateOrganizationStrategies,
  useCreateOrganization,
} from "@/management/hooks"

import { CreateOrganizationButton, OrganizationGeneralInfo } from "@/management/components"

export const OrganizationForm = () => {
  const { organizationId } = useParams<keyof RouteParams>() as RouteParams
  const isEditMode = location.pathname.includes("edit")
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const referralPage = searchParams.get(`${ReferralKey.ReferralPage}`)

  const methods = useForm<IOrganizationState>({
    mode: "all",
    defaultValues: {
      ...initialOrganizationState,
      apiKey: generateApiKey(),
      carrierApiKey: generateApiKey(),
      apiSecret: generateSecretKey(generateApiKey()),
      carrierApiSecret: generateSecretKey(generateApiKey()),
    },
  })

  const {
    control,
    handleSubmit,
    getValues,
    formState: { isDirty },
  } = methods

  const { mutateAsync: createOrganization, isLoading: isCreateOrganizationLoading } = useCreateOrganization()
  const { mutateAsync: updateOrganization, isLoading: isUpdateOrganizationLoading } =
    useUpdateOrganization(organizationId)
  const { mutateAsync: updateOrganizationSla, isLoading: isUpdateOrganizationSlaLoading } =
    useUpdateOrganizationSla()
  const { mutateAsync: updateOrganizationStrategies, isLoading: isUpdateOrganizationStrategiesLoading } =
    useUpdateOrganizationStrategies()

  const isLoading =
    isCreateOrganizationLoading ||
    isUpdateOrganizationLoading ||
    isUpdateOrganizationSlaLoading ||
    isUpdateOrganizationStrategiesLoading

  const updateServices = async (values: IOrganizationState) => {
    if (isDirty) {
      try {
        await updateOrganization(values)

        navigate(
          isEditMode
            ? `${
                searchParams.has(ReferralKey.ReferralPage) ? referralPage + "/" + organizationId : MANAGEMENT
              } `
            : MANAGEMENT,
        )
      } catch (error) {
        return error
      }
    }
  }

  const onSubmitHandler: SubmitHandler<IOrganizationState> = async (values) => {
    isEditMode
      ? updateServices(values)
      : createOrganization(values, {
          onSuccess: async (data) => {
            try {
              await updateOrganizationSla({ orgId: data.id, data: getValues("sla") })
              await updateOrganizationStrategies({
                orgId: data.id,
                organizationType: data.organizationType,
                waterfallStrategy: getValues("waterfallStrategy"),
                waitAllStrategy: getValues("waitAllStrategy"),
                firstComeStrategy: getValues("firstComeStrategy"),
                criticalStrategy: getValues("criticalStrategy"),
                urgentDaysBeforePickup: getValues("urgentDaysBeforePickup"),
                urgentHoursBeforePickup: getValues("urgentHoursBeforePickup"),
              })
              showToast({ type: "success", text: "Saved successfully" })
              navigate(
                isEditMode
                  ? `${
                      searchParams.has(ReferralKey.ReferralPage)
                        ? referralPage + "/" + organizationId
                        : MANAGEMENT
                    } `
                  : MANAGEMENT,
              )
            } catch (error) {
              navigate(`${MANAGEMENT}/edit/organization/${data.id}`)
            }
          },
        })
  }

  useEffect(() => {
    scrollTo({ position: { top: 0, left: 0 } })
  }, [])

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmitHandler)} noValidate autoComplete="off">
        <OrganizationGeneralInfo />
        <CreateOrganizationButton isLoading={isLoading} />
        <Controller name="id" control={control} render={() => <></>} />
        <Controller name="name" control={control} render={() => <></>} />
      </form>
    </FormProvider>
  )
}
