import { Spacer, Title } from "@/shared/components"
import { ProfileForm } from "@/profile/components"

export const ProfileContainer = () => {
  return (
    <>
      <Title as="h1" scale={2} color="system-black">
        User Profile
      </Title>
      <Spacer size={40} />
      <ProfileForm />
    </>
  )
}
