import { ISla, ISlaItem } from "@/shared/types"
import { getSlaNodeFieldType } from "@/shared/utils"

export const transformOrganizationSlaResponse = (data: ISlaItem[]): ISla[] => {
  return data.reduce((acc, content) => {
    const name = getSlaNodeFieldType(content.nodes || [])

    if (!name) {
      return acc
    }

    return [
      ...acc,
      {
        name,
        content,
      },
    ]
  }, [] as ISla[])
}
