import { useFormContext } from "react-hook-form"

import { IOrganizationState } from "@/shared/types"

import { Box, DecimalController, Grid, GridItem, PercentController, Stack } from "@/shared/components"

export const Analytics = () => {
  const {
    control,
    register,
    formState: { errors },
    trigger,
  } = useFormContext<IOrganizationState>()

  return (
    <Box css={{ height: 420 }}>
      <Stack space={32}>
        <Grid columns="1fr 1fr 1fr 1fr" columnGap={16}>
          <GridItem>
            <DecimalController<IOrganizationState>
              name="newCarrier.analytics.claimsRatio"
              control={control}
              register={register}
              trigger={trigger}
              description="Claims ratio, %"
              error={errors.newCarrier?.analytics?.claimsRatio?.message}
            />
          </GridItem>
          <GridItem>
            <PercentController<IOrganizationState>
              name="newCarrier.analytics.tenderAcceptance"
              control={control}
              register={register}
              trigger={trigger}
              description="Tender acceptance, %"
              error={errors.newCarrier?.analytics?.tenderAcceptance?.message}
            />
          </GridItem>
          <GridItem>
            <PercentController<IOrganizationState>
              name="newCarrier.analytics.ediCompliance"
              control={control}
              register={register}
              trigger={trigger}
              description="EDI compliance, %"
              error={errors.newCarrier?.analytics?.ediCompliance?.message}
            />
          </GridItem>
          <GridItem>
            <PercentController<IOrganizationState>
              name="newCarrier.analytics.otp"
              control={control}
              register={register}
              trigger={trigger}
              description="On-time pickup, %"
              error={errors.newCarrier?.analytics?.otp?.message}
            />
          </GridItem>
        </Grid>
        <Grid columns="1fr 1fr 1fr 1fr" columnGap={16}>
          <GridItem>
            <PercentController<IOrganizationState>
              name="newCarrier.analytics.otd"
              control={control}
              register={register}
              trigger={trigger}
              description="On-time delivery, %"
              error={errors.newCarrier?.analytics?.otd?.message}
            />
          </GridItem>
          <GridItem>
            <PercentController<IOrganizationState>
              name="newCarrier.analytics.invoiceCompliance"
              control={control}
              register={register}
              trigger={trigger}
              description="Invoice compliance, %"
              error={errors.newCarrier?.analytics?.invoiceCompliance?.message}
            />
          </GridItem>
        </Grid>
      </Stack>
    </Box>
  )
}
