import { memo, useCallback, useEffect, useRef, useState } from "react"
import { useFormContext } from "react-hook-form"

import { ICarrierContact, ILaneContact, IOrganizationState } from "@/shared/types"
import { escapeKeyDown, filterArrayBySubString } from "@/shared/utils"

import {
  Box,
  ButtonIcon,
  Copy,
  Divider,
  FormCheckbox,
  FormInput,
  Popover,
  PopoverAnchor,
  PopoverContent,
  ResponseContentItem,
  Stack,
} from "@/shared/components"
import { IconChevronTop, IconChevronDown, IconSearch, IconCross } from "@/shared/icons"

import { ContactsSelectSPopoverAnchorButton } from "@/shared/styles/SPopoverAnchorButton"

interface IContactsSelectFieldProps {
  index: number
}

export const ContactsSelectField = memo(({ index }: IContactsSelectFieldProps) => {
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [isCheckPartially, setIsCheckPartially] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [inputValue, setInputValue] = useState<string>("")
  const triggerRef = useRef<HTMLButtonElement>(null)
  const isTriggerClick = (event: Event) => event.composedPath().includes(triggerRef.current as EventTarget)
  const clearButtonRef = useRef<HTMLButtonElement>(null)
  const [filteredContacts, setFilteredContacts] = useState<ICarrierContact[]>([])
  const { setValue, watch } = useFormContext<IOrganizationState>()
  const { newCarrier } = watch()

  const selectedContacts = newCarrier.lanes[index].contacts
  const carrierContacts = newCarrier.contacts

  const handleChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      if (!event.currentTarget.checked) {
        const newArray = selectedContacts
          .filter((i) => `${i.id}` !== event.currentTarget.value)
          .map((el) => {
            return { id: el.id || null } as ILaneContact
          })
        setValue(`newCarrier.lanes.${index}.contacts`, newArray, { shouldValidate: true, shouldDirty: true })
      } else {
        const selectedContact = carrierContacts.find((i) => i.id == event.currentTarget.value)
        const newArray = [...selectedContacts, { id: selectedContact?.id || null } as ILaneContact]
        setValue(`newCarrier.lanes.${index}.contacts`, newArray, { shouldValidate: true, shouldDirty: true })
      }
    },
    [setValue, index, carrierContacts, selectedContacts],
  )

  const handleCheckAllClick = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      if (!event.currentTarget.checked) {
        setValue(`newCarrier.lanes.${index}.contacts`, [], { shouldValidate: true, shouldDirty: true })
      } else {
        setValue(
          `newCarrier.lanes.${index}.contacts`,
          carrierContacts.map((el) => {
            return { id: el?.id || null } as ILaneContact
          }),
          { shouldValidate: true, shouldDirty: true },
        )
      }
    },
    [setValue, index, carrierContacts],
  )

  const filterNameBySubStr = (subStr: string) => {
    carrierContacts.length && setFilteredContacts(filterArrayBySubString(carrierContacts, subStr, "name"))
  }

  useEffect(() => {
    if (selectedContacts.length > 0) {
      if (selectedContacts.length === carrierContacts.length) {
        setIsCheckAll(true)
        setIsCheckPartially(false)
      } else {
        setIsCheckAll(false)
        setIsCheckPartially(true)
      }
    } else {
      setIsCheckAll(false)
      setIsCheckPartially(false)
    }
  }, [carrierContacts.length, selectedContacts.length])

  useEffect(() => {
    setFilteredContacts(carrierContacts)
  }, [carrierContacts])

  return (
    <Popover open={isOpen}>
      <PopoverAnchor asChild onKeyDown={(e: { key: string }) => escapeKeyDown(e.key) && setIsOpen(false)}>
        <ContactsSelectSPopoverAnchorButton
          ref={triggerRef}
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          active={isOpen}
        >
          <Copy as="span" fontWeight="medium" scale={8}>
            {selectedContacts.length || "Not"} selected
          </Copy>
          {isOpen ? <IconChevronTop size="xs" /> : <IconChevronDown size="xs" />}
        </ContactsSelectSPopoverAnchorButton>
      </PopoverAnchor>
      <PopoverContent
        close={() => setIsOpen(false)}
        align="start"
        css={{
          width: 340,
          keyboardFocus: {
            outline: "1px solid $theme-vl-n3",
          },
          borderRadius: "$8",
        }}
        alignOffset={-1}
        onInteractOutside={(event) => {
          if (isTriggerClick(event)) {
            return
          }
          return setIsOpen(false)
        }}
        onOpenAutoFocus={(event) => {
          event.preventDefault()
        }}
      >
        <Stack space={0} css={{ paddingX: "$16", "@md": { paddingX: 0 } }}>
          <Box css={{ padding: "$12 $16" }}>
            <FormInput
              id="Search field selectedCustomers"
              value={inputValue}
              label="Search for name"
              labelProps={{ hidden: true }}
              placeholder="Search for name"
              onChange={(event) => {
                setInputValue(event.target.value)
                filterNameBySubStr(event.target.value)
              }}
              prefix={<IconSearch />}
              suffix={
                inputValue && (
                  <ButtonIcon
                    ref={clearButtonRef}
                    icon={<IconCross />}
                    ariaLabel="Clear button"
                    onClick={() => {
                      setInputValue("")
                      filterNameBySubStr("")
                    }}
                    inputIcon
                  />
                )
              }
              autoComplete="new-password"
            />
          </Box>
          <ResponseContentItem>
            <FormCheckbox
              value="All"
              onChange={handleCheckAllClick}
              name="Select all selectedCustomers"
              id="Select all selectedCustomers"
              label="Select all"
              checked={isCheckAll || isCheckPartially}
              iconType={isCheckAll ? "tick" : "hyphen"}
            />
          </ResponseContentItem>
          <Divider />
          <Box css={{ height: "max-content", maxHeight: 300, overflow: "auto" }}>
            {filteredContacts.length === 0 ? (
              <Box css={{ padding: "$16" }}>
                <Copy color="theme-b-n3">Not found</Copy>
              </Box>
            ) : (
              filteredContacts.map((contact) => (
                <ResponseContentItem key={contact.id}>
                  <FormCheckbox
                    value={contact.id}
                    onChange={handleChange}
                    name={contact.name}
                    id={contact.id}
                    label={
                      <Box as="span" css={{ span: { whiteSpace: "initial" } }}>
                        <Copy as="span" color="theme-b-n3" scale={10}>
                          {contact.name || "-"}
                        </Copy>
                      </Box>
                    }
                    checked={selectedContacts.some((i) => i.id == contact.id)}
                  />
                </ResponseContentItem>
              ))
            )}
          </Box>
        </Stack>
      </PopoverContent>
    </Popover>
  )
})

ContactsSelectField.displayName = "ContactsSelectField"
