import axios from "axios"

import { TASKS_BASE_URI } from "@/constants"

export const tasksApi = axios.create({
  baseURL: TASKS_BASE_URI,
})

tasksApi.defaults.headers.common["Content-Type"] = "application/json"

export const checkTaskStatusFn = async (taskName: string) => {
  const { data } = await tasksApi.get<string>(`task-scheduler/check-task-status?taskName=${taskName}`)

  return data
}

export const startTasksFn = async () => {
  const { data } = await tasksApi.post("task-scheduler/start-all-tasks")

  return data
}

export const stopTasksFn = async () => {
  const { data } = await tasksApi.post("task-scheduler/stop-all-tasks")

  return data
}
