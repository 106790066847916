import { useCallback } from "react"
import { useParams } from "react-router-dom"

import { useModal, useModalActions } from "@/shared/hooks"
import { ModalType, RouteParams } from "@/shared/types"

import { Button, Copy, Flex, Stack, Title } from "@/shared/components"
import { GeneralModal } from "@/modals"
import { useForceTenderSubmit } from "@/shared/data"
import { transformOrderId } from "@/shared/utils"

export const ForceTenderModal = ({
  carrierId,
  carrierName,
  rateInCents,
}: {
  carrierId: string
  carrierName: string
  rateInCents: number
}) => {
  const { orderId, organizationId } = useParams<keyof RouteParams>() as RouteParams
  const { modalProps } = useModal(ModalType.ForceTenderModal + carrierId)
  const { close } = useModalActions()
  const { mutateAsync: forceTenderSubmit } = useForceTenderSubmit({
    carrierName,
    orderId,
  })

  const handleContinueClick = useCallback(() => {
    forceTenderSubmit({ orgId: organizationId, carrierId, rateInCents })
    close(ModalType.ForceTenderModal + carrierId)
  }, [close, carrierId, forceTenderSubmit, organizationId, rateInCents])

  return (
    <GeneralModal
      {...modalProps}
      footer={
        <Flex justify="end" css={{ gap: "$16", paddingBottom: "$8" }}>
          <Button onClick={handleContinueClick}>Yes, assign</Button>
          <Button action="secondary" onClick={() => close(ModalType.ForceTenderModal + carrierId)}>
            Cancel
          </Button>
        </Flex>
      }
    >
      <Stack space={12} css={{ padding: "$64 $24 $0" }}>
        <Title as="h3" scale={2} color="system-black">
          Assign {carrierName} as winning carrier of order {transformOrderId(orderId, organizationId)} at the
          rate ${rateInCents ? (rateInCents / 100).toFixed(2) : 0}?
        </Title>
        <Copy scale={10} color="theme-n6-n5" css={{ paddingBottom: "$8" }}>
          You won’t be able to change this later.
        </Copy>
      </Stack>
    </GeneralModal>
  )
}
