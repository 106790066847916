import { styled } from "@/stitches/config"

export const SLink = styled("a", {
  reset: true,
  cursor: "pointer",
  textDecoration: "none",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  color: "$system-black",
  transition: "150ms color ease-out",
  position: "relative",

  hover: {
    textDecoration: "underline",
    textUnderlineOffset: "$space$4",
  },

  keyboardFocus: {
    textDecoration: "underline",
    textUnderlineOffset: "$space$4",
  },

  disabled: {
    color: "$neutrals-4",
  },
})

export const SLinkIcon = styled("div", {
  display: "flex",
  marginRight: "$4",
  marginTop: "$2",
})
