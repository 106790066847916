import { memo, useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useFormContext } from "react-hook-form"

import {
  useAllUrgentContacts,
  useCurrentAndAvailableSource,
  useOrganizationById,
  useOrganizationCarriersFull,
  useOrganizationStrategies,
} from "@/management/hooks"
import { useModalActions } from "@/shared/hooks"
import { MANAGEMENT } from "@/constants"
import { ModalType, RouteParams, StrategyType, IOrganizationState, OrganizationType } from "@/shared/types"

import { Box, Button, ButtonIcon, Copy, Flex, Spinner, Stack, Title } from "@/shared/components"

import { useGlobalStore } from "@/store"
import { IconPencil } from "@/shared/icons"
import { getUserRole, isReader, transformStrategy } from "@/shared/utils"

import { ExecutionStrategyView } from "@/management/components"
import { ExecutionStrategyModal, UrgentContactsModal } from "@/modals"

export const OrganizationExecutionStrategy = memo(() => {
  const { organizationId } = useParams<keyof RouteParams>() as RouteParams
  const [isBroker, setIsBroker] = useState(false)
  const navigate = useNavigate()
  const user = useGlobalStore((state) => state.user)
  const role = getUserRole(user)
  const isRoleReader = isReader(role)
  const { open } = useModalActions()

  const { watch, setValue } = useFormContext<IOrganizationState>()
  const [inputValue, setInputValue] = useState<string>("")
  const [strategyType, setStrategyType] = useState(StrategyType.Waterfall)
  const [page, setPage] = useState(0)

  const {
    waterfallStrategy,
    waitAllStrategy,
    firstComeStrategy,
    criticalStrategy,
    urgentDaysBeforePickup,
    urgentHoursBeforePickup,
  } = watch()

  const { data: organization, isLoading: organizationLoading } = useOrganizationById(organizationId)
  const { isInitialLoading: organizationStrategiesLoading, data: strategiesData } = useOrganizationStrategies(
    organizationId,
    organization?.organizationType,
  )
  const { data: currentMarketSource, isInitialLoading: currentMarketSourceLoading } =
    useCurrentAndAvailableSource(organizationId)
  const { isInitialLoading: urgentContactsLoading, data: currentUrgentContacts } = useAllUrgentContacts({
    id: organizationId,
  })

  const { data: waterfallCarriersData = { content: [] }, isFetching: waterfallFetching } =
    useOrganizationCarriersFull({
      orgId: organizationId,
      name: inputValue,
      page,
      hasLanesPrice: true,
      disableCache: true,
    })

  const { data: waitAllCarriersDataInternal = { content: [] }, isFetching: waitAllFetching } =
    useOrganizationCarriersFull({
      orgId: organizationId,
      name: inputValue,
      page,
      lanesNoPrice: true,
      disableCache: true,
      isGetData: isBroker,
    })

  const isLoading = organizationStrategiesLoading || currentMarketSourceLoading || organizationLoading

  const isBrokerOrganization = useMemo(() => {
    return organization?.organizationType === OrganizationType.Broker
  }, [organization])

  useEffect(() => {
    setIsBroker(isBrokerOrganization)
  }, [isBrokerOrganization])

  useEffect(() => {
    if (strategiesData && waterfallCarriersData) {
      setValue(
        "waterfallStrategy",
        transformStrategy(
          waterfallCarriersData,
          strategiesData.waterfallStrategy,
          StrategyType.Waterfall,
          waterfallStrategy.totalElements,
        ),
      )

      if (isBroker && waitAllCarriersDataInternal) {
        setValue(
          "waitAllStrategy",
          transformStrategy(
            waitAllCarriersDataInternal,
            strategiesData.waitAllStrategy,
            StrategyType.WaitAll,
            waitAllStrategy.totalElements,
          ),
        )
      } else {
        setValue("waitAllStrategy", strategiesData.waitAllStrategy)
      }
      setValue("firstComeStrategy", strategiesData.firstComeStrategy)
      setValue("criticalStrategy", strategiesData.criticalStrategy)
      setValue("urgentDaysBeforePickup", strategiesData.urgentDaysBeforePickup)
      setValue("urgentHoursBeforePickup", strategiesData.urgentHoursBeforePickup)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    strategiesData,
    waterfallCarriersData,
    setValue,
    waterfallStrategy.totalElements,
    isBroker,
    waitAllStrategy.totalElements,
  ])

  return (
    <>
      {isLoading ? (
        <Flex align="center" justify="center" css={{ height: 500 }}>
          <Spinner />
        </Flex>
      ) : (
        <>
          <Stack space={32}>
            <Flex css={{ gap: "$16" }}>
              <Title as="h2" scale={6} color="system-black">
                Execution strategy
              </Title>
              {!isRoleReader && (
                <ButtonIcon
                  type="button"
                  ariaLabel="Edit sla"
                  icon={<IconPencil />}
                  onClick={() => {
                    navigate(`${MANAGEMENT}/edit/organization/strategies/${organizationId}`)
                  }}
                  inputIcon
                />
              )}
            </Flex>
            <Stack space={24}>
              <Flex css={{ gap: "$32" }}>
                <Flex align="center" css={{ gap: "$4" }}>
                  <Copy color="theme-b-n3">Send urgent emails:</Copy>
                  {urgentDaysBeforePickup || urgentHoursBeforePickup ? (
                    <Copy color="theme-b-n3" fontWeight="bold">
                      {`${urgentDaysBeforePickup || urgentHoursBeforePickup} ${
                        urgentDaysBeforePickup
                          ? urgentDaysBeforePickup === "1"
                            ? "day"
                            : "days"
                          : urgentHoursBeforePickup === "1"
                          ? "hour"
                          : "hours"
                      } before Pickup`}
                    </Copy>
                  ) : (
                    <Copy color="theme-b-n3">-</Copy>
                  )}
                </Flex>
                <Flex align="center" css={{ gap: "$4" }}>
                  <Copy color="theme-b-n3">Market rate source:</Copy>
                  {currentMarketSource?.currentMarketRateSource?.name ? (
                    <Copy color="theme-b-n3" fontWeight="bold">
                      {currentMarketSource.currentMarketRateSource.name}
                    </Copy>
                  ) : (
                    <Copy color="theme-b-n3">-</Copy>
                  )}
                </Flex>
                {!urgentContactsLoading && currentUrgentContacts && (
                  <Button
                    action="tertiary"
                    type="button"
                    size="medium"
                    onClick={() => {
                      open(ModalType.ViewUrgentContacts)
                    }}
                  >
                    See urgent contact list
                  </Button>
                )}
              </Flex>
            </Stack>

            <Box
              css={{
                backgroundColor: "$system-white",
                borderRadius: "$8",
                padding: "$40 $56",
                border: "1px solid $theme-n3-n7",
              }}
            >
              {isLoading ||
              !(
                waterfallStrategy.enabled ||
                (isBroker && firstComeStrategy.enabled) ||
                (isBroker && waitAllStrategy.enabled) ||
                criticalStrategy.enabled ||
                waitAllStrategy.enabled
              ) ? (
                <Copy>There are no active strategies</Copy>
              ) : (
                <ExecutionStrategyView setStrategyType={setStrategyType} />
              )}
            </Box>
          </Stack>

          <ExecutionStrategyModal
            {...{
              strategyType,
              inputValue,
              setInputValue,
              page,
              setPage,
              waterfallFetching,
              waitAllFetching,
            }}
          />
          {currentUrgentContacts && <UrgentContactsModal {...{ currentUrgentContacts }} />}
        </>
      )}
    </>
  )
})

OrganizationExecutionStrategy.displayName = "OrganizationExecutionStrategy"
