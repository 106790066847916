import { useMutation, useQueryClient } from "@tanstack/react-query"
import { isAxiosError } from "axios"

import { showToast } from "@/shared/utils"
import {forceTenderSubmitToAnyFn} from "@/api/tenderApi"

type ForceTenderSubmitToAnyType = { 
  orderId: string, 
  carrierId: string, 
  rateInCents: number, 
}

export const useForceTenderSubmitToAny = () => {
  const queryClient = useQueryClient()
  return useMutation(({carrierId, orderId, rateInCents }: ForceTenderSubmitToAnyType ) =>
    forceTenderSubmitToAnyFn({ carrierId, orderId, rateInCents }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["fullOrderById"],
        })
        queryClient.invalidateQueries({
          queryKey: ["quotesByOrderId"],
        })

        showToast({
          type: "success",
          text: "Carrier has been assigned to the order",
        })
      },
      onError: (error) => {
        if (isAxiosError(error)) {
          showToast({ type: "error", text: error.response?.data || error.message })
        }
      },
    },
  )
}
