import { useMutation, useQueryClient } from "@tanstack/react-query"
import { isAxiosError } from "axios"

import { updateCarriersCsvFn } from "@/api/slaApi"
import { showToast } from "@/shared/utils"

export const useUploadCarriersCSV = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ orgId, formData }: { orgId: string; formData: FormData }) => {
      return updateCarriersCsvFn(orgId, formData)
    },
    {
      onMutate: () => {
        showToast({ type: "info", text: "File is uploading" })
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries({
          queryKey: ["organizationCarriers"],
        })
        if (data.length && data.length > 0) {
          showToast({ type: "success", text: "Uploaded successfully" })
        } else {
          showToast({ type: "error", text: "Upload failed" })
        }
      },
      onError: (error) => {
        if (isAxiosError(error)) {
          showToast({
            type: "error",
            text: `Upload failed. ${error.response?.data || error.message}`,
          })
        }
      },
    },
  )
}
