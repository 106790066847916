import axios from "axios"

import { MRKTS_BASE_URI } from "@/constants"
import { ICurrentAndAvailableSource, ICurrentSource } from "@/shared/types"

export const mrktsApi = axios.create({
  baseURL: MRKTS_BASE_URI,
})

mrktsApi.defaults.headers.common["Content-Type"] = "application/json"

export const getCurrentAndAvailableSourceFn = async (orgId: string): Promise<ICurrentAndAvailableSource> => {
  const { data } = await mrktsApi.get<ICurrentAndAvailableSource>(
    `market-rate-source/find-current-and-available?orgId=${orgId}`,
  )
  return data
}

export const updateCurrentSourceFn = async (currentSource: ICurrentSource, orgId: string) => {
  const { data } = await mrktsApi.post(`market-rate-source?orgId=${orgId}`, {
    currentMarketRateSource: currentSource,
  })
  return data
}
