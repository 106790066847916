import { useEffect } from "react"

export const useZoomAdjustment = () => {
  useEffect(() => {
    const updateZoom = () => {
      // document.documentElement.style.cssText += `zoom: ${window.devicePixelRatio > 1 ? 0.8 : 1};`
    }

    window.addEventListener("resize", updateZoom)
    window.addEventListener("DOMContentLoaded", updateZoom)
    window.addEventListener("load", updateZoom)

    updateZoom()

    return () => {
      window.removeEventListener("resize", updateZoom)
      window.removeEventListener("DOMContentLoaded", updateZoom)
      window.removeEventListener("load", updateZoom)
    }
  }, [])
}
