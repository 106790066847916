import { styled } from "@/stitches/config"
import { boxShadows, easing } from "@/stitches/utils"

export const SSwitchIndicator = styled("div", {
  position: "absolute",
  top: "-$2",
  zIndex: "-$1",
  width: "$20",
  height: "$20",
  borderRadius: "$rounded",
  boxShadow: boxShadows.switch,
  transform: "translateX(0)",
  transition: easing.smooth({
    property: "transform",
    duration: 150,
  }),

  variants: {
    checked: {
      true: {
        backgroundColor: "$system-black",
        transform: "translateX($16)",
        boxShadow: "none",
      },
    },
  },
})

export const SSwitchOption = styled("label", {
  cursor: "pointer",

  before: {
    content: "''",
    position: "absolute",
    top: "-$2",
    width: "$20",
    height: "$20",
    backgroundColor: "$system-white",
    borderRadius: "$rounded",
    transition: easing.smooth({
      property: "transform",
      duration: 150,
    }),
  },
})

export const SSwitchOptionBox = styled("span", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // INFO: we need 36px for a more nice focus state
  width: "calc($32 + $4)",
  height: "$16",
  backgroundColor: "$neutrals-5",
  borderRadius: "$pill",
  zIndex: "$1",
  outline: "2px solid transparent",
  outlineOffset: "$space$4",
})

export const SSwitchOptionInput = styled("input", {
  srOnly: true,

  keyboardFocus: {
    [`+ ${SSwitchOptionBox}`]: {
      outline: "2px solid $brand-yellow-primary",
    },
  },
})

export const SSwitch = styled("div", {
  display: "flex",
  position: "relative",
  top: 1,
  right: "$2",

  variants: {
    checked: {
      true: {
        [`${SSwitchOption}`]: {
          before: {
            backgroundColor: "$system-black",
            transform: "translateX($16)",
          },
        },

        hover: {
          [`${SSwitchIndicator}`]: {
            boxShadow: "0 0 0 5px var(--colors-theme-n3-n7)",
          },
        },
      },
      false: {
        [`${SSwitchOption}`]: {
          before: {
            transform: "translateX(0)",
          },
        },

        hover: {
          [`${SSwitchIndicator}`]: {
            boxShadow: `${boxShadows.switch}, 0 0 0 5px var(--colors-theme-n3-n7)`,
          },
        },
      },
    },
    disabled: {
      true: {
        [`${SSwitchOptionBox}`]: {
          backgroundColor: "$neutrals-4",
        },

        hover: {
          [`${SSwitchIndicator}`]: {
            boxShadow: `${boxShadows.switch} !important`,
          },
        },
      },
    },
  },

  compoundVariants: [
    {
      checked: false,
      disabled: true,
      css: {
        [`${SSwitchOption}`]: {
          before: {
            backgroundColor: "$system-white",
            transform: "translateX(0)",
          },
        },
      },
    },
    {
      checked: true,
      disabled: true,
      css: {
        [`${SSwitchIndicator}`]: {
          backgroundColor: "$neutrals-5",
        },
        [`${SSwitchOption}`]: {
          before: {
            backgroundColor: "$neutrals-5",
            transform: "translateX($16)",
          },
        },
      },
    },
  ],
})
