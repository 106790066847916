import React, { ChangeEventHandler, useCallback, useRef } from "react"

import { enterKeyDown } from "@/shared/utils"

import type { SwitchOptionProps } from "./Option"
import { SwitchIndicator } from "./Indicator"

import { SSwitch } from "./Switch.styles"

export interface SwitchProps<T> {
  children: React.ReactNode

  name: string
  value: T

  onValueChange: (value: T) => void

  checked?: boolean
  disabled?: boolean
}

export const Switch = <T extends string>({
  name,
  value,
  onValueChange,
  checked,
  disabled,
  children,
}: SwitchProps<T>) => {
  const ref = useRef<HTMLDivElement>(null)

  const isChecked = (props: SwitchOptionProps) => props.value === value

  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => onValueChange(event.target.value as T),
    [onValueChange],
  )

  return (
    <SSwitch
      ref={ref}
      disabled={disabled}
      checked={checked}
      onKeyDown={(e) => {
        enterKeyDown(e.key) && e.preventDefault()
      }}
    >
      {React.Children.map(
        children,
        (child) =>
          React.isValidElement(child) &&
          React.cloneElement(child as React.ReactElement, {
            name,
            checked: isChecked(child.props),
            onChange,
          }),
      )}
      <SwitchIndicator checked={checked} />
    </SSwitch>
  )
}
