import { useFormContext } from "react-hook-form"

import { LANES_MAX } from "@/constants"
import { AddItemButton, Box, Copy, Flex, Spacer } from "@/shared/components"
import { IFlsFiltersState } from "@/shared/types"

import { FlsLanesTableRow } from "./FlsLanesTableRow"
import { FlsLanesTablePlaceholder } from "./FlsLanesTablePlaceholder"

export const FlsLanes = ({
  isEditMode = false,
  isLoading,
}: {
  isEditMode?: boolean
  isLoading?: boolean
}) => {
  const { trigger, setValue, watch } = useFormContext<IFlsFiltersState>()

  const { lanesFilter } = watch()
  const { filterData } = lanesFilter

  return (
    <Box css={{ marginTop: "-$8" }}>
      {isEditMode ? (
        <>
          <AddItemButton
            text="Add lane"
            onClick={() => {
              setValue(
                "lanesFilter.filterData",
                [
                  {
                    id: `id-${Date.now()}`,
                    fromCity: "",
                    fromState: "",
                    toCity: "",
                    toState: "",
                  },
                  ...filterData,
                ],
                {
                  shouldDirty: true,
                },
              )
              setTimeout(() => {
                trigger("lanesFilter.filterData")
              }, 0)
            }}
            disabled={filterData.length >= LANES_MAX}
            css={{ width: "fit-content" }}
          />
          <Spacer size={24} />
        </>
      ) : null}

      {isLoading ? (
        <FlsLanesTablePlaceholder />
      ) : (
        <Box>
          <FlsLanesTableRow
            fromCity="From, city*"
            fromState="From, state*"
            toCity="To, city*"
            toState="To, state*"
            isTableHead
          />
          {filterData.length > 0 ? (
            filterData.map(({ id, fromCity, fromState, toCity, toState }, index) => (
              <FlsLanesTableRow
                key={index}
                index={index}
                id={id}
                fromCity={fromCity}
                fromState={fromState}
                toCity={toCity}
                toState={toState}
                isViewMode={!isEditMode}
              />
            ))
          ) : (
            <Flex
              align="center"
              justify="center"
              css={{
                height: "$64",
                color: "$theme-b-n3",
              }}
            >
              <Copy color="theme-n6-n5">There is no data yet</Copy>
            </Flex>
          )}
        </Box>
      )}
    </Box>
  )
}
