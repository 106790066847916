import {
  Accordion,
  AccordionButton,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Box,
  Redacted,
} from "@/shared/components"

export const ExecutionStrategyPreloader = () => {
  return (
    <Box
      css={{
        height: "30vw",
        width: "100%",
      }}
    >
      <Accordion multiple>
        <AccordionItem value="holder1">
          <AccordionHeader>
            <AccordionButton>
              <Redacted width="20%" />
            </AccordionButton>
          </AccordionHeader>
          <AccordionPanel>
            <Redacted width="50%" height="$56" />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem value="holder2">
          <AccordionHeader>
            <AccordionButton>
              <Redacted width="20%" />
            </AccordionButton>
          </AccordionHeader>
          <AccordionPanel>
            <Redacted width="50%" height="$56" />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem value="holder3">
          <AccordionHeader>
            <AccordionButton>
              <Redacted width="20%" />
            </AccordionButton>
          </AccordionHeader>
          <AccordionPanel>
            <Redacted width="50%" height="$56" />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  )
}
