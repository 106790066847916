import { Flex, Spacer, Title } from "@/shared/components"
import { Task } from "@/tasks/components"

const tasks = [
  {
    label: "McLeod",
    name: "MC_LEOD_API",
  },
]

export const TasksContainer = () => {
  return (
    <>
      <Title as="h1" scale={2} color="system-black">
        Tasks
      </Title>
      <Spacer size={32} />
      <Flex wrap css={{ gap: "$24" }}>
        {tasks.map(({ label, name }) => (
          <Task key={label} label={label} name={name} />
        ))}
      </Flex>
    </>
  )
}
