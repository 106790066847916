import { useMutation, useQueryClient } from "@tanstack/react-query"
import { isAxiosError } from "axios"

import { showToast } from "@/shared/utils"
import { forceTenderSubmitFn } from "@/api/tenderApi"

export const useForceTenderSubmit = ({ carrierName, orderId }: { carrierName: string; orderId: string }) => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ orgId, carrierId, rateInCents }: { orgId: string; carrierId: string; rateInCents: number }) =>
      forceTenderSubmitFn({ orgId, carrierId, orderId, rateInCents }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["fullOrderById"],
        })
        queryClient.invalidateQueries({
          queryKey: ["organizationCarriersFull"],
        })
        queryClient.invalidateQueries({
          queryKey: ["quotesByOrderId"],
        })

        showToast({
          type: "success",
          text: `${carrierName} has been assigned to order ${orderId}`,
        })
      },
      onError: (error) => {
        if (isAxiosError(error)) {
          showToast({ type: "error", text: error.response?.data || error.message })
        }
      },
    },
  )
}
