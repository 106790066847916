import { useEffect } from "react"
import { useFormContext } from "react-hook-form"

import { ISla, SlaField, SlaItemType, IOrganizationState } from "@/shared/types"

import { Copy, Flex, Stack } from "@/shared/components"
import { useOrganizationForm } from "@/management/components"

import { SlaRuleItem, SlaGroupItem, AddButton } from "./components"

export const SlaContent = ({
  name,
  slaData = [],
  isViewMode,
}: {
  name: SlaField
  slaData?: ISla[]
  isViewMode?: boolean
}) => {
  const { watch } = useFormContext<IOrganizationState>()
  const { sla } = watch()

  const slaItem = sla.length === 0 ? slaData.find((i) => i.name === name) : sla.find((i) => i.name === name)
  const { slaErrors, setSlaErrors } = useOrganizationForm("SlaContent")

  useEffect(() => {
    if (slaItem) {
      if (!slaItem.content && !slaErrors?.includes(`slaContent_${name}`)) {
        setSlaErrors([...slaErrors, `slaContent_${name}`])
      }

      if (slaItem.content && slaErrors?.includes(`slaContent_${name}`)) {
        setSlaErrors(slaErrors.filter((i) => i !== `slaContent_${name}`))
      }
    }
  }, [name, setSlaErrors, slaErrors, slaItem])

  if (!slaItem || !slaItem.content) {
    return (
      <Stack space={24} css={{ padding: "$16" }}>
        <Flex css={{ gap: "$12" }}>
          <AddButton type={SlaItemType.Rule} name={name} />
          <AddButton type={SlaItemType.Group} name={name} />
        </Flex>
        <>
          <Copy color="neutrals-5">Please add line or group</Copy>
          {slaErrors?.includes(`slaContent_${name}`) ? (
            <Copy color="special-error" css={{ marginTop: "$16" }}>
              Selected SLA could not be empty
            </Copy>
          ) : null}
        </>
      </Stack>
    )
  }

  return !slaItem.content.nodes ? (
    <SlaRuleItem
      name={name}
      field={slaItem.content.field}
      op={slaItem.content.op}
      values={slaItem.content.values}
      isViewMode={isViewMode}
    />
  ) : (
    <SlaGroupItem
      name={name}
      group={slaItem.content.group}
      nodes={slaItem.content.nodes}
      isViewMode={isViewMode}
    />
  )
}
