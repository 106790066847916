import { Controller, useFormContext } from "react-hook-form"

import { IOrganizationState } from "@/shared/types"

import { Copy, Flex, FormSelect, Grid, GridItem, IconTooltip, Stack, Title } from "@/shared/components"
import { IconWarning } from "@/shared/icons"

const daysList = Array.from(new Array(5), (_, index) => `${index + 1}`)
const hoursList = Array.from(new Array(8), (_, index) => `${index + 1}`)

export const Email = () => {
  const {
    watch,
    control,
    register,
    setValue,
    formState: { errors },
  } = useFormContext<IOrganizationState>()
  const { urgentDaysBeforePickup, urgentHoursBeforePickup } = watch()

  return (
    <Stack space={32}>
      <Flex align="center" css={{ gap: "$8" }}>
        <Title as="h2" scale={6} color="theme-b-n3">
          Select time for urgent emails
        </Title>
        <IconTooltip
          tooltip={
            <Copy scale={10}>Urgent emails will be sent to selected contacts if no carriers found</Copy>
          }
          ariaLabel="Urgent email tooltip"
          withTitle={false}
          contentWidth="max-content"
          trigger={["hover", "focus"]}
          placement="top"
          delayShow={150}
          delayHide={150}
          contentCss={{
            padding: "$12",
            borderRadius: "$8",
          }}
          triggerCss={{
            height: "$20",
          }}
          icon={<IconWarning fixedSize width={20} height={20} />}
        />
      </Flex>
      <Grid columns="1fr 1fr 1fr 1fr" columnGap={16}>
        <GridItem>
          <Controller
            name="urgentDaysBeforePickup"
            control={control}
            render={({ field }) => {
              return (
                <FormSelect
                  {...register(field.name, {
                    shouldUnregister: true,
                  })}
                  {...field}
                  id={field.name}
                  onValueChange={(value) => {
                    setValue("urgentDaysBeforePickup", value as string, { shouldDirty: true })
                  }}
                  label="Days before Pickup"
                  labelProps={{ hidden: true }}
                  description="Days before Pickup"
                  isDisplayValueDifferent
                  menuPlacement="top"
                  value={{
                    value: field.value,
                    label: field.value === "" ? "Not selected" : field.value,
                  }}
                  options={[
                    { value: "", label: "Not selected" },
                    ...daysList.map((i) => ({
                      value: i,
                      label: i,
                    })),
                  ]}
                  disabled={!!urgentHoursBeforePickup}
                  error={errors[field.name]?.message}
                />
              )
            }}
          />
        </GridItem>
        <GridItem>
          <Controller
            name="urgentHoursBeforePickup"
            control={control}
            render={({ field }) => {
              return (
                <FormSelect
                  {...register(field.name, {
                    shouldUnregister: true,
                  })}
                  {...field}
                  id={field.name}
                  onValueChange={(value) => {
                    setValue("urgentHoursBeforePickup", value as string, { shouldDirty: true })
                  }}
                  label="Hours before Pickup"
                  labelProps={{ hidden: true }}
                  description="Hours before Pickup"
                  isDisplayValueDifferent
                  menuPlacement="top"
                  value={{
                    value: field.value,
                    label: field.value === "" ? "Not selected" : field.value,
                  }}
                  options={[
                    { value: "", label: "Not selected" },
                    ...hoursList.map((i) => ({
                      value: i,
                      label: i,
                    })),
                  ]}
                  disabled={!!urgentDaysBeforePickup}
                  error={errors[field.name]?.message}
                />
              )
            }}
          />
        </GridItem>
      </Grid>
    </Stack>
  )
}
