import { useQuery } from "@tanstack/react-query"

import { getOrdersSankeyByOrgIdFn } from "@/api/tenderApi"

export const useOrdersSankey = (orgId: string, from: string) => {
  const isEnabled = !!orgId

  return useQuery(["ordersSankeyByOrgIdFn", orgId, from], () => getOrdersSankeyByOrgIdFn(orgId, from), {
    keepPreviousData: true,
    enabled: isEnabled,
  })
}
