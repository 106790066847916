import { FileType } from "@/shared/types"

import { Copy, Spacer, Stack, Title } from "@/shared/components"
import { UploadFile } from "@/files/components"

export const FilesContainer = () => {
  return (
    <>
      <Title as="h1" scale={2} color="system-black">
        Upload files
      </Title>
      <Spacer size={24} />
      <Copy color="theme-n6-n5">Select relevant documents</Copy>
      <Spacer size={40} />
      <Stack space={16}>
        <UploadFile type={FileType.DAT_KMAS} />
        <UploadFile type={FileType.CARRIERS_COVERAGE} />
        <UploadFile type={FileType.CONVOY_RATES_FOR_LANES} />
      </Stack>
    </>
  )
}
