import { useNavigate, useParams } from "react-router-dom"
import { useFormContext } from "react-hook-form"

import { MANAGEMENT } from "@/constants"
import { RouteParams, IOrganizationState } from "@/shared/types"

import { Button, Flex, FooterWrapper } from "@/shared/components"
import { CancelButton } from "@/management/components"

export const EditStrategiesButton = ({ isLoading }: { isLoading: boolean }) => {
  const { organizationId } = useParams<keyof RouteParams>() as RouteParams
  const navigate = useNavigate()

  const {
    formState: { errors, isDirty },
  } = useFormContext<IOrganizationState>()

  return (
    <FooterWrapper>
      <Flex justify="between" css={{ gap: "$16" }}>
        <Button
          type="submit"
          loading={isLoading}
          disabled={isLoading || !isDirty || Object.keys(errors).length > 0}
        >
          Save
        </Button>
        <CancelButton handleClick={() => navigate(`${MANAGEMENT}/organization/${organizationId}`)} />
      </Flex>
    </FooterWrapper>
  )
}
