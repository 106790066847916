import { useModalActions } from "@/shared/hooks"
import { ModalType } from "@/shared/types"
import { IconBin } from "@/shared/icons"

import { ButtonIcon, Copy, Flex, useGlobalSettings } from "@/shared/components"

interface IUserTableRowProps {
  id: string
  email: string
  isShowMode?: boolean
}

export const UrgentContactsTableRow = ({ id, email, isShowMode = false }: IUserTableRowProps) => {
  const { open } = useModalActions()
  const { setUrgentId } = useGlobalSettings("DeleteUrgentContactModal")

  const onDeleteClick = (e: React.SyntheticEvent, id: string) => {
    e.stopPropagation()

    setUrgentId(id)
    open(ModalType.DeleteUrgentContactModal)
  }

  return (
    <Flex
      align="center"
      justify="between"
      css={{
        height: isShowMode ? "$24" : "$64",
        padding: isShowMode ? "0" : "0 $12 0 $24",
        color: "$theme-b-n3",
      }}
    >
      <Copy scale={10}>{email}</Copy>
      {!isShowMode && (
        <ButtonIcon
          type="button"
          ariaLabel={`Remove urgent contact ${id}`}
          icon={<IconBin />}
          onClick={(e) => onDeleteClick(e, id)}
          inputIcon
        />
      )}
    </Flex>
  )
}
