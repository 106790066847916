import axios from "axios"

import { ORGANIZATION_BASE_URI } from "@/constants"
import { replaceFalsyProps, buildUrlString } from "@/shared/utils"
import {
  IUserInfo,
  ICreateOrganizationRequest,
  IOrganizationResponse,
  IOrganizationsResponse,
  IUrgentContacts,
} from "@/shared/types"

export const organizationApi = axios.create({
  baseURL: ORGANIZATION_BASE_URI,
})

organizationApi.defaults.headers.common["Content-Type"] = "application/json"

export const getMeFn = async () => {
  const { data: user } = await organizationApi.get<IUserInfo>(`organizations/users/info`)
  const organizations = await getUserOrganizationsFn()

  return { user, organizations }
}

export const getUserOrganizationsFn = async () => {
  const { data } = await organizationApi.get<IOrganizationResponse[]>("organizations/user/organizations")

  return data
}

export const getOrganizationByIdFn = async (id: string) => {
  const { data } = await organizationApi.get<IOrganizationResponse>(`organizations/${id}`)
  return data
}

export const getOrganizationChildrenByIdFn = async (id: string) => {
  const { data } = await organizationApi.get<IOrganizationResponse[]>(`organizations/${id}/children`)

  return data
}

export const createOrganizationFn = async (organization: ICreateOrganizationRequest) => {
  const formattedData = JSON.stringify(organization, replaceFalsyProps)
  const { data } = await organizationApi.post<IOrganizationResponse>(
    "organizations",
    JSON.parse(formattedData),
  )
  return data
}

export const getAllOrganizationsFn = async (maxDepth = 2, page = 0, size = 1000) => {
  const queryParams = {
    maxDepth,
    sort: "label,asc",
    page,
    size,
  }

  const baseUrl = "organizations/all"
  const url = buildUrlString(baseUrl, queryParams)

  const { data } = await organizationApi.get<IOrganizationsResponse>(url)

  return data.content
}

export const updateOrganizationFn = async (id: string, organization: ICreateOrganizationRequest) => {
  const formattedData = JSON.stringify(organization, replaceFalsyProps)
  const { data } = await organizationApi.put(`organizations/${id}`, JSON.parse(formattedData))
  return data
}

export const deleteOrganizationFn = async (id: string) => {
  const { data } = await organizationApi.delete(`organizations/${id}`)
  return data
}

export const getAllUrgentContactsFn = async ({ organizationId }: { organizationId: string }) => {
  const { data } = await organizationApi.get<IUrgentContacts[]>(
    `organizations/${organizationId}/urgent-contacts`,
  )
  return data
}

export const createUrgentContactsFn = async ({
  organizationId,
  requestBody,
}: {
  organizationId: string
  requestBody: IUrgentContacts[]
}) => {
  const { data } = await organizationApi.post<IUrgentContacts[]>(
    `organizations/${organizationId}/urgent-contacts`,
    requestBody,
  )
  return data
}

export const deleteUrgentContactFn = async ({
  organizationId,
  urgentId,
}: {
  organizationId: string
  urgentId: string
}) => {
  const { data } = await organizationApi.delete(`organizations/${organizationId}/urgent-contacts/${urgentId}`)
  return data
}

export const getDashboardOrgName = async (token: string) => {
  const { data } = await organizationApi.get<string>(
    `organizations/org-name-for-carrier-dashboard`,
    { headers: { "CARRIER-DASHBOARD-TOKEN": token } },
  )
  
  return data
}
