import { SlaField } from "@/shared/types"

export const slaFieldToText = (type?: SlaField | null) => {
  switch (type) {
    case SlaField.ExcludedCarriers:
      return "Excluded Carriers"
    case SlaField.Delivery:
      return "On-Time Delivery Requirement"
    case SlaField.Tender:
      return "Tender Acceptance"
    case SlaField.Tracking:
      return "Automated tracking"
    case SlaField.Unloading:
      return "Unloading Requirement"
    case SlaField.Cargo:
      return "Minimum Cargo Insurance"
    case SlaField.Haul:
      return "Consider no-haul Consignee"
    case SlaField.Brokers:
      return "Allow Brokers to Bid"
    default:
      return ""
  }
}
