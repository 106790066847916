import { Copy, Grid, GridItem, Redacted } from "@/shared/components"

interface IWorkingDaysTableRowProps {
  day?: string
  start?: string
  end?: string
  fullDay?: string
  isTableHead?: boolean
  isPlaceholder?: boolean
}

export const WorkingDaysTableRow: React.FC<React.PropsWithChildren<IWorkingDaysTableRowProps>> = ({
  children,
  day,
  start,
  end,
  fullDay,
  isTableHead,
  isPlaceholder,
}) => {
  return (
    <Grid
      columns="1fr 1fr 1fr 1fr"
      align="center"
      css={{
        height: isTableHead ? "$48" : "$64",
        color: "$theme-b-n3",
        borderBottom: isTableHead ? "1px solid $theme-n3-n7" : "none",
      }}
    >
      {isTableHead ? (
        <>
          <GridItem css={{ paddingX: "$24" }}>
            {isPlaceholder && !isTableHead ? (
              <Redacted height="$24" width="100px" text animated />
            ) : (
              <Copy
                scale={isTableHead ? 8 : 10}
                fontWeight={isTableHead ? "bold" : "regular"}
                uppercase={isTableHead ? true : false}
              >
                {day}
              </Copy>
            )}
          </GridItem>
          <GridItem css={{ paddingX: "$24" }}>
            {isPlaceholder && !isTableHead ? (
              <Redacted height="$24" width="140px" text animated />
            ) : (
              <Copy
                scale={isTableHead ? 8 : 10}
                fontWeight={isTableHead ? "bold" : "regular"}
                uppercase={isTableHead ? true : false}
              >
                {start}
              </Copy>
            )}
          </GridItem>
          <GridItem css={{ paddingX: "$24" }}>
            {isPlaceholder && !isTableHead ? (
              <Redacted height="$24" width="100px" text animated />
            ) : (
              <Copy
                scale={isTableHead ? 8 : 10}
                fontWeight={isTableHead ? "bold" : "regular"}
                uppercase={isTableHead ? true : false}
              >
                {end}
              </Copy>
            )}
          </GridItem>
          <GridItem css={{ paddingX: "$24" }}>
            {isPlaceholder && !isTableHead ? (
              <Redacted height="$24" width="100px" text animated />
            ) : (
              <Copy
                scale={isTableHead ? 8 : 10}
                fontWeight={isTableHead ? "bold" : "regular"}
                uppercase={isTableHead ? true : false}
              >
                {fullDay}
              </Copy>
            )}
          </GridItem>
        </>
      ) : (
        children
      )}
    </Grid>
  )
}
