import { Copy, Flex } from "@/shared/components"

import { SDropdownMenuItem } from "./Dropdown.styles"

interface IDropdownItemProps {
  label: string
  onSelect?: (event: Event) => void
  disabled?: boolean
  dataTestid?: string
}

export const DropdownItem: React.FC<React.PropsWithChildren<IDropdownItemProps>> = ({
  children,
  label,
  onSelect,
  disabled,
  dataTestid,
}) => {
  return (
    <SDropdownMenuItem onSelect={onSelect} disabled={disabled} data-testid={dataTestid}>
      <Flex align="center" css={{ gap: "$12", paddingX: "$16" }}>
        {children}
        <Copy
          as="span"
          color="theme-b-n3"
          css={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
            minWidth: "$128",
            height: "inherit",
          }}
        >
          {label}
        </Copy>
      </Flex>
    </SDropdownMenuItem>
  )
}
