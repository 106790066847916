import { isAxiosError } from "axios"
import { useQuery } from "@tanstack/react-query"

import { getUserOrganizationsFn } from "@/api/organizationApi"
import { showToast } from "@/shared/utils"

export const useAllUserOrganizations = (username: string) => {
  const isEnabled = !!username

  return useQuery(["allUserOrganizations", username], () => getUserOrganizationsFn(), {
    enabled: isEnabled,
    onError: (error) => {
      if (isAxiosError(error)) {
        showToast({ type: "error", text: error.response?.data.errorMessage || error.message })
      }
    },
  })
}
