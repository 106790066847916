import { useCallback } from "react"
import { useParams } from "react-router-dom"

import { useModal, useModalActions } from "@/shared/hooks"
import { IForceTenderToAnyDto, ModalType, RouteParams } from "@/shared/types"

import { Button, 
  Flex, 
  Stack, 
  Title,
  TextFieldController,
  Grid,
  GridItem,
  Box
 } from "@/shared/components"
import { GeneralModal } from "@/modals"
import { formatNumericInput } from "@/shared/utils"
import { FormProvider, useForm } from "react-hook-form"
import { numericPattern } from "@/shared/rules"
import { useForceTenderSubmitToAny } from "@/shared/data/useForceTenderSubmitToAny"
import { CarrierForceTenderSelect } from "./components/CarrierForceTenderSelect"

export const ForceTenderExtendedModal = () => {

  const { orderId } = useParams<keyof RouteParams>() as RouteParams
  const { modalProps } = useModal(ModalType.ForceTenderExtendedModal + orderId)
  const { close } = useModalActions()

  const { mutateAsync: forceTenderSubmit } = useForceTenderSubmitToAny();

  const methods = useForm<IForceTenderToAnyDto>();
  const {
    control,
    register,
    watch,
    trigger,
    setValue,
    formState: { errors },
  } = methods
  const {carrierId, rateInCents} = watch(); // NOTE: the rateInCents contains price in dollars not in cents

  const isButtonDisabled = !carrierId || !rateInCents;

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    const formattedValue: string = formatNumericInput(inputValue, ".", 0, 6)
    setValue("rateInCents", Number.parseInt(formattedValue))
  }

  const handleContinueClick = useCallback(() => {
    const rate = rateInCents * 100;
    forceTenderSubmit({ orderId, carrierId, rateInCents: rate })
    close(ModalType.ForceTenderExtendedModal + orderId)
  }, [close, 
    carrierId, 
    orderId,
    forceTenderSubmit,  
    rateInCents]
  )

  return (
    <GeneralModal
      {...modalProps}
      contentCss={{
        height: 470,
        width: 550,
      }}
      containerCss={{
        maxHeight: 470,
      }}
      footer={
        <Flex justify="end" css={{ gap: "$16", paddingBottom: "$8", paddingTop: "$16" }}>
          <Button onClick={handleContinueClick} css={{ width: "$128" }} disabled={isButtonDisabled}>
            Assign
          </Button>
          <Button
            action="secondary"
            onClick={() => close(ModalType.ForceTenderExtendedModal + orderId)}
            css={{ width: "$128" }}
          >
            Cancel
          </Button>
        </Flex>
      }
    >
      <FormProvider {...methods}>
        <Stack space={32} css={{ padding: "$24" }}>
          <Title as="h4" scale={2} color="system-black">
            Assign any carrier in your organization as the winner
          </Title>

          <Grid columns="2fr" rowGap={24} >
            <GridItem>
              <Box css={{ width: "350px" }}>
                <CarrierForceTenderSelect />
              </Box>      
            </GridItem>
            <GridItem >
              <Box css={{ width: "350px" }}>
                <TextFieldController<IForceTenderToAnyDto>
                  name={`rateInCents`}
                  control={control}
                  register={register}
                  trigger={trigger}
                  controlledValue={rateInCents}
                  controlledChange={handlePriceChange}
                  placeholder="e.g. 1500"
                  description="All-in Rate, $USD *"
                  disabled={false}
                  rules={numericPattern}
                  error={errors.rateInCents?.message}
                />
              </Box>
            </GridItem>
          </Grid>
        </Stack>
      </FormProvider>
    </GeneralModal>
  )
}
