import { QuoteStatusType } from "@/shared/types"

export const quoteStatusToText = (status: QuoteStatusType) => {
  switch (status) {
    case QuoteStatusType.ReadyForSending:
      return "Not requested"
    case QuoteStatusType.Requested:
    case QuoteStatusType.Acknowledged:
      return "Quoting"

    case QuoteStatusType.Rejected:
      return "Declined"

    case QuoteStatusType.Submitted:
      return "Quoted"

    case QuoteStatusType.AwardedWinner:
      return "Awarded winner"

    case QuoteStatusType.Waiting:
      return "Waiting"

    case QuoteStatusType.Accepted:
      return "Accepted"

    case QuoteStatusType.NotTendered:
      return "Not tendered"

    case QuoteStatusType.Error:
      return "Failed"
  }
}
