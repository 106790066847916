import React from "react"
import Select, { MenuPlacement, components } from "react-select"

import { boxShadows } from "@/stitches/utils"
import { enterKeyDown } from "@/shared/utils"
import { CSS } from "@/stitches/config"

import { Box, Copy, ErrorLabel, Flex, FormLabel, IFormLabelProps, Spacer } from "@/shared/components"
import { IconChevronDown, IconTick } from "@/shared/icons"

interface ISelectValue {
  value: unknown
  label: unknown
}

interface ISelectProps {
  id: string
  value: unknown
  options: unknown[]
  isDisplayValueDifferent?: boolean
  onValueChange: (value: unknown) => void
  label: React.ReactNode
  labelProps?: IFormLabelProps
  description?: string
  name: string
  disabled?: boolean
  borderless?: boolean
  isSearchable?: boolean
  selectCss?: CSS
  displayValueCss?: CSS
  generalCss?: CSS
  menuListCss?: CSS
  isMenuScrollbarColored?: boolean
  isViewMode?: boolean
  noOptionsMessage?: ({ inputValue }: { inputValue: string }) => React.ReactNode
  placeholder?: string
  error?: string
  menuPlacement?: MenuPlacement
}

const { Option } = components

export const FormSelect = React.forwardRef<HTMLDivElement, ISelectProps>(
  (
    {
      id,
      label,
      labelProps,
      description,
      value,
      options,
      isDisplayValueDifferent,
      onValueChange,
      disabled,
      borderless,
      isSearchable = false,
      selectCss,
      displayValueCss,
      generalCss,
      isMenuScrollbarColored,
      isViewMode,
      noOptionsMessage,
      placeholder,
      error,
      menuListCss,
      menuPlacement = "auto",
      ...props
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    forwardedRef,
  ) => {
    return (
      <Box ref={forwardedRef} css={selectCss}>
        <Flex justify="between" css={{ position: "relative" }}>
          <FormLabel {...labelProps} htmlFor={id}>
            {label}
          </FormLabel>

          {description && (
            <Copy scale={10} color="neutrals-5" fontWeight="semiBold">
              {description}
              {labelProps?.required ? (
                <Copy as="span" scale={10} fontWeight="semiBold" css={{ paddingLeft: "$2" }}>
                  *
                </Copy>
              ) : null}
            </Copy>
          )}
        </Flex>

        {(!labelProps?.hidden || description) && <Spacer size={4} />}

        <Select
          {...props}
          inputId={id}
          value={(isDisplayValueDifferent ? value : { value: value, label: value }) as ISelectValue}
          onChange={(value) => onValueChange(value?.value)}
          options={
            (isDisplayValueDifferent
              ? options
              : options.map((i) => ({ value: i, label: i }))) as ISelectValue[]
          }
          menuPlacement={menuPlacement}
          isSearchable={isSearchable}
          isDisabled={disabled}
          noOptionsMessage={noOptionsMessage}
          placeholder={placeholder}
          onKeyDown={(e) => {
            enterKeyDown(e.key) && e.preventDefault()
          }}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: () => <IconChevronDown size="xs" />,
            Option: ({ children, isSelected, ...rest }) => {
              return (
                <Option isSelected={isSelected} {...rest}>
                  <Flex align="center" justify="between" css={{ gap: "$12" }}>
                    {children}
                    {isSelected ? <IconTick /> : null}
                  </Flex>
                </Option>
              )
            },
          }}
          styles={{
            placeholder: (styles) => ({
              ...styles,
              color: "var(--colors-neutrals-5)",
              margin: 0,
              fontSize: "initial",
              lineHeight: "initial",
            }),
            control: (styles) => ({
              ...styles,
              flexWrap: "nowrap",
              justifyContent: "space-between",
              gap: generalCss?.gap ? `${generalCss.gap}` : "var(--space-8)",
              minHeight: generalCss?.minHeight ? `${generalCss.minHeight}` : "38px",
              backgroundColor: disabled
                ? borderless || (isViewMode && !generalCss?.backgroundColor)
                  ? "var(--colors-theme-w-n9)"
                  : generalCss?.backgroundColor
                  ? `${generalCss.backgroundColor}`
                  : "var(--colors-theme-n1-n10)"
                : borderless
                ? "var(--colors-theme-w-n9)"
                : generalCss?.backgroundColor
                ? `${generalCss.backgroundColor}`
                : "var(--colors-theme-w-n9)",
              color: disabled && !isViewMode ? "var(--colors-theme-n4-n7)" : "var(--colors-theme-b-n3)",
              border: "none",
              borderRadius: "var(--radii-8)",
              cursor: "pointer",
              boxShadow: !borderless ? (error ? boxShadows.input.error : boxShadows.input.initial) : "none",
              transition: "100ms boxShadow ease-out",
              appearance: "none",
              WebkitAppearance: "none",
              outline: "none",

              "&:hover": {
                boxShadow: !borderless ? (error ? boxShadows.input.error : boxShadows.input.hover) : "none",
              },
              "&:focus, &:focus-within": {
                boxShadow: !borderless ? (error ? boxShadows.input.error : boxShadows.input.focus) : "none",
                backgroundColor: !borderless ? "var(--colors-brand-yellow-lightest)" : "initial",
                // textShadow: !borderless ? "none" : "0 0 1px var(--colors-theme-b-n3)",
              },
            }),
            singleValue: (styles) => ({
              ...styles,
              width: displayValueCss?.width ? `${displayValueCss.width}px` : "initial",
              margin: 0,
              color: "var(--colors-system-inherit)",
              fontSize: displayValueCss?.fontSize ? `${displayValueCss.fontSize}px` : "initial",
              lineHeight: displayValueCss?.lineHeight ? `${displayValueCss.lineHeight}` : "initial",
            }),
            valueContainer: (styles) => ({
              ...styles,
              flex: !borderless ? 1 : "initial",
              padding: !borderless
                ? generalCss?.padding
                  ? `${generalCss.padding}`
                  : "var(--space-12) var(--space-0) var(--space-12) var(--space-16)"
                : "var(--space-12) var(--space-0)",
            }),
            indicatorsContainer: (styles) => ({
              ...styles,
              justifyContent: "center",
              width: !borderless
                ? generalCss?.width
                  ? `${generalCss.width}`
                  : "var(--space-48)"
                : "var(--space-24)",
              visibility: isViewMode ? "hidden" : "visible",
            }),
            menu: (styles) => ({
              ...styles,
              padding: "var(--space-8) var(--space-0)",
              borderRadius: "var(--radii-8)",
              zIndex: "var(--zIndices-2)",
              boxShadow: boxShadows.dropdown,

              "*::-webkit-scrollbar-track": {
                backgroundColor: isMenuScrollbarColored ? "var(--colors-system-white)" : "initial",
                borderRadius: "0 var(--radii-8) var(--radii-8) 0",
              },
            }),
            menuList: (styles) => ({
              ...styles,
              padding: "var(--space-0)",
              minWidth: 100,
              maxHeight: menuListCss?.maxHeight ? (menuListCss.maxHeight as number) : 144,
            }),
            option: (styles, { isFocused }) => ({
              ...styles,
              padding: "var(--space-12) var(--space-16)",
              backgroundColor: isFocused ? "var(--colors-brand-yellow-lightest)" : "var(--colors-theme-w-n8)",
              color: "var(--colors-theme-b-n3)",
              cursor: "pointer",
              zIndex: "var(--zIndices-1)",

              "&:hover": {
                backgroundColor: "var(--colors-brand-yellow-lightest)",
              },
            }),
            input: (styles) => ({
              ...styles,
              margin: 0,
              padding: 0,
              color: "var(--colors-system-inherit)",
            }),
            noOptionsMessage: (styles) => ({
              ...styles,
              padding: "var(--space-12) var(--space-16)",
              backgroundColor: "var(--colors-theme-w-n8)",
              color: "var(--colors-theme-b-n3)",
              textAlign: "left",
            }),
          }}
        />

        {error && (
          <Box css={{ position: "absolute" }}>
            <ErrorLabel id={id}>{error}</ErrorLabel>
          </Box>
        )}
      </Box>
    )
  },
)

FormSelect.displayName = "FormSelect"
