import { styled } from "@/stitches/config"
import { blue, system } from "@/stitches/theme"

export const SButton = styled("button", {
  reset: true,
  border: "1px solid transparent",
  paddingY: "$8",
  paddingX: "$16",
  position: "relative",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "150ms ease-out",
  cursor: "pointer",
  borderRadius: "$8",
  outline: "2px solid transparent",

  variants: {
    size: {
      small: {
        height: "$32",
        paddingY: "$6",
        paddingX: "$12",
      },
      medium: {
        height: "$40",
        paddingY: "$8",
        paddingX: "$16",
      },
      large: {
        height: "$48",
        paddingY: "$12",
        paddingX: "$24",
      },
    },

    loading: {
      true: {
        boxShadow: "none",
        pointerEvents: "none",
      },
    },

    disabled: {
      true: {
        boxShadow: "none",
        pointerEvents: "none",
      },
    },

    action: {
      primary: {
        backgroundColor: "$button-primary",
        color: "$system-white",

        hover: {
          backgroundColor: "$button-primary-hover",
        },

        keyboardFocus: {
          boxShadow: `0 0 0 2px ${system.white}, 0 0 0 4px ${blue["500"]}`,
        },

        active: {
          backgroundColor: "$button-primary-active",
        },
      },

      secondary: {
        color: "$system-black",
        borderColor: "$system-black",
        backgroundColor: "$system-white",

        hover: {
          backgroundColor: "$button-secondary-hover",
        },

        keyboardFocus: {
          boxShadow: `0 0 0 2px ${system.white}, 0 0 0 4px ${blue["500"]}`,
        },

        active: {
          backgroundColor: "$button-secondary-active",
        },
      },

      text: {
        height: "auto",
        padding: "0 $8",
        color: "$system-black",
        textUnderlineOffset: "$space$4",
        outline: "none",

        hover: {
          color: "$brand-yellow-primary",
        },

        keyboardFocus: {
          color: "$brand-yellow-primary",
        },

        active: {
          color: "$brand-yellow-primary",
        },
      },

      tertiary: {
        color: "$system-black",

        hover: {
          backgroundColor: "$button-secondary-hover",
        },

        keyboardFocus: {
          boxShadow: `0 0 0 2px ${system.white}, 0 0 0 4px ${blue["500"]}`,
        },

        active: {
          backgroundColor: "$button-secondary-active",
        },
      },

      destructive: {
        backgroundColor: "$button-destructive",
        color: "$system-white",

        hover: {
          backgroundColor: "$button-destructive-hover",
        },

        keyboardFocus: {
          boxShadow: `0 0 0 2px ${system.white}, 0 0 0 4px ${blue["500"]}`,
        },

        active: {
          backgroundColor: "$button-destructive-active",
        },
      },
    },
  },

  compoundVariants: [
    {
      loading: true,
      action: "primary",
      css: {
        backgroundColor: "$button-primary-active !important",
      },
    },
    {
      disabled: true,
      action: "primary",
      css: {
        backgroundColor: "$button-primary-disabled",
        color: "$button-primary-disabled-text",
      },
    },
    {
      loading: true,
      action: "secondary",
      css: {
        backgroundColor: "$button-secondary-active !important",
        borderColor: "$system-black",
      },
    },
    {
      disabled: true,
      action: "secondary",
      css: {
        color: "$button-primary-disabled-text",
        borderColor: "$gray-300",
      },
    },
    {
      loading: true,
      action: "text",
      css: {
        color: "$neutrals-4",
      },
    },
    {
      disabled: true,
      action: "text",
      css: {
        color: "$neutrals-4",
      },
    },
    {
      loading: true,
      action: "tertiary",
      css: {
        backgroundColor: "$button-secondary-active !important",
      },
    },
    {
      disabled: true,
      action: "tertiary",
      css: {
        backgroundColor: "$button-secondary-active",
        color: "$button-primary-disabled-text",
      },
    },
    {
      loading: true,
      action: "destructive",
      css: {
        backgroundColor: "$button-destructive-active !important",
      },
    },
  ],

  defaultVariants: {
    action: "primary",
    size: "large",
  },
})

export const SButtonSpinner = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
})

export const SButtonIcon = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "$24",
  width: "$24",
})
