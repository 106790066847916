export const HOME = "/"
export const LOGIN = "/login"
export const TASKS = "/tasks"
export const DASHBOARD = "/dashboard"
export const FILES = "/files"
export const RECOVERY = "/recovery"
export const MANAGEMENT = "/management"
export const PROFILE = "/profile"
export const FLS = "/fls"
export const CARRIER = "/carrier-dashboard"
export const CARRIER_LOGIN = "/carrier-dashboard-login"
