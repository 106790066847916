import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"

import { ReferralKey, Role, RouteParams, StatusType, IUserState } from "@/shared/types"
import { MANAGEMENT } from "@/constants"
import { useUserFormData } from "@/management/hooks"

import { CreateUserButton, UserGeneralInfo } from "@/management/components"

const initialUserState: IUserState = {
  firstName: "",
  lastName: "",
  username: "",
  urgentContact: true,
  phone: "",
  defaultOrganizationId: "",
  role: Role.SysAdmin,
  enabled: StatusType.Active,
  darkTheme: false,
  availableOrganizations: [],
}

export const UserForm = ({ isEditMode }: { isEditMode: boolean }) => {
  const { userId } = useParams<keyof RouteParams>() as RouteParams
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const methods = useForm<IUserState>({
    // mode: "onChange",
    mode: "all",
    defaultValues: initialUserState,
  })

  const { handleSubmit, watch } = methods
  const { defaultOrganizationId } = watch()

  const url =
    isEditMode && defaultOrganizationId
      ? `${MANAGEMENT}/organization/${defaultOrganizationId}?${ReferralKey.ReferralTab}=Users`
      : defaultOrganizationId && searchParams.has(ReferralKey.ReferralOrgId)
      ? `${MANAGEMENT}/organization/${defaultOrganizationId}`
      : MANAGEMENT

  const { createUser, updateUser, isLoading } = useUserFormData(userId)

  const onSubmitHandler: SubmitHandler<IUserState> = async (values) => {
    return isEditMode
      ? updateUser(values, {
          onSuccess: () => navigate(url),
        })
      : createUser(values, {
          onSuccess: () => navigate(url),
        })
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmitHandler)} noValidate autoComplete="off">
        <UserGeneralInfo />
        <CreateUserButton isLoading={isLoading} />
      </form>
    </FormProvider>
  )
}
