import { useLocation, useNavigate } from "react-router-dom"
import { isAxiosError } from "axios"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { deleteUserFn } from "@/api/userApi"
import { IDeleteUserResponse } from "@/shared/types"
import { showToast } from "@/shared/utils"
import { MANAGEMENT } from "@/constants"

export const useDeleteUser = () => {
  const queryClient = useQueryClient()
  const location = useLocation()
  const navigate = useNavigate()

  return useMutation((userId: string) => deleteUserFn(userId), {
    onSuccess: (data: IDeleteUserResponse) => {
      // INFO: invalidate the cache of "allOrganizationUsers" request because the data has been changed
      queryClient.invalidateQueries({
        queryKey: ["allOrganizationUsers"],
      })
      if (location.pathname.includes("management/user")) {
        navigate(MANAGEMENT)
      }
      showToast({ type: "success", text: `User "${data.fullName}" has been deleted` })
    },
    onError: (error) => {
      if (isAxiosError(error)) {
        showToast({ type: "error", text: error.response?.data.errorMessage || error.message })
      }
    },
  })
}
