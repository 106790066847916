import { ICreateOrganizationRequest, IOrganizationState, hiddenInputValue } from "@/shared/types"

export const transformOrganizationRequest = (data: IOrganizationState): ICreateOrganizationRequest => {
  const {
    id,
    name,
    generalEmail,
    senderEmail,
    invoiceEmail,
    phone,
    timeZone,
    address,
    billingAddress,
    parentId,
    apiKey,
    apiSecret,
    carrierApiKey,
    carrierApiSecret,
    contacts,
    workingDays,
    organizationType,
  } = data

  return {
    id,
    name,
    generalEmail,
    senderEmail,
    invoiceEmail,
    phone: phone.replace(/[() -]/g, ""),
    timeZone,
    address,
    billingAddress,
    parentId,
    apiKey,
    apiSecret: apiSecret === hiddenInputValue ? "" : apiSecret,
    carrierApiKey,
    carrierApiSecret: carrierApiSecret === hiddenInputValue ? "" : carrierApiSecret,
    contacts,
    workingDays,
    organizationType,
  }
}
