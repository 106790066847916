import { Box, Flex, Redacted } from "@/shared/components"

import { FlsTableRow } from "./FlsTableRow"

export const FlsTablePlaceholder = () => (
  <>
    <Flex justify="between" css={{ marginBottom: "$32" }}>
      <Flex css={{ gap: "$12", flexWrap: "nowrap" }}>
        <Redacted width="250px" height="$40" text animated />
      </Flex>
    </Flex>

    <Box css={{ backgroundColor: "$system-white", borderRadius: "$8" }}>
      <FlsTableRow
        orderId="ID"
        createdAt="Created"
        status="Status"
        shipperAddress="From"
        consigneeAddress="To"
        customerId="Customer"
        isTableHead
        isPlaceholder
      />
      <Box
      // css={{
      //   maxHeight: "calc((var(--vh) * 100) - 460px)",
      //   overflowY: "scroll",
      //   marginRight: -15,
      // }}
      >
        {Array.from(new Array(10), (_, index) => index).map((v) => (
          <FlsTableRow key={v} isPlaceholder />
        ))}
      </Box>
    </Box>
  </>
)
