export const APP_ENV = process.env.REACT_APP_ENV as "development" | "staging" | "production"
export const AUTH_BASE_URI = process.env.REACT_APP_API_AUTH_BASE_URI as string
export const USER_BASE_URI = process.env.REACT_APP_API_USER_BASE_URI as string
export const ORDERS_BASE_URI = process.env.REACT_APP_API_ORDERS_BASE_URI as string
export const ORGANIZATION_BASE_URI = process.env.REACT_APP_API_ORGANIZATION_BASE_URI as string
export const TASKS_BASE_URI = process.env.REACT_APP_API_TASKS_BASE_URI as string
export const CARRIERS_BASE_URI = process.env.REACT_APP_API_CARRIERS_BASE_URI as string
export const SLA_BASE_URI = process.env.REACT_APP_API_SLA_BASE_URI as string
export const TENDER_BASE_URI = process.env.REACT_APP_API_TENDER_BASE_URI as string
export const FLS_BASE_URI = process.env.REACT_APP_API_FLS_BASE_URI as string
export const MRKTS_BASE_URI = process.env.REACT_APP_API_MRKTS_BASE_URI as string
export const ORDERS_CSV_URI = process.env.REACT_APP_API_ORDERS_CSV_URI as string
export const ORDERS_CARS_URI = process.env.REACT_APP_API_ORDERS_CARS_URI as string
