import { useQuery } from "@tanstack/react-query"
import { isAxiosError } from "axios"

import { getOrganizationCarriersFn } from "@/api/slaApi"
import { showToast, transformOrganizationCarriersResponseFull } from "@/shared/utils"

export const useOrganizationCarriersOrderView = ({
  orgId,
  page,
  size,
  disableCache,
  ids,
}: {
  orgId: string
  page?: number
  size?: number
  disableCache?: boolean
  ids?: string
}) => {
  const isEnabled = !!orgId

  return useQuery(
    ["organizationCarriersOrderView", orgId, page, size, disableCache, ids],
    () => getOrganizationCarriersFn({ orgId, page, size, ids }),
    {
      cacheTime: !disableCache ? (isEnabled ? 5 * 60 * 1000 : 0) : 0,
      enabled: isEnabled,
      keepPreviousData: disableCache ? false : true,
      select: (data) => transformOrganizationCarriersResponseFull(data),
      onError: (error) => {
        if (isAxiosError(error)) {
          showToast({ type: "error", text: error.response?.data.errorMessage || error.message })
        }
      },
    },
  )
}
