import { useCallback } from "react"
import { useParams } from "react-router-dom"

import { useModal, useModalActions } from "@/shared/hooks"
import { useDeleteUrgentContact } from "@/management/hooks"
import { ModalType, RouteParams } from "@/shared/types"

import { Button, Copy, Flex, Stack, Title, useGlobalSettings } from "@/shared/components"
import { GeneralModal } from "@/modals"

export const DeleteUrgentContactModal = () => {
  const { organizationId } = useParams<keyof RouteParams>() as RouteParams
  const { modalProps } = useModal(ModalType.DeleteUrgentContactModal)
  const { close } = useModalActions()
  const { mutate: deleteUrgentContact } = useDeleteUrgentContact()
  const { urgentId } = useGlobalSettings("DeleteUrgentContactModal")

  const handleContinueClick = useCallback(() => {
    close(ModalType.DeleteUrgentContactModal)
    deleteUrgentContact({ organizationId, urgentId })
  }, [close, urgentId, organizationId, deleteUrgentContact])

  return (
    <GeneralModal
      {...modalProps}
      footer={
        <Flex justify="end" css={{ gap: "$16", paddingBottom: "$8" }}>
          <Button action="destructive" onClick={handleContinueClick}>
            Delete contact
          </Button>
          <Button action="secondary" onClick={() => close(ModalType.DeleteUrgentContactModal)}>
            Cancel
          </Button>
        </Flex>
      }
    >
      <Stack space={12} css={{ padding: "$64 $24 $0" }}>
        <Title as="h3" scale={2} color="system-black">
          Do you want to delete the urgent contact?
        </Title>
        <Copy scale={10} color="theme-n6-n5" css={{ paddingBottom: "$8" }}>
          In case you press Delete contact, the urgent contact will be deleted permanently
        </Copy>
      </Stack>
    </GeneralModal>
  )
}
