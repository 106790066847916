import axios from "axios"

import {
  FilterCarrierLinesType,
  IOrganizationCarrierDto,
  IOrganizationCarriersResponse,
  IOrganizationSlaDto,
  IOrganizationStrategiesDto,
  IUpdateOrganizationCarrierRequest,
} from "@/shared/types"
import { GET_MAX_CARRIERS, SLA_BASE_URI } from "@/constants"
import { replaceFalsyProps, buildUrlString } from "@/shared/utils"

export const slaApi = axios.create({
  baseURL: SLA_BASE_URI,
})

export const slaApiMultipartFormData = axios.create({
  baseURL: SLA_BASE_URI,
  headers: {
    "Content-Type": "multipart/form-data",
  },
})

slaApi.defaults.headers.common["Content-Type"] = "application/json"

export const getOrganizationCarriersFn = async ({
  orgId,
  name,
  page,
  size,
  hasLanesPrice,
  ids,
  noLanes,
  noContacts,
  lanesNoPrice,
  requestBody,
}: {
  orgId: string
  name?: string
  page?: number
  size?: number
  hasLanesPrice?: boolean
  ids?: string
  noLanes?: boolean
  noContacts?: boolean
  lanesNoPrice?: boolean
  requestBody?: FilterCarrierLinesType[]
}) => {
  const queryParams = {
    hasLanesPrice,
    name,
    ids,
    size: size || GET_MAX_CARRIERS,
    page,
    noLanes,
    noContacts,
    lanesNoPrice,
  }

  const baseUrl = `carriers/org/${orgId}/full`
  const url = buildUrlString(baseUrl, queryParams)

  const { data } = await slaApi.post<IOrganizationCarriersResponse>(url, requestBody)
  return data
}

export const checkCarriersApiKeyFn = async (carriers: IOrganizationCarrierDto[]) => {
  const { data } = await slaApi.post<IOrganizationCarriersResponse>(`carriers/check-api-key-unique`, carriers)
  return data.content
}

export const updateOrganizationCarriersFn = async (carriers: IUpdateOrganizationCarrierRequest) => {
  const { data } = await slaApi.post("carriers/full", carriers)
  return data
}

export const getOrganizationSlaFn = async (orgId: string) => {
  const { data } = await slaApi.get<IOrganizationSlaDto>(`slas/${orgId}`)
  return data.settings.nodes
}

export const updateOrganizationSlaFn = async (sla: IOrganizationSlaDto) => {
  const { data } = await slaApi.post("slas", sla)
  return data
}

export const getOrganizationStrategiesFn = async (orgId: string) => {
  const { data } = await slaApi.get<IOrganizationStrategiesDto>(`strategies/${orgId}`)
  return data
}

export const updateOrganizationStrategiesFn = async (strategies: IOrganizationStrategiesDto) => {
  const formattedData = JSON.stringify(strategies, replaceFalsyProps)
  const { data } = await slaApi.post("strategies", JSON.parse(formattedData))
  return data
}

export const getCarriersCsvFn = async (orgId: string) => {
  const { data } = await slaApi.get<string>(`carriers/export-csv?orgId=${orgId}`, { responseType: "blob" })
  return data
}

export const getCarriersNextContactIdFn = async () => {
  const { data } = await slaApi.get<string>(`carriers/contacts/next-id`)
  return data
}

export const updateCarriersCsvFn = async (orgId: string, formData: FormData) => {
  const { data } = await slaApiMultipartFormData.post<string>(`carriers/upload-csv?orgId=${orgId}`, formData)
  return data
}

export const getHasHwCarriersFn = async (orgId: string) => {
  const { data } = await slaApi.get<boolean>(`carriers/org/${orgId}/has-hw-carriers`)
  return data
}

export const checkTmsCodeFn = async (orgId: string, code: string) => {
  const { data } = await slaApi.get<boolean>(`carriers/check-tms-code-unique?orgId=${orgId}&tmsCode=${code}`)
  return data
}
