import { useQuery } from "@tanstack/react-query"
import { isAxiosError } from "axios"

import { getOrganizationByIdFn } from "@/api/organizationApi"
import { showToast } from "@/shared/utils"

export const useOrganizationById = (id: string) => {
  const isEnabled = !!id

  return useQuery(["organizationById", id], () => getOrganizationByIdFn(id), {
    cacheTime: isEnabled ? 5 * 60 * 1000 : 0,
    enabled: isEnabled,
    onError: (error) => {
      if (isAxiosError(error)) {
        showToast({ type: "error", text: error.response?.data.errorMessage || error.message })
      }
    },
  })
}
