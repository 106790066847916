import { CloseButtonProps } from "react-toastify"

import { IconCross } from "@/shared/icons"

import { SCloseToastButton } from "./CloseToast.styles"

export const CloseToast = ({ closeToast }: CloseButtonProps) => {
  return (
    <SCloseToastButton type="button" onClick={closeToast}>
      <IconCross />
    </SCloseToastButton>
  )
}
