const createArrayRange = (start: number, stop: number) =>
  Array.from({ length: (stop - start) / 1 + 1 }, (value, index) => start + index)

export const createPaginationRange = (total: number, step: number, page: number) => {
  let arr = []

  if (total < step * 2 + 6) {
    arr = createArrayRange(1, total)
  } else if (page < step * 2 + 1) {
    arr = createArrayRange(1, step * 2 + 3)
    arr.push(0)
    arr.push(total)
  } else if (page > total - step * 2) {
    arr.push(1)
    arr.push(0)
    arr = arr.concat(createArrayRange(total - step * 2 - 2, total))
  } else {
    arr.push(1)
    arr.push(0)
    arr = arr.concat(createArrayRange(page - step, page + step))
    arr.push(0)
    arr.push(total)
  }

  return arr
}
