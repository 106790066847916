import { useCheckTaskStatus, useStartTask, useStopTask } from "@/tasks/hooks"

export const useTaskData = (taskName: string) => {
  const { data, fetchStatus } = useCheckTaskStatus(taskName)
  const { mutate: startTask, isLoading: isStartLoading } = useStartTask()
  const { mutate: stopTask, isLoading: isStopLoading } = useStopTask()
  const isLoading = fetchStatus === "fetching" || isStartLoading || isStopLoading

  return {
    status: data,
    stopTask,
    startTask,
    isLoading,
  }
}
