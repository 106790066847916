import { OrderStatusType, FlsStatusType, flsStatusesText } from "@/shared/types"

const orderStatusTypeText = {
  OMS_CREATED: "Created",
  OMS_ACCEPTED: "Created",
  READY_FOR_TENDER: "Created",
  WAITING_FOR_ACKNOWLEDGE: "In progress",
  TENDERING: "In progress",
  TENDERING_FINISHED: "Completed",
  COMPLETED: "Completed",
  NOT_COVERED: "Not covered",
}

export const statusToText = (status: OrderStatusType | FlsStatusType) => {
  if (Object.values(OrderStatusType).includes(status as OrderStatusType)) {
    return orderStatusTypeText[status as OrderStatusType]
  } else if (Object.values(FlsStatusType).includes(status as FlsStatusType)) {
    return flsStatusesText[status as FlsStatusType]
  } else {
    return status
  }
}
