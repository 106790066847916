import { FieldValues } from "react-hook-form"

import { FormType, IFormInputControllerProps } from "@/shared/types"
import { emailRules } from "@/shared/rules"

import { MainController } from "@/shared/components"

export const EmailController = <FieldsType extends FieldValues>({
  name,
  control,
  error,
  disabled,
  label,
  labelProps,
  description,
  placeholder,
  borderless,
  rules,
  register,
  trigger,
  onBlurHandler,
  onChangeHandler,
}: IFormInputControllerProps<FieldsType>) => {
  return (
    <MainController<FieldsType>
      name={name}
      type={FormType.Email}
      rules={{ ...emailRules(!!labelProps?.required), ...rules }}
      control={control}
      register={register}
      trigger={trigger}
      error={error}
      disabled={disabled}
      placeholder={placeholder}
      label={label ? label : description ? description : borderless ? "" : "Email"}
      labelProps={{ ...labelProps, hidden: true }}
      description={description ? description : borderless ? "" : "Email"}
      borderless={borderless}
      onBlurHandler={onBlurHandler}
      onChangeHandler={onChangeHandler}
      autoComplete="new-password"
    />
  )
}
