import React from "react"
import ReactDOM from "react-dom/client"
import { QueryClientProvider, QueryClient } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { BrowserRouter } from "react-router-dom"
// import i18n from "i18next"
// import { initReactI18next } from "react-i18next"
import { ThemeProvider } from "next-themes"
// import { Honeybadger, HoneybadgerErrorBoundary } from "@honeybadger-io/react"
import { Auth0Provider } from "@auth0/auth0-react"

import { AxiosInterceptor } from "@/axios"
import { darkTheme } from "@/stitches/config"
import { APP_ENV, authData, isDev, LOGIN } from "@/constants"
import { ThemeType } from "@/shared/types"

import App from "./App"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
      // refetchOnMount: false,
      // refetchOnReconnect: false,
      // retry: 1,
    },
  },
})

// i18n.use(initReactI18next).init({
//   resources: {
//     en: {},
//   },
//   lng: "en",
//   fallbackLng: "en",
//   defaultNS: "common",
//   ns: ["common"],
//   debug: false,
//   load: "currentOnly",
//   interpolation: {
//     escapeValue: false,
//   },
// })

// const config = {
//   apiKey: "hbp_arSGlMLrJSPOsS13GSWCHfxFHBqwpN2QKHmP",
//   environment: APP_ENV,
// }

// const honeybadger = Honeybadger.configure(config)

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ThemeProvider
          attribute="class"
          defaultTheme={ThemeType.Light}
          enableColorScheme={false}
          value={{
            light: ThemeType.Light,
            dark: darkTheme.className,
          }}
        >
          <Auth0Provider
            domain={authData[APP_ENV].domain}
            clientId={authData[APP_ENV].clientId}
            authorizationParams={{
              redirect_uri: `${window.location.origin}${LOGIN}`,
              audience: authData[APP_ENV].audience,
            }}
          >
            <AxiosInterceptor>
              <App />
            </AxiosInterceptor>
          </Auth0Provider>
        </ThemeProvider>
      </BrowserRouter>
      {isDev ? <ReactQueryDevtools initialIsOpen={false} /> : null}
    </QueryClientProvider>
  </React.StrictMode>,
)
