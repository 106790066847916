import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { useDialog, useDialogActions, useDialogManager } from "@/shared/hooks"

type ModalProps = {
  url: string
}

export function useModal(name: string, defaultOpen?: boolean) {
  const [modalProps, { container, setContainer }] = useDialog(name, "modals", defaultOpen)
  const initial: ModalProps = {
    url: "",
  }
  const [modalContext, setModalContext] = useState(initial)
  return { modalProps, container, setContainer, modalContext, setModalContext } as const
}

export function useModalActions() {
  return useDialogActions("modals")
}

export const useModalsClose = () => {
  const location = useLocation()
  const { closeAll } = useModalActions()

  useEffect(() => {
    closeAll()
  }, [closeAll, location])

  useDialogManager(["drawers", "modals"])

  return {
    closeAll,
  }
}
