import { Controller, useFormContext } from "react-hook-form"
import { useParams } from "react-router-dom"

import { useCurrentAndAvailableSource } from "@/management/hooks"
import { RouteParams, IOrganizationState } from "@/shared/types"
import { useIsCustomerAdmin } from "@/shared/utils"

import { Flex, FormSelect, Grid, GridItem, Stack, Title } from "@/shared/components"

export const MarketRate = () => {
  const { control, register, setValue } = useFormContext<IOrganizationState>()
  const { organizationId } = useParams<keyof RouteParams>() as RouteParams
  const { data: currentMarketSource } = useCurrentAndAvailableSource(organizationId)
  const isRoleCustomerAdmin = useIsCustomerAdmin()

  return (
    <Stack space={32}>
      <Flex align="center" css={{ gap: "$8" }}>
        <Title as="h2" scale={6} color="theme-b-n3">
          Select market rate source
        </Title>
      </Flex>
      <Grid columns="1fr 1fr 1fr 1fr" columnGap={16}>
        <GridItem>
          <Controller
            name="currentMarketSource"
            control={control}
            render={({ field }) => {
              return (
                <FormSelect
                  {...(register &&
                    register(field.name, {
                      shouldUnregister: true,
                    }))}
                  {...field}
                  disabled={isRoleCustomerAdmin}
                  onValueChange={(value) => {
                    setValue("currentMarketSource", value as string, { shouldDirty: true })
                  }}
                  id={field.name}
                  isSearchable
                  isDisplayValueDifferent
                  value={{
                    value: field.value === null || field.value === "" ? "" : field.value,
                    label: field.value === null || field.value === "" ? "Not selected" : field.value,
                  }}
                  options={[
                    { value: "", label: "Not selected" },
                    ...(currentMarketSource?.availableMarketSources || []).map((i) => ({
                      value: i,
                      label: i,
                    })),
                  ]}
                  label="Market rate source"
                  labelProps={{
                    css: {
                      p: {
                        color: "$neutrals-5",
                      },
                    },
                  }}
                />
              )
            }}
          />
        </GridItem>
      </Grid>
    </Stack>
  )
}
