import { useMemo } from "react"

import { Role } from "@/shared/types"
import { useGlobalStore } from "@/store"
import { getUserRole } from "./getUserRole"

export const isCustomerAdmin = (role: Role): boolean => role === Role.CustomerAdmin
export const isSysAdmin = (role: Role): boolean => role === Role.SysAdmin
export const isReader = (role: Role): boolean => role === Role.Reader

export const useUserRole = () => {
  const [user] = useGlobalStore((state) => [state.user])

  const userRole = useMemo(() => getUserRole(user), [user])

  return userRole
}

export const useIsCustomerAdmin = () => {
  const userRole = useUserRole()

  const isCustomerAdmin = useMemo(() => userRole === Role.CustomerAdmin, [userRole])

  return isCustomerAdmin
}

export const useIsSysAdmin = () => {
  const userRole = useUserRole()

  const isSysAdmin = useMemo(() => userRole === Role.SysAdmin, [userRole])

  return isSysAdmin
}

export const useIsReader = () => {
  const userRole = useUserRole()

  const isReader = useMemo(() => userRole === Role.Reader, [userRole])

  return isReader
}
