import { useFormContext } from "react-hook-form"

import { IOrganizationState } from "@/shared/types"
import { numericPattern } from "@/shared/rules"

import { Grid, GridItem, TextFieldController } from "@/shared/components"

export const Waterfall = ({ isViewMode }: { isViewMode?: boolean }) => {
  const {
    watch,
    control,
    register,
    trigger,
    formState: { errors },
  } = useFormContext<IOrganizationState>()
  const { waterfallStrategy, waitAllStrategy, firstComeStrategy, criticalStrategy } = watch()

  return (
    <>
      <Grid columns="1fr 1fr 1fr 1fr" columnGap={16}>
        <GridItem>
          <TextFieldController<IOrganizationState>
            name="waterfallStrategy.finishPoint"
            control={control}
            register={register}
            trigger={trigger}
            labelProps={{ required: !isViewMode && waterfallStrategy.enabled }}
            description="Finish point, Business days before Pickup"
            rules={{
              ...numericPattern,
              validate: {
                max: (v: string) => parseInt(v || "0") < 22 || "Max value not met",
                min: (v: string) => v.length === 0 || parseInt(v || "0") >= 0 || "Min value not met",
              },
            }}
            onChangeHandler={() => {
              if (waitAllStrategy?.enabled) {
                trigger("waitAllStrategy.finishPoint")
              } else if (firstComeStrategy.enabled) {
                trigger("firstComeStrategy.finishPoint")
              } else if (criticalStrategy.enabled) {
                trigger("criticalStrategy.finishPoint")
              }
            }}
            borderless={isViewMode}
            isViewMode={isViewMode}
            error={errors.waterfallStrategy?.finishPoint?.message}
          />
        </GridItem>
        <GridItem>
          <TextFieldController<IOrganizationState>
            name="waterfallStrategy.responseTime"
            control={control}
            register={register}
            trigger={trigger}
            labelProps={{ required: !isViewMode && waterfallStrategy.enabled }}
            description="Response time, minutes"
            rules={{
              ...numericPattern,
              validate: {
                max: (v: string) => parseInt(v || "0") < 1000 || "Max value exceeded",
                min: (v: string) => v.length === 0 || parseInt(v || "0") > 0 || "Min value not met",
              },
            }}
            borderless={isViewMode}
            isViewMode={isViewMode}
            error={errors.waterfallStrategy?.responseTime?.message}
          />
        </GridItem>
      </Grid>
    </>
  )
}
