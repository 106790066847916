import { useQuery } from "@tanstack/react-query"
import { isAxiosError } from "axios"

import { getFeatureFlagsFn } from "@/api/ordersApi"
import { showToast } from "@/shared/utils"
import { FeatureFlags } from "@/shared/types"
import { useGlobalStore } from "@/store"

export const useFeatureFlags = (keys: FeatureFlags[]) => {
  const [setFeatureFlags] = useGlobalStore((state) => [state.setFeatureFlags])

  return useQuery(["allFeatures", keys], () => getFeatureFlagsFn(keys), {
    keepPreviousData: true,
    refetchInterval: 60 * 1000,
    onSuccess: (currentFeatures) => setFeatureFlags(currentFeatures),
    onError: (error) => {
      if (isAxiosError(error)) {
        showToast({
          type: "error",
          text: error.response?.data.errorMessage || error.message,
        })
      }
    },
  })
}
