import { useNavigate } from "react-router-dom"

import { mainOrganizationId, MANAGEMENT } from "@/constants"
import { ModalType } from "@/shared/types"
import { useModalActions } from "@/shared/hooks"
import { phoneToText } from "@/shared/utils"

import {
  ButtonIcon,
  Copy,
  Flex,
  Grid,
  GridItem,
  Redacted,
  Tooltip,
  useGlobalSettings,
} from "@/shared/components"
import { IconBin, IconPencil } from "@/shared/icons"

interface IOrganizationTableRowProps {
  id?: string
  name?: string
  address?: string
  email?: string
  phone?: string
  isTableHead?: boolean
  isPlaceholder?: boolean
}

export const OrganizationTableRow = ({
  id,
  name,
  address,
  email,
  phone,
  isTableHead,
  isPlaceholder,
}: IOrganizationTableRowProps) => {
  const navigate = useNavigate()

  return (
    <Grid
      columns="0.8fr 1fr 0.8fr 1fr"
      align="center"
      css={{
        height: isTableHead ? "$48" : "$64",
        color: "$theme-b-n3",
        borderBottom: isTableHead ? "1px solid $theme-n3-n7" : "none",
        cursor: !isPlaceholder && !isTableHead ? "pointer" : "default",
        transition: "150ms ease-out",

        lastOfType: {
          borderRadius: "0 0 $8 $8",
        },
        hover: { backgroundColor: !isPlaceholder && !isTableHead ? "$brand-yellow-lightest" : "inherit" },
        keyboardFocus: {
          backgroundColor: !isPlaceholder && !isTableHead ? "$brand-yellow-lighter" : "inherit",
        },
      }}
      tabIndex={!isPlaceholder && !isTableHead ? 0 : -1}
      onClick={() => !isPlaceholder && !isTableHead && navigate(`${MANAGEMENT}/organization/${id}`)}
    >
      <GridItem css={{ paddingX: "$24" }}>
        {isPlaceholder && !isTableHead ? (
          <Redacted height="$24" width="140px" text animated />
        ) : isTableHead ? (
          <Copy scale={8} fontWeight="bold" uppercase>
            {name}
          </Copy>
        ) : (
          <Tooltip
            tooltip={<Copy scale={{ "@initial": 12, "@lg": 8 }}>{name}</Copy>}
            ariaLabel="name tooltip"
            withTitle={false}
            contentWidth="max-content"
            trigger={["hover"]}
            placement="top"
            contentCss={{
              width: "100%",
              padding: "$12",
              borderRadius: "$8",
            }}
            triggerCss={{
              display: "inline-grid",
              textAlign: "start",
              color: "$system-inherit",
              outline: "none",

              hover: {
                outline: "none",
                boxShadow: "none",
              },
              keyboardFocus: {
                outline: "none",
              },
            }}
            avoidTriggerFocus
          >
            <Copy scale={10} truncate>
              {name}
            </Copy>
          </Tooltip>
        )}
      </GridItem>
      <GridItem css={{ paddingX: "$24" }}>
        {isPlaceholder && !isTableHead ? (
          <Redacted height="$24" width="100px" text animated />
        ) : isTableHead || !address ? (
          <Copy
            scale={isTableHead ? 8 : 10}
            fontWeight={isTableHead ? "bold" : "regular"}
            uppercase={isTableHead ? true : false}
          >
            {address || "-"}
          </Copy>
        ) : (
          <Tooltip
            tooltip={<Copy scale={{ "@initial": 12, "@lg": 8 }}>{address}</Copy>}
            ariaLabel="address tooltip"
            withTitle={false}
            contentWidth="max-content"
            trigger={["hover"]}
            placement="top"
            contentCss={{
              width: "100%",
              padding: "$12",
              borderRadius: "$8",
            }}
            triggerCss={{
              display: "inline-grid",
              textAlign: "start",
              color: "$system-inherit",
              outline: "none",

              hover: {
                outline: "none",
                boxShadow: "none",
              },
              keyboardFocus: {
                outline: "none",
              },
            }}
            avoidTriggerFocus
          >
            <Copy scale={10} truncate>
              {address}
            </Copy>
          </Tooltip>
        )}
      </GridItem>

      <GridItem css={{ paddingX: "$24" }}>
        {isPlaceholder && !isTableHead ? (
          <Redacted height="$24" width="140px" text animated />
        ) : isTableHead ? (
          <Copy scale={8} fontWeight="bold" uppercase>
            {email}
          </Copy>
        ) : (
          <Tooltip
            tooltip={<Copy scale={{ "@initial": 12, "@lg": 8 }}>{email || "-"}</Copy>}
            ariaLabel="email tooltip"
            withTitle={false}
            contentWidth="max-content"
            trigger={["hover"]}
            placement="top"
            contentCss={{
              width: "100%",
              padding: "$12",
              borderRadius: "$8",
            }}
            triggerCss={{
              display: "inline-grid",
              textAlign: "start",
              color: "$system-inherit",
              outline: "none",

              hover: {
                outline: "none",
                boxShadow: "none",
              },
              keyboardFocus: {
                outline: "none",
              },
            }}
            avoidTriggerFocus
          >
            <Copy scale={10} truncate>
              {email || "-"}
            </Copy>
          </Tooltip>
        )}
      </GridItem>

      <GridItem css={{ paddingX: "$24" }}>
        {isPlaceholder && !isTableHead ? (
          <Redacted height="$24" width="100px" text animated />
        ) : isTableHead ? (
          <Copy scale={8} fontWeight="bold" uppercase>
            {phone}
          </Copy>
        ) : (
          <Flex align="center" justify="between">
            <Copy scale={10}>{phoneToText(phone) || "-"}</Copy>
            <Flex css={{ gap: "$8" }}>
              <EditButton id={id || ""} />
              <RemoveButton id={id || ""} disabled={mainOrganizationId === id} />
            </Flex>
          </Flex>
        )}
      </GridItem>
    </Grid>
  )
}

const RemoveButton = ({ id, disabled }: { id: string; disabled?: boolean }) => {
  const { open } = useModalActions()
  const { setOrganizationId } = useGlobalSettings("ActionDetailsButton")

  const onDeleteClick = (e: React.SyntheticEvent, id: string) => {
    e.stopPropagation()
    setOrganizationId(id)
    open(ModalType.DeleteOrganizationModal)
  }

  return (
    <ButtonIcon
      type="button"
      ariaLabel={`Remove carrier ${id}`}
      icon={<IconBin />}
      onClick={(e) => onDeleteClick(e, id)}
      inputIcon
      disabled={disabled}
    />
  )
}

const EditButton = ({ id }: { id: string }) => {
  const navigate = useNavigate()

  const onEditClick = (e: React.SyntheticEvent, id: string) => {
    e.stopPropagation()
    navigate(`${MANAGEMENT}/edit/organization/${id}`)
  }

  return (
    <ButtonIcon
      type="button"
      ariaLabel={`Edit carrier ${id}`}
      icon={<IconPencil />}
      onClick={(e) => onEditClick(e, id)}
      inputIcon
    />
  )
}
