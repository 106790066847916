import { useFormContext } from "react-hook-form"

import { LANES_MAX } from "@/constants"
import { IFlsFiltersState } from "@/shared/types"

import { AddItemButton, Box, Copy, Flex, Spacer } from "@/shared/components"

import { FlsCustomersTablePlaceholder } from "./FlsCustomersTablePlaceholder"
import { FlsCustomersTableRow } from "./FlsCustomersTableRow"

export const FlsCustomers = ({
  isEditMode = false,
  isLoading,
}: {
  isEditMode?: boolean
  isLoading?: boolean
}) => {
  const { trigger, setValue, watch } = useFormContext<IFlsFiltersState>()

  const { customersFilter } = watch()
  const { filterData } = customersFilter

  return (
    <Box css={{ marginTop: "-$8" }}>
      {isEditMode ? (
        <>
          <AddItemButton
            text="Add customer"
            onClick={() => {
              setValue(
                "customersFilter.filterData",
                [
                  {
                    id: `id-${Date.now()}`,
                    value: "",
                  },
                  ...filterData,
                ],
                {
                  shouldDirty: true,
                },
              )
              setTimeout(() => {
                trigger("customersFilter.filterData")
              }, 0)
            }}
            disabled={filterData.length >= LANES_MAX}
            css={{ width: "fit-content" }}
          />
          <Spacer size={24} />
        </>
      ) : null}

      {isLoading ? (
        <FlsCustomersTablePlaceholder />
      ) : (
        <Box>
          {filterData.length > 0 ? (
            filterData.map(({ id, value }, index) => (
              <FlsCustomersTableRow
                key={index}
                index={index}
                id={id}
                value={value}
                isViewMode={!isEditMode}
              />
            ))
          ) : (
            <Flex
              align="center"
              justify="center"
              css={{
                height: "$64",
                color: "$theme-b-n3",
              }}
            >
              <Copy color="theme-n6-n5">There is no data yet</Copy>
            </Flex>
          )}
        </Box>
      )}
    </Box>
  )
}
