import { Role } from "@/shared/types"
import { APP_ENV } from "./api.constants"

export const MODAL_ANIMATION_DURATION = 200
export const WORKING_DAYS_MAX = 7
export const CONTACTS_MAX = 20
export const LANES_MAX = 100
export const GET_MAX_CARRIERS = 10
export const PAGE_MAX_CARRIERS = 10

export const isDev = APP_ENV === "development"
export const isProd = APP_ENV === "production"

export const allRoles = [Role.SysAdmin, Role.CustomerAdmin, Role.Reader]
export const allAdminRoles = [Role.SysAdmin, Role.CustomerAdmin]
export const adminRoles = [Role.SysAdmin]

export const mainOrganizationName = "Mariner"
export const mainOrganizationId = "1"

export const uberScac = "UBER"

export const ErrorStatusCode = {
  401: 401,
}

export const authData = {
  development: {
    domain: "dev-ftiy88gxhb5vdeqg.us.auth0.com",
    clientId: "vJiMYM3pkWLZ89hxgo3po4Ky564PnubS",
    audience: "https://dev.api.projectapollo.io/",
  },
  staging: {
    domain: "dev-ftiy88gxhb5vdeqg.us.auth0.com",
    clientId: "NoFXsRyVau0ZezCUSlP9jsXMhEn6nM9n",
    audience: "https://staging.api.projectapollo.io/",
  },
  production: {
    domain: "projectapollo.us.auth0.com",
    clientId: "w61c2cruxkinGRkG2VtVHqm4busshZ7P",
    audience: "https://api.projectapollo.io/",
  },
}
