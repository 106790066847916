import { laneRegex } from "@/shared/rules"
import { LaneStopType } from "@/shared/types"

export const getStopType = (value: string) => {
  if (laneRegex.state.test(value)) {
    return LaneStopType.STATE
  } else if (laneRegex.threeDigitZip.test(value)) {
    return LaneStopType.ZIP_CODE_3
  } else if (laneRegex.fiveDigitZip.test(value)) {
    return LaneStopType.ZIP_CODE_5
  } else if (laneRegex.kmaCode.test(value)) {
    return LaneStopType.KMA
  } else if (laneRegex.cityState.test(value)) {
    return LaneStopType.CITY_STATE
  } else {
    return LaneStopType.ANY
  }
}
