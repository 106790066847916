import { useCallback } from "react"
import { useParams } from "react-router-dom"

import { useFormContext } from "react-hook-form"

import { useModal, useModalActions } from "@/shared/hooks"
import { ModalType, RouteParams, IOrganizationState, carrierInitialState, FeatureFlags } from "@/shared/types"
import { useCheckCarriersApiKey, useUpdateOrganizationCarrier } from "@/shared/data"
import { checkTmsCodeFn } from "@/api/slaApi"

import { Box, Button, Flex } from "@/shared/components"
import { getFeatureFlags, isHighway } from "@/shared/utils"

import { GeneralModal } from "@/modals"

import { CarrierDetailsTabs } from "./components"

export const CreateCarrierModal = () => {
  const [isIdInsteadScac] = getFeatureFlags([FeatureFlags.FEATURE_FLAG_USE_CARRIER_ID_INSTEAD_SCAC])
  const { organizationId } = useParams<keyof RouteParams>() as RouteParams
  const { modalProps } = useModal(ModalType.CreateCarrierModal)
  const { close } = useModalActions()
  const { mutateAsync: updateOrganizationCarriers, isLoading: isUpdateOrganizationCarriersLoading } =
    useUpdateOrganizationCarrier()
  const { mutateAsync: checkCarriersApiKey, isLoading: isCheckCarrierApiKeyLoading } =
    useCheckCarriersApiKey()

  const {
    watch,
    setError,
    getValues,
    resetField,
    clearErrors,
    formState: { errors, dirtyFields, defaultValues },
  } = useFormContext<IOrganizationState>()
  const { newCarrier } = watch()
  const { name, scac, code, contacts, lanes } = newCarrier

  const isEditMode = !!newCarrier.id
  const contactsChanges = contacts.toString() != `${defaultValues?.newCarrier?.contacts}`
  const lanesChanges = lanes.toString() != `${defaultValues?.newCarrier?.lanes}`
  const isLoading = isUpdateOrganizationCarriersLoading || isCheckCarrierApiKeyLoading

  const checkButtonDisability = () => {
    if (errors.newCarrier) {
      return true
    }

    const globalChanges = contactsChanges || lanesChanges ? true : dirtyFields.newCarrier

    if (isIdInsteadScac) {
      return !name || !code || !globalChanges
    }

    return !name || !code || !scac || !globalChanges
  }

  const isButtonDisabled = checkButtonDisability()

  const handleAddClick = useCallback(async () => {
    const isTmsCodeUnique = await checkTmsCodeFn(organizationId, getValues("newCarrier.code"))

    if (!isTmsCodeUnique) {
      setError("newCarrier.code", {
        type: "manual",
        message: "TMS code should be unique",
      })

      return
    }

    await checkCarriersApiKey({
      data: getValues("carriers"),
      orgId: organizationId,
    })
    await updateOrganizationCarriers({
      orgId: organizationId,
      data: [getValues("newCarrier")],
      toDelete: getValues("carriersToDelete"),
    })

    resetField("newCarrier", { defaultValue: carrierInitialState })
    clearErrors("newCarrier")
    close(ModalType.CreateCarrierModal)
  }, [
    organizationId,
    checkCarriersApiKey,
    getValues,
    updateOrganizationCarriers,
    resetField,
    setError,
    clearErrors,
    close,
  ])

  const handleEditClick = useCallback(async () => {
    await updateOrganizationCarriers({
      orgId: organizationId,
      data: [getValues("newCarrier")],
      toDelete: getValues("carriersToDelete"),
    })

    resetField("newCarrier", { defaultValue: carrierInitialState })
    clearErrors("newCarrier")
    close(ModalType.CreateCarrierModal)
  }, [organizationId, getValues, updateOrganizationCarriers, resetField, clearErrors, close])

  const handleCancelClick = useCallback(() => {
    close(ModalType.CreateCarrierModal)
    setTimeout(() => {
      resetField("newCarrier", { defaultValue: carrierInitialState })
      clearErrors("newCarrier")
    }, 300)
  }, [clearErrors, close, resetField])

  return (
    <GeneralModal
      {...modalProps}
      header={isEditMode ? "Edit a carrier" : "Create a carrier"}
      size="xl"
      closeButton={{ onClick: handleCancelClick }}
      onInteractOutside={(e) => {
        e?.preventDefault()
      }}
      onEscapeKeyDown={handleCancelClick}
      footer={
        <Flex justify="end" css={{ gap: "$16" }}>
          <Button
            disabled={isButtonDisabled || isLoading}
            onClick={isEditMode ? handleEditClick : handleAddClick}
          >
            {isEditMode ? "Save" : "Add"}
          </Button>
          <Button disabled={isLoading} action="secondary" onClick={handleCancelClick}>
            Cancel
          </Button>
        </Flex>
      }
    >
      <Box css={{ padding: "0 $24" }}>
        <CarrierDetailsTabs isHighway={isHighway(newCarrier)} />
      </Box>
    </GeneralModal>
  )
}
