import { useFormContext } from "react-hook-form"

import { IOrganizationState } from "@/shared/types"
import { lengthRule, numericPattern } from "@/shared/rules"

import { Box, Grid, GridItem, Stack, TextFieldController, Title } from "@/shared/components"

export const Equipment = ({ isHighway }: { isHighway?: boolean }) => {
  const {
    control,
    register,
    formState: { errors },
    trigger,
  } = useFormContext<IOrganizationState>()

  return (
    <Box css={{ height: 420 }}>
      <Stack space={32}>
        <Title as="h2" scale={6} color="theme-b-n3">
          Equipment
        </Title>
        <Grid columns="1fr 1fr 1fr 1fr" columnGap={16} rowGap={32}>
          <GridItem>
            <TextFieldController<IOrganizationState>
              name="newCarrier.equipment.truckAmount"
              control={control}
              register={register}
              trigger={trigger}
              description="Trucks"
              disabled={isHighway}
              rules={{ ...lengthRule("Value", 7, 0), ...numericPattern }}
              error={errors.newCarrier?.equipment?.truckAmount?.message}
            />
          </GridItem>
          <GridItem>
            <TextFieldController<IOrganizationState>
              name="newCarrier.equipment.vanAmount"
              control={control}
              register={register}
              trigger={trigger}
              description="Vans"
              disabled={isHighway}
              rules={{ ...lengthRule("Value", 7, 0), ...numericPattern }}
              error={errors.newCarrier?.equipment?.vanAmount?.message}
            />
          </GridItem>
          <GridItem>
            <TextFieldController<IOrganizationState>
              name="newCarrier.equipment.flatbedAmount"
              control={control}
              register={register}
              trigger={trigger}
              description="Flatbeds"
              disabled={isHighway}
              rules={{ ...lengthRule("Value", 7, 0), ...numericPattern }}
              error={errors.newCarrier?.equipment?.flatbedAmount?.message}
            />
          </GridItem>
          <GridItem>
            <TextFieldController<IOrganizationState>
              name="newCarrier.equipment.reeferAmount"
              control={control}
              register={register}
              trigger={trigger}
              description="Reefers"
              disabled={isHighway}
              rules={{ ...lengthRule("Value", 7, 0), ...numericPattern }}
              error={errors.newCarrier?.equipment?.reeferAmount?.message}
            />
          </GridItem>
          {isHighway ? (
            <>
              <GridItem>
                <TextFieldController<IOrganizationState>
                  name="newCarrier.equipment.dropDeckAmount"
                  control={control}
                  register={register}
                  trigger={trigger}
                  description="Drop deck trailers"
                  disabled
                  rules={{ ...lengthRule("Value", 7, 0), ...numericPattern }}
                  error={errors.newCarrier?.equipment?.dropDeckAmount?.message}
                />
              </GridItem>
              <GridItem>
                <TextFieldController<IOrganizationState>
                  name="newCarrier.equipment.tankerAmount"
                  control={control}
                  register={register}
                  trigger={trigger}
                  description="Tanker trailers"
                  disabled
                  rules={{ ...lengthRule("Value", 7, 0), ...numericPattern }}
                  error={errors.newCarrier?.equipment?.tankerAmount?.message}
                />
              </GridItem>
              <GridItem>
                <TextFieldController<IOrganizationState>
                  name="newCarrier.equipment.intermodalAmount"
                  control={control}
                  register={register}
                  trigger={trigger}
                  description="Intermodal trailers"
                  disabled
                  rules={{ ...lengthRule("Value", 7, 0), ...numericPattern }}
                  error={errors.newCarrier?.equipment?.intermodalAmount?.message}
                />
              </GridItem>
              <GridItem>
                <TextFieldController<IOrganizationState>
                  name="newCarrier.equipment.pneumaticAmount"
                  control={control}
                  register={register}
                  trigger={trigger}
                  description="Pneumatic trailers"
                  disabled
                  rules={{ ...lengthRule("Value", 7, 0), ...numericPattern }}
                  error={errors.newCarrier?.equipment?.pneumaticAmount?.message}
                />
              </GridItem>
              <GridItem>
                <TextFieldController<IOrganizationState>
                  name="newCarrier.equipment.dumpsAmount"
                  control={control}
                  register={register}
                  trigger={trigger}
                  description="Dumps trailers"
                  disabled
                  rules={{ ...lengthRule("Value", 7, 0), ...numericPattern }}
                  error={errors.newCarrier?.equipment?.dumpsAmount?.message}
                />
              </GridItem>
              <GridItem>
                <TextFieldController<IOrganizationState>
                  name="newCarrier.equipment.carHaulerAmount"
                  control={control}
                  register={register}
                  trigger={trigger}
                  description="Car hauler trailers"
                  disabled
                  rules={{ ...lengthRule("Value", 7, 0), ...numericPattern }}
                  error={errors.newCarrier?.equipment?.carHaulerAmount?.message}
                />
              </GridItem>
              <GridItem>
                <TextFieldController<IOrganizationState>
                  name="newCarrier.equipment.doubleDropAmount"
                  control={control}
                  register={register}
                  trigger={trigger}
                  description="Double drop trailers"
                  disabled
                  rules={{ ...lengthRule("Value", 7, 0), ...numericPattern }}
                  error={errors.newCarrier?.equipment?.doubleDropAmount?.message}
                />
              </GridItem>
              <GridItem>
                <TextFieldController<IOrganizationState>
                  name="newCarrier.equipment.lowboyAmount"
                  control={control}
                  register={register}
                  trigger={trigger}
                  description="Lowbody trailers"
                  disabled
                  rules={{ ...lengthRule("Value", 7, 0), ...numericPattern }}
                  error={errors.newCarrier?.equipment?.lowboyAmount?.message}
                />
              </GridItem>
              <GridItem>
                <TextFieldController<IOrganizationState>
                  name="newCarrier.equipment.grainAmount"
                  control={control}
                  register={register}
                  trigger={trigger}
                  description="Grain trailers"
                  disabled
                  rules={{ ...lengthRule("Value", 7, 0), ...numericPattern }}
                  error={errors.newCarrier?.equipment?.grainAmount?.message}
                />
              </GridItem>
              <GridItem>
                <TextFieldController<IOrganizationState>
                  name="newCarrier.equipment.livestockAmount"
                  control={control}
                  register={register}
                  trigger={trigger}
                  description="Livestock trailers"
                  disabled
                  rules={{ ...lengthRule("Value", 7, 0), ...numericPattern }}
                  error={errors.newCarrier?.equipment?.livestockAmount?.message}
                />
              </GridItem>
              <GridItem>
                <TextFieldController<IOrganizationState>
                  name="newCarrier.equipment.heavyHaulAmount"
                  control={control}
                  register={register}
                  trigger={trigger}
                  description="Heavy haul trailers"
                  disabled
                  rules={{ ...lengthRule("Value", 7, 0), ...numericPattern }}
                  error={errors.newCarrier?.equipment?.heavyHaulAmount?.message}
                />
              </GridItem>
            </>
          ) : null}
        </Grid>
      </Stack>
    </Box>
  )
}
