import { isAxiosError } from "axios"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { createOrganizationFn } from "@/api/organizationApi"
import { showToast, transformOrganizationRequest } from "@/shared/utils"
import { IOrganizationState } from "@/shared/types"

export const useCreateOrganization = () => {
  const queryClient = useQueryClient()

  return useMutation((data: IOrganizationState) => createOrganizationFn(transformOrganizationRequest(data)), {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["organizationSla"],
      })
      queryClient.invalidateQueries({
        queryKey: ["allOrganizations"],
      })
      queryClient.invalidateQueries({
        queryKey: ["allUserOrganizations"],
      })
      queryClient.invalidateQueries({
        queryKey: ["organizationById"],
      })
      queryClient.invalidateQueries({
        queryKey: ["userById"],
      })
    },
    onError: (error) => {
      if (isAxiosError(error)) {
        showToast({ type: "error", text: error.response?.data.errorMessage || error.message })
      }
    },
  })
}
