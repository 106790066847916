import React from "react"

import { Flex, Stack } from "@/shared/components"

export const EquipmentInfoCard = ({ content, fill }: { content: React.ReactNode; fill?: boolean }) => {
  return (
    <Flex
      align="start"
      css={{
        padding: "$20 $24",
        gap: "$16",
        border: "1px solid $theme-n3-n7",
        borderRadius: "$8",
        backgroundColor: fill ? "$system-white" : "none",
      }}
    >
      <Stack space={16} css={{ flex: 1 }}>
        {content}
      </Stack>
    </Flex>
  )
}
