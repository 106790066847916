import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { useNavigate, useParams } from "react-router-dom"

import { lengthRule, nonNumericPattern } from "@/shared/rules"
import { useGlobalStore } from "@/store"
import {
  generateApiKey,
  isSysAdmin,
  phoneToText,
  generateSecretKey,
  useIsReader,
  useIsCustomerAdmin,
  getEnumKeyByEnumValue,
} from "@/shared/utils"
import { useAllUserOrganizations, useOrganizationById } from "@/management/hooks"
import { MANAGEMENT } from "@/constants"
import {
  FormType,
  RouteParams,
  IOrganizationState,
  OrganizationType,
  initialOrganizationState,
  hiddenInputValue,
} from "@/shared/types"

import {
  Box,
  TextFieldController,
  Copy,
  CountryController,
  ZipCodeController,
  EmailController,
  PhoneController,
  StateController,
  Spacer,
  Grid,
  GridItem,
  Stack,
  FormSelect,
  TimeZoneController,
  ButtonIcon,
  Spinner,
  Flex,
  Breadcrumbs,
  Title,
  IconTooltip,
} from "@/shared/components"
import { WorkingDays } from "@/management/components"
import { IconCopy, IconInfoCircle, IconRefresh } from "@/shared/icons"

const checkApiKeyVisibility = (secretKey: string) => secretKey === hiddenInputValue

export const OrganizationGeneralInfo = () => {
  const navigate = useNavigate()
  const { organizationId } = useParams<keyof RouteParams>() as RouteParams
  const isEditMode = location.pathname.includes("edit")
  const [organization, user] = useGlobalStore((state) => [state.organization, state.user])
  const [copiedApiKeyToClipboard, setCopiedApiKeyToClipboard] = useState(false)
  const [copiedApiSecretToClipboard, setCopiedApiSecretToClipboard] = useState(false)
  const [copiedCarrierApiKeyToClipboard, setCopiedCarrierApiKeyToClipboard] = useState(false)
  const [copiedCarrierApiSecretToClipboard, setCopiedCarrierApiSecretToClipboard] = useState(false)
  const [selectedParentOrgType, setSelectedParentOrgType] = useState(OrganizationType.Shipper)
  const isRoleReader = useIsReader()
  const isRoleCustomerAdmin = useIsCustomerAdmin()

  const isOrganizationUnavailable =
    isEditMode && (isRoleCustomerAdmin || isRoleReader) && organization.id !== organizationId

  const handleCopyClick = (action: Dispatch<SetStateAction<boolean>>) => {
    action(true)

    setTimeout(() => {
      action(false)
    }, 2000)
  }

  const CopyMessage = () => (
    <Box css={{ position: "absolute", height: "$12" }}>
      <Copy scale={10} color="brand-green-primary">
        Copied successfully
      </Copy>
    </Box>
  )

  const {
    setValue,
    getValues,
    watch,
    control,
    register,
    trigger,
    formState: { errors },
    reset,
  } = useFormContext<IOrganizationState>()
  const { address, billingAddress, apiKey, apiSecret, carrierApiKey, carrierApiSecret } = watch()
  const { country, zipCode } = address
  const zipCodeData = { zipCode, country }
  const { country: billingCountry, zipCode: billingZipCode } = billingAddress
  const billingZipCodeData = { zipCode: billingZipCode, country: billingCountry }

  const { data: orgData, fetchStatus: organizationByIdFetchStatus } = useOrganizationById(
    !isOrganizationUnavailable ? organizationId : "",
  )
  const { data: organizationList = [], fetchStatus: organizationsFetchStatus } = useAllUserOrganizations(
    user.username,
  )

  const organizationTypeList: OrganizationType[] = Object.values(OrganizationType)

  const isLoading = organizationsFetchStatus === "fetching" || organizationByIdFetchStatus === "fetching"

  useEffect(() => {
    if (isOrganizationUnavailable) {
      navigate(`${MANAGEMENT}/edit/organization/${organization.id}`)
    }
  }, [isOrganizationUnavailable, navigate, organization.id])

  useEffect(() => {
    if (isEditMode && orgData) {
      setValue("id", orgData.id || "", { shouldDirty: true })
      setValue("name", orgData.name || "", { shouldDirty: true })
      setValue("generalEmail", orgData.generalEmail || "", { shouldDirty: true })
      setValue("senderEmail", orgData.senderEmail || "", { shouldDirty: true })
      setValue("invoiceEmail", orgData.invoiceEmail || "", { shouldDirty: true })
      setValue("phone", phoneToText(orgData.phone), { shouldDirty: true })
      setValue("timeZone", orgData.timeZone || "", { shouldDirty: true })
      setValue("apiKey", orgData.apiKey || "", { shouldDirty: true })
      setValue("apiSecret", orgData.apiSecret || initialOrganizationState.apiSecret, { shouldDirty: true })
      setValue("carrierApiKey", orgData.carrierApiKey || "", { shouldDirty: true })
      setValue("carrierApiSecret", orgData.carrierApiSecret || initialOrganizationState.carrierApiSecret, {
        shouldDirty: true,
      })
      setValue("parentId", orgData.parentId || "", { shouldDirty: true })
      setValue("address.country", orgData.address?.country || "", { shouldDirty: true })
      setValue("address.zipCode", orgData.address?.zipCode || "", { shouldDirty: true })
      setValue("address.state", orgData.address?.state || "", { shouldDirty: true })
      setValue("address.city", orgData.address?.city || "", { shouldDirty: true })
      setValue("address.address1", orgData.address?.address1 || "", { shouldDirty: true })
      setValue("address.address2", orgData.address?.address2 || "", { shouldDirty: true })
      setValue("billingAddress.country", orgData.billingAddress?.country || "", { shouldDirty: true })
      setValue("billingAddress.zipCode", orgData.billingAddress?.zipCode || "", { shouldDirty: true })
      setValue("billingAddress.state", orgData.billingAddress?.state || "", { shouldDirty: true })
      setValue("billingAddress.city", orgData.billingAddress?.city || "", { shouldDirty: true })
      setValue("billingAddress.address1", orgData.billingAddress?.address1 || "", { shouldDirty: true })
      setValue("billingAddress.address2", orgData.billingAddress?.address2 || "", { shouldDirty: true })
      setValue("contacts", orgData.contacts || [], { shouldDirty: true })
      setValue("workingDays", orgData.workingDays || [], { shouldDirty: true })
      setValue("organizationType", orgData.organizationType || OrganizationType.Broker, { shouldDirty: true })

      setTimeout(() => {
        reset(getValues())
      }, 0)
    }
  }, [setValue, isEditMode, orgData, getValues, reset])

  return (
    <>
      <Breadcrumbs
        routeParam={isEditMode ? `Edit organization / ${orgData?.name}` : "Create an organization"}
      />
      <Title as="h1" scale={2} color="system-black">
        {isEditMode ? "Edit organization" : "Create an organization"}
      </Title>
      <Spacer size={40} />
      <Box
        css={{
          backgroundColor: "$system-white",
          padding: "$40 $56",
          borderRadius: "$8",
          marginBottom: "$96",
        }}
      >
        <Title as="h2" scale={6} color="theme-b-n3">
          General Info
        </Title>
        <Spacer size={32} />
        {isLoading ? (
          <Flex align="center" justify="center" css={{ height: 400 }}>
            <Spinner />
          </Flex>
        ) : (
          <>
            <Box>
              <Stack space={32}>
                <Grid columns="1fr 1fr 1fr 1fr" columnGap={16}>
                  <GridItem>
                    <TextFieldController<IOrganizationState>
                      name="name"
                      control={control}
                      register={register}
                      trigger={trigger}
                      error={errors.name?.message}
                      labelProps={{ required: true }}
                      rules={{ ...lengthRule("Name", 40) }}
                      description="Name of the organization"
                    />
                  </GridItem>
                  <GridItem>
                    <Controller
                      name="parentId"
                      control={control}
                      render={({ field }) => {
                        return (
                          <FormSelect
                            {...register(field.name, {
                              shouldUnregister: true,
                            })}
                            {...field}
                            id={field.name}
                            onValueChange={(value) => {
                              setValue("parentId", value as string, { shouldDirty: true })
                              const selectedOrgType = organizationList.find(
                                (i) => i.id === value,
                              )?.organizationType

                              if (selectedOrgType === OrganizationType.Broker) {
                                setSelectedParentOrgType(selectedOrgType)
                                setValue("organizationType", OrganizationType.Broker, { shouldDirty: true })
                              } else {
                                setSelectedParentOrgType(OrganizationType.Shipper)
                              }
                            }}
                            label="Parent organization"
                            labelProps={{ hidden: true }}
                            description="Parent organization"
                            isDisplayValueDifferent
                            isSearchable
                            value={{
                              value: field.value,
                              label: [{ id: "", value: "", label: "Not selected" }, ...organizationList].find(
                                (i) => i.id === field.value,
                              )?.label,
                            }}
                            options={[{ id: "", value: "", label: "Not selected" }, ...organizationList].map(
                              (i) => ({
                                value: i.id ? i.id : "",
                                label: i.label,
                              }),
                            )}
                            disabled={isLoading || isEditMode}
                            error={errors[field.name]?.message}
                          />
                        )
                      }}
                    />
                  </GridItem>
                  <GridItem>
                    <Controller
                      name="organizationType"
                      control={control}
                      render={({ field }) => {
                        return (
                          <FormSelect
                            {...register(field.name, {
                              shouldUnregister: true,
                            })}
                            {...field}
                            id={field.name}
                            onValueChange={(value) => {
                              setValue("organizationType", value as OrganizationType, { shouldDirty: true })
                            }}
                            label="Type"
                            labelProps={{ hidden: true }}
                            description="Type"
                            isDisplayValueDifferent
                            isSearchable
                            value={{
                              value: field.value,
                              label: getEnumKeyByEnumValue(OrganizationType, field.value),
                            }}
                            options={organizationTypeList.map((type) => ({
                              value: type,
                              label: getEnumKeyByEnumValue(OrganizationType, type),
                            }))}
                            disabled={
                              isLoading || isEditMode || selectedParentOrgType === OrganizationType.Broker
                            }
                            error={errors[field.name]?.message}
                          />
                        )
                      }}
                    />
                  </GridItem>
                  <GridItem>
                    <TextFieldController<IOrganizationState>
                      name="address.address1"
                      control={control}
                      register={register}
                      trigger={trigger}
                      description="Address line 1"
                      rules={lengthRule("Address", 100, 0)}
                      error={errors.address?.address1?.message}
                    />
                  </GridItem>
                </Grid>
                <Grid columns="1fr 1fr 1fr 1fr" columnGap={16}>
                  <GridItem>
                    <TextFieldController<IOrganizationState>
                      name="address.address2"
                      control={control}
                      register={register}
                      trigger={trigger}
                      description="Address line 2"
                      rules={lengthRule("Address", 40, 0)}
                      error={errors.address?.address2?.message}
                    />
                  </GridItem>
                  <GridItem>
                    <TextFieldController<IOrganizationState>
                      name="address.city"
                      control={control}
                      register={register}
                      trigger={trigger}
                      description="City"
                      rules={{ ...nonNumericPattern, ...lengthRule("City", 40, 0) }}
                      error={errors.address?.city?.message}
                    />
                  </GridItem>
                  <GridItem>
                    <StateController<IOrganizationState>
                      name="address.state"
                      control={control}
                      register={register}
                      trigger={trigger}
                      label="State"
                      description="State"
                      error={errors.address?.state?.message}
                    />
                  </GridItem>
                  <GridItem>
                    <ZipCodeController<IOrganizationState>
                      name="address.zipCode"
                      control={control}
                      register={register}
                      trigger={trigger}
                      label="Zip code"
                      description="Zip code"
                      extendedData={{ zipCodeData }}
                      error={errors.address?.zipCode?.message}
                    />
                  </GridItem>
                </Grid>
                <Grid columns="1fr 1fr 1fr 1fr" columnGap={16}>
                  <GridItem>
                    <CountryController<IOrganizationState>
                      name="address.country"
                      control={control}
                      register={register}
                      trigger={trigger}
                      error={errors.address?.country?.message}
                    />
                  </GridItem>
                  <GridItem>
                    <EmailController<IOrganizationState>
                      name="generalEmail"
                      control={control}
                      register={register}
                      trigger={trigger}
                      description="General email"
                      labelProps={{ required: true }}
                      error={errors.generalEmail?.message}
                    />
                  </GridItem>
                  <GridItem>
                    <EmailController<IOrganizationState>
                      name="senderEmail"
                      control={control}
                      register={register}
                      trigger={trigger}
                      disabled={isRoleCustomerAdmin}
                      label={
                        <Flex as="span" align="center" css={{ gap: "$4" }}>
                          <Copy as="span" scale={10} fontWeight="semiBold">
                            Sender for email correspondence
                          </Copy>
                          <IconTooltip
                            tooltip={
                              <Copy scale={10}>
                                Please make sure this email is integrated with Levity and SendGrid before
                                saving it
                              </Copy>
                            }
                            ariaLabel="Chart tooltip"
                            withTitle={false}
                            contentWidth="max-content"
                            trigger={["hover", "focus"]}
                            placement="top"
                            contentCss={{
                              width: "100%",
                              padding: "$12",
                              borderRadius: "$8",
                            }}
                            icon={<IconInfoCircle />}
                          />
                        </Flex>
                      }
                      description="Sender for email correspondence"
                      error={errors.senderEmail?.message}
                    />
                  </GridItem>
                  <GridItem>
                    <PhoneController<IOrganizationState>
                      name="phone"
                      control={control}
                      register={register}
                      trigger={trigger}
                      error={errors.phone?.message}
                    />
                  </GridItem>
                </Grid>
                <Grid columns="1fr 1fr 1fr 1fr" columnGap={16}>
                  <GridItem>
                    <TimeZoneController<IOrganizationState>
                      name="timeZone"
                      control={control}
                      register={register}
                      trigger={trigger}
                      error={errors.timeZone?.message}
                    />
                  </GridItem>
                </Grid>
              </Stack>
            </Box>
            <Spacer size={32} />
            <Box>
              <Title as="h2" scale={6} color="theme-b-n3">
                Billing address
              </Title>
              <Spacer size={32} />
              <Stack space={32}>
                <Grid columns="1fr 1fr 1fr 1fr" columnGap={16}>
                  <GridItem>
                    <TextFieldController<IOrganizationState>
                      name="billingAddress.address1"
                      control={control}
                      register={register}
                      trigger={trigger}
                      description="Address line 1"
                      rules={lengthRule("Address", 100, 0)}
                      error={errors.billingAddress?.address1?.message}
                    />
                  </GridItem>
                  <GridItem>
                    <TextFieldController<IOrganizationState>
                      name="billingAddress.address2"
                      control={control}
                      register={register}
                      trigger={trigger}
                      description="Address line 2"
                      rules={lengthRule("Address", 40, 0)}
                      error={errors.billingAddress?.address2?.message}
                    />
                  </GridItem>
                  <GridItem>
                    <TextFieldController<IOrganizationState>
                      name="billingAddress.city"
                      control={control}
                      register={register}
                      trigger={trigger}
                      description="City"
                      rules={{ ...nonNumericPattern, ...lengthRule("City", 40, 0) }}
                      error={errors.billingAddress?.city?.message}
                    />
                  </GridItem>
                  <GridItem>
                    <StateController<IOrganizationState>
                      name="billingAddress.state"
                      control={control}
                      register={register}
                      trigger={trigger}
                      label="State"
                      description="State"
                      error={errors.billingAddress?.state?.message}
                    />
                  </GridItem>
                </Grid>
                <Grid columns="1fr 1fr 1fr 1fr" columnGap={16}>
                  <GridItem>
                    <ZipCodeController<IOrganizationState>
                      name="billingAddress.zipCode"
                      control={control}
                      register={register}
                      trigger={trigger}
                      label="Zip code"
                      description="Zip code"
                      extendedData={{ zipCodeData: billingZipCodeData }}
                      error={errors.billingAddress?.zipCode?.message}
                    />
                  </GridItem>
                  <GridItem>
                    <CountryController<IOrganizationState>
                      name="billingAddress.country"
                      control={control}
                      register={register}
                      trigger={trigger}
                      error={errors.billingAddress?.country?.message}
                    />
                  </GridItem>
                  <GridItem>
                    <EmailController<IOrganizationState>
                      name="invoiceEmail"
                      control={control}
                      register={register}
                      trigger={trigger}
                      description="Invoice email"
                      error={errors.invoiceEmail?.message}
                    />
                  </GridItem>
                </Grid>
              </Stack>
            </Box>
            <Spacer size={32} />
            <WorkingDays />
            <Spacer size={32} />
            {isSysAdmin(user.authorities[0].authority) ? (
              <Box>
                <Title as="h2" scale={6} color="theme-b-n3">
                  Integration
                </Title>
                <Spacer size={32} />
                {!checkApiKeyVisibility(apiSecret) && (
                  <>
                    <Copy color="theme-b-n3">
                      Please copy API secrets as they will not be available after saving.
                    </Copy>
                    <Spacer size={32} />
                  </>
                )}

                <Stack space={32}>
                  <Grid columns="1fr 1fr 1fr 1fr" columnGap={16}>
                    <GridItem>
                      <TextFieldController<IOrganizationState>
                        name="apiKey"
                        control={control}
                        register={register}
                        trigger={trigger}
                        hasdoublesuffix
                        suffix={
                          !(isRoleCustomerAdmin || isRoleReader) && (
                            <>
                              <ButtonIcon
                                ariaLabel="Generate api key"
                                type="button"
                                icon={<IconRefresh />}
                                onClick={() => {
                                  setValue("apiKey", generateApiKey(), { shouldDirty: true })
                                  setValue("apiSecret", generateSecretKey(generateApiKey()), {
                                    shouldDirty: true,
                                  })
                                }}
                                inputIcon
                              />
                              <CopyToClipboard text={apiKey}>
                                <ButtonIcon
                                  type="button"
                                  ariaLabel="Copy"
                                  icon={<IconCopy />}
                                  onClick={() => handleCopyClick(setCopiedApiKeyToClipboard)}
                                  inputIcon
                                />
                              </CopyToClipboard>
                            </>
                          )
                        }
                        description="API key"
                        error={errors.apiKey?.message}
                        disabled
                      />
                      {copiedApiKeyToClipboard ? <CopyMessage /> : null}
                    </GridItem>
                    <GridItem>
                      <TextFieldController<IOrganizationState>
                        name="apiSecret"
                        control={control}
                        register={register}
                        trigger={trigger}
                        type={checkApiKeyVisibility(apiSecret) ? FormType.Password : FormType.Text}
                        hasdoublesuffix
                        suffix={
                          <>
                            {!(isRoleCustomerAdmin || isRoleReader) && (
                              <ButtonIcon
                                ariaLabel="Generate api secret"
                                type="button"
                                icon={<IconRefresh />}
                                onClick={() =>
                                  setValue("apiSecret", generateSecretKey(generateApiKey()), {
                                    shouldDirty: true,
                                  })
                                }
                                inputIcon
                              />
                            )}
                            {!checkApiKeyVisibility(apiSecret) ? (
                              <CopyToClipboard text={apiSecret}>
                                <ButtonIcon
                                  type="button"
                                  ariaLabel="Edit"
                                  icon={<IconCopy />}
                                  onClick={() => handleCopyClick(setCopiedApiSecretToClipboard)}
                                  inputIcon
                                />
                              </CopyToClipboard>
                            ) : null}
                          </>
                        }
                        description="API Secret"
                        error={errors.apiSecret?.message}
                        disabled
                      />
                      {copiedApiSecretToClipboard ? <CopyMessage /> : null}
                    </GridItem>
                  </Grid>

                  <Grid columns="1fr 1fr 1fr 1fr" columnGap={16}>
                    <GridItem>
                      <TextFieldController<IOrganizationState>
                        name="carrierApiKey"
                        control={control}
                        register={register}
                        trigger={trigger}
                        hasdoublesuffix
                        suffix={
                          !(isRoleCustomerAdmin || isRoleReader) && (
                            <>
                              <ButtonIcon
                                ariaLabel="Generate carrier api key"
                                type="button"
                                icon={<IconRefresh />}
                                onClick={() => {
                                  setValue("carrierApiKey", generateApiKey(), { shouldDirty: true })
                                  setValue("carrierApiSecret", generateSecretKey(generateApiKey()), {
                                    shouldDirty: true,
                                  })
                                }}
                                inputIcon
                              />
                              <CopyToClipboard text={carrierApiKey}>
                                <ButtonIcon
                                  type="button"
                                  ariaLabel="Copy"
                                  icon={<IconCopy />}
                                  onClick={() => handleCopyClick(setCopiedCarrierApiKeyToClipboard)}
                                  inputIcon
                                />
                              </CopyToClipboard>
                            </>
                          )
                        }
                        description="Carrier API key"
                        error={errors.carrierApiKey?.message}
                        disabled
                      />
                      {copiedCarrierApiKeyToClipboard ? <CopyMessage /> : null}
                    </GridItem>
                    <GridItem>
                      <TextFieldController<IOrganizationState>
                        name="carrierApiSecret"
                        control={control}
                        register={register}
                        trigger={trigger}
                        type={checkApiKeyVisibility(carrierApiSecret) ? FormType.Password : FormType.Text}
                        hasdoublesuffix
                        suffix={
                          <>
                            {!(isRoleCustomerAdmin || isRoleReader) && (
                              <ButtonIcon
                                ariaLabel="Generate carrier api secret"
                                type="button"
                                icon={<IconRefresh />}
                                onClick={() =>
                                  setValue("carrierApiSecret", generateSecretKey(generateApiKey()), {
                                    shouldDirty: true,
                                  })
                                }
                                inputIcon
                              />
                            )}
                            {!checkApiKeyVisibility(carrierApiSecret) ? (
                              <CopyToClipboard text={carrierApiSecret}>
                                <ButtonIcon
                                  type="button"
                                  ariaLabel="Copy"
                                  icon={<IconCopy />}
                                  onClick={() => handleCopyClick(setCopiedCarrierApiSecretToClipboard)}
                                  inputIcon
                                />
                              </CopyToClipboard>
                            ) : null}
                          </>
                        }
                        description="Carrier API Secret"
                        error={errors.carrierApiSecret?.message}
                        disabled
                      />
                      {copiedCarrierApiSecretToClipboard ? <CopyMessage /> : null}
                    </GridItem>
                  </Grid>
                </Stack>
              </Box>
            ) : null}
          </>
        )}
      </Box>
    </>
  )
}
