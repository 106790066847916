import { StrategyType } from "@/shared/types"

export const strategyToText = (strategy?: StrategyType) => {
  switch (strategy) {
    case StrategyType.Waterfall:
      return "Waterfall"
    case StrategyType.FirstCome:
      return "FCFS"
    case StrategyType.WaitAll:
    case StrategyType.WaitAllWithLanes:
      return "Wait for all"
    case StrategyType.Critical:
      return "Critical"
    default:
      return "-"
  }
}
