import format from "date-fns-tz/format"
import { enUS } from "date-fns/locale"

export const dateTimeTzFormat = ({
  dateTime,
  timeZone,
  timeFormat = "MMM d, yyyy hh:mm aa (zzz)",
}: {
  dateTime?: string | null
  timeZone: string
  timeFormat?: string
}) => {
  return dateTime
    ? format(new Date(dateTime), timeFormat, {
        timeZone: timeZone,
        locale: enUS,
      })
    : null
}
