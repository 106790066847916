import { ISlaItem, SlaField } from "@/shared/types"

const excludedCarriersKeys = [SlaField.AddressFrom, SlaField.AddressTo, SlaField.Shipper]

export const excludedAddressFields = [SlaField.ExcludedCarriers, ...excludedCarriersKeys]

const slaFieldTypes = [
  //categories
  SlaField.ExcludedCarriers,
  SlaField.Delivery,
  SlaField.Tender,
  SlaField.Tracking,
  SlaField.Unloading,
  SlaField.Cargo,
  SlaField.Haul,
  SlaField.Brokers,

  //additional
  ...excludedCarriersKeys,
]

const findNode = (nodes: ISlaItem[]) => {
  for (const node of nodes) {
    if (node.field && slaFieldTypes.includes(node.field)) return node
    if (node.nodes) {
      const child = findNode(node.nodes) as ISlaItem
      if (child) return child
    }
  }
}

export const findSlaAdditionalItem = (slaKeysArr: SlaField[]) => {
  return slaKeysArr.find((el) => excludedCarriersKeys.includes(el))
}

export const getSlaNodeFieldType = (nodes: ISlaItem[]) => {
  const node = findNode(nodes)
  return node?.field
}
