import { memo } from "react"

import { QuoteStatusType } from "@/shared/types"
import { Colors, ResponsiveProp } from "@/stitches/types"
import { quoteStatusToText } from "@/shared/utils"

import { Copy, Flex, TypographyScale } from "@/shared/components"

function getStatusColor(status: QuoteStatusType): {
  color: Colors
  backgroundColor: Colors
} {
  switch (status) {
    case QuoteStatusType.ReadyForSending:
    case QuoteStatusType.NotTendered:
      return {
        color: "special-disabled",
        backgroundColor: "specials-disabled",
      }
    case QuoteStatusType.Requested:
    case QuoteStatusType.Waiting:
    case QuoteStatusType.Acknowledged:
      return {
        color: "system-black",
        backgroundColor: "specials-warning",
      }

    case QuoteStatusType.Rejected:
    case QuoteStatusType.Error:
      return {
        color: "system-white",
        backgroundColor: "specials-error",
      }

    case QuoteStatusType.Submitted:
    case QuoteStatusType.Accepted:
    case QuoteStatusType.AwardedWinner:
      return {
        color: "system-white",
        backgroundColor: "specials-success",
      }

    default:
      return {
        color: "neutrals-5",
        backgroundColor: "theme-n2-n7",
      }
  }
}

export const QuoteStatusLabel = memo(
  ({
    status,
    code,
    scale = 10,
  }: {
    status: QuoteStatusType
    code?: string
    scale?: TypographyScale | ResponsiveProp<TypographyScale>
  }) => {
    return (
      <Flex
        align="center"
        css={{
          height: "$24",
          borderRadius: "$32",
          padding: "$2 $8",
          backgroundColor: `$${getStatusColor(status).backgroundColor}`,
          width: "fit-content",
        }}
      >
        <Copy
          id={`status_${code}`}
          as="span"
          scale={scale}
          color={getStatusColor(status).color}
          truncate
          fontWeight="bold"
        >
          {quoteStatusToText(status)}
        </Copy>
      </Flex>
    )
  },
)

QuoteStatusLabel.displayName = "QuoteStatusLabel"
