import { useQuery } from "@tanstack/react-query"

import { getGroupedCarrierStatsByOrgIdFn } from "@/api/tenderApi"

export const useGroupedCarrierStats = (
  orgId: string,
  from: string,
  groups: string,
  enabled: boolean,
  ids?: string[],
) => {
  const isEnabled = !!orgId && enabled

  return useQuery(
    ["groupedCarrierStatsByOrgIdFn", orgId, from, groups, ids],
    () => getGroupedCarrierStatsByOrgIdFn(orgId, from, groups, ids),
    {
      keepPreviousData: true,
      enabled: isEnabled,
    },
  )
}
