import { useQuery } from "@tanstack/react-query"
import { isAxiosError } from "axios"

import { getAllFilteredOrdersFn } from "@/api/ordersApi"
import { showToast } from "@/shared/utils"
import { SankeyNodeKey } from "@/shared/types"
import { getTenderOrdersFn } from "@/api/tenderApi"

export const useAllFilteredOrders = ({
  orgId,
  page,
  size,
  lineType,
  from,
}: {
  orgId: string
  page: number
  size?: number
  lineType: SankeyNodeKey
  from: string
}) => {
  const isEnabled = !!orgId

  return useQuery(
    ["allFilteredOrders", orgId, page, lineType, from],
    () =>
      lineType === SankeyNodeKey.FILTERED
        ? getAllFilteredOrdersFn({ orgId, from, page, size })
        : getTenderOrdersFn({ orgId, strategyType: lineType, from, page, size }),
    {
      keepPreviousData: true,
      enabled: isEnabled,
      onError: (error) => {
        if (isAxiosError(error)) {
          showToast({ type: "error", text: error.response?.data.errorMessage || error.message })
        }
      },
    },
  )
}
