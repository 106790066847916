import { useMutation, useQueryClient } from "@tanstack/react-query"
import { isAxiosError } from "axios"

import { showToast } from "@/shared/utils"
import { IFlsFiltersState } from "@/shared/types"
import { updateFlsConfigFn } from "@/api/flsApi"

export const useUpdateFlsConfig = () => {
  const queryClient = useQueryClient()

  return useMutation((flsFilter: IFlsFiltersState) => updateFlsConfigFn(flsFilter), {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["flsConfig"],
      })
      showToast({ type: "success", text: "Saved successfully" })
    },

    onError: (error) => {
      if (isAxiosError(error)) {
        showToast({ type: "error", text: error.response?.data.errorMessage || error.message })
      }
    },
  })
}
