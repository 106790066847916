import { Box } from "@/shared/components"

import { ContactsTableRow } from "./ContactsTableRow"

export const ContactsTablePlaceholder = () => (
  <Box>
    <ContactsTableRow name="Name*" role="Role" phone="Phone*" email="Email" isTableHead isPlaceholder />
    {Array.from(new Array(3), (_, index) => index).map((v) => (
      <ContactsTableRow key={v} isPlaceholder />
    ))}
  </Box>
)
