import { memo, useCallback, useEffect, useRef, useState, Dispatch, SetStateAction, Key } from "react"

import { escapeKeyDown, filterArrayBySubString, spaceAndEnterKeyDown } from "@/shared/utils"

import {
  Box,
  ButtonIcon,
  Copy,
  Divider,
  Flex,
  FormCheckbox,
  FormInput,
  Pill,
  Popover,
  PopoverAnchor,
  PopoverContent,
  ResponseContentItem,
  Stack,
} from "@/shared/components"
import { IconChevronTop, IconChevronDown, IconCross, IconSearch } from "@/shared/icons"

import { ViewSelectedSPopoverAnchorButton } from "@/shared/styles/SPopoverAnchorButton"

interface IItemsSelectProps<T> {
  selectedItemsState: [T[], Dispatch<SetStateAction<T[]>>]
  items: T[]
  disabled?: boolean
  isSelectAll?: boolean
  keyField: keyof T
  labelField: keyof T
  titleField?: string
  infoField?: string
}

export const ItemsSelect = memo(
  <T extends Record<string, string>>({
    selectedItemsState: [selectedItems, setSelectedItems],
    items,
    disabled,
    isSelectAll = true,
    keyField,
    labelField,
    titleField,
    infoField,
  }: IItemsSelectProps<T>) => {
    const [isCheckAll, setIsCheckAll] = useState(false)
    const [isCheckPartially, setIsCheckPartially] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const triggerRef = useRef<HTMLButtonElement>(null)
    const [inputValue, setInputValue] = useState<string>("")
    const [filteredScacs, setFilteredScacs] = useState<T[]>(items)
    const clearButtonRef = useRef<HTMLButtonElement>(null)
    const isTriggerClick = (event: Event) => event.composedPath().includes(triggerRef.current as EventTarget)
    const pillRef = useRef<HTMLButtonElement>(null)

    const selectedCarrierAccounts = items.filter((i) =>
      selectedItems.find((acc) => acc[keyField] === i[keyField]),
    )

    const handleChange = useCallback(
      (event: React.FormEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value as string

        if (!event.currentTarget.checked) {
          const newArray = selectedItems.filter((i) => String(i[keyField]) !== value)
          setSelectedItems(newArray)
        } else {
          const newArray = [...selectedItems, items.find((i) => String(i[keyField]) === value) as T]
          setSelectedItems(newArray)
        }
      },
      [items, keyField, selectedItems, setSelectedItems],
    )

    const handleCheckAllClick = useCallback(
      (event: React.FormEvent<HTMLInputElement>) => {
        if (event.currentTarget.checked) {
          setSelectedItems([...selectedItems, ...items])
        } else {
          setSelectedItems(selectedItems.filter((i) => !items.find((acc) => acc[keyField] === i[keyField])))
        }
      },
      [items, keyField, selectedItems, setSelectedItems],
    )

    useEffect(() => {
      if (selectedCarrierAccounts.length > 0) {
        if (selectedCarrierAccounts.length === items.length) {
          setIsCheckAll(true)
          setIsCheckPartially(false)
        } else {
          setIsCheckAll(false)
          setIsCheckPartially(true)
        }
      } else {
        setIsCheckAll(false)
        setIsCheckPartially(false)
      }
    }, [items.length, selectedCarrierAccounts.length])

    const filterScacsBySubStr = (subStr: string) => {
      setFilteredScacs(filterArrayBySubString(items, subStr, String(labelField)))
    }

    useEffect(() => {
      setFilteredScacs(items)
    }, [items])

    return (
      <Popover open={isOpen}>
        <PopoverAnchor asChild onKeyDown={(e: { key: string }) => escapeKeyDown(e.key) && setIsOpen(false)}>
          <ViewSelectedSPopoverAnchorButton
            ref={triggerRef}
            type="button"
            onClick={(event) => {
              if (!pillRef?.current?.contains(event?.target as Node)) {
                setIsOpen(!isOpen)
              }
            }}
            active={isOpen}
          >
            <Copy fontWeight="medium">{titleField}</Copy>
            <Flex css={{ gap: "$12" }}>
              <Pill
                as="span"
                ref={pillRef}
                suffix={
                  <IconCross
                    onClick={() => {
                      setIsCheckAll(false)
                      setIsCheckPartially(false)
                      setSelectedItems([])
                    }}
                  />
                }
                size="small"
                tabIndex={0}
                onKeyDown={(e: { key: string }) =>
                  spaceAndEnterKeyDown(e.key) &&
                  (setIsCheckAll(false), setIsCheckPartially(true), setSelectedItems([]))
                }
                css={{ color: "$theme-b-n11" }}
                data-testid="carrier-filter"
              >
                {`${selectedCarrierAccounts.length || "Not"} selected`}
              </Pill>
              <Flex align="center">
                {isOpen ? (
                  <IconChevronTop onClick={() => setIsOpen(!isOpen)} size="xs" />
                ) : (
                  <IconChevronDown onClick={() => setIsOpen(!isOpen)} size="xs" />
                )}
              </Flex>
            </Flex>
          </ViewSelectedSPopoverAnchorButton>
        </PopoverAnchor>
        <PopoverContent
          close={() => setIsOpen(false)}
          align="start"
          css={{
            width: 360,
            keyboardFocus: {
              outline: "1px solid $theme-vl-n3",
            },
          }}
          alignOffset={-1}
          onInteractOutside={(event) => {
            if (isTriggerClick(event)) {
              return
            }
            return setIsOpen(false)
          }}
          onOpenAutoFocus={(event) => {
            event.preventDefault()
          }}
        >
          <Stack space={0} css={{ paddingX: "$16", "@md": { paddingX: 0 } }}>
            <Box css={{ padding: "$12 $16" }}>
              <FormInput
                id={`Search field ${infoField}`}
                value={inputValue}
                label={`Search for ${infoField}`}
                labelProps={{ hidden: true }}
                placeholder={`Search for ${infoField}`}
                onChange={(event) => {
                  setInputValue(event.target.value)
                  filterScacsBySubStr(event.target.value)
                }}
                prefix={<IconSearch />}
                suffix={
                  inputValue && (
                    <ButtonIcon
                      ref={clearButtonRef}
                      icon={<IconCross />}
                      ariaLabel="Clear button"
                      onClick={() => {
                        setInputValue("")
                        filterScacsBySubStr("")
                      }}
                      inputIcon
                    />
                  )
                }
                autoComplete="new-password"
              />
            </Box>
            {isSelectAll && (
              <ResponseContentItem>
                <FormCheckbox
                  value="All"
                  onChange={handleCheckAllClick}
                  name={`Select all`}
                  id={`Select all`}
                  label="Select all"
                  checked={isCheckAll || isCheckPartially}
                  iconType={isCheckAll ? "tick" : "hyphen"}
                  disabled={disabled}
                />
              </ResponseContentItem>
            )}
            <Divider />
            <Box css={{ height: "max-content", maxHeight: 240, overflow: "auto" }}>
              {filteredScacs.length === 0 ? (
                <Box css={{ padding: "$16" }}>
                  <Copy color="theme-b-n3">Not found</Copy>
                </Box>
              ) : (
                filteredScacs.map((item) => (
                  <ResponseContentItem key={item[keyField] as Key}>
                    <FormCheckbox
                      value={item[keyField] as string}
                      onChange={handleChange}
                      name={item[labelField] as string}
                      id={item[keyField] as string}
                      label={`${item[labelField]}`}
                      checked={selectedItems.some((i) => i[keyField] === item[keyField])}
                      disabled={disabled}
                    />
                  </ResponseContentItem>
                ))
              )}
            </Box>
          </Stack>
        </PopoverContent>
      </Popover>
    )
  },
)

ItemsSelect.displayName = "ItemsSelect"
