import { IOrganizationResponse } from "@/shared/types"

import { Box, Copy, Flex } from "@/shared/components"

import { OrganizationTableRow } from "./components"

export const OrganizationTable = ({
  organizationList,
  isPlaceholder,
  placeholderRows = 10,
}: {
  organizationList: IOrganizationResponse[]
  isPlaceholder?: boolean
  placeholderRows?: number
}) => {
  return (
    <Box
      css={{
        backgroundColor: "$system-white",
        borderRadius: "$8",
      }}
    >
      <OrganizationTableRow
        name="name"
        address="address"
        email="email"
        phone="phone"
        isTableHead
        isPlaceholder
      />
      {isPlaceholder ? (
        Array.from(new Array(placeholderRows), (_, index) => index).map((v) => (
          <OrganizationTableRow key={v} isPlaceholder />
        ))
      ) : organizationList.length === 0 ? (
        <Flex
          align="center"
          justify="center"
          css={{
            height: "$64",
            color: "$theme-b-n3",
          }}
        >
          <Copy color="theme-n6-n5">There is no data yet</Copy>
        </Flex>
      ) : (
        organizationList.map(({ id, address, label, generalEmail: email, phone }) => {
          const fullAddress = [
            address.address1,
            address.address2,
            address.city,
            address.state,
            address.zipCode,
            address.country,
          ]
            .filter(Boolean)
            .join(", ")

          return (
            <OrganizationTableRow
              key={id}
              id={id}
              address={fullAddress}
              name={label}
              email={email}
              phone={phone}
            />
          )
        })
      )}
    </Box>
  )
}
