import { CommonLayout } from "@/shared/layouts/common"
import { MainLayout } from "@/shared/layouts/main"

import { Box, GridItem } from "@/shared/components"
import { TasksContainer } from "@/tasks/components"

export const Tasks = () => {
  return (
    <CommonLayout>
      <MainLayout>
        <GridItem column={{ "@initial": "1 / span 6", "@sm": "1 / span 12", "@lg": "1 / span 24" }}>
          <Box css={{ padding: "$32" }}>
            <TasksContainer />
          </Box>
        </GridItem>
      </MainLayout>
    </CommonLayout>
  )
}
