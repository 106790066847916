import { useCallback } from "react"

import { useModal, useModalActions } from "@/shared/hooks"
import { useDeleteUser } from "@/management/hooks"
import { ModalType } from "@/shared/types"

import { Button, Copy, Flex, Stack, Title, useGlobalSettings } from "@/shared/components"
import { GeneralModal } from "@/modals"

export const DeleteUserModal = () => {
  const { modalProps } = useModal(ModalType.DeleteUserModal)
  const { close } = useModalActions()
  const { mutate: deleteUser } = useDeleteUser()
  const { userId } = useGlobalSettings("DeleteUserModal")

  const handleContinueClick = useCallback(() => {
    close(ModalType.DeleteUserModal)
    deleteUser(userId)
  }, [close, userId, deleteUser])

  return (
    <GeneralModal
      {...modalProps}
      footer={
        <Flex justify="end" css={{ gap: "$16", paddingBottom: "$8" }}>
          <Button onClick={handleContinueClick}>OK</Button>
          <Button action="secondary" onClick={() => close(ModalType.DeleteUserModal)}>
            Cancel
          </Button>
        </Flex>
      }
    >
      <Stack space={12} css={{ padding: "$64 $24 $0" }}>
        <Title as="h3" scale={2} color="system-black">
          Do you want to delete the user?
        </Title>
        <Copy scale={10} color="theme-n6-n5" css={{ paddingBottom: "$8" }}>
          In case you press OK, the user will be deleted permanently
        </Copy>
      </Stack>
    </GeneralModal>
  )
}
