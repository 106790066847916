import { Controller, FieldValues, Path, PathValue } from "react-hook-form"
import { IFormInputControllerProps } from "@/shared/types"
import { FormSelect } from "@/shared/components"

const timeZones = [
  "America/Adak",
  "America/Anchorage",
  "America/Boise",
  "America/Chicago",
  "America/Denver",
  "America/Detroit",
  "America/Indiana/Knox",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Tell_City",
  "America/Indiana/Vevay",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indianapolis",
  "America/Juneau",
  "America/Kentucky/Monticello",
  "America/Los_Angeles",
  "America/Louisville",
  "America/Menominee",
  "America/Metlakatla",
  "America/New_York",
  "America/Nome",
  "America/North_Dakota/Beulah",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/Phoenix",
  "America/Sitka",
  "America/Yakutat",
  "Pacific/Honolulu",
]

export const TimeZoneController = <FieldsType extends FieldValues>({
  name,
  control,
  error,
  disabled,
  register,
  onChangeHandler,
}: IFormInputControllerProps<FieldsType>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <FormSelect
            {...(register &&
              register(field.name, {
                shouldUnregister: true,
                required: {
                  value: true,
                  message: "Required field",
                },
              }))}
            {...field}
            onValueChange={(value) => {
              if (value !== field.value) {
                onChangeHandler && onChangeHandler()
              }

              return field.onChange(value as PathValue<FieldsType, Path<FieldsType>>)
            }}
            id={name}
            isDisplayValueDifferent
            isSearchable
            value={{
              value: field.value,
              label: field.value.replace(/[_]/g, " "),
            }}
            options={timeZones.map((i) => ({
              value: i,
              label: i.replace(/[_]/g, " "),
            }))}
            label="Time zone"
            labelProps={{ required: true, hidden: true }}
            description="Time zone"
            disabled={disabled}
            error={error}
            isMenuScrollbarColored
          />
        )
      }}
    />
  )
}
