import { useFormContext } from "react-hook-form"

import { ModalType, IOrganizationState, ICarrierSource } from "@/shared/types"
import { useModalActions } from "@/shared/hooks"
import { uberScac } from "@/constants"
import { initialOrganizationData } from "@/store"

import { ActivityStatusLabel, ButtonIcon, Copy, Flex, Grid, GridItem, Redacted } from "@/shared/components"
import { IconBin, IconPencil } from "@/shared/icons"

interface ICarrierTableRowProps {
  id?: string
  code?: string
  name?: string
  source?: ICarrierSource
  status?: string | boolean
  isTableHead?: boolean
  isPlaceholder?: boolean
  isViewMode?: boolean
  isBroker?: boolean
  isWithoutPermission?: boolean
  scac?: string
}

export const CarrierTableRow = ({
  id,
  code,
  name,
  source,
  status,
  isViewMode,
  isTableHead,
  isPlaceholder,
  isBroker,
  isWithoutPermission,
  scac,
}: ICarrierTableRowProps) => {
  const { watch, setValue, clearErrors } = useFormContext<IOrganizationState>()
  const { carriers } = watch()
  const { open } = useModalActions()

  const onViewClick = (id: string) => {
    const viewItem = carriers.find((el) => el.id === id)

    if (viewItem) {
      setValue("newCarrier", viewItem, { shouldDirty: true })
      clearErrors("newCarrier")
      open(ModalType.ViewCarrierModal)
    }
  }

  return (
    <Grid
      columns="1fr 1fr 1fr"
      align="center"
      css={{
        height: isTableHead ? "$48" : "$64",
        color: "$theme-b-n3",
        borderBottom: isTableHead ? "1px solid $theme-n3-n7" : "none",
        cursor: !isPlaceholder && !isTableHead ? "pointer" : "default",
        transition: "150ms ease-out",

        lastOfType: {
          borderRadius: "0 0 $8 $8",
        },
        hover: { backgroundColor: !isPlaceholder && !isTableHead ? "$brand-yellow-lightest" : "transparent" },
        keyboardFocus: {
          backgroundColor: !isPlaceholder && !isTableHead ? "$brand-yellow-lightest" : "transparent",
        },
      }}
      tabIndex={!isPlaceholder && !isTableHead ? 0 : -1}
      onClick={() => !isPlaceholder && !isTableHead && onViewClick(id || "")}
    >
      <GridItem css={{ paddingX: "$24" }}>
        {isPlaceholder && !isTableHead ? (
          <Redacted height="$24" width="100px" text animated />
        ) : (
          <Copy
            scale={isTableHead ? 8 : 10}
            fontWeight={isTableHead ? "bold" : "regular"}
            uppercase={isTableHead ? true : false}
          >
            {code || "-"}
          </Copy>
        )}
      </GridItem>
      <GridItem css={{ paddingX: "$24" }}>
        {isPlaceholder && !isTableHead ? (
          <Redacted height="$24" width="140px" text animated />
        ) : (
          <Copy
            scale={isTableHead ? 8 : 10}
            fontWeight={isTableHead ? "bold" : "regular"}
            uppercase={isTableHead ? true : false}
          >
            {name}
          </Copy>
        )}
      </GridItem>
      <GridItem css={{ paddingX: "$24" }}>
        {isPlaceholder && !isTableHead ? (
          <Redacted height="$24" width="100px" text animated />
        ) : isTableHead ? (
          <Copy scale={8} fontWeight="bold" uppercase>
            {status}
          </Copy>
        ) : (
          <Flex align="center" justify="between">
            <ActivityStatusLabel status={!!status} scale={10} filled />
            <Flex css={{ gap: "$8" }}>
              {!isViewMode ? <EditButton id={id || ""} /> : null}
              {!isWithoutPermission && (
                <RemoveButton
                  id={id || ""}
                  disabled={
                    (isBroker && scac?.toUpperCase() === uberScac) || source === ICarrierSource.HIGHWAY
                  }
                />
              )}
            </Flex>
          </Flex>
        )}
      </GridItem>
    </Grid>
  )
}

const RemoveButton = ({ id, disabled }: { id: string; disabled?: boolean }) => {
  const { watch, setValue } = useFormContext<IOrganizationState>()
  const { carriers } = watch()
  const { open } = useModalActions()

  const onDeleteClick = (e: React.SyntheticEvent, id: string) => {
    e.stopPropagation()

    const currentItem = carriers.find((el) => el.id === id)

    if (currentItem) {
      setValue("newCarrier", currentItem, { shouldDirty: true })
      open(ModalType.DeleteCarrierModal)
    }
  }

  return (
    <ButtonIcon
      type="button"
      ariaLabel={`Remove carrier ${id}`}
      icon={<IconBin />}
      onClick={(e) => onDeleteClick(e, id)}
      inputIcon
      disabled={disabled}
    />
  )
}

const EditButton = ({ id }: { id: string }) => {
  const { watch, setValue, clearErrors, getValues, reset } = useFormContext<IOrganizationState>()
  const { carriers } = watch()
  const { open } = useModalActions()

  const onEditClick = (e: React.SyntheticEvent, id: string) => {
    e.stopPropagation()
    const currentItem = carriers.find((el) => el.id === id)

    if (currentItem) {
      setTimeout(() => {
        reset({ ...initialOrganizationData, carriers: getValues("carriers"), newCarrier: currentItem })

        clearErrors("newCarrier")
      }, 0)
      setValue("newCarrier", currentItem, { shouldDirty: true })

      open(ModalType.CreateCarrierModal)
    }
  }

  return (
    <ButtonIcon
      type="button"
      ariaLabel={`Edit carrier ${id}`}
      icon={<IconPencil />}
      onClick={(e) => onEditClick(e, id)}
      inputIcon
    />
  )
}
