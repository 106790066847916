export const brand = {
  yellow: {
    lightest: "#fffceb",
    lighter: "#fffad6",
    light: "#fff3a4",
    primary: "#fee11b",
    dark: "#647227",
    darker: "#394116",
    darkest: "#202900",
  },
  blue: {
    light: "#e6eeff",
    primary: "#256ffe",
    dark: "#2c303a",
  },
  azure: {
    light: "#e0f9ff",
    primary: "#00ccf9",
  },
  green: {
    light: "#e3fded",
    primary: "#11d05e",
    dark: "#2c3a32",
  },
  red: {
    light: "#ffe7e5",
    primary: "#fe3225",
    dark: "#3a2d2c",
  },
  orange: {
    light: "#fdf4e2",
    primary: "#f9af20",
    dark: "#3a352c",
  },
  pink: {
    light: "#ffebf7",
    primary: "#ff47b5",
  },
  violet: {
    lightest: "#f2f2fd",
    lighter: "#ddddf9",
    light: "#7b7bfe",
    primary: "#5454de",
  },
  purple: {
    light: "#f7e6ff",
    primary: "#b925fe",
  },
}

export const system = {
  white: "#ffffff",
  black: "#191a1a",
  layout: "#f4f5f6",
  inherit: "inherit",
}

export const neutrals = {
  "0": system.white,
  "1": "#fafafa",
  "2": "#f2f2f2",
  "3": "#d8d8d8",
  "4": "#cccccc",
  "5": "#999999",
  "6": "#737373",
  "7": "#575757",
  "8": "#292929",
  "9": "#1f1f1f",
  "10": "#1a1a1a",
  "11": "#323333",
  "12": system.black,
}

// New colors
export const gray = {
  "50": "#F5F6F6",
  "100": "#E6E7E7",
  "200": "#CFD2D2",
  "300": "#AEB2B2",
  "400": "#858B8A",
  "500": "#6A706F",
  "600": "#5B5F5F",
  "700": "#4D5151",
  "800": "#444645",
  "900": "#3C3D3D",
}

export const green = {
  "50": "#F0F9F4",
  "100": "#DAF1E2",
  "200": "#B8E2C9",
  "300": "#88CDA8",
  "400": "#57B083",
  "500": "#359467",
  "600": "#29845A",
  "700": "#1D5F42",
  "800": "#194C35",
  "900": "#163E2E",
}

export const red = {
  "50": "#FDF3F3",
  "100": "#FDE4E3",
  "200": "#FBCECD",
  "300": "#F8ACA9",
  "400": "#F17C78",
  "500": "#E6524D",
  "600": "#C62F2A",
  "700": "#B12924",
  "800": "#932521",
  "900": "#7A2522",
}

export const yellow = {
  "50": "#FEFEE8",
  "100": "#FFFEC2",
  "200": "#FFFB88",
  "300": "#FFF044",
  "400": "#FEE11B",
  "500": "#EEC604",
  "600": "#CD9A01",
  "700": "#A46E04",
  "800": "#87560C",
  "900": "#734610",
}

export const orange = {
  "50": "#FFF6ED",
  "100": "#FFEAD5",
  "200": "#FED0AA",
  "300": "#FEAF73",
  "400": "#FC823A",
  "500": "#FA6115",
  "600": "#EB460B",
  "700": "#C3320B",
  "800": "#9B2911",
  "900": "#7D2411",
}

export const blue = {
  "50": "#EAF2FF",
  "100": "#D8E6FF",
  "200": "#B9CFFF",
  "300": "#8EAEFF",
  "400": "#6280FF",
  "500": "#3E51FF",
  "600": "#1C20FF",
  "700": "#0E0FE0",
  "800": "#1215C1",
  "900": "#181D97",
}

export const purple = {
  "50": "#FAF5FF",
  "100": "#F3E9FE",
  "200": "#E9D6FE",
  "300": "#D8B6FC",
  "400": "#C490F9",
  "500": "#A75AF2",
  "600": "#9238E5",
  "700": "#7D27C9",
  "800": "#6A25A4",
  "900": "#581F84",
}

export const pink = {
  "50": "#FDF2FA",
  "100": "#FDE6F7",
  "200": "#FCCEF0",
  "300": "#FBA6E3",
  "400": "#F877D2",
  "500": "#F044B8",
  "600": "#E02299",
  "700": "#C3137C",
  "800": "#A11366",
  "900": "#861557",
}

export const teal = {
  "50": "#EBFFFF",
  "100": "#CEFEFF",
  "200": "#A2FBFF",
  "300": "#63F5FD",
  "400": "#1CE4F4",
  "500": "#00BBCC",
  "600": "#039FB7",
  "700": "#0A7F94",
  "800": "#126578",
  "900": "#145465",
}

//for text
export const special = {
  error: brand.red.primary,
  success: brand.green.primary,
  warning: brand.orange.primary,
  info: brand.blue.primary,
  disabled: gray["700"],
}

//for background
export const specials = {
  error: red["600"],
  success: green["600"],
  warning: yellow["500"],
  info: purple["600"],
  disabled: gray["100"],
}

export const colorsMap = {
  "brand-yellow-lightest": brand.yellow.lightest,
  "brand-yellow-lighter": brand.yellow.lighter,
  "brand-yellow-light": brand.yellow.light,
  "brand-yellow-primary": brand.yellow.primary,
  "brand-yellow-dark": brand.yellow.dark,
  "brand-yellow-darker": brand.yellow.darker,
  "brand-yellow-darkest": brand.yellow.darkest,
  "brand-blue-light": brand.blue.light,
  "brand-blue-primary": brand.blue.primary,
  "brand-blue-dark": brand.blue.dark,
  "brand-azure-light": brand.azure.light,
  "brand-azure-primary": brand.azure.primary,
  "brand-green-light": brand.green.light,
  "brand-green-primary": brand.green.primary,
  "brand-green-dark": brand.green.dark,
  "brand-red-light": brand.red.light,
  "brand-red-primary": brand.red.primary,
  "brand-red-dark": brand.red.dark,
  "brand-orange-light": brand.orange.light,
  "brand-orange-primary": brand.orange.primary,
  "brand-orange-dark": brand.orange.dark,
  "brand-pink-light": brand.pink.light,
  "brand-pink-primary": brand.pink.primary,
  "brand-violet-lightest": brand.violet.lightest,
  "brand-violet-lighter": brand.violet.lighter,
  "brand-violet-light": brand.violet.light,
  "brand-violet-primary": brand.violet.primary,
  "brand-purple-light": brand.purple.light,
  "brand-purple-primary": brand.purple.primary,

  // Gray
  ...Object.fromEntries(Object.entries(gray).map(([key, value]) => [`gray-${key}`, value])),

  // Green
  ...Object.fromEntries(Object.entries(green).map(([key, value]) => [`green-${key}`, value])),

  // Red
  ...Object.fromEntries(Object.entries(red).map(([key, value]) => [`red-${key}`, value])),

  // Yellow
  ...Object.fromEntries(Object.entries(yellow).map(([key, value]) => [`yellow-${key}`, value])),

  // Orange
  ...Object.fromEntries(Object.entries(orange).map(([key, value]) => [`orange-${key}`, value])),

  // Blue
  ...Object.fromEntries(Object.entries(blue).map(([key, value]) => [`blue-${key}`, value])),

  // Purple
  ...Object.fromEntries(Object.entries(purple).map(([key, value]) => [`purple-${key}`, value])),

  // Pink
  ...Object.fromEntries(Object.entries(pink).map(([key, value]) => [`pink-${key}`, value])),

  // Teal
  ...Object.fromEntries(Object.entries(teal).map(([key, value]) => [`teal-${key}`, value])),

  // Neutrals
  "neutrals-0": neutrals["0"],
  "neutrals-1": neutrals["1"],
  "neutrals-2": neutrals["2"],
  "neutrals-3": neutrals["3"],
  "neutrals-4": neutrals["4"],
  "neutrals-5": neutrals["5"],
  "neutrals-6": neutrals["6"],
  "neutrals-7": neutrals["7"],
  "neutrals-8": neutrals["8"],
  "neutrals-9": neutrals["9"],
  "neutrals-10": neutrals["10"],
  "neutrals-11": neutrals["11"],
  "neutrals-12": neutrals["12"],

  // System
  "system-white": system.white,
  "system-black": system.black,
  "system-layout": system.layout,
  "system-inherit": system.inherit,

  // Special
  "special-error": special.error,
  "special-success": special.success,
  "special-warning": special.warning,
  "special-info": special.info,
  "special-disabled": special.disabled,

  // Specials
  "specials-error": specials.error,
  "specials-success": specials.success,
  "specials-warning": specials.warning,
  "specials-info": specials.info,
  "specials-disabled": specials.disabled,

  // Theme
  "theme-w-b": system.white,
  "theme-w-n8": system.white,
  "theme-w-n9": system.white,
  "theme-w-n11": system.white,
  "theme-b-n3": system.black,
  "theme-n1-n10": neutrals["1"],
  "theme-n2-n7": neutrals["2"],
  "theme-n3-n7": neutrals["3"],
  "theme-n4-n7": neutrals["4"],
  "theme-n6-n5": neutrals["6"],
  "theme-bl-bd": brand.blue.light,
  "theme-gl-gd": brand.green.light,
  "theme-ol-od": brand.orange.light,
  "theme-rl-rd": brand.red.light,
  "theme-vl-yl": brand.violet.light,

  // Buttons
  "button-primary": system.black,
  "button-primary-hover": gray["700"],
  "button-primary-active": gray["500"],
  "button-primary-spinner": gray["300"],
  "button-primary-disabled": gray["100"],
  "button-primary-disabled-text": gray["600"],
  "button-secondary": system.white,
  "button-secondary-hover": gray["100"],
  "button-secondary-active": gray["200"],
  "button-secondary-spinner": gray["400"],
  "button-tertiary": brand.blue.primary,
  "button-destructive": red["600"],
  "button-destructive-hover": red["500"],
  "button-destructive-active": red["700"],
}

export const darkColorsMap = {
  ...colorsMap,
  "theme-w-b": system.black,
  "theme-w-n8": neutrals["8"],
  "theme-w-n9": neutrals["9"],
  "theme-w-n11": neutrals["11"],
  "theme-b-n3": neutrals["3"],
  "theme-n1-n10": neutrals["10"],
  "theme-n2-n7": neutrals["7"],
  "theme-n3-n7": neutrals["7"],
  "theme-n4-n7": neutrals["7"],
  "theme-n6-n5": neutrals["5"],
  "theme-bl-bd": brand.blue.dark,
  "theme-gl-gd": brand.green.dark,
  "theme-ol-od": brand.orange.dark,
  "theme-rl-rd": brand.red.dark,
  "theme-vl-yl": brand.yellow.light,
}
