import { useFormContext } from "react-hook-form"

import { ISlaItem, SlaField, SlaItemGroup, IOrganizationState } from "@/shared/types"
import { slaGroupToText, spaceAndEnterKeyDown } from "@/shared/utils"
import { boxShadows } from "@/stitches/utils"

import { Copy, Flex, FormSelect, Tooltip } from "@/shared/components"

export const GroupSelect = ({
  group,
  name,
  path = [],
  isViewMode,
}: {
  name: SlaField
  path?: number[]
  group?: SlaItemGroup
  isViewMode?: boolean
}) => {
  const { watch, setValue } = useFormContext<IOrganizationState>()
  const { sla } = watch()

  // TODO: it will need to be refactored in the future
  const handleValueChange = (value: SlaItemGroup) => {
    if (path.length === 0) {
      const newValue = sla.map((i) => {
        if (i.name !== name) {
          return i
        }

        return {
          ...i,
          content: {
            ...(i.content as ISlaItem),
            group: value,
          },
        }
      })
      setValue("sla", newValue, {
        shouldDirty: true,
      })
      return
    }

    if (path.length === 1) {
      const newValue = sla.map((i) => {
        if (i.name !== name) {
          return i
        }

        return {
          ...i,
          content: {
            ...(i.content as ISlaItem),
            nodes: i.content?.nodes?.map((j, index) => {
              if (index !== path[0]) {
                return j
              }

              return {
                ...j,
                group: value,
              }
            }),
          },
        }
      })
      setValue("sla", newValue, {
        shouldDirty: true,
      })
      return
    }

    if (path.length === 2) {
      const newValue = sla.map((i) => {
        if (i.name !== name) {
          return i
        }

        return {
          ...i,
          content: {
            ...(i.content as ISlaItem),
            nodes: i.content?.nodes?.map((n, index) => {
              if (index !== path[0]) {
                return n
              }

              return {
                ...n,
                nodes: n.nodes?.map((j, index) => {
                  if (index !== path[1]) {
                    return j
                  }

                  return {
                    ...j,
                    group: value,
                  }
                }),
              }
            }) as ISlaItem[],
          },
        }
      })
      setValue("sla", newValue, {
        shouldDirty: true,
      })
      return
    }
  }

  return (
    <Tooltip
      tooltip={
        <Copy scale={10} color="theme-b-n3">
          {group === SlaItemGroup.And
            ? "If elements are combined by AND, they all must be met at the same time"
            : "If elements are combined by OR, only one of them must be met"}
        </Copy>
      }
      ariaLabel="Group select tooltip"
      withTitle={false}
      contentWidth="max-content"
      trigger={["hover"]}
      delayShow={2000}
      placement="top"
      contentCss={{
        width: 290,
        padding: "$20 $16",
        borderRadius: "$8",
      }}
      avoidTriggerFocus
      triggerCss={{
        display: "inline-grid",
        textAlign: "start",
        color: "$system-inherit",
        borderRadius: "$8",
        outline: "none",
      }}
      onKeyDown={(e) => {
        if (spaceAndEnterKeyDown(e.key)) {
          e.preventDefault()
          e.stopPropagation()
        }
      }}
      disabled={isViewMode}
    >
      {isViewMode ? (
        <Flex
          align="center"
          justify="center"
          css={{
            width: "$80",
            paddingY: "$4",
            marginLeft: 10,
            backgroundColor: "$brand-yellow-primary",
            borderRadius: "$8",
            boxShadow: boxShadows.input.initial,
          }}
        >
          <Copy color="theme-b-n3">{slaGroupToText(group)}</Copy>
        </Flex>
      ) : (
        <FormSelect
          id={`slaGroup_${name}_${path.join(".")}`}
          name={`slaGroup_${name}_${path.join(".")}`}
          label="Group select"
          labelProps={{ hidden: true }}
          value={slaGroupToText(group)}
          onValueChange={(value) => handleValueChange(value as SlaItemGroup)}
          options={[SlaItemGroup.And, SlaItemGroup.Or]}
          displayValueCss={{ width: 40 }}
          generalCss={{
            backgroundColor: "var(--colors-brand-yellow-primary)",
            padding: "var(--space-0) var(--space-0) var(--space-0) var(--space-12)",
            width: "var(--space-48)",
            minHeight: "var(--space-32)",
            gap: "var(--space-0)",
          }}
        />
      )}
    </Tooltip>
  )
}
